import { EAvailablePrebuiltEntity, EFusorType, EMLEntityType, EMLLuisTrainResultType, EOmniSenseMethods, IKBAppConfig } from "../../knowledge-base/luis-core-interfaces";
import { MetaTranslation } from '@colmeia/core/src/shared-business-rules/const-text/meta-translation';
import { defineTranslations, EAllSerializable, getTranslationsFields, getTranslationsFieldsAndScreenOptions } from "../all-serializables";
import { kbPredictItems } from "../../visual-constants";
import { createHashFromEntries, createHashFromEntriesV2, createMapFromEntries, IdField, typedEntryFields, typedFields, typedScreenOptions } from "../../../tools/utility";
import { genericTypedSuggestions, deprecatedSuggestions, suggestions } from "../../../tools/type-utils";
import { asConst } from "@colmeia/core/src/tools/utility/functions/asConst";


export const knowledgeBase = typedScreenOptions({
    idSerializable: EAllSerializable.KnowledgeBase,
    fields: {
        [EMLEntityType.list]: 1, //
        [EMLEntityType.simple]: 2, //
        [EMLEntityType.regex]: 3, //
        [EMLEntityType.composite]: 4, //
        [EMLEntityType.patternAny]: 5, //
        [EMLEntityType.prebuilt]: 6, //
        knowledgeBase: 7,  // 'SNManagement/knowledgeBase' 1
        [kbPredictItems.data]: 8,  // 'knowledgeBase/data' 1
        entities: 9,  // 'knowledgeBase/entities' 1
        training: 10,  // 'SNManagement/knowledgeBase' 34
        assets: 11,  // 'SNManagement/knowledgeBase' 35
        train: 12,  // 'SNManagement/knowledgeBase' 39
        test: 13,  // 'knowledgeBase/training' 23
        checkTrainingResults: 14,  // 'SNManagement/knowledgeBase' 43
        prediction: 15,  // 'knowledgeBase/data' 19
        extraction: 16,  // 'knowledgeBase/data' 20
        //fieldRequired: 17, // @TODO change to common
        roles: 18, // serializableId: 'knowledgeBase/entities', idField: 34
        labeledUtterances: 19, // serializableId: 'knowledgeBase/entities', idField: 35
        //utterance: 20, expressao @TODO change to common
        normalizedValue: 21, // serializableId: 'knowledgeBase/entities', idField: 36
        synonyms: 22, // serializableId: knowledgeBase/synonyms, idField: 1
        addSynonymPlaceholder: 23, // serializableId: 'knowledgeBase/entities', idField: 37
        addDataToTrain: 24, // SNManagement/knowledgeBase' 42
        lastTraining: 25, // SNManagement/knowledgeBase 33
        [EMLEntityType.machineLearned]: 26, //
    }
} as const);


export const knowledgeBase2 = typedScreenOptions({
    idSerializable: EAllSerializable.KnowledgeBase2,
    fields: {
        noBases: 1, // SNManagement/knowledgeBase 22
        needsMoreIntentsText: 2, // SNManagement/knowledgeBase' 44
        traningDoneAt: 3, // SNManagement/knowledgeBase' 45
        utterances: 4, // knowledgeBase/intents 22
        score: 5, // knowledgeBase/intents 27
        listTitle: 6, // knowledgeBase/intents 19
        addIntent: 7, // knowledgeBase/intents 23
        associatedEntity: 8,// knowledgeBase/intents 28
        associatedValue: 9,// knowledgeBase/intents 29
        identifiedAs: 10,// knowledgeBase/intents 30
        referenceEntityHelp: 11, // knowledgeBase/patterns 25
        referenceEntityExample: 12, // knowledgeBase/patterns 26
        referenceTextHelp: 13, // knowledgeBase/patterns 27
        referenceTextExample: 14, // knowledgeBase/patterns 30
        referenceTextGroupHelp: 15, // knowledgeBase/patterns 28
        referenceTextGroupExample: 16, // knowledgeBase/patterns 29
        patternPlaceholder: 17,// 'knowledgeBase/patterns' 23
        patternNeedsEntityOrTextField: 18,// 'knowledgeBase/patterns' 24
        patterns: 19,// 'knowledgeBase/patterns' 1
        patternSyntaxError: 20,// 'knowledgeBase/patterns' 31
        search: 21, // 'SNManagement/knowledgeBase' 40
        content: 22, // 'SNManagement/knowledgeBase' 36
        accuracy: 23, // 'SNManagement/knowledgeBase' 37
        seeContent: 24, // kbPredictionBox 19
        lastTouch: 25, // Ùltima modificação
    }
} as const);


export const knowledgeBase3 = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.KnowledgeBase3,
    fields: {
        typeTheQuestion: 1, // kbPredictionBox 20
        word: 2,  // knowledgeBase/synonyms 21
        valueHint: 3,  // knowledgeBase/synonyms 28
        recommendedValues: 4,  // knowledgeBase/synonyms 30
        recommend: 5,  // knowledgeBase/synonyms 31
        createSynonymList: 6, // knowledgeBase/synonyms 22
        editSynonymList: 7, // knowledgeBase/synonyms 32
        registers: 8,// SNManagement/knowledgeBase 24
        baseInfo: 9,// SNManagement/knowledgeBase 46
        errorLoadingBases: 10, // SNManagement/knowledgeBase 21
        intentions: 11, // Intenções
        nlpConfig: 12, // Configurações de NLP
        addToIntent: 13,// Adicionar a intenção
        [EOmniSenseMethods.machineLearning]: 15, // Machine Learning
        // [EOmniSenseMethods.corporateSearch]: 16, // Busca corporativa
        [EOmniSenseMethods.bruteForce]: 17, // Força bruta
        strategiesList: 18, // Estratégias
        strategy: 19, // Estratégia
        strategyEnabled: 20, // Habilitado
        strategyWeight: 21, // Peso
        createStrategyConfig: 22, // Adicionar estratégia
        editStrategyConfig: 23, // Editar estratégia
        bias: 24, // Viés
        changeIntention: 26, // Mudança de Intenção
        [EFusorType.SoftmaxWeigths]: 27, // Softmax
        [EFusorType.StaticWeights]: 28, // Média Ponderada
    }
} as const));


export const kbOmniSenseMethodsTranslations = defineTranslations({
    idSerializable: EAllSerializable.KBOmniSenseMethods,
    fields: asConst<{ [name in EOmniSenseMethods]: number }>()({
        [EOmniSenseMethods.machineLearning]: 1, // Machine Learning
        // [EOmniSenseMethods.corporateSearch]: 2, // Busca corporativa
        [EOmniSenseMethods.bruteForce]: 3, // Força bruta
        [EOmniSenseMethods.generative]: 4, // Generativo
        [EOmniSenseMethods.semanticPrediction]: 5, // Semântico
    } as const),
});

export const prebuiltEntitiesNameTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.AvailablePrebuildEntityName,
    fields: deprecatedSuggestions<{ [key in EAvailablePrebuiltEntity]: number }>()({
        [EAvailablePrebuiltEntity.Age]: 1, // Idade
        [EAvailablePrebuiltEntity.DatetimeV2]: 2, // DataTempoV2
        [EAvailablePrebuiltEntity.Dimension]: 3, // Dimensão
        [EAvailablePrebuiltEntity.Email]: 4, // Email
        [EAvailablePrebuiltEntity.KeyPhrase]: 5, // Frase chave
        [EAvailablePrebuiltEntity.Money]: 6, // Dinheiro
        [EAvailablePrebuiltEntity.Number]: 7, // Número
        [EAvailablePrebuiltEntity.Ordinal]: 8, // Ordinal
        [EAvailablePrebuiltEntity.Percentage]: 9, // Percentagem
        [EAvailablePrebuiltEntity.Phonenumber]: 10, // Número de telefone
        [EAvailablePrebuiltEntity.Temperature]: 11, // Temperatura
        [EAvailablePrebuiltEntity.URL]: 12, // URL
    }).ok,
}));

export const prebuiltEntitiesDescriptionTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.AvailablePrebuildEntityDescription,
    fields: deprecatedSuggestions<{ [key in EAvailablePrebuiltEntity]: number }>()({
        [EAvailablePrebuiltEntity.Age]: 1, // Idade de uma pessoa ou coisa
        [EAvailablePrebuiltEntity.DatetimeV2]: 2, // Datas e horários, resolvidos de forma canônica
        [EAvailablePrebuiltEntity.Dimension]: 3, // Dimensões espaciais, incluindo comprimento, distância, área e volume
        [EAvailablePrebuiltEntity.Email]: 4, // Endereço de e-mail
        [EAvailablePrebuiltEntity.KeyPhrase]: 5, // Extraia automaticamente frases-chave para identificar rapidamente os principais pontos de discussão
        [EAvailablePrebuiltEntity.Money]: 6, // Valores monetários, incluindo moeda
        [EAvailablePrebuiltEntity.Number]: 7, // Um número cardinal em formato numérico ou de texto
        [EAvailablePrebuiltEntity.Ordinal]: 8, // Um número ordinal na forma numérica ou de texto
        [EAvailablePrebuiltEntity.Percentage]: 9, // Uma porcentagem, usando o símbolo% ou a palavra "por cento"
        [EAvailablePrebuiltEntity.Phonenumber]: 10, // Números de telefone dos EUA
        [EAvailablePrebuiltEntity.Temperature]: 11, // Uma temperatura em Celsius ou Fahrenheit
        [EAvailablePrebuiltEntity.URL]: 12, // URLs e links de sites"
    }).ok,
}));

export const prebuiltEntitiesExampleTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.AvailablePrebuildEntityExample,
    fields: deprecatedSuggestions<{ [key in EAvailablePrebuiltEntity]: number }>()({
        [EAvailablePrebuiltEntity.Age]: 1, // 10 meses, 19 anos, 58 anos
        [EAvailablePrebuiltEntity.DatetimeV2]: 2, // 23 de junho de 1976, 11 de julho de 2012, 7h, 18h49, amanhã às 7h
        [EAvailablePrebuiltEntity.Dimension]: 3, // 2 milhas, 650 quilômetros quadrados, 9.350 pés
        [EAvailablePrebuiltEntity.Email]: 4, // user@example.net, user_name@example.com, user.Name12@example.net
        [EAvailablePrebuiltEntity.KeyPhrase]: 5, // Paris, cinema, futebol
        [EAvailablePrebuiltEntity.Money]: 6, // 1000,00 dólares americanos, £ 20,00, $ 67,5 bilhões
        [EAvailablePrebuiltEntity.Number]: 7, // dez, quarenta e dois, 3.141, 10K
        [EAvailablePrebuiltEntity.Ordinal]: 8, // primeiro, segundo, décimo, primeiro, segundo, 10º
        [EAvailablePrebuiltEntity.Percentage]: 9, // 10%, 5,6 por cento
        [EAvailablePrebuiltEntity.Phonenumber]: 10, // 123-456-7890, +1 123 456 789, (123) 456-789
        [EAvailablePrebuiltEntity.Temperature]: 11, // 32F, 34 graus Celsius, 2 graus C
        [EAvailablePrebuiltEntity.URL]: 12, // www.example.com, http://example.net?name=my_name&age=10, https://www.example.net/page
    }).ok,
}));

export const knowledgeBaseAppConfig = typedScreenOptions({
    idSerializable: EAllSerializable.KnowledgeBaseAppConfig,
    fields: genericTypedSuggestions<Required<{ [key in keyof IKBAppConfig]: number }>>()({
        UseAllTrainingData: 1, // Usar todos os dados de treino
        NormalizeDiacritics: 2, // Retirar Acentos (Diacríticos)
        NormalizePunctuation: 3, // Retirar Pontuação
    } as const)
} as const);

export const kbLuisTraininResultTypeTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.LuisTrainingResultType,
    fields: typedFields<EMLLuisTrainResultType>()({
        [EMLLuisTrainResultType.inProgress]: 1, // Em progresso
        [EMLLuisTrainResultType.upToDate]: 2, // Atualizado
        [EMLLuisTrainResultType.fail]: 3, // Falha
        [EMLLuisTrainResultType.success]: 4, // Sucesso
        [EMLLuisTrainResultType.queued]: 5, // Enfileirado
        [EMLLuisTrainResultType.error]: 6, // Erro
    }),
}));

export const kbStatusErrorTranslations = defineTranslations({
    idSerializable: EAllSerializable.KBStatusErrorTranslations,
    fields: ({
        FewLabels: MetaTranslation.field.template({
            num: 1,
            template: 'FewLabels',
            text: 'Poucas expressões em algumas intenções',
        }),
        Aborted: MetaTranslation.field.template({
            num: 2,
            template: 'Aborted',
            text: 'Abortado',
        }),
    }),
});

export const kbLuisErrorTranslations = defineTranslations({
    idSerializable: EAllSerializable.KBLuisErrorTranslations,
    fields: ({
        notAuthorized: MetaTranslation.field.template({
            num: 1,
            template: 'This LUIS resource is not authorized to access the application. Please assign it to access the app, or use a resource already assigned to access the app.',
            text: 'Este recurso LUIS não está autorizado a acessar a aplicação. Atribua-o para acessar a aplicação ou use um recurso já atribuído para acessar.',
        }),
        anApplicationWithTheSameNameAlreadyExists: MetaTranslation.field.template({
            num: 2,
            template: 'An application with the same name ({{ name }}) already exists.',
            text: 'Já existe uma aplicação com o mesmo nome ({{ name }}).',
            isWarning: true,
        }),
        theIntentClassifierNameDoesNotExistInTheApplicationVersion: MetaTranslation.field.template({
            num: 3,
            template: 'The intent classifier {{ name }} does not exist in the application version.',
            text: 'O classificador de intenção "{{ name }}" não existe na versão da aplicação.',
            isWarning: true,
        }),
    }),
});


export const kbErrors = defineTranslations({
    idSerializable: EAllSerializable.KBErrors,
    fields: {
        kbNotFound: MetaTranslation.field(1, 'Base não encontrada'), //
        connectionNotFound: MetaTranslation.field(2, 'Conexão com o luis não encontrada'), //
        youMustSaveBeforeTrain: MetaTranslation.field(3, 'Você deve salvar antes de treinar'), //
        trainTheBaseBeforeToSee: MetaTranslation.field(4, 'Treine a base para ver as utterances'), //
        templateDeletingError: MetaTranslation.field.template({
            num: 5,
            template: "New {{ language }} content can't be added while the existing {{ language }} content is being deleted. Try again in {{ time }} or consider creating a new message template.",
            text: 'Novo conteúdo em {{ language }} não pode ser adicionado enquanto o conteúdo existente em {{ language }} estiver sendo excluído. Tente novamente em {{ time }} ou considere criar um novo modelo de mensagem.'
        }),
        invalidParameter: MetaTranslation.field.template({
            num: 6,
            template: "Invalid parameter",
            text: 'Parâmetro inválido',
        }),
        messageTemplateLanguageIsBeingDeleted: MetaTranslation.field.template({
            num: 7,
            template: "Message template language is being deleted",
            text: 'O template está sendo deletado nesse idioma',
        }),
        intentWithSpacesBetweenName: MetaTranslation.field(8, 'Intenção com espaços entre o nome'), //
        templateNameDoesNotExistInLanguage: MetaTranslation.field.template({
            num: 9,
            template: "template name ({{ name }}) does not exist in {{ language }}",
            text: 'O template com o nome ({{ name }}) não existe em {{ language }}',
        }),
        templateNameDoesNotExistInLanguageGeneric: MetaTranslation.field.template({
            num: 10,
            template: "(#132001) Template name does not exist in the translation",
            text: '(#132001) O template não existe nesse idioma',
        }),

        kbIsNotTrained: MetaTranslation.field.template({
            num: 11,
            template: "The specified deployment cannot be found within the provided subscription.",
            text: 'A base não foi treinada',
        }),
    },
});

export const allKnowledgeBaseScreenOptionsTranslations = {
    ...getTranslationsFields(knowledgeBase),
    ...getTranslationsFields(knowledgeBase2),
    ...kbLuisTraininResultTypeTranslations,
    ...knowledgeBase3,
    ...kbStatusErrorTranslations,
    ...getTranslationsFields(knowledgeBaseAppConfig),
    ...prebuiltEntitiesNameTranslations,
    ...kbLuisErrorTranslations,
    ...prebuiltEntitiesDescriptionTranslations,
    ...prebuiltEntitiesExampleTranslations,
    ...kbErrors,
    ...kbOmniSenseMethodsTranslations,
};
