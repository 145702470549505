import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { isValidString } from '@colmeia/core/src/tools/utility';
import { detectSimpleChange } from 'app/model/client-utility';

@Directive({
    selector: '[clickableBadge]'
})
export class ClickableBadgeDirective implements OnInit, OnChanges, OnDestroy {

    @Input()
    clickableBadge: string;

    @Input()
    safeVars: string[] = [];

    @Input() show: boolean;

    @Output() badgeClick = new EventEmitter();

    clickFn = (event: Event) => {
        event.stopPropagation();
        this.renderer.setStyle(this.element, 'display', 'none');
        this.badgeClick.emit();
    };

    element: HTMLElement;

    constructor(
        private renderer: Renderer2,
        private hostElement: ElementRef<HTMLElement>,
    ) 
    { 
        const element = hostElement.nativeElement;
        element.style.position = 'relative';
    }

    @HostListener('mouseenter') onMouseEnter() {
        if (!this.show || !this.element) return; 
        this.renderer.setStyle(this.element, 'display', 'flex');
      }
    
    @HostListener('mouseleave') onMouseLeave() {
        if (!this.show || !this.element) return; 
        this.renderer.setStyle(this.element, 'display', 'none');
    }

    public ngOnInit() {
        this.parseTemplate();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if(detectSimpleChange(changes.clickableBadge) || detectSimpleChange(changes.show)) {
            this.parseTemplate();
        }
    }

    ngOnDestroy() {
        this.renderer.removeChild(this.hostElement.nativeElement, this.element);
        this.element.removeEventListener('click', this.clickFn);

    }

    private parseTemplate() {
        if (!isValidString(this.clickableBadge)) return;
        
        this.hostElement.nativeElement.children;

        this.element = this.renderer.createElement("mat-icon");
        this.element.addEventListener('click', this.clickFn);
        this.renderer.setStyle(this.element, 'display', 'none');
        this.renderer.addClass(this.element, 'clickable-badge');
        this.renderer.addClass(this.element, 'mat-icon');
        this.renderer.addClass(this.element, 'material-icons');
        this.renderer.setAttribute(this.element, 'aria-hidden', 'true');
        this.renderer.setProperty(this.element, 'innerHTML', this.clickableBadge);
        this.renderer.appendChild(this.hostElement.nativeElement, this.element);
    }
}
