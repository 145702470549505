
<!-- multiple action mode -->
<ng-container *ngIf="hasAnswerList && canShowCreateTab()">
  <mat-tab-group class="boxed-tabs boxed-dense" dynamicHeight>
    <mat-tab [label]="translations.list">
      <ng-container *ngTemplateOutlet="annotationsList"></ng-container>
    </mat-tab>
    <mat-tab [label]="translations.create">
      <ng-container *ngTemplateOutlet="annotationsUpsert"></ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-container>

<!-- single action mode -->
<ng-container *ngIf="!canShowCreateTab() && hasAnswerList">
  <ng-container *ngTemplateOutlet="annotationsList"></ng-container>
</ng-container>

<ng-container *ngIf="!hasAnswerList && canShowCreateTab()">
  <ng-container *ngTemplateOutlet="annotationsUpsert"></ng-container>
</ng-container>

<ng-template #annotationsList>
  <annotations-list
    *ngIf="schemas"
    [sourceId]="handler.serializableId"
    [schemas]="schemas"
    [selectedSchema]="handler.selectedSchema"
    [header]="translations.header"
    #list
  ></annotations-list>
</ng-template>

<ng-template #annotationsUpsert>
  <app-colmeia-window-top-bar *ngIf="isWindow"></app-colmeia-window-top-bar>
  <app-annotations-upsert
    [persist]="handler.persist"
    [viewType]="handler.viewType"
    (save)="annotationCreated($event)"
    [serializableId]="handler.serializableId"
    [schema]="handler.selectedSchema"
    [answer]="handler.answer"
    [engagement]="handler.engagement"
    [computedInfo]="computedInfo"
    [idGroup]="idGroup"
    [targetDatabaseId]="handler.targetDatabaseId"
    ></app-annotations-upsert>
</ng-template>
