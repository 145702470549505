
import { IGroupSecurityItemJSON } from './interaction-interfaces';
import { IMultimediaObjectJSON } from './multi-media-interfaces';

import { TGlobalUID } from '../business/constant';
import { IDeliveryTarget, TArrayID, TNserUIDArray } from '../core-constants/types';
import { TFeedbackArray } from '../interaction/feedback';
import { InteractionType } from '../interaction/interaction-type';
import { EGroupContainer } from '../shared-business-rules/group-container/group-container-config';
import { EDependencyEntryType, ENonSerializableObjectType } from '../shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IBasicUniversalInfo } from './aux-interfaces';
import { IDSSearchAccelerator, ISocialNetworkMembership } from './ds-interfaces';
import { EAttendantStatus } from './service-group-interfaces';
import { IInteractionLiveInfo } from './tracker-interfaces';
import { IdDep } from '../shared-business-rules/non-serializable-id/non-serializable-types';

export type IPermissionJSONArray = Array<IPermissionJSON>;
export type IGroupTypeJSONArray = Array<IGroupTypeJSON>;
export type IRoleJSONArray = Array<IRoleJSON>;
export type IGroupJSONArray = Array<IGroupJSON>;
export type IUniversalJSONArray = Array<IUniversalJSON>;
export type IParticipantJSONArray = Array<IParticipantJSON>;
export type IInteractionTypeJSONArray = Array<IInteractionTypeJSON>;
export type IGroupSecurityDomainJSONArray = Array<IGroupSecurityDomainJSON>;
export type IPaneJSONArray = Array<IPaneJSON>;
export type IGroupSecurityItemJSONArray = Array<IGroupSecurityItemJSON>;
export type IObjectTypeJSONArray = Array<IObjectTypeJSON>;
export type TArrayIDJSON = Array<TGlobalUID>;
export type TSearchArray = Array<string>;
export type TContainerArray = Array<IContainerObjectJSON>;
export type TAvatarJSONArray = Array<IAvatarJSON>;
export type TSecuritySettingsArray = Array<ISecuritySettingsJSON>;
export type TIErrorDomainArray = Array<IErrorDomainJSON>;
export type TGroupArrayJSON = Array<IGroupJSON>;
export type TPlayerArray = Array<TGlobalUID>;
export type TSecurityItemArrayJSON = Array<ISecurityItemJSON>;
export type TPlainGenealogy = Array<TGlobalUID>;
export type TGroupGenealogy = Array<IGroupParentJSON>;
export type TScreenGroupArray = Array<ISecurityScreenGroup>;
export type TFeedbackJSONArray = Array<IFeedbackJSON>;
export type TPlayerJSONArray = Array<IPlayerJSON>;


export type TGroupParticipantBridgeArray = Array<IGroupParticipanBridgeInfo>;

export interface IGroupParticipanBridgeInfo {
    idGroup: TGlobalUID;
    idParticipant: TGlobalUID;
    idAvatar: TGlobalUID;
    isProxy?: boolean;
};


export type TFieldTextArray = Array<ISerializableText>;
export type TDSSearchInfoInfoArray = Array<IDSSearchInfo>;
export type TIDSSearchInfoOnSearchEngineArray = Array<IDSSearchInfoOnSearchEngine>;



export interface IUniversalJSON {
    idObjectType: TGlobalUID;
    primaryID: TGlobalUID;
    clockTick: number,
    timeZone: number;
    businessID?: string;
    exluded?: boolean;
    idCycle?: number,
    multimediaObject?: IMultimediaObjectJSON;
    serializableTextArray: TFieldTextArray;
    visualPriority?: number,
    idMultimedia?: TGlobalUID,
    _updated?: boolean;
    _posGenerated?: boolean;
    DSAccelerator?: IDSSearchAccelerator;
    screenGroup?: TScreenGroupArray;
    necessaryPermission?: TGlobalUID;
    idSocialContext?: TGlobalUID;
    idAngularRoute?: string;
    originalClock?: number;
    hasAnnotation?: boolean;
    uClass?: EClassifierArray;

};


export interface ISerializableText {
    idValue?: string;
    idField: number;
    idLanguage: TGlobalUID,
    clockTick: number,
    text: string;
    seInfo?: IDSSearchInfo;
    isValid?: boolean;
    toBeDeletedSearchEngine?: boolean;
    textToken?: TSearchArray;
};



export interface ISearchClassificator {
    id: EUniversalClassifier;
    isVisible: boolean;
};

export type TSearchClassificator = Array<ISearchClassificator>;


export interface IDSSearchInfo {
    idSearchKey: TGlobalUID;
    primaryID: TGlobalUID;
    textToken: TSearchArray;
    idField: number;
    idValue: string;
    idAvatar: TGlobalUID;
    idParticipant: TGlobalUID;
    businessID: string;
    idOwner: TGlobalUID;
    genealogy: TGroupGenealogy;
    idObjectType: TGlobalUID;
    idGroup: TGlobalUID;
    isSearchVisible: boolean;
    avatarSN: TArrayID;
    text: string;
    searchC?: TSearchClassificator;
};


export interface IDSSearchEngineData {
    isAgentOnline?: boolean
    status?: EAttendantStatus
}
export interface IDSSearchInfoOnSearchEngine extends IDSSearchInfo, IDSSearchEngineData { }

export interface ISecurityScreenGroup {
    primaryID: TGlobalUID;
    screenGroup: string;
    params: string;
    idPermission: string;
    idMenu: string;
};



export interface IGroupParentJSON {
    idGroupParent: TGlobalUID,
    idGroupTypeParent: TGlobalUID,
    level: number;
    name?: string;
};


export type TDriverToFeedbacks = Map<InteractionType, TFeedbackArray>;
export type TDriverFeedbackArray = Array<IInteractionFeedbackDriver>;

export interface IInteractionFeedbackDriver {
    idDriverInteraction: TGlobalUID;
    priority: number;
};

export type EClassifierArray = Array<EUniversalClassifier>;

export enum EUniversalClassifier {
    normal = 'no',
    serviceClientUser = 'sc',
    agent = 'ag'
}



export interface IGroupContainerInfo {
    containerType: EGroupContainer;
    idServingGroups?: TArrayID;

}

export interface IGroupJSON extends IUniversalJSON {
    groupParent: IGroupJSON,
    groupType: TGlobalUID,
    idAvatar: TGlobalUID,
    idSecuritySettings: TGlobalUID,
    securitySettings?: ISecuritySettingsJSON,
    genealogy: TGroupGenealogy,
    depth: number,
    idParentEntityCreator: TGlobalUID;
    participantsAttachedToGroup?: TGlobalUID;
    securityAttachedToGroup?: TGlobalUID;
    DSidGroupParent?: TGlobalUID;
    lastInteractionJSON?: IInteractionLiveInfo,
    idProjection: TGlobalUID;
    sampleAvatar?: TAvatarJSONArray;
    driverFeedbacks?: TDriverFeedbackArray;
    auxFirstParticipant?: IParticipantJSON;
    bridgeInfo?: IGroupParticipanBridgeInfo;
    groupContainer?: EGroupContainer;
};



export interface ISubscriptionTypeJSON extends IUniversalJSON {
    follow: boolean,
    request: boolean,
    response: boolean,
    symetric: boolean,
    fromSubscriberObjectType: string,
    toSubjectObjectType: string,
    behaviourWhenRequest: string,
};

export interface IInteractionTypeJSON extends IUniversalJSON {
    idPane: TGlobalUID;
    visible: boolean;
    chained: boolean;
    tree: boolean;
    subscribable: boolean;
    arrayInteractinType: TArrayIDJSON;
    filterable: boolean;
    relatedTo: string;
    filteredByPackage: boolean;
    trackerInformation: boolean;
    mutuallYExclusiveFeedback: boolean;
    mutuallyExclusiveForInteraction: boolean;
    mutuallyExclusiveOutsideInteraction: boolean;
    statusFeedback: boolean;
    bigIcon: boolean;

};

export interface IDueDateTypeJSON extends IUniversalJSON {
    isFutureEvent: boolean;
};




export interface IFeedbackJSON extends IUniversalJSON {
    positive: boolean;
    points: number;
    offensive: boolean;
    idInteractionType: TGlobalUID;
    sendPersonal: boolean;
    sendAll: boolean;
    sendWorker: boolean;
    displayable: boolean;
};



export interface IObjectTypeJSON extends IUniversalJSON {
    tableName: string,
    isContainer: boolean,
    contextable: boolean,
    DSCacheable: boolean,
};


export interface IContainerObjectJSON extends IUniversalJSON {
    pkTable: TGlobalUID;
    idContainerObject: TGlobalUID;
};



export interface ILanguageJSON extends IUniversalJSON {
    readableName: string;
    googleCode: string;
};


export interface IPlayerJSON extends IUniversalJSON {
    email: string,
    personalGroup?: IGroupJSON,
    avatarOriginal?: IAvatarJSON,
    arrayAvatar?: TAvatarJSONArray,
    securitySettings?: ISecuritySettingsJSON,
    participantPersonalGroup?: IParticipantJSON,
};


export interface IAvatarParent {
    idAvatar: TGlobalUID,
    relatedTo: string,
    idParentEntity: TGlobalUID,

}

export type TAvatarParentArray = Array<IAvatarParent>;

export interface IExternalCreatorData {
    classifier: EUniversalClassifier;
    idSocialContext?: TGlobalUID;
    userToken: string;
    comm: IDeliveryTarget;
    idShakingHands?: string;
    isActiveCall1x1?: boolean;
    isTemporaryUser?: boolean;
};



export enum ETypeOfContact {
    email = 'ctEmail',
    landLine = 'ctLandLine',
    mobile = 'ctMobile',
    fbUserPageID = 'ctUserPageID',
    instagram = 'ctInst',
    colmeia = 'ctColmeia',
    telegram = 'telegram',
}


export interface ICheckedContact {
    type: ETypeOfContact;
    isDefault: boolean;
    address: string;
    isChecked: boolean;
}

export type TCheckedContactArray = Array<ICheckedContact>;

export interface IAvatarJSON extends IUniversalJSON {
    original: boolean;
    idParentEntity: TGlobalUID;
    relatedTo: string;
    securitySettings: ISecuritySettingsJSON
    idSecuritySettings: TGlobalUID;
    idPersonalGroup: TGlobalUID;
    participantOnPersonalGroup: IParticipantJSON;
    spokeResponsibles?: TAvatarParentArray;
    idProjection: TGlobalUID;
    contacts?: TCheckedContactArray;
    externalUniquezedNick?: string;
    isTemporaryUser?: boolean;
    userProfilesList?: IdDep<ENonSerializableObjectType.userApprovalProfile>[]
};


export interface IParticipantJSON extends IUniversalJSON {
    idGroup: TGlobalUID,
    avatar: IAvatarJSON,
    idRoles: TArrayID,
    proxy?: boolean;
    idAvatar: TGlobalUID;
    spokePersonalGroupParticipant?: boolean;
    firstSNParticipation?: ISocialNetworkMembership;
};

export interface IPermissionJSON extends IUniversalJSON {
    alias: string;
};

export interface IRoleJSON extends IUniversalJSON {
    alias: string,
    interactionsTypeAllowedArray: Array<TGlobalUID>,
    permissionsArray: Array<TGlobalUID>
};

export interface IGroupTypeJSON extends IUniversalJSON {
    supportGroup: boolean,
    root: boolean,
    firstParticipantRole: TGlobalUID,
    firstAdminRoleParticipant: TGlobalUID,
    functionalRoot: boolean,
    groupTypeSupportArray: Array<IGroupTypeJSON>,
    securitySettings: ISecuritySettingsJSON,
    idSecurityProfile: number,
    acceptParamenters: boolean,
    adminOnlyParticipants: boolean,
    allwaysSameParentParticipant: boolean,

};

export interface ISecuritySettingsJSON extends IUniversalJSON {
    securityAttachedIDGroup: TGlobalUID,
    securityItems: TSecurityItemArrayJSON;
};

export interface ISecurityItemJSON {
    idSecurityDomain: TGlobalUID;
};

export interface IErrorDomainJSON extends IUniversalJSON {
    errorType: TGlobalUID;
}

export interface IGroupSecuritySettingsJSON extends IUniversalJSON {
    idParticipant: TGlobalUID;
    securityAttachedIDGroup: TGlobalUID;
    groupSecuritySettingItemsArray?: Array<IGroupSecurityItemJSON>;
    securityItems?: TSecurityItemArrayJSON; // Apenas para conversão do cache em security Event Controller
};

export interface IGroupSecurityDomainJSON extends IUniversalJSON {
    idPane: TGlobalUID;
    relatedTo: string;
};


export interface IPaneJSON extends IUniversalJSON {
    muttualy_exclusive: boolean,
    mutuallyExclusiveWithOffOption: boolean,
};

export interface ISessionData {
    idSession: TGlobalUID,
    idPlayer: TGlobalUID,
    email: string,
    timezone: number,
    clockTick: number;
    socketID?: string
    cycle?: string;
    idSecSN?: string; // Guarda a SN no processo do socketManager
    lastChangePDW?: number;
};

export interface IUserErrorJSON {
    errorID: TGlobalUID,
    customMessage?: string;
    title?: string;
    shouldPrintCustomMessageOnly?: boolean;
};



export type TExtraFriendlyMessageArray = Array<IFriendlyExtraMessage>;


export interface IFriendlyExtraMessage {
    idSerializable: TGlobalUID;
    idField: number;
    friendlyAction: number;
    delegateToInfra: boolean;
    returnNowToClient: boolean;
    isError: boolean;
    additionalText?: string;
};


export interface IDeleteErrorDetail {
    idGroup?: TGlobalUID; // caso seja objectType !== interacaco, apenas o Lookup, que terá apenas avatares
    idInteractionType?: TGlobalUID;
    lookup: IBasicUniversalInfo;
};

export type TDeleteErrorDetailArray = Array<IDeleteErrorDetail>;

export interface IFriendDeleteExtraMessage extends IFriendlyExtraMessage {
    details: TDeleteErrorDetailArray;
};


export type TOpenDependencies = Array<IDelDependency>;

export interface IDelDependency {
    message: string;
    allDependencies: TArrayID;
    type: EDependencyEntryType;
}

export interface IFriendlyDeleteMessage {
    header: string;
    dependencies: TOpenDependencies;
}

export type TDeleteFriendlyMessageArray = Array<IFriendlyDeleteMessage>;

export interface IFriendlyMessageJSON {
    functionName: string,
    okState: boolean,
    friendlyErrorArray: Array<IUserErrorJSON>,
    tips: Array<string>
    sucessMessage?: TExtraFriendlyMessageArray;
    noFurtherActionInterrupt?: boolean;
    deleteDependencies: TDeleteFriendlyMessageArray;
    canAutomaticDelete?: boolean;
    isBussError?: boolean;
    isWarning?: boolean;
};


