import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { secToMS } from '@colmeia/core/src/time/time-utl';
import { TButtonAlertConfig } from '@colmeia/core/src/ui/ui.model';
import { SubscriptionGroup } from 'app/model/client-utility';
import { AttendanceBroadcastService, IBroadcastIslandCase, TBroadcastIslandCases } from 'app/services/attendance-broadcast.service';
import { AttendanceService } from 'app/services/attendance.service';
import { DateService } from 'app/services/date.service';
import { ButtonAlertComponent } from '../button-alert/button-alert.component';
import { horizontalAppear, verticalAppear } from '../dashboard/dashboard-animations';

const statusUpdateCacheInvalidationThresholdDuration = secToMS(2);


@Component({
    selector: 'app-attendance-broadcasting-catcher',
    templateUrl: './attendance-broadcasting-catcher.component.html',
    styleUrls: ['./attendance-broadcasting-catcher.component.scss'],
    animations: [
        verticalAppear,
        horizontalAppear,
    ]
})
export class AttendanceBroadcastingCatcherComponent implements OnInit, AfterViewInit, OnDestroy {
    private subscriptions = new SubscriptionGroup();

    @ViewChild("buttonAlert", { static: false })
    buttonAlert: ButtonAlertComponent;
    _initialButtonAlertState: TButtonAlertConfig;

    openedCasesAvailable: number = 0;

    constructor(
        private attSvc: AttendanceService,
        private broadcastSvc: AttendanceBroadcastService,
        private dateSvc: DateService,
    ) { }

    ngOnInit() {
        this.subscriptions.from(this.broadcastSvc.buttonControl$).subscribe((b) => {
            const { releaseButton, ...buttonAlertConfig } = b;
            if (releaseButton) {
                this.buttonAlert?.hide();
                this._initialButtonAlertState = undefined;
                return;
            }

            this._initialButtonAlertState = buttonAlertConfig;
            this.buttonAlert?.show(buttonAlertConfig);
        });
    }

    get isInBet() {
        return this.broadcastSvc.isInBet;
    }

    get initialButtonAlertState() {
        return this.broadcastSvc.isInBet ? this._initialButtonAlertState : undefined;
    }

    public toggleViewPreviousState() {
        this.broadcastSvc.toggleViewPreviousState();
    }

    getIsViewPreviousStateIcon(): string {
        return this.broadcastSvc.isViewPreviousState() ? 'keyboard_backspace' : 'visibility';
    }

    public hasPreviousState(): boolean {
        return this.broadcastSvc.hasPreviousState();
    }

    public getPreviousStateButtonLabel(): string {
        return this.broadcastSvc.isViewPreviousState() ? '' : `Ver último resultado`
    }

    public previousStateDate(): string {
        return this.dateSvc.getBestDate(new Date(this.broadcastSvc.getPreviousContestStart()));
    }

    public isViewPreviousState(): boolean {
        return this.broadcastSvc.isViewPreviousState();
    }

    islandTrackBy(idx: number, islandCase: IBroadcastIslandCase) {
        const island = this.attSvc.getIsland(islandCase.idIsland);
        const theme = this.getHighlightStatusTheme(island.idNS);
        const icon = this.getHighlightStatusIcon(island.idNS);
        return `${island.idNS}${islandCase.casesLength}${theme}${icon}`;
    }

    get loading(): boolean {
        return this.broadcastSvc.broadcastingLoading;
    }

    get attendancesIslandCountHash(): TBroadcastIslandCases {
        return this.broadcastSvc.getOpenedCases();
    }

    public getHighlightStatusTheme(idIsland: string): string | undefined {
        return this.broadcastSvc.currentHighlighIslands[idIsland]?.theme;
    }

    public getHighlightStatusIcon(idIsland: string): string | undefined {
        return this.broadcastSvc.currentHighlighIslands[idIsland]?.matIcon;
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public async catchOne($event: PointerEvent) {
        $event.preventDefault();

        this.broadcastSvc.requestAttAllocation();
    }

    public isOnline(): boolean {
        return this.attSvc.isOnline();
    }

    get isInCacheInvalidationAfterStatusUpdate(): boolean {
        const diff = Date.now() - this.attSvc.lastStatusClocktick;
        return diff > statusUpdateCacheInvalidationThresholdDuration;
    }

}
