import {TRoleArray} from "@colmeia/core/src/security/role";
import {TArrayAvatar, TArrayID, TExtendedParticipantArray, TGlobalUID} from "@colmeia/core/src/core-constants/types";
import {MainHandler} from "./main-handler";
import {clientConstants} from "../model/constants/client.constants";
import {IParticipantRoles} from "./participant-role-selector.handler";
import {ESearchAllowedType} from "@colmeia/core/src/shared-business-rules/search";
import { ISearchRemoteEnvConfig } from "app/services/search.service";
import { ISearchRequest, TMultipleCursor, TSortBy } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { AcceptPromise } from "@colmeia/core/src/tools/utility-types";
import { IAvatarWithParticipationClockTick } from "@colmeia/core/src/request-interfaces/response-interfaces";

    export enum EParticipantClickTypeMode {
    GotoTarget,
    Selectionable,
    SendToParent
};

export enum EParticipantSelectorAction {
    None,
    Remove,
    Role,
}
export type TParticipantSelectorActionList = Array<EParticipantSelectorAction>;

export interface IParticipantSelectorToRolesSelected {
    [participantId: string]: {roles: TRoleArray;}
}

export interface OnParticipantsSelectedReturn {
    isAdded: boolean
    isRemoved: boolean
  }

export interface IParticipantSelectorClient {
    onParticipantsSelected(
        selected: TExtendedParticipantArray,
        excluded: TExtendedParticipantArray):  AcceptPromise<void | OnParticipantsSelectedReturn>;
    onRolesSelectedChange?(participantToRoles: IParticipantSelectorToRolesSelected): void;
    loadMoreParticipants?: (amountItemsPerPage?: number, cursor?: string, sortBy?: TSortBy) => Promise<void>;
}

export interface IParticipantSelectorParameter {
    typesAllowed: ESearchAllowedType[];
    typesOfActionsOnEachElementSelected: TParticipantSelectorActionList;
    alreadySelectedList: TExtendedParticipantArray;
    clientCallback: IParticipantSelectorClient;
    operationMode: EParticipantClickTypeMode;
    maxNumberOfParticipants: number;
    isEditableTypesSelected: boolean;
    groupId?: TGlobalUID;
    groupParticipantsControl?: IGroupParticipantsControl;
    participantRoles?: IParticipantRoles;
    clear?: true;
    idAgentIsland?: string;
    searchRemoteEnvConfig?: ISearchRemoteEnvConfig;
    overwritePartipantsWhenFull?: boolean;
    cursor?: string
    usePagination?: boolean;
    sortBy?: TSortBy;
    idAvatarToClocktickBegin?: Record<string, IAvatarWithParticipationClockTick>;
    requestModifierCallback?: (request: ISearchRequest) => ISearchRequest
}

export enum ESelectionType {
    groupSelector,
}


export interface IGroupParticipantsControl {
    isNewGroup: boolean;
    selectionType: ESelectionType.groupSelector;
    currentParticipants: TExtendedParticipantArray;
    newParticipants: TExtendedParticipantArray;
    deletedParticipants: TExtendedParticipantArray;
}

export class ParticipantSelectorHandler extends MainHandler {
    static new(param: Partial<IParticipantSelectorParameter>): ParticipantSelectorHandler {
        return new ParticipantSelectorHandler({
            alreadySelectedList: [],
            typesOfActionsOnEachElementSelected: [],
            typesAllowed: [],
            clientCallback: null,
            operationMode: EParticipantClickTypeMode.GotoTarget,
            maxNumberOfParticipants: clientConstants.maxParticipantsPerGroup,
            isEditableTypesSelected: false,
            overwritePartipantsWhenFull: false,
            ...param
        })
    }

    private selectedDuringInstance: TExtendedParticipantArray;

    constructor(parameters: IParticipantSelectorParameter) {
        super(parameters)
        this.selectedDuringInstance = parameters.alreadySelectedList;
    }

    public getParameter(): IParticipantSelectorParameter {
        return (<IParticipantSelectorParameter>super.getComponentParameter());
    }

    getIdAgentIsland(): string | undefined {
        return this.getParameter().idAgentIsland;
    }

    isEditableTypesSelected(): boolean {
        return this.getParameter().isEditableTypesSelected
    }

    getMaxNumberOfParticipants(): number {
        return this.getParameter().maxNumberOfParticipants
    }

    getParticipants(): TExtendedParticipantArray {
        return this.selectedDuringInstance
            ? this.selectedDuringInstance
            : [];
    }

    getAllowedTypes(): ESearchAllowedType[] {
        return this.getParameter().typesAllowed;
    }

    getGroupParticipantsControl(): IGroupParticipantsControl {
        return this.getParameter().groupParticipantsControl
    }

    getParticipantRoles(): IParticipantRoles{
        return this.getParameter().participantRoles
    }

    getGroupId(): TGlobalUID {
        return this.getParameter().groupId
    }

    getCursor(): string | undefined {
        return this.getParameter().cursor
    }

    getSortBy(): TSortBy | undefined {
        return this.getParameter().sortBy
    }

    getAvatarClocktickBegin() {
        return this.getParameter().idAvatarToClocktickBegin;
    }

    parameters() {
        return this.getParameter()
    }

    hasRemove(): boolean {
        return this.getParameter().typesOfActionsOnEachElementSelected.includes(EParticipantSelectorAction.Remove)
    }

    hasRoles(): boolean {
        return this.getParameter().typesOfActionsOnEachElementSelected.includes(EParticipantSelectorAction.Role)
    }

    onRolesSelectedChange(participantToRoles: IParticipantSelectorToRolesSelected) {
        const clientInstance = (<IParticipantSelectorClient>this.getComponentParameter().clientCallback)
        if(clientInstance && clientInstance.onRolesSelectedChange) {
            clientInstance.onRolesSelectedChange(participantToRoles)
        }
    }

    
    async onParticipantsSelected(selected: TExtendedParticipantArray, excluded: TExtendedParticipantArray): Promise<void | OnParticipantsSelectedReturn> {

        this.selectedDuringInstance = selected;
        if (this.getParameter().clientCallback) {
            const items: void | OnParticipantsSelectedReturn = await this.getParameter().clientCallback.onParticipantsSelected(selected, excluded);
            return items
        }
    }

    getDefaultAllowedType(): TGlobalUID {
        let defaultType = null;

        if (this.getAllowedTypes().length) {
            defaultType = this.getAllowedTypes()[0];
        } else {
            throw new Error((new Error()).stack.slice(6));
        }

        return defaultType;
    }

    getOperationMode(): EParticipantClickTypeMode {
        return this.getParameter().operationMode
    }

    removeAllParticipants(): void {
        this.selectedDuringInstance = [];
    }

    shouldOverwritePartipantsWhenFull(): boolean {
        return this.getParameter().overwritePartipantsWhenFull;
    }
}


