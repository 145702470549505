import { TNserUIDArray } from "../../../../core-constants/types"
import { IBotMenuItemServer } from "../../../bot/bot-model"
import { ESubscribedTo } from "../../../BPM/common/common-bpm"
import { ICRMProcessingNodeEventServer } from "../../../crm/crm-services/crms-model-bpm-def"
import { ENonSerializableObjectType } from "../../../non-serializable-id/non-serializable-id-interfaces"
import { TReferencedObject } from "../../toolbar/config-toolbar.types"
import { CrmHostNode } from "./crm-host-node"

export class CrmProcessingHostNode extends CrmHostNode {
    botMenuItem: IBotMenuItemServer

    constructor(crmRoot: ICRMProcessingNodeEventServer) {
        super(crmRoot)
    }

    static create(crmRoot: ICRMProcessingNodeEventServer): CrmProcessingHostNode {
        return new CrmProcessingHostNode(crmRoot)
    }

    getBotItemId(): string | undefined {
        const procNode = <ICRMProcessingNodeEventServer>super.getNonSerializable()
        return procNode.listenTo.type === ESubscribedTo.botElement && procNode.listenTo.idSubscribedTo
    }

    isBotItemIdExists(): boolean {
        return this.getBotItemId() != null
    }

    addBotMenuItem(botMenuItem: IBotMenuItemServer) {
        const procNode = <ICRMProcessingNodeEventServer>super.getNonSerializable()
        procNode.listenTo = {
            type: ESubscribedTo.botElement,
            idSubscribedTo: botMenuItem.idNS
        }

        this.botMenuItem = botMenuItem
    }

    getBotMenuItem(): IBotMenuItemServer | undefined {
        return this.botMenuItem
    }

    getAllSubscribedItems(): TNserUIDArray {
        const procNode = <ICRMProcessingNodeEventServer>super.getNonSerializable()
        return [procNode.listenTo.idSubscribedTo]
    }

    setParentId(idParentNS: string): void {
        super.getNonSerializable().idParent = idParentNS
    }

    isParenthoodPredicateDrawable(targetType: TReferencedObject): boolean {
        return true
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        return nodeTypeToDrag === ENonSerializableObjectType.crmServiceConfigAgentEvent
    }
}