<div class="wrapper" [style.display]="isMinimized ? 'none' : 'initial'">
  <div class="cb-window">
    <app-macro-list
      [handler]="getMacroHandler()"
      *ngIf="showMacroList"
    ></app-macro-list>

    <chat-search-participant
      #searchParticipant
      *ngIf="showSearchCitationParticipant"
      [handler]="chatSearchHandler">
    </chat-search-participant>

    <div class="btns--window-ctrl" [style.display]="showAudioRecorder && !showAttachment ? 'none' : 'flex'">
      <button class="cb-window__close" *ngIf="canMinimize()" (click)="onMinimizeClicked()">
        <mat-icon>minimize</mat-icon>
      </button>
      <button class="cb-window__close" *ngIf="canClose()" (click)="onUserClosed()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="cb-window__container">
      <div class="cb-window__content" *ngIf="showAudioRecorder">
        <chat-media-recorder [recorderHandler]="getAudioRecorderHandler()"></chat-media-recorder>
      </div>
      <div class="cb-window__content" *ngIf="showAttachment">
        <chat-attachment [handler]="getAttachmentHandler()"></chat-attachment>
      </div>
      <div class="cb-window__content" [ngClass]="canShow() ? 'fadein' : 'fadeout'">
        <div class="interact-inner">
          <div class="interact-content">
            <ng-container [ngComponentOutlet]="outletComponent" *ngIf="showOutlet"></ng-container>
          </div>
        </div>
      </div>
    </div>

    <app-cited-participants-list
      *ngIf="showCitedParticipants"
      [handler]="citedParticipantsListHandler">
    </app-cited-participants-list>

  </div>
</div>
<div class="maximize" *ngIf="isValidWindow()" [class.visible]="isMinimized">
  <button class="maximize--btn" (click)="onMinimizeClicked()">
    <i class="material-icons i--md">arrow_drop_up</i>
  </button>
</div>
