import { ENextGenBotElementType, IBotMenuContainerServer, INextGenBotServer } from "../../../bot/bot-model";
import { EBPMType } from '../../../BPM/bpm-model';
import { IBPMMarketingAcknowledgeServer, IBPMMarketingActionServer, IBPMMarketingEndServer, IBPMMarketingListenerServer, IBPMMarketingPauseServer, IBPMMarketingRootServer, TBpmMarketingNserServer } from '../../../BPM/marketing/bpm-marketing.model';
import { ICRMAgentEventServer, ICRMNotificationServer, ICRMProcessingNodeEventServer, ICRMRootServer } from "../../../crm/crm-services/crms-model-bpm-def";
import { IFormTransactionServer, INLPTransactionServer, ITransactionServer } from '../../../knowledge-base/bot-transaction/bot-transaction';
import { ENonSerializableObjectType } from "../../../non-serializable-id/non-serializable-id-interfaces";
import { TBPMBOTElements, TBPMCRMElements, TBPMCRMElementTypes, TBPMMKTElements, TBPMNestedAIElements } from "../../toolbar/config-toolbar.types";
import { BotTransactionHostNode } from "../bot/bot-transaction.host";
import { BotRootHostNode } from "../bot/bot.root.host";
import { MenuContainerHostNode } from "../bot/menu-container-host.node";
import { MenuItemHostNode } from "../bot/menu-item-host-node";
import { CRMContentGeneratorHost } from '../crm/crm-content-generator.host';
import { CrmNotificationHost } from '../crm/crm-notification.host';
import { CrmProcessingHostNode } from '../crm/crm-processing-host-node';
import { CrmRootHost } from '../crm/crm-root.host';
import { CrmUserEventHost } from "../crm/crm-user-event.host";
import { MktActionHost } from '../mkt/mkt-action.host';
import { MktAckHost } from '../mkt/mkt-akc.host';
import { MktBotElementHost } from "../mkt/mkt-bot-element.host";
import { MktContentGeneratorHost } from '../mkt/mkt-content-generator.host';
import { MktEndHost } from "../mkt/mkt-end.host";
import { MktListenerHost } from "../mkt/mkt-listener.host";
import { MktPauseHost } from '../mkt/mkt-pause.host';
import { MktRootHost } from '../mkt/mkt-root.host';
import { NestedAIColmeiaFormsHost } from '../nested-ai/nested-ai-colmeia-forms-node.host';
import { NestedAIMenuContainerHostNode } from '../nested-ai/nested-ai-menu-container-node.host';
import { NestedAIRootHost } from '../nested-ai/nested-ai-root.host';
import { NSGenericHost } from '../ns/ns.host';
import { IBOTHostFactoryInfo, ICRMHostFactoryInfo, IHostNodeCreationFactoryInfo, IMKTHostFactoryInfo, INestedAIHostFactoryInfo } from '../ns/ns.host.types';



export class HostNodeFactory {
    constructor() { }

    public static createCRM(info: ICRMHostFactoryInfo): TBPMCRMElements {
        switch (info.hostType) {
            case ENonSerializableObjectType.crmServiceConfigRoot:
                return CrmRootHost.create(<ICRMRootServer>info.ns)
            case ENonSerializableObjectType.crmServiceConfigAgentEvent:
                return CrmUserEventHost.create(<ICRMAgentEventServer>info.ns)
            case ENonSerializableObjectType.crmServiceConfigNotification:
                return CrmNotificationHost.create(<ICRMNotificationServer>info.ns)
            case ENonSerializableObjectType.crmServiceConfigProcessingNode:
                return CrmProcessingHostNode.create(<ICRMProcessingNodeEventServer>info.ns)
            case ENonSerializableObjectType.contentGenerator:
                return CRMContentGeneratorHost.create(<ITransactionServer>info.ns)
        }
    }

    public static createBOT(info: IBOTHostFactoryInfo): TBPMBOTElements {
        switch (info.hostType) {
            case ENextGenBotElementType.menuContainer:
                return MenuContainerHostNode.create(info)
            case ENextGenBotElementType.botMenuItem:
                return MenuItemHostNode.create(info)
            case ENextGenBotElementType.root:
                return BotRootHostNode.factory(info)
            case ENextGenBotElementType.formTransaction:
            case ENextGenBotElementType.nlpTransaction:
                return BotTransactionHostNode.factory(info)
        }
    }

    public static createMKT(info: IMKTHostFactoryInfo): TBPMMKTElements {
        switch (info.hostType) {
            case ENonSerializableObjectType.bpmMarketingRoot:
                return MktRootHost.create(info.ns as IBPMMarketingRootServer);
            case ENonSerializableObjectType.bpmMarketingAction:
                return MktActionHost.create(info.ns as IBPMMarketingActionServer);
            case ENonSerializableObjectType.bpmMarketingPause:
                return MktPauseHost.create(info.ns as IBPMMarketingPauseServer);
            case ENonSerializableObjectType.bpmMarketingAcknowledge:
                return MktAckHost.create(info.ns as IBPMMarketingAcknowledgeServer);
            case ENonSerializableObjectType.contentGenerator:
                return MktContentGeneratorHost.create(info.ns as ITransactionServer);
            case ENonSerializableObjectType.bpmMarketingListener:
                return MktListenerHost.create(info.ns as IBPMMarketingListenerServer);
            case ENonSerializableObjectType.bpmMarketingEnd:
                return MktEndHost.create(info.ns as IBPMMarketingEndServer);
            case ENonSerializableObjectType.bot:
                return MktBotElementHost.create(info.ns as INextGenBotServer);
            default:
                throw new Error(`Missing: hostType "${info.hostType}" HostNodeFactory`);
        }
    }

    public static createNestedAI(info: INestedAIHostFactoryInfo): TBPMNestedAIElements {
        switch (info.hostType) {
            case ENonSerializableObjectType.contentGenerator:
                const ns = info.ns as ITransactionServer;
                if (ns.botLevel === ENextGenBotElementType.formTransaction)
                    return NestedAIColmeiaFormsHost.create(ns as IFormTransactionServer);
                return NestedAIRootHost.create(ns as INLPTransactionServer);
            case ENonSerializableObjectType.bot:
                return NestedAIMenuContainerHostNode.create(info.ns as IBotMenuContainerServer)
            default:
                throw new Error(`Missing: hostType "${info.hostType}" HostNodeFactory`);
        }
    }

    public static create(info: IHostNodeCreationFactoryInfo): NSGenericHost {
        switch (info.bpmType) {
            case EBPMType.crm:
                return HostNodeFactory.createCRM({
                    hostType: <TBPMCRMElementTypes>info.ns.nsType,
                    ns: info.ns
                });
            case EBPMType.bot:
                return HostNodeFactory.createBOT({
                    hostType: (info.ns as INextGenBotServer).botLevel,
                    ns: info.ns
                });
            case EBPMType.marketing:
                return HostNodeFactory.createMKT({
                    hostType: (info.ns as TBpmMarketingNserServer).nsType,
                    ns: info.ns,
                });
            case EBPMType.nestedAI:
                return HostNodeFactory.createNestedAI({
                    hostType: (info.ns as INLPTransactionServer).nsType,
                    ns: info.ns,
                });
            default:
                throw new Error(`Missing: bpmType "${info.bpmType}" HostNodeFactory`);
        }
    }
}
