import { NSGenericHost } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/ns/ns.host';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ESubscribedTo } from '../../../BPM/common/common-bpm';
import { ICRMBridgeServer, ICRMRootServer } from '../../../crm/crm-services/crms-model-bpm-def';
import { IGraphConnectionData, TIGraphConnectionDataArray } from '../../../graph/essential/graph-types';
import { TBPMCRMConnectionTypes, TBPMCRMElementTypes, TBPMCRMNser, TGraphActionContainer, TReferencedObject } from '../../toolbar/config-toolbar.types';
import { INodeDeletedConnection } from '../ns/ns.host.types';


export class CrmHostNode extends NSGenericHost {
    constructor(crmNser: TBPMCRMNser) {
        super(crmNser);
    }

    static create(crmNser: TBPMCRMNser): CrmHostNode {
        return new CrmHostNode(crmNser)
    }

    isNodeCreatable(): boolean {
        const nsType = this.getNonSerializable().nsType
        return nsType == ENonSerializableObjectType.crmServiceConfigRoot
            || nsType == ENonSerializableObjectType.crmServiceConfigAgentEvent
            || nsType == ENonSerializableObjectType.crmServiceConfigNotification
            || nsType == ENonSerializableObjectType.crmServiceConfigProcessingNode
            || nsType == ENonSerializableObjectType.contentGenerator
    }

    isNeighborDrawable(neighborType: TBPMCRMElementTypes): boolean {
        return neighborType == ENonSerializableObjectType.contentGenerator
    }

    getNonSerializable(): TBPMCRMNser {
        return <TBPMCRMNser>super.getNonSerializable()
    }

    getHostedType(): TBPMCRMElementTypes {
        return <TBPMCRMElementTypes>super.getNonSerializable().nsType
    }

    isRoot(): boolean {
        return this.getHostedType() == ENonSerializableObjectType.crmServiceConfigRoot
    }

    isContentGenerator(): boolean {
        return this.getHostedType() == ENonSerializableObjectType.contentGenerator
    }

    isActionPredicateDrawable(targetType: TReferencedObject, action: TReferencedObject): boolean {
        return true
    }

    deleteConnectionTo(targetToBeDeleted: CrmHostNode, connectionType: TBPMCRMElementTypes): INodeDeletedConnection {
        if (targetToBeDeleted.isContentGenerator()) {
            const crmNode: ICRMBridgeServer = (<ICRMBridgeServer>this.getNonSerializable())
            crmNode.listenTo = {
                idSubscribedTo: undefined,
                type: undefined
            }
            return { deletedConnection: true }
        }
        return { deletedConnection: false }
    }

    public mustUpdateConnections(connectionType: ESubscribedTo): boolean {
        switch (connectionType) {
            case ESubscribedTo.contentGenerator:
            case ESubscribedTo.attRegistry:
                return true;
            default:
                return false;
        };
    }

    public getConnectionTargetData(): TIGraphConnectionDataArray {
        const crmNode: TBPMCRMNser = (super.getNonSerializable() as TBPMCRMNser)

        return [{
            targetHostId: (<ICRMBridgeServer>crmNode).listenTo?.idSubscribedTo,
            connectionType: (<ICRMBridgeServer>crmNode).listenTo?.type,
            subElementId: undefined
        }]
    }

    public getActionContainer(): TGraphActionContainer {
        return (super.getNonSerializable() as ICRMRootServer)
    }

    getEdgeTypeToConnect(neighborType: TBPMCRMElementTypes): TBPMCRMConnectionTypes {
        return undefined;
    }

    public isParenthoodPredicateDrawable(type: TReferencedObject): boolean {
        return true;
    }
}