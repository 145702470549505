import { FriendlyMessage, isInEnum, isValidString } from "../../../../tools/barrel-tools";
import { nonNullable } from "../../../../tools/type-utils";
import { EBPMAction, IBPMActionAsset } from "../../../BPM/bpm-action-model";
import { isValidBPMEvaluators } from "../../../BPM/bpm-functions";
import { ESubscribedTo } from "../../../BPM/common/common-bpm";
import { isValidContent } from "../../../bot/asset-functions";
import { IBotActionCRMTicketCreate } from "../../../bot/bot-action-model";
import { EBotActionType } from "../../../bot/new-bot-action";
import { gTranslations } from "../../../const-text/translations";
import { ECloseState, EServiceManagementPhases, ESeverityLevel, ETicketState } from "../../../crm/crm-services/crm-canonical-model";
import { isCRMConfigParent } from "../../../crm/crm-services/crm-config-functions";
import { ICloseStateConfigItemClient, IPhaseConfigItemClient, ISeverityConfigItemClient, IStateConfigItemClient, ISupportLevelConfigItemServer, ISupportUrgencyConfigItemServer, TCRMConfigNSTypes } from "../../../crm/crm-services/crm-config-interfaces";
import { ICRMAgentEventServer, ICRMNotificationServer, ICRMProcessingNodeEventServer, ICRMRootServer, TCRMBPMConfigs } from "../../../crm/crm-services/crms-model-bpm-def";
import { ENonSerializableObjectType, INonSerializable } from "../../non-serializable-id-interfaces";
import { NsTypeToInterface } from "../../non-serializable-interface-mapper";
import { nserValidationNOOP } from "../helpers";
import { TNserValidator } from "../validate-nser";
import { defaultNserValidator } from "./nser";

interface TCRMConfigValidator<Nser extends INonSerializable = INonSerializable> extends TNserValidator {
    /**
     * Validate CRM
     */
    (nser: Nser, friendly: FriendlyMessage): FriendlyMessage
} 

const validators: { [key in TCRMConfigNSTypes]: TCRMConfigValidator<NsTypeToInterface[key]> } = {
    [ENonSerializableObjectType.crmConfigPackage]: nserValidationNOOP,
    [ENonSerializableObjectType.crmHeaderCloseState]: nserValidationNOOP,
    [ENonSerializableObjectType.crmItemCloseState]: (nser: ICloseStateConfigItemClient, friendly: FriendlyMessage) => {
        if (!isInEnum(ECloseState, nser.closeState)) {
            friendly.addReturnTranslationResponse(gTranslations.crm.closeState)
        }
        return friendly;
    },
    [ENonSerializableObjectType.crmHeaderPhase]: nserValidationNOOP,
    [ENonSerializableObjectType.crmItemPhase]: (nser: IPhaseConfigItemClient, friendly: FriendlyMessage) => {
        if (!isInEnum(EServiceManagementPhases, nser.phase)) {
            friendly.addReturnTranslationResponse(gTranslations.crm.phase)
        }
        return friendly;
    },
    [ENonSerializableObjectType.crmHeaderSeverity]: nserValidationNOOP,
    [ENonSerializableObjectType.crmItemSeverity]: (nser: ISeverityConfigItemClient, friendly: FriendlyMessage) => {
        if (!isInEnum(ESeverityLevel, nser.severity)) {
            friendly.addReturnTranslationResponse(gTranslations.crm.severity)
        }
        return friendly;
    },
    [ENonSerializableObjectType.crmHeaderState]: nserValidationNOOP,
    [ENonSerializableObjectType.crmItemState]: (nser: IStateConfigItemClient, friendly: FriendlyMessage) => {
        if (!isInEnum(ETicketState, nser.state)) {
            friendly.addReturnTranslationResponse(gTranslations.crm.state)
        }
        return friendly;
    },
    [ENonSerializableObjectType.crmHeaderSupportLevel]: nserValidationNOOP,
    [ENonSerializableObjectType.crmItemSupportLevel]: (nser: ISupportLevelConfigItemServer, friendly: FriendlyMessage) => {
        // if (!isInEnum(ETicketState, nser.state)) {
        //     friendly.addReturnTranslationResponse(gTranslations.crm.state)
        // }
        return friendly;
    },
    [ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel]: nserValidationNOOP,
    [ENonSerializableObjectType.crmItemUrgencyLevel]: (nser: ISupportUrgencyConfigItemServer, friendly: FriendlyMessage) => {
        // if (!isInEnum(ETicketState, nser.state)) {
        //     friendly.addReturnTranslationResponse(gTranslations.crm.state)
        // }
        return friendly;
    },
}

const defaultCRMItemValidator: TCRMConfigValidator<INonSerializable> = (nser: INonSerializable, friendly: FriendlyMessage): FriendlyMessage => {
    if (!nser.idParent) {
        friendly.addReturnTranslationResponse(gTranslations.errors.noParent);
    }

    return friendly;
}

const defaultCRMPackageValidator: TCRMConfigValidator<INonSerializable> = (nser: INonSerializable, friendly: FriendlyMessage): FriendlyMessage => {
    return friendly;
}

export const crmConfigValidator = (nsType: TCRMConfigNSTypes): TCRMConfigValidator => (nser: INonSerializable, friendly: FriendlyMessage) => {
    defaultNserValidator(nser as INonSerializable, friendly);
    
    if (isCRMConfigParent(nsType)) {
        defaultCRMPackageValidator(nser, friendly)
    } else {
        defaultCRMItemValidator(nser, friendly)
    }

    return nonNullable(validators[nsType])(nser, friendly);
}


function defaultCRMBpmConfigValidator(nser: TCRMBPMConfigs, friendly: FriendlyMessage) { }

function validateListenerConfig(nser: TCRMBPMConfigs, friendly: FriendlyMessage) {
    if (!isInEnum(ESubscribedTo, nser.listenTo.type) || !isValidString(nser.listenTo?.idSubscribedTo)) {
        friendly.addReturnGenericErrorMessage("Configuração de acionador incorreta.");
    }

    // if (!isValidBPMEvaluators(nser.listenConditions)) {
    //     friendly.addReturnGenericErrorMessage("Configuração de condições do acionador incorreta.");
    // }
}

export const crmRootBPMValidator = (nser: ICRMRootServer, friendly: FriendlyMessage): FriendlyMessage => {
    defaultNserValidator(nser, friendly);
    defaultCRMBpmConfigValidator(nser, friendly);

    if (!isValidString(nser.idConfigurationPackage)) {
        friendly.addReturnGenericErrorMessage("Selecione a configuração de projeto.");
    }

    validateListenerConfig(nser, friendly);

    if (nser.useCustomTitleGen && !isValidContent(nser.titleTemplate)) {
        friendly.addReturnGenericErrorMessage("Estrutura do título não configurada.");
    }

    return friendly;
}

export const crmUserEventBPMValidator = (nser: ICRMAgentEventServer, friendly: FriendlyMessage): FriendlyMessage => {
    defaultNserValidator(nser, friendly);
    defaultCRMBpmConfigValidator(nser, friendly);
    validateListenerConfig(nser, friendly);
    validateCrmActionConditions(nser, friendly);
    // validateBotAction(nser, friendly);

    return friendly;
}

export function validateCrmActionConditions(nser: ICRMAgentEventServer, friendly: FriendlyMessage) {
    if (!isValidBPMEvaluators(nser.condFormsAction)) {
        friendly.addReturnGenericErrorMessage("Configuração de ações.");
    }

    for (const condition of nser.condFormsAction) {

        if (condition.action?.bpmAction === EBPMAction.action) {
            const action = <IBPMActionAsset>condition.action;

            if (action.action.type !== EBotActionType.upsertTicket) continue;

            const transfer = (action.action as IBotActionCRMTicketCreate).transfer

            if (transfer.enabled && !isValidString(transfer.idIsland)) {
                friendly.addReturnGenericErrorMessage("Configuração de alocação sem ilha selecionada.");
            }
        };

    }

}

export const crmNotificationBPMValidator = (nser: ICRMNotificationServer, friendly: FriendlyMessage): FriendlyMessage => {
    defaultNserValidator(nser, friendly);
    defaultCRMBpmConfigValidator(nser, friendly);

    return friendly;
}

export const crmProcessNodeBPMValidator = (nser: ICRMProcessingNodeEventServer, friendly: FriendlyMessage): FriendlyMessage => {
    defaultNserValidator(nser, friendly);
    defaultCRMBpmConfigValidator(nser, friendly);

    return friendly;
}