import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { pickTranslations } from '@colmeia/core/src/shared-business-rules/const-text/all-serializables';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { EMigrationOperationType, ENonSerializableObjectType, INonSerializableBatchSaveDescriptor, INSerDescription } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import {
    EDeployedProdStatus,
    IProdPatchModelServer
} from "@colmeia/core/src/shared-business-rules/production-deploy/prod-deploy-model";
import { EBatchSaveType, IBatchSaveToDeployNser } from '@colmeia/core/src/shared-business-rules/production-deploy/prod-deploy-req-res';
import { getCurrentPatchStatus } from "@colmeia/core/src/tools/patch";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { RootComponent } from 'app/components/foundation/root/root.component';
import { INSPickerHandlerClientCallback, NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { GlobalWarningService } from 'app/services/global-warning.service';
import { ProdDeployService } from "../../../services/prod-deploy.service";
import { IColmeiaDialogComponentData } from "../../dialogs/dialog/dialog.component";

type ISaveTypeText = {
    [key in EBatchSaveType]: string
}




@Component({
    selector: 'app-add-to-prod-dialog',
    templateUrl: './add-to-prod-dialog.component.html',
    styleUrls: ['./add-to-prod-dialog.component.scss']
})
export class AddToProdDialogComponent extends RootComponent<
    'addTheFollowingItemsToAPatch' |
    'addToTheDeploysList' |
    'chooseADeploy' |
    'create' |
    'update' |
    'delete' |
    'operation'
> implements OnInit, AfterViewInit {
    descriptions: IBatchSaveToDeployNser[];
    deployList: IProdPatchModelServer[] = [];
    notes: string;
    deployTo: string;
    saveType: EBatchSaveType;
    allowedSaveTypes: EBatchSaveType[] = [EBatchSaveType.hierarchical, EBatchSaveType.normal, EBatchSaveType.recursive]
    saveTypeEnum: ISaveTypeText = {
        [EBatchSaveType.normal]: 'Normal',
        [EBatchSaveType.recursive]: "Recursivo",
        [EBatchSaveType.hierarchical]: "Hierarquico",
    }

    @ViewChild('itensList', { static: false }) itensList: ElementRef;

    constructor(
        @Inject(MAT_DIALOG_DATA) private dataToComponent: IColmeiaDialogComponentData<INonSerializableBatchSaveDescriptor>,
        private service: ProdDeployService,
        private ref: MatDialogRef<AddToProdDialogComponent>,
        private dashboardSvc: DashBoardService,
        private warning: GlobalWarningService,
    ) {
        super({
            ...pickTranslations(gTranslations.fragments, [
                'addTheFollowingItemsToAPatch',
                'addToTheDeploysList',
                'chooseADeploy',
                'create',
                'update',
                'delete',
                'operation'
            ])
        });
        const data: INonSerializableBatchSaveDescriptor = dataToComponent.getParamsToChildComponent();
        console.log({ data });

        this.descriptions = data.descriptors;
        // console.log({
        //     descriptionsAtAddToProd: this.descriptions
        // }); //<< Log left

        this.saveType = data.type || EBatchSaveType.hierarchical;
        this.allowedSaveTypes = data.allowedTypes || this.allowedSaveTypes;
    }


    public async ngOnInit() {
        this.init();
    }

    ngAfterViewInit() {
        this.checkItensListOverFlowStyle();
    }

    public init(): void {
        this.initDeployListHandler();
    }

    showTypeKeys(): string[] {
        return Object.keys(this.saveTypeEnum);
    }


    public deployListHandler: NSPickerHandler;
    public initDeployListHandler(): void {
        this.deployListHandler = this.dashboardSvc.easyCreateNSPickerHandler({
            selectedId: undefined,
            title: this.translations.chooseADeploy.value,
            nsType: ENonSerializableObjectType.patchHeader,
            clientCallback: <INSPickerHandlerClientCallback>{ onSaveNSCallback: (ns: IProdPatchModelServer) => this.onChangeDeployTo(ns) },
            useDemandedTag: false,
        }, { filter: (patch: IProdPatchModelServer) => getCurrentPatchStatus(patch).status === EDeployedProdStatus.notDeployed });
    }

    public onChangeDeployTo(ns: IProdPatchModelServer): void {
        this.deployTo = ns?.idNS;
    }

    get canSubmit(): boolean {
        return isValidRef(this.deployTo);
    }

    async submit(): Promise<void> {
        if (this.hasDeleteOperation()) {
            const tobeDeleted = this.descriptions.filter(item => item.operationType === EMigrationOperationType.delete)
                .map(item => item.nName);
            const confirm = await this.warning.promptYesOrNo(
                'Os itens abaixo serão DELETADOS no ambiente alvo, você deseja prosseguir com essa operação?',
                tobeDeleted.join('\n'),
                { icon: 'delete', color: 'red' }
            );
            if (!confirm) return;
        }

        console.log({ descriptions: this.descriptions });
        await this.service.addNSerializablesToDeploy(this.deployTo, this.descriptions, this.saveType);
        this.ref.close();
    }

    hasDeleteOperation() {
        return this.descriptions.some(item => item.operationType === EMigrationOperationType.delete);
    }

    hasOperation() {
        return this.descriptions.some(item => !!item.operationType);
    }

    getOperationTranslation(op: INSerDescription) {
        return op.operationType ? this.translations[op.operationType] : ''
    }

    checkItensListOverFlowStyle() {
        const nativeListBox = this.itensList.nativeElement;
        if (isValidRef(nativeListBox) && nativeListBox.scrollHeight > nativeListBox.clientHeight) {
            nativeListBox.style.boxShadow = 'inset  0px -10px 15px -15px rgba(0, 0, 0, 0.1)';
        }
    }
}
