import { CrmHostNode } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/crm/crm-host-node';
import { ICRMNotificationServer } from '../../../crm/crm-services/crms-model-bpm-def';
import { ENonSerializableObjectType } from '../../../non-serializable-id/non-serializable-id-interfaces';
import { TReferencedObject } from '../../toolbar/config-toolbar.types';

export class CrmNotificationHost extends CrmHostNode {

    constructor(private crmNotification: ICRMNotificationServer) {
        super(crmNotification)
    }

    static create(crmNotification: ICRMNotificationServer): CrmNotificationHost {
        return new CrmNotificationHost(crmNotification)
    }

    public isParenthoodPredicateDrawable(target: TReferencedObject): boolean {
        return target === ENonSerializableObjectType.crmServiceConfigAgentEvent
    }

}
