import { Injectable } from "@angular/core";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { IQuickDrawAllocationRequest, IQuickDrawAllocationResponse, TBroadcastIslandControl } from "@colmeia/core/src/shared-business-rules/attendent-service-pack/attendent-sp-req-resp";
import { EBroadcastContestStage, TBroadcastControlButton, TBroadcastHighlightedIslands, TBroadcastHighlightedIslandsArray } from "@colmeia/core/src/shared-business-rules/new-notifications/new-notification-model";
import { secToMS } from "@colmeia/core/src/time/time-utl";
import { getClock, isValidNumber, isValidObject, isValidRef, isValidString, typedClone } from "@colmeia/core/src/tools/utility";
import { BehaviorSubject, interval, merge, Subject } from "rxjs";
import { AttendanceService } from "./attendance.service";
import { GlobalWarningService } from "./global-warning.service";
import { NewNotificationsService } from "./new-notifications.service";
import { ServerCommunicationService } from "./server-communication.service";
import { EAppAlertTypes, ISnackSettingsMessage, SnackMessageService, SnackVerticalPosition, TSnackBarRefTypes } from "./snack-bar";

interface IHighlightIslandsState {
    previous: {
        started: number;
        state: TBroadcastHighlightedIslands;
    };
    current: {
        state: TBroadcastHighlightedIslands;
    };
}

interface IOpendedCasesHistory {
    previous: TBroadcastIslandCases;
    current: TBroadcastIslandCases;
    next: TBroadcastIslandCases;
}

export interface IBroadcastIslandCase {
    idIsland: string,
    name: string,
    casesLength: number
}

export type TBroadcastIslandCases = {
    totalCases: number;
    islandCases: Array<IBroadcastIslandCase>
}

export type TAttBroadcastLoading = { broadcastingLoading: boolean };

const MAX_TIME_WAITING_CONTEST = secToMS(25);

function initialIslandCases(): TBroadcastIslandCases {
    return {
        totalCases: 0,
        islandCases: [],
    }
}

enum ESnackMessages {
    ContestTimeExeced = 'Foi detectado que a sua solicitação excedeu o tempo limite de espera.',
    AlreadyAllocated = 'Você foi alocado, aguarde o atendimento chegar.',
    InQueue = 'Você já está na disputa, aguarde',
}

@Injectable({
    providedIn: 'root'
})
export class AttendanceBroadcastService {
    private openedCasesState: IOpendedCasesHistory = {
        previous: undefined,
        current: undefined,
        next: undefined,
    };

    private _broadcastingLoading: boolean = false;
    get broadcastingLoading(): boolean {
        return this._broadcastingLoading;
    }

    private _buttonControl$ = new BehaviorSubject<TBroadcastControlButton>({});
    buttonControl$ = this._buttonControl$.asObservable();

    private islandCases: TBroadcastIslandCases = initialIslandCases();
    public currentContestIslandHighlights: TBroadcastHighlightedIslandsArray = [];
    private highIslandsState: IHighlightIslandsState = {
        previous: {
            started: 0,
            state: {}
        },
        current: {
            state: {}
        },
    }

    private _isInBet: boolean = false;
    get isInBet(): boolean { return this._isInBet }
    private waitingAllocationConvId: string;
    private highlightEreaseTimeout: NodeJS.Timeout;
    private _isViewPreviousState: boolean = false;
    private lastBetTS!: number;
    private lastSnackMessage: string;
    private snackRef: TSnackBarRefTypes;
    private requesting: boolean = false;
    private ereasedContest: Subject<void> = new Subject();

    constructor(
        private attSvc: AttendanceService,
        private notificationSvc: NewNotificationsService,
        private api: ServerCommunicationService,
        private globalWarnSvc: GlobalWarningService,
        private snackMsgSvc: SnackMessageService,
    ) {
        this.registrySubscribers();
    }

    hasPreviousState(): boolean {
        return (
            isValidObject(this.highIslandsState.previous.state) &&
            isValidNumber(this.highIslandsState.previous.started) &&
            isValidObject(this.openedCasesState.previous)
        );
    }

    getPreviousContestStart(): number {
        return this.highIslandsState.previous.started
    }

    private registrySubscribers() {
        interval(1000).subscribe(() => {
            if (this._isInBet && this.recheadMaxTimeWaitingContest() && this.lastSnackMessage !== ESnackMessages.ContestTimeExeced) {
                this.showResetDialog();
            }

            if (!isValidString(this.waitingAllocationConvId)) return;

            const currentButtonControl = this._buttonControl$.getValue();
            const isBlockedByWaitingConversationID = currentButtonControl.theme === 'success';

            if (!isBlockedByWaitingConversationID) return;

            const attRegistry = this.attSvc.getAttendanceRegistryByIdConversation(this.waitingAllocationConvId);
            const alreadyArraived = isValidRef(attRegistry);

            if (alreadyArraived) {
                this.updateButtonState({ releaseButton: true });
                this.ereaseIslandHighLights();
                this.handleContestFinish();
                this.waitingAllocationConvId = undefined;
            }
        });

        merge(this.attSvc.broadcastIslandControl$, this.notificationSvc.broadcastOpenQueue$).subscribe((v) => {
            this.handleOpenedCasesPayload(v);
        });

        this.notificationSvc.broadcastAllocationStatus$.subscribe((v) => {

            if (isValidRef(v.current)) {
                this.handleOpenedCasesPayload(v.current);
            }

            if (v.ereaseHighLight === true) {
                this.ereaseIslandHighLights();
            } else if (isValidRef(v.highlightedIslands)) {
                this.currentContestIslandHighlights.push(v.highlightedIslands);
                this.computeIslandsHighlights();
            }

            if (isValidRef(v.buttonControl)) {
                this.updateButtonState(v.buttonControl);
            }

            if (isValidString(v.idConversation)) {
                this.waitingAllocationConvId = v.idConversation;
            }

            if (isValidNumber(v.highlighEreaseTimeout)) {
                this.clearEreaseIslandHighlightTimeout();

                this.highlightEreaseTimeout = setTimeout(() => {
                    this.ereaseIslandHighLights();
                    this.handleContestFinish();
                }, v.highlighEreaseTimeout);
            }

            if (v.contestStage === EBroadcastContestStage.Finish && !isValidNumber(v.highlighEreaseTimeout)) {
                this.handleContestFinish();
            }
        });
    }

    private computeIslandsHighlights() {
        this.highIslandsState.current.state = this.currentContestIslandHighlights.reduce<{ result: TBroadcastHighlightedIslands }>((computed, hash) => {
            Object.entries(hash).forEach(([idIsland, config]) => {
                computed.result[idIsland] = config
            });

            return computed;
        }, { result: {} }).result;
    }

    private clearEreaseIslandHighlightTimeout() {
        if (isValidRef(this.highlightEreaseTimeout)) {
            clearTimeout(this.highlightEreaseTimeout);
        }
    }

    private ereaseIslandHighLights() {
        this.clearEreaseIslandHighlightTimeout();

        this.highIslandsState.previous.state = typedClone(this.highIslandsState.current.state);
        this.highIslandsState.current.state = {};
        this.currentContestIslandHighlights = [];
    }

    get currentHighlighIslands() {
        return this._isViewPreviousState && this.hasPreviousState()
            ? this.highIslandsState.previous.state
            : this.highIslandsState.current.state;
    }

    private handleContestFinish() {
        if (!this._isInBet) return;

        this.lastSnackMessage = undefined;
        this.snackRef?.dismiss();

        this.openedCasesState.previous = typedClone(this.openedCasesState.current);
        this.openedCasesState.current = typedClone(this.openedCasesState.next || this.openedCasesState.current);
        this.openedCasesState.next = undefined;

        this._isInBet = false;
        this.lastBetTS = undefined;
        this.ereasedContest.next();
    }

    private handleOpenedCasesPayload(e: TBroadcastIslandControl) {
        const islandCases = Object.entries(e).reduce<TBroadcastIslandCases>((islandCases, [idIsland, casesLength]) => {
            const islandNS = this.attSvc.getIsland(idIsland);

            if (!islandNS) return islandCases;

            islandCases.totalCases += casesLength;

            islandCases.islandCases.push({
                idIsland,
                name: islandNS?.nName,
                casesLength,
            });

            return islandCases;
        }, initialIslandCases());

        islandCases.islandCases.sort((a, b) => a.name.localeCompare(b.name))

        if (this._isInBet) {
            this.openedCasesState.next = islandCases;
        } else {
            this.openedCasesState.current = islandCases
        }

    }

    getOpenedCases(): TBroadcastIslandCases {
        return this._isViewPreviousState && this.hasPreviousState() ? this.openedCasesState.previous : this.openedCasesState.current
    }

    getIslandCases(): TBroadcastIslandCases {
        return this.islandCases;
    }

    private openSnack(config: ISnackSettingsMessage) {
        this.snackRef?.dismiss();
        this.lastSnackMessage = config.message;
        config.vertical = SnackVerticalPosition.Top;
        config.duration = -1;
        this.snackRef = this.snackMsgSvc.open(config);
    }

    private async showResetDialog() {
        this.waitingAllocationConvId = undefined;
        this.updateButtonState({ releaseButton: true });
        this.ereaseIslandHighLights();
        this.handleContestFinish();
        this.openSnack({
            message: ESnackMessages.ContestTimeExeced,
            options: {
                duration: -1,
            },
            duration: -1,
        });
    }

    public async requestAttAllocation() {
        if (this.requesting) return;

        if (this._isInBet) {
            const reacheadMaxTime = this.recheadMaxTimeWaitingContest();

            if (!reacheadMaxTime) {
                const duration: number = getClock() - this.lastBetTS;
                if (isValidString(this.waitingAllocationConvId)) {
                    this.openSnack({ message: ESnackMessages.AlreadyAllocated, type: EAppAlertTypes.Success, duration });
                } else {
                    this.openSnack({ message: ESnackMessages.InQueue, type: EAppAlertTypes.Primary, duration });
                }
            } else {
                this.showResetDialog();
            }

            return;
        }

        if (this.isViewPreviousState()) {
            this._isViewPreviousState = false;
        }

        this.lastBetTS = Date.now();
        this._isInBet = true;
        this.updateButtonState({ message: "Disputando...", theme: "info" });
        this.requesting = true;
        const res = await this.api.quick<IQuickDrawAllocationRequest, IQuickDrawAllocationResponse>()({
            requestType: apiRequestType.dashboardExtra.attendance.quickDrawAllocation,
        });

        /** Erro na requisição */
        if (!res) {
            this.updateButtonState({ releaseButton: true });
            this._isInBet = false;
            this.lastBetTS = undefined;
            this.requesting = false;

            return;
        }

        this.requesting = false;
        this.highIslandsState.previous.started = Date.now();
    }

    public toggleViewPreviousState() {
        this._isViewPreviousState = !this._isViewPreviousState
    }

    public isViewPreviousState(): boolean {
        return this._isViewPreviousState;
    }

    private recheadMaxTimeWaitingContest(): boolean {
        return getClock() - this.lastBetTS > MAX_TIME_WAITING_CONTEST
    }

    private updateButtonState(value: TBroadcastControlButton) {
        this._buttonControl$.next(value);
    }
}
