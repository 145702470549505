import { ETypeBPMActionModel } from "../crm/crm-services/crm-action-model";
import { EBPMAction, TAllBPMACtion } from "./bpm-action-model";
import { EBPMMarketingActionType } from "./marketing/bpm-marketing.model";


interface IBPMActionConfigItem {
    showMetadataPicker?: boolean
}


type TDbControlMeta = { [key in TAllBPMACtion]?: IBPMActionConfigItem };


export const dbControlBPM: TDbControlMeta = {
    [ETypeBPMActionModel.Assign]: {
        showMetadataPicker: true,
    },
    [ETypeBPMActionModel.GerFollowUpFromCustomer]: {
        showMetadataPicker: true,
    },
    [ETypeBPMActionModel.Notification]: {
        showMetadataPicker: true,
    },
    [ETypeBPMActionModel.executeFunction]: {
        showMetadataPicker: true,
    },
}


type TMaterialIcon = string;

export const BPMActionIcons: { [key in TAllBPMACtion]?: TMaterialIcon } = {
    [EBPMAction.validator]: "check_circle",
    [EBPMAction.conditionalDisplay]: "visibility",
    [EBPMAction.action]: "launch",
    [EBPMAction.anomization]: "person_outline",
    [EBPMAction.optIn]: "person_add",
    [EBPMAction.optOut]: "person_remove",
    [EBPMAction.getTitularData]: "badge",
    [EBPMAction.solicitation]: "pending_actions",
    [ETypeBPMActionModel.Assign]: 'assignment',
    [ETypeBPMActionModel.CloseTicket]: 'disabled_by_default',
    [ETypeBPMActionModel.GerFollowUpFromCustomer]: 'campaign',
    [ETypeBPMActionModel.Notification]: 'notifications',
    [ETypeBPMActionModel.executeFunction]: 'code',
    [EBPMMarketingActionType.messageWithCampaing]: 'campaign',
    [EBPMMarketingActionType.goCampaignWithBot]: 'smart_toy',
}
