import { AfterViewInit, Component, ElementRef, Input, Optional, ViewChild } from "@angular/core";
import { constant } from "@colmeia/core/src/business/constant";
import { bbCodeTagsDB } from "@colmeia/core/src/shared-business-rules/bbcode/bbcode-constants";
import { EBBCodeStyles } from "@colmeia/core/src/shared-business-rules/bbcode/bbcode-types";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { bbCodeStylesTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/bbcode";
import { IEditorVariable, getVariablesOfTemplate, removeBracket, textCompiledDelimeters } from "@colmeia/core/src/shared-business-rules/metadata/metadata-utils";
import { GenericSharedService } from "@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service";
import { genericTypedSuggestions } from "@colmeia/core/src/tools/type-utils";
import { delay, flat, isAllValid, isEven, isOdd, isValidArray, isValidFunction, isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { BBCodeViewModalComponent } from "app/components/bbcode-view-modal/bbcode-view-modal.component";
import { RootComponent } from "app/components/foundation/root/root.component";
import { BBCodeViewModalHandler } from "app/handlers/bbcode-view.handler";
import { IVarEditorTextAreaParameter, VarEditorTextAreaHandler } from "app/handlers/var-editor-textarea.handler";
import { ColmeiaDialogService } from "app/services/dialog/dialog.service";
import { EAppAlertTypes, SnackMessageService } from "app/services/snack-bar";
import { getEmojiFromCode } from "app/utils/get-emoji";
import EmojiPanel from 'emoji-panel';
import { values } from 'lodash';
import { IEditorVariableClient, VarEditorComponent } from "../var-editor/var-editor.component";
import { VarEditorTextAreaTiptapComponent } from "./var-editor-text-tiptap/var-editor-text-area-tiptap.component";
import { IPoint, moveEventsOf } from "app/model/client-utility";

interface IKeyOnKeyboardConfiguration {
    direction: number;
    look: number;
    preventDefault: boolean;
}

export interface IVarEditorUIConfig {
    fontSize: number;
    viewRawCode: boolean;
    showEmojiBox: boolean,
    textAreaHeightPX: number;
}

const keyboardKeysConfiguration = genericTypedSuggestions<Record<string, IKeyOnKeyboardConfiguration>>()({
    Backspace: {
        direction: -1,
        look: -1,
        preventDefault: true,
    },
    Delete: {
        direction: 1,
        look: 0,
        preventDefault: false,
    },
} as const);

type TConfiguratedKeys = keyof typeof keyboardKeysConfiguration;

function configuratedKeysGuard(key): key is TConfiguratedKeys {
    return isValidRef(keyboardKeysConfiguration[key]);
}


export const varEditorUIConfigDefault: IVarEditorUIConfig = {
    fontSize: 100,
    viewRawCode: false,
    showEmojiBox: false,
    textAreaHeightPX: 90,
}


@Component({
    selector: "app-var-editor-text-area",
    templateUrl: "./var-editor-text-area.component.html",
    styleUrls: ["./var-editor-text-area.component.scss"],
    host: {
        '[class.invalid]': 'handler?.hasInvalidText()',
    }
})
export class VarEditorTextAreaComponent extends RootComponent<
    | 'fullfillFallBackMessage'
    | 'youCantWriteInsideAVariable'
    | EBBCodeStyles
> implements AfterViewInit {

    textUpdateStep = 10;

    public varEditorUIConfigDefault: IVarEditorUIConfig = varEditorUIConfigDefault;
    public varEditorUIConfig: IVarEditorUIConfig = { ...varEditorUIConfigDefault }

    _handler: VarEditorTextAreaHandler;
    @Input()
    public set handler(value: VarEditorTextAreaHandler) { this._handler = value; this.init(); }
    public get handler(): VarEditorTextAreaHandler { return this._handler }
    public bbCodeStyleTags: EBBCodeStyles[] = values(EBBCodeStyles);
    public textareaCursorPosition: number = 0;
    public mapRawText: boolean[] = [];
    @ViewChild('emojibox') emojibox: ElementRef;

    @ViewChild('tiptapEditor', { static: false })
    tiptapEditor: VarEditorTextAreaTiptapComponent;

    @ViewChild('textAreaResizer', { static: false })
    textAreaResizer: ElementRef<HTMLDivElement>;

    public get amoutCharacters(): number {
        return this.handler.calcAmountCharacters(this.rawText);
    }

    public get parameters(): IVarEditorTextAreaParameter {
        return this.handler.getComponentParameter();
    }

    public get shouldHideEmojis() {
        return this.parameters.shouldHideEmojis;
    }

    public get shouldHideBBCode() {
        return this.parameters.shouldHideBBCode;
    }

    @ViewChild('varEditorTextarea') textAreaRef: ElementRef<HTMLTextAreaElement>;

    public get maxCharsLength(): number {
        return this.parameters.limitCharacters ?? constant.maxAssetTextLength;
    }

    @Input()
    public allVariables: IEditorVariableClient[] = [];

    @ViewChild(VarEditorTextAreaTiptapComponent)
    tiptapVarEditor: VarEditorTextAreaTiptapComponent;

    constructor(
        private snack: SnackMessageService,
        private dialogSvc: ColmeiaDialogService,
        @Optional() private varEditorComponent: VarEditorComponent,
    ) {
        super({
            fullfillFallBackMessage: gTranslations.errors.fullfillFallBackMessage,
            youCantWriteInsideAVariable: gTranslations.errors.youCantWriteInsideAVariable,
            ...bbCodeStylesTranslations,
        });
    }

    public toggleEmojiBox(): void {
        this.varEditorUIConfig.showEmojiBox = !this.varEditorUIConfig.showEmojiBox;
    }

    ngAfterViewInit() {
        this.buildEmojiBoxIfNeeded();

        if (isValidFunction(this.parameters.clientCallback.onVarEditorCreated)) {
            this.parameters.clientCallback.onVarEditorCreated();
        }

        let lastY: number = 0;
        let lastAnim: number;

        moveEventsOf(this.textAreaResizer.nativeElement, {
            start: () => { },
            move: (event: MouseEvent | TouchEvent, pointerTranslation: IPoint) => {

                cancelAnimationFrame(lastAnim);
                lastAnim = window.requestAnimationFrame(() => {

                    this.textAreaResizer.nativeElement.style.transform = `translateY(${pointerTranslation.y}px)`
                    lastY = pointerTranslation.y;
                })
            },
            end: () => {
                cancelAnimationFrame(lastAnim);
                this.textAreaResizer.nativeElement.style.transform = `translateY(0)`
                this.varEditorUIConfig.textAreaHeightPX += lastY;
            },
        })
    }

    public buildEmojiBoxIfNeeded(): void {

        new EmojiPanel(this.emojibox.nativeElement, {
            onClick: (optionClicked) => {
                const emoji = getEmojiFromCode(optionClicked.unified);

                if (!this.varEditorUIConfig.viewRawCode) {
                    this.tiptapEditor.insertEmoji(emoji);

                    return
                }

                this.rawText = `${this.rawText.slice(0, this.textareaCursorPosition)}${emoji}${this.rawText.slice(this.textareaCursorPosition)}`;
                this.textareaCursorPosition += emoji.length;
            }
        });
    }

    public openVisualizationModal(): void {
        this.dialogSvc.open<BBCodeViewModalComponent, BBCodeViewModalHandler>({
            componentRef: BBCodeViewModalComponent,
            title: "Visualização de estilo",
            dataToComponent: {
                data: BBCodeViewModalHandler.factory({
                    text: this.rawText,
                    isWhatsApp: true,
                    clientCallback: this,
                }),
            },
        });
    }

    public getSelectionEnd(): number {
        const element: HTMLTextAreaElement = this.getTextAreaElement();
        return element.selectionEnd;
    }

    public async waitAndSetCursor(start: number, end: number = start): Promise<void> {
        await delay(1);
        const el = this.getTextAreaElement();
        el?.focus();
        el && this.setCursor(start, end);
    }

    public setCursor(start: number, end: number): void {
        const element: HTMLTextAreaElement = this.getTextAreaElement();
        element.selectionStart = start;
        element.selectionEnd = end;
    }


    public getTextAreaElement(): HTMLTextAreaElement {
        const element: HTMLTextAreaElement = this.textAreaRef?.nativeElement;
        return element;
    }

    ngOnInit() {
        // setTimeout(() => {
        //     if (this.useTiptap) return;

        //     const element: HTMLTextAreaElement = this.getTextAreaElement();
        //     if (isValidRef(element)) element.blur();

        //     if (isValidRef(element)) element.focus();
        //     this.buildEmojiBoxIfNeeded();
        // }, 1);
    }

    public init(): void {
        this.handler.setSlave(this);
        this.rawText = this.rawText;
    }

    public async onVarEditorSelectVariable(variable: IEditorVariable): Promise<void> {
        this.insertVariable(variable);
        await this.waitAndSetCursor(this.textareaCursorPosition + variable.variable.length);
    }

    public async onVarEditorSelectBBCodeStyle(styleTag: EBBCodeStyles): Promise<void> {
        this.insertBBCodeVariableAndSetCursor(styleTag);
    }


    public handlePress(event: InputEvent): boolean {
        return this.preventInvalidsInputs(event);
    }

    public canWriteHere(): boolean {
        const currentChar: boolean = this.mapRawText[this.textareaCursorPosition];
        const lastChar: boolean = this.mapRawText[this.textareaCursorPosition - 1];

        return isAllValid(currentChar, lastChar)
            ? (currentChar || lastChar)
            : true;
    }

    private canWriteHereMessage(): void {
        return this.errorMessage("Você não pode adicionar uma variável dentro de outra.");
    }

    splittedRawText: string[] = [];
    public updateSplittedRawText(): void {
        const start: string = textCompiledDelimeters.startCharacter;
        const end: string = textCompiledDelimeters.endCharacter;
        const pattern: RegExp = new RegExp(`(${start}[^${start}${end}]*${end})`);
        this.splittedRawText = this.rawText.split(pattern);
    }

    public updateMapRawText(): void {
        this.mapRawText = flat(
            this.splittedRawText
                .map((group: string, index: number) => group.split('').map(() => isEven(index))) // block write inside a match
                .filter((mapped: boolean[]) => mapped.length) // remove empty maps
            // .map((mapped: boolean[]) => [true, ...mapped.slice(1)]) // allow first
        );
    }

    public get hasUsedSomeVariable(): boolean {
        return this.splittedRawText.length > 1 && isOdd(this.splittedRawText.length);
    }

    public get placeholderText(): string {
        return this.parameters.placeholderText;
    }

    public setTextareaCursorPosition(event: KeyboardEvent): void {
        const el: HTMLTextAreaElement = event.target as HTMLTextAreaElement;

        this.textareaCursorPosition = isValidRef(el.selectionStart) ? el.selectionStart : 0;

        if (isValidRef(event) && configuratedKeysGuard(event.key)) {
            const { config, position } = this.getConfigAndPosition(event.key)
            const shouldDeleteBothSides: boolean = this.shouldDeleteBothSides(event.key);
            const deleted = this.mapRawText[position] === false

            let charDeletedPositions: number[] = [];

            charDeletedPositions.push(...this.handleDeletion(event.key));
            if (shouldDeleteBothSides) {
                const lastDeleted: number[] = this.handleDeletion(event.key === 'Delete' ? 'Backspace' : 'Delete');
                charDeletedPositions.push(...lastDeleted);
            }

            this.updateRawTextByMap();
            if (config.preventDefault && deleted) event.preventDefault();

            if (isValidArray(charDeletedPositions)) {
                this.waitAndSetCursor(Math.min(...charDeletedPositions) + 1);
            }

            this.parameters.clientCallback.onVarEditorChangeRawText(this.rawText);
        }

    }

    public getConfigAndPosition(key: TConfiguratedKeys) {
        const config = keyboardKeysConfiguration[key];
        const position: number = this.textareaCursorPosition + config.look;
        return { config, position }
    }

    public showBBCodeStyleTag(bbCodeStyleTag: EBBCodeStyles): boolean {
        return bbCodeTagsDB[bbCodeStyleTag].showOnClientEditor;
    }
    public addBBCodeStyleTag(bbCodeStyleTag: EBBCodeStyles): void {
        this.onVarEditorSelectBBCodeStyle(bbCodeStyleTag);
    }

    public bbCodeStyleToIcon: { [key in EBBCodeStyles]: string } = {
        [EBBCodeStyles.Italic]: 'format_italic',
        [EBBCodeStyles.Bold]: 'format_bold',
        [EBBCodeStyles.Strikethrough]: 'strikethrough_s',
        [EBBCodeStyles.Code]: 'code',
        [EBBCodeStyles.Monospace]: 'font_download',
        [EBBCodeStyles.Menu]: 'menu',
    };

    public bbCodeStyleTooltip: { [key in EBBCodeStyles]: string } = {
        [EBBCodeStyles.Italic]: 'Itálico',
        [EBBCodeStyles.Bold]: 'Negrito',
        [EBBCodeStyles.Strikethrough]: 'Tachado',
        [EBBCodeStyles.Code]: 'Código',
        [EBBCodeStyles.Monospace]: 'Monospace',
        [EBBCodeStyles.Menu]: 'Menu',
    };

    public printBBCodeStyleTagIcon(bbCodeStyleTag: EBBCodeStyles): string {
        return this.bbCodeStyleToIcon[bbCodeStyleTag];
    }

    public printBBCodeTooltip(bbCodeStyleTag: EBBCodeStyles): string {
        return this.bbCodeStyleTooltip[bbCodeStyleTag];
    }

    public handleDeletion(key: TConfiguratedKeys): number[] {
        const { config, position } = this.getConfigAndPosition(key);

        let charDeletedIndexes: number[] = [];
        let index: number;
        if (this.mapRawText[position] === false) {
            index = position;

            while (this.mapRawText[index] === false) {
                delete this.mapRawText[index];
                index += config.direction;
                charDeletedIndexes.push(index);
            }
        }

        return charDeletedIndexes;
    }

    public shouldDeleteBothSides(key: TConfiguratedKeys) {
        const { config, position } = this.getConfigAndPosition(key)
        const oppositeDirection: number = config.direction * -1;
        const otherSideLook: number = this.textareaCursorPosition === position ? position + oppositeDirection : this.textareaCursorPosition;
        const shouldDeleteBoth: boolean = [position, otherSideLook].map(index => this.mapRawText[index]).every(item => item === false);
        return shouldDeleteBoth;
    }


    public updateRawTextByMap(): void {
        this.rawText = Object.keys(this.mapRawText).map((key: string) => this.rawText[key]).join('');
    }

    public errorMessage(message: string): void {
        this.snack.open({
            type: EAppAlertTypes.Error,
            message,
            duration: 4000,
        });
        return;
    }

    public insertVariable(variable: IEditorVariable): void {
        if (!this.varEditorUIConfig.viewRawCode) {

            this.tiptapVarEditor.insertVariable(variable);

            return;
        }

        if (!this.canWriteHere()) return this.canWriteHereMessage();
        this.rawText = `${this.rawText.substring(0, this.textareaCursorPosition)}${variable.variable}${this.rawText.substring(this.textareaCursorPosition)}`;
    }

    public async insertBBCodeVariableAndSetCursor(styleTag: EBBCodeStyles): Promise<void> {
        if (!this.canWriteHere()) return this.canWriteHereMessage();

        const initialCursorStart: number = this.textareaCursorPosition;
        const initialCursorEnd: number = this.getSelectionEnd();

        const middleContent: string = `${this.rawText.substring(initialCursorStart, initialCursorEnd)}`;
        const openTag: string = `[${styleTag}]`;
        const closeTag: string = `[/${styleTag}]`;

        this.rawText = `${this.rawText.substring(0, initialCursorStart)}${openTag}${middleContent}${closeTag}${this.rawText.substring(initialCursorEnd)}`;

        await this.waitAndSetCursor(initialCursorStart + openTag.length, initialCursorEnd + openTag.length);
    }

    get rawText(): string {
        return this.parameters.rawText;
    }

    set rawText(rawText: string) {
        this.handler.setText(rawText);
        this.updateRawTextMappers();
        this.callOnVarEditorChangeRawText();
        this.initRawTextCharCount();
    }

    public rawTextCharCount: number;
    initRawTextCharCount() {
        let text: string;

        if (this.parameters.computeAmountCharactersString) {
            text = this.parameters.computeAmountCharactersString(this.rawText);
        } else {
            text = this.rawText;
        }

        this.rawTextCharCount = GenericSharedService.charCount(text);
    }


    public updateRawTextMappers(): void {
        this.updateSplittedRawText();
        this.updateMapRawText();
        this.updateUsedVariables();
    }

    usedVariablesNames: string[] = [];
    public updateUsedVariables() {
        this.usedVariablesNames = this.splittedRawText
            .filter((block: string, index: number) => isOdd(index))
            .map((block: string) => this.removeBrackets(block))
            ;
    }

    public removeBrackets(vr: string) {
        return removeBracket(vr);
    }

    public callOnVarEditorChangeRawText = () => {
        this.parameters.clientCallback.onVarEditorChangeRawText(this.rawText); //
    }

    public preventInvalidsInputs(event: InputEvent): boolean {
        const char = event.data;

        if (isValidString(char) && this.hasTextLengthExceededLimit(this.rawText + char)) {
            return false;
        }

        if (isValidRef(this.parameters.limitRows)) {
            const isInsertingLine = (event.inputType === 'insertLineBreak');
            const shouldBlockMoreRowInsertion = (this.rawText.split('\n').length >= this.parameters.limitRows);
            const canInsert = !(isInsertingLine && shouldBlockMoreRowInsertion);
            if (!canInsert) return false;
        }

        if (!this.canWriteHere()) {
            this.snack.open({
                type: EAppAlertTypes.Error,
                message: this.translations.youCantWriteInsideAVariable.value,
                duration: 4000,
            });
            return false;
        }

        const delimiterChars: string[] = flat([...Object.values(textCompiledDelimeters).map((delimiter: string) => [...delimiter])]);
        return !delimiterChars.includes(char);
    }


    updateTextSize(value: number): void {
        this.varEditorUIConfig.fontSize = value;
    }

    toggleViewRaw(): void {
        this.varEditorUIConfig.viewRawCode = !this.varEditorUIConfig.viewRawCode;
    }

    hasTextLengthExceededLimit(rawText: string): boolean {
        return this.handler.calcAmountCharacters(rawText) > this.maxCharsLength;
    }

    shouldShowCanonicalAlert(): boolean {
        const isCountingCharactersWithoutVariable = this.handler.isCountingCharactersWithoutVariables();

        if (!isCountingCharactersWithoutVariable) return false;

        const hasVariables = !!getVariablesOfTemplate(this.rawText).length;
        
        return hasVariables;
    }
}
