export enum EColmeiaAPIErrors {
    unauthorized = 'unauthorized',
    fileDoesNotExistOnCloudStorage = 'fileDoesNotExistOnCloudStorage',
    invalidIdDatabase = 'invalidIdDatabase',
    invalidDatabaseRows = 'invalidDatabaseRows',
    noFileMetadataFoundForDatabase = 'noFileMetadataFoundForDatabase',
    missingEmailOrPassword = 'missingEmailOrPassword',
    idCampaignActionMissingOrInvalid = 'idCampaignActionMissingOrInvalid',
    panelIdOrReportIdDoNotExist = 'panelIdOrReportIdDoNotExist',
    reportIdToDeleteDoNotExist = 'reportIdToDeleteDoNotExist',
    reportToDeleteNotFound = 'reportToDeleteNotFound',
    fileNotUploaded = 'fileNotUploaded',
    avatarOnRemoteEnvironmentDoesNotExist = 'avatarOnRemoteEnvironmentDoesNotExist',
    socialNetworkOnRemoteAvatarDoesNotExist = 'socialNetworkOnRemoteAvatarDoesNotExist',
    idRouteIsRequired = 'idRouteIsRequired',
    idConnectionIsRequired = 'idConnectionIsRequired',
    rawRequestTypeIsRequired = 'rawRequestTypeIsRequired',
    protecedResourceNotFoundForAvatar = 'protecedResourceNotFoundForAvatar',
    notHalted = 'notHalted',
    coordinatorNotFound = 'coordinatorNotFound',
    sendCampaignFailed = 'sendCampaignFailed',
    invalidIdCallback = "invalidIdCallback",
    invalidFormFields = "invalidFormFields",
    missingMigrationTransformation = "missingMigrationTransformation",
    colmeiaProviderAlreadyExistsInSameEnvironment = "colmeiaProviderAlreadyExistsInSameEnvironment",
    scheduleClockTickInvalid = "scheduleClockTickInvalid",
    cadenceConfigInvalid = "cadenceConfigInvalid",
    approverGroupDoesNotExistIntoSocialNetwork = "approverGroupDoesNotExistIntoSocialNetwork",
    cannotRemoveFromIslandAgentStillAttending = "cannotRemoveFromIslandAgentStillAttending",
    idAvatarAgentTargetListRequired = "idAvatarAgentTargetListRequired",
    initAndEndClockMandatory = "initAndEndClockMandatory",
    databaseRowsNotCompliant = "databaseRowsNotCompliant",
    mandatoryFieldsTokenEmailType = "mandatoryFieldsTokenEmailType",
    tokenDoesNotExist = "tokenDoesNotExist",
    userDoesNotBelongToSocialNetworkWhereTokenCreated = "userDoesNotBelongToSocialNetworkWhereTokenCreated",
    userIsNotAttendentInSocialNetwork = "userIsNotAttendentInSocialNetwork",
    playerDoesNotExistIntoThisSocialNetwork = "playerDoesNotExistIntoThisSocialNetwork",
    tokenExpirableOnceDoesNotExist = "tokenExpirableOnceDoesNotExist",
    expiredTokenValue = "expiredTokenValue",
    hostnameNotAllowed = "hostnameNotAllowed",
    ipAddressNotAllowed = "ipAddressNpwAllowed",
    errorWhenTryingToRegisterCustomWebhookIntoWABAID = "errorWhenTryingToRegisterCustomWebhookIntoWABAID",
    errorWhenTryingToMigrateWhatsappChannelPhoneNumberFromAnotherBSPToColmeia = 'errorWhenTryingToMigrateWhatsappChannelPhoneNumberFromAnotherBSPToColmeia',
    errorWhenTryingToVerifyPhoneOwnershipAndRegister = 'errorWhenTryingToVerifyPhoneOwnershipAndRegister',
    errorWhenTryingToRegisterPhoneNumber = 'errorWhenTryingToRegisterPhoneNumber',
    errorWhenTryingToWhatsappChannelExchangeCodeForTokenAndGetWhatsappInfo = 'errorWhenTryingToWhatsappChannelExchangeCodeForTokenAndGetWhatsappInfo',
    errorWhenTryingToRegisterWebhook = 'errorWhenTryingToRegisterWebhookandRegisterNumber',
    errorWhenTryingToCheckIfMetaWhatsappPhoneIsRegistered = "errorWhenTryingToCheckIfMetaWhatsappPhoneIsRegistered",
    errorWhenTryingToGetAllPhoneNumbersFromWABAID = "errorWhenTryingToGetAllPhoneNumbersFromWABAID",
    userGroupProfileCannotBeEditedYet = 'userGroupProfileCannotBeEditedYet',
    errorWabaNotFoundWhenSavingChannel = 'errorWabaNotFoundWhenSavingChannel',
    fillMandatoryFieldsOnSaveWABA = "fillMandatoryFieldsOnSaveWABA",
    sameWABAExistsInDatabase = "sameWABAExistsInDatabase",
    creditLineForWabaAlreadyExtended = "creditLineForWabaAlreadyExtended",
    tryToextedCreditLineOfOwnedWaba = "tryToextedCreditLineOfOwnedWaba",
    globalCanonicalSingleUse = "globalCanonicalSingleUse",
    errorWhenTryingToGetLastAddedPhoneNumberAndRegisterIt = "errorWhenTryingToGetLastAddedPhoneNumberAndRegisterIt",
    errorWAFProcessing = 'errorWAFProcessing',
    errorWhenWAFCommunicateWithMeta = 'errorWhenWAFCommunicateWithMeta',
    errorWhenPersistingWAF = 'errorWhenPersistingWAF',
    errorWhenValidatingWAF = 'errorWhenValidatingWAF',
    errorWhenMappingWAF = 'errorWhenMappingWAF',
    formExtractorNotRecognized = "formExtractorNotRecognized",
    tryToSaveEmptyNserList = "tryToSaveEmptyNserList",
    whatsappBusinessProfileError = "whatsappBusinessProfileError",
    whatsappProfileFieldsError = "whatsappProfileFieldsError",
    whatsappTemplateBlocked = "whatsappTemplateBlocked",
    postgreSQLVersioningTableVersionMismatch = "postgreSQLVersioningTableVersionMismatch",
    nonserializableIsNotMappedToPersist = "nonserializableIsNotMappedToPersist",
    migrationVersioningAlreadyExists = "migrationVersioningAlreadyExists"
}
