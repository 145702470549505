import { MetaFields, TemplateFields } from "@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service";
import { cast } from '@colmeia/core/src/tools/utility/functions/cast';
import { factoryOf } from "@colmeia/core/src/tools/utility/functions/factory-of";
import { getGroupsFromPattern } from '@colmeia/core/src/tools/utility/functions/getGroupsFromPattern';
import { IntersectPartialExplicit } from "@colmeia/core/src/tools/utility/types";
import { Merger } from "@colmeia/core/src/tools/utility/types/entities/merger";
import { ITranslationConfig, ITranslationConfigWithMeta } from "../translation/translation-engine";
import * as _ from 'lodash'
import { TextTemplate } from "@colmeia/core/src/tools/utility/functions/TextTemplate";

export namespace MetaTranslation {
    export function isNumber(num: number): num is number
    export function isNumber(num: unknown): boolean {
        return num instanceof Number;
    }
    export function isField(num: number): num is number & Field
    export function isField(num: unknown): boolean {
        return num instanceof Field;
    }
    
    type Config = ITranslationConfigWithMeta
    
    export function isConfig(config: ITranslationConfig): config is Config;
    export function isConfig(config: ITranslationConfig): boolean {
        return isField(config.idField);
    }
    
    function factoryOfAndAddNum<A extends [number, ...unknown[]], B>(fn: new (...a: A) => B): { (...a: A): A[0] & B }
    function factoryOfAndAddNum<A extends unknown[], B>(fn: new (...a: A) => B): (...a: A) => B {
        return factoryOf(fn);
    }
    
    export class Field<Num extends number = number, Text extends MetaFields = MetaFields> extends Number {
        constructor(
            public num: Num,
            public text: Text,
        ) {
            super(num);
        }
    
        instance(): Field {
            return this;
        }
    
        static isTemplate(field: Field): field is TemplateField {
            return field instanceof TemplateField;
        }
    
        isTemplate(): this is TemplateField {
            return TemplateField.isTemplate(this);
        }
    
        translate(input: string): string | undefined {
            return this.text;
        }
    
    }
    
    
    export interface ITemplateField<Num extends number, Text extends string, Template extends string> extends ITemplateFieldConfig {
        num: Num,
        text: Text,
        template: Template;
    }

    export interface ITemplateFieldConfig {
        isWarning?: boolean;
    }
    
    interface IOriginalField<Num extends number, Text extends string, Original extends string> {
        num: Num,
        text: Text,
        original: Original
    }
    export type DefaultTemplateField = TemplateField;
    export class TemplateField<Num extends number = number, Text extends MetaFields = MetaFields, Template extends TemplateFields = TemplateFields> extends Field<Num, Text> {
    
        static is(field: unknown): field is DefaultTemplateField {
            return field instanceof TemplateField;
        }
        
        constructor(
            public num: Num,
            public text: Text,
            public template: Template,
            public config: ITemplateFieldConfig
        ) {
            super(num, text);
        }
    
        static factoryOf = factoryOfAndAddNum(this)
    
        static factory<Num extends number, Text extends MetaFields, Template extends string>(input: ITemplateField<Num, Text, Template>) {
            return TemplateField.factoryOf(input.num, input.text, input.template, input);
        }
    
        instance(): TemplateField {
            return this;
        }
    
        escapedTemplate = _.escapeRegExp(this.template)
            .replace(/\\({|})/g, '$1')
        ;
        extractor = TextTemplate.buildTemplateExtractor(this.escapedTemplate);
        groups = getGroupsFromPattern(this.extractor);
        
        hasGroups = this.extractor.source !== this.escapedTemplate;

        translate(input: string): string | undefined {
            if (input === this.text) return input;
            if (this.template === input) return this.text;
            const groups = this.groups?.(input);
            if (!groups) return;
            return _.template(this.text)(groups);
        }

        compress(input: string) {
            const groups = this.groups?.(input);
            if (!groups) return;
            
        }
    }
    
    export const factoryOfField = factoryOfAndAddNum(Field);
    const factory: Merger<(typeof factoryOfField), { template: typeof TemplateField.factory }> = cast(factoryOfField);
    factory.template = TemplateField.factory;
    export const field = factory;
    
}
