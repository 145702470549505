import { IProvider } from "../../conn-provider/types";
import { DeliveryProvider } from "../../core-constants/types";
import { IContentBasicAsset } from "../bot/bot-content-model";
import { ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";
import { WhatsApp } from "../social-media/whatsapp-interface";

export type WAFId = string;

export enum EWAFStatus {
    UNREGISTERED = 'UNREGISTERED', // INFO: this is the DEFAULT value, this can be only changed to draft when the flow is inserted at least one time. 
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED', // WARN: Once a Flow is published, it cannot be modified or deleted, but can be marked as deprecated.
    DEPRECATED = 'DEPRECATED',
    BLOCKED = 'BLOCKED', // Monitoring detected that the endpoint is unhealthy and set the status to Blocked. The Flow cannot be sent or opened in this state; the developer needs to fix the endpoint to get it back to Published state
    THROTTLED = 'THROTTLED', // Monitoring detected that the endpoint is unhealthy and set the status to Throttled. Flows with throttled status can be opened, however only 10 messages of the Flow could be sent per hour. The developer needs to fix the endpoint to get it back to the PUBLISHED state
    DELETED = 'DELETED', // The Flow is deleted and cannot be opened or sent (deletion only exists inside colmeia).
    ERROR = 'ERROR',
}

export const noPreviewFlowStatus = [EWAFStatus.UNREGISTERED, EWAFStatus.ERROR];

export enum EWAFCaterogies {
    SIGN_UP = 'SIGN_UP',
    SIGN_IN = 'SIGN_IN',
    APPOINTMENT_BOOKING = 'APPOINTMENT_BOOKING',
    LEAD_GENERATION = 'LEAD_GENERATION',
    CONTACT_US = 'CONTACT_US',
    CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
    SURVEY = 'SURVEY',
    OTHER = 'OTHER'
}

export enum EWAFComponents {
    TEXT_HEADING = 'TextHeading',
    TEXT_SUBHEADING = 'TextSubheading',
    TEXT_BODY = 'TextBody',
    TEXT_CAPTION = 'TextCaption',
    TEXT_INPUT = 'TextInput',
    TEXT_AREA = 'TextArea',
    CHECKBOX_GROUP = 'CheckboxGroup',
    RADIO_BUTTONS_GROUP = 'RadioButtonsGroup',
    OPT_IN = 'OptIn',
    DROPDOWN = 'Dropdown',
    DATE_PICKER = 'DatePicker',
    EMBEDDED_LINK = 'EmbeddedLink',
    IMAGE = 'Image',
    FORM = 'Form',
    FOOTER = 'Footer',
}

export enum WAFInputType {
    TEXT = 'text',
    NUMBER = 'number',
    EMAIL = 'email',
    PASSWORD = 'password',
    PASSCODE = 'passcode',
    PHONE = 'phone',
}

export enum EWAFFontWeight {
    BOLD = 'bold',
    ITALIC = 'italic',
    NORMAL = 'normal',
    BOLD_ITALIC = 'bold_italic',
}

export type TWAFTextStylesClient = EWAFFontWeight.ITALIC | EWAFFontWeight.BOLD | 'strikethrough';

export enum EWAFOnSubmit {
    Navigate = 'navigate',
    Complete = 'complete',
    DataExchange = 'data_exchange'
}
export interface IWAFBasicAction {
    name: EWAFOnSubmit;
}

export interface IWAFNavigateAction extends IWAFBasicAction {
    name: EWAFOnSubmit.Navigate,
    next: { type: string, name: string },
    payload: Object
}

export interface IWAFCompleteAction extends IWAFBasicAction {
    name: EWAFOnSubmit.Complete,
    payload: Object
}

export interface IWAFDataExchangeAction extends IWAFBasicAction {
    name: EWAFOnSubmit.DataExchange,
    payload: Object
}

export type TWAFActionTypeALL = IWAFNavigateAction | IWAFCompleteAction | IWAFDataExchangeAction;


export enum EWAFFieldTypes {
    string = 'string',
    number = 'number',
    interger = 'integer',
    boolean = 'boolean',
    enum = 'enum',
    object = 'object',
    array = 'array',
}

export interface IWAFIDynamicFieldType {
    type: string,
}

/**
 * INFO: Based on the documentation of the WAF, the array type only support an
 * object filed type as items. WHY? I don't know. 
 */
export interface IWAFIDynamicFieldArrayType extends IWAFIDynamicFieldType {
    type: 'array'
    items: IWAFDynamicFieldTypes;
}

export interface IWAFIDynamicFieldObjectType extends IWAFIDynamicFieldType {
    type: 'object';
    properties: {
        [key: string]: IWAFIDynamicFieldType;
    }
}

export type IWAFDynamicFieldTypes =
    IWAFIDynamicFieldType |
    IWAFIDynamicFieldObjectType |
    IWAFIDynamicFieldArrayType;

export interface IDynamicField<T> {
    id: string;
    default: T;
    typeName?: string;
}

// this is the data used for custom type of input componets for example:
// DropDown Fields, or CheckBoxGroup
export interface IWAFOption {
    id: string;
    title: string;
    description?: string;
    asideInfo?: string; // exemplo: para preços 
    enabled: boolean;
}

//  Some of the Componets have a  list of dataSource
export type IWAFMultipleOptions = IWAFOption[];

export type IWAFDropdownOption = Omit<IWAFOption, 'asideInfo'>;
export type IWAFDropdownOptions = IWAFDropdownOption[];


export enum ImageScaleType {
    CONTAIN = 'contain', // default if not set
    COVER = 'cover',
    // methods not implements yet
    // FILL = 'fill',
    // FIT = 'fit',
    // INSIDE = 'inside',
    // OUTSIDE = 'outside',
}

export enum EWafEngineVersion {
    v3 = '3.0',
    v3_1 = '3.1',
    v4 = '4.0',
    v5 = '5.0',
}

export const CurrentWAFEngineVersion = EWafEngineVersion.v5;

// represents the version to use during communication with the WhatsApp Flows 
// Data Endpoint. Currently, it is 3.0. If flow uses the data-channel 
// capability, the validation system will ask to provide this property.
export enum EWAFDataApiVersion {
    v3 = '3.0',
}

export const CurrentWAFDataApiVersion = EWAFDataApiVersion.v3;


export enum FlowMessageVersion {
    V3 = "3",
}

export const DefaultFlowMessageVersion = FlowMessageVersion.V3;

export enum EWAFMode {
    DRAFT = 'draft',
    PUBLISHED = 'published',
}

export type TWAFMessagePayload = Record<string, any>;

export enum EWAFActionType {
    Navigate = 'navigate',
}
export const DefaultWAFActionType = EWAFActionType.Navigate;

export interface IWAFActionPayload {
    screen: string;
    data: TWAFMessagePayload;
};

export interface IWAFActionParameter {
    flow_token: string;
    flow_id: string;
    flow_cta: string;
    flow_action: EWAFActionType;
    flow_message_version: FlowMessageVersion; // by default of meta is
    mode: EWAFMode;
    flow_action_payload: IWAFActionPayload;
}
export interface IWACTAActionParameter {
    display_text: string;
    url: string;
}

export enum EWAFFooterCaptionPosition {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',

    // can have both at same time or only center, but one thing at time.
    // LeftAndRight = 'leftAndRight', 
}

export interface IWAFValidationError {
    error: string;
    error_type: string;
    message: string;
    line_start: number;
    line_end: number;
    column_start: number;
    column_end: number;
}

export interface IWAFPreview {
    preview_url: string;
    expires_at: string;
}

export interface IWAFProvider extends IProvider {
    status: EWAFStatus;
    provider?: DeliveryProvider.whatsApp;
    whatsappFlowId?: WAFId;
    errorHistory?: string[];
    phoneId?: string;
}

export interface IWAFMessageComponents {
    header?: {
        format: WhatsApp.Message.Template.Structure.Format;
        content: IContentBasicAsset;
    };
    body: IContentBasicAsset;
    footer?: {
        content: IContentBasicAsset;
    };
    cta: IContentBasicAsset;
}

export interface IWAFMessageContent {
    header?: string;
    body: string;
    footer?: string;
    cta: string;
}

export type TIWAFProviderArray = IWAFProvider[];

export const MAX_WAF_OPTION_TITLE_SIZE: number = 30;
export const MAX_WAF_OPTION_DESCRIPTION_SIZE: number = 300;
export const MAX_WAF_OPTION_ASIDE_INFO_SIZE: number = 20;


export interface IFlowFieldMetadata {
    isOptional: boolean;
    type: EWAFComponents;
}

export interface IFlowField {
    idFlowField: string;  // fornecido pela Meta
    idField: string; // idFieldForm
    metadata?: IFlowFieldMetadata;
}

export type TIFlowFieldArray = Array<IFlowField>;

export type idDynamicField = string;
export type OverwriteDynamicFieldsContent = Record<idDynamicField, IContentBasicAsset>;

/**
 * @deprecated esta e a config de instancias antigas do flows.
 * nao podemos deletar caso ainda esteja sendo usado.
 * e existem breaking changes entra uma interface e a outra.
 */
export interface IFlowAreaV1 {
    id: string;
    shouldNotBeInHistoric?: boolean;
    fields: TIFlowFieldArray;
    dynamicFieldsContent: OverwriteDynamicFieldsContent;
    idFlowSchema: IdDep<ENonSerializableObjectType.wafSchema>;
    idScreen: string;
    formFlowOn: boolean;
    message: IWAFMessageComponents;
    errorMessage?: IWAFMessageComponents;
}

/**
 * @deprecated esta e a config de instancias antigas do flows.
 */
export type TIFlowAreaArray = Array<IFlowAreaV1>;

export interface IFlowAreaV2 {
    screenId: string;
    // Barbieri acha que não será usado.. em observação
    id: string;
    fields: TIFlowFieldArray;
    enabled: boolean;
    dynamicFieldsContent: OverwriteDynamicFieldsContent;
    ForceShowArea: boolean; // not used yet
}

export type TIFlowAreaV2Array = Array<IFlowAreaV2>;

export interface IWAFEngagementFlowConfiguration {
    id: string;
    shouldNotBeInHistoric?: boolean;
    formFlowOn: boolean;
    idFlowSchema: IdDep<ENonSerializableObjectType.wafSchema>;
    message: IWAFMessageComponents;
    errorMessage?: IWAFMessageComponents;
    flowAreas: TIFlowAreaV2Array;
}

export type TIEngagementFlowConfigurationArray = Array<IWAFEngagementFlowConfiguration>;

export type TFlowEngamentSettings = IFlowAreaV1 | IWAFEngagementFlowConfiguration;
