import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { TGlobalUID } from "../../business/constant";
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from "../non-serializable-id/non-serializable-id-interfaces";

export enum EProductCategory {
    SaaS = 'pSaaS', professionalServices = 'pPro', premiumSupport = 'pPr'
}

export interface IRangeBilling {
    /**
     * limite superior de eventos
     */
    limit: number;
    /**
     * preco por valor unitario
     */
    price: number;
}

export type TIRangeBillingArray = Array<IRangeBilling>
// Exemplo caso o item nao tenha range:
// Banana
// [{init: 0, price: 0.5}]

export type TECostEventArray = Array<ECostEvent>

export enum ECostEvent {
    // Relatório
    reportUsage = 'eRepUsg',
    biUser = 'eBIUser', // Done

    // Bot
    /**
     * evento de nova conversacao
     */
    smartFlowConversation = 'eSmFlConv', // Done
    /**
     * evento de nova conversacao por target(address) unico(evento derivado)
     */
    smartFlowMAU = 'eSmFlMAU',

    /**
     * mensagens de usuario
     */
    smartFlowCustomerMessage = 'eSmFlCustMsg',
    /**
     * mensagens de bot para cliente
     */
    smartFlowBotMessage = 'eSmFlBotMsg',
    /**
     * mensagens de agente pro cliente
     */
    smartFlowAttMessage = 'eSmFlAttMsg',
    /**
     * somatorio de smartFlowAttMessage+smartFlowBotMessage+smartFlowCustomerMessage
     */
    smartFlowAttMsgBotMsgCustomerMsg = 'smartFlowAttMsgBotMsgCustomerMsg',

    // Atendimento
    /**
     * evento de logins feitos pelos atendentes na plataforma
     */
    humanService = 'eHumanSvc',
    /**
     * evento de logins feitos por idAvatar unicos dos agentes que logaram na plataforma
     */
    attendentMAU = 'eAttMAU',

    // OmnisSense
    omniSenseConversation = 'eOmnConv', // nao precisa agora
    omniSenseMessage = 'eOmnMsg', // done

    // Marketing
    /**
     * evento de mensagens enviadas pelo envio-de-campanhas
     */
    mktMessage = 'eMktMsg', // Done
    bpmMarketing = 'eMktBPM', // Done

    // Corporate Search
    corporateSearch = 'eCSMsg', // nao precisa agora
    corporateSearchElastic = 'eCSElasticMsg', // nao precisa agora


    // Eventos de Contrato
    storage = 'eSto', // OK
    dwProcessing = 'eDwProcessing', // OK

    map = 'eMap', // proximo foco
    TextToSpeech = 'eTTS', // proximo foco
    speechToText = 'eSpeechToTxt', // proximo foco

    homologEnvironment = 'eHomolog', // evento manual
    support = 'eSuppBasic', // evento manual
    premiumSupport = 'eSuppPrem', // evento manual
    dwAccess = 'eDwAccess', // evento manual
    hours = 'eManualHours', // evento manual
    others = 'eManualOthers', // nao precisa
}

export interface IRangeConfig {
    metric: ECostEvent;
    range: TIRangeBillingArray;
    hasMonthlyCommit: boolean;
    monthlyCommit?: number;
    amountEventVolumeLimit?: number;
}

export interface IProductModuleClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.productModule;
}
export interface IProductModuleServer extends INonSerializable, IProductModuleClient {
    nsType: ENonSerializableObjectType.productModule;
}

export type TIProductModuleServerArray = Array<IProductModuleServer>;


export enum ETypeServiceProvider {
    nlp = 'nlp', communication = 'comm'
}

export enum ENLPProvider {
    Microsoft = 'NLP_MS', Colmeia = 'NLP_Colmeia'
}

export type TProvidedService = EDelivery360Action | ENLPProvider;

export enum EIncidentCostType {
    taxes = 'taxes',
    exchange = 'exchange',
    discount = 'discount',
}

export enum EOperationTypeAdjust {
    divsion = 'division',
    multiply = 'multiply'
}



export interface IProductClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.product;
    category: EProductCategory;
    deprecatedAt: number; // inicia com zero
    defaultPricing: IRangeConfig;

    isProviderDependent: boolean; // É relacionado a provedor
    serviceType: ETypeServiceProvider;
    providedService: TProvidedService;
};



export interface IProductServer extends INonSerializable, IProductClient {
    nsType: ENonSerializableObjectType.product;
}

export enum EContractStatus {
    active = 'active',
    inactive = 'inactive',
    canceled = 'canceled'
}


export interface IColmeiaBillingContractClient extends INonSerializableHeader {
    idSocialContext: TGlobalUID;
    /**
     * volume minimo de eventos acordado com cliente
     */
    monthlyCommit: number;
    hasValidity: boolean;
    validity: IValidity;
    status: EContractStatus;
    isActive: boolean;
    isDeprecated: IDeprecationInfo;
    gCloudProjectId?: string;
};
export type TActiveContractID = string;
export type TActiveContractProductID = string;

export interface IColmeiaBillingContractServer extends INonSerializable, IColmeiaBillingContractClient {
    nsType: ENonSerializableObjectType.billingContract;
}

export interface IDeprecationInfo {
    reason: string;
    deprecatedAt: number;
}

export interface IValidity {
    initAt: number;
    endAt: number; // endAt = initAt = 1 pgto

}

export enum EIncidentCostsProcessType {
    preProcess = 'preProcess',
    postProcess = 'postProcess'
}
export interface IIncidentCosts {
    index: number;
    operation: EOperationTypeAdjust;
    type: EIncidentCostType;
    description: string;
    processType: EIncidentCostsProcessType;
}

export type TIIncidentCostsArray = Array<IIncidentCosts>;

export interface IProductSKUClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.productSKU;
    idProduct: string;
    isRefund: boolean;
    deprecatedAt: number; // inicia com zero
    pricingRange: IRangeConfig; // IRangeConfigServer
    hasValidity: boolean;
    validity: IValidity;
    incidentsCosts?: TIIncidentCostsArray;
}

export interface IProductSKUServer extends INonSerializable, IProductSKUClient {
    nsType: ENonSerializableObjectType.productSKU;
}


export interface ICorporateGroup {
    primaryID: TGlobalUID;
    name: string;
}

export type TICorporateGroupArray = Array<ICorporateGroup>;

export function isRangeUniquePricing(rangeConfig: IRangeConfig): boolean {
    return (rangeConfig.range.length === 1) && (rangeConfig.range[0].limit === 0);
}
