import { Component, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SelectedFile } from '@colmeia/core/src/multi-media/selected-file';
import { FileUploadSignal } from 'app/model/signal/file-upload-signal';

/**
 * Component created to have a minus and a plus between a counter variable
 * @author Aldenir
 */

enum ProgressMode {
  Determinate = "determinate",
  Indeterminate = "indeterminate"
}

@Component({
    selector: 'progressbar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressbarComponent implements OnChanges {

    @Input() color: string;
    @Input() fileName: string;
    @Input() progressValue: number;
    @Input() uploadSignal: FileUploadSignal;

    //local atribute to show percentage '100%'
    public percents: number = 0;

    private mimeType?: string;
    public fileUrl?: SafeResourceUrl;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private sanitizer: DomSanitizer
    ) { }

    //change values always update progress value
    ngOnChanges(changes) {
        if (changes.progressValue && this.progressValue <= 100) {
            this.percents = this.progressValue;
        }

        const selectedFile: SelectedFile = this.uploadSignal?.getObservableInformation();

        if(this.uploadSignal?.getObservableInformation()) {
            this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(selectedFile.getClientCachedFile().getFileUrl());
            this.mimeType = selectedFile.getClientCachedFile().getMimeType();
        }
    }

    get progressMode(): string {
        return this.progressValue === 100
               ? ProgressMode.Determinate
               : ProgressMode.Indeterminate
    }

    isImage(): boolean {
        return this.mimeType?.startsWith("image/");
    }

    isVideo(): boolean {
        return this.mimeType?.startsWith("video/");
    }
}
