import { Component, Input, OnInit } from "@angular/core";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { getCoundown } from "@colmeia/core/src/time/time-utl";
import { getClock, isValidNumber } from "@colmeia/core/src/tools/barrel-tools";
import { AttendanceChannelService, ICheckChannelStatusReturn } from "app/services/attendace/attendance-channel.service";
import { SessionService } from "app/services/session.service";
import { SnackMessageService } from "app/services/snack-bar";

@Component({
    selector: 'attendance-chat-channel-closed',
    templateUrl: './attendance-chat-channel-closed.component.html',
    styleUrls: ['./attendance-chat-channel-closed.component.scss']
})
export class AttendanceChatChannelClosedComponent implements OnInit {

    @Input()
    public channelStatus: ICheckChannelStatusReturn = { isClosed: false };
    public sending: boolean = false;

    constructor(
        private session: SessionService,
        private attChannelService: AttendanceChannelService,
        private snackMessageSvc: SnackMessageService,
    ) { }

    ngOnInit(): void {
    }

    private async updateChannelStatus() {
        const currentGroupID: string = this.session.getSelectedGroupID();
        this.channelStatus = await this.attChannelService.checkChannelStatus(currentGroupID);
    }

    private hasIteractionOfSentTemplate(): boolean {
        const currentGroupID: string = this.session.getSelectedGroupID();
        const lastMessageOfChannelClosedOnAgentChat: Interaction = this.attChannelService.getLastMessageOfChannelClosedOnAgentChat(currentGroupID);

        return !!lastMessageOfChannelClosedOnAgentChat;
    }

    public isAllowedSendTemplate(): boolean {
        return !this.hasIteractionOfSentTemplate() || this.attChannelService?.agentControl?.isSendAllowed;
    }

    public getCanSendTemplate(): boolean {
        if (!this.hasIteractionOfSentTemplate()) return true;

        return (
            this.isAllowedSendTemplate() &&
            isValidNumber(this.channelStatus.sendTemplateBlockedUntil) &&
            (this.channelStatus.sendTemplateBlockedUntil < Date.now())
        );
    }

    public getCoundown(): string {
        return getCoundown(getClock(), this.channelStatus.sendTemplateBlockedUntil)
    }

    public async sendTemplate() {
        const currentGroupID: string = this.session.getSelectedGroupID();

        this.sending = true;
        const sent = await this.attChannelService.sendOpenChannelTemplate(currentGroupID);

        if (sent) {
            this.snackMessageSvc.openSuccess("Mensagem enviada");
            this.channelStatus = await this.attChannelService.checkChannelStatus(currentGroupID);
        } else {
            this.snackMessageSvc.openWarning("Ocorreu algum problema ao enviar a mensagem.");
        }

        await this.updateChannelStatus();

        this.sending = false;
    }
}