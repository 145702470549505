import { MainHandler } from "./main-handler";
import { IEditorVariable, IBracketType, textCompiledDelimeters } from "@colmeia/core/src/shared-business-rules/metadata/metadata-utils";
import { IComponentParameter } from "app/model/component-comm/basic";
import { defaultFields, isValidNumber } from "@colmeia/core/src/tools/utility";
import { EBBCodeStyles } from "@colmeia/core/src/shared-business-rules/bbcode/bbcode-types";
import { constant } from "@colmeia/core/src/business/constant";
import { IFormatVisibility } from "./var-editor.handler";
import { AssetAdderProcessor } from "app/model/dashboard/asset-adder.model";

//

export interface IVarEditorAreaSlaveCallback {
    onVarEditorSelectVariable(variable: IEditorVariable): void;
    onVarEditorSelectBBCodeStyle(styleTag: EBBCodeStyles): Promise<void>;
    usedVariablesNames: string[];
}

export interface IVarEditorAreaClientCallback {
    onVarEditorChangeRawText(rawText: string): void; // add debounce,
    onVarEditorCreated?(): void;
}

export interface IVarEditorTextAreaParameter extends IComponentParameter, IFormatVisibility {
    rawText: string;
    clientCallback: IVarEditorAreaClientCallback;

    textCompiledDelimeters?: IBracketType;
    placeholderText: string;
    limitCharacters?: number;
}

const defaultParameter: Partial<IVarEditorTextAreaParameter> = {
    textCompiledDelimeters,
}

export class VarEditorTextAreaHandler extends MainHandler {
    private slave: IVarEditorAreaSlaveCallback;

    private constructor(private _parameter: IVarEditorTextAreaParameter) {
        super(_parameter);
        defaultFields(_parameter, defaultParameter);
    }

    public static factory<P extends IVarEditorTextAreaParameter>(parameter: P): VarEditorTextAreaHandler {
        return new VarEditorTextAreaHandler(parameter);
    }

    //
    public setSlave(slave: IVarEditorAreaSlaveCallback) {
        this.slave = slave;
    }

    public getSlave(): IVarEditorAreaSlaveCallback {
        return this.slave;
    }
    //

    public getComponentParameter(): IVarEditorTextAreaParameter {
        return super.getComponentParameter() as IVarEditorTextAreaParameter;
    }

    setText(value: string) {
        this.getComponentParameter().rawText = value;
    }

    hasInvalidText(): boolean {
        const maxTextLength: number = isValidNumber(this._parameter.limitCharacters) ? this._parameter.limitCharacters : constant.maxAssetTextLength;
        const textSize = this.calcAmountCharacters(this.getComponentParameter().rawText);

        return textSize > maxTextLength;
    }

    calcAmountCharacters(text: string): number {
        if (this.getComponentParameter().computeAmountCharactersString) {
            return this.getComponentParameter().computeAmountCharactersString(text).length;
        }

        return text.length;
    }

    isCountingCharactersWithoutVariables(): boolean {
        return this.getComponentParameter().computeAmountCharactersString === AssetAdderProcessor.computeAmountCharactersWithoutVariables;
    }
}
