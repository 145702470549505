
import * as papa from 'papaparse';
import { typedCloneLodash } from '../../tools/utility';
const Papa = papa;


interface IGuessDelimiter {
    input: string;
    config: papa.ParseConfig
}

export function guessDelimiter({ input, config }: IGuessDelimiter) {
    interface Preview {
        data:   string[][];
        errors: any[];
        meta:   Meta;
    }
    
    interface Meta {
        delimiter: string;
        linebreak: string;
        aborted:   boolean;
        truncated: boolean;
        cursor:    number;
    }
    const empty: any = undefined;

    let { newline, skipEmptyLines, comments, delimitersToGuess } = config;
    let bestDelim: string | undefined;
    let bestDelta: number | undefined;
    let fieldCountPrevRow: number | undefined;
    let maxFieldCount: number | undefined;

    delimitersToGuess = delimitersToGuess || [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP];

    for (let i = 0; i < delimitersToGuess.length; i++) {
        let delim = delimitersToGuess[i];
        let delta = 0, avgFieldCount = 0, emptyLinesCount = 0;
        fieldCountPrevRow = undefined;

        let preview: Preview = new papa.Parser({
            comments: comments,
            delimiter: delim,
            newline: newline,
            preview: 10
        }).parse(input, empty, empty);


        for (let j = 0; j < preview.data.length; j++) {
            if (skipEmptyLines && testEmptyLine(preview.data[j])) {
                emptyLinesCount++;
                continue;
            }
            let fieldCount = preview.data[j].length;
            avgFieldCount += fieldCount;

            if (typeof fieldCountPrevRow === 'undefined') {
                fieldCountPrevRow = fieldCount;
                continue;
            }
            else if (fieldCount > 0) {
                delta += Math.abs(fieldCount - fieldCountPrevRow);
                fieldCountPrevRow = fieldCount;
            }
        }

        if (preview.data.length > 0)
            avgFieldCount /= (preview.data.length - emptyLinesCount);

        if ((typeof bestDelta === 'undefined' || delta <= bestDelta)
            && (typeof maxFieldCount === 'undefined' || avgFieldCount > maxFieldCount) && avgFieldCount > 1.99) {
            bestDelta = delta;
            bestDelim = delim;
            maxFieldCount = avgFieldCount;
        }
    }

    config.delimiter = bestDelim;

    return {
        successful: !!bestDelim,
        bestDelimiter: bestDelim
    };
    function testEmptyLine(line: string[]) {
        return skipEmptyLines === 'greedy' ? line.join('').trim() === '' : line.length === 1 && line[0].length === 0;
    }
}


export function getDelimiterGetterInfo(this: papa.ParseLocalConfig, input: string) {
    const [firstLine] = input.split('\n');
    const { bestDelimiter } = guessDelimiter({
        input: firstLine,
        config: typedCloneLodash(this),
    })
    
    return {
        delimiter: bestDelimiter!,
        firstLine,
    }
}
export function delimiterGetter(this: papa.ParseLocalConfig, input: string) {
    const [firstLine] = input.split('\n');
    const { bestDelimiter } = guessDelimiter({
        input: firstLine,
        config: typedCloneLodash(this),
    })
    return bestDelimiter!;
}