import { DatePipe } from "@angular/common";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild
} from "@angular/core";
import {
    MatDatepicker, MatDateRangePicker
} from "@angular/material/datepicker";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { hourToMS, minToMS } from "@colmeia/core/src/time/time-utl";
import { isValidNumber, isValidRef } from "@colmeia/core/src/tools/utility";
import { HardwareLayerService } from "app/services/hardware";
import { SnackMessageService } from "app/services/snack-bar";
import {
    DatePickerHandler,
    EDatePickerColor,
    EDatePickerMode
} from "../../../handlers/date-picker.handler";
import { UserSettingsService } from "../../../services/user-settings.service";
import { OverlayRef } from "@angular/cdk/overlay";

export type DateRange = [Date, Date];

@Component({
    selector: "cm-date-picker",
    templateUrl: "./date-picker.component.html",
    styleUrls: ["./date-picker.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnInit {
    private _handler: DatePickerHandler;
    private _dateRange: DateRange;
    private _date: Date;
    private datePipe: DatePipe;
    public get labelInputStyle() {
        return this.handler.labelInputStyle || {};
    }
    lbl1Value: string;
    lbl2Value: string;

    _time: number = 0;
    _dateRangeStartTime: number = 0;
    _dateRangeEndTime: number = 0;

    public isMobile: boolean = false;

    @ViewChild("datePicker", { static: false })
    datePicker: MatDatepicker<unknown>;

    @ViewChild("rangeDatePicker", { static: false })
    rangeDatePicker: MatDateRangePicker<unknown>;

    constructor(
        private userSettings: UserSettingsService,
        private cdr: ChangeDetectorRef,
        private hw: HardwareLayerService,
        private snack: SnackMessageService
    ) {
        this.datePipe = new DatePipe(this.userSettings.getSelectedLocale());
    }

    ngOnInit() {
        this.resetComponentState();
        this.isMobile = this.hw.isMobile();
    }

    @Input()
    public appearance: MatFormFieldAppearance = "fill";

    @Input()
    set handler(value: DatePickerHandler) {
        this._handler = value;
        if (value) {
            value.setInstance(this);
        }
        this._dateRange = undefined;
        this.resetComponentState();
    }
    @Input() disabled: boolean;

    get handler(): DatePickerHandler {
        return this._handler;
    }

    resetComponentState() {
        if (this.isRange) {
            this._rangeStartDate = this.handler.currentRange?.[0];
            this._rangeEndDate = this.handler.currentRange?.[1];

            this.setRangeTimes();
        } else {
            this._date = this.handler.current;

            this.setSingleDateTime();
        }

        this.updateLabel();
    }

    private setRangeTimes() {
        if (!(this.hasTimePicker && this._rangeStartDate && this._rangeEndDate))
            return;

        this._dateRange = [this._rangeStartDate, this._rangeEndDate];
        const startHours = this._rangeStartDate.getHours();
        const startMinutes = this._rangeStartDate.getMinutes();

        const endHours = this._rangeEndDate.getHours();
        const endMinutes = this._rangeEndDate.getMinutes();

        this._dateRangeStartTime = hourToMS(startHours) + minToMS(startMinutes);
        this._dateRangeEndTime = hourToMS(endHours) + minToMS(endMinutes);
        // this._dateRangeStartTime = `${startHours}${TIME_PICKER_SEP}${startMinutes}`;
        // this._dateRangeEndTime = `${endHours}${TIME_PICKER_SEP}${endMinutes}`;
    }

    private setSingleDateTime() {
        if (!(this.hasTimePicker && this._date)) return;

        const hours = this._date.getHours();
        const minutes = this._date.getMinutes();

        this._time = hourToMS(hours) + minToMS(minutes);
        // this._time = `${hours}${TIME_PICKER_SEP}${minutes}`;
    }

    get icon(): string {
        return this.handler.getDataPickerMode() == EDatePickerMode.TIME
            ? "query_builder"
            : "date_range";
    }

    get isRange(): boolean {
        return this.handler.isRange;
    }

    get hasTimePicker(): boolean {
        return this.handler.mode() === EDatePickerMode.DATETIME;
    }

    setDate(date: Date | Date[]) {
        const startDate: Date = this.handler.isRange ? date[0] : date;
        const endDate: Date = this.handler.isRange ? date[1] : date;

        if (this.handler.isRange) {
            this._dateRange = [startDate, endDate];
            if (
                (startDate && endDate) ||
                this.handler.getComponentParameter().allowEmptyRangeValues
            )
                this.handler.dateRangeChanged(
                    startDate?.getTime(),
                    endDate?.getTime()
                );
        } else {
            this._date = startDate;

            if (this.hasTimePicker) {
                this.setTimeToWithTimestamp(this._date, this._time);
            }

            if (this.handler.mode() === EDatePickerMode.TIME) {
                this.handler.dateChanged(
                    hourToMS(startDate.getHours()) +
                    minToMS(startDate.getMinutes())
                );
            } else {
                this.handler.dateChanged(startDate.getTime());
            }
        }

        this.updateLabel();

        return;
    }

    updateLabel(): void {
        let format: string;
        const time = this.handler.hourFormat()
            ? this.userSettings.getTwelveTimeFormat()
            : this.userSettings.getTwentyFourTimeFormat();
        const date = this.userSettings.getDateFormat();
        const hasDateAtLeastSize = (number: number) =>
            this._dateRange && this._dateRange.length >= number;

        switch (this.handler.mode()) {
            case EDatePickerMode.DATETIME:
                format = `${date} ${time}`;
                break;
            case EDatePickerMode.DATE:
                format = `${date}`;
                break;
            case EDatePickerMode.TIME:
                format = `${time}`;
                break;
        }

        if (this.isRange) {
            const firstDate = hasDateAtLeastSize(1)
                ? this.datePipe.transform(this._dateRange[0], format)
                : "";
            const secondDate = hasDateAtLeastSize(2)
                ? this.datePipe.transform(this._dateRange[1], format)
                : "";
            this.lbl1Value =
                firstDate && secondDate ? firstDate + " - " + secondDate : "";
        } else {
            this.lbl1Value = this.datePipe.transform(
                this._date ? this._date : "",
                format
            );
        }

        this.cdr.markForCheck();
    }

    public openCalendarDialog(event: PointerEvent) {
        event.preventDefault();
        this.open();
    }

    onOpen() {
        this.handler.onOpenCallback();

        if (this.hasTimePicker) {
            this.addHasTimeClassToOverlay();
        }
    }

    onClose() {
        this.handler.onCloseCallback();
    }

    onYearSelected(time: Date) {
        const cb = this.handler.onYearSelectedCallback();
        cb(time.getTime());
    }

    onMonthSelected(time: Date) {
        const cb = this.handler.onMonthSelectedCallback();
        cb(time.getTime());
    }

    isWhite(): boolean {
        return this.handler.getColor() === EDatePickerColor.White;
    }

    isGrey(): boolean {
        return this.handler.getColor() === EDatePickerColor.Grey;
    }

    open() {
        if (this.isRange) {
            this.rangeDatePicker.open();
        } else {
            this.datePicker.open();
        }
    }

    set date(value: Date) {
        this.setDate(value || new Date());
    }

    get date(): Date {
        return this._date;
    }

    _rangeStartDate: Date;
    _rangeEndDate: Date;

    set rangeStartDate(value: Date) {
        this._rangeStartDate = value;
    }
    get rangeStartDate(): Date {
        return this._rangeStartDate;
    }

    set rangeEndDate(value: Date) {
        this._rangeEndDate = value;
    }
    get rangeEndDate(): Date {
        return this._rangeEndDate;
    }

    setRangeIfPossible() {
        if (
            !isValidRef(this._rangeStartDate) ||
            !isValidRef(this._rangeEndDate)
        )
            return;

        if (this.hasTimePicker) {
            this.setTimeToWithTimestamp(
                this._rangeStartDate,
                this._dateRangeStartTime
            );
            this.setTimeToWithTimestamp(
                this._rangeEndDate,
                this._dateRangeEndTime
            );
        }

        this.setDate([this._rangeStartDate, this._rangeEndDate]);
    }

    validateAndSet(_$event: PointerEvent) {
        if (!this.isRange) return;

        const hasInvalidSelection: boolean = this.hasTimePicker
            ? !this._rangeStartDate
            : !this._rangeStartDate || !this._rangeEndDate

        if (hasInvalidSelection) {
            return this.snack.openWarning("Faça uma seleção correta das datas");
        }

        if (this.hasTimePicker && !this._rangeEndDate) {
            this._rangeEndDate = new Date(this._rangeStartDate);
        }

        this.setRangeIfPossible();
    }

    private setTimeToWithTimestamp(target: Date, time: number) {
        const d = new Date(time);
        this.setTimeTo(
            target,
            d.getUTCHours(),
            d.getUTCMinutes(),
            d.getUTCSeconds()
        );
    }

    private setTimeTo(
        target: Date,
        hours: number,
        minutes: number,
        seconds: number
    ) {
        isValidNumber(hours, 0) && target.setHours(hours);
        isValidNumber(minutes, 0) && target.setMinutes(minutes);
        isValidNumber(seconds, 0) && target.setSeconds(seconds);
    }

    private addHasTimeClassToOverlay() {
        const picker = this.isRange ? this.rangeDatePicker : this.datePicker;

        // gambiarra para acessar o overlayRef
        const overlayRef: OverlayRef = (picker as any)._overlayRef;

        if (overlayRef) {
            overlayRef.addPanelClass('date-picker-has-time-overlay')
        }
    }
}
