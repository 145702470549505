import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { DatePipe } from "@angular/common";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input, OnChanges, OnDestroy,
    OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { constant } from '@colmeia/core/src/business/constant';
import { EServiceGroupIntType } from '@colmeia/core/src/business/constant.enums';
import { Participant } from '@colmeia/core/src/business/participant';
import { ICitedAvatar, TCitedAvatarArray } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { StartServiceChat } from '@colmeia/core/src/interaction/service-group/start-service-chat';
import { TypingBroadcaster } from '@colmeia/core/src/interaction/tracker/typing';
import { MMconstant, MultimediaInstance, MultimediaObject, TMultimediaInstanceArray } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { addToMultimediaInstanceArray, getMergedMMObjectIntoInstance } from '@colmeia/core/src/rules/mm-functions';
import { IMacroEntry } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendente-service-pack';
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { ELayoutElement, ILayoutFieldMapperInputMask } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { getStringDate, secToMS } from '@colmeia/core/src/time/time-utl';
import { getClock, insertContentAt, isValidArray, isValidRef } from '@colmeia/core/src/tools/utility';
import { IInternalSupportWindowHandler } from 'app/handlers/chat-bar-interactions/internal-support-window-handler';
import { ReplyChatHandler } from 'app/handlers/chat-bar-interactions/reply-chat-handler';
import { IReplyWindowHandler } from 'app/handlers/chat-bar-interactions/reply-window-handler';
import { DatePickerHandler, EDatePickerType, IDatePickerHandlerParameter } from 'app/handlers/date-picker.handler';
import { MacrosSignal } from 'app/model/signal/macros-signal';
import { ChatActionBarWindowService } from 'app/services/chat-action-bar-window.service';
import { EmbeddedChatService } from 'app/services/embedded-chat.service';
import { LookupService } from 'app/services/lookup.service';
import { MacrosService } from 'app/services/macros.service';
import { PosProcessingInteractionService } from 'app/services/pos-processing-interaction.service';
import { TagsService } from 'app/services/tags.service';
import { SupervisorAgentService } from 'app/supervisor-agent.service';
import { getEmojiFromCode } from 'app/utils/get-emoji';
import EmojiPanel from 'emoji-panel';
import { Subscription } from 'rxjs';
import { filter, first, skip, take, timeout } from 'rxjs/operators';
import { AttachBarHandler, IAttachBarComponentCallback } from "../../../handlers/attach-bar-handler";
import { IChatBarHandler } from "../../../handlers/chat-bar-interactions/chat-bar-handler";
import { ChatBarInteractionHandlerService } from "../../../handlers/chat-bar-interactions/chat-bar-interaction-handler.service";
import { ChatBarWindowHandler } from "../../../handlers/chat-bar-interactions/chat-bar-window-handler";
import { IChatSearchParticipantHandler } from "../../../handlers/chat-bar-interactions/chat-search-participant-handler";
import { ICitationHandlers } from "../../../handlers/chat-bar-interactions/citation/citations-handler";
import { ICitedParticipantsListHandler } from "../../../handlers/chat-bar-interactions/citation/cited-participants-list-handler";
import { HandlerFactoryService } from "../../../handlers/handler-factory.service";
import { IMacro, IMacroChooseListener, MacrosHandler } from "../../../handlers/macros";
import { IRecorderCallBack, RecorderHandler } from "../../../handlers/recorder-handler";
import { ChatBackboneModel } from "../../../model/chat-backbone.model";
import { IChatBarWindowParameter } from "../../../model/chat-bar/chat-bar-window.model";
import {
    formatTextToClient,
    formatTextToServer,
    removeCitationsFromList,
    setCitationsOnText
} from "../../../model/citation-helper";
import { clientConstants } from "../../../model/constants/client.constants";
import { InterfaceInfoSignal } from "../../../model/signal/interface-signal";
import {
    IChangeInterfaceListener,
    IFeatureChangeListener,
    IListenerSubscription
} from "../../../model/signal/ps-interfaces";
import { FeatureSignal } from "../../../model/signal/state-signals/feature-signal";
import { SubscriptionSignal } from "../../../model/signal/subscription-signal";
import { AttendanceService } from "../../../services/attendance.service";
import { IRollbackTransaction } from "../../../services/client.transact.service";
import { HardwareLayerService } from "../../../services/hardware";
import { InteractionPersistorServices } from "../../../services/interaction-persistor.service";
import { MultimediaService } from "../../../services/multimedia.service";
import { SessionService } from "../../../services/session.service";
import { SignalListenerService } from "../../../services/signal/signal-listener";
import { SnackMessageService } from "../../../services/snack-bar";
import { SusbcriptionContainerService } from "../../../services/subscriptions.service";
import { UserSettingsService } from "../../../services/user-settings.service";
import { RootComponent } from "../../foundation/root/root.component";
import { SocketService } from './../../../services/socket.service';
import { ChatActionBarWindowComponent } from './chat-action-bar-window/chat-action-bar-window.component';
import { ChatActionBarService } from './chat-action-bar.service';
import { InternalSupportWindowComponent } from './internal-support-window/internal-support-window.component';
import { NoopActionBarComponent } from "./noop-action-bar/noop-action-bar.component";
import { ReplyWindowComponent } from "./reply-window/reply-window.component";
import { ChatBackboneComponent } from 'app/components/backbone/chat-backbone.component';
import { getAssetOfType } from '@colmeia/core/src/shared-business-rules/bot/bot-client-functions';
import { EBotEventType, IAgentDoesNotAnwer, IBotEvent } from '@colmeia/core/src/shared-business-rules/bot/bot-event-model';
import { type } from 'jquery';
import { ICustomEventsServer } from '@colmeia/core/src/shared-business-rules/attendance-island/custom-events';

interface WindowComponentHash {
    [idInteractionType: string]: any;
}

@Component({
    // changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-chat-action-bar',
    templateUrl: './chat-action-bar.component.html',
    styleUrls: ['./chat-action-bar.component.scss'],
    animations: [
        // Animation that triggers the submenu that moves up when the user clicks the button on the right of the action bar
        trigger('sendFeaturesSubmenuAnimation', [
            transition('* => *', [

                query(':enter', style({ opacity: 0, transform: 'translateX(-40px)' }), { optional: true }),

                query(':enter', stagger('150ms', [
                    animate('0.2s ease-in', keyframes([
                        style({ opacity: 0, transform: 'translateY(75%)', offset: 0 }),
                        style({ opacity: .5, transform: 'translateY(10px)', offset: 0.3 }),
                        style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
                    ]))]), { optional: true }),

                query(':leave', stagger('150ms', [
                    animate('0.3s ease-in', keyframes([
                        style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                        style({ opacity: .5, transform: 'translateY(10px)', offset: 0.3 }),
                        style({ opacity: 0, transform: 'translateY(-75%)', offset: 1.0 }),
                    ]))]), { optional: true })
            ])
        ]),
    ],
    host: {
        ['[class.is-widgets-api-chat]']: 'isWidgetsApi',
    }
})
export class ChatActionBarComponent extends RootComponent<'typeYourMessage'> implements OnInit,
    OnDestroy,
    IAttachBarComponentCallback,
    IRecorderCallBack,
    IChangeInterfaceListener,
    IFeatureChangeListener,
    IListenerSubscription,
    IMacroChooseListener, OnChanges {

    static interationTextContentExceededDuration = secToMS(3);
    static sendInteractionWaitSocketReconnectTimeout = secToMS(10);

    @ViewChild('chatBarWindow', {
        static: true
    }) chatBarWindow: ChatActionBarWindowComponent;

    @ViewChild('chatBarWindow', {
        static: true,
        read: ElementRef
    }) chatBarWindowElRef: ElementRef<HTMLElement>;

    @ViewChild('contenteditableInput', {
        static: true
    }) _contenteditableInput: ElementRef<HTMLDivElement>;

    @ViewChild('emojibox', {
        static: true
    }) emojibox: ElementRef<HTMLDivElement>;

    private rollbackTransaction: IRollbackTransaction;
    private _handler: IChatBarHandler;
    public citedParticipants: TCitedAvatarArray = [];

    private emojiBoxHeight: number = 200;

    public windowBarHeight(): number {
        return this.chatBarWindowElRef?.nativeElement?.clientHeight;
    }

    public getMinHeight(): number {
        let minHeight = 50;
        minHeight += this.showEmojiBox ? this.emojiBoxHeight : 0;
        minHeight += this.windowBarHeight() || 0;

        return minHeight;
    }

    @Input()
    set handler(value: IChatBarHandler) {
        this._handler = value;
        if (value.getInteraction()) {
            this.rollbackTransaction = this._handler.getRollbackTransaction();
        };
        this.handlersInit();
    };

    get handler(): IChatBarHandler {
        return this._handler;
    }

    private WindowComponentByInteractionType: WindowComponentHash = {
        [EServiceGroupIntType.startServiceChat]: InternalSupportWindowComponent,
        [constant.interactionType.standard.citation]: ReplyWindowComponent,
    };
    private windowComponentOutlet: any = NoopActionBarComponent;
    private isEdit: boolean = false;
    private sendingInteraction: boolean = false;
    private lastClockTyping: number;
    private lastClockSentTypingTracker: number;
    public chatBarTypedParticipant: string = '';
    private multimediaInstanceArray: TMultimediaInstanceArray;
    public clientInteraction: Interaction;
    public recordHandler: RecorderHandler;
    public attachHandler: AttachBarHandler;
    private mInstanceHexagonon: MultimediaInstance;
    public canShowMoreFeatures: boolean = false;
    public isInEmbeddedMode: boolean = false;
    private isOnline: boolean;
    private isMobile: boolean = false;
    private lastText: string;
    private editingPosition: number;
    private editingPositionEnd: number;
    private indexOfLastTypedWord: number;
    private actionBarWindowHandler: ChatBarWindowHandler = null;
    public mustShowEmojiPicker: boolean;
    public showEmojiBox: boolean = false;
    public formFieldAnswer: any;
    public showAsCalendarDate: Date;

    private datePipe: DatePipe;

    public interactionPendingDelivery: boolean = false;
    private interactionSubscription: Subscription;
    private isAttendingOnGroup: boolean = true;

    private eventsCache = this.lookupSvc.createNSHashCache('chat-action-bar')

    constructor(
        private cdr: ChangeDetectorRef,
        private sessionSvc: SessionService,
        private listenerSvc: SignalListenerService,
        private multimediaService: MultimediaService,
        private subscriptionContainer: SusbcriptionContainerService,
        private interactionPersistorSvc: InteractionPersistorServices,
        private handlerFactorySvc: HandlerFactoryService,
        private chatBarSvc: ChatActionBarService,
        private hw: HardwareLayerService,
        private snack: SnackMessageService,
        private windowSvc: ChatActionBarWindowService,
        private ActionBarHandlerSVC: ChatBarInteractionHandlerService,
        private AttendanceSVC: AttendanceService,
        private settings: UserSettingsService,
        private tagSvc: TagsService,
        private lookupSvc: LookupService,
        private macrosService: MacrosService,
        private snackBar: MatSnackBar,
        private supervisorAgentService: SupervisorAgentService,
        private socketSvc: SocketService,
        private embeddedChatSvc: EmbeddedChatService,
        private posProcessingInteractionSvc: PosProcessingInteractionService,
        private chatBackboneComponent: ChatBackboneComponent
    ) {
        super({
            typeYourMessage: gTranslations.fragments.typeYourMessage
        }, true, cdr);
        this.datePipe = new DatePipe(this.settings.getSelectedLocale());
        this.mustShowEmojiPicker = this.hw.isBrowser() || this.hw.isAndroid();
        this.lastClockSentTypingTracker = this.lastClockTyping = 0;
        this.isOnline = true;
        this.listenerSvc.listenToInterfaceChanges(this);
        this.listenerSvc.listenToChatBarHandlerChange(this, this.sessionSvc.getSelectedGroupID());
        this.isMobile = this.hw.isMobile();
        this.listenerSvc.listenSubscriptionChanges(this);
        this.listenerSvc.listenToMacros(this);
        this.macrosService.setActionBarComponentContext(this);
        this.isInEmbeddedMode = this.sessionSvc.isEmbeddedChat();
    }

    get isWidgetsApi(): boolean {
        return this.embeddedChatSvc.isWidgetsApi
    }

    ngOnInit() {
        this.init();
        this.interactionSubscription = this.posProcessingInteractionSvc.posProcessedInteraction$().subscribe(() => {
            if (this.isFieldShowAsCalendar() && !this.formFieldAnswer) {
                this.calendarValue = this.showAsCalendarDate;
            }

            this.cdr.markForCheck();
        });

        this.insertFirstMessage();
    }

    private async insertFirstMessage() {
        const firstMessage = this.embeddedChatSvc.getAndRemoveFirstMessage();

        if (!firstMessage) return;

        await this.sendInteraction(firstMessage.content);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        console.log('ChatActionBarComponent changes')
    }

    public init(): void {
        const currentGroupID = this.sessionSvc.getSelectedGroupID();
        this.isAttendingOnGroup = this.AttendanceSVC.isAttendingOnGroup(currentGroupID);

        this.handlersInit();
        this.buildEmojiBoxIfNeeded();
        this.generateDefaultHandler();        
    }

    toggleEmojiBox(): void {
        // if (!this.showEmojiBox) {
        //     this.canShowMoreFeatures = false;
        // }
        this.showEmojiBox = !this.showEmojiBox;
    }

    get emojiBoxClasses(): string {
        const result = ['emoji-box'];

        if (this.showEmojiBox)
            result.push('emoji-box-visible');

        return result.join(' ');
    }

    buildEmojiBoxIfNeeded(): void {
        if (this.mustShowEmojiPicker) {
            new EmojiPanel(this.emojibox.nativeElement, {
                onClick: (optionClicked) => {
                    const emoji = getEmojiFromCode(optionClicked.unified);
                    this.setContentEditableInputText(
                        this.getContentEditableInputText() + emoji
                    )
                    this.cdr.markForCheck();
                }
            });
        }
    }

    ngAfterViewInit() {
    }


    private generateDefaultHandler(): void {
        this.handler = this.ActionBarHandlerSVC.handlerFor(constant.interactionType.standard.message, undefined, null, null)
    }

    changeSubscriptionSignCallback(subscriptionSignal: SubscriptionSignal): void {
        // this.listenerSvc.destroySubscriptions(this);
        // this.listenerSvc.listenSubscriptionChanges(this);
        // this.listenerSvc.listenToChatBarHandlerChange(this, subscriptionSignal.getGroupID());
    }

    setClientInteraction(idInteractionType: TGlobalUID, interaction: Interaction, parentInteraction: Interaction): void {
        this.clientInteraction = interaction;
        this.updateActionBarWindowDependencies(idInteractionType, interaction, parentInteraction);
        this.setContentEditableInputText(
            interaction.getMessage()
        );
        this.updateCitationHandlers(interaction);
        this.updateWindowComponent();
        this.updatePlaceHolderLabel(interaction);
        this.buildWindowHandlerIfNeeded();
    }

    private updateWindowComponent(): void {
        const idInteractionType = this.clientInteraction.getInteractionType().getPrimaryID();
        if (idInteractionType in this.WindowComponentByInteractionType) {
            this.windowComponentOutlet = this.WindowComponentByInteractionType[idInteractionType];
            return;
        }
        this.windowComponentOutlet = NoopActionBarComponent;
    }

    buildWindowHandlerIfNeeded(): void {
        if (isValidRef(this.actionBarWindowHandler)) {
            this.actionBarWindowHandler.getParameter().outlet = this.windowComponentOutlet;
        } else {
            this.buildActionBarWindowHandler({
                outlet: this.windowComponentOutlet,
            });
        }
    }

    updateCitationHandlers(interaction: Interaction): void {
        this.citedParticipants = interaction.getCitations();
        if (!isValidArray(this.citedParticipants)) {
            this.citedParticipants = [];
        };
        if (this.citedParticipants.length > 0) {
            this.setContentEditableInputText(setCitationsOnText(this.citedParticipants, interaction.getMessage()));
            this.toggleCitationSearch();
        }
    }

    updatePlaceHolderLabel(interaction: Interaction): void { }

    updateActionBarWindowDependencies(idInteractionType: TGlobalUID, interaction: Interaction, parentInteraction: Interaction): void {
        // const iType: InteractionType = interaction.getInteractionType();
        switch (idInteractionType) {
            case constant.interactionType.standard.citation:

                const datePickerHandler = (<ReplyChatHandler>this.handler).isReplyWithRemember ? this.getDatePickerHandler() : null;

                this.windowSvc.setCarrier<IReplyWindowHandler>({
                    interaction,
                    datePickerHandler
                },
                    constant.interactionType.standard.citation);

                this.setInputFocus();
                break;
            case EServiceGroupIntType.startServiceChat:
                const intSuppHandler: IInternalSupportWindowHandler = {
                    interaction: <StartServiceChat>interaction,
                    isEditing: this.isEdit,
                }
                this.windowSvc.setCarrier<IInternalSupportWindowHandler>(intSuppHandler, EServiceGroupIntType.startServiceChat);
                break;
            default:
                this.windowSvc.setCarrier<null>(null, '');
                break;
        }
        this.setInputFocus();
    }

    updateIsEdit(isEdit: boolean): void {
        this.isEdit = isEdit;
    }

    // Feature Emissor Service
    setFeaturedChatBarHandlerCallback(sign: FeatureSignal): void {
        this.chatBarWindow.setShowOutlet(false);
        this.windowSvc.onWindowDestroy();
        const handler = sign.getChatHandler();
        this.handler = handler;
        const interaction: Interaction = handler.getInteraction();
        const parentInteraction: Interaction = handler.getInteractionParent();
        this.updateIsEdit(handler.isEditing());
        this.setClientInteraction(
            handler.getInteractionType().getPrimaryID(),
            interaction,
            parentInteraction
        );
        this.chatBarWindow.setShowOutlet(true);
        this.chatBarWindow.markForCheck();
    }

    private buildActionBarWindowHandler(options: Partial<IChatBarWindowParameter>) {
        this.actionBarWindowHandler = new ChatBarWindowHandler({
            ...ChatBarWindowHandler.getDefaultParameter(this),
            ...options,
            chatBackboneModel: ChatBackboneModel.getBackboneModel(this.sessionSvc.getSelectedGroupID()),
            onUserWindowClosed: () => {
                this.onChatBarWindowClosed();
            },
            UIUpdate: () => {
                this.cdr.markForCheck();
            }
        });
        this.updateChatBarWindowView();
    }

    toggleMoreFeatures(event: MouseEvent): void {
        event.preventDefault();
        // if (this.showEmojiBox) {
        //     this.showEmojiBox = false;
        // }
        this.canShowMoreFeatures = !this.canShowMoreFeatures;
    }

    ngOnDestroy(): void {
        this.listenerSvc.destroySubscriptions(this);
        this.interactionSubscription?.unsubscribe();
    }

    receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        this.cdr.markForCheck();
    }

    public getClientInteraction(): Interaction {
        if (!this.clientInteraction) {
            this.clientInteraction = this.handler.getNewInteraction(this.handler.getParticipant());
        }

        return this.clientInteraction;
    };

    public resetInteraction(): void {
        this.clientInteraction = null;
    }

    getCitedParticipantsListHandler(): ICitedParticipantsListHandler {
        return {
            citedParticipantsList: this.citedParticipants
        }
    }

    public hideActionWindow(): boolean {
        if (isValidRef(this.chatBarWindow) && isValidRef(this.actionBarWindowHandler)) {
            return !this.chatBarWindow.isValidWindow();
        } else {
            return true;
        }
    }

    public getActionBarWindowHandler(): ChatBarWindowHandler {
        return this.actionBarWindowHandler;
    }


    public async sendInteractionWrapper(event?: Event): Promise<void> {
        event?.preventDefault();

        if (!this.canSendMessage() || this.sendingInteraction || this.interactionPendingDelivery) {
            return;
        }

        if (this.hw.getNetwork().isOnline()) {
            this.sendInteraction();
        } else {
            // this.sessionSvc.sendSocketDebug('onSendInteractionClicked', {});

            this.interactionPendingDelivery = true;
            this.cdr.markForCheck();

            this.socketSvc.lastSocketConnStatus$.pipe(
                skip(1),
                filter(ev => ev === "restored"),
                first(),
                timeout(ChatActionBarComponent.sendInteractionWaitSocketReconnectTimeout)
            ).subscribe(() => {
                this.interactionPendingDelivery = false;
                this.cdr.markForCheck();
                this.sendInteraction();
            }, () => {
                this.interactionPendingDelivery = false;
                this.cdr.markForCheck();
            });

            // const serializable: Serializable = Serializable.staticFactory(errorCodes.client.connection.clientConnectionError);
            // this.snack.openError(serializable.getSerializableText(constant.serializableField.name));
        }
    }

    private async sendInteraction(interationTextContent?: string) {
        try {
            this.sendingInteraction = true;
            this.cdr.markForCheck();
            const interactionToSend = this.getClientInteraction();

            if (this.isAttendingOnGroup) {
                const attIsland = this.AttendanceSVC.getCurrentIsland();
                await this.eventsCache.hydrate([attIsland.eventsId]);

                const islandEvents = <ICustomEventsServer>this.eventsCache.hash[attIsland.eventsId];

                const event = getAssetOfType<IAgentDoesNotAnwer>(islandEvents.events, EBotEventType.providerExpire);

                if (isValidRef(event) && !event.onlyFistAnswer) {
                    interactionToSend.setIslandEventsId(attIsland.eventsId);
                }

            }

            if (this.hasFieldEngagement) {
                interactionToSend.setWebChatConfig(this.embeddedChatSvc.getWebChatConfigOfLastFormField());
            }

            interactionToSend.setCitations(this.citedParticipants);
            interationTextContent ??= formatTextToServer(this.getContentEditableInputText());

            if (interationTextContent.length >= clientConstants.UI.maxInterationTextLength) {
                this.snackBar.open(`Você excedeu o limite do tamanho de texto: ${clientConstants.UI.maxInterationTextLength}`, "Fechar", {
                    duration: ChatActionBarComponent.interationTextContentExceededDuration
                });

                this.cdr.markForCheck();

                return;
            }

            this.clientInteraction.setMessage(interationTextContent)
            // const interactionToSend: Interaction = this.getClientInteraction();
            this.multimediaInstanceArray = getMergedMMObjectIntoInstance(interactionToSend.getMultimediaObject(), this.multimediaInstanceArray);

            await this.handler.beforeInteractionSave(interactionToSend);
            await this.handler.saveInteraction(interactionToSend, this.multimediaInstanceArray, null);
            await this.handler.afterInteractionSave(interactionToSend);

            this.setInputFocus();
            this.cdr.markForCheck();
        } catch (err) {
            this.handler.rollback(this.rollbackTransaction);
            throw err;
        } finally {
            this.sendingInteraction = false;
            this.canShowMoreFeatures = false;
            this.formFieldAnswer = undefined;
            this.showAsCalendarDate = undefined;
            this.resetchatActionBarState();
        }
    }

    public isLoading(): boolean {
        return this.interactionPendingDelivery || this.sendingInteraction;
    }

    isSendButtonAvailable(): boolean {
        return this.isOnline;
    }

    public canSendMessage(): boolean {
        return this.textMessageReadyToSent() || this.hasMultimedia();
    }

    private textMessageReadyToSent(): boolean {
        const textMessage = this.getContentEditableInputText();
        return (textMessage &&
            textMessage.length > 0 &&
            textMessage !== '\x0a');
    }

    public hasMultimedia(): boolean {
        return this.multimediaInstanceArray && this.multimediaInstanceArray.length > 0;
    };

    public getGroupID(): TGlobalUID {
        return this.subscriptionContainer.getSelectedGroupID();
    }

    updateChatBarWindowView(): void {
        setTimeout(() => {
            this.chatBarWindow.markForCheck();
            this.cdr.markForCheck();
        }, 100);
    }

    public onChatBarWindowClosed(): void {
        if (this.rollbackTransaction) {
            this.handler.rollback(this.rollbackTransaction);
        }
        this.resetchatActionBarState(false);
        this.cdr.markForCheck();
    }

    public onInput(event: any) {
        if (event.inputType == 'deleteContentBackward') {
            this.removeCitations();
        }
    }

    public async onKeyUp(event: KeyboardEvent): Promise<void> {
        this.updateEditingPosition();

        if (event.keyCode === 8 || event.keyCode === 46)
            this.removeCitations()

        // this.sendTyping()

        if (!await this.checkIfMacroHasAccioned()) return this.checkIfParticipantWasTyped()
    }

    lineOfEditingPosition: number = 0;

    updateEditingPosition() {
        const selection = window.getSelection();

        if (selection && selection.rangeCount > 0) {
            const anchor = selection.anchorNode;
            const isEmptyLineOfRoot = anchor.isSameNode(this._contenteditableInput.nativeElement);
            const range = selection.getRangeAt(0);
            const preSelectionRange = range.cloneRange();
            preSelectionRange.selectNodeContents(this._contenteditableInput.nativeElement);
            preSelectionRange.setEnd(range.startContainer, range.startOffset);
            const start = preSelectionRange.toString().length + (isEmptyLineOfRoot ? selection.anchorOffset : 0);
            const end = start + range.toString().length;
            this.editingPosition = start;
            this.editingPositionEnd = end;

            const nodesArr = [];

            this._contenteditableInput.nativeElement.childNodes.forEach(n => {
                if (!n) return;
                if (n.nodeName === 'BR' && n.previousSibling.nodeName === 'BR') {
                    nodesArr.push(n);
                }

                if (n.nodeName === '#text') {
                    nodesArr.push(n);
                }
            });

            this.lineOfEditingPosition = isEmptyLineOfRoot
                ? selection.anchorOffset
                : nodesArr.findIndex(n => anchor.isSameNode(n))
        }
    }

    public async checkIfMacroHasAccioned(): Promise<boolean> {
        if (this.sessionSvc.iAmAttendingOnCurrentGroup()) {
            if (this.checkIfMacrosTriggerWasPressed()) {
                const macrosHandler: MacrosHandler = await this.buildMacrosHandlerAndMountWindowBarHandlerForMacros();

                return true;
            } else if (this.showMacros) {
                this.clearMacrosHandler();
                return false;
            }
        }
        return false;
    }

    private mountWindowBarHandlerForMacros(macrosHandler: MacrosHandler) {
        this.buildActionBarWindowHandler({
            macros: macrosHandler,
        })

        if (isValidRef(this.actionBarWindowHandler)) {
            this.actionBarWindowHandler.getParameter().macros = macrosHandler;
            this.chatBarWindow.markForCheck()
        }

        this.cdr.markForCheck()

    }

    public onKeyDown(event: KeyboardEvent): void {
        this.lastText = this.getMessageToServer();
        if (event.key === 'Enter' && !event.shiftKey && this.hw.isBrowser()) {
            event.preventDefault();
            this.sendInteractionWrapper();
        }
    };

    private removeCitations(): void {
        if (this.getMessageToServer() === '') {
            this.setContentEditableInputText('');
        }
        this.removeCitationFromText();
        this.removeCitationsFromList();
        this.cdr.markForCheck();
    }

    private removeCitationFromText(): void {
        const selection = window.getSelection();
        if (selection.anchorNode && selection.anchorNode.parentElement.tagName === 'CM-CITATION') {
            selection.anchorNode.parentNode.removeChild(selection.anchorNode);
            this.setContentEditableInputText(setCitationsOnText(this.citedParticipants, this.getMessageToServer()));
            this.setInputFocus();
        }
    }

    getPersonalGroupParticipant(): Participant {
        return this.handler.getPersonalGroupParticipant()
    }

    private removeCitationsFromList(): void {
        if (this.citedParticipants.length > 0) {
            this.citedParticipants = removeCitationsFromList(this.citedParticipants, this.getMessageToServer());
        }
    }

    toggleCitationSearch(searchParticipantHandler?: IChatSearchParticipantHandler): void {
        const searchHandler = searchParticipantHandler ? searchParticipantHandler : null;

        const citationHandlers: ICitationHandlers = {
            listed: {
                citedParticipantsList: this.citedParticipants,
            },
            search: searchHandler,
        }

        if (isValidRef(this.actionBarWindowHandler)) {
            this.actionBarWindowHandler.getParameter().citation = citationHandlers;
        } else {
            this.buildActionBarWindowHandler({
                citation: citationHandlers,
            });
        }
    }

    public onParticipantSelected(citedAvatar: ICitedAvatar): void {
        if (!citedAvatar)
            return;

        let inputText = this.getMessageToServer();
        inputText = inputText.substr(0, this.indexOfLastTypedWord) + '@' + citedAvatar.name + String.fromCharCode(160) + inputText.substr(this.editingPosition + 1);
        inputText = setCitationsOnText(this.citedParticipants, inputText);
        this.setContentEditableInputText(inputText);
        this.setInputFocus();
        this.toggleCitationSearch();
        this.cdr.markForCheck();
    }

    private checkIfParticipantWasTyped() {
        this.chatBarTypedParticipant = '';
        // checks if last word typed first char is '@'
        if (this.isOpenCitation()) {
            // open participant window
            this.chatBarTypedParticipant = this.getLastTypedWord().trim().slice(1);
            const searchHandler: IChatSearchParticipantHandler = {
                citedParticipants: this.citedParticipants,
                group: this.getClientInteraction().getParticipant().getGroup(),
                setCitedParticipantInText: (citedAvatar) => this.onParticipantSelected(citedAvatar),
                inputText: this._contenteditableInput,
            }
            this.toggleCitationSearch(searchHandler);
        } else {
            this.toggleCitationSearch();
        }

        setTimeout(() => {
            if (this.chatBarWindow) {
                this.chatBarWindow.onTypedMessage(this.chatBarTypedParticipant);
            }
        }, 0);
    }

    private isOpenCitation(): boolean {
        const inputText = this.getMessageToServer().split(String.fromCharCode(160)).join(' ');

        if (inputText[this.editingPosition - 1] === '@'
            && (!isValidRef(inputText[this.editingPosition - 2]) || inputText[this.editingPosition - 2] === ' ' || inputText[this.editingPosition - 2] === '\n')
            && (!isValidRef(inputText[this.editingPosition]) || inputText[this.editingPosition] === ' ' || inputText[this.editingPosition] === '\n')) {
            this.indexOfLastTypedWord = this.editingPosition - 1;
            this.chatBarTypedParticipant = '';
            return true;
        }

        if (this.getLastTypedWord()[0] === '@') {
            this.chatBarTypedParticipant = this.getLastTypedWord().slice(1);
            return true;
        }

        return false
    }

    private getLastTypedWord(): string {
        let inputText = this.getMessageToServer();
        inputText = this.breakLinesToWhiteSpaces(inputText);

        if (!inputText) {
            return '';
        }

        let lastTypedWord = '';
        for (let i = this.editingPosition - 1; i >= 0; i--) {
            if (inputText.charAt(i) == ' ' || inputText.charAt(i) == '\u00A0') {
                lastTypedWord = inputText.substring(i + 1, this.editingPosition + 1);
                this.indexOfLastTypedWord = i + 1;
                break;
            } else if (i === 0) {
                lastTypedWord = inputText.substring(i, this.editingPosition + 1);
                this.indexOfLastTypedWord = i;
                break;
            }
        }
        return lastTypedWord;
    }

    private breakLinesToWhiteSpaces(text: string): string {
        return text.replace(/(?:\r\n|\r|\n)/g, ' ');
    }

    //#region Reset
    public resetchatActionBarState(resetText: boolean = true): void {
        this.windowSvc.onWindowDestroy();
        this.resetInteraction();
        if (resetText) {
            this.setContentEditableInputText('');
        }
        this.mInstanceHexagonon = null;
        this.generateDefaultHandler();
        this.handler.resetChatBarHandler();
        this.handlersInit();
        this.chatBarTypedParticipant = '';
        this.citedParticipants = [];
        this.multimediaInstanceArray = [];
        this.notifyChatBarResetedToObservers();
        this.canShowMoreFeatures = false;
        this.showEmojiBox = false;
        this.actionBarWindowHandler = null;
        this.cdr.markForCheck();
    }

    notifyChatBarResetedToObservers(): void {
        this.chatBarSvc.chatBarReseted(this.getClientInteraction().getPrimaryID());
    }
    //#endregion

    toggleMediaRecorder() {
        if (isValidRef(this.actionBarWindowHandler)) {
            const isShowingRecorder: boolean = this.actionBarWindowHandler.getShowAudioRecorder();

            if (isShowingRecorder) {
                this.clearVoiceMessages();
            }

            this.actionBarWindowHandler.setAudioRecorderHandler(this.recordHandler);
            this.actionBarWindowHandler.setShowAudioRecorder(!isShowingRecorder);
            this.updateChatBarWindowView();
        } else {
            this.buildActionBarWindowHandler({
                audioRecorder: true,
                audioRecorderHandler: this.recordHandler
            });
        }
    }

    public openAttachments(uploadWindowOpen: boolean = false, event?: PointerEvent): void {
        console.log({event})
        event?.preventDefault();
        this.attachHandler.setUploadWindowOpen(uploadWindowOpen);
        if (isValidRef(this.actionBarWindowHandler)) {
            this.actionBarWindowHandler.setAttachmentHandler(this.attachHandler);
            this.actionBarWindowHandler.setShowAttachment(
                !this.actionBarWindowHandler.getShowAttachment()
            )
            this.updateChatBarWindowView();
        } else {
            this.buildActionBarWindowHandler({
                attachment: true,
                attachmentHandler: this.attachHandler,
            });
        }
    }

    public sendTyping(): void {
        this.lastClockTyping = getClock();
        if (this.lastClockTyping > this.lastClockSentTypingTracker + clientConstants.tracker.typingTracker.msToResendTypingTracker) {
            const participant = this.handler.getParticipant();

            this.interactionPersistorSvc.sendSocketInteraction(
                TypingBroadcaster.getNewTypingBroadcaster(participant)
            );
            this.lastClockSentTypingTracker = getClock();
        };
    }

    // handlers INIT
    public handlersInit(): void {
        this.multimediaInstanceArray = [];

        // como é chamado na inicialização do componente pai, precisa ser checado.
        if (this.handler) {
            const allMimesTypes: string[] = this.hw.getGenericPlataform().getMultimediaAllowedMIMETypes();
            const currentGroupID = this.sessionSvc.getSelectedGroupID();
            const mimeTypeFilter: string[] = this.isAttendingOnGroup
                ? allMimesTypes.filter((mime) => !this.AttendanceSVC.notAllowedMimeTypes.includes(mime))
                : allMimesTypes

            const maxFileBytesSize: number = this.isAttendingOnGroup
                ? this.AttendanceSVC.getMaxFileSizePerChannel(currentGroupID)
                : -1;

            this.attachHandler = new AttachBarHandler({
                idPlayer: this.sessionSvc.getPlayerID(),
                idAvatar: this.sessionSvc.getAvatarID(),
                clientCallback: this,
                idMultimediaTag: this.handler.getMultiMediaTagID(),
                maxNumberOfFiles: this.handler.getNumberOfFilesAllowed(),
                multimediaService: this.multimediaService,
                mimeTypeFilter,
                maxFileBytesSize,
                generateHashNow: true,
            });

            this.recordHandler = new RecorderHandler({
                clientCallback: this,
                secondsAllowed: clientConstants.multimedia.maxRecordingTime,
            });

            this.cdr.markForCheck();
        }
    }

    public onStopRecording(multimediaIntance: MultimediaInstance): void {
        this.clearVoiceMessages();

        this.multimediaInstanceArray.push(multimediaIntance);
        this.cdr.markForCheck();
    }

    private clearVoiceMessages() {
        this.multimediaInstanceArray = this.multimediaInstanceArray
            .filter(elem => elem.getTag().getPrimaryID() != MMconstant.tag.voiceMessage);
    }

    public onCancelRecording(): void {
        this.toggleMediaRecorder();
    };

    // IAttachBarComponentCallback compliance
    public async onMultimediaSelected(instanceArray: TMultimediaInstanceArray) {
        instanceArray.forEach(
            mm => addToMultimediaInstanceArray(mm, this.multimediaInstanceArray)
        );
        this.cdr.markForCheck();
    }

    public onClearMultimedia(): void {
        this.cdr.markForCheck();
    }

    public onSelectedFile(identifier: string, multimediaInstance: MultimediaInstance): void {
        addToMultimediaInstanceArray(multimediaInstance, this.multimediaInstanceArray);
        // this.multimediaInstanceArray.push(multimediaInstance);
        this.mInstanceHexagonon = multimediaInstance;
    };

    public onClearFile(multimediaInstance: MultimediaInstance): void {
        if (this.mInstanceHexagonon) {
            const idx: number = this.multimediaInstanceArray.findIndex((m) => { return m.iss(this.mInstanceHexagonon) });
            if (idx > -1) {
                this.multimediaInstanceArray.splice(idx, 1);
                this.mInstanceHexagonon = null;
            };
        };
    }

    getInputPlaceholder(): string {
        const handlerPlaceholder = this.handler.getInputPlaceholder();
        if (isValidRef(handlerPlaceholder)) {
            return handlerPlaceholder;
        }
        return this.translations.typeYourMessage.value;
    }

    get sendBtnClasses(): string {
        const base = ['send-button', 'send-msg'];

        if (!this.hw.getNetwork().isOnline()) {
            base.push('send-disabled');
        }

        return base.join(' ');
    }

    get canShowBtnOverInput(): boolean {
        return !this.canShowMoreFeatures
        // && !this.canSendMessage()
        // && this.hideActionWindow();
    }

    private getMessageToClient(): string {
        return formatTextToClient(this.getClientInteraction().getMessage());
    }

    private getMessageToServer(): string {
        return formatTextToServer(this.getContentEditableInputText());
    }

    private setInputFocus(index?: number): void {


        setTimeout(() => {
            const el = this._contenteditableInput.nativeElement;

            if (this.isMobile) {
                this.scrollToBottomOfContentEditable();
                el.focus();
            } else {
                let range = document.createRange();

                let selection = window.getSelection();

                if (index !== undefined) {

                    let indexNodeOfTargetIndexLength: number = 0;
                    let lastLineLength = index;

                    if (el.childNodes.length > 1) {
                        let targetIndex = index;
                        let currentLinesIndexLength = 0;
                        let finded = false;

                        el.childNodes.forEach((el: HTMLElement, idx) => {
                            if (el.nodeName === 'BR' && el.previousSibling.nodeName === '#text') return;

                            const lineLengthOfBRElement = 2;
                            const lineLength = el.textContent === ''
                                ? lineLengthOfBRElement
                                : el.textContent.length;

                            currentLinesIndexLength += lineLength;

                            if (currentLinesIndexLength >= targetIndex && !finded) {
                                indexNodeOfTargetIndexLength = idx;

                                let diff = currentLinesIndexLength - index;

                                lastLineLength = lineLength - diff;
                                finded = true;
                            }
                        });


                    }

                    try {
                        range.setStart(el.childNodes[indexNodeOfTargetIndexLength], lastLineLength);
                        range.setEnd(el.childNodes[indexNodeOfTargetIndexLength], lastLineLength);
                    } catch { }

                } else {
                    this.scrollToBottomOfContentEditable();
                    range.selectNodeContents(el);
                    range.collapse(false);
                }

                selection?.removeAllRanges();
                selection?.addRange(range);
            }
        }, 0);
    }

    private scrollToBottomOfContentEditable(): void {
        this._contenteditableInput.nativeElement.scrollTop = this._contenteditableInput.nativeElement.scrollHeight;
    }

    getContentEditableInputText(): string {
        if (this.hasFieldEngagement) {
            return this.formFieldAnswer;
        }

        return this._contenteditableInput.nativeElement.innerHTML;
    }

    setContentEditableInputText(text: string): void {
        this._contenteditableInput.nativeElement.innerHTML = text;
    }

    onPaste(evt: ClipboardEvent): void {
        evt.preventDefault();
        const clipboardData = evt?.clipboardData;
        if (!clipboardData) {
            return;
        }
        const clipboardFiles = clipboardData.files;
        const clipboardText = clipboardData.getData('text/plain');
    
        if (isValidArray(Array.from(clipboardFiles))) {
            evt.stopPropagation();
            evt.preventDefault();

            if (!isValidRef(this.actionBarWindowHandler)) {
                this.openAttachments();
            }

            this.attachHandler.component$().pipe(take(1)).subscribe((component) => {
                component.forceUpload(clipboardFiles);
            });
        } else if (clipboardText) {
            const selection = window.getSelection();
            if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                range.deleteContents();
                range.insertNode(document.createTextNode(clipboardText));
            }
        }

    }

    private checkIfMacrosTriggerWasPressed(): boolean {
        const inputText = this.getMessageToServer().split(String.fromCharCode(160)).join(' ');
        return inputText[this.editingPosition - 1] === '/';
    }

    public showMacros: boolean = false;

    async buildMacrosHandlerAndMountWindowBarHandlerForMacros(): Promise<MacrosHandler> {
        const macrosHandler = await this.macrosService.buildMacrosHandlerByContext()

        this.mountWindowBarHandlerForMacros(macrosHandler)

        return macrosHandler
    }

    clearMacrosHandler(): void {
        this.showMacros = false;
        if (isValidRef(this.actionBarWindowHandler)) {
            this.actionBarWindowHandler.getParameter().macros = undefined;
        } else {
            this.buildActionBarWindowHandler({
                macros: undefined,
            });
        }
        this.chatBarWindow.markForCheck();
    }




    macroEntryToIMacro(entry: IMacroEntry): IMacro {
        return {
            template: entry.variablesTemplate,
            name: entry.name
        };
    }



    async listenToMacros(sign: MacrosSignal) {
        const entry: IMacroEntry = sign.getObservableInformation()

        if (!this.hasMacroHandler)
            await this.buildMacrosHandlerAndMountWindowBarHandlerForMacros()

        if (!entry) return this.clearMacrosHandler()

        const macro: IMacro = this.macroEntryToIMacro(entry)

        const render = await this.actionBarWindowHandler.getParameter().macros.renderMacro(macro);
        this.onMacroClicked(render);

        if (isValidRef(entry.media)) {
            const mmObj = MultimediaObject.getNewMultimediaObjectFromMultimediaInstanceJSON([entry.media]);
            
            for (const mmInstance of mmObj.getAllMultimediaInstance()) {
                this.onSelectedFile("", mmInstance);
            }
            
            this.openAttachments();
            this.attachHandler.component$().pipe(take(1)).subscribe((component) => {
                component.setMMObject(mmObj);
            });
        }
        
        this.chatBackboneComponent.closeSidebars();
    }



    get hasMacroHandler() {
        try {
            return !!this.actionBarWindowHandler.getParameter().macros
        } catch {
            return false
        }
    }


    onMacroClicked(output: string): void {
        this.setContentEditableInputText(
            `${this.getContentEditableInputText()}`.slice(0, -1) + ' ' + output
        );
        this.clearMacrosHandler();
        this.setInputFocus();
    }

    iAmAttendingOnCurrentGroup(): boolean {
        return this.sessionSvc.iAmAttendingOnCurrentGroup();
    }

    private getDatePickerHandler(): DatePickerHandler {
        const dateParameter: IDatePickerHandlerParameter = {
            type: EDatePickerType.Single,
            onPick: (time: number) => {
                // this.getClientInteraction().setRememberMe(time, this.getPersonalGroupParticipant());
            },
            enabled: true,
        }

        return new DatePickerHandler(dateParameter);
    }

    get canSendAudioMessage(): boolean {
        return !(this.sessionSvc.iAmAttendingOnCurrentGroup())
            || this.AttendanceSVC.isAllowedToSendAudioMessages(this.sessionSvc.getSelectedGroupID());
    }

    get hasFieldEngagement(): boolean {
        return !!this.embeddedChatSvc.getFormFieldEngagementLayout();
    }

    public getFormFieldType(): string {
        const hasSecretLayout: boolean = this.embeddedChatSvc.getFormFieldEngagementLayout()?.type === ELayoutElement.secret;

        if (hasSecretLayout) {
            return 'password';
        }

        return 'text';
    }

    public getFormFieldMask(): ILayoutFieldMapperInputMask {
        const maskConfigured: ILayoutFieldMapperInputMask = this.embeddedChatSvc.getFormFieldEngagementLayout();
        const hasMaskConfigured: boolean = maskConfigured?.type === ELayoutElement.inputMask;

        return hasMaskConfigured
            ? maskConfigured
            : {} as ILayoutFieldMapperInputMask
    }

    public isFieldShowAsCalendar(): boolean {
        return this.embeddedChatSvc.isLastFormFieldShowAsCalendar();
    }

    public set calendarValue(date: Date) {
        this.showAsCalendarDate = date;
        this.formFieldAnswer = getStringDate(date.getTime());
    }

    public get calendarValue(): Date {
        return this.showAsCalendarDate;
    }
}
