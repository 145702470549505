import { TCRMConfigNSTypes } from "../shared-business-rules/crm/crm-services/crm-config-interfaces";
import { ENonSerializableObjectType } from "../shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { asConst } from "../tools/type-utils";
import { DeepFindValuesOfType } from "../tools/utility-types";
import { ELookupType } from "./lookup-request";

// Requisições apenas para abrir Execution com request
export enum EExecutionRequest {
    assigCaseToAgent = 'ex.assigCase',
    assigTicketToAgent = 'ex.assigTicket'
}

export enum ESecurityControlRequestType {
    save = 'smc.save',
    list = 'smc.list',
    checkCurrentMenuRule = 'smc.check'
}

export enum ESharedServicesRequestType {
    save = 'ss.save',
    list = 'ss.list'
}

export enum EPowerBIDashboard {
    getConfigInfo = 'pbi.getConfigInfo',
    setConfigInfo = 'pbi.setConfigInfo',
    getWorkspaceInfo = 'pbi.getWorkspaceInfo',
    setWorkspaceInfo = 'pbi.setWorkspaceInfo',
    extraConfigMetadata = 'pbi.extraConfigMetadata',
    upsertReportInfo = 'pbi.upsertReportInfo',
    deleteReportInfo = 'pbi.deleteReportInfo',
    upsertAnalyticsReportInfo = 'pbi.upsertAnalyticsReportInfo',
    getAnalyticsReports = "pbi.getAnalyticsReports",
}

export enum EBIRequest {
    getEmbbededUrl = 'bi.getEmbbededUrl'
}

export enum EAttendantFollowUpRequestType {
    joinAttendence = 'af.joinAttendence',
    revokeSupervisor = 'af.revokeSupervisor',
    getAllAttendenceFromAgent = 'af.getAllAttendenceFromAgent',
    getCaseFromCustomerBeingAttendent = 'af.getCaseFromCustomerBeingAttendent'
}

export enum EJournalingRequestTypes {
    updateAllJournalingToVersionID = "jr.updateAllJournalingToVersionID",
    getAllMigrationVersions = 'jr.getAllMigrationVersions',
    upsertMigrationVersion = 'jr.upsertMigrationVersion',
    listAllNonSerializablesChangedInVersion = 'jr.listAllNonSerializablesChangedInVersion',
    listAllNonSerializablesChangedWithoutVersion = 'jr.listAllNonSerializablesChangedWithoutVersion',
    addChanngedJournalingsToVersion = 'jr.addChanngedJournalingsToVersion',
    getAllChangesFromNonSerializable = "jr.getAllChangesFromNonSerializable",
    getObjectDeployDataRequest = 'jr.getObjectDeployDataRequest',
    deleteNonSerializablesFromVersion = 'jr.deleteNonSerializablesFromVersion',
    transferNonSerializablesToVersion = 'jr.transferNonSerializablesToVersion',
}

export enum ESecurityApiRequestType {
    init = 'sc.init',
    signUp = 'sc.signUp',
    signIn = 'sc.signIn',
    logUserFromEmail = 'sc.logEmail',
    logUserFromShakingHands = 'sc.shakingHands',
    logout = 'sc.logout',
    setRole = 'sc.setRole',
    setGroupSecurity = 'sc.grpSec',
    getAvatarsThatParticipateInAGroup = 'sc.getAvatarsFromGroup',
    getGroupCreateEditSupportData = 'sc.getSecInfo',
    logFromToken = 'sc.token',
    logFromSocialNetwork = 'sc.logSN',
    getGroupParticipants = 'sc.getp',
    getSettingsData = 'sc.getData',
    getTrackerEvents = 'sc.gpsget',
    firstStepSignup = 'sc.firstStep',
    forgotPass = 'sc.forgotPass',
    changePass = 'sc.changePass'
};

export enum EKBOperations {
    querySubscriptionResources = 'lu.qry.sub.resources',
    saveIntent = 'lu.saveIntent',
    saveUtterance = 'lu.saveUtter',
    deleteIntent = 'KB.deleteIntent',
    deleteUtterance = 'lu.delUtter',
    getUtterances = 'lu.getUtter',
    predict = 'lu.predict',
    testPredictions = 'lu.testPredictions',
    train = 'lu.train',
    upsertPhraseList = 'lu.upsertPhraseList',
    deletePhraseList = 'lu.deletePhraseList',
    recommendationPhraseList = 'lu.recommendationPhraseList',
    upsertEntity = 'lu.upsertEntity',
    deleteEntity = 'lu.deleteEntity',
    upsertPattern = 'lu.upsertPattern',
    deletePattern = 'lu.deletePattern',
    updateEntityFeatures = 'lu.updateEntityFeatures',
    listIntentExamples = 'listIntentExamples',
    listPrebuiltEntities = 'listPrebuiltEntities',
}

export enum EWarehouseDataExtractor {
    processDataExtractor = 'wde.processDataExtractor'
}

export enum EThirdParty {
    thirdPartyLogin = 'tp.login',
}

export enum EFileRequest {
    saveSchemma = 'fl.saveSchemma',
    retrieveSchemma = 'fl.retrieve',
    retrieveNSSchemma = 'fl.retNSschemm',
    processFile = 'fl.process',
    deleteFile = 'fl.deleteFile',
    fullfillForm = 'fl.fullfill',
    saveGeneralAnswer = 'fl.svga',
    getAnswerForSerializable = 'fl.getans',
    getAnswerForConversation = 'fl.getans.conv',
    downloadFileContent = 'fl.downFileContent',
    deleteFileContent = 'fl.deleteFileContent',
    getFilesMetadata = 'fl.getMetadata',
    getSpecificSchemma = 'fl.getSchemma',
    saveFeatureAnswer = 'fl.saveFeaturedSchema',
    getContentFile = 'fl.getContent',
    getContentErrorFile = 'fl.getContentError',
    checkIfFileIsBeingUsedInSomeDatabase = 'fl.checkIfFileIsBeingUsedInSomeDatabase'
};

export enum EProdDeply {
    savePatchHeader = 'dp.psave',
    savePatchItem = 'dp.psItem',
    listPatchHeader = 'do.phlist',
    listPatchItem = 'dp.pilist',
    getChanges = 'dp.getChanges',
    listTagDependencies = 'dp.tagDependencies',
    savePatchBatch = 'dp.saveBatch',
    rollback = 'dp.roll',
    promote = 'dp.promo'
}

export enum EWidgetRequest {
    autoLogin = 'autoLogin'
}

export enum EUserFunctions {
    createOrEdit = 'uf.createOrEdit',
    getAll = 'uf.getAll',
    compile = 'uf.compile',
    run = 'uf.run',
    runService = 'uf.runservice',
    runRawRequest = 'uf.rrawreq',
    // edit = 'uf.edit'
}


export enum EFacebookChannelIntegrationReqRes {
    getColmeiaAppId = "fb.getColmeiaAppId",
    getColmeiaAppLoginConfiguration = "fb.getColmeiaAppLoginConfiguration",
    registerWebhookURL = 'fb.registerWebhookURL',
    exchangeCodeForTokenAndGetWhatsappInfo = 'fb.exchangeCodeForTokenAndGetWhatsappInfo',
    registerWebhookAndPhoneNumber = 'fb.registerWebhookAndPhoneNumber',
    migrateWhatsappChannelPhoneNumberFromAnotherBSPToColmeia = 'fb.migrateWhatsappChannelPhoneNumberFromAnotherBSPToColmeia',
    updateFacebookPageList = 'fb.updateFacebookPageList',
    getFacebookPageList = 'fb.getFacebookPageList',
    subscribeAppToPage = 'fb.subscribeAppToPage',
    disconnectMetaPage = 'fb.disconnectMetaPage',
    verifyPhoneOwnershipAndRegister = "fb.verifyPhoneOwnershipAndRegister",
    registerPhoneNumber = "fb.registerPhoneNumber",
    checkIfPhoneIsRegistered = "fb.checkIfPhoneIsRegistered",
    getAllPhoneNumbersFromWABAID = "fb.getAllPhoneNumbersFromWABAID"
}

export enum EColmeiaAPIToken {
    create = 'cmtk.create',
    refresh = 'cmtk.refresh',
    update = 'cmtk.update'
}

export enum EKnowledgeBaseRequest {
    saveKB = 'KB.save',
    editKB = 'KB.edit',
    editKBName = 'KB.editKBName',
    listKB = 'KB.list',
    getInfo = 'KB.getInfo',
    editNLPConfig = 'KB.editNLPConfig',
    processUtterances = 'KB.processUtterances',
    editKBAppConfig = 'KB.editKBAppConfig',
    getCompleteKBTextSyntax = 'KB.getCompleteKBTextSyntax',
    listKBInfo = 'KB.listInfo',
    listKbActivity = 'KB.activity',
    getUtteranceExampleTestAnalysis = 'KB.getUtteranceExampleTestAnalysis',
    getSentenceTestAnalysis = 'kb.getSentenceTestAnalysis',
    getNLPConfigAndIntents = 'KB.nlpIntents',
    getKBRelationShip = 'KB.getKBRelationShip',
    getKBUtteranceTestMigration = 'KB.getPredictionFromUtterancesToKb',
    getPredictionFromTextToKb = 'KB.getPredictionFromTextToKb',
    editKBCorporateEntity = 'KB.editKBCorporateEntity',
    listKBCorporateEntity = 'KB.listKBCorporateEntity',
}

export enum EMacroRequest {
    listMacros = 'mc.list',
    saveMacro = 'mc.save',
    getMacros = 'mc.getMacros',
}

export enum ECampaignRequest {
    listCampaigns = 'cp.list',
    listSingleByAction = 'cp.listSingleByAction',
    saveCampaing = 'cp.save',
    listSocialMedias = 'sm.list',
    saveSocialMedia = 'sm.save',
    getTelegramInfo = 'sm.telegram.info',
    runCampaignForActiveCall = "sm.runCampaignForActiveCall",
    saveRunCampaignForActiveCall = "sm.saveRunCampaignForActiveCall",
    runCampaign = 'sm.runCampaing',
    removeScheduledCampaign = 'sm.removeScheduledCampaign',
    rescheduleCampaign = 'sm.rescheduleCampaign',
    countMatches = 'sm.countMatches',
    listCampaignsByUser = 'sm.listByAvatar'
}

export enum EWABARequest {
    saveWABA = 'wb.save',
    getCreditLineStatus = 'wb.getCreditLineStatus',
    extendCreditLine = 'wb.extendCreditLine',
    revokeCreditLine = 'wb.revokeCreditLine',
}

export enum EBotTransaction {
    getCoordinators = 'bt.getCoo',
    saveTransaction = 'bt.saveTr',
    getTransactions = 'bt.getTr',
    saveLocalCanonical = 'bt.saveLoc',
    getLocalCanonical = 'bt.getLoc',
    getLocalCanonicalByGlobal = 'bt.getByGl',
    saveBot = 'bt.save',
    listBot = 'bot.list',
}

export enum EDeployService {
    saveDepServ = 'ds.save',
    listDepServs = 'ds.list',
}

export enum ETagService {
    save = 'tag.save',
    list = 'tag.list',
}

export enum EJobProcess {
    delete = 'jb.delete',
    rescheduleJob = 'jb.rescheduleJob',
    getAll = 'jb.getAll',
    environmentDataMigration = 'jb.environment-data-migration',
    getPayloads = 'jb.getPayloads',
}

export enum ECampaignView {
    filter = 'campgnViw.filterCampaingData',
    getAnswers = 'campgnViw.getAnswers',
    getRowAnswers = 'campgnViw.getRowAnswers',
}

export enum ERealTimeFilter {
    confirmInteractions = 'ER.conf'
}

export enum EColmeiaHelpRequestTypes {
    getAll = 'ch.getAll',
    upsertOne = 'ch.upsertOne'
}

export enum ESocialNetworkMessages {
    removeFromThisToBellow = 'sc.revokeAll',
    removeFromGroup = 'sc.removeGroup',
    // updateGroupContainers = 'sc.updateContainer'
}

export type TApiRequestType = ESecurityApiRequestType;

export enum EAttendanceIslandRequest {
    saveIsland = 'attIsland.save',
    listIslands = 'attIsland.list',
    deleteIsland = 'attIsland.delete',  /// ISSO AQUI???

    getAttendants = 'attIsland.getAttendants',
    saveAttendants = 'attIsland.saveAttendants',
    updateAttendant = 'attIsland.updateAttendant',
    deleteAttendants = 'attIsland.deleteAttendants',  /// ISSO AQUI??

    getAttendantsByAvatar = 'attIsland.getAttendantsByAvatar',

    transferIsland = 'attIsland.transferIsland',
    savePresenceStatus = 'att.savePresene',

    saveNamedAccount = 'att.saveNamedAccount',
    changeTemporaryQueuePrioritization = 'changeTemporaryQueuePrioritization',
}

export enum EApiRequestCorporateSearch {
    save = 'cs.save',
    list = 'cs.list'
}

export enum EAttendentRequest {
    changeStatus = 'att.changeStatus',
    saveAgentFile = 'att.saveAgentFile',
    getAvatarStatus = 'att.status',
    getStatusChangeLogs = 'att.getChLog',
    getIdentifiedOpenChannel = 'att.OpenChann',
    sendScheduledMessage = 'att.sendSched',
    login = 'att.login',
    loginLogoutRealTime = 'att.loginLogoutRealTime',
    stats = 'att.stats',
    returnToQueue = 'att.retQueue',
    finishAllAttendance = 'att.finshAll',
    attMetrics = 'att.metrics',
    callcenterActiveCalls = 'att.callcenterActiveCalls',
    getMessageSentForActiveCall = 'att.msgSentActvCall'
};

export enum ESupervisorRequest {
    changeAgentStatus = 'supervisor.changeAgentStatus',
    transferCustomerIsland = 'supervisor.transferCustomerIsland',
    transferCustomerToQueue = 'supervisor.transferCustomerToQueue',
    sendMessagesToAttendants = 'supervisor.sendMessagesToAttendants',
}

export enum EConfigurationSetRequest {
    executeSearch = 'ecs.executeSearch',
    getAllowedDB = 'ecs.getAllowedDB',
    saveConfigSet = 'ecs.saveConfigSet',
    deleteConfigSet = 'ecs.deleteConfigSet'
}

export enum EMenuSecurityRequest {
    getAllowedMenuIDs = 'getAllowedMenuIDs',
    getApplicationProcessedContract = 'getApplicationProcessedContract',
}

export enum ERTFParamsRequest {
    getAllHeader = 'sm-rtf-get-header',
    getAllBody = 'sm-rtf-get-body',
    saveHeader = 'sm-rtf-save-header',
    saveBody = 'sm-rtf-save-body',
}

export enum ECRMServiceConfigRequest {
    crmServiceRootSave = 'crmServiceRootSave',
    crmUserEventSave = 'crmUserEventSave',
    crmProcessingNodeSave = 'crmProcessingNodeSave',
    crmNotificationSave = 'crmNotificationSave',
}

export enum EBPMMarketingRequest {
    mktBpmSave = 'mktBpmSave'
}

export enum EYouTaskRequest {
    save = 'ot.save',
    getOne = 'ot.getOne',
    getAll = 'ot.getAll',
    getAllFromGroup = 'ot.getAllFromGroup',
    saveBatch = 'ot.saveBatch'
}

export enum ECopilotRequest {
    getAll = 'cplt-getAll',
    saveCopilot = 'cplt-save'
}

export enum EContactListRequest {
    saveList = 'ctcList.saveList',
    saveListItem = 'ctcList.saveListItem',
    saveListItemListsBatch = 'ctcList.saveListItemListsBatch',
}

export enum EBillingRequest {
    productSave = 'colmeia.productSave',
    productModuleSave = 'colmeia.productModuleSave',
    productModuleList = 'colmeia.productModuleList',
    productList = 'colmeia.productList',
    productSKUList = 'colmeia.productSKUList',
    productSKUSave = 'colmeia.productSKUSave',
    billingContractList = 'colmeia.billingContractList',
    billingContractSave = 'colmeia.billingContractSave',
    corporateGroupList = 'colmeia.corporateGroupList',
    billingCloseSave = 'colmeia.billingCloseSave',
    billingCloseList = 'colmeia.billingCloseList',
    billingItemsList = 'colmeia.billingItemsList',
    googleCostsCheck = 'colmeia.googleCostsCheck',
    googleCostsFetch = 'colmeia.googleCostsFetch',
    saveBillingItemAfterEdited = 'colmeia.saveBillingItemAfterEdited',
}

export enum EUserGroupProfile {
    editAvatarUserGroupProfile = 'uGrpPrfl.sn-edit-avatar-profiles-list',
    editUserGroupProfile = 'uGrpPrfl.editUserGroupProfile',
    getOneGroupProfile = 'uGrpPrfl.getOneGroupProfile'
}

export interface IBasicCRMConfigRequests {
    save: string;
    list: string;
    delete: string;
}

export const crmRequests = asConst<{ [key in TCRMConfigNSTypes]: IBasicCRMConfigRequests }>()({
    [ENonSerializableObjectType.crmHeaderCloseState]: {
        save: 'crm-save-header-closeState',
        list: 'crm-list-header-closeState',
        delete: 'crm-delete-header-closeState',
    },
    [ENonSerializableObjectType.crmItemCloseState]: {
        save: 'crm-save-item-closeState',
        list: 'crm-list-item-closeState',
        delete: 'crm-delete-item-closeState',
    },
    [ENonSerializableObjectType.crmHeaderPhase]: {
        save: 'crm-save-header-phase',
        list: 'crm-list-header-phase',
        delete: 'crm-delete-header-phase',
    },
    [ENonSerializableObjectType.crmItemPhase]: {
        save: 'crm-save-item-phase',
        list: 'crm-list-item-phase',
        delete: 'crm-delete-item-phase',
    },
    [ENonSerializableObjectType.crmHeaderSeverity]: {
        save: 'crm-save-header-severity',
        list: 'crm-list-header-severity',
        delete: 'crm-delete-header-severity',
    },
    [ENonSerializableObjectType.crmItemSeverity]: {
        save: 'crm-save-item-severity',
        list: 'crm-list-item-severity',
        delete: 'crm-delete-item-severity',
    },
    [ENonSerializableObjectType.crmHeaderState]: {
        save: 'crm-save-header-state',
        list: 'crm-list-header-state',
        delete: 'crm-delete-header-state',
    },
    [ENonSerializableObjectType.crmItemState]: {
        save: 'crm-save-item-state',
        list: 'crm-list-item-state',
        delete: 'crm-delete-item-state',
    },
    [ENonSerializableObjectType.crmHeaderSupportLevel]: {
        save: 'crm-save-header-support-level',
        list: 'crm-list-header-support-level',
        delete: 'crm-delete-header-support-level',
    },
    [ENonSerializableObjectType.crmItemSupportLevel]: {
        save: 'crm-save-item-support-level',
        list: 'crm-list-item-support-level',
        delete: 'crm-delete-item-support-level',
    },
    [ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel]: {
        save: 'crm-save-header-urgency-level',
        list: 'crm-list-header-urgency-level',
        delete: 'crm-delete-header-urgency-level',
    },
    [ENonSerializableObjectType.crmItemUrgencyLevel]: {
        save: 'crm-save-item-urgency-level',
        list: 'crm-list-item-urgency-level',
        delete: 'crm-delete-item-urgency-level',
    },
    [ENonSerializableObjectType.crmConfigPackage]: {
        save: 'crm-save-package',
        list: 'crm-list-package',
        delete: 'crm-delete-package',
    },
} as const)

export enum ESearchRequestTypes {
    search = 'search',
    fullTextSearch = 'fullTextSearch',
    searchRemoteEnv = 'searchRemoteEnv',
}

export enum EWAFRequest {
    get = 'waf.get',
    upsert = 'waf.upsert',
    listProviders = 'waf.listProviders',
    businessEncryptionKey = 'waf.businessEncryptionKey',
}

export enum EHouseKeepingRequests {
    getLogStructure = 'hk.getLogStructure',
    getPersistedConfigs = 'hk.getPersistedConfigs',
}

export enum EWAFSchemaRequest {
    get = 'wafs.get',
    upsert = 'wafs.upsert',
    publish = 'wafs.publish',
    deprecate = 'wafs.deprecate',
    delete = 'wafs.delete',
    send = 'wafs.send',
    submit = 'wafs.submit',
}

export const apiRequestType = asConst<{}>()({
    colmeiaDownstream: 'colmeia-downstream',
    embed: {
        checkDeployedService: 'embed-check-deployed-service',
    },
    widget: {
        autoLogin: EWidgetRequest.autoLogin,
    },
    dashboardExtra: {
        houseKeeping: {
            exec: 'house-keeping-exec',
            ...EHouseKeepingRequests,
        },
        supervisor: {
            ...ESupervisorRequest,
        },
        youTasks: {
            ...EYouTaskRequest,
        },
        contactList: {
            ...EContactListRequest,
        },
        smartFlow: {
            integration: 'ai-integration',
            saveForm: 'ai-saveform',
            form: 'ai-form',
            rtfParams: {
                getAllHeader: ERTFParamsRequest.getAllHeader,
                saveHeader: ERTFParamsRequest.saveHeader,
                getAllBody: ERTFParamsRequest.getAllBody,
                saveBody: ERTFParamsRequest.saveBody,
            },
            transformer: 'sf-transformer',
            saveTransformer: 'sf-save-transformer',
        },
        attendanceCalendar: {
            create: 'att-cal.create',
            update: 'att-cal-update'
        },
        indexedSearch: {
            list: EApiRequestCorporateSearch.list,
            save: EApiRequestCorporateSearch.save,
        },
        crm: {
            config: crmRequests,
            serviceConfigRootSave: ECRMServiceConfigRequest.crmServiceRootSave,
            crmProcessingNodeSave: ECRMServiceConfigRequest.crmProcessingNodeSave,
            crmNotificationSave: ECRMServiceConfigRequest.crmNotificationSave,
            crmUserEventSave: ECRMServiceConfigRequest.crmUserEventSave,
            getCRMStatusFromRootDefinition: 'get-crm-status-from-root-definition',
            crmExecListRoot: 'crm-exec-list-root',
            crmExecListAgent: 'crm-exec-list-agent',
            crmExecList: 'crm-exec-list',
            crmFetchDomainItens: 'crm-fetch-domain-itens',
            saveItemsOrder: 'save-items-order',
            transformer: 'crm-transformer',
            saveTransformer: 'crm-save-transformer',
        },
        ai: {
            bots: 'ai-bots',
            transaction: 'ai-transaction',
            trainContentGeneratorGenerative: 'ai-train-content-generator-generative',
            bpm: 'ai-bpmgraph',
            saveBpm: 'ai-savebpmgraph',
            deleteBpm: 'ai-deletebpmgraph',
            getAllElementsByRoot: 'ai-getallelementsbyroot',
            filterAlreadyUsedBpmRootElement: 'ai-filteralreadyusedbpmrootelement',
            getDependenciesFromHostedObject: 'ai-getdependenciesfromhostedobject',
            getExternalPrintableDependenciesOf: 'ai-getExternalPrintableDependenciesOf',
            isBPMNestedAIRootAlreadyExist: 'ai-isBPMNestedAIRootAlreadyExist',
            isNLPFromBPM: 'ai-isNLPFromBPM',
            saveVectors: 'ai-save-vectors',
            previewChunks: 'ai-preview-chunks',
            persistChunks: 'ai-persist-chunks',
            editChunk: 'ai-edit-chunk',
            vector: 'ai-vector',
            getChunks: 'ai-get-chunks',
            vectors: 'ai-vectors',
            generativeChat: 'ai-generative-chat',
            prompt: 'ai-prompt',
            savePrompt: 'ai-save-prompt',
        },
        conversationalCommerce: {
            catalog: 'conversational-commerce-catalog',
            previewMetaCatalog: 'conversational-commerce-previewMetaCatalog',
            saveCatalog: 'conversational-commerce-savecatalog',
            upsertCatalogOnWaba: 'conversational-commerce-upsertCatalogOnWaba',
            listCatalogsOnWaba: 'conversational-commerce-listCatalogsOnWaba',
            sendCatalog: 'conversational-commerce-sendCatalog',
            getMetaEntity: 'conversational-commerce-getMetaEntity',
            uploadCatalogToProvider: 'conversational-commerce-uploadCatalogToProvider',
            listSubmittedProducts: 'conversational-commerce-listSubmittedProducts',
            integration: 'conversational-commerce-integration',
            form: 'conversational-commerce-form',
            saveForm: 'conversational-commerce-saveForm',
        },
        marketing: {
            integration: 'marketing-integration',
            form: 'marketing-form',
            campaigns: 'marketing-campaigns',
            send: 'marketing-send',
            saveForm: 'marketing-saveform',
            template: 'marketing-template',
            templates: 'marketing-templates',
            getTemplateProviders: 'marketing-templateproviders',
            updateTemplateProviderApproval: 'marketing-templateproviderapproval',
            updateTemplateProviderApprovals: 'marketing-templateproviderapprovals',
            saveTemplate: 'marketing-savetemplate',
            submitWhatsAppTemplate: 'submit-whatsapp-template',
            archiveWhatsAppTemplate: 'archive-whatsapp-template',
            getSocialMediaConnection: 'get-social-media-connection',
            ...EBPMMarketingRequest,
            ...ECampaignView,
            wabaQuality: 'marketing-waba-quality',       
            wabaTemplateQuality: 'marketing-waba-template-quality', 
            updateTemplateUnpause: 'marketing-waba-template-unpause',
            cancelScheduledCampaignByTemplateIdNS: 'remove-scheduled-campaign-by-template-idns',
            getScheduledCampaignByTemplateIdNS: 'get-scheduled-campaign-by-template-idns',
            wabaNotificationsQuality: 'marketing-waba-notifications-quality', 
            
        },
        env: {
            transformations: 'env-transformations',
            patches: 'env-patches',
            promote: 'env-promote',
            backupSnapshot: 'env-backup-snapshot',
            backupSnapshotCreate: 'env-backup-snapshot-create',
            backupSnapshotRestore: 'env-backup-snapshot-restore',
            backupListItems: 'env-backup-list-items',
            backupPackage: 'env-backup-package',
        },
        service: {
            integration: 'service-integration',
        },
        jobs: {
            ...EJobProcess
        },
        attendance: {
            sendOpenChannelTemplate: 'send-open-channel-template',
            quickDrawAllocation: 'quick-draw-allocation',
            attsuport: 'provider-attsuport',
            provider: 'provider-attendance',
            file: 'provider-file',
            deleteFile: 'provider-delete-file',
            namedaccount: 'provider-namedaccount',
            integration: 'provider-integration',
            form: 'provider-form',
            saveForm: 'provider-saveform',
            checkFileExists: 'provider-check-file-exists',

            events: 'provider-attendanceevents',
            saveEvent: 'provider-save-attendanceevent',

            agentStatus: 'provider-agentstatus',

            activeCallSendSelectedContacts: 'active-call-send-selected-contacts',
            activeCallScheduleSelectedContacts: 'active-call-schedule-selected-contacts',
            editActiveCallContact: 'edit-active-call-contact',
            createActiveCallContact: 'create-active-call-contact',
            getActiveCustomerStatus: 'get-ac-customer-status',
            getChannelOpenTime: 'get-channel0-open-time',
            saveContactActiveCall: 'save-contact-active-call',
            activeCallBatch: 'active-call-batch',
            saveBatchActiveCall: 'save-batch-active-call',
            activeCallSearchCustomer: 'active-call-search-customer',
            getActiveCompactibleCampaignActions: 'get-active-compactible-campaign-actions',
            sendActionToMultipleContacts: 'send-action-to-multiple-contacts',
        },
        communication: {
            form: 'communication-form',
            saveForm: 'communication-saveform',
            apis: 'communication-apis',
        },
        colmeia: {
            downloadFile: 'colmeia.downloadFile',
            broker: 'colmeia.broker',
            deleteCoordinator: 'colmeia.deleteCoordinator',
            getCoordinatorInfo: 'colmeia.getCoordinatorInfo',
            resetServers: 'colmeia.resetServers',
            ...EBillingRequest
        },
        socialNetwork: {
            config: 'social-network-config',
            listConfig: 'social-network-listconfig',
            saveConfig: 'social-network-saveconfig',
            saveUserSettings: 'sn-user-settings-save',
            users: 'social-network-users',
            listGroupsAccess: 'social-network-list-groups-access',
            form: 'social-network-form',
            saveForm: 'social-network-saveform'
        }
    },
    visualElement: {
        upsert: 'visual-element-upsert',
        get: 'visual-element-get',
        refreshCache: 'visual-element-refresh-cache',
        insertInAllEnvironments: 'visual-element-insert-in-all-environments',
    },
    userFunctions: {
        ...EUserFunctions,
    },
    deployTransformer: {
        upsert: 'dep.trans.upsert',
        delete: 'dep.trans.delete'
    },
    wallet: {
        getStatement: 'wall.stat'
    },
    analytics: {
        htmlReports: {
            allCustomerContactHeader: 'an.cuHea',
            contactDetail: 'an.conDet',
            renewStorageLink: 'ac.renew-storage-link',
        },
        group360: 'an.360',
        ...EPowerBIDashboard,
        ...EBIRequest
    },
    security: {
        ...ESecurityApiRequestType
    },
    lookup: 'lookup',
    unAuthenticatedLookup: 'una.lookup',
    createParticipant: 'createPart',
    batchInsertInteractions: 'batchInter',
    participantInteraction: 'interaction',
    getInteractionById: 'getIntByID',
    getOldestRecentInteractionsInfo: 'getOldestRecentInteractionsInfo',
    getInviteeDataToSignUp: 'getInviteeDataToSignUp',
    adminSetup: 'admSetup',
    ...ESearchRequestTypes,
    feedback: {
        feedbackCreator: 'feed.creat',
        details: 'feed.detail'
    },
    avatar: {
        editAvatar: 'avt.Edit',
        editAvatarName: 'avt.Edit.Name',
        getGroupAvatars: 'avt.get',
        getAvatarsOnlineOnGroup: 'avt.getAvtLive',
        createAvatar: 'avt.create',
        editAvatarProfilesList: EUserGroupProfile.editAvatarUserGroupProfile
    },
    internal: {
        startHuman: 'int.startHuman',
        e360ToInternal: 'int.toInternal',
        externalInteraction: 'int.external',
        firstNavigation: 'int.first',
        createGroupForCRM: 'int.crepg',
        createEmbeddedUser: 'int.createEmb',
        createPlayer: 'int.player',
        addExpiredRole: 'int.addRoleAvatar',
        expireBotSessionRequest: 'int.closeIfNeeded',
        batchInteractions: 'int.bathInteractions',
        forceCloseAgent: 'int.forceCloseAgent',
        grantRole: 'int.grantRole',
        externalManualCRMProcess: 'int.extCRM'
    },
    executionRequest: {
        ...EExecutionRequest,
    },
    multimedia: {
        getSETMultimediaMetadata: 'mm.getSet',
        getSmartshareInformation: 'mm.smart',
        deleteMultimediaInstance: 'mm.delete',
        setMultimediaHeader: 'mm.header',
    },
    groups: {

        editGroup: 'grp.edit',
        getGroupData: 'grp.get',
        getGroupChilds: 'grp.childs',
        getGroupInteractions: 'grp.inter',
        createGroup: 'grp.create',
        getGroupById: 'grp.getByID',
        addAvatar: 'grp.addAvatar',
        removeAvatar: 'grp.removeAvatar',
        addGroupOnDL: 'dl.addGroup',
        removeGroupOnDL: 'dl.removeGroup'
    },
    canonical: {
        getGlobalCanonical: 'canon.gbl'
    },
    connections: {
        all: 'conn.all', // @hugo
        get: 'conn.get',
        testRouteConnection: 'conn.testRouteConn',
        getByIdDomain: 'conn.getByIdDomain',
        create: 'conn.create',
        delete: 'conn.delete',
        update: 'conn.update',
        routes: {
            getNameById: 'conn.routes.nameById',
            upsert: 'conn.routes.upsert',
            getOne: 'conn.routes.getOne',
            all: 'conn.routes.all',
        }
    },
    subscription: {
        inviteToGroup: 'sub.inv',
        status: 'sub.status',
        unsubscribe: 'sub.unsub'
    },
    navigation: {
        runNavigation: 'nav.run',
    },
    pushNotification: {
        policy: {
            get: 'push.policy.get',
            upsert: 'push.policy.update'
        },
        devices: {
            get: 'push.devices.get',
            upsert: 'push.devices.create'
        }
    },
    stopServiceRequestWatchDog: 'stopServiceRequestWatchDog',
    recover: {
        topic: 'rec.topic',
        disconnectionRecover: 'rec.disc',
    },
    share: {
        send: 'shr.send',
        currentState: 'shr.cstate'
    },
    socialBind: {
        getActiveBinds: 'sb.getActive',
        unbind: 'sb.unbind'
    },
    serializable: {
        header: 'serializableHeader',
        updateTreePosition: 'sg.botUpdate',
        getListOnRemoteEnv: 'sr.getListOnRemoteEnv'
    },
    payments: {
        checkout: 'checkout',
        medium: 'medium',
        mediums: 'mediums',
        notification: 'notification',
        defaultMedium: 'medium.default',
        getMedium: 'medium.get'
    },
    serviceGroup: {
        startComm: 'sg.start',
        getService: 'sg.getSrv'
    },
    socialNetwork: {
        get: 'sn.get'
    },
    faceCompany: {
        news: 'newsArticles',
        save: 'fc.save',
        list: 'fc.list',
    },
    snConfig: {
        ...ESocialNetworkMessages
    },
    files: {
        ...EFileRequest
    },
    knowledgeBase: {
        ...EKnowledgeBaseRequest
    },
    realTimeFilter: {
        ...ERealTimeFilter
    },
    macros: {
        ...EMacroRequest
    },
    LUIS: {
        ...EKBOperations
    },
    campaigns: {
        ...ECampaignRequest
    },
    botTransaction: {
        ...EBotTransaction
    },
    deployedService: {
        ...EDeployService,
    },
    tags: {
        ...ETagService
    },
    nonSerializable: {
        getUsedTags: 'ns.usedTags',
        getGeneric: 'ns.getGeneric',
        getGenericEnv: 'ns.getGenericEnv',
        list: 'ns.list',
        listRemoteEnv: 'ns.list.remoteEnv',
        delete: 'ns.delete',
        deleteBatch: 'ns.deleteBatch',
        deleteAndClearReferences: 'ns.delete.clref',
        checkDelete: 'ns.check.delete',
        checkDependencies: 'ns.check.dependencies',
        genericNSSave: 'ns.genericNSSave',
    },
    attendanceIsland: {
        ...EAttendanceIslandRequest
    },
    attendent: {
        ...EAttendentRequest
    },
    callcenter: {
        allAgents: 'cc.allAgents',
        generalQueueInformation: 'cc.agentsQueue',
        // items
        queuePendencies: 'cc.queuePendencies',
        removeQueues: 'cc.removeQueues',
        finishAttendances: 'cc.finishAttendances',
        assignedWorkQueueItem: 'cc.assignedWorkQueueItem',
        allAgentCases: 'cc.allAgentCases',
    },
    sharedServices: {
        ...ESharedServicesRequestType
    },
    menuSecurityRequest: {
        ...EMenuSecurityRequest
    },
    prodDeply: { ...EProdDeply },
    securityControlRequestType: { ...ESecurityControlRequestType },
    lookupType: { ...ELookupType },
    waf: { ...EWAFSchemaRequest, ...EWAFRequest }

} as const);

export type ApiRequestType = DeepFindValuesOfType<typeof apiRequestType, string>;

export const ignoreAuthMessageTypes = [
    apiRequestType.security.signUp,
    apiRequestType.security.firstStepSignup,
    apiRequestType.security.forgotPass,
    apiRequestType.security.logUserFromEmail,
];

export const errorResponse = {
    requestConsistencyError: {
        fileRequestIncomplete: 'fileRequestIncomplete', //!NEW
        interactionInfoIncomplete: 'interactionInfoIncomplete',
        groupRequestInfoIncomplete: 'groupRequestInfoIncomplete',
        resolveDataInfoIncomplete: 'resolveDataInfoIncomplete',
        poolInfoIncomplete: 'poolInfoIncomplete',
        resourceSetInfoIncomplete: 'resourceSetInfoIncomplete',
        resourceInfoIncomplete: 'resourceInfoIncomplete',
        formInfoIncomplete: 'formInfoIncomplete',
        answerFormIncomplete: 'answerFormIncomplete',
        loginEmailInfoIncomplete: 'loginEmailInfoIncomplete',
        loginByCookNotPossible: 'loginByCookNotPossible',
        searchAPIInvalid: 'searchAPIInvalid',
        noRequestSent: 'noRequestSent',
        noInteractionSent: 'noInteractionSent',
        signUpInfoIncomplete: 'signUpInfoIncomplete',
        forgotPassInfoIncomplete: 'forgotPassInfoIncomplete',
    },
    sendTreeChoiceArray: 'sendTreeChoiceArray',
    noSessionFound: 'noSessionFound', //!NEW! não foi achado a sessao na tabela session


    playerMismatch: 'playerMismatch',  // Player da session não confere com cookie
    loginNeeded: 'loginNeeded',  // Necessário Logar
    mustBeLogged: 'mustBeLogged',   // não logado pedindo um acesso que necessariamente deve estar logado



    invalidChainedInteraction: 'invalidChainedInteraction', // isInteractionConsistent recebeu interaction Chained Invalida
    interactionNotAllowed: 'interactionNotAllowed',


    // Server??
    missingRequestParams: 'missingRequestParams',
    missingBodyPayloadOnLogon: 'missingBodyPayloadOnLogon', // usuário não preencheu todos os campos necessarios
    wrongBodyPayload: 'wrongBodyPayload',
    wrongSocketDataMessage: 'wrongSocketDataMessage',

};

