import { MetaTranslation } from "@colmeia/core/src/shared-business-rules/const-text/meta-translation";
import { constant } from "../../../business/constant";
import { DeliveryProvider } from "../../../core-constants/types";
import { suggestions } from "../../../tools/type-utils";
import { IdField, typedFields, typedScreenOptions } from "../../../tools/utility";
import { defineTranslations, EAllSerializable, getTranslationsFields } from "../all-serializables";



export const deliveryProviderTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.DeliveryProvider,
    fields: typedFields<DeliveryProvider, { IsLimitingFields: true }>()({
        [DeliveryProvider.sendGrid]: 1, // SendGrid
        [DeliveryProvider.twilio]: 2, // Twilio
        [DeliveryProvider.infobip]: 3, // Infobip
        [DeliveryProvider.colmeia]: 4, // Colmeia
        [DeliveryProvider.telegram]: 5, // Telegram
        [DeliveryProvider.instagram]: 6, // Instagram
        [DeliveryProvider.facebook]: 7, // Facebook
        [DeliveryProvider.whatsApp]: 8, // WhatsApp
        [DeliveryProvider.sinch]: 9, // Sinch
        [DeliveryProvider.wavy]: 10, // Wavy
        [DeliveryProvider.smarters]: 11, // Smarters
        // [DeliveryProvider.meta]: 12 // Meta
    }),
} as const));


const commonTemplateTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.MarketingTemplates,
    fields: suggestions<{ [key in string]: IdField }>()({

    }),
} as const));

const templateErrorsTranslations = defineTranslations({
    idSerializable: EAllSerializable.MarketingTemplateErrors,
    fields: ({
        noProviders: 1, // Sem provedores
        internalServerError: MetaTranslation.field.template({
            num: 2,
            template: "An internal server error has occurred",
            text: 'Um erro ocorreu com um provedor externo',
        }), // 

        fileTypeNotSupported: MetaTranslation.field.template({
            num: 3,
            template: "File Type Not Supported",
            text: 'Tipo de arquivo não suportado',
        }), //

        theTypeOfFileIsNotSupported: MetaTranslation.field.template({
            num: 4,
            template: "The type of file is not supported.",
            text: 'O arquivo enviado pertence a um formato não permitido.',
        }), //
        replyTitleCannotExceedCharacters: MetaTranslation.field.template({
            num: 5,
            template: "Reply title cannot exceed {{ size }} characters",
            text: 'O tamanho do título não pode exceder {{ size }} caracteres.',
        }), // 

        facebookGraphApiError: MetaTranslation.field.template({
            num: 6,
            template: "Facebook Graph API Error: message={{ message }} userMessage={{ userMessage }} userTitle={{ userTitle }}",
            text: '{{ userTitle }}. {{ userMessage }}',
        }), // 

        expiredTokenMessage: MetaTranslation.field.template({
            num: 7,
            template: "Error validating access token: Session has expired on {{ expiredOn }}. The current time is {{ currentTime }}.",
            text: 'Erro ao validar o token de acesso: a sessão expirou em {{ expiredOn }}.\nA hora atual é {{ currentTime }}.',
            isWarning: true,
        }), // 
    }),
});


// Error validating access token: Session has expired on Tuesday, 30-May-23 13:00:00 PDT. The current time is Thursday, 01-Jun-23 06:42:22 PDT.


export const templateTranslations = {
    ...commonTemplateTranslations,
    ...templateErrorsTranslations,
    ...deliveryProviderTranslations,
}

