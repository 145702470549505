<div class="wrapper">
  <div *ngIf="isImage()" class="preview">
    <a [href]="fileUrl" target="_blank">
      <img class="image" width="40px" height="40px" [src]="fileUrl">
    </a>
  </div>
  <section class="progress-container">
    <div class="progress-container__file-name">
      <a *ngIf="fileUrl" [href]="fileUrl" target="_blank">{{ fileName }} <mat-icon>open_in_new</mat-icon></a>
      <ng-container *ngIf="!fileUrl">{{ fileName }}</ng-container>
    </div>
    <div class="progress-container__status">
        <mat-progress-bar color="accent" [mode]="progressMode" [value]="percents"></mat-progress-bar>
        <div class="percents">{{percents}}% </div>
    </div>
  </section>
</div>
