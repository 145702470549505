import { groupBy, keys } from "@colmeia/core/src/tools/barrel-tools";
import { TGlobalUID } from "../../core-constants/types";
import { getBlankNonSerializableHeader } from '../../nser/blank-ns';
import { IMetaEngagement } from '../metadata/meta-engagement';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from '../non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '../non-serializable-id/non-serializable-types';
import { IBasicAuthInformation, ICLUConnectionAuth, IColmeiaProviderAuth, ILuisConnectionAuth } from "./connections-auth-model";
import { IAssetApiCallResult } from "./connections-requests";

// export const gAuthVariable = '${auth}'

export type TIConnectionVariableArray = IConnectionVariable[];

export interface IConnectionVariable {
    /**
     * nome da variavel com delimitadores("${" "}")
     */
    varName: string,
    startIdx: number,
    endIdx: number,
    /**
     * nome da variavel sem delimitadores
     */
    varId?: string,
    /**
     * nome do campo que contem a variavel, este valor eh opcional pois apenas o uri eh uma string e obrigatório para body, query e headers
     */
    fieldName?: string
}

export type TUriVarName = string
export type TUriVarNameMap = Record<TUriVarName, IVariableDataToReplace>

export interface IVariableDataToReplace {
    varName: string,
    newValue: string
}

export interface IConnectionCommonFields {
    method: EHTTPMethod;
    uri: IConnectionRouteUri;
    headers: IConnectionRouteHeaders;
    body?: IConnectionRouteBody;
    urlParams?: IConnectionRouteUrlParams;
    queryParams?: IConnectionRouteUrlQueryParams;
}

export interface IConnectionRouteClient extends IConnectionCommonFields, INonSerializableHeader {
    idConnection: IdDep<ENonSerializableObjectType.connection>;
    idDomain: string;
    mustAuthenticate: boolean;
    isKeepAliveEnabled?: boolean;
    /**
     * @deprecated
     */
    idForm: string;
    /**
     * @deprecated
     */
    engagement: IMetaEngagement;
}

export interface IConnectionRouteServer extends INonSerializable, IConnectionRouteClient {
}
export interface IConnectionRouteFragmentsVariable {
    variables: IConnectionVariable[]
}
export interface IConnectionRouteUri extends IConnectionRouteFragmentsVariable {
    data: THttpClientRequestUri
}
export interface IConnectionRouteUrlParams extends IConnectionRouteFragmentsVariable {
    data: THttpClientParams
}
export interface IConnectionRouteBody extends IConnectionRouteFragmentsVariable {
    data: THttpClientRequestBody
}
export interface IConnectionRouteHeaders extends IConnectionRouteFragmentsVariable {
    data: THttpClientHeaders;
}
export interface IConnectionRouteUrlQueryParams extends IConnectionRouteFragmentsVariable {
    data: THttpClientQueryParams
}


export interface IHttpConnectionResult<T = any> {
    status: IHttpStatus;
    headers: THttpClientHeaders,
    data: T,
}

export interface IConnectionDomain {
    name: string;
    domain: string;
    identifier: string;
    uriPrefix?: string,
    isKeepAliveEnabled?: boolean;
}

export type TConnectionDomainArray = Array<IConnectionDomain>;


export enum EConnectionRoutine {
    Once = 'Once',
    EveryCall = 'EveryCall',
    ExpiresAfter = 'ExpiresAfter',
    Unauthenticated = 'Unauthenticated'
}

export interface IConnectionClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.connection,
    allowedDomains: IConnectionDomain[];
    auth: IBasicAuthInformation;
}

export interface IConnectionServer extends IConnectionClient, INonSerializable {
    nsType: ENonSerializableObjectType.connection
}

export interface IColmeiaProviderConnectionServer extends IConnectionServer {
    auth: IColmeiaProviderAuth;
}

export interface ILuisConnectionServer extends IConnectionServer {
    auth: ILuisConnectionAuth;
}


export interface ILLMConnectionServer extends IConnectionServer {
    isLLM: true;
}

export interface IEmbeddingConnectionServer extends IConnectionServer {
    isEmbedding: true;
}

export interface ICLUConnectionServer extends IConnectionServer {
    auth: ICLUConnectionAuth;
}

export type TConnectionsArray = Array<IConnectionServer>;

export interface IHttpStatus {
    code: number;
    message: string;
}

export enum EHTTPMethod {
    get = 'GET',
    post = 'POST',
    put = 'PUT',
    delete = 'DELETE',
    patch = 'PATCH',
    Head = 'HEAD',
    Options = 'OPTIONS',
}

export enum EHTTPKnownHeaders {
    Accept = "accept",
    AcceptLanguage = "accept-language",
    AcceptPatch = "accept-patch",
    AcceptRanges = "accept-ranges",
    AccessControlAllowCredentials = "access-control-allow-credentials",
    Allow = "allow",
    AccessControlAllowHeaders = "access-control-allow-headers",
    AccessControlAllowMethods = "access-control-allow-methods",
    Origin = "origin",
    AccessControlAllowOrigin = "access-control-allow-origin",
    AccessControlExposeHeaders = "access-control-expose-headers",
    Age = "age",
    AccessControlMaxAge = "access-control-max-age",
    AccessControlRequestHeaders = "access-control-request-headers",
    AccessControlRequestMethod = "access-control-request-method",
    AltSvc = "alt-svc",
    Authorization = "authorization",
    CacheControl = "cache-control",
    Connection = "connection",
    ContentDisposition = "content-disposition",
    ContentEncoding = "content-encoding",
    ContentLanguage = "content-language",
    ContentLength = "content-length",
    Location = "location",
    ContentLocation = "content-location",
    Range = "range",
    ContentRange = "content-range",
    ContentType = "content-type",
    Cookie = "cookie",
    Date = "date",
    Expect = "expect",
    Expires = "expires",
    Forwarded = "forwarded",
    From = "from",
    Host = "host",
    IfMatch = "if-match",
    IfModifiedSince = "if-modified-since",
    IfNoneMatch = "if-none-match",
    IfUnmodifiedSince = "if-unmodified-since",
    LastModified = "last-modified",
    Pragma = "pragma",
    ProxyAuthenticate = "proxy-authenticate",
    ProxyAuthorization = "proxy-authorization",
    PublicKeyPins = "public-key-pins",
    Referer = "referer",
    RetryAfter = "retry-after",
    SecWebsocketAccept = "sec-websocket-accept",
    SecWebsocketExtensions = "sec-websocket-extensions",
    SecWebsocketKey = "sec-websocket-key",
    SecWebsocketProtocol = "sec-websocket-protocol",
    SecWebsocketVersion = "sec-websocket-version",
    SetCookie = "set-cookie",
    StrictTransportSecurity = "strict-transport-security",
    Tk = "tk",
    Trailer = "trailer",
    TransferEncoding = "transfer-encoding",
    Upgrade = "upgrade",
    UserAgent = "user-agent",
    Vary = "vary",
    Via = "via",
    Warning = "warning",
    WwwAuthenticate = "www-authenticate",
}


export enum EHTTPStatusCode {
    ok = 200,
    created = 201,
    unauthorized = 401,
    notFound = 404,
    badRequest = 400,
    internalError = 500,
    lastHttpSuccess = 299,
    lastHttpErrorCode = 599
}

export type THttpClientFieldsBasicValues = string | number | boolean
export type THttpClientFieldsBasicParams = THttpClientFieldsBasicValues | object
export type THttpClientHeaders = Record<string, string>
export type THttpClientRequestBody = any
export type THttpClientParams = Record<string, string>
export type THttpClientQueryParams = undefined | Record<string, undefined | string | string[]>
export type THttpClientRequestUri = string


export interface RouteNameHash {
    [idRoute: string]: string;
}

/*@TODO datastore*/
export enum EConnectionCategory {
    Integration = 'cc.integration',
    Provider = 'cc.provider'
}

/* @TODO datastore Vai dar merda nos registros do datastore*/

export enum EConnectionType {
    Custom = 'ct.custom',
    SalesForce = 'ct.salesforce',
    Luis = 'ct.luis',
    CLU = 'ct.clu',
    Colmeia = 'ct.colmeia',
    Google = 'ct.google',
    FacebookAnalytics = 'ct.facebookAnalytics',
    GoogleAnalytics = 'ct.googleAnalytics',
    OpenAi = 'ct.openAi'
}

export type EUniqueProvider = EConnectionType.Google;

export type TConnectionTypesByCategory = { [key in EConnectionCategory]: Array<EConnectionType> };
export type TConnectionCategoriesByType = { [key in EConnectionType]: EConnectionCategory };


export const connectionDB: TConnectionCategoriesByType = {
    [EConnectionType.Custom]: EConnectionCategory.Integration,
    [EConnectionType.SalesForce]: EConnectionCategory.Provider,
    [EConnectionType.Luis]: EConnectionCategory.Provider,
    [EConnectionType.CLU]: EConnectionCategory.Provider,
    [EConnectionType.Colmeia]: EConnectionCategory.Provider,
    [EConnectionType.Google]: EConnectionCategory.Provider,
    [EConnectionType.GoogleAnalytics]: EConnectionCategory.Provider,
    [EConnectionType.FacebookAnalytics]: EConnectionCategory.Provider,
    [EConnectionType.OpenAi]: EConnectionCategory.Provider
};

function getConnectionsTypeDB(): TConnectionTypesByCategory {
    const items = keys(connectionDB);
    const grouped = groupBy(items, item => connectionDB[item]);
    return grouped;
}

export const connectionTypesDB: TConnectionTypesByCategory = getConnectionsTypeDB();

export function filterConnectionsByCategory(list: IConnectionServer[], category: EConnectionCategory): IConnectionServer[] {
    return list.filter((conn: IConnectionServer) => connectionTypesDB[category].includes(conn && conn.auth && conn.auth.type));
}

export function isColmeiaProvider(connection: IConnectionServer): connection is IColmeiaProviderConnectionServer {
    return connection.auth.type === EConnectionType.Colmeia;
}

export interface IRouteExecution {
    idCall: string;
    idRoute: string;
    result: IAssetApiCallResult;
    err?: Error;
    rawResponse: IHttpConnectionResult;
}

export interface IAuthenticationDBEntry {
    clockTick: number;
    expire: number;
    connectionInfo: IBasicAuthInformation;
    result: string;
}

export interface IRouteDBEntry {
    idConnection: string;
    route: IConnectionRouteServer;
    domain: IConnectionDomain;
}

export interface ConnectionRawAndParsedResult {
    result: IHttpConnectionResult;
    parsed: object;
}

export interface IStoredConnectionCredentialField {
    variable: string;
    value: string;
}

export function getConnectionFieldsValues(connection: IConnectionServer): { [variable: string]: string } {
    const fields: { [variable: string]: string } = {};
    return fields;
}

export enum ESalesforceRouteTag {
    SubmitCase = 'sf_scase',
    UserSignUp = 'sf_user_signup',
    QueryCase = 'sf_query_case'
}

export enum ELuisRouteTag {
    QueryXpto = 'ls_qrxo'
}

export type TConnectionRouteTag = ESalesforceRouteTag | ELuisRouteTag;

export interface IConnectionTaggedRoute {
    name: {
        idSerializable: TGlobalUID;
        idField: number;
    };
    tag: TConnectionRouteTag;
}

export type TTaggedRoutes = Array<IConnectionTaggedRoute>;

export interface IConnectionTypeSettings {
    credentials: {
        tab: boolean;
    };
    authentication: {
        tab: boolean;
    };
    domains: {
        tab: boolean;
    };
    preProgrammedRoutes: {
        tab: boolean;
    }
}

export const ConnectionTypeSettingsDB: { [key in EConnectionType]: IConnectionTypeSettings } = {
    [EConnectionType.Custom]: {
        credentials: {
            tab: false,
        },
        authentication: {
            tab: true,
        },
        domains: {
            tab: true
        },
        preProgrammedRoutes: {
            tab: false,
        }
    },
    [EConnectionType.SalesForce]: {
        credentials: {
            tab: true,
        },
        authentication: {
            tab: false,
        },
        domains: {
            tab: true
        },
        preProgrammedRoutes: {
            tab: true,
        }
    },
    [EConnectionType.Colmeia]: {
        credentials: {
            tab: true,
        },
        authentication: {
            tab: false,
        },
        domains: {
            tab: false
        },
        preProgrammedRoutes: {
            tab: true,
        }
    },
    [EConnectionType.CLU]: {
        credentials: {
            tab: true,
        },
        authentication: {
            tab: false,
        },
        domains: {
            tab: false
        },
        preProgrammedRoutes: {
            tab: true,
        },
    },
    [EConnectionType.Luis]: {
        credentials: {
            tab: true,
        },
        authentication: {
            tab: false,
        },
        domains: {
            tab: false
        },
        preProgrammedRoutes: {
            tab: true,
        },
    },
    [EConnectionType.Google]: {
        credentials: {
            tab: true,
        },
        authentication: {
            tab: false,
        },
        domains: {
            tab: false
        },
        preProgrammedRoutes: {
            tab: true,
        }
    },
    [EConnectionType.GoogleAnalytics]: {
        credentials: {
            tab: true,
        },
        authentication: {
            tab: false,
        },
        domains: {
            tab: false
        },
        preProgrammedRoutes: {
            tab: true,
        }
    },
    [EConnectionType.FacebookAnalytics]: {
        credentials: {
            tab: true,
        },
        authentication: {
            tab: false,
        },
        domains: {
            tab: false
        },
        preProgrammedRoutes: {
            tab: true,
        }
    },
    [EConnectionType.OpenAi]: {
      credentials: {
          tab: true,
      },
      authentication: {
          tab: false,
      },
      domains: {
          tab: false
      },
      preProgrammedRoutes: {
          tab: false,
      }
  }
};

export function getInitialConnectionClient(): IConnectionClient {
    return {
        ...getBlankNonSerializableHeader('', false),
        nsType: ENonSerializableObjectType.connection,
        allowedDomains: [],
        auth: {
            type: EConnectionType.Colmeia
        }
    }
}
