import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Feedback } from '@colmeia/core/src/interaction/feedback';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { Avatar, Serializable } from '@colmeia/core/src/business/barrel-business';
import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';
import { ISenderSignatureJSON } from '@colmeia/core/src/comm-interfaces/tracker-interfaces';
import { MMconstant } from '@colmeia/core/src/multi-media/multimedia-constant';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { isChildOff } from '@colmeia/core/src/rules/interaction-filter';
import { isValidAndEqual } from '@colmeia/core/src/tools/utility';
import {
    IFilteredGroup,
    IListenerTypingInfo
} from "../../../model/signal/ps-interfaces";
import {
    IMessageEndPointCallback,
    MessageInstanceHandler, TMessageInstanceHandlerArray
} from "../../../handlers/message-instance-handler/message-instance-handler";
import { IChatBackboneInputMsgComponent } from "../../../handlers/chat-backbone.handler";
import { SignalListenerService } from "../../../services/signal/signal-listener";
import { ToolBarServices } from "../../../services/controllers-services/tool-bar-controller/tool-bar.service";
import { SubscriptionSignal } from "../../../model/signal/subscription-signal";
import { ReactDisplayBarHandler } from "../../../handlers/react-display-handler";
import { TypingSignal } from "../../../model/signal/trackers/typing-signal";
import { clientConstants } from "../../../model/constants/client.constants";
import { ChatBackboneModel, IToggleViewModeOptions } from "../../../model/chat-backbone.model";
import { NDropDown } from "../../../handlers/dropdown.handler";
import { MessageContainerHandler } from "../../../handlers/message-container-handler";
import { Observable, Subscription, timer } from 'rxjs';
import { MessageInstanceIteratorComponent } from "./message-instance-iterator/message-instance-iterator.component";
import { chatMessageEdit } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { EmbeddedChatService } from "../../../services/embedded-chat.service";
import { SessionService } from "../../../services/session.service";
import { getOffsetOfPointerEvent } from 'app/model/client-utility';
import { ChatActionBarComponent } from '../action-bar/chat-action-bar.component';
import { DraggableDirectiveMoveEvent } from 'app/directives/drag.directive';
import { PosProcessingInteractionService } from 'app/services/pos-processing-interaction.service';
import { filter } from 'rxjs/operators';
import { EDelivery360Action, IInteractionJSON } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { AttendanceService } from 'app/services/attendance.service';
import { AttendanceChannelService, ICheckChannelStatusReturn } from 'app/services/attendace/attendance-channel.service';
import { EBotEventType } from '@colmeia/core/src/shared-business-rules/bot/bot-event-model';

export interface IMessagesContainerComponent {
    onMsgRemoved(interactionClicked: Interaction): Promise<void>;
    getGroupID(): TGlobalUID;
    markForCheck(): void;
}

@Component({
    selector: 'app-message-container',
    templateUrl: './message-container.component.html',
    styleUrls: ['./message-container.component.scss'],
    host: {
        '[class.is-widget-webchat]': 'isWidgetWebChat'
    }
})
export class MessagesContainerComponent implements OnInit, AfterViewInit, OnDestroy,
    AfterViewChecked,
    IListenerTypingInfo,
    IMessageEndPointCallback,
    IMessagesContainerComponent {

    static maxInterationTextLength = 1450;

    @ViewChild('msgIterator', { static: true })
    msgIterator: MessageInstanceIteratorComponent;
    @ViewChild('messageContainer', { static: true })
    messageContainer: ElementRef<HTMLDivElement>;
    @Input()
    handler: MessageContainerHandler;
    @Input()
    chatBackboneHandler: IChatBackboneInputMsgComponent;

    private lastBiggerEnd: number = 0;
    private filterGroup: IFilteredGroup;
    private avatarImage: string;
    private signature: ISenderSignatureJSON;
    private typingAvatar: string;
    private prevChatHeight: number;
    private newInteractionArrived: boolean;
    private currentDropdownHandler: NDropDown.DropDownHandler;
    private subscriptionChanged: boolean;
    private filterAppliedSub: Subscription;

    @ViewChild("resizeTriggerContainer")
    private resizeTriggerContainer: ElementRef<HTMLSpanElement>;

    @ViewChild(ChatActionBarComponent, { static: false })
    public actionBarComponent: ChatActionBarComponent;

    interactionSubscription: Subscription;

    labels = {
        isTyping: '',
    };

    public barResizing: boolean = false;
    public loadingPreviousInteractions: boolean = false;

    public channelStatus: ICheckChannelStatusReturn = { isClosed: false };
    public isEmbedded: boolean = this.session.isEmbeddedChat();

    constructor(
        private cdr: ChangeDetectorRef,
        private listener: SignalListenerService,
        private toolbarSvc: ToolBarServices,
        private embedded: EmbeddedChatService,
        private session: SessionService,
        private viewContainerRef: ViewContainerRef,
        private posProcessingInteractionSvc: PosProcessingInteractionService,
        private attendanceSvc: AttendanceService,
        private attChannelService: AttendanceChannelService,
    ) { }

    ngOnInit() {
        this.handler.setMessageContainerEndPoint(this);
        this.chatBackboneHandler.setMsgContainerInstance(this);
        this.filterGroup = { idGroup: this.handler.getGroupID() };
        this.listener.listenSubscriptionChanges(this);
        this.listener.listenToTypingFiltered(this.filterGroup, this);
        this.initLabels();

        this.filterAppliedSub = this.handler.getChatBackbone().filterApplied.subscribe((options: IToggleViewModeOptions = {}) => {
            const { shouldScrollDown = true } = options

            if (shouldScrollDown) setTimeout(() => this.scrollDown(), 0)
            //  && this.canScrollDown()
        });

        this.filterAppliedSub.add(this.posProcessingInteractionSvc.posProcessedInteraction$()
            .pipe(filter((interaction: Interaction | IInteractionJSON) => {
                const interactionJSON = interaction instanceof Interaction ? interaction.toJSON() : interaction;
                const isOfCurrentGroup: boolean = interactionJSON.publishingGroups.some(group => {
                    return group.idGroup === this.filterGroup.idGroup || group.genealogy.some(g => g.idGroupParent === this.filterGroup.idGroup)
                });
                const hasMessage: boolean = !!Serializable.getJText(interactionJSON, constant.serializableField.chat_text);

                return isOfCurrentGroup && hasMessage;
            }))
            .subscribe(() => {
                this.scrollDown();
            }));

        const isAttGroup = this.attendanceSvc.isInAttendDB(this.getGroupID());

        if (isAttGroup) {
            this.channelCheckProcediments()
        }

    }

    private channelCheckProcediments() {
        const startServiceInteraction = this.attendanceSvc.getInitInteractionService(this.getGroupID())

        if (startServiceInteraction?.get360ProviderType() === EDelivery360Action.Delivery360WhatsApp) {
            this.interactionSubscription = this.posProcessingInteractionSvc.interactionReceived$().subscribe((i) => {
                const isGeneratedByClosedChannelEvent: boolean = i instanceof Interaction
                    ? i.isGeneratedByBotEvent(EBotEventType.onChannelClosedOnAgentChat)
                    : i.generatedByBotEvent === EBotEventType.onChannelClosedOnAgentChat;

                if (isGeneratedByClosedChannelEvent) {
                    this.loadChannelStatus();
                }
            })

            this.loadChannelStatus();
        }
    }

    private loadChannelStatus() {
        this.attChannelService.checkChannelStatus(this.getGroupID()).then(channelStatus => {
            this.channelStatus = channelStatus;
        });
    }

    ngAfterViewInit(): void {
        this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
        this.scrollDown();
    }

    get isWidgetWebChat(): boolean {
        return this.embedded.isWidgetsApi
    }

    private startYOffset: number;
    private endYOffset: number;
    private _percentOfAbove: number;
    private _percentOfBellow: number;
    public percentOfAbove: string;
    public percentOfBellow: string;
    private moveFrame: number;

    resizeDragStart() {
        const el: HTMLElement = this.viewContainerRef.element.nativeElement;
        ({ top: this.startYOffset, bottom: this.endYOffset } = el.getBoundingClientRect());

        document.body.style.cursor = "ns-resize";
        this.barResizing = true;
    }

    resizeDragMove({ event, pointerTranslation: { y: verticalTranslation } }: DraggableDirectiveMoveEvent) {
        const { y } = getOffsetOfPointerEvent(event);

        const my = y - this.startYOffset;
        let height = this.endYOffset - this.startYOffset;

        this._percentOfAbove = Math.round(my / height * 100);
        this._percentOfBellow = 100 - this._percentOfAbove;

        cancelAnimationFrame(this.moveFrame);
        this.moveFrame = requestAnimationFrame(() => {
            this.resizeTriggerContainer.nativeElement.style.transform = `translateY(${verticalTranslation}px)`;
        });
    }

    resizeDragEnd() {
        cancelAnimationFrame(this.moveFrame);
        this.resizeTriggerContainer.nativeElement.style.transform = `translateY(0)`
        // this.messageContainer.nativeElement.style.height = `${this._percentOfAbove}%`;
        // this.actionBarWrapper.nativeElement.style.height = `${this._percentOfBellow}%`;
        this.resizeTriggerContainer.nativeElement.style.transform = `translateY(0)`
        this.percentOfAbove = `${this._percentOfAbove}%`;
        this.percentOfBellow = `${this._percentOfBellow}%`;
        this.barResizing = false;
        document.body.style.cursor = "";
    }

    ngOnDestroy(): void {
        this.listener.destroySubscriptions(this);
        this.handler.removeMessageContainerEndpoint(this);
        this.chatBackboneHandler.removeMsgContainerInstance();
        this.filterAppliedSub.unsubscribe();
        this.interactionSubscription?.unsubscribe();
    }

    ngAfterViewChecked(): void {
        this.scrollDownIfPossible();
    }

    initLabels(): void {
        this.labels.isTyping = Serializable.staticFactory(chatMessageEdit)
            .getSerializableText(constant.serializableField.auxiliars.aux02)
    }

    onNewInteractionArrivedCallback(isChildInteraction: boolean): void {
        if (!this.handler.isForumViewMode())
            this.newInteractionArrived = true;
    }

    changeAllSiblingsDetection(): void {
        this.cdr.markForCheck()
    }

    //#region scroll
    async onScroll(scrollEvent): Promise<void> {
        const scrollDistanceToTop = scrollEvent.target.scrollTop;
        const isScrollOnTop = scrollDistanceToTop === 0
        if (!isScrollOnTop) {
            return
        }

        console.log(`scroll esta no topo!!! ${scrollDistanceToTop}`);

        this.loadingPreviousInteractions = true;
        const someInteractionAdded = await this.onScroolTop()
        this.loadingPreviousInteractions = false;

        if (!someInteractionAdded) {
            return
        }
        scrollEvent.target.scrollTop += 1

        // await this.chatFilterService.reloadFiltersAndBlockScrollDown()
    };

    public async onScroolTop(): Promise<number> {
        return await this.handler.getMsgHandlerCallback().onScroolTop()
    }

    private scrollDownIfPossible(): void {
        const isCanScrollDown = this.canScrollDown()
        if (isCanScrollDown) {
            this.scrollDown();
        }
    }

    private canScrollDown(): boolean {
        const canScrollConditions = this.newInteractionArrived || this.subscriptionChanged
        if (!canScrollConditions)
            return;
        const scrollHeight = this.messageContainer.nativeElement.scrollHeight;
        const scrollChangedSize = this.prevChatHeight !== scrollHeight;
        const canScroll = canScrollConditions && scrollChangedSize;

        this.prevChatHeight = scrollHeight;
        this.newInteractionArrived = false;
        this.subscriptionChanged = false;
        return canScroll;
    }

    public scrollDown(): void {
        timer(200).subscribe(() => {
            this.messageContainer.nativeElement.scroll({
                top: Number.MAX_SAFE_INTEGER,
                behavior: 'smooth'
            })
        });
    }
    //#endregion

    detectChangesOnFirstLevelMsgs(): void {
        for (const handler of this.getInteractionHandlerArray()) {
            handler.changeDetectionOnMessageInstance();
        }
    }

    // Listeners COMPLIANCE
    public changeSubscriptionSignCallback(subscriptionSignal: SubscriptionSignal): void {
        this.markForCheck();
        this.subscriptionChanged = true;
    };

    public getDisplayBarReactHandler(): ReactDisplayBarHandler { return null; };

    public removeInteraction(interaction: Interaction): void {
        this.handler.getMsgHandlerCallback().removeInteraction(interaction);
    };

    public changeSpecificSibling(idInteraction: TGlobalUID): void {
        const handler: MessageInstanceHandler = this.handler.getMsgHandlerCallback().getInteractionHandlerArray()
            .find((h) => { return h.getInteraction().is(idInteraction) });
        if (handler) {
            handler.changeDetectionOnMessageInstance();
        };
    };

    public changeDectionChildren(): boolean {
        let updated: boolean = false;
        const array = this.handler.getMsgHandlerCallback().getInteractionHandlerArray();
        array.forEach((handler) => {
            updated = true;
            handler.changeDetectionOnMessageInstance();
        });
        // this.msgIterator.markForCheck();
        return updated;
    };

    // Endpoint Callback COMPLIANCE
    public onMessageReact(interactionID: TGlobalUID, interactionTypeID: TGlobalUID, feedback: Feedback): void {
        this.handler.getChatBackbone().saveReaction(
            interactionID,
            interactionTypeID,
            this.getGroupID(),
            feedback.getInteractionTypeID(),
            feedback.getFeedbackID(),
            this.handler.getParticipant()
        );
    };


    public callMessageContainerChangeDetector(refreshVector: boolean): void {
        if (refreshVector) {
            this.handler.getMsgHandlerCallback().refreshVector();
        };
        this.cdr.markForCheck();
    };

    public receiveTypingInfoCallback(info: TypingSignal): void {
        this.signature = info.getObservableInformation();
        let avatar: Avatar;
        let img: string = "";
        if (UberCache.testCache(this.signature.idAvatar)) {
            avatar = Avatar.staticFactory(this.signature.idAvatar);
            if (avatar.getMultimediaObject()) {
                img = avatar
                    .getMultimediaObject()
                    .getMultimediaIDwithIDTag(MMconstant.tag.photo);
            }
        }
        this.setTypingVariables(this.signature.avatarName, img);
        setTimeout(() => {
            this.setTypingVariables("", "");
        }, clientConstants.UI.typingDurationEvent);
    }

    public getInteractionHandlerArray(): TMessageInstanceHandlerArray {
        const all: TMessageInstanceHandlerArray = this.handler.getMsgHandlerCallback().getInteractionHandlerArray();
        const chosenOnes = all.filter(handler => this.isAllFilterValid(handler));
        return chosenOnes
    };

    private isAllFilterValid(handler: MessageInstanceHandler): boolean {
        if (this.chatBackboneHandler.isInFeatureVisualization() && this.chatBackboneHandler.getRootInteraction()) {
            const idRootInteraction = this.chatBackboneHandler.getRootInteraction().getPrimaryID();
            return handler.getInteraction().is(idRootInteraction) || isChildOff(idRootInteraction, handler.getInteraction());

        } else if (this.getChatBackboneModel().hasAdvancedFilters()) {
            if (isValidAndEqual(this.getChatBackboneModel().getAdvancedFilters().showAdminInteraction, true)) {
                // @TODO Daniel ver isto:
                // return this.isToolbarFeature(handler) || handler.getInteraction().getInteractionType().isAdminModeVisible()
            };
        } else if (this.chatBackboneHandler.isInAnyChat()) {
            const model: ChatBackboneModel = ChatBackboneModel.getBackboneModel(this.handler.getGroupID());
            // @TODO Daniel ver o special visible:
            // const specialVisible: boolean = this.handler.getGroupType().is(constant.groupType.functional.featureCreator) ||
            //     ! handler.getInteraction().getInteractionType().isAdminModeVisible()
            const isFC = handler.getInteraction()
                .getInteractionType()
                .is(constant.interactionType.standard.featureCarrier)

            return (
                isFC ? !(this.attendanceSvc.isAttendingOnGroup(this.handler.getGroupID()) || this.session.isEmbeddedChat()) :
                    !this.isToolbarFeature(handler) &&
                    handler.getInteraction().isShallowVisibleOnCurrentFilter(
                        this.handler.getGroupID(),
                        this.handler.getCurrentSearchFilters(), model.getDynamicFilters()
                    )
            );

        }

        return false;

    };



    private isToolbarFeature(handler: MessageInstanceHandler): boolean {
        return this.toolbarSvc.isTollbarFeature(this.getGroupID(), handler.getInteraction())
    }

    public changedMessagesLength(event) {
        if (event.end > this.lastBiggerEnd) {
            this.lastBiggerEnd = event.end;
        }
    }

    private setTypingVariables(name: string, idMedia: string): void {
        this.avatarImage = idMedia;
        this.typingAvatar = name;
        this.cdr.markForCheck();
    }

    public getWhoIsTyping(): string {
        return this.typingAvatar
            ? `${this.typingAvatar} ${this.labels.isTyping}...`
            : ''
    };
    public getImageAvatarTyping(): string { return this.avatarImage; };

    //#region Dropdown

    async onMsgRemoved(interactionClicked: Interaction): Promise<void> {
        this.handler.getChatBackbone().deleteInteraction(interactionClicked);
        this.markForCheck();
    }

    getGroupID(): TGlobalUID {
        return this.handler.getGroupID();
    }

    markForCheck(): void {
        this.cdr.markForCheck();
    }

    getDropDownHandler(): NDropDown.DropDownHandler {
        return this.currentDropdownHandler;
    }
    //#endregion

    //#region Survey

    isSurvey(): boolean {
        return this.getChatBackboneModel().isFeatureCreator()
    }

    canSetMarginOnBothSides(): boolean {
        return this.isSurvey() && !this.chatBackboneHandler.isInFeatureVisualization()
    }
    //#endregion

    //#region Handlers
    isBackboneHandlerExists(): boolean {
        return this.chatBackboneHandler != null
    }

    canShowChatInputBar(): boolean {
        if (this.channelStatus.isClosed) {
            return false;
        }

        return (this.chatBackboneHandler.isInAnyChat() &&
            (!this.session.isEmbeddedChat()) || !this.embedded.mustAnswerEmbeddedMenu());
    }

    canShowPreviewTitle(): boolean {
        return this.isSurvey() && this.chatBackboneHandler.isInAnyChat();
    }

    getChatBackboneModel(): ChatBackboneModel {
        return this.handler.getChatBackbone();
    }

    scrollTop(): void {
        this.messageContainer.nativeElement.scrollTop = 0
    }

    mustAnswerEmbeddedMenu(): boolean {
        return this.embedded.mustAnswerEmbeddedMenu();
    }
    //#endregion

    public get emojiBoxOpened(): boolean {
        return this.actionBarComponent?.showEmojiBox;
    }

    public getActionBarMinHeight(): number {
        return this.canShowChatInputBar() ? this.actionBarComponent?.getMinHeight() : 0;
    }

    public getMessageContainerHeight(): string {
        return this.isLastFormFieldShowAsCalendar()
            ? 'calc(100% - 280px)'
            : this.percentOfAbove
    }

    public isLastFormFieldShowAsCalendar(): boolean {
        return this.session.isEmbeddedChat() && this.embedded.isLastFormFieldShowAsCalendar();
    }

    public getActionBarHeight(): string {
        return this.isLastFormFieldShowAsCalendar()
            ? '280px'
            : this.percentOfBellow
    }

    public disableActionBarResize(): boolean {
        return this.isLastFormFieldShowAsCalendar();
    }
}
