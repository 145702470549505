import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { TBasicUniveralInfoArray } from "@colmeia/core/src/comm-interfaces/aux-interfaces";
import { EMashupColor, EMashupSize } from '@colmeia/core/src/jimp/jimp.constants';
import { getAvailableColorsBySize } from '@colmeia/core/src/jimp/jimp.utils';
import { EMimeTypes } from '@colmeia/core/src/multi-media/file-interfaces';
import { isValidMedia } from '@colmeia/core/src/rules/mm-functions';
import { EBPMAction, IBPMActionModel } from '@colmeia/core/src/shared-business-rules/BPM/bpm-action-model';
import { IGenerativeConfigurationOnAsset, KAssetType, KAssetTypeClient, KBAssetType, KBAssetTypeClientOnly, mapKBAssetClientToServer } from "@colmeia/core/src/shared-business-rules/bot/bot-asset-model";
import { EBotContentEvent, IBotActionAPIAsset, IContentBasicAsset } from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { INextGenBotServer } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { EBotActionType } from "@colmeia/core/src/shared-business-rules/bot/new-bot-action";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { allBotTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/bot';
import { IGenerativePromptServer } from '@colmeia/core/src/shared-business-rules/generative/generative-model';
import { IBotTransaction, ITransactionServer } from "@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction";
import { ESourceOfInfo } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { TIVariablesArray } from '@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces';
import { TIEditorVariableArray } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { ENonSerializableObjectType, INonSerializable, IdCampaignAction } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { GenericSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service';
import { ETooltipInfoKey } from '@colmeia/core/src/shared-business-rules/tooltip-json/tooltip-json.model';
import { nonNullable } from '@colmeia/core/src/tools/type-utils';
import { empty, isInEnum, isInvalid, isInvalidArray, isInvalidString, isValidArray, isValidRef, isValidString, values } from '@colmeia/core/src/tools/utility';
import { HexagonUploaderComponent } from 'app/components/foundation/hexagon-uploader/hexagon-uploader.component';
import { INSPickerHandlerClientCallback, INSPickerHandlerParameter, NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { EVarEditorEntityType, pickFormatVisibility } from 'app/handlers/var-editor.handler';
import { EMediaAssetType, IAssetAdderHandler, getAssetInstanceTypeClient, mediaAssetTypeAllowedMimeMap } from 'app/model/dashboard/asset-adder.model';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { LookupService } from 'app/services/lookup.service';
import { VariablesService } from 'app/services/variables.service';
import { merge } from 'lodash';
import * as $tools from 'ts-toolbelt';
import { ConnectionRoutePickerHandler } from "../../../../handlers/connection-route-picker.handler";
import { HexagonUploaderHandler } from "../../../../handlers/hexagon-uploader-handler";
import { InputNumberHandler } from "../../../../handlers/input-number.handler";
import { AssetTypeTranslations } from "../../../../model/bot-transaction.model";
import { IColmeiaDialogComponentData } from "../../../dialogs/dialog/dialog.component";
import { EEnumPickerMode, EnumPickerHandler } from "../../../foundation/enum-picker/enum-picker.handler";
import { RootComponent } from "../../../foundation/root/root.component";
import { InputTextVariablesComponent, InputTextVariablesHandler } from '../../input-text-variables/input-text-variables.component';
import { ConditionsEditorHandler, IConditionsEditorParameters, TConditionsEditorTargetsList } from '../../new-condition-editor/conditions-editor/conditions-editor.model';
import { IGenerativeIntentConfig } from '@colmeia/core/src/shared-business-rules/knowledge-base/luis-core-interfaces';
import { isSendQuickCampaign, isValidToSendOnOngoingConversation } from '@colmeia/core/src/shared-business-rules/campaigns/mkt-utils';
import { isCTAURLTemplate, isWhatsAppTemplate, isWhatsAppTemplateNS } from '@colmeia/core/src/shared-business-rules/social-media/template.functions';
import { ICampaignActionPicker } from 'app/components/dashboard/campaign-action-picker/campaign-action-picker.component';
import { isMassComm } from '@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/campaign';
import { ICampaignServer } from '@colmeia/core/src/shared-business-rules/campaigns/campaign-type-model';
import { GlobalWarningService } from 'app/services/global-warning.service';
import { isNSType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-interface-mapper';

export interface AssetAdderCloseCallback {
    close(): void;
}

export interface IAssetAdderInstance {
    showInputTextVariables: boolean;
    waitTimeSecondsNumberHandler: InputNumberHandler;
    assetTypeEnum: typeof KBAssetType;
    botActionType: typeof EBotActionType;
    connRouteHandler: ConnectionRoutePickerHandler;
    newAssetUploader: HexagonUploaderHandler;
    newAsset: IContentBasicAsset;
    enumRecencyPickerHandler: EnumPickerHandler<string>;
    inputNumberHandler: InputNumberHandler;
    editingAsset: boolean;
    schemaVariables: TIVariablesArray;
    bots: TBasicUniveralInfoArray;
    transactions: IBotTransaction[];
    handler: IAssetAdderHandler;
    limitCharacters: number;
    onAssetTypeChange(event: MatSelectChange): void;
    isOnlyText(): boolean;
    toggleEditAsset(asset?: IContentBasicAsset): void;
    editAsset(): Promise<void>;
    saveAsset(): Promise<void>;
    setDialogInstanceCDR(callback: AssetAdderCloseCallback, cdr: ChangeDetectorRef): void;
    toggleAddAsset(): void;
    assetTypes: Array<KAssetTypeClient>;
    disableFallback?: true;
    varEditorMode?: EVarEditorEntityType; // Default === EVarEditorEntityType.NonSerializable
    markForCheck();
    computeAmountCharactersString?: (rawText: string) => string;
}

@Component({
    selector: 'app-asset-adder-add-form-dialog',
    templateUrl: './asset-adder-add-form-dialog.component.html',
    styleUrls: ['./asset-adder-add-form-dialog.component.scss']
})
export class AssetAdderAddFormDialogComponent extends RootComponent<'type' | 'image' | 'apiCall' | 'transaction' | 'bot'> implements OnInit, AfterViewInit {

    public instance: IAssetAdderInstance;
    private _instanceTypeClient: KAssetTypeClient; // variável apenas do client, não é salva no backend
    public forceMimeType: EMimeTypes | undefined; // usado para enviar arquivo do tipo sticker
    public goHumanPicker: NSPickerHandler;
    public goActionTreePicker: NSPickerHandler;
    public goActionTreeBotPicker: NSPickerHandler;
    public selectedIdBot: string;
    public get idElement(): string {
        return this.botActionApisset.idElement;
    }
    public set idElement(value: string) {
        this.botActionApisset.idElement = value;
    }
    public get limitCharacters(): number {
        return this.instance.limitCharacters;
    }
    public get disableFallback(): true {
        return this.instance.disableFallback;
    }
    public get varEditorMode(): EVarEditorEntityType { return this.instance.varEditorMode }

    public assetTypesIcons: { [key in KAssetType]?: string } = {
        [KBAssetType.content]: "text_fields",
        [KBAssetType.contentAPICall]: "api",
        [KBAssetType.media]: "attachment",
        [EBotActionType.goBot]: "android",
        [EBotActionType.contentGenerator]: "low_priority",
        [EBotActionType.goHuman]: "contact_phone",
        [EBotActionType.goActionTree]: "ballot",
    }

    localeTranslation: { [key in EBotContentEvent]?: string } = {
        [EBotContentEvent.optionTitle]: 'Título',
        [EBotContentEvent.optionDescription]: 'Descrição',
    }

    KBAssetType = KBAssetType;

    public KBAssetTypeClientOnly = KBAssetTypeClientOnly;
    public ETooltipInfoKey = ETooltipInfoKey;

    public conditionsHandler: ConditionsEditorHandler;

    @ViewChild(InputTextVariablesComponent)
    inputTextVariables: InputTextVariablesComponent;

    @ViewChild("hexagonUploader", { static: false })
    hexagonUploader?: HexagonUploaderComponent;

    public generativeVectorPicker: NSPickerHandler;
    public generativeChunksPicker: NSPickerHandler;
    public prompt: IGenerativePromptServer;

    constructor(
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) private data: IColmeiaDialogComponentData<IAssetAdderInstance>,
        private dialogRef: MatDialogRef<any>,
        private dashboardSvc: DashBoardService,
        private lookupSvc: LookupService,
        private variablesSvc: VariablesService,
        private warningSvc: GlobalWarningService,
    ) {
        super(
            {
                ...AssetTypeTranslations,
                ...allBotTranslations,
                'image': gTranslations.common.image,
                'bot': gTranslations.bot.bots,
                'type': gTranslations.common.type,
            },
            true,
            cdr
        );
        this.instance = data.getParamsToChildComponent();
        this.instance.setDialogInstanceCDR(this, cdr);
        this.dialogRef.disableClose = true;
        const preSelectedValue = this.instance.handler.preSelectedValue;
        if (isValidRef(preSelectedValue)) this.instanceType = preSelectedValue as EBotActionType | KBAssetType;
        this._instanceTypeClient = this.instanceType;
    }

    ngOnInit(): void {
        this.init();
    }

    ngAfterViewInit(): void {
        if (this.instanceType === KBAssetType.media) {
            this.initInstanceType();
        }
    }

    public getNSs: (ids: string[]) => Promise<INonSerializable[]> = this.lookupSvc.createNSCacheImplementation();

    public getNSVariables(): TIEditorVariableArray {
        if (!isValidRef(this.instance.handler.variables?.[EVarEditorEntityType.NonSerializable])) {
            return this.variablesSvc.getAssetAdderVariables()[EVarEditorEntityType.NonSerializable];
        }

        return merge(
            this.variablesSvc.getAssetAdderVariables()[EVarEditorEntityType.NonSerializable],
            this.instance.handler.variables[EVarEditorEntityType.NonSerializable]
        );
    }

    get fineTuning() {
        return this.instance.newAsset.generativeConfiguration?.fineTunning;
    }

    set fineTuning(value: boolean) {
        if (!this.instance.newAsset.generativeConfiguration) {
            return;
        }
        this.instance.newAsset.generativeConfiguration.fineTunning = value;
    }

    public generatorPicker: NSPickerHandler;
    public initGeneratorPicker(): void {
        const extraConfig: Partial<INSPickerHandlerParameter> = {
            useNsMatchByCustomerChoice: {
                allowPropertyValueSelection: true,
            }
        };
        const nsPickerSelectedProperty = this.instance.handler.nsPickerSelectedProperty;
        if (isValidString(nsPickerSelectedProperty))
            extraConfig.useNsMatchByCustomerChoice = {
                ...extraConfig.useNsMatchByCustomerChoice,
                config: {
                    value: nsPickerSelectedProperty
                }
            }
        this.generatorPicker = this.dashboardSvc.easyCreateNSPickerHandler({
            title: this.translations.transaction.value,
            nsType: ENonSerializableObjectType.contentGenerator,
            selectedId: this.idElement,
            clientCallback: {
                onSaveNSCallback: ns => this.onSelectGeneratorCallback(ns as ITransactionServer),
            },
            useDemandedTag: false,
        }, extraConfig);
    }

    get asset() {
        return this.instance.newAsset;
    }

    public get handler() {
        return this.instance.handler;
    }

    public get shouldAskDisplayName() {
        return this.handler.shouldAskMediaDisplayName ?? true;
    }

    public get shouldHideBottomOptions() {
        return this.handler.shouldHideBottomOptions;
    }

    public botPicker: NSPickerHandler;
    private initBotPicker() {
        this.botPicker = this.dashboardSvc.easyCreateNSPickerHandler({
            title: 'Selecione um bot',
            nsType: ENonSerializableObjectType.bot,
            clientCallback: <INSPickerHandlerClientCallback>{
                onSaveNSCallback: ns => isValidRef(ns) && this.onSelectBot(ns as INextGenBotServer),
            },
            useDemandedTag: false,
            selectedId: this.idElement,
        });
    }

    private onSelectBot(botNS: INextGenBotServer) {
        this.idElement = botNS.idNS;
    }

    public onSelectGeneratorCallback(ns: ITransactionServer): void {
        this.idElement = isValidRef(ns) ? ns.idNS : undefined;
    }

    public async getNS(idNS: string): Promise<INonSerializable> {
        const nss: INonSerializable[] = (await this.getNSs([idNS]));
        const ns: INonSerializable = nss[0];
        return ns;
    }

    campaignActionPicker: ICampaignActionPicker;
    initCampaignActionPicker() {
        this.campaignActionPicker = {
            value: this.asset.sendTemplateConfig?.idCampaignAction,
            valueChange: (idCampaigAction: IdCampaignAction) => {
                this.asset.sendTemplateConfig ??= {};
                this.asset.sendTemplateConfig.idCampaignAction = idCampaigAction;
            },
            filters: {
                actionFilter: action => {
                    return isMassComm(action) && isSendQuickCampaign(action);
                },
            },
        };
    }

    ctaTemplatePicker: NSPickerHandler<ENonSerializableObjectType.channelTemplate>;
    initCTATemplatePicker() {
        this.ctaTemplatePicker = new NSPickerHandler({
            title: 'Selecione um template',
            nsType: ENonSerializableObjectType.channelTemplate,
            nonSerializablesIds: [this.asset?.cta?.idTemplate].filter(isValidRef),
            clientCallback: {
                onSaveNSCallback: (ns) => {
                    if (!ns?.idNS) {
                        delete this.asset.cta;
                        return;
                    }

                    this.asset.cta = {
                        idTemplate: ns.idNS!
                    };
                },
            },
            filter: (ns) => this.isNSTemplate(ns) && isWhatsAppTemplateNS(ns) && isCTAURLTemplate(ns.parameter),
        })
    }

    isNSTemplate = isNSType(ENonSerializableObjectType.channelTemplate);



    mapInit: { [type in | EBotActionType | KBAssetType]?: () => void } = {
        [EBotActionType.goHuman]: () => {
            this.initGoHuman();
        },
        [EBotActionType.goActionTree]: () => {
            this.initGoActionBot();
        },
        [EBotActionType.contentGenerator]: () => {
            this.initGeneratorPicker();
        },
        [EBotActionType.goBot]: () => {
            this.initBotPicker();
        },
        [KBAssetType.generativo]: () => {
            this.initGenerativePromptPicker();
            setTimeout(() => this.initGenerativePicker(), 1000)
        },
        [KBAssetType.sendTemplate]: () => {
            this.initCampaignActionPicker();
        },
        [KBAssetType.sendCTA]: () => {
            this.initCTATemplatePicker();
        },
    }


    public async init(): Promise<void> {
        this.inputTextVariablesHandler = {
            ...pickFormatVisibility(this.handler),
        }

        const instanceType: EBotActionType | KBAssetType = this.instanceType as EBotActionType | KBAssetType;

        this.mapInit[instanceType]?.();

        if (this.instance.newAsset.isConditionalContent) {
            this.initConditionalDisplayEditorHandler();
        }

        if (this.hasMedia) {
            this.initMashup();
        }

        if (isValidRef(this.instance.newAsset.generativeConfiguration?.idPrompt))
            this.prompt = await this.lookupSvc.getNS(this.instance.newAsset.generativeConfiguration?.idPrompt);
        else
            this.prompt = undefined;
        // this.dialogRef.beforeClosed().subscribe(() => this.cancelEditing());
    }

    public async initGoActionBot(): Promise<void> {
        if (isInvalid(this.selectedIdBot) && isValidRef(this.idElement)) {
            this.selectedIdBot = (await this.getNS(this.idElement)).idParent;
        }

        this.goActionTreeBotPicker = this.dashboardSvc.easyCreateNSPickerHandler({
            title: 'Selecione um bot',
            nsType: ENonSerializableObjectType.bot,
            clientCallback: <INSPickerHandlerClientCallback>{
                onSaveNSCallback: ns => this.onSelectGoActionBot(ns),
            },
            useDemandedTag: false,
            selectedId: this.selectedIdBot,
        });

        if (isValidRef(this.idElement)) this.initGoAction();
    }

    public onSelectGoActionBot(ns: INonSerializable): void {
        this.selectedIdBot = isValidRef(ns) ? ns.idNS : undefined;
        this.idElement = undefined;

        if (this.selectedIdBot) this.initGoAction();
        else this.goActionTreePicker = undefined;
    }

    public initGoAction(): void {
        this.goActionTreePicker = this.dashboardSvc.easyCreateNSPickerHandler({
            title: 'Selecione um menu',
            nsType: ENonSerializableObjectType.bot,
            idParent: this.selectedIdBot,
            clientCallback: <INSPickerHandlerClientCallback>{
                onSaveNSCallback: ns => this.onSelectGoActionTree(ns),
            },
            useDemandedTag: false,
            selectedId: this.idElement,
        })
    }

    public async initGoHuman(): Promise<void> {
        this.goHumanPicker = this.dashboardSvc.easyCreateNSPickerHandler({
            title: 'Selecione uma ilha',
            nsType: ENonSerializableObjectType.serviceIsland,
            clientCallback: <INSPickerHandlerClientCallback>{
                onSaveNSCallback: ns => this.onSelectGoHuman(ns),
            },
            selectedId: this.idElement,
            useDemandedTag: false
        });
    }

    public get instanceType(): KBAssetType | EBotActionType {
        return this.instance.newAsset.type;
    }

    public set instanceType(value: KBAssetType | EBotActionType) {
        this.instance.newAsset.type = value as KBAssetType;
        this.reset();
        this.init();
    }

    public get instanceTypeClient(): KAssetTypeClient {
        return this._instanceTypeClient;
    }

    public set instanceTypeClient(value: KAssetTypeClient) {
        this._instanceTypeClient = value;

        if (isInEnum(KBAssetTypeClientOnly, value)) {
            this.instanceType = mapKBAssetClientToServer[value as KBAssetTypeClientOnly];
        } else {
            this.instanceType = value as KBAssetType;
        }
    }

    public reset(): void {
        type Reset = $tools.Any.Compute<
            & $tools.Object.Select<AssetAdderAddFormDialogComponent, string, 'equals'>
            & $tools.Object.Select<AssetAdderAddFormDialogComponent, NSPickerHandler, 'equals'>
            , 'flat'>;
        const toReset: Reset = {
            selectedIdBot: undefined,
            idElement: undefined,
            goHumanPicker: undefined,
            goActionTreePicker: undefined,
            goActionTreeBotPicker: undefined,
            generatorPicker: undefined,
            botPicker: undefined,
            generativeVectorPicker: undefined,
            generativeChunksPicker: undefined,
            generativePromptPicker: undefined
        }
        Object.assign(this, toReset as Partial<this>);
    }

    public get botActionApisset(): IBotActionAPIAsset {
        return (<IBotActionAPIAsset>this.instance.newAsset);
    }

    public onSelectGoHuman(ns: INonSerializable): void {
        this.idElement = isValidRef(ns) ? ns.idNS : undefined;
    }
    public onSelectGoActionTree(ns: INonSerializable): void {
        this.idElement = isValidRef(ns) ? ns.idNS : undefined;
        this.initGoAction();
    }

    close() {
        this.dialogRef.close();
    }

    public forceVariablesSave(): void {
        if (this.inputTextVariablesHandler?.home?.forceSave)
            this.inputTextVariablesHandler.home.forceSave()
                ;
    }

    public inputTextVariablesHandler: InputTextVariablesHandler = {};

    public editAsset() {
        this.forceVariablesSave();

        if (isValidRef(this.inputTextVariables) && !this.inputTextVariables.varEditorHandler.getHomeInstance().valid) return;

        this.instance.editAsset();
    }

    public saveAsset() {
        this.forceVariablesSave();

        if (isValidRef(this.inputTextVariables) && !this.inputTextVariables.varEditorHandler.getHomeInstance().valid) return;

        this.instance.saveAsset();
    }

    cancelEditing() {
        this.instance.toggleEditAsset();
        this.instance.markForCheck();
    }

    public cancelEditingAndClose(): void {
        this.cancelEditing();
        this.close();
    }

    handleCancelClick() {
        if (this.instance.editingAsset) {
            this.cancelEditingAndClose();
        } else {
            this.instance.toggleAddAsset();
        }
    }

    handleSaveClick() {
        if (!this.validate()) {
            return;
        }

        if (this.instance.editingAsset) {
            this.editAsset();
        } else {
            this.saveAsset();
        }
    }

    private validate(): boolean {
        let errorMessage;

        if (this.hasBotEventContentType()) {
            errorMessage = this.validateBotEventContentType();
        }

        if (this.instanceType === KBAssetType.generativo) {
            errorMessage = this.validateGenerative();
        }

        if (isValidString(errorMessage)) {
            this.warningSvc.showError(errorMessage);
            return false;
        }

        return true;
    }

    private validateGenerative(): string | undefined {
        if (isInvalid(this.instance.newAsset.generativeConfiguration))
            return "A escolha de um Prompt é obrigatória.";
        if (isInvalidString(this.getVectorId()))
            return "A escolha de um Vetor é obrigatória. Defina um vetor para esta configuração ou espelhe o Vetor e os Chunks da base de conhecimento,";
        if (isInvalidArray(this.getChunkIds()))
            return "Escolha ao menos um Chunk para poder salvar esta configuração ou espelhe o Vetor e os Chunks da base de conhecimento,";
    }

    private validateBotEventContentType(): string | undefined {
        if (!isInEnum(EBotContentEvent, this.instance.newAsset.botContentType))
            return "A escolha do tipo de conteúdo é obrigatória.";
    }

    public onConditionalDisplayToggleChange() {
        if (!this.instance.newAsset.isConditionalContent) return;

        const { displayCondition } = this.instance.newAsset;

        if (!isValidRef(displayCondition)) {
            this.instance.newAsset.displayCondition = {
                name: undefined,
                matchConditions: [],
                action: ({
                    doNotDisplay: false,
                    bpmAction: EBPMAction.conditionalDisplay
                }) as IBPMActionModel,
                stopAction: false
            }
        }

        if (!isValidRef(this.conditionsHandler)) {
            this.initConditionalDisplayEditorHandler();
        }

    }

    public getDisplayName() {
        if (!this.hasMedia) return "";
        return this.instance.newAsset.media?.displayMediaName;
    }

    public handleCustomDisplayName(event: InputEvent) {
        if (!this.hasMedia) return;
        const name = (event.target as HTMLInputElement).value;
        this.instance.newAsset.media!.displayMediaName = name;
    }

    mashupColorHandler: EnumPickerHandler<EMashupColor>;
    mashupSizeHandler: EnumPickerHandler<EMashupSize>;
    public initMashup(): void {

        this.instance.newAsset.textOnImage ??= {
            color: empty,
            size: empty,
        }

        this.mashupSizeHandler = new EnumPickerHandler<EMashupSize>({
            clientCallback: null,
            client: {
                onSingleEnumSelection: status => {
                    this.instance.newAsset.textOnImage.size = status;
                    this.initMashupColorHandler()
                },
            },
            mode: EEnumPickerMode.Single,
            translations: {
                [EMashupSize.size8]: '8',
                [EMashupSize.size10]: '10',
                [EMashupSize.size12]: '12',
                [EMashupSize.size14]: '14',
                [EMashupSize.size16]: '16',
                [EMashupSize.size32]: '32',
                [EMashupSize.size64]: '64',
                [EMashupSize.size128]: '128',
            },
            inputTitle: 'Selecione um tamanho',
            enum: EMashupSize,
            current: this.instance.newAsset.textOnImage.size as EMashupSize,
            appearance: 'outline',
        });


        if (this.instance.newAsset.textOnImage.size) {
            this.initMashupColorHandler();
        }
    }

    public get hasMedia(): boolean {
        return isValidMedia(this.instance.newAsset.media)
    }
    public initMashupColorHandler(): void {
        const size = this.instance.newAsset.textOnImage.size as EMashupSize;
        const color = this.instance.newAsset.textOnImage.color as EMashupColor;
        const availableColors = getAvailableColorsBySize(size)

        if (!nonNullable(availableColors[color])) {
            this.instance.newAsset.textOnImage.color = empty;
        }

        this.mashupColorHandler = new EnumPickerHandler<EMashupColor>({
            clientCallback: null,
            client: {
                onSingleEnumSelection: status => (this.instance.newAsset.textOnImage.color = status),
            },
            mode: EEnumPickerMode.Single,
            translations: {
                [EMashupColor.Black]: 'Preto',
                [EMashupColor.White]: 'Branco',
            },
            ignoreValues: values(EMashupColor).filter(item => !nonNullable(availableColors[item])),
            inputTitle: 'Selecione uma cor',
            enum: EMashupColor,
            current: this.instance.newAsset.textOnImage.color as EMashupColor,
            appearance: 'outline',
        });
    }

    public initConditionalDisplayEditorHandler() {
        const { displayCondition } = this.instance.newAsset;
        const targetsList: TConditionsEditorTargetsList = []
        const allowedSourceInfo: ESourceOfInfo[] = [
            ESourceOfInfo.canonical,
            ESourceOfInfo.bot,
            ESourceOfInfo.serviceScheduler,
            ESourceOfInfo.metrics,
            ESourceOfInfo.botTracker,
            ESourceOfInfo.generativeChunks
        ];

        if (isValidArray(this.instance.handler.additionalSourceInfo)) {
            allowedSourceInfo.push(...this.instance.handler.additionalSourceInfo)
        }

        if (isValidArray(this.instance.handler.additionalConditionsTargets)) {
            allowedSourceInfo.push(ESourceOfInfo.property);
            targetsList.push(...this.instance.handler.additionalConditionsTargets)
        }

        const conditionsParameters: IConditionsEditorParameters = {
            targetsList,
            allowedSourceInfo,
            currentConditions: isValidRef(displayCondition) ? displayCondition.matchConditions : undefined,
            clientCallback: {
                onChange: (conditions) => {
                    displayCondition.matchConditions = conditions;
                },
            },
        }

        conditionsParameters.chunksConfig = this.getIntentsKBGenerativeChunks();

        this.conditionsHandler = new ConditionsEditorHandler(conditionsParameters);
    }

    public saveIfRequested($event: KeyboardEvent) {
        const isContentTextAsset: boolean = this.instanceType === this.instance.assetTypeEnum.content;
        if (($event.ctrlKey && $event.code === 'Enter') && isContentTextAsset) {
            this.saveAsset();
        }
    }

    hasBotEventContentType(): boolean {
        return !!this.instance.handler.botContentEventTypesSelectionPerMessage?.length
    }

    canAddSubType(item: EBotContentEvent): boolean {
        if (this.instance.handler.canRepeatBotContentEventTypes) return true;

        return !this.instance.handler.assets.some((i: IContentBasicAsset) => i.botContentType === item);
    }

    getIntentsKBGenerativeChunks(): IGenerativeIntentConfig {
        const intentOSConfig = this.handler.intent?.intentOSConfig?.generative;
        return intentOSConfig;
    }

    getGenerativeChunksConfig() {
        const config = this.mirrorKBIntent
            ? this.handler.intent?.intentOSConfig?.generative
            : this.instance.newAsset.generativeConfiguration?.chunks
            ;
        return config;
    }

    getVectorId() {
        const config = this.getGenerativeChunksConfig();
        const selectedId = config?.idVector ?? undefined;
        return selectedId;
    }

    getChunkIds() {
        const config = this.getGenerativeChunksConfig();
        const selectedId = config?.idsNSChunk ?? undefined;
        return selectedId;
    }


    public async initGenerativePicker(): Promise<void> {
        const selectedId = this.getVectorId();
        this.generativeVectorPicker = undefined;
        this.generativeVectorPicker = this.dashboardSvc.easyCreateNSPickerHandler({
            title: "Selecione um Vetor",
            nsType: ENonSerializableObjectType.contentVector,
            clientCallback: {
                onSaveNSCallback: (ns) => {
                    let config: IGenerativeConfigurationOnAsset = this.getInstanceGenerativeConfig();
                    config.chunks.idVector = ns?.idNS;
                    if (isValidString(config.chunks.idVector)) {
                        this.initGenerativeChunksPicker();
                    } else {
                        config.chunks.idsNSChunk = [];
                        this.generativeChunksPicker = undefined;
                    }
                },
            },
            useDemandedTag: false,
            selectedId,
        });
        if (selectedId) {
            await this.initGenerativeChunksPicker();
        } else {
            this.generativeChunksPicker = undefined;
        }
    }

    private getInstanceGenerativeConfig() {
        let generativeConfig: IGenerativeConfigurationOnAsset = this.instance.newAsset.generativeConfiguration;
        if (isInvalid(generativeConfig)) {
            generativeConfig = {
                idPrompt: "",
                chunks: {
                    idVector: "",
                    idsNSChunk: [],
                }
            }
        }

        if (isInvalid(generativeConfig.chunks)) {
            generativeConfig.chunks = {
                ...generativeConfig,
                idVector: "",
                idsNSChunk: [],
            }
        }

        this.instance.newAsset.generativeConfiguration = generativeConfig;
        return generativeConfig;
    }

    public async initGenerativeChunksPicker(): Promise<void> {
        const config = this.getGenerativeChunksConfig();

        this.generativeChunksPicker = this.dashboardSvc.easyCreateNSPickerHandler({
            title: 'Selecione os Chunks',
            nsType: ENonSerializableObjectType.contentVectorChunk,
            idParent: config?.idVector ?? undefined,
            clientCallback: {
                onSaveCallback: (nss) => {
                    const config = this.getGenerativeChunksConfig();

                    if (!config) {
                        return;
                    }

                    config.idsNSChunk = nss?.map(ns => ns.idNS!) ?? [];
                },
            },
            useDemandedTag: false,
            selectedIds: config?.idsNSChunk ?? [],
            maxSelections: Number.MAX_SAFE_INTEGER,
        })
    }

    public generativePromptPicker: NSPickerHandler;
    public async initGenerativePromptPicker(): Promise<void> {
        this.generativePromptPicker = this.dashboardSvc.easyCreateNSPickerHandler({
            title: "Selecione um Prompt",
            nsType: ENonSerializableObjectType.generativePrompt,
            clientCallback: <INSPickerHandlerClientCallback>{
                onSaveCallback: async (nss) => {
                    const idPrompt = isValidArray(nss) ? nss[0].idNS : undefined;
                    if (isInvalid(idPrompt)) {
                        delete this.instance.newAsset.generativeConfiguration;
                        this.prompt = undefined;
                        this.generativeVectorPicker = undefined;
                        return;
                    }
                    this.instance.newAsset.generativeConfiguration = { idPrompt };
                    this.prompt = await this.lookupSvc.getNS(this.instance.newAsset.generativeConfiguration?.idPrompt);
                    this.initGenerativePicker();
                },
            },
            useDemandedTag: false,
            selectedId: this.instance.newAsset.generativeConfiguration?.idPrompt ?? undefined,
        });
        this.setPerPage(this.generativePromptPicker);
    }

    get hasGenerativeConfiguration() {
        return isValidRef(this.instance.newAsset.generativeConfiguration);
    }


    get mirrorKBIntent() {
        return this.instance.newAsset.generativeConfiguration?.mirrorKBIntent;
    }

    set mirrorKBIntent(value) {
        if (!this.instance.newAsset.generativeConfiguration) {
            return;
        }

        if (value && !this.intentHasValidGenerativeConfig()) {
            this.warningSvc.showError(
                'Essa intenção não está configurada para usar a opção Generativo. Você pode conectar um vetor e chunks a ela na página de sua Base de Conhecimento.'
            );
            this.instance.newAsset.generativeConfiguration.mirrorKBIntent = false;
            return;
        }

        this.resetGenerative();
        setImmediate(() => this.initGenerativePicker());
        //
        this.instance.newAsset.generativeConfiguration.mirrorKBIntent = value;
    }

    resetGenerative(): void {
        this.generativeVectorPicker = undefined;
        this.generativeChunksPicker = undefined;
    }

    public intentHasValidGenerativeConfig() {
        return isValidString(this.handler.intent?.intentOSConfig?.generative?.idVector) &&
            isValidArray(this.handler.intent?.intentOSConfig?.generative?.idsNSChunk);
    }

    public perPage = 500;
    setPerPage(picker: NSPickerHandler) {
        picker.setMaxItemsPerPage(this.perPage);
    }

    /**
     * muda o valor do instanceTypeClient caso detecte que é sticker
     */
    private initInstanceType() {
        this.instanceTypeClient = getAssetInstanceTypeClient(this.instance.newAsset);

        this.updateHexagonUploader(this.instanceTypeClient);
    }

    public onChangeInstanceTypeClient(event: MatSelectChange) {
        this.updateHexagonUploader(event.value, true);
    }

    private updateHexagonUploader(instanceTypeClient: KAssetTypeClient, clearFile = false) {
        const isSticker = instanceTypeClient === KBAssetTypeClientOnly.sticker;

        if (isSticker) {
            this.forceMimeType = EMimeTypes.Sticker;
        } else {
            this.forceMimeType = undefined;
        }

        // apenas filtra por mime quando é sticker
        // no momento ele não usa outros valores do EMediaAssetType além dos que estão abaixo
        const mediaAssetType = isSticker ? EMediaAssetType.sticker : EMediaAssetType.other;
        const mimeTypes = mediaAssetTypeAllowedMimeMap[mediaAssetType];

        this.instance.newAssetUploader.mimeType = mimeTypes;

        setTimeout(() => {
            this.hexagonUploader?.updateMimeTypeFilters(clearFile);
        }, 0);
    }
}
