import { Injectable } from '@angular/core';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { TBroadcastIslandControl } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendent-sp-req-resp';
import { IJobServer, Job, TJobServer } from '@colmeia/core/src/shared-business-rules/jobs/jobs-model';
import { EJobPayloadType, ENotificationPayload, IBasicNotificationPayload, INotificationAdminSolicitationApprovalMessage, INotificationBroadcastAllocationStatus, INotificationCRMTicket, INotificationCustomerExpireClose, INotificationCustomerExpireWarn, INotificationFormSolicitationMessage, INotificationJobData, INotificationSendOpenCasesBroadcast, INotificationWebchatRenameTemporaryAvatar, IStatusAllocation, TJobNotificationData, TNotificationPayload } from '@colmeia/core/src/shared-business-rules/new-notifications/new-notification-model';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { NSSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service';
import { minToMS } from '@colmeia/core/src/time/time-utl';
import { defaults, flat, formatMessage, getClock, INewPromise, initPromise, isInvalid, isInvalidArray, isValidRef, PercentageCalculator, values } from '@colmeia/core/src/tools/utility';
import { checkEarlyReturnSwitch, DeepMap } from '@colmeia/core/src/tools/utility-types';
import { NotificationDialogService } from 'app/components/notifications-dialog/notification-dialog.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { GlobalWarningService, IInteractiveButton, INTERACTIVE_OK } from './global-warning.service';
import { LookupService } from './lookup.service';
import { ENotificationPayloadClientType, INotificationPayloadClient, INotificationPayloadClientData, TNotificationId } from './new-notifications-client.model';
import { SessionService } from './session.service';
import { SocketService } from './socket.service';

export interface INotificationCounter {
    unreadItems: INotificationPayloadClient[];
}

export type TNotificationIdMap = Record<TNotificationId, INotificationPayloadClient[]>;
export interface IWaitJobInput {
    idJob: IdDep<ENonSerializableObjectType.job>;
    inputJob?: IJobServer;
    maxWaitTime?: number;
    retry?: {
        shouldRetry?: boolean;
        retryIntervalMS?: number;
    }
}

export interface IWaitJobConfig extends INewPromise<void> {
    job: IJobServer | undefined;
    idNSTarget: IdDep | undefined;
}


export type IdJob = IdDep<ENonSerializableObjectType.job>;


@Injectable({
    providedIn: "root",
})
export class NewNotificationsService {
    private unreadCount: number = 0;
    private notificationCounter: INotificationCounter = { unreadItems: [] };
    public bSubject = new BehaviorSubject<INotificationCounter>(
        this.notificationCounter
    );

    private notificationMap: TNotificationIdMap = {};
    private newNotificationsToShowOnScreen$: Subject<INotificationPayloadClient> =
        new Subject<INotificationPayloadClient>();

    #notificationsStream: Subject<TNotificationPayload> = new Subject();
    notifications$: Observable<TNotificationPayload> =
        this.#notificationsStream.asObservable();

    #broadcastOpenQueue$ = new BehaviorSubject<TBroadcastIslandControl>(
        {}
    );
    get broadcastOpenQueue$(): Observable<TBroadcastIslandControl> {
        return this.#broadcastOpenQueue$.asObservable();
    }

    #broadcastAllocationStatus$ = new ReplaySubject<IStatusAllocation>(1);
    get broadcastAllocationStatus$(): Observable<IStatusAllocation> {
        return this.#broadcastAllocationStatus$.asObservable();
    }
    public mapWaitJobPromises: DeepMap<
        [idJob: IdJob, config: IWaitJobConfig]
    > = new Map();

    private lastOpenedCasesAvailableClocktick: number = 0;


    getIdNSJobTarget(idJob: IdJob) {
        const job = this.mapWaitJobPromises.get(idJob);
        return job?.idNSTarget;
    }

    constructor(
        private socketSvc: SocketService,
        private sessionService: SessionService,
        private notificationDialogSvc: NotificationDialogService,
        private warning: GlobalWarningService,
        private lookupSvc: LookupService
    ) {
        this.socketNotificationsProcessor();
    }

    getUnreadCount(): number {
        return this.unreadCount;
    }

    clearUnreadCount() {
        this.unreadCount = 0;
    }

    getUnreadNotificationsInMemory(): INotificationPayloadClient[] {
        return this.notificationCounter.unreadItems;
    }

    getNotificationsInMemoryGrouped(): TNotificationIdMap {
        return this.notificationMap;
    }

    setNotificationsInMemoryGrouped(value: TNotificationIdMap): void {
        this.notificationMap = value;
    }

    getNotificationsInMemoryAsArray(): INotificationPayloadClient[] {
        return flat(values(this.notificationMap)).reverse();
    }

    listenNewNotificationsToShowOnScreen(): Observable<INotificationPayloadClient> {
        return this.newNotificationsToShowOnScreen$.asObservable();
    }

    printJobMessageByTargetIdNS(idNS: IdDep) {
        const [id] = this.waitingNSs.get(idNS!) ?? [];
        return this.printJobMessage(id);
    }

    printJobMessage(idJob: IdJob | undefined) {
        if (!idJob) return;
        const config = this.mapWaitJobPromises.get(idJob);
        if (!config) return;
        const { job } = config;
        if (!job) return;
        return formatMessage`
            ${job.phaseName && `[b]${job.phaseName}[/b]`}
            ${isValidRef(job.percentComplete) && `<div>${PercentageCalculator.toPercentage(job.percentComplete)}%</div>`}
        `;
    }

    removeFromUnread(notification: INotificationPayloadClient) {
        const index =
            this.notificationCounter.unreadItems.indexOf(notification);
        this.notificationCounter.unreadItems.splice(index, 1);
        this.bSubject.next(this.notificationCounter);
    }

    addToUnread(notification: INotificationPayloadClient) {
        this.notificationCounter.unreadItems.push(notification);
        this.bSubject.next(this.notificationCounter);
    }

    private socketNotificationsProcessor() {
        this.socketSvc
            .getNewNotificationsListener()
            .subscribe((notificationFromSocket: TNotificationPayload) => {
                this.handleNotificationPayload(notificationFromSocket);
            });
    }

    private notificationConfirmationDialog: Set<string> = new Set();

    private async showNotificationConfirmationDialogIfNeeded(
        notificationPayload: TNotificationPayload
    ) {
        if (!isValidRef(notificationPayload.promptMessage)) return;

        console.log("loop showNotificationConfirmationDialogIfNeeded");

        // if (notificationPayload.idNotification) {
        //     if (this.notificationConfirmationDialog.has(notificationPayload.idNotification)) {
        //         return;
        //     }

        //     this.notificationConfirmationDialog.add(notificationPayload.idNotification);
        // }

        const { title, message, matIcon, readConfirmation } =
            notificationPayload.promptMessage;

        const okButton: IInteractiveButton = { ...INTERACTIVE_OK };

        if (isValidRef(readConfirmation)) {
            okButton.enableQuestion = readConfirmation;
        }

        await this.warning.showInteractivePrompt({
            title, message, options: [okButton], disableClose: true, matIcon: {
                icon: matIcon?.icon,
                color: matIcon?.color,
            }
        });

        if (notificationPayload.idNotification) {
            this.notificationConfirmationDialog.delete(notificationPayload.idNotification);
        }
    }

    handleNotificationPayload(notificationPayload: TNotificationPayload) {
        this.showNotificationConfirmationDialogIfNeeded(notificationPayload);
        this.#notificationsStream.next(notificationPayload);
        // console.log({ notificationPayload });

        switch (notificationPayload.type) {
            case ENotificationPayload.broadcastAllocationStatus:
                this.#broadcastAllocationStatus$.next(
                    (
                        notificationPayload as INotificationBroadcastAllocationStatus
                    ).content
                );
                return;
            case ENotificationPayload.sendOpenCasesBroadcast:
                const isOlder = notificationPayload.createdAt < this.lastOpenedCasesAvailableClocktick;
                if (isOlder) return;

                this.lastOpenedCasesAvailableClocktick = notificationPayload.createdAt;

                this.#broadcastOpenQueue$.next(
                    (notificationPayload as INotificationSendOpenCasesBroadcast)
                        .content
                );
                return;
            case ENotificationPayload.customerExpireClose:
            case ENotificationPayload.customerExpireWarn:
            case ENotificationPayload.job:
            case ENotificationPayload.adminSolicitationTaskApproval:
            case ENotificationPayload.crmTicket:
            case ENotificationPayload.formSolicitationComplete:
                return this.handleMessageNotification(notificationPayload);
            case ENotificationPayload.renameTemporaryAvatar:
                return this.handleRenameAvatarNotification(
                    notificationPayload as INotificationWebchatRenameTemporaryAvatar
                );
            case ENotificationPayload.customerTransferedBySupervisor:
            case ENotificationPayload.genericPromptMessage:
            case ENotificationPayload.supervisionBroadcastMessage:
            case ENotificationPayload.statusChangedBySupervision:
            case ENotificationPayload.agentExpire:
            case ENotificationPayload.attServiceFinish:
                return;

        }
        checkEarlyReturnSwitch()(notificationPayload);
    }

    resetAllocationStatus() {
        this.#broadcastAllocationStatus$.next(undefined);
    }

    getNotificationCount(): Observable<INotificationCounter> {
        return this.bSubject.asObservable();
    }

    incrementCounter(item: INotificationPayloadClient) {
        //Se o item já estiver adicionado na lista de não lidos, o contador não é incrementado
        if (
            this.notificationCounter.unreadItems.some(
                (unreadItem) => unreadItem.idNotification == item.idNotification
            )
        ) {
            return;
        }

        this.addToUnread(item);
        this.unreadCount++;
    }

    resetCounter() {
        this.notificationCounter.unreadItems = [];
        this.bSubject.next(this.notificationCounter);
    }

    removeFromCounter(idNotification: string) {
        const index = this.notificationCounter.unreadItems.findIndex(
            (unreadItem) => unreadItem.idNotification == idNotification
        );
        if (index >= 0) {
            this.notificationCounter.unreadItems.splice(index, 1);
            this.bSubject.next(this.notificationCounter);
        }
    }

    handleRenameAvatarNotification(
        notification: INotificationWebchatRenameTemporaryAvatar
    ) {
        const avatar: Avatar = this.sessionService.getSelectedAvatar();
        avatar.setName(notification.newName);
        avatar.setAnonymous(false);
    }

    public getRunningJobIds(): IdJob[] {
        return [...this.mapWaitJobPromises.keys()];
    }

    public updateJob(job: IJobServer) {
        if (!job.isJobStillInProgress) {
            this.unlockJob(job.idNS!);
        }
    }

    public async getRunningJobs(idNSTarget?: string): Promise<TJobServer[]> {
        let ids = this.getRunningJobIds();
        if (idNSTarget) ids = ids.filter(id => this.mapWaitJobPromises.get(id)?.idNSTarget === idNSTarget)
        if (isInvalidArray(ids)) return [];
        const jobs: TJobServer[] = await NSSharedService.getByIds(ids);
        jobs.forEach(job => this.updateJob(job));
        return jobs;
    }

    public async waitJob(input: IWaitJobInput) {
        const config = await this.setJob(input);
        return config?.promise;
    }

    public async setJob({
        idJob,
        inputJob,
        maxWaitTime = minToMS(15),
        retry,
    }: IWaitJobInput) {
        const { shouldRetry, retryIntervalMS } = defaults(retry, {
            shouldRetry: true,
            retryIntervalMS: 10000,
        });

        const previousConfig = this.mapWaitJobPromises.get(idJob);
        if (previousConfig) return previousConfig;


        const config: IWaitJobConfig = {
            ...initPromise<void>(),
            job: undefined,
            idNSTarget: undefined,
        };
        this.mapWaitJobPromises.set(idJob, config);

        const job: IJobServer = inputJob ?? (await NSSharedService.getNS(idJob))!;
        config.job = job;
        config.idNSTarget = Job.getIdNSTargetFromJob(job);

        if (!job.isJobStillInProgress) {
            this.unlockJob(job.idNS!);
            return config;
        }

        this.addJobToTarget(config.idNSTarget, idJob);

        let timeout: NodeJS.Timeout | undefined;

        if (shouldRetry) {
            const interval = setInterval(
                async () => {
                    const config = this.mapWaitJobPromises.get(idJob);
                    if (isInvalid(config)) {
                        clearInterval(interval);
                        return;
                    }
                    if (config?.job?.lastTouch && ((getClock() - config?.job?.lastTouch) < (retryIntervalMS!))) return;
                    const job = await NSSharedService.getNS(idJob);
                    this.jobSubscribers.next(job);
                    if (job && !job.isJobStillInProgress) {
                        this.unlockJob(job.idNS!);
                        clearInterval(interval);
                        if (isValidRef(timeout)) clearTimeout(timeout);
                    }
                },
                retryIntervalMS,
            );
        }

        if (maxWaitTime) {
            timeout = setTimeout(() => {
                config.reject();
                this.unlockJob(idJob);
            }, maxWaitTime);
        }

        return config;
    }

    public jobSubscribers: Subject<IJobServer> = new Subject();

    public unlockJob(idJob: IdJob) {
        const config = this.mapWaitJobPromises.get(idJob);
        if (!config) return;
        this.jobSubscribers.next(config.job);
        config?.resolve();
        this.mapWaitJobPromises.delete(idJob);
        this.updateWaitingNS(config.idNSTarget);
    }

    private addJobToTarget(idNS: IdDep<ENonSerializableObjectType> | undefined, idJob: IdJob) {
        if (!idNS) return;
        if (!this.waitingNSs.has(idNS)) this.waitingNSs.set(idNS, new Set());
        this.waitingNSs.get(idNS)!.add(idJob);
        this.updateWaitingNS(idNS);
    }

    getPercentProcessing(idNS: IdDep) {
        const jobs = [...(this.waitingNSs.get(idNS) ?? [])];
        let amount = 0;
        let total = 0;
        if (!jobs.length) return `100%`;
        for (const idJob of jobs) {
            const config = this.mapWaitJobPromises.get(idJob);
            if (!config?.job) total++;
            else total += !config.job.isJobStillInProgress ? 1 : config.job.percentComplete ?? 0;
            amount++;
        }
        const percentageNumber = total / amount;
        const percentage = !percentageNumber ? 0 : PercentageCalculator.toPercentage(percentageNumber);
        return `${percentage}%`;
    }

    getWaitingNS(idNS: IdDep<ENonSerializableObjectType>) {
        const nss = [...this.waitingNSs.get(idNS) ?? []].map(id => this.mapWaitJobPromises.get(id)?.job).filter(isValidRef);
        return nss;
    }

    private updateWaitingNS(idNS: IdDep<ENonSerializableObjectType> | undefined) {
        try {
            if (!idNS) return;
            const set = this.waitingNSs.get(idNS);

            if (!set) return;

            for (const idJob of [...set]) {
                const idNSTarget = this.getIdNSJobTarget(idJob);
                if (idNSTarget === idNS) continue;
                set?.delete(idJob);
            }
        } finally {
            console.log('waitingNSs', this.waitingNSs);
        }
    }

    waitingNSs: DeepMap<[idNS: IdDep<ENonSerializableObjectType>, jobs: Set<IdJob>]> = new Map();

    handleMessageNotification(
        notificationFromSocket:
            | INotificationJobData
            | INotificationCustomerExpireWarn
            | INotificationCustomerExpireClose
            | INotificationAdminSolicitationApprovalMessage
            | INotificationFormSolicitationMessage
            | INotificationCRMTicket
    ) {
        console.log({ notificationFromSocket });

        if (notificationFromSocket.type === ENotificationPayload.job) {
            const input = notificationFromSocket as TJobNotificationData;
            switch (input.jobPayloadType) {
                case EJobPayloadType.Update: {
                    const config = this.mapWaitJobPromises.get(input.idJob)
                    if (!config) return;
                    config.job = input.job;
                    this.jobSubscribers.next(input.job);
                    return;
                }
                case EJobPayloadType.UpdatePercentage: {
                    const config = this.mapWaitJobPromises.get(input.idJob)
                    if (config?.job) config.job.percentComplete = input.percentComplete;
                    return;
                }
                case EJobPayloadType.Finish: return this.unlockJob(input.idJob);
                case EJobPayloadType.Start: {
                    this.waitJob({
                        idJob: input.idJob!,
                        retry: {
                            shouldRetry: false,
                        }
                    });
                    return;
                }
            }
        }

        const notificationStoredInMemory: INotificationPayloadClient =
            this.notificationMap[notificationFromSocket.idNotification] &&
            this.notificationMap[notificationFromSocket.idNotification][
            this.notificationMap[notificationFromSocket.idNotification]
                .length - 1
            ];

        // se nao tiver em memoria, mostrar mensagem
        if (!notificationStoredInMemory) {
            const newNotification: INotificationPayloadClient =
                this.upsertNotificationInMemory(notificationFromSocket, {
                    alreadyClosedByUser: false,
                    photo: "",
                    icon: "",
                    linkToFollow: "",
                    counter: 0,
                    viewType: ENotificationPayloadClientType.open,
                });
            this.newNotificationsToShowOnScreen$.next(newNotification);
            this.incrementCounter(newNotification);
            return;
        }

        this.incrementCounter(notificationStoredInMemory);

        // backend quer forcar abrir como nova mensagem
        if (notificationFromSocket.forceAsNew) {
            const newNotification: INotificationPayloadClient =
                this.upsertNotificationInMemory(
                    notificationFromSocket,
                    notificationStoredInMemory
                );
            this.newNotificationsToShowOnScreen$.next(newNotification);
            return;
        }

        // mensagem ja existe, incrementando counter
        const notificationUpdated = this.upsertNotificationInMemory(
            notificationFromSocket,
            {
                ...notificationStoredInMemory,
                viewType: notificationStoredInMemory.alreadyClosedByUser
                    ? notificationStoredInMemory.viewType
                    : ENotificationPayloadClientType.replace,
                counter: notificationStoredInMemory.counter + 1,
            }
        );

        // se nao for fechado pelo usuario replace na mensagem
        if (!notificationStoredInMemory.alreadyClosedByUser) {
            this.newNotificationsToShowOnScreen$.next(notificationUpdated);
            return;
        }
    }

    private upsertNotificationInMemory(
        notificationFromSocket: IBasicNotificationPayload,
        notificationStoredInMemory: INotificationPayloadClientData
    ): INotificationPayloadClient {
        const newNotificationStoredInMemory: INotificationPayloadClient = {
            ...notificationStoredInMemory,
            ...notificationFromSocket,
        };

        if (!this.notificationMap[notificationFromSocket.idNotification]) {
            this.notificationMap[notificationFromSocket.idNotification] = [];
        }

        this.notificationMap[notificationFromSocket.idNotification].push(
            newNotificationStoredInMemory
        );
        return newNotificationStoredInMemory;
    }
}
