import { isInvalid, isValidRef, isValidString, typedClone } from "../../../../tools/utility";
import { IBotActionAsset, IBotActionAssetContainer, initialAction, TIBotActionAssetContainerArray } from "../../../bot/bot-action-model";
import { TKAssetTypeArray } from "../../../bot/bot-asset-model";
import { ENextGenBotElementType, getInitialActionWithId, IBotMenuContainerServer, IBotMenuItemServer, IBotRootServer } from "../../../bot/bot-model";
import { EBotActionType } from "../../../bot/new-bot-action";
import { EBPMType } from "../../../BPM/bpm-model";
import { GraphElement } from "../../../graph/essential/graph-element";
import { GraphRulesProcessor } from "../../../graph/essential/graph-rules-processor";
import { IGraphConnectionData, TIGraphConnectionDataArray } from "../../../graph/essential/graph-types";
import { IHostObjectInterface } from "../../../graph/essential/host-object";
import { GraphPredicate } from "../../../graph/essential/predicate";
import { ENonSerializableObjectType, INonSerializable } from "../../../non-serializable-id/non-serializable-id-interfaces";
import { BPMConfigToolbarSelector } from "../../toolbar/bpm-config-toolbar-selector";
import { IBasicToolbarElement, TBPMBOTConnectionTypes, TBPMBOTElementTypes, TReferencedObject } from "../../toolbar/config-toolbar.types";
import { NSGenericHost } from "../ns/ns.host";
import { IAddedConnection } from "../ns/ns.host.types";
import { EHandleHostUpdateAction, IBotRootFactoryInfo, THandleHostUpdateActions } from "./bot-host.types";
import { BotHostNode } from "./bot.host";

export class BotRootHostNode extends BotHostNode {

    constructor(root: IBotRootServer) {
        super(root);
    }

    public onDragged(host: IHostObjectInterface) {
        // im from
        // -> transaction
        // -> menu
    }

    public onDragIntoMe(): void {
    }

    public hasValidAssets(): boolean {
        return;
    }

    public handleHostUpdate(graphElement: GraphElement, beforeNSData?: INonSerializable): THandleHostUpdateActions {
        const rulesProcessor: GraphRulesProcessor = graphElement.getRuleProcessor();
        const beforeAction: IBotActionAsset = (beforeNSData as IBotRootServer)?.firstAction;
        console.log({ beforeNSData });

        const checkConnectionDelete: boolean = beforeAction?.type === EBotActionType.goActionTree;

        if (checkConnectionDelete) {
            const connectionToMenuContainer = graphElement.getConnectionsToOthers().find(predicate => {
                const targetElement: GraphElement = <GraphElement><unknown>rulesProcessor.getElementById(predicate.getToElementId());
                const targetNS: IBotMenuContainerServer = targetElement.getHostObject().getNonSerializable() as IBotMenuContainerServer;

                return targetNS.idNS === beforeAction?.idElement;
            });

            const isNotConnectedToMenuContainer = isInvalid(connectionToMenuContainer);

            if (isNotConnectedToMenuContainer) return;

            //
            rulesProcessor.removePredicate(connectionToMenuContainer);
            // connectionToMenuContainer.emit(ECommonEvents.remove, undefined);
            return {
                [EHandleHostUpdateAction.delete]: [connectionToMenuContainer],
            }
        }
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        const noOtherConnectionsFromMe = edgesToOthersCount == 0
        const toolbarElement: IBasicToolbarElement<TReferencedObject> = BPMConfigToolbarSelector.getOneToolbarElement(EBPMType.bot, this.getElementType())
        const toolbarDragElement: IBasicToolbarElement<TReferencedObject> = BPMConfigToolbarSelector.getOneToolbarElement(EBPMType.bot, nodeTypeToDrag)
        const isConnectable = isValidRef(toolbarElement.canConnectTo[nodeTypeToDrag])
        const canDragOn = toolbarDragElement.canDragOn?.includes(this.getElementType());

        return canDragOn || (noOtherConnectionsFromMe && isConnectable);
    }

    public static factory(info: IBotRootFactoryInfo): BotRootHostNode {
        return new BotRootHostNode(info.ns as IBotRootServer);
    }

    public getHostedType(): TReferencedObject {
        return ENextGenBotElementType.root;
    }

    public isEditable(graphElement: GraphElement) {
        return graphElement.isRoot();
    }

    public handleRemoveSubTree(graphElement: GraphElement): INonSerializable[] {
        const rulesProcessor = graphElement.getRuleProcessor();
        const connectionsFromOthers: GraphPredicate[] = graphElement.getConnectionsFromOthers();
        const alteredNSs: INonSerializable[] = [];

        connectionsFromOthers.forEach(predicate => {
            const element: GraphElement = <GraphElement><unknown>rulesProcessor.getElementById(predicate.getFromElementId());
            if (element.getHostedType() !== ENextGenBotElementType.botMenuItem) { return; }

            const elementNS: IBotMenuItemServer = element.getHostObject().getNonSerializable() as IBotMenuItemServer;
            if (elementNS.action.idElement !== graphElement.getHostedID()) { return; }

            elementNS.action = typedClone(initialAction);
            alteredNSs.push(elementNS);
            element.changeDetection();
        });

        return alteredNSs;
    }

    public isActionPredicateDrawable(targetType: TBPMBOTElementTypes, action: EBotActionType): boolean {
        switch (targetType) {
            case ENextGenBotElementType.menuContainer:
                return action === EBotActionType.goActionTree;
            case ENonSerializableObjectType.contentGenerator:
                return action === EBotActionType.contentGenerator;
            case ENextGenBotElementType.root:
                return action === EBotActionType.goBot;
            default:
                return false;
        }
    }

    public isParenthoodPredicateDrawable(_: TReferencedObject): boolean {
        return false
    }

    public getActionContainer(): TIBotActionAssetContainerArray {
        const asset = (super.getNonSerializable() as IBotRootServer).firstAction
        return [{
            action: asset
        }]
    }

    public getPredicateTypesFromState(): TKAssetTypeArray {
        return isValidRef(this.getNser().firstAction)
            ? [this.getNser().firstAction.type]
            : []
    }

    private getNser(): IBotRootServer {
        return super.getNonSerializable() as IBotRootServer
    }

    public getActionAvailableByConnectingManuallyToOtherNode(): EBotActionType {
        return EBotActionType.goActionTree
    }

    public isActionAvailableByConnectingManuallyToOtherNode(): boolean {
        return true
    }

    public setAction(actionAsset: IBotActionAsset): void {
        const ns = super.getNonSerializable() as IBotRootServer

        ns.firstAction = actionAsset

        if (isValidRef(ns.posAction) && ns.firstAction.type === EBotActionType.goActionTree) {
            ns.posAction = undefined;
        }
    }

    public mustUpdateConnections(connectionType: TReferencedObject): boolean {
        switch (connectionType) {
            case EBotActionType.goActionTree:
            case EBotActionType.contentGenerator:
            case undefined:
                return true;
            default: return false;
        };
    }

    public getConnectionTargetData(): TIGraphConnectionDataArray {
        const action = this.getActionContainer()[0].action ?? undefined;
        return [{
            connectionType: action?.type,
            targetHostId: action?.idElement,
            subElementId: undefined
        }]
    }

    public addConnectionTo(
        target: NSGenericHost,
        connectionType: TReferencedObject,
    ): IAddedConnection {
        const currentAction = this.getNser().firstAction;
        const action: IBotActionAsset = {
            ...(isValidRef(currentAction) ? currentAction : getInitialActionWithId()),
            type: <EBotActionType>connectionType,
            idElement: target.getHostedID(),
        };

        this.setAction(action);

        return {
            isConnectionAdded: true
        }
    }

    thereIsMoreChildrenNodesToCreate(): boolean {
        const con = this.getConnectionTargetData()[0];
        return con.connectionType == EBotActionType.contentGenerator && isValidString(con.targetHostId)
    }

    isNeighborDrawable(neighborType: TBPMBOTElementTypes): boolean {
        return neighborType == ENonSerializableObjectType.contentGenerator
    }

    getEdgeTypeToConnect(neighborType: TBPMBOTElementTypes): TBPMBOTConnectionTypes {
        const connectionMap = { [ENonSerializableObjectType.contentGenerator]: EBotActionType.contentGenerator }
        return connectionMap[neighborType]
    }
}
