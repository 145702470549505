import { EFunctionContext, TAllUserFunctionRuntimeFunctions, TAllUserFunctionRuntimeFunctionsIntersection } from "@colmeia/core/src/shared-business-rules/user-function/user-function-model";
import { values } from "@colmeia/core/src/tools/utility";
import { defineConfig, IsMatch, MapTo, ReMapObject } from "@colmeia/core/src/tools/utility-types";
import { Merger } from "@colmeia/core/src/tools/utility/types/entities/merger";

export enum EUFRuntimeFunctionType {
    Data = "data",
    Channel = "channel",
    Communication = "communication",
    Validation = "validation",
    UserInteraction = "userInteraction",
    Multimedia = "Multimedia",
    Utils = "utils",
    ShakeHands = "shake-hands",
    Flow = "flow",
}



export interface TUFRuntimeMethodConfig<T extends TAllUserFunctionRuntimeFunctions = TAllUserFunctionRuntimeFunctions> {
    value: T,
    title: string,
    matIcon: string,
    auxIcon?: {
        matIcon?: string,
        url?: string,
    }
    desc: string,
    isAsync?: boolean;
    argsGuard?: string[];
    docSuffix: string,
    type: EUFRuntimeFunctionType
    contexts?: EFunctionContext[],
};

export type TRuntimeFunctionConfigDB = Partial<TRuntimeFunctionsClientDB>;
export type TRuntimeFunctionsClientDB = {
    [key in keyof TAllUserFunctionRuntimeFunctionsIntersection]: TUFRuntimeMethodConfig
};
type TRuntimeFunctionsClientDBTypeValidation = {
    [key in keyof TAllUserFunctionRuntimeFunctionsIntersection]: TUFRuntimeMethodConfigTypeValidationItem<key>
}




export const runtimeFunctionsClientDB = defineConfig<TRuntimeFunctionsClientDBTypeValidation, TRuntimeFunctionsClientDB>({
    // getGenerativeProcessor: {
    //     value: "getGenerativeProcessor",
    //     title: "Obtém controlador de IA generativa",
    //     desc: "",
    //     matIcon: "psychology",
    //     isAsync: false,
    //     docSuffix: "",
    //     type: EUFRuntimeFunctionType.Data,
    //     contexts: [EFunctionContext.AI],
    // },
    getOCRDataContent: {
        value: `getOCRDataContent`,
        title: "retorna o resultado da API de OCR executado previamente",
        matIcon: "ocr",
        desc: "",
        docSuffix: "getOCRDataContent(): string[];",
        type: EUFRuntimeFunctionType.Multimedia,
        contexts: [EFunctionContext.BOTContext],
    },
    setOCRResult: {
        value: "setOCRResult",
        title: "armazena o resultado do OCR custom extracted no user-function no runtime",
        matIcon: "ocr",
        desc: "",
        docSuffix: "getOCRDataContent(): string[];",
        type: EUFRuntimeFunctionType.Multimedia,
        contexts: [EFunctionContext.BOTContext],
    },
    getBusinessRuntime: {
        value: `getBusinessRuntime`,
        title: "Obter nova sequencia numerica para um sequenceName",
        matIcon: "spellcheck",
        desc: "",
        argsGuard: ['offsetZone'],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "",
        contexts: [EFunctionContext.BOTContext, EFunctionContext.ClientContext],
    },
    getSourceAddress: {
        value: `getSourceAddress`,
        title: "Obtém endereço de origem da campanha",
        matIcon: "spellcheck",
        desc: "",
        argsGuard: [],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "setApiCallPolicy(apiCallPolicy: UserFunctionApiCallPolicy): void;",
        contexts: [EFunctionContext.MKTContext],
    },
    getTargetAddress: {
        value: `getTargetAddress`,
        title: "Obtém endereço alvo da campanha",
        matIcon: "spellcheck",
        desc: "",
        argsGuard: [],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "setApiCallPolicy(apiCallPolicy: UserFunctionApiCallPolicy): void;",
        contexts: [EFunctionContext.MKTContext],
    },
    getCampaignAction: {
        value: `getCampaignAction`,
        title: "Obter ação de campanha pelo id",
        matIcon: "spellcheck",
        desc: "",
        argsGuard: ['idCampaignAction'],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "setApiCallPolicy(apiCallPolicy: UserFunctionApiCallPolicy): void;",
        contexts: [EFunctionContext.MKTContext],
    },
    setApiCallPolicy: {
        value: `setApiCallPolicy`,
        title: "Definir configurações da API",
        matIcon: "spellcheck",
        desc: "",
        argsGuard: ['apiCallPolicy'],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "setApiCallPolicy(apiCallPolicy: UserFunctionApiCallPolicy): void;",
        contexts: values(EFunctionContext),
    },
    getContextAI: {
        value: `getContextAI`,
        title: "Obter funções de IA",
        matIcon: "spellcheck",
        desc: "",
        argsGuard: [],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "getContextAI(): IUserFunctionAIContext;",
        contexts: [EFunctionContext.AI],
    },
    safeGetFieldValueByCanonicalNormalized: {
        value: `safeGetFieldValueByCanonicalNormalized`,
        title: "Obtém valor do campo pelo canônico normalizado(seguro)",
        matIcon: "spellcheck",
        desc: "Idêntico ao getFieldValueByCanonicalNormalized, mas considera que o valor de retorno pode ser vazio",
        argsGuard: ["idCanonical"],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "(idCanonical: string): string | undefined;"
    },
    //
    safeGetFieldValueByCanonical: {
        value: "safeGetFieldValueByCanonical",
        title: "Obtém valor do campo pelo canônico(seguro)",
        matIcon: "spellcheck",
        desc: "Idêntico ao getFieldValueByCanonical, mas considera que o valor de retorno pode ser vazio",
        argsGuard: ['idCanonical'],
        docSuffix: "(idCanonical: string): string | undefined;",
        type: EUFRuntimeFunctionType.Data,
        contexts: values(EFunctionContext)
    },
    getMessageEngine: {
        value: "getMessageEngine",
        title: "Obter engine de envio de mensagens",
        matIcon: "reviews",
        desc: "",
        argsGuard: [],
        docSuffix: "(): IUserFunctionMessageEngine",
        type: EUFRuntimeFunctionType.Data,
        contexts: values(EFunctionContext)
    },
    sendMessageToUser: {
        value: "sendMessageToUser",
        title: "Envia uma mensagem na hora",
        matIcon: "send",
        desc: "",
        argsGuard: ['idUserFunction'],
        docSuffix: "(message: string): Promise<void>",
        type: EUFRuntimeFunctionType.Data,
        isAsync: true,
        contexts: values(EFunctionContext)
    },
    isProduction: {
        value: 'isProduction',
        desc: '',
        type: EUFRuntimeFunctionType.Data,
        title: "Verifica se o ambiente é o de produção.",
        docSuffix: '(): boolean',
        matIcon: 'engineering'
    },
    getNewFunctionExecutor: {
        value: "getNewFunctionExecutor",
        title: "Executar função",
        matIcon: "code",
        desc: "",
        argsGuard: ['idUserFunction', 'name'],
        docSuffix: "getNewFunctionExecutor<Out>(idUserFunction: IdDep<ENonSerializableObjectType.userFunction>, name?: string): ICoreUserFunctionsExecutor<Out>",
        type: EUFRuntimeFunctionType.Data,
        contexts: values(EFunctionContext)
    },
    getMultimediaMetadata: {
        value: "getMultimediaMetadata",
        title: "retorna metadados da multimedia",
        matIcon: "multimedia",
        desc: "",
        isAsync: true,
        argsGuard: ['expireTimeInMilisseconds'],
        docSuffix: "(): string;",
        type: EUFRuntimeFunctionType.Multimedia,
        contexts: [EFunctionContext.BOTContext, EFunctionContext.ClientContext, EFunctionContext.MKTContext, EFunctionContext.ShakingHands]
    },
    getMultimediaSignedUrl: {
        value: "getMultimediaSignedUrl",
        title: "Obtém o URL para acessar multimedia",
        matIcon: "multimedia",
        desc: "",
        argsGuard: ['idMedia'],
        isAsync: true,
        docSuffix: "(idMedia: string): Promise<string>;",
        type: EUFRuntimeFunctionType.Multimedia,
        contexts: [EFunctionContext.BOTContext, EFunctionContext.ClientContext, EFunctionContext.MKTContext, EFunctionContext.ShakingHands]
    },
    getFieldAsAddress: {
        value: "getFieldAsAddress",
        title: "Obtém estrutura de dados contendo o CEP",
        matIcon: "edit_road",
        desc: "",
        argsGuard: ['idMetadata', 'idProperty'],
        docSuffix: "(idMetadata: string, idProperty: string): IAddressOnField;",
        type: EUFRuntimeFunctionType.UserInteraction,
        contexts: [EFunctionContext.BOTContext]
    },
    getFirstCustomerMessage: {
        value: "getFirstCustomerMessage",
        title: "Obtém a primeira mensagem enviada para o cliente",
        matIcon: "question_answer",
        desc: "",
        argsGuard: [],
        docSuffix: "(): string;",
        type: EUFRuntimeFunctionType.UserInteraction,
        contexts: [EFunctionContext.BOTContext, EFunctionContext.ClientContext, EFunctionContext.MKTContext, EFunctionContext.ShakingHands]
    },
    changeAccountOwner: {
        value: "changeAccountOwner",
        title: "modifica o account owner para um outro avatar",
        matIcon: "supervisor_account",
        desc: "",
        isAsync: true,
        argsGuard: ['idAvatarAgent'],
        docSuffix: "(idAvatarAgent: string): void;",
        type: EUFRuntimeFunctionType.Validation
    },
    toString: {
        value: "toString",
        title: "Transforma objeto em string",
        matIcon: "format_quote",
        desc: "",
        argsGuard: ['obj'],
        docSuffix: "(obj: object): string;",
        type: EUFRuntimeFunctionType.Utils
    },
    createCorporateSearch: {
        value: "createCorporateSearch",
        title: "Instancia uma Busca corporativa",
        matIcon: "manage_search",
        desc: "",
        docSuffix: "(idCorporateSearch: string): IUserFunctionCorporateSearchRuntime",
        type: EUFRuntimeFunctionType.Data
    },
    createDatabase: {
        value: "createDatabase",
        title: "Instancia um geranciador de banco de dados",
        matIcon: "data_object",
        desc: "",
        docSuffix: "(database: string): IUserFunctionCustomerDatabase",
        type: EUFRuntimeFunctionType.Data
    },
    upsertCustomerDatabase: {
        value: "upsertCustomerDatabase",
        title: "armazena dados do cliente",
        isAsync: true,
        matIcon: "assignment_ind",
        desc: "O banco de dados fica atrelado ao cliente",
        docSuffix: "(object: Object): Promise<void>;",
        type: EUFRuntimeFunctionType.Data
    },
    getTrackerProcessor: {
        value: "getTrackerProcessor",
        title: "Obtém informações de rastreio",
        matIcon: "manage_search",
        desc: "",
        docSuffix: "(): IUserFunctionTrackerProcessor;",
        type: EUFRuntimeFunctionType.Data
    },
    getUserFunctionValidator: {
        value: 'getUserFunctionValidator',
        title: "Obtém funções de validação",
        matIcon: "manage_search",
        desc: "",
        docSuffix: "(): IUserFunctionValidatorRuntime",
        type: EUFRuntimeFunctionType.Utils
    },
    getFromCustomerDatabase: {
        value: "getFromCustomerDatabase",
        title: "retorna os dados armazenados do cliente",
        isAsync: true,
        matIcon: "person_search",
        desc: "",
        docSuffix: "(): string;",
        type: EUFRuntimeFunctionType.Data
    },
    getAttendentEmail: {
        value: "getAttendentEmail",
        title: "Obtém o email de atendente",
        matIcon: "alternate_email",
        desc: "",
        argsGuard: [],
        docSuffix: "(): string;",
        type: EUFRuntimeFunctionType.Utils
    },
    getIDConversation: {
        value: "getIDConversation",
        title: "Obtém o id único da conversa",
        matIcon: "fingerprint",
        desc: "",
        argsGuard: [],
        docSuffix: "(): string;",
        type: EUFRuntimeFunctionType.Channel
    },
    getConversationHistory: {
        value: "getConversationHistory",
        title: "Obtém o histórico de conversa",
        isAsync: true,
        matIcon: "history",
        desc: "",
        argsGuard: ['idInteractionConversation'],
        docSuffix: "(): string;",
        type: EUFRuntimeFunctionType.Data
    },
    getConversationHistoryWithBot: {
        value: "getConversationHistoryWithBot",
        title: "Obtém o histórico de conversa com o bot",
        matIcon: "history",
        desc: "",
        isAsync: true,
        argsGuard: ['idInteractionConversation'],
        docSuffix: "(): ISimpleBotConversation;",
        type: EUFRuntimeFunctionType.Data
    },
    createDate: {
        value: "createDate",
        title: "Instancia um gerenciamento de data",
        matIcon: "manage_history",
        desc: "",
        argsGuard: ['date', 'format'],
        docSuffix: "(date: string, format?: EValidFormatFinal)): IUserFunctionDateInjection;",
        type: EUFRuntimeFunctionType.Utils
    },
    getOnlyDigitsFrom: {
        value: "getOnlyDigitsFrom",
        title: "Obtém apenas digitos de uma string",
        matIcon: "123",
        desc: "",
        argsGuard: ['value'],
        docSuffix: "(value: string): string;",
        type: EUFRuntimeFunctionType.Utils
    },
    createVirtualMenu: {
        value: "createVirtualMenu",
        title: "Cria um menu virtual",
        matIcon: "list",
        desc: "",
        argsGuard: ['idCorporateSearch'],
        docSuffix: "(idCorporateSearch: string): IUserFunctionVirtualMenuParams;",
        type: EUFRuntimeFunctionType.Data
    },
    getVirtualMenuRepository: {
        value: "getVirtualMenuRepository",
        title: "Obtém Menu Virtual",
        matIcon: "view_list",
        desc: "",
        argsGuard: [],
        docSuffix: "(): TVirtualMenuRepository;",
        type: EUFRuntimeFunctionType.Data
    },
    saveVirtualMenu: {
        value: "saveVirtualMenu",
        title: "Salva o Menu Virtual no runtime da função",
        matIcon: "fact_check",
        desc: "",
        docSuffix: "(virtualMenu: UserFunctionVirtualMenuParams): void;",
        type: EUFRuntimeFunctionType.Data
    },
    addToVirtualDW: {
        value: "addToVirtualDW",
        title: "Salva dados em uma tabela virtual no Data Warehouse",
        matIcon: "storage",
        desc: "",
        docSuffix: "addToVirtualDW(data: IFunctionCustomerDW): void;",
        type: EUFRuntimeFunctionType.Data
    },
    getCustomerMetrics: {
        value: "getCustomerMetrics",
        title: "Obtém metricas do cliente",
        matIcon: "leaderboard",
        desc: "",
        docSuffix: "(metric: ECustomerVisitMetrics): number;",
        type: EUFRuntimeFunctionType.Data
    },
    /**
     * Canal
     */
    isWhatsapp: {
        value: "isWhatsapp",
        title: "Retorna se canal é whatsapp",
        matIcon: "whatsapp",
        desc: "",
        argsGuard: [],
        docSuffix: "(): boolean;",
        type: EUFRuntimeFunctionType.Channel
    },
    getChannelAddress: {
        value: "getChannelAddress",
        title: "Retorna o endereço do canal",
        matIcon: "call",
        desc: "Retorna o endereco do canal, ex: (5511...)",
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Channel
    },
    getClientAddress: {
        value: `getClientAddress`,
        title: "Obter o endereço do cliente",
        matIcon: "contact_phone",
        desc: "Retorna o endereço do cliente relativo ao canal",
        argsGuard: [],
        docSuffix: "(): string;",
        type: EUFRuntimeFunctionType.Channel
    },

    getReferral: {
        value: 'getReferral',
        title: "Retorna o código da campanha que iniciou a conversação",
        matIcon: "fingerprint",
        desc: "Disponível em campanhas do Facebook e Instagram",
        isAsync: false,
        argsGuard: [],
        docSuffix: "(): string;",
        type: EUFRuntimeFunctionType.Channel,
        contexts: []
    },
    /**
     * Comunicação
     */
    createRequest: {
        value: "createRequest",
        title: "Criacao de request",
        matIcon: "api",
        desc: "Cria request HTTP",
        argsGuard: ["idRoute"],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Communication
    },
    /**
     * Validação
     */
    isValidEmail: {
        value: "isValidEmail",
        title: "Validação de email",
        matIcon: "alternate_email",
        desc: "Verifica se o Email está nos padrões internacionais",
        argsGuard: ["email"],
        docSuffix: "(email: string): boolean;",
        type: EUFRuntimeFunctionType.Validation
    },
    isValidCPF: {
        value: "isValidCPF",
        title: "Validação de documento",
        matIcon: "assignment_ind",
        desc: "Verifica se o CPF é válido ignorando caracteres não numéricos",
        argsGuard: ["cpf"],
        docSuffix: "(cpf: string): boolean;",
        type: EUFRuntimeFunctionType.Validation
    },
    isValidCNPJ: {
        value: "isValidCNPJ",
        title: "Validação de CNPJ",
        matIcon: "assignment",
        desc: "Verifica se o CNPJ é válido ignorando caracteres não numéricos",
        argsGuard: ["cnpj"],
        docSuffix: "(cnpj: string): boolean;",
        type: EUFRuntimeFunctionType.Validation
    },
    isValidCEP: {
        value: "isValidCEP",
        title: "Validação de CEP",
        matIcon: "edit_road",
        desc: "Valida se 99999-999 ou 99999999",
        argsGuard: ["cep"],
        docSuffix: "(cep: string): boolean;",
        type: EUFRuntimeFunctionType.Validation
    },
    getCepDigityOnly: {
        value: "getCepDigityOnly",
        title: "Remover pontuação do CEP",
        matIcon: "edit_road",
        desc: "99999-999 para 99999999",
        argsGuard: ["cep"],
        docSuffix: "(cep: string): string;",
        type: EUFRuntimeFunctionType.Utils
    },
    /**
     * Obtenção de dados
     */
    getCurrentFormValue: {
        value: 'getCurrentFormValue',
        title: "Retorna valor do campo atual preenchido pelo usuario",
        matIcon: "input",
        desc: "",
        argsGuard: [],
        docSuffix: "(): string;",
        type: EUFRuntimeFunctionType.Data
    },
    logData: {
        value: 'logData',
        title: "Log",
        matIcon: "assignment",
        desc: "",
        isAsync: true,
        argsGuard: ["type", "description", 'data'],
        docSuffix: "(type: string, description: string, data: object): Promise<void>;",
        type: EUFRuntimeFunctionType.Data
    },
    getResults: {
        value: `getResults`,
        title: "Obtém resultado da função",
        matIcon: "format_list_bulleted",
        desc: "",
        argsGuard: [],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: `(): TVLReturn;

interface TVLReturn {
        isValid?: boolean;
        errorDescription?: string;
        compilationErrors?: string;
        returnValue?: string;
        rtf?: IFileField;
        userMessage?:string;
}`
    },
    getExecutionState: {
        value: `getExecutionState`,
        title: "Obtém o estado da execução",
        matIcon: "description",
        desc: "",
        argsGuard: [],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: `(): TComputedInfo;

interface TComputedInfo {
        [idNS: string]: string
}
        `
    },
    getFieldValueByCanonical: {
        value: `getFieldValueByCanonical`,
        title: "Obtém valor do campo pelo canônico",
        matIcon: "spellcheck",
        desc: "",
        argsGuard: [""],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "(idCanonical: string): string;"
    },
    getFieldValueByProperty: {
        value: `getFieldValueByProperty`,
        title: "Obtém o valor do campo pelo idProperty do metadado",
        matIcon: "list_alt",
        desc: "",
        argsGuard: [""],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "(idProperty: string): string;"
    },
    getCodeOrValueOfProperty: {
        value: `getCodeOrValueOfProperty`,
        title: "Obtém o valor do código de retorno do campo pelo idProperty do metadado",
        matIcon: "list_alt",
        desc: "",
        argsGuard: [""],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "(idProperty: string): string;"
    },
    getCodeOrValueOfPropertyNormalized: {
        value: `getCodeOrValueOfPropertyNormalized`,
        title: "Obtém o valor do código de retorno do campo pelo idProperty do metadado(normalizado)",
        matIcon: "list_alt",
        desc: "",
        argsGuard: [""],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "(idProperty: string): string;"
    },
    getCurrentFormValueNormalized: {
        value: `getCurrentFormValueNormalized`,
        title: "Obtém um valor normalizado",
        matIcon: "format_shapes",
        desc: "",
        argsGuard: [],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "(): string;"
    },
    getFieldValueByCanonicalNormalized: {
        value: `getFieldValueByCanonicalNormalized`,
        title: "Obtém valor do campo pelo canônico normalizado",
        matIcon: "spellcheck",
        desc: "",
        argsGuard: [""],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "(idCanonical: string): string;"
    },
    getFieldValueByPropertyNormalized: {
        value: `getFieldValueByPropertyNormalized`,
        title: "Obtém o valor do campo pelo idProperty do metadado normalizado",
        matIcon: "list_alt",
        desc: "",
        argsGuard: [""],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "(idProperty: string): string;"
    },
    setFieldByProperty: {
        value: `setFieldByProperty`,
        title: "Altera o valor do campo pelo idProperty",
        matIcon: "list_alt",
        desc: "",
        argsGuard: ["idProperty", "value"],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "(idProperty: string, value: string): void;"
    },
    initDynamicDBHandle: {
        value: `initDynamicDBHandle`,
        title: "Faz um upsert ou leitura de linha em um banco de dados",
        matIcon: "drive_file_rename_outline",
        desc: "",
        argsGuard: ["idDatabase"],
        type: EUFRuntimeFunctionType.Data,
        docSuffix: "(idDatabase: string): Promise<IUserFunctionDynamicList>;",
        isAsync: true,
    },
    /**
     * Interação com usuário
     */
    addMessageToUser: {
        value: `addMessageToUser`,
        title: "Adiciona mensagem para o usuário",
        matIcon: "3p",
        desc: "",
        argsGuard: ["userMessage"],
        docSuffix: "(userMessage: string): void;",
        type: EUFRuntimeFunctionType.UserInteraction
    },

    addTypedMessage: {
        value: `addTypedMessage`,
        title: "Adiciona mensagem para o usuário",
        matIcon: "3p",
        desc: "",
        argsGuard: ['type', 'message'],
        docSuffix: "(type: ${'`${EUserMessageType}`'}, message: string): void;",
        type: EUFRuntimeFunctionType.UserInteraction
    },

    setErrorMessage: {
        value: `setErrorMessage`,
        title: "Adiciona mensagem de erro",
        matIcon: "sms_failed",
        desc: "",
        argsGuard: ["errorMessage"],
        docSuffix: "(errorMessage: string): void;",
        type: EUFRuntimeFunctionType.UserInteraction
    },
    setRealTimeFusion: {
        value: `setRealTimeFusion`,
        title: "Define o valor do RTF",
        matIcon: "merge_type",
        isAsync: true,
        desc: "",
        argsGuard: ['object'],
        docSuffix: "(object: Object);",
        type: EUFRuntimeFunctionType.UserInteraction
    },
    addToRealTimeFusion: {
        value: `addToRealTimeFusion`,
        title: "Adiciona valor ao objeto RTF",
        matIcon: "merge_type",
        desc: "",
        argsGuard: ["key", "value"],
        docSuffix: "(key: string, value: string): void;",
        type: EUFRuntimeFunctionType.UserInteraction
    },
    removeFromRealTimeFusion: {
        value: "removeFromRealTimeFusion",
        title: "Remove um item do RTF",
        matIcon: "delete",
        desc: "",
        argsGuard: ['key'],
        docSuffix: "(key: string): void;",
        type: EUFRuntimeFunctionType.UserInteraction,
    },
    setValidReturn: {
        value: `setValidReturn`,
        title: "setValidReturn",
        matIcon: "file_download",
        desc: "",
        argsGuard: [],
        docSuffix: "(): void;",
        type: EUFRuntimeFunctionType.UserInteraction
    },
    setInvalidReturn: {
        value: `setInvalidReturn`,
        title: "setInvalidReturn",
        matIcon: "file_download_off",
        desc: "",
        argsGuard: [],
        docSuffix: "(): void;",
        type: EUFRuntimeFunctionType.UserInteraction
    },
    generateNormalizedPWD: {
        value: `generateNormalizedPWD`,
        title: "Gerador de senha",
        matIcon: "password",
        desc: "",
        argsGuard: ['elements', 'validSpecials'],
        docSuffix: "(elements: TArrayIElementPWD, validSpecials: string = specialCharConst): string;",
        type: EUFRuntimeFunctionType.Utils
    },

    safeStringifyJSON: {
        value: "safeStringifyJSON",
        title: "Converte JSON para string(seguro)",
        matIcon: "data_object",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "Alternativa segura ao JSON.stringify(remove referências circulares)",
        isAsync: false,
        argsGuard: ['obj'],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },

    delay: {
        value: "delay",
        title: "setTimeout assíncrono",
        matIcon: "schedule",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: true,
        argsGuard: ['miliseconds'],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },

    safeParseJSON: {
        value: "safeParseJSON",
        title: "Parse JSON seguro",
        matIcon: "data_object",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "Retorna objeto vazio '{}' caso seja um JSON inválido",
        isAsync: false as true,
        argsGuard: ['jsonString'],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },

    isThisOneOfThat: {
        value: "isThisOneOfThat",
        title: "Compara valores",
        matIcon: "data_object",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "Compara se primeiro argumento é igual a algum dos próximos",
        isAsync: false,
        argsGuard: ['jsonString'],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    getNormalizedValue: {
        value: "getNormalizedValue",
        title: "Sanitiza valor",
        matIcon: "data_object",
        desc: "Ex: Nó.me_2 > Nome2 ou nome2 caso argumento toLowerCase = true",
        isAsync: false,
        argsGuard: ['value', 'toLowerCase'],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    isValidRef: {
        value: "isValidRef",
        title: "Checa se variável é válida",
        matIcon: "data_object",
        desc: "Se valor é diferente de null ou undefined",
        isAsync: false,
        argsGuard: ['value'],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    changeBot: {
        value: `changeBot`,
        title: "Alterar Bot",
        matIcon: "change_circle",
        desc: "",
        argsGuard: ['idBot'],
        docSuffix: "(idBot: string): void;",
        type: EUFRuntimeFunctionType.ShakeHands,
        contexts: [EFunctionContext.ShakingHands]
    },
    getConversationHistoryUrl: {
        value: 'getConversationHistoryUrl',
        title: "Obtém URL do histórico de conversação",
        matIcon: "history",
        desc: "Retorna url no sistema da colmeia do historico de conversacao",
        isAsync: false,
        argsGuard: [],
        docSuffix: "(): string;",
        type: EUFRuntimeFunctionType.Data,
        contexts: []
    },
    hasFormOfMetadata: {
        value: "hasFormOfMetadata",
        title: "Checa de formulário foi respondido",
        matIcon: "playlist_add_check",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: ['idMetadata'],
        docSuffix: "(idMetadata: string): boolen;",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    getCustomerFormOfMetadata: {
        value: "getCustomerFormOfMetadata",
        title: "Obtém respostas do usuário ao formulário",
        matIcon: "contacts",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: ['idMetadata'],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    isChannelType: {
        value: "isChannelType",
        title: "Checa tipo atual do canal",
        matIcon: "category",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: ['type'],
        docSuffix: "(type: EDelivery360Action): boolean",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    createNewShakingHands: {
        value: `createNewShakingHands`,
        title: "Instancia gerenciamento de ShakingHands",
        matIcon: "handshake",
        desc: "",
        argsGuard: ['idMetadata'],
        docSuffix: "(idMetadata: string): IUserFunctionShakeHands;",
        type: EUFRuntimeFunctionType.ShakeHands,
        contexts: [EFunctionContext.ShakingHands]
    },
    saveShakingHands: {
        value: "saveShakingHands",
        title: "Salva gerenciamento de ShakingHands",
        matIcon: "handshake",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: ['shakeHands'],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    getIslandName: {
        value: "getIslandName",
        title: "Obtém nome da Ilha de atendimento",
        matIcon: "hdr_auto",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    getIslandID: {
        value: "getIslandID",
        title: "Obtém ID da Ilha de atendimento",
        matIcon: "fingerprint",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    getCurrentServiceBegin: {
        value: "getCurrentServiceBegin",
        title: "Obtém timestamp do início do atendimento atual",
        matIcon: "schedule",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    getCurrentServiceTime: {
        value: "getCurrentServiceTime",
        title: "Obtém duração do atendimento atual",
        matIcon: "schedule",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    getCustomerName: {
        value: "getCustomerName",
        title: "Obtém nome do cliente em atendimento",
        matIcon: "badge",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    getCustomerAvatarID: {
        value: "getCustomerAvatarID",
        title: "Obtém ID do avatar do cliente em atendimento",
        matIcon: "badge",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    getStatus: {
        value: "getStatus",
        title: "Obtém o status da campanha",
        matIcon: "category",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "Enviado, recebido, lido, etc...",
        isAsync: true,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: [EFunctionContext.MKTContext]
    },
    getCallbackStatus: {
        value: "getCallbackStatus",
        title: "Obtém o status que gerou a execução do callback",
        matIcon: "category",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "Enviado, recebido, lido, etc...",
        isAsync: false,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: [EFunctionContext.MKTContext]
    },
    getMktRuntime: {
        value: "getMktRuntime",
        title: "Obtém funções de marketing",
        matIcon: "category",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "Enviado, recebido, lido, etc...",
        isAsync: false,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: [EFunctionContext.MKTContext]
    },
    getActionID: {
        value: "getActionID",
        title: "Obtém o ID da ação enviada",
        matIcon: "fingerprint",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: [EFunctionContext.MKTContext]
    },
    getCampaignID: {
        value: "getCampaignID",
        title: "Obtém o id da campanha enviada",
        matIcon: "fingerprint",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: [EFunctionContext.MKTContext]
    },
    getRunID: {
        value: "getRunID",
        title: "Obtém o ID do envio",
        matIcon: "fingerprint",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: [EFunctionContext.MKTContext]
    },
    getActionName: {
        value: "getActionName",
        title: "Obtém título da ação enviada",
        matIcon: "title",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: true,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: [EFunctionContext.MKTContext]
    },
    getBotID: {
        value: "getBotID",
        title: "Obtém o ID do Bot da ação",
        matIcon: "fingerprint",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: false,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: [EFunctionContext.MKTContext]
    },
    getMktState: {
        value: "getMktState",
        title: "Obtém informações do disparo",
        matIcon: "info",
        auxIcon: {
            matIcon: "",
            url: ""
        },
        desc: "",
        isAsync: true,
        argsGuard: [],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: [EFunctionContext.MKTContext]
    },
    setHaltCommand: {
        value: "setHaltCommand",
        title: "Pause a função até o recebimento de callback",
        matIcon: "pause",
        desc: "",
        isAsync: false,
        argsGuard: ["halt"],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Flow,
        contexts: [EFunctionContext.BOTContext]
    },
    getUniqueStringID: {
        value: "getUniqueStringID",
        title: "Gera um ID único",
        matIcon: "fingerprint",
        desc: "",
        isAsync: false,
        argsGuard: ['size'],
        docSuffix: "",
        type: EUFRuntimeFunctionType.Data,
        contexts: [EFunctionContext.BOTContext]
    },
    getRowSentToClient: {
        value: "getRowSentToClient",
        title: "Retorna os dados enviados para o cliente na campanha, e o tipo do evento",
        matIcon: "fingerprint",
        desc: "",
        isAsync: false,
        docSuffix: "",
        type: EUFRuntimeFunctionType.Communication,
        contexts: [EFunctionContext.MKTContext]
    },
    replaceBotMessages: {
        value: "replaceBotMessages",
        title: "Substitui as mensagens das intenções",
        matIcon: "fingerprint",
        desc: "",
        isAsync: false,
        docSuffix: "",
        type: EUFRuntimeFunctionType.Communication,
        contexts: [EFunctionContext.AI]
    },
    charsSplitter: {
        value: "charsSplitter",
        title: "Divisor de texto",
        matIcon: "expand",
        desc: "",
        isAsync: false,
        docSuffix: "",
        type: EUFRuntimeFunctionType.Utils,
        contexts: []
    },
    getToBeSentMessages: {
        value: "getToBeSentMessages",
        title: "Retorna mensagens a ser enviadas pelo bot",
        matIcon: "mark_email_unread",
        desc: "",
        isAsync: false,
        docSuffix: "",
        type: EUFRuntimeFunctionType.Communication,
        contexts: []
    },
    getMktExecutionInfo: {
        value: "getMktExecutionInfo",
        title: "Retorna informações da campanha de marketing",
        matIcon: "campaign",
        desc: "",
        isAsync: false,
        docSuffix: "",
        type: EUFRuntimeFunctionType.Communication,
        contexts: []
    },
    getTriggerInfo: {
        value: "getTriggerInfo",
        title: "Obtém informações do acionador da função",
        matIcon: "mark_email_unread",
        desc: "Retorna classe com métodos para identificar o acionador da função",
        isAsync: false,
        docSuffix: "",
        type: EUFRuntimeFunctionType.Data,
        contexts: []
    },
    getBotRunningType: {
        value: "getBotRunningType",
        title: "Obtém o tipo de execução do bot",
        matIcon: "smart_toy",
        desc: "Útil para identificar se a função está sendo executada em um bot de pesquisa de satisfação",
        isAsync: false,
        docSuffix: "",
        type: EUFRuntimeFunctionType.Flow,
        contexts: []
    },
    getDocumentReaderState: {
        value: "getDocumentReaderState",
        title: "Obtém resultado do processamento de documentos",
        matIcon: "transform",
        desc: "",
        isAsync: false,
        docSuffix: "",
        type: EUFRuntimeFunctionType.Data,
        contexts: [EFunctionContext.BOTContext]
    },
    popVolatileProperties: {
        value: "popVolatileProperties",
        title: "pega e remove dados sensiveis do flow (volateis)",
        matIcon: "smart_toy",
        desc: "Pega os dados sensíveis preenchidos no WhatsApp flow, e os deleta.",
        isAsync: true,
        docSuffix: "",
        type: EUFRuntimeFunctionType.Data,
        contexts: []
    },
    getVolatileProperties: {
        value: "getVolatileProperties",
        title: "pega dados sensiveis do flow (volateis)",
        matIcon: "smart_toy",
        desc: "Pega os dados sensíveis preenchidos no WhatsApp flow.",
        isAsync: true,
        docSuffix: "",
        type: EUFRuntimeFunctionType.Data,
        contexts: []
    },
});


type RequiredByValue<T, Target> = ReMapObject<{ [key in keyof T]-?: IsMatch<T[key], Target> extends false ? { [$key in key]: T[key] } : { [$key in key]-?: T[key] } }>;
type DefineInterface<E extends {}, T extends Partial<E>> = Merger<T, E>;

type TUFRuntimeMethodConfigTypeValidationItem<T extends TAllUserFunctionRuntimeFunctions> = RequiredByValue<
    DefineInterface<TUFRuntimeMethodConfig<T>, {
        isAsync?: TAllUserFunctionRuntimeFunctionsIntersection[T] extends (...params: any[]) => Promise<any> ? true : false;
        argsGuard?: T extends TAllUserFunctionRuntimeFunctions
        ? Extract<MapTo<Required<Parameters<Extract<TAllUserFunctionRuntimeFunctionsIntersection[T], (...args: any) => any>>>, string>, string[]>
        : string[]
        ;
    }>,
    true
>;
