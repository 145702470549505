import { Injectable } from "@angular/core";
import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { IListNonSerializablesRequest } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { IListNonSerializablesResponse } from "@colmeia/core/src/dashboard-control/dashboard-response-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import {
    CreateConnectionRequest,
    CreateConnectionResponse,
    GetConnectionRequest,
    GetConnectionResponse, IGetConnectionByIdDomainRequest,
    IGetConnectionByIdDomainResponse,
    UpdateConnectionRequest,
    UpdateConnectionResponse
} from "@colmeia/core/src/shared-business-rules/connections/connections-requests";
import {
    EConnectionType,
    IConnectionClient,
    IConnectionServer,
    TConnectionsArray
} from "@colmeia/core/src/shared-business-rules/connections/endpoint-model";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { routeList } from "app/model/routes/route-constants";
import { ClientInfraResponse, IInfraParameters } from "../model/client-infra-comm";
import { GenericNonSerializableService } from "./generic-ns.service";
import { RequestBuilderServices } from "./request-builder.services";
import { RoutingService } from "./routing.service";
import { ServerCommunicationService } from "./server-communication.service";
import { SessionService } from "./session.service";
import { EMutationType } from "@colmeia/core/src/shared-business-rules/journaling/journaling-req-res.dto";

@Injectable({
    providedIn: 'root'
})
export class ConnectionsService extends GenericNonSerializableService {

    constructor(
        api: ServerCommunicationService,
        routeSvc: RoutingService,
        rbs: RequestBuilderServices,
        session: SessionService,
        private serverAPI: ServerCommunicationService,
    ) {
        super(
            routeList.dashboard.children.smartFlow.path,
            routeList.dashboard.children.smartFlow.children.canonical,
            { api, rbs, session, routeSvc },
        );
    }

    private getSnID(): TGlobalUID {
        return this.session.getCurrentSocialNetworkID();
    }


    async create(
        connection: IConnectionClient
    ): Promise<void> {
        await this.api.safeSendRequest<UpdateConnectionRequest, UpdateConnectionResponse>(apiRequestType.connections.create)({
            saveType: EMutationType.create,
            ns: connection,
        });
    }

    async update(connection: IConnectionServer): Promise<boolean> {
        const infra: IInfraParameters = this.spinningParameters();
        const req: CreateConnectionRequest = {
            ...this.rbs.secureBasicRequestForCustomGroup(apiRequestType.connections.update, this.getSnID()),
            ns: connection
        };
        const res = await this.serverAPI.managedRequest(infra, req);
        return res.executionOK;
    }

    async getAllAvailableConnections(showSpinner: boolean = true, type?: EConnectionType): Promise<TConnectionsArray> {
        const infra: IInfraParameters = showSpinner ? this.spinningParameters() : this.noSpinningParamaters();
        const req: IListNonSerializablesRequest = {
            ...this.rbs.secureBasicRequestForCustomGroup(apiRequestType.nonSerializable.list, this.getSnID()),
            nsType: ENonSerializableObjectType.connection,
            multipleCursor: {}
        };
        const res = await this.serverAPI.managedRequest(infra, req);
        const arr = (<IConnectionServer[]>(<IListNonSerializablesResponse>res.response).nonSerializableArray);
        return (isValidRef(type))
            ? arr.filter(conn => conn.auth && conn.auth.type === type)
            : arr;
    }

    async getConnection(idConnection: string): Promise<IConnectionServer> {
        const infra: IInfraParameters = this.spinningParameters();
        const req: GetConnectionRequest = {
            ...this.rbs.secureBasicRequestForCustomGroup(apiRequestType.connections.get, this.getSnID()),
            idConnection
        };
        const res = await this.serverAPI.managedRequest(infra, req);
        return res.executionOK ? (<GetConnectionResponse>res.response).connection : null;
    }


    async getConnectionByIdDomain(idDomain: string): Promise<IConnectionServer> {
        const infra: IInfraParameters = this.spinningParameters();

        const req: IGetConnectionByIdDomainRequest = {
            ...this.rbs.secureBasicRequestForCustomGroup(apiRequestType.connections.getByIdDomain, this.getSnID()),
            idDomain
        };
        const res = await this.serverAPI.managedRequest(infra, req);
        return res.executionOK ? (<IGetConnectionByIdDomainResponse>res.response).connection : null;
    }

    private spinningParameters(): IInfraParameters {
        return this.rbs.getNoCallBackSpinnningParameters(
            this.session.getPlayer().getPrimaryID(),
            this.session.getSelectedAvatarID()
        );
    }

    private noSpinningParamaters(): IInfraParameters {
        return this.rbs.getNoCallBackNoSpinnningParameters(
            this.session.getPlayer().getPrimaryID(),
            this.session.getSelectedAvatarID()
        );
    }



}
