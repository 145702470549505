import { getBlankNonSerializableHeader, initNserClientNoCheck } from "../../../nser/blank-ns";
import { apiRequestType } from "../../../request-interfaces/message-types";
import { getUniqueStringID } from "../../../tools/utility";
import { ENonSerializableObjectType } from "../../non-serializable-id/non-serializable-id-interfaces";
import { ECRMCanonicalItemsTypes } from "./crm-canonical-model";
import { TCRMConfigHeaderServer, TCRMConfigItemServer, ICRMDatabaseManifest, ICRMHeaderPackageBase, ISRPackageServer, TCRMConfigHeaderNsTypes, TCRMConfigItemsNsTypes, TCRMConfigNSTypes } from "./crm-config-interfaces";

const crmConfigParentNsTypes: ENonSerializableObjectType[] = [
  ENonSerializableObjectType.crmHeaderCloseState,
  ENonSerializableObjectType.crmHeaderPhase,
  ENonSerializableObjectType.crmHeaderSeverity,
  ENonSerializableObjectType.crmHeaderState,
];

export function isCRMConfigParent(nsType: ENonSerializableObjectType): boolean {
  return crmConfigParentNsTypes.includes(nsType);
}


export function getCRMConfigListRequestType<NsType extends TCRMConfigNSTypes>(nsType: NsType): typeof apiRequestType.dashboardExtra.crm.config[NsType]['list'] {
  return apiRequestType.dashboardExtra.crm.config[nsType].list;
}


export class InitCRMConfig {
  public static item(idParent: string, name: string, nsType: TCRMConfigItemsNsTypes): TCRMConfigItemServer {
    const ns: TCRMConfigItemServer = {
          ...getBlankNonSerializableHeader(name, false),
          nsType,
          idParent,
    } as TCRMConfigItemServer;
    return ns as TCRMConfigItemServer;
  }
  public static header(name: string, nsType: TCRMConfigHeaderNsTypes): TCRMConfigHeaderServer {
    const ns: TCRMConfigHeaderServer = {
          ...getBlankNonSerializableHeader(name, false),
          nsType,
    } as TCRMConfigHeaderServer;
    return ns as TCRMConfigHeaderServer;
  }
  public static package(name: string): ISRPackageServer {
      const ns: ISRPackageServer = initNserClientNoCheck(ENonSerializableObjectType.crmConfigPackage, name, {
        idCloseStateConfig: undefined,
        idSRPhasesConfig: undefined,
        idSeverityConfig: undefined,
        idTicketStateConfig: undefined,
        supportingDB: {},
        projectKey: undefined,
      });
      return ns;
  }

  public static packageSupportingDB(): ICRMDatabaseManifest {
      return {
          idDatabaseManifest: getUniqueStringID(10),
          idRTF: undefined
      }
  }
}

const crmConfigItemToHeaderMap: Record<TCRMConfigHeaderNsTypes, TCRMConfigItemsNsTypes> = {
  [ENonSerializableObjectType.crmHeaderCloseState]: ENonSerializableObjectType.crmItemCloseState,
  [ENonSerializableObjectType.crmHeaderPhase]: ENonSerializableObjectType.crmItemPhase,
  [ENonSerializableObjectType.crmHeaderSeverity]: ENonSerializableObjectType.crmItemSeverity,
  [ENonSerializableObjectType.crmHeaderState]: ENonSerializableObjectType.crmItemState,
  [ENonSerializableObjectType.crmHeaderSupportLevel]: ENonSerializableObjectType.crmItemSupportLevel,
  [ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel]: ENonSerializableObjectType.crmItemUrgencyLevel,
} 

export function getItemConfigNSTypeByHeader(nsType: TCRMConfigHeaderNsTypes): TCRMConfigItemsNsTypes {
  return crmConfigItemToHeaderMap[nsType];
} 


export function getCrmPackageObject<T extends ICRMHeaderPackageBase>(headerPackageId: string, name: string, type: ECRMCanonicalItemsTypes): T {
  return {
    headerPackageId,
    name,
    type,
    items: [],
  } as T
}