import { Injectable, Injector } from '@angular/core';
import { ECRMServiceConfigRequest } from '@colmeia/core/src/request-interfaces/message-types';
import { ICRMNotificationRequest, ICRMNotificationResponse, ICRMProcessNodeRequest, ICRMProcessNodeResponse, ICRMRootRequest, ICRMUserEventRequest } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-bpm-req-resp';
import { ICRMNotificationServer, ICRMProcessingNodeEventServer, ICRMRootServer, ICRMAgentEventServer, TCRMBPMConfigs } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crms-model-bpm-def';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { INonSerializable, ENonSerializableObjectType, TINonSerializableArray } from '../../../../../../core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ICRMUserEventResponse, ICRMRootResponse } from '../../../../../../core/src/shared-business-rules/crm/crm-services/crm-bpm-req-resp';
import { isValidRef } from '@colmeia/core/src/tools/barrel-tools';
import { BpmService } from '../bpm.service';

@Injectable({
    providedIn: 'root'
})
export class BpmCrmApiService {

    constructor(
        private serverCommunicationService: ServerCommunicationService,
        private injector: Injector,
    ) { }

    private getGraphRootID(): string {
        const bpmSvc = this.injector.get(BpmService);
        return bpmSvc.getCurrentStrategy().getGraphRulesProcessor().getRootElementId();
    }

    async save<T extends TCRMBPMConfigs>(nser: T, idParentGraphNode?: string): Promise<T[]> {
        let res: TCRMBPMConfigs[] = [];
        switch (nser.nsType) {
            case ENonSerializableObjectType.crmServiceConfigProcessingNode:
                res = [await this.saveProcessNode(<ICRMProcessingNodeEventServer>nser, idParentGraphNode)]
                break;
            case ENonSerializableObjectType.crmServiceConfigRoot:
                res = [await this.saveRoot(<ICRMRootServer>nser)]
                break;
            case ENonSerializableObjectType.crmServiceConfigAgentEvent:
                res = [await this.saveUserEvent(<ICRMAgentEventServer>nser, idParentGraphNode)]
                break;
            case ENonSerializableObjectType.crmServiceConfigNotification:
                res = [await this.saveNotification(<ICRMNotificationServer>nser, idParentGraphNode)]
                break;
            default:
                throw new Error("BpmCrmApiService: unknown ENonSerializableObjectType");
        }

        return res.filter(ns => isValidRef(ns)) as T[];
    }

    async saveNotification(crmNotification: ICRMNotificationServer, idParentNode: string): Promise<ICRMNotificationServer> {
        const result: ICRMNotificationResponse = <ICRMNotificationResponse>await this.serverCommunicationService.doRequest<ICRMNotificationRequest>(ECRMServiceConfigRequest.crmNotificationSave, {
            idParentGraphNode: idParentNode,
            idBPM: this.getGraphRootID(),
            data: {
                ns: crmNotification
            }
        });
        return result?.data.ns
    }

    async saveRoot(root: ICRMRootServer): Promise<ICRMRootServer> {
        const result: ICRMRootResponse = <ICRMRootResponse>await this.serverCommunicationService.doRequest<ICRMRootRequest>(ECRMServiceConfigRequest.crmServiceRootSave, {
            idBPM: this.getGraphRootID(),
            idParentGraphNode: undefined,
            data: {
                ns: root
            }
        });
        return result?.data.ns
    }

    async saveUserEvent(userEvent: ICRMAgentEventServer, idParentNode: string): Promise<ICRMAgentEventServer> {
        const result: ICRMUserEventResponse = <ICRMUserEventResponse>await this.serverCommunicationService.doRequest<ICRMUserEventRequest>(ECRMServiceConfigRequest.crmUserEventSave, {
            idParentGraphNode: idParentNode,
            idBPM: this.getGraphRootID(),
            data: {
                ns: userEvent
            }
        });
        return result?.data.ns
    }

    async saveProcessNode(processingNode: ICRMProcessingNodeEventServer, idParentNode: string): Promise<ICRMProcessingNodeEventServer> {
        const result: ICRMProcessNodeResponse = <ICRMProcessNodeResponse>await this.serverCommunicationService.doRequest<ICRMProcessNodeRequest>(ECRMServiceConfigRequest.crmProcessingNodeSave, {
            idParentGraphNode: idParentNode,
            idBPM: this.getGraphRootID(),
            data: {
                ns: processingNode
            }
        });
        return result?.data.ns
    }
}
