import { Injectable } from '@angular/core';
import { constant } from "@colmeia/core/src/business/constant";
import { Group } from '@colmeia/core/src/business/group';
import { PlayerCachedInfo } from "@colmeia/core/src/business/player-cached";
import { TPublishGroupArray } from '@colmeia/core/src/comm-interfaces/interaction-interfaces';
import { TArrayID, TGlobalUID, TGroupArray } from '@colmeia/core/src/core-constants/types';
import { TInteractionArray } from '@colmeia/core/src/interaction/interaction';
import { TArrayGroupSynch } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { hashToArray } from '@colmeia/core/src/tools/utility';
import { createServiceLogger } from 'app/model/client-utility';
import { ChatBackboneModel } from "../model/chat-backbone.model";
import { GroupSubscription, IClientSubscriptionHash } from "../model/group-subscription.model";
import { ContractService } from "./controllers-services/contract-services/contract.service";
import { NavigatorServices } from "./controllers-services/navigator/navigator.service";
import { GroupChatServices } from "./group-chat.services";
import { QueuService } from "./queue.service";
import { SerializableHeaders } from "./serializable-headers";
import { SessionService } from "./session.service";
import { SignalListenerService } from "./signal/signal-listener";
import { SignalPublisherService } from "./signal/signal-publisher";
import { SubscriptionInteractionInfoBuilder } from "./subscription-info.service";

/**
 * Service that is responsible for having all data related to the user subscriptions
 */
@Injectable()
export class SusbcriptionContainerService {
    private log = createServiceLogger('SusbcriptionContainerService', '#ffb914');

    private subscriptionHash: IClientSubscriptionHash = {};
    private currentSubscription: GroupSubscription;
    private session: SessionService;
    private navigator: NavigatorServices;
    private listener: SignalListenerService;
    private susbsInfoBuilder: SubscriptionInteractionInfoBuilder;
    private queueService: QueuService;
    private contractSvc: ContractService;
    constructor(
        private publisher: SignalPublisherService,
        private chatSvc: GroupChatServices,
    ) { };

    setDependencySessionService(session: SessionService): void { this.session = session; };
    setDependencyNavigator(navigator: NavigatorServices): void { this.navigator = navigator };
    setDependencyListener(listener: SignalListenerService): void { this.listener = listener };
    setDependencyQueueServices(queue: QueuService): void { this.queueService = queue; };
    setDependencyContractService(contractSvc: ContractService): void { this.contractSvc = contractSvc };
    public setDependencySubscriptionBuilder(builder: SubscriptionInteractionInfoBuilder): void {
        this.susbsInfoBuilder = builder;
    }

    // Se chegou pelo socket, todos tem acesso à informação
    public changeSelectedSubscription(modelSubscription: GroupSubscription): void {
        this.createChatBackboneModel();
        this.currentSubscription = modelSubscription;
        this.publisher.susbcriptionEmissor(modelSubscription, this.session.getMenuSafeBuilderData());
    }

    public setArchived(archivedGroups: TArrayID, oldCached: PlayerCachedInfo): void {
        for (const idGroup of archivedGroups) {
            const subs: GroupSubscription = this.subscriptionHash[idGroup];
            this.log("~> DELETANDO", idGroup)
            if (subs) {
                const group: Group = subs.getGroup();
                delete this.subscriptionHash[idGroup];
                this.log("~> GENEALOGIA", group.getPlainGroupGenealogy())
                const idParentLevelOne = group.getPlainGroupGenealogy()
                    .find(gen => group.isMyParentOfLevel(gen.idGroupParent) === 0);
                this.log("~> DELETANDOP", idParentLevelOne.idGroupParent)


                delete this.subscriptionHash[idParentLevelOne.idGroupParent];

                for (const onGenealogy of (oldCached.getAllGroupOnGenealogy(idGroup))) {
                    delete this.subscriptionHash[onGenealogy];
                    this.log("~> DELETANDOG", onGenealogy)
                }
            }
        };
        this.refreshCached();
    };

    public refreshCached(): void {
        const newCached: PlayerCachedInfo = this.session.getPlayerInfo();
        for (const idGroup in this.subscriptionHash) {
            if (!newCached.belongsToGroup(idGroup)) {
                delete this.subscriptionHash[idGroup];
            };
        };
    };

    private createChatBackboneModel(): void {
        const model: ChatBackboneModel = ChatBackboneModel.chatBackboneModelFactory(
            this.session,
            this.navigator,
            this.listener,
            this.chatSvc,
            this.queueService,
            this.contractSvc,
        );
        ChatBackboneModel.storeBackboneModel(model);
        ChatBackboneModel.setActiveGroup(this.session.getSelectedGroupID());
    }

    public isSubscriptionOperational(): boolean {
        // Só o ALL-ROOT não quer dizer operacional
        return hashToArray(this.subscriptionHash).length > 1
    }

    public isInMemory(idGroup: TGlobalUID): boolean {
        return this.subscriptionHash[idGroup] ? true : false;
    };

    public somePublishInMemory(publishinGroups: TPublishGroupArray): boolean {
        return publishinGroups.some((gp) => { return this.isInMemory(gp.idGroup); });
    };

    public getGroupSubscriptionModel(idGroup: TGlobalUID): GroupSubscription {
        return this.subscriptionHash[idGroup];
    }


    /**
     * Add new subscription
     * @param {GroupSubscription} mySubscription [description]
     */
    public addSubscriptionToHash(mySubscription: GroupSubscription): void {
        if (!this.subscriptionHash[mySubscription.getGroup().getGroupID()])
            this.subscriptionHash[mySubscription.getGroup().getGroupID()] = mySubscription;
    }

    public getSubscriptionHashAsArray(): GroupSubscription[] {
        return hashToArray(this.subscriptionHash);
    }

    public getSelectedSubscription(): GroupSubscription {
        return this.currentSubscription;
    }

    public getSelectedGroupID(): TGlobalUID {
        return this.getSelectedSubscription() ? this.getSelectedSubscription().getGroup().getGroupID() : null;
    }

    public getSelectedGroup(): Group { return this.getSelectedSubscription() ? this.getSelectedSubscription().getGroup() : null; }

    public isGroupType(idGroupType: TGlobalUID): boolean {
        return this.getSelectedGroup().getGroupType().is(idGroupType);
    };

    public isGroupBroadcast(): boolean {
        return this.isGroupType(constant.groupType.functional.broadcast);
    }

    public async groupSubscriptionModelFactory(group: Group, interactionArray: TInteractionArray,
        childGroups: TGroupArray, requestedGroups: TGroupArray): Promise<GroupSubscription> {
        // Create a new subscription and push to the array
        const mySubscription: GroupSubscription =
            GroupSubscription.getNewGroupSubscriptionModel(group, interactionArray,
                childGroups, requestedGroups, this.subscriptionHash);

        if (group.getGroupType().isPersonal()) {
            // get raw jsons for groups and avatars...
            SerializableHeaders.appendSerializableHeaders(
                await this.susbsInfoBuilder.fetchGroupsAndAvatarsFromInteractions(interactionArray)
            );
        }

        this.addSubscriptionToHash(mySubscription);
        return mySubscription;
    };

    public getAllInMemoryGroups(): TArrayGroupSynch {
        const possibleDelays: TArrayGroupSynch = [];
        for (const idGroup in this.subscriptionHash) {
            const subs: GroupSubscription = this.subscriptionHash[idGroup];
            if (!subs.getGroupType().isFunctionalRoot() && !subs.getGroupType().isRoot()) {
                possibleDelays.push({ idGroup: subs.getGroupID() });
            }
        };
        return possibleDelays;
    };

    reset(): void {
        this.log('resetando clientSubs');
        this.subscriptionHash = {};
    }

}
