import { Participant, TParticipantArray } from '../business/participant';
import { isValidRef, isValidArray, isInvalid, isValidObject } from '../tools/utility';
import { constant, TGlobalUID } from '../business/constant';
import { InteractionType } from './interaction-type';
import { Serializable } from '../business/serializable';
import {
    EDelivery360Action,
    EDistributionEngagement,
    IInteractionDeliveryOptions,
    IInteractionFeedbackDriver,
    IInteractionJSON,
    IPersonalResponse,
    IPublishGroup,
    ITimed,
    TArrayIDJSON,
    TDriverFeedbackArray,
    TEDelivery360ActionArray,
    TIDueDateAppointmentArray,
    TPersonalResponseArray,
    TPublishGroupArray,
    TThreadInfoArray,
    IBasicUniversalInfo,
    TCitedAvatarArray,
    IReadSendMSGControl,
    IReadReceiveData,
    IWebChatConfig,
    EInteractionSentType
} from '../comm-interfaces/barrel-comm-interfaces';
import { UberCache } from '../persistency/uber-cache';
import { ChainedInteraction } from './chained-interaction';
import { addToPersonalResponse } from '../rules/filters';
import {
    feedbackControlFields,
    ICacheFeedbackInteraction,
    IFeedbackFigures
} from '../comm-interfaces/feedback-interfaces';
import { bulkInviteFields, TArrayID, EConfirmationType, TDeliveryTargets } from '../core-constants/types';
import { addPubGroupIntoInteraction, addToDriverFeedbackArray, getPubForGroup } from '../rules/interaction-filter';
import { ETimedInteractionTypeID, EThreadType } from '../business/constant.enums';
import { ICoordinates, ILocationCoordinates } from '../tools/geo-util';
import { isValidaBillable } from '../rules/finance-rules';
import { IBillOptions, EBillType } from '../comm-interfaces/finance-interfaces';
import { TOmniChannelArray } from '../shared-business-rules/campaigns/campaing-comm-strategy';
import { EConversationStatusCC } from '../comm-interfaces/service-group-interfaces';
import { EBotEventType } from '../shared-business-rules/bot/bot-event-model';
import { IdDep } from '../shared-business-rules/non-serializable-id/non-serializable-types';
import { ENonSerializableObjectType } from '../shared-business-rules/non-serializable-id/non-serializable-id-interfaces';

export type TOnlineFilterFunction = (interaction: Interaction, filter: any, idGroup: TGlobalUID) => boolean;

export type TFeedbackParticipantArray = Array<TParticipantArray>;
export type TInteractionArray = Array<Interaction>;
export type TChildrenStructure = { [idGroup: string]: TInteractionArray };


export class Interaction extends Serializable {
    private participant: Participant;
    private dateDeleted: number;
    private interactionType: InteractionType;
    private publishingGroups: TPublishGroupArray;
    private personalResponse: TPersonalResponseArray;
    private childrenInteraction: TChildrenStructure;
    private timedConfig: ITimed;
    private personalGroupParticipant: Participant;
    private idInteractionParentArray: TArrayIDJSON;
    private appointments: TIDueDateAppointmentArray;
    private feedbackFigures: IFeedbackFigures;
    private driverFeedbacks: TDriverFeedbackArray;
    private delivery: IInteractionDeliveryOptions;
    private threadInfo: TThreadInfoArray;
    private billOptions: IBillOptions;
    private coordinates: ILocationCoordinates;
    private citations: TCitedAvatarArray;
    private e360Provider: TDeliveryTargets;
    private rrCtrl?: IReadSendMSGControl;
    private idHandShake: string;
    private interlocutor: EConversationStatusCC;
    private sentLocation: ICoordinates;
    private isAttendantPersonalMessage: boolean;
    private webChatConfig: IWebChatConfig = {}
    private generatedByBotEvent!: EBotEventType;
    private sentType?: EInteractionSentType;
    private islandEventsId?: IdDep<ENonSerializableObjectType.customEvents>;

    protected constructor(primaryID: TGlobalUID, participant: Participant, interactionType: InteractionType) {
        super(constant.objectType.interaction, primaryID);
        this.participant = participant;
        this.interactionType = interactionType;
        this.personalResponse = null;
        this.childrenInteraction = {};
        this.publishingGroups = [];
        this.appointments = [];
        this.driverFeedbacks = [];
        this.feedbackFigures = {};
        this.threadInfo = [];
        this.coordinates = null;
        this.delivery = <IInteractionDeliveryOptions>{};
        this.citations = [];
        this.idInteractionParentArray = [];
    };

    public isValid(): boolean {
        return !this.dateDeleted || this.dateDeleted === 0;
    };

    private getGroupInteractionArray(idGroup: TGlobalUID): TInteractionArray {
        if (!this.childrenInteraction[idGroup]) {
            this.childrenInteraction[idGroup] = [];
        };
        return this.childrenInteraction[idGroup];
    }

    public getBillableOptions(): IBillOptions {
        return this.billOptions;
    }

    public setBillableOptions(billOptions: IBillOptions): void {
        this.billOptions = billOptions;
        this.addToDriverFeedback({ idDriverInteraction: constant.interactionType.products.prePurchase.billableDriverFeedback, priority: 0 })
    };

    public getBillType(): EBillType {
        return (this.isBillable() && this.billOptions.billType) ? this.billOptions.billType : EBillType.product;
    }

    public getCitations(): TCitedAvatarArray {
        return this.citations;
    }

    public setCitations(citations: TCitedAvatarArray): void {
        this.citations = citations;
    }

    public removeBillOptions(): void {
        this.billOptions = null;
        this.removeDriverInteraction(constant.interactionType.products.prePurchase.billableDriverFeedback);
    };

    public addChildInteraction(idGroup: TGlobalUID, interaction: Interaction): void {
        const array: TInteractionArray = this.getGroupInteractionArray(idGroup);
        const idx: number = array.findIndex((i) => { return i.iss(interaction); });
        if (idx === -1) {
            array.push(interaction);
        } else {
            array[idx] = interaction;
        };
    };

    public removeChildrenInteraction(idGroup: TGlobalUID, interaction: Interaction): void {
        const array: TInteractionArray = this.getGroupInteractionArray(idGroup);
        const k: number = array.findIndex((i) => { return i.iss(interaction) });
        if (k > -1) {
            array.splice(k, 1);
        };
    };


    public isChainedOrTreeWithParent(): boolean {
        let interaction: Interaction = this;
        return this.interactionType.isChained() || (this.interactionType.isTree() && (<ChainedInteraction>interaction).getInteractionParent() ? true : false);
    };


    ////// * PERSONAL MESSAGE CONTROL ///////////////

    public addPersonalResponse(idAvatarList: TArrayIDJSON): void {
        const idGroup: TGlobalUID = this.participant.getGroupID();

        if (!this.personalResponse)
            this.personalResponse = [];

        addToPersonalResponse(this.personalResponse, [], idGroup, ...idAvatarList);
    };

    public getGroupPersonalResponse(idGroup: TGlobalUID): TArrayIDJSON {
        const personalResponse: IPersonalResponse = this.personalResponse.find((p) => { return p.idGroup == idGroup });
        return (personalResponse && isValidArray(personalResponse.idAvatarArray)) ? personalResponse.idAvatarArray : [];
    };

    public isToSubgroup(): boolean { return this.personalResponse ? true : false; };

    public getPersonalResponse(): TPersonalResponseArray {
        return this.personalResponse;
    }

    public getAllIDAvatarCopied(): TArrayIDJSON {
        const cc: TArrayIDJSON = [];
        if (this.isToSubgroup()) {
            for (const personal of this.personalResponse) {
                cc.push(...personal.idAvatarArray);
            };
        };
        return cc;
    };

    public getRrCtrl(): IReadSendMSGControl {
        return this.rrCtrl;
    }

    public isReadByMe(idGroup: TGlobalUID): boolean {
        return isValidRef(this.rrCtrl)
            && this.rrCtrl[idGroup]
            && (this.rrCtrl[idGroup].yr || this.rrCtrl[idGroup].read);
    };

    public setReadByMe(idGroup: TGlobalUID): void {
        if (isInvalid(this.rrCtrl)) {
            this.rrCtrl = {}
        };
        if (this.rrCtrl[idGroup]) {
            this.rrCtrl[idGroup].yr = true;
        } else {
            this.rrCtrl[idGroup] = <IReadReceiveData>{ yr: true }
        }
    };

    public setReceive(idGroup: TGlobalUID, confirmationType: EConfirmationType): void {
        if (isInvalid(this.rrCtrl)) {
            this.rrCtrl = {}
        };
        if (!this.rrCtrl[idGroup]) {
            this.rrCtrl[idGroup] = <IReadReceiveData>{}
        };
        if (confirmationType === EConfirmationType.read) {
            this.rrCtrl[idGroup].read = true;
        } else {
            this.rrCtrl[idGroup].rec = true;
        }

    }

    public isReceivedByAll(idGroup): boolean {
        return isValidRef(this.rrCtrl)
            && this.rrCtrl[idGroup]
            && this.rrCtrl[idGroup].rec;
    };

    public isReadByAll(idGroup): boolean {
        return isValidRef(this.rrCtrl)
            && this.rrCtrl[idGroup]
            && this.rrCtrl[idGroup].read;
    };


    public setRrCtrl(rrCtrl: IReadSendMSGControl) {
        this.rrCtrl = rrCtrl;
    }

    public addRrCtrl(idGroup: TGlobalUID, rrData: IReadReceiveData) {
        if (isInvalid(this.rrCtrl)) {
            this.rrCtrl = {};
        }
        this.rrCtrl[idGroup] = rrData;
    };

    public removeRrCtrl(idGroup: TGlobalUID) {
        delete this.rrCtrl[idGroup];
    }

    //****** TIME CONTROL ********/
    public resetTimed(): void {
        this.timedConfig = null;
        this.personalGroupParticipant = null;
    };

    protected setTimed(idTypeTimed: ETimedInteractionTypeID, when: number, personalGroupParticipant: Participant, rememberMe: number = null): void {
        this.resetTimed();
        this.timedConfig = {
            timedType: idTypeTimed,
            whenOcurrs: when,
            warnMeBefore: rememberMe,
            registering: true,
            personalParticipant: null,  // será preenchido no toJSON
        };
        this.personalGroupParticipant = personalGroupParticipant;
    };

    public static setSTimed(interaction: IInteractionJSON, idTypeTimed: ETimedInteractionTypeID, when: number, rememberMe: number = null): void {
        interaction.timedConfig = {
            timedType: idTypeTimed,
            whenOcurrs: when,
            warnMeBefore: rememberMe,
            registering: true,
            personalParticipant: null
        };
    };

    public setSuicidal(when: number): void {
        this.setTimed(constant.timedType.suicidal, when, null);
    };
    public sendOnlyAt(when: number, personalGroupParticipant: Participant): void {
        this.setTimed(constant.timedType.sendAt, when, personalGroupParticipant);
    };
    public isTimed(): boolean { return this.timedConfig ? true : false };

    public isEventOcurred(past: boolean, clockTick: number): boolean {
        if (this.isTimed()) {
            return past ? clockTick >= this.timedConfig.whenOcurrs : clockTick < this.timedConfig.whenOcurrs
        }
        return false;
    }

    public getWhenOccurs(): number {
        return this.timedConfig && this.timedConfig.whenOcurrs;
    }

    public setWhenDate(when: number): void {
        if (this.isTimed()) {
            this.timedConfig.whenOcurrs = when;
        };
    };

    public setWarnMeBefore(warmeBefore: number): void {
        if (this.isTimed()) {
            this.timedConfig.warnMeBefore = warmeBefore;
        };
    };


    public setRememberMe(when: number, personalGroupParticipant: Participant): void {
        this.setTimed(constant.timedType.rememberMe, when, personalGroupParticipant);
    };

    public setDueDate(when: number): void {
        this.setTimed(constant.timedType.dueDate, when, null);
    };

    public getTimedType(): TGlobalUID {
        return this.isTimed() ? this.timedConfig.timedType : null;
    };

    public isTimedType(timed: TGlobalUID): boolean { return this.getTimedType() == timed }

    public getAppointments(): TIDueDateAppointmentArray { return this.appointments; };

    //******** Distribution sending and replies ******/
    public setWarnEngagement(): void { this.delivery.distributionEngagement = EDistributionEngagement.engageWarn };
    public setFeedbackEngagement(): void { this.delivery.distributionEngagement = EDistributionEngagement.engageFeed };
    public setWorkGroupEngagement(): void { this.delivery.distributionEngagement = EDistributionEngagement.engageGroupW };
    public setTeamWorkEngagement(): void { this.delivery.distributionEngagement = EDistributionEngagement.engageTeamW };

    public isWarnEngagement(): boolean { return this.delivery.distributionEngagement == EDistributionEngagement.engageWarn };
    public isFeedbackEngagement(): boolean { return this.delivery.distributionEngagement == EDistributionEngagement.engageFeed };
    public isWorkGroupEngagement(): boolean { return this.delivery.distributionEngagement == EDistributionEngagement.engageGroupW };
    public isTeamWorkEngagement(): boolean { return this.delivery.distributionEngagement == EDistributionEngagement.engageTeamW };

    public setEngagement(engagement: TGlobalUID): void {
        this.delivery.distributionEngagement = EDistributionEngagement[engagement];
    }
    public getEngagement(): EDistributionEngagement {
        return this.delivery.distributionEngagement
    }
    public hasEngagement(): boolean { return this.delivery.delivery360Options ? true : false };

    public setDelivery360(...items: TArrayID): void {
        this.delivery.delivery360Options = items.map(item => EDelivery360Action[item])
    }
    public getDelivery360(): TEDelivery360ActionArray {
        return this.delivery.delivery360Options
    };
    public setOmniChannels(channels: TOmniChannelArray): void {
        this.delivery.schedule = channels;
    }
    public getOmniChannels(): TOmniChannelArray {
        return this.delivery.schedule;
    }

    //****************************************************************************** */


    ////// FEEDBACKS
    //returns a feedbackhash containing interactionType -> FeedbackType -> ParticipantArray


    // gets all feedbacks names in current interaction

    public getInteractionFeedbackFiguresForGroup(idGroup: TGlobalUID): ICacheFeedbackInteraction {
        return this.feedbackFigures[idGroup] ? this.feedbackFigures[idGroup] : {};
    };

    public isFeedbackedInteraction(idGroup: TGlobalUID): boolean {
        return this.hasInteractionFeedbackFigures(idGroup)
            || this.getChildren(idGroup).some((i) => { return i.getInteractionType().isConsideredAnswer() });
    }


    public hasInteractionFeedbackFigures(idGroup: TGlobalUID): boolean {
        return this.getInteractionFeedbackFiguresForGroup(idGroup)[feedbackControlFields.total] > 0;
    };

    public hasFeedback(idGroup: TGlobalUID, ...idFeedbacks: TArrayID): boolean {
        const figures: ICacheFeedbackInteraction = this.getInteractionFeedbackFiguresForGroup(idGroup);
        for (const idFeedback of idFeedbacks) {
            if (figures[idFeedback]) {
                return true;
            }
        };
        return false;
    };

    public replaceFeedbackFigures(idGroup: TGlobalUID, figures: ICacheFeedbackInteraction): void {
        this.feedbackFigures[idGroup] = figures;
    };

    public getDriverFeedbacks(): TDriverFeedbackArray { return this.driverFeedbacks };
    public addToDriverFeedback(driver: IInteractionFeedbackDriver): void {
        addToDriverFeedbackArray(this.driverFeedbacks, driver);
    };
    public removeDriverInteraction(idDriverInteraction: TGlobalUID): void {
        const idx: number = this.driverFeedbacks.findIndex((d) => { return d.idDriverInteraction == idDriverInteraction });
        if (idx > -1) {
            this.driverFeedbacks.splice(idx, 1);
        };
    };

    public isBillable(): boolean {
        return isValidaBillable(this.billOptions);
    };
    public getEmbedded(): string { return this.idHandShake; }
    public isEmbedded(): boolean { return isValidRef(this.idHandShake) }
    public setEmbedded(idHandShake: string): void { this.idHandShake = idHandShake };
    public getInterlocutor(): EConversationStatusCC { return this.interlocutor };

    public isInterlocutor(interlecoutor: EConversationStatusCC): boolean { return this.interlocutor === interlecoutor; };
    public isInterlocutorASupervisor(): boolean { return this.interlocutor === EConversationStatusCC.supervisorMessage; };
    public setInterlocutor(type: EConversationStatusCC): void { this.interlocutor = type; };


    public setDriverFeedbacks(drivers: TDriverFeedbackArray = []): void { this.driverFeedbacks = drivers };
    public hasDriverFeedbacks(): boolean { return isValidArray(this.driverFeedbacks) };

    public isBigIconFeedbacks(): boolean {
        return this.hasDriverFeedbacks() && this.getDriverFeedbacks().some(
            d => InteractionType.staticFactory(d.idDriverInteraction).isBigIcon()
        );
    }

    /********************************************************************************************* */



    public getHopNumber(): number {
        let count: number = 0;
        let interaction: Interaction = this;
        let current: ChainedInteraction = <ChainedInteraction>interaction;
        let inter: boolean;
        do {
            inter = current.isChainedOrTreeWithParent();
            if (inter) {
                ++count;
                current = <ChainedInteraction>current.getInteractionParent();
            };

        } while (inter);

        return count;
    };

    public getRoot(): Interaction {
        let interaction: Interaction = this;
        let current: ChainedInteraction = <ChainedInteraction>interaction;

        while (current.isChainedOrTreeWithParent()) {
            current = <ChainedInteraction>current.getInteractionParent();
        }

        return current;
    }

    public getChainOfParents(): TArrayID {
        return this.idInteractionParentArray.reverse();
    }

    public isShallowVisibleOnCurrentFilter(idGroup: TGlobalUID, checkVisible: TOnlineFilterFunction, filter: any): boolean {
        return checkVisible(this, filter, idGroup);
    }

    public isDeepVisibleOnCurrentFilter(idGroup: TGlobalUID, checkVisible: TOnlineFilterFunction, filter: any): boolean {
        if (checkVisible(this, filter, idGroup)) {
            return true;
        } else {
            for (const interaction of this.getChildren(idGroup)) {
                if (interaction.isDeepVisibleOnCurrentFilter(idGroup, checkVisible, filter)) {
                    return true;
                };
            };
            return false;
        };
    };

    public getProfoundBusinessID(idGroup: TGlobalUID): string {
        let maxBusinessID: string = this.getBusinessID();

        for (const interaction of this.getChildren(idGroup)) {
            const profound: string = interaction.getProfoundBusinessID(idGroup);
            if (profound > maxBusinessID) {
                maxBusinessID = profound;
            }
        };
        return maxBusinessID;
    };



    public getProfoundClockTick(idGroup: TGlobalUID, level: number = 0): number {
        let clockTick: number = super.getClockTick();

        for (const interaction of this.getChildren(idGroup)) {
            const profound: number = interaction.getProfoundClockTick(idGroup, level + 1);
            if (profound > clockTick) {
                clockTick = profound;
            };
        };
        return clockTick;
    };

    // anyObject by standard (with no compared function) will be idInteractionType
    // null will return all the children
    public getChildren(idGroup: TGlobalUID, anyObject: any = null,
        compareFunction: (iterator: Interaction, anyObject: any) => boolean
            = this.defaultSearch, oneChildren: boolean = false): TInteractionArray {
        let interactionArray: TInteractionArray = [];
        const childrenInteraction = this.getGroupInteractionArray(idGroup);
        for (let interaction of childrenInteraction.filter((i) => { return i.isValid() })) {
            if (compareFunction(interaction, anyObject)) {
                interactionArray.push(interaction);
                if (oneChildren)
                    return interactionArray;
            }
        }
        return interactionArray;
    };


    public getChild(idGroup: TGlobalUID, idInteractionType: TGlobalUID,
        compareFunction: (iterator: Interaction, anyID: TGlobalUID) => boolean
            = this.defaultSearch, throwError: boolean = true): Interaction {
        let interactionArray: TInteractionArray = this.getChildren(idGroup, idInteractionType, compareFunction, true);
        if (interactionArray)
            return interactionArray[0];
        else
            return null;
    };

    //returns the tree starting from a particular parent(and includes parent)
    public static getWholeInteractionChain(idGroup: TGlobalUID, parent: Interaction): TInteractionArray {
        return Interaction.getWholeChainAuxiliar(idGroup, parent, []);
    };

    // Auxiliar de getWholeInteractionChain
    private static getWholeChainAuxiliar(idGroup: TGlobalUID, parent: Interaction, tree: TInteractionArray): TInteractionArray {
        tree.push(parent);
        for (let child of parent.getChildren(idGroup)) {
            this.getWholeChainAuxiliar(idGroup, child, tree);
        };
        return tree;
    };

    public getInteractionAncestry(): TInteractionArray {
        const ancestry: TInteractionArray = [];
        let interaction: Interaction = this;
        let cont: boolean = true;

        while (cont) {
            if (cont = interaction.isChainedOrTreeWithParent()) {
                const interactionParent: Interaction = (<ChainedInteraction>interaction).getInteractionParent();
                ancestry.push(interactionParent);
                interaction = interactionParent;
            };
        };
        return ancestry;
    }

    public hasAsOneOfAncestors(idInteractionParent: TGlobalUID): boolean {
        let interaction: Interaction = this;
        let cont: boolean = true;
        let found: boolean = false;

        while (cont && !found) {
            if (cont = interaction.isChainedOrTreeWithParent()) {
                const interactionParent: Interaction = (<ChainedInteraction>interaction).getInteractionParent();
                found = interactionParent.is(idInteractionParent);
                interaction = interactionParent;
            };
        };

        return found;
    };


    public isMyDirectConcerne(interaction: Interaction): boolean {
        let is: boolean = false;
        if (interaction.isChainedOrTreeWithParent()) {
            const chained: ChainedInteraction = <ChainedInteraction>interaction;
            is = chained.getInteractionParent().iss(this);
        };
        return is;
    };




    private defaultSearch(iterator: Interaction, interactionType: TGlobalUID): boolean {
        return !interactionType || iterator.getInteractionType().is(interactionType);
    };

    public getParticipant(): Participant { return this.participant; };
    public getInteractionID(): TGlobalUID { return super.getPrimaryID(); };
    public getInteractionType(): InteractionType { return this.interactionType; };
    public isInteractionTypeID(idInteractionType: TGlobalUID): boolean { return idInteractionType == this.getInteractionType().getInteractionTypeID() };
    public sameInteraction(interaction: Interaction): boolean { return this.getInteractionID() == interaction.getInteractionID() };
    public setDateDeleted(dateDeleted: number): void { this.dateDeleted = dateDeleted; }
    public getDateDeleted(): Date { return !this.dateDeleted || this.dateDeleted == 0 ? null : new Date(this.dateDeleted) };
    public getPublishingGroups(): TPublishGroupArray { return this.publishingGroups };
    public belongToGroup(idGroup: TGlobalUID): boolean {
        return this.publishingGroups.some((grp) => { return grp.idGroup == idGroup })
    };
    public belongsToAnotherGroup(idGroup: TGlobalUID): boolean {
        return this.publishingGroups.some((grp) => { return grp.idGroup !== idGroup })
    }

    public getGroupID(): string {
        return this.getParticipant().getGroupID();
    }

    public excludeFromGroup(idGroup: TGlobalUID): void {
        const idx: number = this.publishingGroups.findIndex((g) => { return g.idGroup === idGroup });
        if (idx > -1) {
            this.publishingGroups.splice(idx, 1)
        };
    }

    public getBasicUniversalInfo(idField: number = constant.serializableField.chat_text): IBasicUniversalInfo {
        return {
            ...super.getBasicUniversalInfo(idField),
            idInteractionType: this.getInteractionType().getInteractionTypeID(),
            idObjectType: this.getObjectTypeID(),
        }
    };


    public toJSON(): IInteractionJSON {
        // No caso de fazer o rehydrate da interaction multi group, ele da um setREceivedCRC que faz um interaction.toJSON()
        // por isso preciso verificar se existe o grupoß
        const json: IInteractionJSON = {
            ...super.toJSON(),
            idGroup: this.getParticipant().getGroupID(),
            idInteractionType: this.getInteractionType().getInteractionTypeID(),
            idParticipant: this.getParticipant().getPrimaryID(),
            participant: this.participant.toJSON(),
            dateDeleted: this.dateDeleted,
            timedConfig: this.timedConfig,
            publishingGroups: this.publishingGroups,
            idInteractionParentArray: this.idInteractionParentArray,
            appointment: this.appointments,
            driverFeedbacks: this.driverFeedbacks,
            delivery: this.delivery,
            threadInfo: this.threadInfo,
            // Será preenchido na Chained
            isFeedback: false,
            isTypeFeedback: false,
            isSendableFeedback: false,
            billOptions: this.billOptions,
            coordinates: this.coordinates,
            citations: this.citations,
            e360Provider: this.e360Provider,
            rrCtrl: this.rrCtrl,
            idHandShake: this.idHandShake,
            interlocutor: this.interlocutor,
            sentCoordinates: this.sentLocation,
            isAttendantPersonal: this.isAttendantPersonalMessage,
            webChatConfig: this.webChatConfig,
            generatedByBotEvent: this.generatedByBotEvent,
            sentType: this.sentType,
            islandEventsId: this.islandEventsId,
        };

        if (this.isTimed() && this.personalGroupParticipant) {
            this.timedConfig.personalParticipant = this.personalGroupParticipant.toJSON();
        };
        if (this.personalResponse) {
            json.personalResponse = this.personalResponse;
        };

        return json;
    };

    public getInteractionParentArray(): TArrayIDJSON {
        return this.idInteractionParentArray;
    }


    public getTranscription(): string {
        return this.getSerializableText(constant.serializableField.transcription);
    };

    public hasTranscription(): boolean {
        return this.getTranscription() ? true : false;
    };

    public getCoordinates(): ILocationCoordinates {
        return this.coordinates;
    };

    public setCoordinates(coordinates: ILocationCoordinates): void {
        this.coordinates = coordinates;
    }

    public get360Providers(): TDeliveryTargets {
        return this.e360Provider;
    }

    public isFrom360(): boolean { return isValidArray(this.e360Provider) };
    public get360ProviderType(): EDelivery360Action { return this.isFrom360() ? this.e360Provider[0].providerType : null };

    public is360FromColmeia(): boolean {
        return !this.isFrom360() && this.getInteractionType().isReadInformationAvailable() && this.hasThreadType(EThreadType.socialCC)
    }

    public setAttendantPersonalMessage(): void {
        this.isAttendantPersonalMessage = true;
    }

    public hasAttPersonalOnTheChain(): boolean {
        const parents: TInteractionArray = this.getInteractionAncestry()
        console.log('hasAttPersonalOnTheChain:', { parentsIsAttendantPersonal: parents.map(p => p.isAttendantPersonal()) });

        return parents.some(i => i.isAttendantPersonal())
    }

    public setAttendentIfOnTheChain(): void {
        if (this.hasAttPersonalOnTheChain()) {
            this.setAttendantPersonalMessage();
        }
    }

    public isAttendantPersonal(): boolean {
        return this.isAttendantPersonalMessage
    }

    public getThreadInfo(): TThreadInfoArray { return this.threadInfo; };
    public hasThreadInfo(): boolean { return isValidArray(this.threadInfo); };

    public hasThreadType(thread: EThreadType): boolean {
        return this.hasThreadInfo() && this.threadInfo.some((x) => { return x.threadType === thread })
    };

    public rehydrate(json: IInteractionJSON): void {
        super.rehydrate(json);
        this.interactionType = InteractionType.staticFactory(json.idInteractionType);
        this.timedConfig = json.timedConfig;
        this.idInteractionParentArray = json.idInteractionParentArray ? json.idInteractionParentArray : [];
        this.appointments = json.appointment ? json.appointment : [];
        this.driverFeedbacks = json.driverFeedbacks;
        this.delivery = json.delivery;
        this.threadInfo = json.threadInfo;
        this.billOptions = json.billOptions;
        this.coordinates = json.coordinates;
        this.citations = json.citations;
        this.e360Provider = json.e360Provider;
        this.idHandShake = json.idHandShake;
        this.interlocutor = json.interlocutor;
        this.sentLocation = json.sentCoordinates;
        this.isAttendantPersonalMessage = json.isAttendantPersonal
        this.webChatConfig = json.webChatConfig;
        this.generatedByBotEvent = json.generatedByBotEvent;
        this.sentType = json.sentType;
        this.islandEventsId = json.islandEventsId;

        addPubGroupIntoInteraction(this.publishingGroups, json.publishingGroups[0]);

        if (json.rrCtrl) {
            if (!this.rrCtrl) {
                this.rrCtrl = json.rrCtrl;
            } else {
                Object.entries(json.rrCtrl).forEach(([idGroup, rrData]) => this.addRrCtrl(idGroup, rrData));
            }
        } else {
            json.rrCtrl = {};
        }

        if (json.personalResponse) {
            this.personalResponse = json.personalResponse;
        } else {
            this.personalResponse = [];
        }

        if (json.feedbackFigures) {
            for (const idGroup in json.feedbackFigures) {
                this.feedbackFigures[idGroup] = json.feedbackFigures[idGroup];
            };
        };

        if (!this.participant)
            this.participant = Participant.staticFactory(json.participant.primaryID)
    };



    public static factoryMessage(json: IInteractionJSON, participant: Participant = null): Interaction {
        let interaction: Interaction = <Interaction>UberCache.uberFactory(json.primaryID, constant.objectType.interaction, false);
        if (interaction == null)
            interaction = new Interaction(json.primaryID,
                participant ? participant : Participant.staticFactory(json.participant.primaryID),
                InteractionType.staticFactory(json.idInteractionType));
        interaction.rehydrate(json);
        return interaction;
    };

    public static staticFactory(idInteraction: TGlobalUID): Interaction {
        return <Interaction>UberCache.uberFactory(idInteraction, constant.objectType.interaction, true);
    };

    public static searchCachedInteraction(idInteraction: TGlobalUID): Interaction {
        return <Interaction>UberCache.uberFactory(idInteraction, constant.objectType.interaction, false);
    };

    public static getNewInteraction(participant: Participant, idInteractionType: InteractionType,
        message: string): Interaction {
        let interaction: Interaction = new Interaction(null, participant, idInteractionType);
        interaction.setMessage(message);
        return interaction;
    };

    // removes elements from tree
    public removeChain(idGroup: TGlobalUID): void {
        const tree = Interaction.getWholeInteractionChain(idGroup, this);
        for (let element of tree) {
            element.removeUberCache();
            if (element.isChainedOrTreeWithParent()) {
                let parent: Interaction = (<ChainedInteraction>element).getInteractionParent();
                parent && parent.removeChildrenInteraction(idGroup, element);
            };
        };
    };

    public isLocationInteraction(): boolean {
        return isValidRef(this.sentLocation);
    }

    public getParentEntityID(): TGlobalUID {
        return this.participant.getAvatar().getParentEntityID();
    };

    public static isTimedInteractionRegistrationOfGFutureEvent(interaction: IInteractionJSON): boolean {
        return Interaction.isTimedInteractionOfFutureEvent(interaction)
            && interaction.timedConfig.registering
    };

    public static isTimedInteractionOfFutureEvent(interaction: IInteractionJSON): boolean {
        return interaction && interaction.timedConfig
            && Interaction.willChangeGroup(interaction.timedConfig)
    };

    public static isRegistetingRememberMe(interaction: IInteractionJSON): boolean {
        return Interaction.isTimedInteractionRegistrationOfGFutureEvent(interaction) &&
            interaction.timedConfig.timedType == constant.timedType.rememberMe;
    }

    public isGroupingChanging(): boolean {
        return Interaction.willChangeGroup(this.timedConfig)
    };


    private static willChangeGroup(timed: ITimed): boolean {
        return timed && (timed.timedType == constant.timedType.sendAt || timed.timedType == constant.timedType.rememberMe);
    };

    public static isRememberMe(interaction: IInteractionJSON): boolean {
        return (interaction.timedConfig && interaction.timedConfig.timedType == constant.timedType.rememberMe);
    };

    public static isTimedRegisteringConfig(interaction: IInteractionJSON): boolean {
        return Interaction.isTimedConfig(interaction) && interaction.timedConfig.registering;
    }

    public getSentLocation(): ICoordinates {
        return this.sentLocation;
    }

    public static isTimedConfig(interaction: IInteractionJSON): boolean {
        return interaction && (interaction.timedConfig ? true : false);
    };

    // !Atenção, para ser usado no servidor apenas pois informações do player não vão para o cliente
    // !idParentEntity em caso de SPOKE será um grupo
    public static isGeneratedBySpokePerson(interaction: IInteractionJSON): boolean {
        return interaction.participant.avatar.relatedTo == constant.avatarRelatedTo.group;
    };

    public getWebChatConfig(): IWebChatConfig {
        return this.webChatConfig;
    }

    public setWebChatConfig(value: IWebChatConfig) {
        return this.webChatConfig = value;
    }

    public hasValidWebChatConfig(): boolean {
        return isValidObject(this.webChatConfig)
    }

    public getGeneratedByBotEvent(): EBotEventType | undefined {
        return this.generatedByBotEvent;
    }

    public isGeneratedByBotEvent(botEvent?: EBotEventType): boolean {
        if (!isValidRef(botEvent)) {
            return !!this.generatedByBotEvent;
        }

        return this.generatedByBotEvent === botEvent;
    }

    public getAvatarID(): string {
        return this.participant.getAvatar().getAvatarID();
    }

    public getSentType(): EInteractionSentType | undefined {
        return this.sentType;
    }

    public isCreatedAt(sentType?: EInteractionSentType): boolean {
        if (!isValidRef(sentType)) {
            return !!this.sentType;
        }

        return this.sentType === sentType;
    }


    public getIslandEventsId(): IdDep<ENonSerializableObjectType.customEvents> {
        return this.islandEventsId;
    }

    public setIslandEventsId(value: IdDep<ENonSerializableObjectType.customEvents>) {
        return this.islandEventsId = value;
    }

    public getLastChildInteraction() {
        return this.getChildren(this.getGroupID()).sort((intA, intB) => intB.getClockTick() - intA.getClockTick())[0];
    }
};
