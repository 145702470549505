import { IJobServer } from "@colmeia/core/src/shared-business-rules/jobs/jobs-model";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";
import { boolean } from "joi";
import { $ValueOf } from "../../tools/utility-types";
import { DefineDiscriminatedMap, Explicit, IntersectPartialExplicit } from "../../tools/utility/types";
import { TAppColorThemes, TButtonAlertConfig } from "../../ui/ui.model";
import { TBroadcastIslandControl } from "../attendent-service-pack/attendent-sp-req-resp";
import { TGlobalUID } from "../../core-constants/types";
import { TCRMTicketNotificationContent } from "../crm/crm-notification.model";

export enum ENotificationPayload {
    job = 'job',
    renameTemporaryAvatar = 'rnmTempAvatar',
    customerExpireWarn = 'cusExWar',
    customerExpireClose = 'cusExClo',
    sendOpenCasesBroadcast = 'sendOpen',
    broadcastAllocationStatus = 'broadAllStatus',
    customerTransferedBySupervisor = 'cusTransSupVisor',
    genericPromptMessage = 'genericDialogConfirmation',
    statusChangedBySupervision = 'statusChangedBySupervision',
    supervisionBroadcastMessage = 'supervisionBroadcastMessage',
    adminSolicitationTaskApproval = 'adminSolicitationTaskApproval',
    formSolicitationComplete = 'formSolicitationComplete',
    agentExpire = 'agentExpire',
    attServiceFinish = 'attServiceFinish',
    crmTicket = 'crmTicket',
}

export type MapNotificationTypes = Explicit<
    { [Type in ENotificationPayload]: IntersectPartialExplicit<IBasicNotificationPayload, { type: Type }> },
    {
        [ENotificationPayload.job]: INotificationJobData;
        [ENotificationPayload.renameTemporaryAvatar]: INotificationWebchatRenameTemporaryAvatar;
        [ENotificationPayload.customerExpireWarn]: INotificationCustomerExpireWarn;
        [ENotificationPayload.customerExpireClose]: INotificationCustomerExpireClose;
        [ENotificationPayload.sendOpenCasesBroadcast]: INotificationSendOpenCasesBroadcast;
        [ENotificationPayload.broadcastAllocationStatus]: INotificationBroadcastAllocationStatus;
        [ENotificationPayload.customerTransferedBySupervisor]: INotificationCustomerTransferedBySupervisor;
        [ENotificationPayload.genericPromptMessage]: INotificationGenericPromptMessage;
        [ENotificationPayload.statusChangedBySupervision]: INotificationStatusChangeBySupervision;
        [ENotificationPayload.supervisionBroadcastMessage]: INotificationSupervisionBroadcastMessage;
        [ENotificationPayload.adminSolicitationTaskApproval]: INotificationAdminSolicitationApprovalMessage;
        [ENotificationPayload.formSolicitationComplete]: INotificationFormSolicitationMessage;
        [ENotificationPayload.agentExpire]: INotificationAgentExpire;
        [ENotificationPayload.attServiceFinish]: INotificationAttServiceFinish;
        [ENotificationPayload.crmTicket]: INotificationCRMTicket;
    }
>


export type TNotificationPayload = $ValueOf<MapNotificationTypes>


export type TNotificationPromptMatIconConfig = {
    icon: string,
    color: string
}

export enum ENotificationPromptReadConfirmationType {
    Sum = 'sum',
    Text = 'text',
}

export interface INotificationPromptReadConfirmation {
    type: ENotificationPromptReadConfirmationType;
    /**
     * Ultilizando apenas com o tipo seja "Text"
     */
    text?: string;
}

export interface INotificationPromptMessage {
    title: string,
    message: string,
    matIcon?: TNotificationPromptMatIconConfig;
    readConfirmation?: INotificationPromptReadConfirmation;
}

export interface INotificationMessageContainer {
    message?: string;
}

export interface IBasicNotificationPayload extends INotificationMessageContainer {
    type: ENotificationPayload
    createdAt: number;
    idNotification?: string;  // idNser
    supportingMessage?: string;
    forceAsNew?: boolean;
    promptMessage?: INotificationPromptMessage;
    theme?: TAppColorThemes;
}

export interface IBasicNotificationPayloadWithPromptMessage extends IBasicNotificationPayload {
    promptMessage: INotificationPromptMessage;
}

export enum EJobPayloadType {
    Text = 'Text',
    Start = 'Start',
    Finish = 'Finish',
    UpdatePercentage = 'UpdatePercentage',
    Update = 'Update',
    Failed = 'Failed'
}

export interface INotificationJobDataStatus extends INotificationJobData {
    type: ENotificationPayload.job;
    jobPayloadType: EJobPayloadType;
    idJob: string;
}


export interface INotificationJobDataFinished extends INotificationJobDataStatus {
    jobPayloadType: EJobPayloadType.Finish;
}

export interface INotificationJobDataStarted extends INotificationJobDataStatus {
    jobPayloadType: EJobPayloadType.Start;
}

export interface INotificationJobDataFailure extends INotificationJobDataStatus {
    jobPayloadType: EJobPayloadType.Failed;
}
export interface INotificationJobDataUpdatePercentage extends INotificationJobDataStatus {
    jobPayloadType: EJobPayloadType.UpdatePercentage;
    percentComplete: number;
}
export interface INotificationJobDataUpdate extends INotificationJobDataStatus {
    jobPayloadType: EJobPayloadType.Update;
    job: IJobServer;
}


export type TJobNotificationData = $ValueOf<MapJobNotification>;

export type MapJobNotification = DefineDiscriminatedMap<INotificationJobData, 'jobPayloadType', {
    [EJobPayloadType.Finish]: INotificationJobDataFinished;
    [EJobPayloadType.Text]: INotificationJobDataText;
    [EJobPayloadType.Start]: INotificationJobDataStarted;
    [EJobPayloadType.Failed]: INotificationJobDataFailure;
    [EJobPayloadType.UpdatePercentage]: INotificationJobDataUpdatePercentage;
    [EJobPayloadType.Update]: INotificationJobDataUpdate;
}>;


export interface INotificationJobDataText extends IBasicNotificationPayload {
    type: ENotificationPayload.job;
    jobPayloadType: EJobPayloadType.Text;
    message: string;
}

export interface INotificationJobData extends IBasicNotificationPayload {
    type: ENotificationPayload.job;
    jobPayloadType?: EJobPayloadType;
    message: string;
    idJob?: string;
    idNSTarget?: IdDep<ENonSerializableObjectType>;
}

export interface INotificationWebchatRenameTemporaryAvatar extends IBasicNotificationPayload {
    type: ENotificationPayload.renameTemporaryAvatar;
    newName: string;
}

export interface INotificationCustomerExpire extends INotificationMessageContainer {
    type: ENotificationPayload;
    customerName: string;
    idAvatarCustomer: string;
    idServiceGroup: string;
    attempts: number;
}

export interface INotificationCustomerExpireWarn extends IBasicNotificationPayload, INotificationCustomerExpire {
    type: ENotificationPayload.customerExpireWarn;
}
export interface INotificationCustomerExpireClose extends IBasicNotificationPayload, INotificationCustomerExpire {
    type: ENotificationPayload.customerExpireClose;
}

// 



export interface INotificationSendOpenCasesBroadcast extends IBasicNotificationPayload {
    type: ENotificationPayload.sendOpenCasesBroadcast;
    content: TBroadcastIslandControl;
}


export interface INotificationBroadcastAllocationStatus extends IBasicNotificationPayload {
    type: ENotificationPayload.broadcastAllocationStatus;
    content: IStatusAllocation;
}

export interface ITransferedBySupervisorPayloadContent {
    idConversation: string;
}

export interface INotificationCustomerTransferedBySupervisor extends IBasicNotificationPayloadWithPromptMessage {
    type: ENotificationPayload.customerTransferedBySupervisor;
    content: ITransferedBySupervisorPayloadContent;
}

export interface INotificationGenericPromptMessage extends IBasicNotificationPayloadWithPromptMessage {
    type: ENotificationPayload.genericPromptMessage;
}

export interface INotificationStatusChangeBySupervision extends IBasicNotificationPayloadWithPromptMessage {
    type: ENotificationPayload.statusChangedBySupervision;
}

export interface INotificationSupervisionBroadcastMessage extends IBasicNotificationPayloadWithPromptMessage {
    type: ENotificationPayload.supervisionBroadcastMessage;

}
export interface INotificationAdminSolicitationApprovalMessage extends IBasicNotificationPayload {
    type: ENotificationPayload.adminSolicitationTaskApproval;

}

export interface INotificationFormSolicitationMessage extends IBasicNotificationPayload {
    type: ENotificationPayload.formSolicitationComplete;

}

export interface INotificationAgentExpire extends IBasicNotificationPayloadWithPromptMessage {
    type: ENotificationPayload.agentExpire;
}

export interface INotificationAttServiceFinish extends IBasicNotificationPayloadWithPromptMessage {
    type: ENotificationPayload.attServiceFinish;
}


export interface INotificationCRMTicket extends IBasicNotificationPayload {
    type: ENotificationPayload.crmTicket;
    content: TCRMTicketNotificationContent;
}

export enum EBroadcastContestStage {
    Init = 'init',
    Current = 'current',
    PreFinish = 'pre-finish',
    Finish = 'finish'
}

export type TBroadcastHighlightedIslands = {
    [idIsland in string]: {
        theme: TAppColorThemes;
        matIcon?: string;
    }
}

export type TBroadcastHighlightedIslandsArray = Array<TBroadcastHighlightedIslands>;

export type TBroadcastControlButton = TButtonAlertConfig & { releaseButton?: boolean; }

export interface IStatusAllocation {
    contestStage: EBroadcastContestStage,
    current?: TBroadcastIslandControl
    buttonControl?: TBroadcastControlButton;
    highlighEreaseTimeout?: number;
    ereaseHighLight?: boolean;
    highlightedIslands?: TBroadcastHighlightedIslands

    idConversation?: string;
    idISland?: string;
}