import { EGeneratorTransactionType, INLPTransactionServer } from "@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction";
import { IBasicAsset, KBAssetType, KAssetTypeClient, KBAssetTypeClientOnly, KAssetType } from "@colmeia/core/src/shared-business-rules/bot/bot-asset-model";
import { EVarEditorEntityType, IFormatVisibility, TVarEditorVariables } from "app/handlers/var-editor.handler";
import { EBotContentEvent, IContentBasicAsset, IContentEmailHTMLTemplate } from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { getVariablesOfTemplate, prepareWhatsAppContentAsset } from "@colmeia/core/src/shared-business-rules/metadata/metadata-utils";
import { TConditionsEditorTargetsList } from "app/components/dashboard/new-condition-editor/conditions-editor/conditions-editor.model";
import { validateTemplateMedia } from "@colmeia/core/src/shared-business-rules/social-media/template.functions";
import { isValidMedia } from "@colmeia/core/src/rules/mm-functions";
import { IMLLuisIntent } from '@colmeia/core/src/shared-business-rules/knowledge-base/luis-core-interfaces';
import { EMimeTypes, TMimeTypeArray, onlyDocumentAllowed, onlyImageAllowed, onlyStickerAllowed, onlyVideoAllowed } from "@colmeia/core/src/multi-media/file-interfaces";
import { ESourceOfInfo } from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";

interface IBaseAssetAdder<Asset extends IBasicAsset = IBasicAsset> {
	onChangeAssetsPositions?(assets: Asset[]): void;
	saveAsset(newAsset: Asset, assets: Asset[], isEdit?: boolean): Promise<boolean>;
	removeAsset(index: number, assets: Asset[]): Promise<boolean>;
}


export class AssetAdderProcessor {
	public static onRemove(onRemove: () => void) {
		return async function execute(index: number, assets: IContentBasicAsset[]) {
			const out = await removeAsset(index, assets);
			onRemove();
			return out;
		}

		async function removeAsset(index: number, assets: IContentBasicAsset[]) {
			assets.splice(index, 1);
			return true;
		}
	}

	public static onSaveWhatsApp(onSave: (asset: IContentBasicAsset) => void) {


		return AssetAdderProcessor.onSave(execute);

		function execute(asset: IContentBasicAsset) {
			prepareWhatsAppContentAsset(asset);
			return onSave(asset);
		}

	}

	public static onSave(onSave: (asset: IContentBasicAsset) => void) {
		return async function execute(asset: IContentBasicAsset, assets: IContentBasicAsset[], isEdit?: boolean) {
			if (asset?.type === KBAssetType.media && isValidMedia(asset.media)) {
				validateTemplateMedia(asset.media);
			}

			const out = await saveAsset(asset, assets, isEdit);
			onSave(asset);
			return out;
		}

		async function saveAsset(asset: IContentBasicAsset, assets: IContentBasicAsset[], isEdit?: boolean): Promise<boolean> {
			const found = assets.findIndex(item => item.idAsset === asset.idAsset);

			if (found !== -1) {
				assets.splice(found, 1, asset);
				return true;
			}

			assets.push(asset);
			return true;
		}
	}

	static create({
		title,
		onSave,
		onRemove,
		target,
		limitCharacters = 60,
		limitRows,
		isText = true,
		isDisabled,
		shouldAskMediaDisplayName,
		variables,
	}: IInitAssetAdderHandler) {
		const value = target();

		const handler: IAssetAdderHandler<IContentBasicAsset> = {
			title,
			assets: value ? [value] : [],
			limitRows,
			limitCharacters,
			shouldAskMediaDisplayName,
			maxAssets: 1,
			assetTypesEnabled: [isText ? KBAssetType.content : KBAssetType.media],
			disableFallback: true,
			removeAsset: AssetAdderProcessor.onRemove(onRemove),
			saveAsset: AssetAdderProcessor.onSaveWhatsApp(onSave),
			variables,
			isDisabled,
			shouldHideBBCode: true,
			shouldHideEmojis: true,
			shouldHideBottomOptions: true,
		}

		return handler;
	}

	public static computeAmountCharactersWithoutVariables(rawText: string): string {
		const variables = getVariablesOfTemplate(rawText);
		let trimmedText = rawText;

		variables.forEach(variable => {
			trimmedText = trimmedText.split(variable).join('');
		});
		
		return trimmedText;
	}
}

interface IInitAssetAdderHandler {
	title: string;
	onSave: (asset: IContentBasicAsset) => void;
	onRemove: () => void;
	target: () => IContentBasicAsset | undefined;
	isText?: boolean;
	isDisabled?: boolean;
	limitCharacters?: number;
	isUsingVariables?: boolean;
	limitRows?: number;
	variables?: TVarEditorVariables;
	shouldAskMediaDisplayName?: boolean;
}


export interface IAssetAdderHandler<Asset extends IBasicAsset = IBasicAsset> extends IBaseAssetAdder<Asset>, IFormatVisibility {
	title?: string;
	assets: Asset[];
	assetTypesEnabled: Array<KAssetTypeClient>;
	botContentEventTypesSelectionPerMessage?: Array<EBotContentEvent>,
	canRepeatBotContentEventTypes?: boolean;
	maxAssets?: number;
	onlyText?: true;
	slave?: IAssetAdderSlave;
	enableChangePositions?: boolean;

	disableFallback?: true;
	varEditorMode?: EVarEditorEntityType;
	variables?: TVarEditorVariables;
	shouldAskMediaDisplayName?: boolean;

	shouldEnableEmailHTMLTemplate?: () => boolean;
	emailHTMLTemplate?: IContentEmailHTMLTemplate;




	removeEdit?: boolean;
	removeDelete?: boolean;
	removeDrag?: boolean;


	removeClose?: boolean;
	removeAdd?: boolean;
	showConditionalDisplay?: boolean;
	autoOpenIfEmpty?: boolean;
	alloweedGrouping?: boolean; // :-|
	isEditOnly?: boolean;
	isDisabled?: boolean;

	additionalSourceInfo?: ESourceOfInfo[];
	additionalConditionsTargets?: TConditionsEditorTargetsList;

    intent?: IMLLuisIntent;
    preSelectedValue?: KAssetType;
	nsPickerSelectedProperty?: EGeneratorTransactionType;
}


interface IAssetAdderSlave {
	dispatchSaveAsset(): void;
}

export interface IIntentAssetAdderHandler extends IBaseAssetAdder {
	transaction: INLPTransactionServer;
	variables?: TVarEditorVariables;
	softSync?: () => void;
    expandedIntentsIDs: Set<string>;
    preSelectedValue?: KAssetType;
    nsPickerSelectedProperty?: EGeneratorTransactionType
}

export enum EMediaAssetType {
	image = 'image',
	video = 'video',
	document = 'document',
	sticker = 'sticker',
	other = 'other'
}

/** não está sendo usado */
export const defaultMediaAssetTypes: EMediaAssetType[] = [
	EMediaAssetType.image,
	EMediaAssetType.video,
	EMediaAssetType.document,
	EMediaAssetType.other
];

export const mediaAssetTypeAllowedMimeMap: Record<EMediaAssetType, TMimeTypeArray> = {
	[EMediaAssetType.image]: onlyImageAllowed,
	[EMediaAssetType.video]: onlyVideoAllowed,
	[EMediaAssetType.document]: onlyDocumentAllowed,
	[EMediaAssetType.sticker]: onlyStickerAllowed,
	[EMediaAssetType.other]: []
};

export function getAssetInstanceTypeClient(asset: IContentBasicAsset): KAssetTypeClient {
	if (
		isValidMedia(asset.media)
		&& asset.media.mymeType === EMimeTypes.Sticker
	) {
		return KBAssetTypeClientOnly.sticker;
	}

	return asset.type;
}
