import { ENonSerializableObjectType, TENonSerializableObjectArray } from "../non-serializable-id/non-serializable-id-interfaces";
import { ICRMStatusNames, ICRMStep, ICRMTicketDataBasic } from "./crm-entities";
import { ECRMCanonicalItemsTypes } from "./crm-services/crm-canonical-model";

export function isCrmNSType(nsType: ENonSerializableObjectType): boolean {
    return nsType.substring(0,3) === 'crm';
}

export function getItemStatusIdByType(ticketData: ICRMTicketDataBasic, type: ECRMCanonicalItemsTypes): string {
    switch (type) {
        case ECRMCanonicalItemsTypes.CloseState:
            return ticketData.currentStatus.idCloseState;
        case ECRMCanonicalItemsTypes.Phase:
            return ticketData.currentStatus.idPhase;
        case ECRMCanonicalItemsTypes.Severity:
            return ticketData.currentStatus.idSeverity;
        case ECRMCanonicalItemsTypes.SupportLevel:
            return ticketData.currentStatus.idSupportLevel;
        case ECRMCanonicalItemsTypes.TicketState:
            return ticketData.currentStatus.idTicketState;
        case ECRMCanonicalItemsTypes.UrgencyLevel:
            return ticketData.currentStatus.idUrgencyLevel;
    }
}

const itemTypeToIdKey: Record<ECRMCanonicalItemsTypes, keyof ICRMStep> = {
    [ECRMCanonicalItemsTypes.Severity]: "idSeverity",
    [ECRMCanonicalItemsTypes.UrgencyLevel]: "idUrgencyLevel",
    [ECRMCanonicalItemsTypes.SupportLevel]: "idSupportLevel",
    [ECRMCanonicalItemsTypes.Phase]: "idPhase",
    [ECRMCanonicalItemsTypes.TicketState]: "idTicketState",
    [ECRMCanonicalItemsTypes.CloseState]: "idCloseState"
};

export function getItemStatusKeyByType(type: ECRMCanonicalItemsTypes): string {
    return itemTypeToIdKey[type];
}


const itemTypeToIdNameKey: Record<ECRMCanonicalItemsTypes, keyof ICRMStatusNames> = {
    [ECRMCanonicalItemsTypes.Severity]: "severityName",
    [ECRMCanonicalItemsTypes.UrgencyLevel]: "urgencyLevelName",
    [ECRMCanonicalItemsTypes.SupportLevel]: "supportLevelName",
    [ECRMCanonicalItemsTypes.Phase]: "phaseName",
    [ECRMCanonicalItemsTypes.TicketState]: "ticketStateName",
    [ECRMCanonicalItemsTypes.CloseState]: "closeStateName",
};

export function getItemStatusNameKeyByType(type: ECRMCanonicalItemsTypes): string {
    return itemTypeToIdNameKey[type];
}