import { AfterNarrow } from "../../../tools/utility-types";
import { IRequestTypeConfig } from "./request-type-config.types";


export type DefinedBasicRequestTypeConfig = { [key in string]: IRequestTypeConfig };

export function defineBasicRequestTypeConfig<Source extends DefinedBasicRequestTypeConfig>(
    source:
        & Source
        // Keep the nesting reference
        & AfterNarrow<{ [key in keyof Source]: IRequestTypeConfig }>
    ): Source {
    return source;
}
