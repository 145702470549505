import { INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { assertNonNullable } from "@colmeia/core/src/tools/utility/functions/assertNonNullable";
import { nullishSet } from '@colmeia/core/src/tools/utility/functions/nullish-set';
import { Merger } from "@colmeia/core/src/tools/utility/types/entities/merger";
import { $Extends, $NarrowExtends, $NarrowV2, $NarrowV3, ItNarrowable, ToolbeltPure } from "@colmeia/core/src/tools/utility/types/validate";
import * as _ from 'lodash';
import { IScreenOptions } from "../shared-business-rules/const-text/atext-types";
import { IdDep } from "../shared-business-rules/non-serializable-id/non-serializable-types";
import { asserts, createTypeGuard, EImplementAccessModifier, empty, EnumObject, EnumValue, ETypeOf, fakeAssertsGuard, getTypedProperty, IdField, isKeysIn, recursiveKeys, typeOf } from "./utility";
import { resetPattern } from "./utility/functions/resetPattern";
import { Unsafe } from "./utility/functions/unsafe";
import { Explicit, IntersectPartialExplicit } from "./utility/types";
import { StringNumber } from "./utility/types/basic";
import { $$ } from "./utility/types/error";
import { Custom } from "./utility/types/generic";
import { IsObject } from "./utility/types/intersection";
import { CCustomType } from "@colmeia/core/src/core-constants/named-types";

const lodash = require('lodash');


export type ExcludeTypeKey<K> = K extends "type" ? never : K
export type ExcludeTypeField<A> = { [K in ExcludeTypeKey<keyof A>]: A[K] }
export type ExtractCorrectParams<A, T> = A extends { ["type"]: T }
    ? ExcludeTypeField<A>
    : never
;

export type TErrorList = string[]

export type ExtractPossibleTypes<T> = 
    T extends string ? string : 
    T extends object ? object : 
    T extends number ? number : 
    T extends undefined ? undefined : 
never;
export type AnyToNever<T> = IsAny<T> extends true ? never : T;
export type AnyOrUnknownTo<T, To> = IsAny<T> extends true ? To : (IsUnknown<T> extends true ? To : T);
export type AnyToUnknown<T> = IsAny<T> extends true ? unknown : T;
export type IsAny<T> = unknown extends (T extends never ? unknown : never) ? true : false;
export type IsUnknown<T> = IsAny<T> extends false ? unknown extends T ? true : false : false;
export type IsNever<T> = (T extends never ? T : unknown) extends never ? true : false;
export type IsUndefined<T> = CheckNullish<T, undefined>;
export type IsNull<T> = CheckNullish<T, null>;
export type IsVoid<T> = CheckNullish<T, void>;

export type IsEmptyV2<T> =
  CheckExpression<
    | IsNever<T>
    | IsAny<T>
    | IsUnknown<T>
    | IsUndefined<T>
    | IsNull<T>
    | IsVoid<T>
  >
;

type CheckNullish<T, Predicate, Computed = IsMatchBoth<T, Predicate> extends true ? Negate<IsAny<T>> : false> = Computed;
export type IsMatchBoth<T, Predicate, Computed = [T] extends [Predicate] ? [Predicate] extends [T] ? true : false : false> = Computed;

export type GetGenericV2<T> = 
    T extends object ? { [key in keyof T]: GetGenericV2<T[key]> } :
    | AssertToE<T, string>
    | AssertToE<T, number>
    | AssertToE<T, boolean>
    | AssertToE<T, symbol>
;

declare namespace Type {
    namespace Array {
        type Find<List extends unknown[], Predicate, Current extends unknown[] = List> =
            Current extends [infer Item, ...infer Items] 
            ? CheckExpression<IsEqual<Item, Predicate> & (Negate<IsUnknown<Item>> | IsUnknown<Predicate>)> extends true
            ? [Item, true]
            : Find<List, Predicate, Items> 
            : [never, false]
        ;
    }
}

export type FindEmpty<T, Found = Type.Array.Find<
    [
        never,
        any,
        unknown,
        undefined,
        null,
        void,
    ],
    T
>> = Found;

type AssertToE<T, E> = T extends E ? E : never


// 

export type NotNever<T, Check = (IsNever<T> extends true ? NotNever<T> : unknown)> = IsNever<T> extends true ? $$<'Not never'> : T;

export type GetGeneric<T> = 
    NormalizeAny<T> extends never ? any :
    T extends string ? string : 
    T extends number ? number : 
    T extends boolean ? boolean : 
    T extends object ? object : 
never;
type NormalizeAny<T> = any extends T ? never : T;



export type IsEqual<A, B> = 
    (<T>(source: T) => T extends A ? true : false) extends (<T>(source: T) => T extends B ? true : false) ? true : false
;

export type SpecialTypeName<T> =
    IsEqual<T, undefined> extends true ? 'undefined' : 
    IsEqual<T, null> extends true ? 'null' : 
    IsEqual<T, never> extends true ? 'never' : 
    IsEqual<T, any> extends true ? 'any' : 
    IsEqual<T, unknown> extends true ? 'unknown' : 
    IsEqual<T, void> extends true ? 'void' : 
    never
;
export type SpecialTypes = [undefined, never, any, unknown, null, void];


export type IsSpecialType<T, TSpecialTypes extends any[] = SpecialTypes> = 
    IsEqual<{ [key in keyof TSpecialTypes]: IsEqual<T, TSpecialTypes[key]> extends true ? true : never }[number], true> extends true ? true : false
;

export type IsEmpty<T> = IsSpecialType<T>;

export type CheckIfIsInEnum<Enum, OtherValues> =
    OtherValues extends Enum ? unknown : $$<'Value is not in enum'>
;
export type CheckIfIsSpecialType<Value> =
    IsSpecialType<Value> extends true ? $$<'Value is empty'> : unknown
;


export type IsKeyIn<T, Key> = 
    T extends [...infer V] ?
        IsEqual<V['length'], number> extends true ? 
            Key extends number | `${number}` ? true : false
        : IsEqual<Extract<keyof T, Key>, never> extends true ? false : true
    : IsEqual<Extract<keyof T, Key>, never> extends true ? false : true
;

// export type IsKeyIn<T, Key> =
//     T extends unknown ?
//         IsEqual<Extract<keyof T, Key>, never> extends true ? false : true
//     : never
// ;


export type Negate<T> = [T] extends [false] ? true : false;


export enum EEnum {}


interface IMapSimpleLiteralType {
    Type: any;
    Literal: string;
    TypeOf: ETypeOf;
}
type MapSimpleLiteralType = Explicit<IMapSimpleLiteralType[], [
    { Type: string; Literal: 'string' ; TypeOf: ETypeOf.String },
    { Type: number; Literal: 'number' ; TypeOf: ETypeOf.Number },
    { Type: boolean; Literal: 'boolean' ; TypeOf: ETypeOf.Boolean },
    { Type: bigint; Literal: 'bigint' ; TypeOf: ETypeOf.BigInt },
    { Type: symbol; Literal: 'symbol' ; TypeOf: ETypeOf.Symbol },
    { Type: undefined; Literal: 'undefined' ; TypeOf: ETypeOf.Undefined },
    { Type: Function; Literal: 'function' ; TypeOf: ETypeOf.Function },
    { Type: Array<any>; Literal: 'array'; TypeOf: ETypeOf.Array },
    { Type: object; Literal: 'object' ; TypeOf: ETypeOf.Object },
]>;
export type FromSimpleLiteralTypeName<T extends AllSimpleLiteralTypeNames> = T extends unknown ? { [key in keyof MapSimpleLiteralType]: MapSimpleLiteralType[key] extends infer MappedType ? MappedType extends IMapSimpleLiteralType ? T extends MappedType['Literal'] ? MappedType['Type'] : never : never : never }[number] : never;
export type FromSimpleTypeOfName<T extends ETypeOf> = T extends unknown ? { [key in keyof MapSimpleLiteralType]: MapSimpleLiteralType[key] extends infer MappedType ? MappedType extends IMapSimpleLiteralType ? T extends MappedType['TypeOf'] ? MappedType['Type'] : never : never : never }[number] : never;
export type ToTypeOf<T> = T extends unknown ? { [key in keyof MapSimpleLiteralType]: MapSimpleLiteralType[key] extends infer MappedType ? MappedType extends IMapSimpleLiteralType ? T extends MappedType['Type'] ? MappedType['TypeOf'] : never : never : never }[number] : never;


export type ToSimpleLiteralTypeName<T> = 
    T extends string ? 'string' : 
    T extends number ? 'number' : 
    T extends boolean ? 'boolean' : 
    T extends bigint ? 'bigint' : 
    T extends symbol ? 'symbol' : 
    T extends undefined ? 'undefined' : 
    T extends Function ? 'function' : 
    T extends Array<any> ? 'array': 
    T extends object ? 'object' : 
never;
export type AllSimpleLiteralTypeNames = ToSimpleLiteralTypeName<any>;

export type ToNativeTypeOf<T> = 
    T extends string ? 'string' : 
    T extends number ? 'number' : 
    T extends boolean ? 'boolean' : 
    T extends bigint ? 'bigint' : 
    T extends symbol ? 'symbol' : 
    T extends undefined ? 'undefined' : 
    T extends Function ? 'function' : 
    T extends object ? 'object' : 
never;


 
export type AsyncReturnType<T extends TFunction> = 
    ReturnType<T> extends Promise<infer Return> ? Return : ReturnType<T>
;
export type AsyncType<T> = T extends Promise<infer V> ? V : T;


export type ToLiteralTypeName<T> = 
    T extends string ? 'string' : 
    T extends number ? 'number' : 
    T extends boolean ? 'boolean' : 
    T extends Array<infer Element> ?
        Element extends object ? 'array-object' :
        Element extends string ? 'array-string' : 
        Element extends number ? 'array-number' : 
        Element extends boolean ? 'array-boolean' :
        'array'
    : 
    T extends object ? 'object' : 
never;

/*
 * Know if a value is specific or generic.
 * IsSpecific<number> => false
 * IsSpecific<string> => false
 * IsSpecific<boolean> => false
 * IsSpecific<object> => false
 * 
 * IsSpecific<any> => false
 * 
 * IsSpecific<3> => true
 * IsSpecific<'string'> => true
 * IsSpecific<false> => true
 * IsSpecific<true> => true
 * IsSpecific<{ name: 'Test' }> => true
*/
export type IsSpecific<T> = GetGeneric<T> extends T ? false : true;
//


/**
 * Accepts literal only values on overload 
 * @example
 *     declare function execute<T extends string & SpecificOverload<T>>(mime: T): 'literal'
 *     declare function execute(mime: string): 'generic'
 *
 * @example execute('') // 'literal'
 * @example execute('' as string) // 'generic'
 */
export type SpecificOverload<T> = [IsSpecific<T>] extends [true] ? unknown : IsAny<T> extends true ? T : never;
export type AllowOverload<T, E> = [T] extends [E] ? unknown : never;

export type IsHash<T> = IsSpecific<keyof T> extends true ? false : true;

export type CustomType<T, Name> = T & {
    _____TYPE_____?: Name;
};


// Classic
export type ValueOf<T> = T[keyof T];

export type DeepValueOf<T> = ValueOf<{
    [key in keyof T]: T[key] extends object ? DeepValueOf<T[key]> : T[key]
}>;

export type DeepValueOfV2<T, Done = never> = ValueOf<{
    [key in keyof T]: T[key] extends object ? DeepValueOfV2<Exclude<T[key], Done>, Done | T[key]> : T[key]
}>;


export type DeepValueOfAndGetClass<T> = ValueOf<{
    [key in keyof T]: T[key] extends object ? T[key] extends { new(...params) } ? T[key] : DeepValueOf<T[key]> : T[key]
}>;

// Internal helper function
export type MapKeysWithValueOf<T, E> = { [key in keyof T]: T[key] extends E ? key : never }

/*
 * FindKeysWithValueOf<T, E>
 * FindKeysWithValueOf<{ a: number, b: number, c: string }, number> => 'a' | 'b'
*/
export type FindKeysWithValueOf<T, E> = ValueOf<MapKeysWithValueOf<T, E>>

export interface IFilterOptions {
    IsNegating: boolean,
    IsIndex: boolean
}

// export type Filter<Items extends any[], Predicate, Options extends IFilterOptions = { IsIndex: false; IsNegating: false }, Negate extends boolean = Options['IsNegating'] extends true ? true : false> =
//     { [key in keyof Items]: (Items[key] extends Predicate ? true : false) extends Negate ? never : Options['IsIndex'] extends true ? key : Items[key] }[number]
// ;
// export type Split<T extends string, Delimiter extends string, Current extends string[] = []> = 
//     T extends '' ? Current :
//     T extends `${infer Value}${Delimiter}${infer OtherValue}` ? Split<OtherValue, Delimiter, [...Current, Value]>  : 
//     [...Current, T]
// ;



/*
 * PickByValueType<T, E>
 * PickByValueType<{ a: number, b: number, c: string }, number> => { a: number, b: number }
 * PickByValueType<{ a: number, b: number, c: string }, number> => { c: string }
*/
export type PickByValueType<T, E> = Pick<T, FindKeysWithValueOf<T, E>>


export type OmitByValueType<T, E> = Pick<T, Exclude<keyof T, FindKeysWithValueOf<T, E>>>

// export type UnionRange<T extends StringNumber, Sum extends StringNumber = '0'> = Assert<Tuple.Range<T, Sum>, any[]>[number];

export type TFunction = (...params: any[]) => any;

export type DefineReadableType<Config, Type extends Config> = Type;
export type ReverseEnum<T extends { [key in any]: any }> = { [key in ValueOf<T>]: FindKeysWithValueOf<T, key> };
export type CommonKeys<T extends object> = keyof T;

export type Replace<R extends any[], T> = { [K in keyof R]: T }

export type PickMakeRequiredAndIntersect<T, K extends keyof T> = T & Required<Pick<T, K>>;
export type RequiredPick<T, K extends keyof T> = Required<Pick<T, K>>;
export type PartialPick<T, K extends keyof T> = Partial<Pick<T, K>>;
export type PartialKeys<T, Keys extends keyof T = keyof T> = Compute<
    & Omit<T, Keys>
    & PartialPick<T, Keys>
>;

export type PreserveOnlyPartialProperties<T> = ReMapObject<{ [key in keyof T]: ([{ [$key in key]?: T[key] }] extends [{ [$key in key]: T[key] }] ? { [$key in key]: T[key] } : never) }>

/*
 * Same as DeepFindValuesOfType, but for values and recursive.
 * DeepFindValuesOfType<{ a: 'A', b: number, c: 'C' }, string> => 'A' | 'C'
 * DeepFindValuesOfType<{ a: 'A', b: number, c: { foo: { bar: 'bar' } } }, { bar: string }> => { bar: 'bar' }
 * DeepFindValuesOfType<{ a: 'A', b: number, c: { d: 'D', e: 5, f: 'F' } }, string> => 'A' | 'D' | 'F'
*/
export type DeepFindValuesOfType<T, E> = ValueOf<{
    [key in keyof T]:
        T[key] extends E ? T[key] :
        T[key] extends object ? DeepFindValuesOfType<T[key], E> :
    never;
}>;
export type FindValuesOfType<T, E> = ValueOf<{
    [key in keyof T]:
        T[key] extends E ? T[key] :
    never;
}>;

export type DeepFindValuesOfTypeV2<T, E> = Custom.$Utility.ValueOf<{
    [key in keyof T]:
        T[key] extends E ? T[key] :
        T[key] extends object ? DeepFindValuesOfTypeV2<T[key], E> :
    never;
}>;

export type Nullishs = [null, undefined];
export type NonNullablePrimitives = [string, number, symbol, boolean];
export type Primitives = [...NonNullablePrimitives, ...Nullishs]
export type Primitive = Primitives[number];


export namespace Omit {
    export type Safe<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
    export type Unsafe<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SafeOmit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UnsafeOmit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

export type TGenericTypeGuardVerifier = (value) => boolean;


// Credits to KPD
export type RequireOnlyOneKeys<T, Keys extends keyof T = keyof T> =
    Pick<T, Exclude<keyof T, Keys>>
    & {
        [K in Keys]-?:
            Required<Pick<T, K>>
            & Partial<Record<Exclude<Keys, K>, undefined>>
}[Keys];


type DefaultArrayKeys = keyof [];
// type GetArrayIndexes<T> = Exclude<keyof T, DefaultArrayKeys>;
export type RequireOnlyOne<T extends object, OtherT = T> = 
    T extends unknown ?
        keyof T extends unknown ?
            Compute<{ [key in keyof T]: T[key] } & { [key in Exclude<KeyOf<OtherT>, keyof T>]?: $$<'Invalid property'> }>
        : never
    : never
;
export type OldRequireOnlyOne<T> = RequireOnlyOneKeys<T, keyof T>;
export type Compute<T> = { [key in keyof T]: T[key] } & {};
export type DeepCompute<T> = { [key in keyof T]: T[key] extends object ? DeepCompute<T[key]> : T[key] } & {};
export type RequiredPartial<Keys extends keyof any, Values> = Partial<Record<Keys, Values>>;
export type AcceptPromise<T> = T | Promise<T>;
export type First<T extends any[]> = T[0];

// From toolbelt
export type UnionLast<U> = UnionToIntersection<U extends unknown ? (x: U) => void : never> extends (x: infer P) => void ? P : never;


export type TPrintTimeInfoPluralAndSingularText = (keyof PickByValueType<IPrintTimeInfoTranslatedText, IPluralAndSingularText>);
export type TOcurrencesHash = Record<string, number>;
/*
  GetPropertyFromOr<{ a: string } | { b: number }, 'b'> => number
  GetPropertyFromOr<{ a: string } | { b: number }, 'a'> => string
*/
export type _GetPropertyFromOr<T, P extends Extract<keyof UnionToIntersection<T>, string>> = Extract<T, { [key in P]: any }>[P];
export type GetPropertyFromOr<T, P extends string> = Extract<T, { [key in P]: any }>[P];
export type KeysDifferences<Generic, Extension> = Extension extends unknown ? Exclude<keyof Extension, keyof Generic & keyof Extension> : never;
export type KeyOf<T> = T extends unknown ? keyof T : never;


/*
  * Based on ts-toolbelt, all credits to original creator
  * UnionToIntersection<U>
  * UnionToIntersection<{ a: string } | { b: number }> => { a: string } & { b: number }
*/
export type UnionToIntersection<U> = 
    (U extends any ? (value: U) => void : never) extends ((value: infer V) => void) ? V : never
;


export interface IPluralAndSingularText { singular: string, plural: string }
export interface IPrintTimeInfoTranslatedText {
    minutes: IPluralAndSingularText;
    seconds: IPluralAndSingularText;
    hours: IPluralAndSingularText;
    days: IPluralAndSingularText;
    months: IPluralAndSingularText;
    and: string;
}

export type TPrintTimeInfoShouldHideOptions = {
    [key in TPrintTimeInfoPluralAndSingularText]?: true;
}

/*
 * Same as DeepFindValuesOfNotObjectType, but only for not Objects.
 * DeepFindValuesOfPrimitiveType<{ a: 'A', b: number, c: ['C']}, string> => 'A' | 'C'
 * DeepFindValuesOfPrimitiveType<{ a: 'A', b: number, c: { foo: { bar: 'bar' } } }, { bar: string }> => never
*/
export type DeepFindValuesOfNotObjectType<T, U> = Extract<DeepValueOf<T>, U>;

export type ObjectToUnion<T extends object> = ValueOf<{
    [key in keyof T]: { [nestedKey in key]: T[nestedKey] };
}>

export type DeepWritable<T> = T extends object ? {
    - readonly [key in keyof T]: DeepWritable<T[key]> ;
} : T;
export type DeepReadonlyAndCompute<T> = T extends object ? {
    + readonly [key in keyof T]: DeepReadonlyAndCompute<T[key]> ;
} : T;
export type DeepReadonly<T> = {
    + readonly [key in keyof T]: T[key] extends object ? DeepReadonly<T[key]> : T[key]
};
export type Immutable<T, Exceptions = IdDep<any>> = {
    + readonly [key in keyof T]: T[key] extends object ? T[key] extends Exceptions ? T[key] : Immutable<T[key]> : T[key]
};

export function getImmutable<T extends {}>(source: T): (Immutable<T>) {
    return source;
}


// Credits to Scott Schafer
export type TWritable<T> = {
    - readonly [P in keyof T]: T[P];
};

export type SimpleObjectEquals<Generic extends {}, Extension extends {}> =
    Extension extends Generic ?
        KeysDifferences<Generic, Extension> extends never
            ? Extension
        : never
    : never
;


// export type DeepKeys<T extends object, Prefix extends string = '', Done = never> = ValueOf<{
//     [key in keyof T]: T[key] extends object ? DeepKeys<Exclude<T[key], Done>, `${Prefix}.${key}`, Done | T[key]> | `${Prefix}.${key}` : `${Prefix}.${key}`
// }>;


export type UnionToArray<Values, C = []> =
    Extract<Values, UnionLast<Values>> extends never ? C :
    UnionToArray<Exclude<Values, UnionLast<Values>>, [UnionLast<Values>, ...Extend<C, any[]>]>
;

// export type KeyOf<T, C = [], Values = UnionToArray<Compute<keyof T>>, Prefix = []> = Values extends [] ? C :
//     Values extends [...infer Other, infer Item] ? KeyOf<T, [...Extend<Prefix, any[]>, Item, ...Extend<C, any[]>], Other> : never
// ;

export interface IHashTableAlias {
    [alias: string]: any;
};

export interface ISearchParams {
    [param: string]: string;
};

export interface IHashSet<T> {
    [idObjectField: string]: T;
};

export interface IHashSetNumberIndexed<T> {
    [idObjectField: number]: T;
};

export interface GenericConstructor<T> {
    new(...args: any[]): T;
}
export interface IDeferedPromise<T> {
    resolve: ((value: T) => T | PromiseLike<T> | void);
    reject: ((reason: any) => never | PromiseLike<never> | void);
    promise: Promise<T>
}
export interface ICreateWrapSlot {
    value: ReturnType<TFunction>;
    index: number;
}
export interface IIgnoreProperty {
    [propertyName: string]: true
}
export interface IGetTimeInfo {
    seconds: number;
    minutes: number;
    hours: number;
    days: number;
    months: number;
}

export type ToClass<T> = T & { new(...params: any[]) };

// export type TupleToString<T extends string[], Result = ''> = T extends [] ? Result :
//     T extends [string, ...infer Others] ?
//         Others extends string[] ?`${T[0]}${Others extends [] ? '': '.'}${TupleToString<Others>}` : never
//     : never
// ;

type GetKeyAndFilter<T, K extends string | number> = Exclude<
    ValueOf<{
        [key in K]: {
            key: key
            // @ts-ignore
            value: AnyOrUnknownToNever<T[key]>
        }
    }>,
    { value: never }
>;

// type GetKey<T, K, AnyOrUnknownBecomes = never> = 
//     ValueOf<{
//             // @ts-ignore
//         [key in K]: {
//             key: key
//             // @ts-ignore
//             value: AnyOrUnknownTo<T[key], AnyOrUnknownBecomes>
//         }
//     }>
// ;

export type MapTo<From, To> = { [key in keyof From]: GetByKey<To, key> };


export type RemountObject<Keys, T, TransformEmptyValuesTo = unknown> = {
    // @ts-ignore
    [key in Keys]: IsUnknown<TransformEmptyValuesTo> extends true ? T[key] : (IsUnknown<T[key]> extends true ? TransformEmptyValuesTo : T[key])
};



/*
 * @ImplementPrivate<{ foo: string }>() // success
 *  class ClassWithPrivateValue {
 *      private foo: string
 *  }
 * @ImplementPrivate<{ foo: number }>() // error
 *  class ClassWithPrivateValue {
 *      private foo: string
 *  }
*/
// export const ImplementPrivate: <T>() => <A extends { prototype: any }>(target: A) => 
//     (Is<A['prototype'], T> extends true ? never : unknown) = () => undefined
// ;
// type TImplementPrivate<Keys, Generic extends {}, Class extends {}> = RemountObject<Keys, Class, unknown> extends Generic ? never : unknown;

// export const StaticImplement: <T>() => <A>(target: A) => 
//     Is<A, T> extends true ? never : unknown = () => undefined
// ;

export type _GetKey<T, E extends object, Error = $$<'Error'>> = 
    ValueOf<
        & {
            [key in GetCustomKeys<E, false>]: {
                key: key
                value:
                    GetByKey<T, key> extends infer TValue ?
                        IsAny<TValue> extends true ? Error : 
                        IsUnknown<TValue> extends true ? Error : 
                        IsNever<TValue> extends true ? Error : 
                        IsUndefined<TValue> extends true ? Error : 
                    TValue extends GetByKey<E, key> ? GetByKey<E, key> : Error
                : never
            }
        }
        & {
            [key in Assert<GetCustomKeys<E, true>, string>]: {
                key: key
                value:
                    GetByKey<T, key> extends infer TValue ?
                    [GetByKey<E, key>] extends [infer EKey] ? 
                        HasKey<T, key> extends false ? EKey : 
                    TValue extends EKey ? EKey : Error
                    : never
                : never
            }
        }
    >
;


export declare namespace WordUtils {
    interface MapIsLetter extends Custom.Generic<string> {
        compute(): [_IsLetter<this['item']>] extends [false] ? '' : this['item']
    }
    interface ICapitalize extends Custom.Generic<string> {
        compute: () => Capitalize<this['item']>
    }
    
    interface IWords extends Custom.Generic<string> {
        compute: () => Words<this['item']>
    }

    interface IStartCase extends Custom.Generic<string> {
        compute: () => StartCase<this['item']>
    }
    interface IPascalCase extends Custom.Generic<string> {
        compute: () => PascalCase<this['item']>
    }
    interface ICamelCase extends Custom.Generic<string> {
        compute: () => CamelCase<this['item']>
    }
    // interface IKebabCase extends Custom.Generic<string> {
    //     compute: () => KebabCase<this['item']>
    // }
    interface ISnakeCase extends Custom.Generic<string> {
        compute: () => SnakeCase<this['item']>
    }
    type _IsLetter<
        Token extends string,
        Upper = Uppercase<Token>,
        Lower = Lowercase<Token>
    > = [Upper] extends [Lower] ? false : true

    interface ILowerCase extends Custom.Generic<string> {
        compute: () => Lowercase<this['item']>
    }


    type IsEmptyOrUpper<T extends string> = T extends '' ? true : T extends Uppercase<T> ? true : false;
    
    type IsUpperCase<T> = T extends string ? T extends '' ? false : T extends Uppercase<T> ? true : false : false;
    
    type _ChunkLetters<T extends string[], Previous extends string = undefined, Current extends string[][] = [[]]> =
        T extends [infer First, ...infer Others] ?
        _ChunkLetters<
            Assert<Others, string[]>,
            Assert<First, string>, 
            Assert<First extends '' ? Previous extends '' ? Current : [...Current, []] :  Current extends [...infer Remaining, infer Last] ? 
            (
                [IsUpperCase<First>, IsUpperCase<Others[0]>] extends [infer IsFirstUpper, infer IsNextUpper] ?
                (IsFirstUpper extends IsNextUpper ? true : false) extends true ?
                [...Remaining, [...Assert<Last, unknown[]>, First]]
                : [IsFirstUpper, IsNextUpper] extends [false, true] ? [...Remaining, [...Assert<Last, unknown[]>, First], []] : [...Remaining, ...(Last extends [] ? [] : [[...Assert<Last, unknown[]>]]), ...(IsNextUpper extends false ? [[First]] : [[First], []])]
                : never
            ) : never, string[][]>
        >
        : Current
    ;
    interface ChunkLetters extends Custom.Generic {
        compute: () => _ChunkLetters<this['item'] extends string[] ? this['item'] : never>
    }


    // namespace FromWords {
        

    //     export type StartCase<T extends string[]> = Custom.Chain<T, [
    //         Custom.$Utility.Map<WordUtils.ICapitalize>,
    //         Custom.$Utility.Join<' '>,
    //     ]>;

    //     export type PascalCase<T extends string[]> = Custom.Chain<T, [
    //         Custom.$Utility.Map<WordUtils.ILowerCase>,
    //         Custom.$Utility.Map<WordUtils.ICapitalize>,
    //         Custom.$Utility.Join<''>,
    //     ]>;

    //     export type SnakeCase<T extends string[]> = Custom.Chain<T, [
    //         Custom.$Utility.Map<WordUtils.ILowerCase>,
    //         Custom.$Utility.Join<'_'>,
    //     ]>;

    //     export type KebabCase<T extends string[]> = Custom.Chain<T, [
    //         Custom.$Utility.Map<WordUtils.ILowerCase>,
    //         Custom.$Utility.Join<'-'>,
    //     ]>;

    //     export type CamelCase<T extends string[]> = PascalCase<T> extends `${infer Char}${infer Chars}` ? `${Lowercase<Char>}${Chars}` : ''
    // }
}

export type Words<T extends string> = Custom.Chain<T, [
    Custom.$Utility.Split<''>,
    Custom.$Utility.Map<WordUtils.MapIsLetter>,
    WordUtils.ChunkLetters,
    Custom.$Utility.Map<Custom.$Utility.Join<''>>,
]>;


// export type WordsV2<
//     T extends string, LastPattern = never,
//     Current extends string[] = [],

//     ShouldReturn extends boolean = (
//         [IsMatch<T, ''>] extends [true] ? true :
//         [IsEqual<T, string>] extends [true] ? true :
//         false
//     ),
//     Computed extends string[] = (
//         ShouldReturn extends true ? Current :
//         [MatchV2All<T, [Patterns.Pascal, Patterns.Upper, Alphabet.Lower, Patterns.Digit, LastPattern]>] extends [[infer $Match, infer Text, infer Pattern]] ?
//         [IsUnknown<$Match>] extends [true] ?
//             WordsV2<$String.Tail<T>, never, Current>
//         :
//         [Text] extends [string] ? 
//         [Pattern] extends [string] ?
//         [$Match] extends [string] ?
//         IsEqual<Pattern, LastPattern> extends true
//             ? WordsV2<Text, LastPattern, [...Assert<Pop<Current>, string[]>, `${Assert<Last<Current>, string>}${$Match}`]>
//             : WordsV2<Text, $String.Last<Pattern>, [...Current, $Match]>
//         : never
//         : never
//         : never
//         : never

//     ),
// > =
//     Computed
// ;

export type KebabCaseV2<T extends string> = Custom.Chain<Words<T>, [
    Custom.$Utility.Map<WordUtils.ILowerCase>,
    Custom.$Utility.Join<'-'>,
]>;

// export type Words<T extends string, LastPattern = never, Current extends string[] = []> =
//     CheckBooleanExpression<IsMatch<T, ''> | IsEqual<T, string>> extends true ? Current :
//     [MatchV2All<T, [Patterns.Pascal, Patterns.Upper, Patterns.Digit, LastPattern]>] extends [[infer $Match, infer Text, infer Pattern]] ?
//     [IsUnknown<$Match>] extends [true] ?
//         Words<$String.Tail<T>, never, Current>
//     :
//     IsEqual<Pattern, LastPattern> extends true
//         ? Words<string & Text, LastPattern, [...Assert<Pop<Current>, string[]>, `${string & Last<Current>}${string & $Match}`]>
//         : Words<string & Text, $String.Last<string & Pattern>, [...Current, string & $Match]>
//     : never
// ;

export type StartCase<T extends string> = Custom.Chain<Words<T>, [
    Custom.$Utility.Map<WordUtils.ICapitalize>,
    Custom.$Utility.Join<' '>,
]>;

export type PascalCase<T extends string, $Words extends string[] = Words<T>> = Custom.Chain<$Words, [
    Custom.$Utility.Map<WordUtils.ILowerCase>,
    Custom.$Utility.Map<WordUtils.ICapitalize>,
    Custom.$Utility.Join<''>,
]>;

export type SnakeCase<T extends string> = Custom.Chain<Words<T>, [
    Custom.$Utility.Map<WordUtils.ILowerCase>,
    Custom.$Utility.Join<'_'>,
]>;

export type CamelCase<T extends string, $Words extends string[] = Words<T>> = $Words extends unknown ? PascalCase<T, $Words> extends `${infer Char}${infer Chars}` ? `${Lowercase<Char>}${Chars}` : '' : never

export type IsStringLiteral<A extends any> = A extends `${infer AA}` ? string extends A ? 0 : 1 : 0;
export type IsNumberLiteral<A extends any> = A extends number ? number extends A ? 0 : `${A}` extends `${infer N }` ? N : 0 : 0;

export function makeHandlerFactory<A extends unknown[], B>(handler: (new (...a: A) => B)): (...a: A) => B
export function makeHandlerFactory(handler: (new (...a: unknown[]) => unknown)) {
    return (...params: any[]) => new handler(...params)
}


export type FactoryOf<T> = T extends (new (...a: infer A) => infer B) ? (...a: A) => B : never;



export function instanceOf<A extends unknown[], B>(handler: (new () => B)): B {
    return new handler();
}

interface Identity extends Custom.Generic {
    compute: () => this['item']
}

// @ts-expect-error
export declare function makeAssertsFactory<A, B>(handler: (new (source: A) => B)): (source: A) => source is B
export declare function modifyPrototype<Generic extends Custom.Generic>(): <A, B>(handler: (new (source: A) => B)) => (new (source: A) => Custom.Chain<B, [Generic]>)


declare namespace Deburr {
    type TDeburrHash = {
        "À": "A",
        "Á": "A",
        "Â": "A",
        "Ã": "A",
        "Ä": "A",
        "Å": "A",
        "Ç": "C",
        "È": "E",
        "É": "E",
        "Ê": "E",
        "Ë": "E",
        "Ì": "I",
        "Í": "I",
        "Î": "I",
        "Ï": "I",
        "Ñ": "N",
        "Ò": "O",
        "Ó": "O",
        "Ô": "O",
        "Õ": "O",
        "Ö": "O",
        "Ù": "U",
        "Ú": "U",
        "Û": "U",
        "Ü": "U",
        "Ý": "Y",
        "à": "a",
        "á": "a",
        "â": "a",
        "ã": "a",
        "ä": "a",
        "å": "a",
        "ç": "c",
        "è": "e",
        "é": "e",
        "ê": "e",
        "ë": "e",
        "ì": "i",
        "í": "i",
        "î": "i",
        "ï": "i",
        "ñ": "n",
        "ò": "o",
        "ó": "o",
        "ô": "o",
        "õ": "o",
        "ö": "o",
        "ù": "u",
        "ú": "u",
        "û": "u",
        "ü": "u",
        "ý": "y",
        "ÿ": "y",
        "Ā": "A",
        "ā": "a",
        "Ă": "A",
        "ă": "a",
        "Ą": "A",
        "ą": "a",
        "Ć": "C",
        "ć": "c",
        "Ĉ": "C",
        "ĉ": "c",
        "Ċ": "C",
        "ċ": "c",
        "Č": "C",
        "č": "c",
        "Ď": "D",
        "ď": "d",
        "Ē": "E",
        "ē": "e",
        "Ĕ": "E",
        "ĕ": "e",
        "Ė": "E",
        "ė": "e",
        "Ę": "E",
        "ę": "e",
        "Ě": "E",
        "ě": "e",
        "Ĝ": "G",
        "ĝ": "g",
        "Ğ": "G",
        "ğ": "g",
        "Ġ": "G",
        "ġ": "g",
        "Ģ": "G",
        "ģ": "g",
        "Ĥ": "H",
        "ĥ": "h",
        "Ĩ": "I",
        "ĩ": "i",
        "Ī": "I",
        "ī": "i",
        "Ĭ": "I",
        "ĭ": "i",
        "Į": "I",
        "į": "i",
        "İ": "I",
        "Ĵ": "J",
        "ĵ": "j",
        "Ķ": "K",
        "ķ": "k",
        "Ĺ": "L",
        "ĺ": "l",
        "Ļ": "L",
        "ļ": "l",
        "Ľ": "L",
        "ľ": "l",
        "Ń": "N",
        "ń": "n",
        "Ņ": "N",
        "ņ": "n",
        "Ň": "N",
        "ň": "n",
        "Ō": "O",
        "ō": "o",
        "Ŏ": "O",
        "ŏ": "o",
        "Ő": "O",
        "ő": "o",
        "Ŕ": "R",
        "ŕ": "r",
        "Ŗ": "R",
        "ŗ": "r",
        "Ř": "R",
        "ř": "r",
        "Ś": "S",
        "ś": "s",
        "Ŝ": "S",
        "ŝ": "s",
        "Ş": "S",
        "ş": "s",
        "Š": "S",
        "š": "s",
        "Ţ": "T",
        "ţ": "t",
        "Ť": "T",
        "ť": "t",
        "Ũ": "U",
        "ũ": "u",
        "Ū": "U",
        "ū": "u",
        "Ŭ": "U",
        "ŭ": "u",
        "Ů": "U",
        "ů": "u",
        "Ű": "U",
        "ű": "u",
        "Ų": "U",
        "ų": "u",
        "Ŵ": "W",
        "ŵ": "w",
        "Ŷ": "Y",
        "ŷ": "y",
        "Ÿ": "Y",
        "Ź": "Z",
        "ź": "z",
        "Ż": "Z",
        "ż": "z",
        "Ž": "Z",
        "ž": "z",
        "Ơ": "O",
        "ơ": "o",
        "Ư": "U",
        "ư": "u",
        "Ǎ": "A",
        "ǎ": "a",
        "Ǐ": "I",
        "ǐ": "i",
        "Ǒ": "O",
        "ǒ": "o",
        "Ǔ": "U",
        "ǔ": "u",
        "Ǖ": "U",
        "ǖ": "u",
        "Ǘ": "U",
        "ǘ": "u",
        "Ǚ": "U",
        "ǚ": "u",
        "Ǜ": "U",
        "ǜ": "u",
        "Ǟ": "A",
        "ǟ": "a",
        "Ǡ": "A",
        "ǡ": "a",
        "Ǣ": "Æ",
        "ǣ": "æ",
        "Ǧ": "G",
        "ǧ": "g",
        "Ǩ": "K",
        "ǩ": "k",
        "Ǫ": "O",
        "ǫ": "o",
        "Ǭ": "O",
        "ǭ": "o",
        "Ǯ": "Ʒ",
        "ǯ": "ʒ",
        "ǰ": "j",
        "Ǵ": "G",
        "ǵ": "g",
        "Ǹ": "N",
        "ǹ": "n",
        "Ǻ": "A",
        "ǻ": "a",
        "Ǽ": "Æ",
        "ǽ": "æ",
        "Ǿ": "Ø",
        "ǿ": "ø",
        "Ȁ": "A",
        "ȁ": "a",
        "Ȃ": "A",
        "ȃ": "a",
        "Ȅ": "E",
        "ȅ": "e",
        "Ȇ": "E",
        "ȇ": "e",
        "Ȉ": "I",
        "ȉ": "i",
        "Ȋ": "I",
        "ȋ": "i",
        "Ȍ": "O",
        "ȍ": "o",
        "Ȏ": "O",
        "ȏ": "o",
        "Ȑ": "R",
        "ȑ": "r",
        "Ȓ": "R",
        "ȓ": "r",
        "Ȕ": "U",
        "ȕ": "u",
        "Ȗ": "U",
        "ȗ": "u",
        "Ș": "S",
        "ș": "s",
        "Ț": "T",
        "ț": "t",
        "Ȟ": "H",
        "ȟ": "h",
        "Ȧ": "A",
        "ȧ": "a",
        "Ȩ": "E",
        "ȩ": "e",
        "Ȫ": "O",
        "ȫ": "o",
        "Ȭ": "O",
        "ȭ": "o",
        "Ȯ": "O",
        "ȯ": "o",
        "Ȱ": "O",
        "ȱ": "o",
        "Ȳ": "Y",
        "ȳ": "y",
        "Ά": "Α",
        "Έ": "Ε",
        "Ή": "Η",
        "Ί": "Ι",
        "Ό": "Ο",
        "Ύ": "Υ",
        "Ώ": "Ω",
        "ΐ": "ι",
        "Ϊ": "Ι",
        "Ϋ": "Υ",
        "ά": "α",
        "έ": "ε",
        "ή": "η",
        "ί": "ι",
        "ΰ": "υ",
        "ϊ": "ι",
        "ϋ": "υ",
        "ό": "ο",
        "ύ": "υ",
        "ώ": "ω",
        "Ѐ": "Е",
        "Ё": "Е",
        "Ѓ": "Г",
        "Ї": "І",
        "Ќ": "К",
        "Ѝ": "И",
        "Ў": "У",
        "Й": "И",
        "й": "и",
        "ѐ": "е",
        "ё": "е",
        "ѓ": "г",
        "ї": "і",
        "ќ": "к",
        "ѝ": "и",
        "ў": "у",
        "Ѷ": "Ѵ",
        "ѷ": "ѵ",
        "Ӂ": "Ж",
        "ӂ": "ж",
        "Ӑ": "А",
        "ӑ": "а",
        "Ӓ": "А",
        "ӓ": "а",
        "Ӗ": "Е",
        "ӗ": "е",
        "Ӛ": "Ә",
        "ӛ": "ә",
        "Ӝ": "Ж",
        "ӝ": "ж",
        "Ӟ": "З",
        "ӟ": "з",
        "Ӣ": "И",
        "ӣ": "и",
        "Ӥ": "И",
        "ӥ": "и",
        "Ӧ": "О",
        "ӧ": "о",
        "Ӫ": "Ө",
        "ӫ": "ө",
        "Ӭ": "Э",
        "ӭ": "э",
        "Ӯ": "У",
        "ӯ": "у",
        "Ӱ": "У",
        "ӱ": "у",
        "Ӳ": "У",
        "ӳ": "у",
        "Ӵ": "Ч",
        "ӵ": "ч",
        "Ӹ": "Ы",
        "ӹ": "ы",
        "Ḁ": "A",
        "ḁ": "a",
        "Ḃ": "B",
        "ḃ": "b",
        "Ḅ": "B",
        "ḅ": "b",
        "Ḇ": "B",
        "ḇ": "b",
        "Ḉ": "C",
        "ḉ": "c",
        "Ḋ": "D",
        "ḋ": "d",
        "Ḍ": "D",
        "ḍ": "d",
        "Ḏ": "D",
        "ḏ": "d",
        "Ḑ": "D",
        "ḑ": "d",
        "Ḓ": "D",
        "ḓ": "d",
        "Ḕ": "E",
        "ḕ": "e",
        "Ḗ": "E",
        "ḗ": "e",
        "Ḙ": "E",
        "ḙ": "e",
        "Ḛ": "E",
        "ḛ": "e",
        "Ḝ": "E",
        "ḝ": "e",
        "Ḟ": "F",
        "ḟ": "f",
        "Ḡ": "G",
        "ḡ": "g",
        "Ḣ": "H",
        "ḣ": "h",
        "Ḥ": "H",
        "ḥ": "h",
        "Ḧ": "H",
        "ḧ": "h",
        "Ḩ": "H",
        "ḩ": "h",
        "Ḫ": "H",
        "ḫ": "h",
        "Ḭ": "I",
        "ḭ": "i",
        "Ḯ": "I",
        "ḯ": "i",
        "Ḱ": "K",
        "ḱ": "k",
        "Ḳ": "K",
        "ḳ": "k",
        "Ḵ": "K",
        "ḵ": "k",
        "Ḷ": "L",
        "ḷ": "l",
        "Ḹ": "L",
        "ḹ": "l",
        "Ḻ": "L",
        "ḻ": "l",
        "Ḽ": "L",
        "ḽ": "l",
        "Ḿ": "M",
        "ḿ": "m",
        "Ṁ": "M",
        "ṁ": "m",
        "Ṃ": "M",
        "ṃ": "m",
        "Ṅ": "N",
        "ṅ": "n",
        "Ṇ": "N",
        "ṇ": "n",
        "Ṉ": "N",
        "ṉ": "n",
        "Ṋ": "N",
        "ṋ": "n",
        "Ṍ": "O",
        "ṍ": "o",
        "Ṏ": "O",
        "ṏ": "o",
        "Ṑ": "O",
        "ṑ": "o",
        "Ṓ": "O",
        "ṓ": "o",
        "Ṕ": "P",
        "ṕ": "p",
        "Ṗ": "P",
        "ṗ": "p",
        "Ṙ": "R",
        "ṙ": "r",
        "Ṛ": "R",
        "ṛ": "r",
        "Ṝ": "R",
        "ṝ": "r",
        "Ṟ": "R",
        "ṟ": "r",
        "Ṡ": "S",
        "ṡ": "s",
        "Ṣ": "S",
        "ṣ": "s",
        "Ṥ": "S",
        "ṥ": "s",
        "Ṧ": "S",
        "ṧ": "s",
        "Ṩ": "S",
        "ṩ": "s",
        "Ṫ": "T",
        "ṫ": "t",
        "Ṭ": "T",
        "ṭ": "t",
        "Ṯ": "T",
        "ṯ": "t",
        "Ṱ": "T",
        "ṱ": "t",
        "Ṳ": "U",
        "ṳ": "u",
        "Ṵ": "U",
        "ṵ": "u",
        "Ṷ": "U",
        "ṷ": "u",
        "Ṹ": "U",
        "ṹ": "u",
        "Ṻ": "U",
        "ṻ": "u",
        "Ṽ": "V",
        "ṽ": "v",
        "Ṿ": "V",
        "ṿ": "v",
        "Ẁ": "W",
        "ẁ": "w",
        "Ẃ": "W",
        "ẃ": "w",
        "Ẅ": "W",
        "ẅ": "w",
        "Ẇ": "W",
        "ẇ": "w",
        "Ẉ": "W",
        "ẉ": "w",
        "Ẋ": "X",
        "ẋ": "x",
        "Ẍ": "X",
        "ẍ": "x",
        "Ẏ": "Y",
        "ẏ": "y",
        "Ẑ": "Z",
        "ẑ": "z",
        "Ẓ": "Z",
        "ẓ": "z",
        "Ẕ": "Z",
        "ẕ": "z",
        "ẖ": "h",
        "ẗ": "t",
        "ẘ": "w",
        "ẙ": "y",
        "ẛ": "ſ",
        "Ạ": "A",
        "ạ": "a",
        "Ả": "A",
        "ả": "a",
        "Ấ": "A",
        "ấ": "a",
        "Ầ": "A",
        "ầ": "a",
        "Ẩ": "A",
        "ẩ": "a",
        "Ẫ": "A",
        "ẫ": "a",
        "Ậ": "A",
        "ậ": "a",
        "Ắ": "A",
        "ắ": "a",
        "Ằ": "A",
        "ằ": "a",
        "Ẳ": "A",
        "ẳ": "a",
        "Ẵ": "A",
        "ẵ": "a",
        "Ặ": "A",
        "ặ": "a",
        "Ẹ": "E",
        "ẹ": "e",
        "Ẻ": "E",
        "ẻ": "e",
        "Ẽ": "E",
        "ẽ": "e",
        "Ế": "E",
        "ế": "e",
        "Ề": "E",
        "ề": "e",
        "Ể": "E",
        "ể": "e",
        "Ễ": "E",
        "ễ": "e",
        "Ệ": "E",
        "ệ": "e",
        "Ỉ": "I",
        "ỉ": "i",
        "Ị": "I",
        "ị": "i",
        "Ọ": "O",
        "ọ": "o",
        "Ỏ": "O",
        "ỏ": "o",
        "Ố": "O",
        "ố": "o",
        "Ồ": "O",
        "ồ": "o",
        "Ổ": "O",
        "ổ": "o",
        "Ỗ": "O",
        "ỗ": "o",
        "Ộ": "O",
        "ộ": "o",
        "Ớ": "O",
        "ớ": "o",
        "Ờ": "O",
        "ờ": "o",
        "Ở": "O",
        "ở": "o",
        "Ỡ": "O",
        "ỡ": "o",
        "Ợ": "O",
        "ợ": "o",
        "Ụ": "U",
        "ụ": "u",
        "Ủ": "U",
        "ủ": "u",
        "Ứ": "U",
        "ứ": "u",
        "Ừ": "U",
        "ừ": "u",
        "Ử": "U",
        "ử": "u",
        "Ữ": "U",
        "ữ": "u",
        "Ự": "U",
        "ự": "u",
        "Ỳ": "Y",
        "ỳ": "y",
        "Ỵ": "Y",
        "ỵ": "y",
        "Ỷ": "Y",
        "ỷ": "y",
        "Ỹ": "Y",
        "ỹ": "y",
        "ἀ": "α",
        "ἁ": "α",
        "ἂ": "α",
        "ἃ": "α",
        "ἄ": "α",
        "ἅ": "α",
        "ἆ": "α",
        "ἇ": "α",
        "Ἀ": "Α",
        "Ἁ": "Α",
        "Ἂ": "Α",
        "Ἃ": "Α",
        "Ἄ": "Α",
        "Ἅ": "Α",
        "Ἆ": "Α",
        "Ἇ": "Α",
        "ἐ": "ε",
        "ἑ": "ε",
        "ἒ": "ε",
        "ἓ": "ε",
        "ἔ": "ε",
        "ἕ": "ε",
        "Ἐ": "Ε",
        "Ἑ": "Ε",
        "Ἒ": "Ε",
        "Ἓ": "Ε",
        "Ἔ": "Ε",
        "Ἕ": "Ε",
        "ἠ": "η",
        "ἡ": "η",
        "ἢ": "η",
        "ἣ": "η",
        "ἤ": "η",
        "ἥ": "η",
        "ἦ": "η",
        "ἧ": "η",
        "Ἠ": "Η",
        "Ἡ": "Η",
        "Ἢ": "Η",
        "Ἣ": "Η",
        "Ἤ": "Η",
        "Ἥ": "Η",
        "Ἦ": "Η",
        "Ἧ": "Η",
        "ἰ": "ι",
        "ἱ": "ι",
        "ἲ": "ι",
        "ἳ": "ι",
        "ἴ": "ι",
        "ἵ": "ι",
        "ἶ": "ι",
        "ἷ": "ι",
        "Ἰ": "Ι",
        "Ἱ": "Ι",
        "Ἲ": "Ι",
        "Ἳ": "Ι",
        "Ἴ": "Ι",
        "Ἵ": "Ι",
        "Ἶ": "Ι",
        "Ἷ": "Ι",
        "ὀ": "ο",
        "ὁ": "ο",
        "ὂ": "ο",
        "ὃ": "ο",
        "ὄ": "ο",
        "ὅ": "ο",
        "Ὀ": "Ο",
        "Ὁ": "Ο",
        "Ὂ": "Ο",
        "Ὃ": "Ο",
        "Ὄ": "Ο",
        "Ὅ": "Ο",
        "ὐ": "υ",
        "ὑ": "υ",
        "ὒ": "υ",
        "ὓ": "υ",
        "ὔ": "υ",
        "ὕ": "υ",
        "ὖ": "υ",
        "ὗ": "υ",
        "Ὑ": "Υ",
        "Ὓ": "Υ",
        "Ὕ": "Υ",
        "Ὗ": "Υ",
        "ὠ": "ω",
        "ὡ": "ω",
        "ὢ": "ω",
        "ὣ": "ω",
        "ὤ": "ω",
        "ὥ": "ω",
        "ὦ": "ω",
        "ὧ": "ω",
        "Ὠ": "Ω",
        "Ὡ": "Ω",
        "Ὢ": "Ω",
        "Ὣ": "Ω",
        "Ὤ": "Ω",
        "Ὥ": "Ω",
        "Ὦ": "Ω",
        "Ὧ": "Ω",
        "ὰ": "α",
        "ά": "α",
        "ὲ": "ε",
        "έ": "ε",
        "ὴ": "η",
        "ή": "η",
        "ὶ": "ι",
        "ί": "ι",
        "ὸ": "ο",
        "ό": "ο",
        "ὺ": "υ",
        "ύ": "υ",
        "ὼ": "ω",
        "ώ": "ω",
        "ᾀ": "α",
        "ᾁ": "α",
        "ᾂ": "α",
        "ᾃ": "α",
        "ᾄ": "α",
        "ᾅ": "α",
        "ᾆ": "α",
        "ᾇ": "α",
        "ᾈ": "Α",
        "ᾉ": "Α",
        "ᾊ": "Α",
        "ᾋ": "Α",
        "ᾌ": "Α",
        "ᾍ": "Α",
        "ᾎ": "Α",
        "ᾏ": "Α",
        "ᾐ": "η",
        "ᾑ": "η",
        "ᾒ": "η",
        "ᾓ": "η",
        "ᾔ": "η",
        "ᾕ": "η",
        "ᾖ": "η",
        "ᾗ": "η",
        "ᾘ": "Η",
        "ᾙ": "Η",
        "ᾚ": "Η",
        "ᾛ": "Η",
        "ᾜ": "Η",
        "ᾝ": "Η",
        "ᾞ": "Η",
        "ᾟ": "Η",
        "ᾠ": "ω",
        "ᾡ": "ω",
        "ᾢ": "ω",
        "ᾣ": "ω",
        "ᾤ": "ω",
        "ᾥ": "ω",
        "ᾦ": "ω",
        "ᾧ": "ω",
        "ᾨ": "Ω",
        "ᾩ": "Ω",
        "ᾪ": "Ω",
        "ᾫ": "Ω",
        "ᾬ": "Ω",
        "ᾭ": "Ω",
        "ᾮ": "Ω",
        "ᾯ": "Ω",
        "ᾰ": "α",
        "ᾱ": "α",
        "ᾲ": "α",
        "ᾳ": "α",
        "ᾴ": "α",
        "ᾶ": "α",
        "ᾷ": "α",
        "Ᾰ": "Α",
        "Ᾱ": "Α",
        "Ὰ": "Α",
        "Ά": "Α",
        "ᾼ": "Α",
        "ι": "ι",
        "ῂ": "η",
        "ῃ": "η",
        "ῄ": "η",
        "ῆ": "η",
        "ῇ": "η",
        "Ὲ": "Ε",
        "Έ": "Ε",
        "Ὴ": "Η",
        "Ή": "Η",
        "ῌ": "Η",
        "ῐ": "ι",
        "ῑ": "ι",
        "ῒ": "ι",
        "ΐ": "ι",
        "ῖ": "ι",
        "ῗ": "ι",
        "Ῐ": "Ι",
        "Ῑ": "Ι",
        "Ὶ": "Ι",
        "Ί": "Ι",
        "ῠ": "υ",
        "ῡ": "υ",
        "ῢ": "υ",
        "ΰ": "υ",
        "ῤ": "ρ",
        "ῥ": "ρ",
        "ῦ": "υ",
        "ῧ": "υ",
        "Ῠ": "Υ",
        "Ῡ": "Υ",
        "Ὺ": "Υ",
        "Ύ": "Υ",
        "Ῥ": "Ρ",
        "ῲ": "ω",
        "ῳ": "ω",
        "ῴ": "ω",
        "ῶ": "ω",
        "ῷ": "ω",
        "Ὸ": "Ο",
        "Ό": "Ο",
        "Ὼ": "Ω",
        "Ώ": "Ω",
        "ῼ": "Ω",
        "Ω": "Ω",
        "K": "K",
        "Å": "A"
    };

}


// 
type Deburr<
    T extends string,
    Splitted extends any[] = Split<T, ''>,
    Mapped extends any[] = { [key in keyof Splitted]: GetByKey<Deburr.TDeburrHash, Splitted[key]> extends infer V ? V extends string ? V : Splitted[key] : never  },
    Chunks extends string[][] = MakeChunks<'22', Mapped>
> =
    Join<{ [key in keyof Chunks]: Join<Extract<Chunks[key], string[]>, ''> }, ''>
;



export type IsProtected<T, Mount extends { [key in Key]: GetByKey<T, Key> }, Key extends string = (keyof Mount & string)> = IsPrivate<T, Key> extends true ? IsNever<Mount & T> extends false ? true : false : false;
export type IsPrivate<T, Key extends ItPropertyType> = IsNever<UnionToIntersection<[{ [key in Key]: GetByKey<T, Key> }, T][number]>>;
export type HasKey<T, Key extends string> = 
    IsUnknown<GetByKey<T, Key>> extends true
        ? IsPrivate<T, Key>
        : true
;

declare const EmptyKey: unique symbol;
type RemoveUndefined<T> = { [key in keyof T]: IsUndefined<T[key]> extends true ? typeof EmptyKey : T[key] }

// Best
export type THasKey<T, Key extends ItPropertyType> =
    [T] extends [{ [key in Key]: any }] ? true :
    [T] extends [unknown[]] ? Negate<IsUndefined<GetByKey<RemoveUndefined<T>, Key>>> :
    IsPrivate<T, Key>
;



export const ImplementPrivate: <T>() => <A extends { prototype: any }>(target: A) => 
    _TImplementPrivate<T, A['prototype']> = () => empty
;
export type _TImplementPrivate<E extends {}, T extends {}> =
    Exclude<_GetKey<E, E>, _GetKey<T, E>>
;



export type RemoveKeysFromClass<T, Keys = 'prototype'> = ReMapObject<{
    [methodName in keyof T]: methodName extends Keys ? {} : { [key in methodName]: T[methodName]; }
}>

export type NeverToUnknown<T> = (T extends never ? T : unknown) extends never ? unknown : T;
export type Extend<Value extends Verifier, Source, ShouldVerify extends boolean = false, Verifier = ShouldVerify extends true ? (Source & Extract<Value, Source>) : any> = Value extends Source ? Value : never;
export type NeverToNever<T> = (T extends never ? T : unknown) extends never ? never : T;

export type MergeInterfaces<
    A,
    B = unknown,
    C = unknown,
    D = unknown,
    E = unknown,
    F = unknown,
    G = unknown,
    H = unknown,
    I = unknown,
    J = unknown,
    K = unknown,
    L = unknown,
    M = unknown,
    N = unknown,
    O = unknown,
    P = unknown,
    Q = unknown,
    R = unknown,
    S = unknown,
    T = unknown,
    U = unknown,
    V = unknown,
    W = unknown,
    X = unknown,
    Y = unknown,
    Z = unknown,
> = & A & B & C & D & E & F & G & H & I & J & K & L & M & N & O & P & Q & R & S & T & U & V & W & X & Y & Z;




// export type TDeepMapToMany<List> = CustomType<
//     List extends [infer Value] ? Map<Value, undefined> : 
//     List extends [infer Value, ...infer Values] ? Map<Value, TDeepMapToMany<Values>> : 
// never, 'DeepMapToMany'>;

// ts >= 4
// export type TDeepMap<List> = CustomType<
//     List extends [infer Value] ? Value : 
//     List extends [infer Value, ...infer Values] ? Map<Value, TDeepMap<Values>> : 
// never, 'DeepMap'>;


export namespace DeprecatedTypes {
    export type UnShift<T extends any[], Item> =
        ((a: Item, ...others: T) => never) extends ((...a: infer R) => never) ? R : never
    ;
    export type Tail<T extends any[]> = 
        ((...x: T) => void) extends ((h: infer A, ...t: infer R) => void) ? R : never
    ;
}




export type TypeDeepMap<T = unknown> = CCustomType<[[T & 'DeepMap']]>;

// ts < 4
export type TDeepMap<List extends any[]> = (
    List extends [infer Value] ? Value : 
    // @ts-ignore
    List extends [infer Value, ...Tail<List>] ? Map<Value, TDeepMap<Tail<List>>> 
    : never
) & TypeDeepMap<List>;

// type GetMap<Init extends any[], Original> =
//     Original extends [...Init, ...infer Values] ? Values extends [infer V] ? V : TDeepMap<Values> :
//     Init
// ;


// type Flat<T extends any[][], C extends any[] = []> = T extends [] ? C : T extends [...infer Others, infer Value] ? Flat<Extend<Others, any[]>, [...(Value extends any[] ? Value : [Value]), ...C]> : never;

// export type ObjectToTuple<T extends object, C extends any[] = []> = NeverToNever<keyof T> extends never ? C :
//     UnionLast<keyof T> extends (infer LastKey) ? LastKey extends keyof T ? ObjectToTuple<Omit<T, LastKey>, [T[LastKey], ...C,]> : never
//     : never
// ;

// export type TUnionFirst<U extends string> = ValueOf<Record<U, U>> extends UnionLast<ValueOf<Record<U, U>>>
//     ? ValueOf<Record<U, U>>
//     : TUnionFirst<Exclude<ValueOf<Record<U, U>>, UnionLast<ValueOf<Record<U, U>>>>>
// ;
// export type TUnionTake<U extends (string | number | symbol), Item> = UnionLast<ValueOf<Record<U, U>>> extends Item
//     ? ValueOf<Record<U, U>>
//     : TUnionTake<Exclude<ValueOf<Record<U, U>>, UnionLast<ValueOf<Record<U, U>>>>, Item>
// ;
// export type TUnionTakeNext<U extends (string | number | symbol), Item> = TUnionFirst<Extend<Exclude<U, TUnionTake<U, Item>>, string>>;

// export type PickUntil<T extends [any, any]> = T extends [infer Source, infer Key] ?
//     & TUnionTake<keyof Source, ReturnType<Extend<Key, () => any>>> extends infer Keys ?
//         Compute<{ [key in Extend<Keys, keyof Source>]: Source[key] }>
//     : never
// : never;



export type DeepPartial<T extends object> = { [key in keyof T]?: T[key] extends object ? DeepPartial<T[key]> : T[key] };

export type DeepMount<T> = 
    { [key in keyof T]: T[key] extends object ? DeepMount<T[key]> : any }
;

export type CheckIfObjectExtends<T extends object, E extends object> = T extends E ? IsEqual<DeepMount<T>, DeepMount<E>> : false;

export type Assert<T, E> = T extends E ? T : never;
export type Check<T extends E, E> = T;

export type DeepInfer<T, V> =
    T extends [...V extends any[] ? V : never] ? T :
    T extends { [key in keyof T]: V | DeepInfer<T[key], V> } ? T :
    never
;

export type EntriesHash<Hash extends object = { [key in string]: string }> = Assert<ValueOf<{ [key in keyof Hash]: [key, Hash[key]] }>[], [unknown, unknown][]>;
export type Entries<Hash extends object, UnionEntries = ValueOf<{ [key in keyof Hash]: [key, Hash[key]] }>, Current extends [string, unknown][] = []> = 
    IsEqual<UnionEntries, never> extends true ? Current :
    UnionLast<UnionEntries> extends infer Last ? Entries<Hash, Exclude<UnionEntries, Last>, [Assert<Last, [string, unknown]>, ...Current]> : 
    never
;

export namespace EntriesHash {
    type ComputeArrayValues<T> = T extends unknown[] ? { [key in keyof T]: [{ Value: GetByKey<T[key], '0'>; Key: key }] }[number][number] : never;
    export type NonRepeatedKeys<T, Computed = ComputeArrayValues<T>> = T extends unknown[] ? { [key in keyof T]: [IsEqual<UnionToIntersection<Extract<Computed, { Value: GetByKey<T[key], '0'> }>>, never> extends true ? $$<'Duplicated key'> : unknown, unknown] } : never; // 
    type GetRequiredKeys<T> = T extends object ? Extract<ValueOf<ObjectLiteralMetadata<T>>, { isOptional: false }>['key'] : never;
    export type CheckRequiredKeys<T, Hash> = T extends object ? [Exclude<GetRequiredKeys<Hash>, GetRequiredKeys<FromEntries<T>>>] extends [infer MissingKeys] ? IsEqual<MissingKeys, never> extends true ? unknown : Replace<Assert<T, unknown[]>, [unknown, unknown]> & [[$$<[`Missing the following properties`, MissingKeys]>, unknown], ...unknown[]] : never : never;
}

export type FromEntries<T> =
    [T] extends [[unknown, unknown][]] ? 
        Compute<
        UnionToIntersection<
            ({ [key in keyof T]: T[key] extends [infer Key, infer Value] ? { [subKey in Assert<Key, string>]: Value } : never })[number]
        > 
        > 
    : never
;
export type GetCustomKeys<T extends object, ShouldBePartial extends boolean = false> = T extends unknown ? ValueOf<{ [key in keyof T]: IsPartial<T, key> extends ShouldBePartial ? key : never }> : never;

type TValidHashEntry = [...ItPropertyType[], unknown];
type FromEntry<T > = T extends [infer Item, ...infer Items] ? Items extends [] ? Item : { [key in Assert<Item, ItPropertyType>]: FromEntry<Items> } : never;
export type DeepFromEntries<T> = Compute<UnionToIntersection<$ValueOf<{ [key in keyof T]: FromEntry<T[key]> }>>>

export type IsPartial<T extends object, Key extends keyof T> = Pick<T, Key> extends infer Source ? IsEqual<Source, Partial<Source>> : never;
export type IsReadonly<T extends object, Key extends keyof T> = Pick<T, Key> extends infer Source ? IsEqual<Source, Readonly<Source>> : never;

// @ts-ignore
export type GetByKey<T, Key extends (keyof T | {})> = T[Key];

export type NormalizedGetByKey<T, Key> = T extends [...infer V] ? IsEqual<T['length'], number> extends true ? T[number] : GetByKey<T, Key> : GetByKey<T, Key>;

type CreateTemplateSplit<N extends number, Char extends string = `${number}`, Current extends string[] = [Char]> = Current['length'] extends N ? Current :
    CreateTemplateSplit<N, Char, [`${Current[0]}${Char}`, ...Current]>
;
type TemplateSplit = CreateTemplateSplit<10>;
type DefaultTemplate = TemplateSplit[0];

export type SplitN<T, Current extends any[] = []> = T extends '' ? Current :
    T extends `${DefaultTemplate}${infer N}` ? T extends `${infer First}${N}` ? SplitN<N, [...Current, First]> : never : SplitN<'', [...Current, T]>
;
export type BasicSplit<T, Current extends any[] = []> = T extends '' ? Current : T extends `${number}${infer N}` ? T extends `${infer First}${N}` ? BasicSplit<N, [...Current, First]> : never : never;


export type $notEmpty = {};
export type $unknown = Nullable<$notEmpty>;
export type $any = $unknown;

export type $string = string & {};
export type $number = number & {};

/**
 * Truthy or Falsy
 */
export type $boolean = $unknown;

export type SplitDepth<T extends StringNumber, Items extends StringNumber[] = SplitN<T>> = Flat<{ [key in keyof Items]: BasicSplit<Items[key]> }>;

export type ConcatLargestUntilDone<Size extends StringNumber, Chunks extends any[][], Current extends any[] = []> = 
`${Current['length']}` extends `${Size}` ? Current : 
    ConcatLargestUntilDone<
        Size,
        // @ts-ignore
        Tail<Chunks>,
        [...Chunks[0], ...Current] extends infer NextCurrent ?
            // @ts-ignore
            IsEqual<NextCurrent[Size], undefined> extends false ? Current : NextCurrent
        : never
    >
;
export type NullishCoalescing<T, E> = [IsEqual<T, undefined> | IsEqual<T, null>] extends [false] ? NonNullable<T> : E;
export type EmptyCoalescing<T extends unknown[], Current extends unknown[] = T> =
    [Current] extends [[infer Item, ...infer Items]] ? [IsEmpty<Item>] extends [true] ? EmptyCoalescing<T, Items> : Item : Current[0]
;

export type Nullable<T> = T | undefined | null;


export type ToTuple<N extends StringNumber, Item = never> = ConcatLargestUntilDone<N, LogTo<N, Item>>;
export type UnShift<T extends any[]> = T extends [...infer Remaining, infer Last] ? Remaining : never;
export type Tail<T extends any[]> = T extends [T[0], ...infer Remaining] ? Remaining : never;
export type LogTo<Size extends StringNumber, Item = never, Current extends Item[][] = [[Item]]> = 
    // @ts-ignore
    IsEqual<Current[0][Size], Item> extends true ? Current : LogTo<Size, Item, [[...Current[0], ...Current[0]], ...Current]>
;

declare namespace $String {
    type Tail<T> = T extends `${infer First}${infer Others}` ? Others : T;
    type Last<T extends string> = Split<T, ''> extends [...infer Items, infer Item] ? Item : never;
}


export declare namespace Alphabet {
    namespace Text {
        type Lower = 'abcdefghijklmnopqrstuvwxyz';
        type Upper = Uppercase<Lower>;
    }
    namespace List {
        type Map<T extends string, Items extends string[] = Split<T, ''>> = Items;
        type Lower = Map<Text.Lower>;
        type Upper = Map<Text.Upper>;
    }
    namespace Union {
        type Lower = List.Lower[number];
        type Upper = List.Upper[number];
    }
    type Lower = Union.Lower;
    type Upper = Union.Upper;
}
declare namespace Patterns {
    type Pascal = `${Alphabet.Upper}${Alphabet.Lower}`;
    type Upper = Alphabet.Upper;
    type Digit = `${number}`;
}
type MatchV2<T extends string, Pattern extends string> =
    T extends `${Pattern}${infer Others}` ?
    T extends `${infer $Match}${Others}` ?
    [$Match, Others]
    : never
    : never
;
type MatchV2All<T extends string, Patterns extends unknown[]> =
    Patterns extends [infer Pattern, ...infer Others] ?
    [MatchV2<T, Assert<Pattern, string>>] extends [[infer $Match, infer Text]] ?
    [IsUnknown<$Match>] extends [true] ?
    MatchV2All<T, Others>
    : [$Match, Text, Pattern]
    : never
    : never
;

export type Last<T> = T extends [...infer Items, infer Item] ? Item : never;
export type Pop<T extends unknown[]> = T extends [...infer O, infer L] ? O : never;


export type MakeChunks<
    Size extends StringNumber,
    Items extends any[][],
    Current extends any[] = [],
    Chunk extends any[] = ToTuple<Size, any>,
> =
    Items extends [...Chunk, ...infer Others] ? Items extends [...infer FromChunk, ...Others] ? MakeChunks<Size,
    // @ts-ignore
    Others,
    [...Current, FromChunk]> : never : [...Current, Items]
;


export declare namespace Flat {
    type SimpleFlat<Items extends any[][], Current extends any[] = []> = 
        Items extends [...infer Other, infer Last] ?
            // @ts-ignore
            [...Last, ...Current] extends infer NextCurrent ?
                SimpleFlat<
                    // @ts-ignore
                    Other,
                    NextCurrent
                >
            : never
        : Current
    ;
    
    type FlatDepth<Items extends any[][]> = 
        // @ts-ignore
        IsEqual<Items['22'], undefined> extends false ? FlatChunks<MakeChunks<'20', Items>> : SimpleFlat<Items>
    ;
    type FlatChunks<Current extends any[]> = SimpleFlat<{ [key in keyof Current]: SimpleFlat<
        // @ts-ignore
        Current[key]
    > }>;
}
export type Flat<Items extends any[][]> = Flat.FlatDepth<Items>;

export declare namespace Join {
    type SimpleJoin<T extends string[], Prefix extends string, ToFilter extends string = never, Current extends string = ``> = T extends [] ? Current :
        T extends [infer First, ...infer Others] ? SimpleJoin<Assert<Others, string[]>, Prefix, ToFilter, `${Current}${First extends ToFilter ? '' : `${Current extends '' ? '' : First extends '' ? '' : Prefix}${Assert<First, string>}`}`> : never
    ;
    type JoinDepth<Items extends any[], Prefix extends string, ToFilter extends string> = 
        IsEqual<GetByKey<Items, '22'>, undefined> extends false ? JoinChunks<MakeChunks<'22', Items>, Prefix, ToFilter> : SimpleJoin<Items, Prefix, ToFilter>
    ;
    type JoinChunks<Current extends any[], Prefix extends string, ToFilter extends string> = SimpleJoin<{ [key in keyof Current]: SimpleJoin<Assert<Current[key], string[]>, Prefix, ToFilter> }, Prefix, ToFilter>;
}

export type Join<Items extends any[], Prefix extends string = '', ToFilter extends string = ''> = Join.JoinDepth<Items, Prefix, ToFilter>;


export declare namespace MetaGetDeepTypedProperty {
    class $$$<Property> {
        static readonly type: unique symbol;
        private [$$$.type]?: Property;
    }

    type MapDeepTypedProperty<Source, Options extends ITransformSource = {  }, Key extends any[] = []> = 
        { [key in keyof Source]-?:
            [...Key, `${Assert<key, string | number>}`] extends infer CurrentKey ?
            CurrentKey extends any[] ?
            (Options['IsIgnoringArray'] extends true ? GetIgnoredArray<Source[key]> : Source[key]) extends infer SourceValue ?
                & 
                    ((IsObject<SourceValue> extends true ? MapDeepTypedProperty<SourceValue, Options, [...CurrentKey]>  :
                        Options['IsPreservingValues'] extends true
                        ? SourceValue :
                        Options['IsReplacingValuesWithDeepPaths'] extends true ? Join<CurrentKey, '.'>  : $$<Join<CurrentKey, '.'>> & SourceValue))
            : never
            : never
            : never
        }
    ;

    type RecursiveKeys<Source, Key extends any[] = []> = 
        ReMapObject<{ [key in keyof Source]: ReMapObject<{ [$key in (Key extends [] ? key : Join<[...Key, key], '.'>)]:  [GetIgnoredArray<Source[key]>] extends [infer Item] ? [IsObject<Item>] extends [true] ? RecursiveKeys<Item, [...Key, key]> : { [$$key in $key]: Item } : never }> }>
    ;

    type NotObject = IdDep<any>;
    type IsObject<T> = CheckExpression<IsMatch<T, object> & Negate<IsMatch<T, NotObject>>>;
    
    type ValidateProperty<T, Item, Source = IgnoreArray<T>> = 
        GetDeepPropertyStringValue<Source, Assert<Item, string>> extends infer DeepValue ?
        Item extends DeepValue ?
        unknown
        : $$<['Value must be', DeepValue]>
        : never
    ;
    type ValidateProperties<T, Predicates, Source = IgnoreArray<T>> = {
        [key in keyof Predicates]:
            GetDeepPropertyStringValue<Source, Assert<key, string>> extends infer DeepValue ?
            Predicates[key] extends DeepValue ?
            unknown
            : $$<['Value must be', DeepValue]>
            : never
    };

    export type GetDeepPropertyStringValue<T, Properties extends string> = GetDeepPropertyValue<T, Split<Properties, '.'>>
    export type GetDeepPropertyStringValueWithIgnoredArray<T, Properties extends string> = GetDeepPropertyStringValue<IgnoreArray<T>, Properties>;

    type IgnoreArray<T> = { [key in keyof T]: GetIgnoredArray<T[key]> extends infer Value ? IsObject<Value> extends true ? IgnoreArray<Value> : Value : never }
    type GetIgnoredArray<T> = T extends (infer Value)[] ? Value : T;
    
  
    interface ITransformSource {
        IsIgnoringArray?: boolean;
        IsPreservingValues?: boolean;
        IsReplacingValuesWithDeepPaths?: boolean;
    }
}
export interface IFilterOptions {
    IsNegating: boolean,
    IsIndex: boolean
}

export type Filter<Items extends any[], Predicate, Options extends IFilterOptions = { IsIndex: false; IsNegating: false }, Negate extends boolean = Options['IsNegating'] extends true ? true : false> =
    { [key in keyof Items]: (Items[key] extends Predicate ? true : false) extends Negate ? never : Options['IsIndex'] extends true ? key : Items[key] }[number]
;
export type Split<T extends string, Delimiter extends string, Current extends string[] = []> = 
    T extends '' ? Current :
    T extends `${infer Value}${Delimiter}${infer OtherValue}` ? Split<OtherValue, Delimiter, [...Current, Value]>  : 
    [...Current, T]
;

export type EcmaSplit<T extends string, Delimiter extends string, Current extends string[] = []> = 
    Delimiter extends '' ? Split<T, ''> :
    T extends '' ? Current extends [] ? [] : [...Current, T] :
    T extends `${infer Value}${Delimiter}${infer OtherValue}` ? EcmaSplit<OtherValue, Delimiter, [...Current, Value]>  : 
    [...Current, T]
;



export type GetDeepPropertyValue<T, Properties extends string[]> =
    Properties extends [] ? T :
    Properties extends [infer Current, ...infer Others] ? GetDeepPropertyValue<NonNullable<GetByKey<T, Current>>, Others extends any[] ? Others : []>
    : never
;



export interface IObjectLiteralMetadataClass {
    isPrivate?: boolean;
    isStatic?: boolean;
}
export interface IObjectLiteralMetadata extends IObjectLiteralMetadataClass {
    isReadonly: boolean;
    isOptional: boolean;
    isKeyIn?: boolean;
    value: object;
    key: unknown;
}

export type HashObjectLiteralMetadata<T> =
    { [key in keyof T]: IObjectLiteralMetadata }
;

export type ObjectLiteralMetadata<T extends object, ForceKeys extends string = never> =
    & { [key in (keyof T)]: Explicit<IObjectLiteralMetadata, { key: key; isReadonly: Assert<IsReadonly<T, key>, boolean>; isOptional: IsPartial<T, key>; value: { [tkey in key]: T[key] } }> }
    // @ts-ignore
    & { [key in (ForceKeys)]: Explicit<IObjectLiteralMetadata, { key: key; isReadonly: Assert<IsReadonly<T, key>, boolean>; isOptional: IsPartial<T, key>; value: { [tkey in key]: T[key] } }> }
;

export type SimpleDifferences<T, E, DiffT extends keyof T = Exclude<keyof T, keyof E>, DiffE extends keyof E = Exclude<keyof E, keyof T>> =
    & { [key in DiffT]: T[key] }
    & { [key in DiffE]: E[key] }
;

export type NonNullableGetByKey<T, Key extends (keyof T | {})> = NonNullable<GetByKey<T, Key>>;

export type DifferencesDebug<T extends object, OtherT extends object,
    Result = T extends unknown ? 
    OtherT extends unknown ? 
    ObjectLiteralMetadata<T> extends infer MetadataT ? 
    ObjectLiteralMetadata<OtherT> extends infer MetadataOtherT ? 
    ({ [key in (keyof T | keyof OtherT)]:
        IsEqual<NonNullableGetByKey<MetadataT, key>, NonNullableGetByKey<MetadataOtherT, key>> extends true
            ? never
            : NonNullableGetByKey<NonNullableGetByKey<MetadataOtherT, key>, 'value'> extends infer Value ? IsEqual<Value, unknown> extends true ? never : Value : never
    } extends infer Result ? Compute<UnionToIntersection<ValueOf<Result>>> : never)
    : never
    : never
    : never
    : never
> = 
    Result
;

export type Differences<T extends object, OtherT extends object,
    Result = T extends unknown ? 
    OtherT extends unknown ? 
    ObjectLiteralMetadata<T> extends infer MetadataT ? 
    ObjectLiteralMetadata<OtherT> extends infer MetadataOtherT ? 
    ({ [key in (keyof T | keyof OtherT)]:
        IsEqual<NonNullableGetByKey<MetadataT, key>, NonNullableGetByKey<MetadataOtherT, key>> extends true
            ? never
            : NonNullableGetByKey<NonNullableGetByKey<MetadataOtherT, key>, 'value'> extends infer Value ? IsEqual<Value, unknown> extends true ? never : Value : never
    } extends infer Result ? Compute<UnionToIntersection<ValueOf<Result>>> : never)
    : never
    : never
    : never
    : never
> = 
    Result
;


// https://stackoverflow.com/questions/42999983/typescript-removing-readonly-modifier
export type TWriteable<T> = { -readonly [P in keyof T]: T[P] };
export type TDeepWriteable<T> = { -readonly [P in keyof T]: TDeepWriteable<T[P]> };

export type AfterNarrow<U, T = unknown> = 
  [U] extends [infer V] ? V : never
;

export type Narrowable = string | number | bigint | boolean;

export type Narrow<T> = 
    T extends [] ? [] : 
    T extends Narrowable ? T : 
    { [key in keyof T]: T[key] extends Function ? T[key] : Narrow<T[key]> }
;

export type $ValueOf<T> = T extends unknown[] ? T[number] : T[keyof T];

// Including private values
export type $PrivateValueOf<T> =
    (T & { [key in keyof T]: never }) extends { [key in infer Key]: infer Value } ? Value : never
;

export type NoInfer<A> = A extends unknown ? A : never;


class _TClass {}
type IsClass<T> = T extends typeof _TClass ? true : false;


enum EHasKeyOn {
    prototype = 'prototype',
    static = 'static',
    never = 'never',
}

type TObjectLiteralMetadataValue<
    Source extends {},
    Key extends keyof Source,
    SKey extends string = Assert<Key, string>,
    isClass extends boolean = IsClass<Source>,
    hasKeyOn extends EHasKeyOn = (
        HasKey<Source, SKey> extends true ?
        EHasKeyOn.static
        : (
            Source extends typeof _TClass ?
            HasKey<Source['prototype'], SKey> extends true ?
            EHasKeyOn.prototype
            : EHasKeyOn.never
            : EHasKeyOn.never
        )
    ),
    isKeyIn extends boolean = hasKeyOn extends EHasKeyOn.never ? false : true,
    SourceWithKey extends {} = hasKeyOn extends EHasKeyOn.prototype ? Assert<Source, typeof _TClass>['prototype'] : Source,
    // @ts-expect-error
    WKey extends keyof SourceWithKey = SKey,
    value extends {} = { [tkey in WKey]: SourceWithKey[WKey] },
    isReadonly extends boolean = Assert<IsReadonly<SourceWithKey, WKey>, boolean>,
    isOptional extends boolean = IsPartial<SourceWithKey, WKey>,
> = Compute<
    & Explicit<
        IObjectLiteralMetadata,
        {
            key: Key;
            isReadonly: isReadonly;
            isOptional: isOptional;
            value: value;
            isKeyIn: isKeyIn;
        }
    >
    & (
        isClass extends true 
        ? Explicit<
             IObjectLiteralMetadataClass,
            {
                isStatic: hasKeyOn extends EHasKeyOn.static ? true : false;
                isPrivate: IsPrivate<SourceWithKey, SKey>;
            }
        >
        : unknown
    )
>;

export type TObjectLiteralMetadata<T extends object, ForceKeys extends string = never, Keys extends keyof T = 
// @ts-expect-error
(ForceKeys | (IsClass<T> extends true ? Exclude<keyof T, 'prototype'> : keyof T))
> = 
    & { [key in Keys]: TObjectLiteralMetadataValue<T, key> }
;

export type Invoke<Fn extends (...a: Params) => B, Params extends unknown[], B> = B;

export function inMemoryMatch<Target extends {}, Predicate extends {}>(
    target: Target,
    predicate: Predicate,
): boolean {
    if (Array.isArray(target)) return target.some(target => inMemoryMatch(target, predicate));
    if (isPrimitive(target)) return Object.is(target, predicate);
    return  _.isMatchWith(target, predicate, inMemoryMatch);
}

const notPrimitive: Set<unknown> = new Set([
    typeof isPrimitive,
    typeof [],
]);
export function isPrimitive(value: any): value is Primitive {
    return nullishSet.has(value) || !notPrimitive.has(typeof value);
}


export function createClientPredicatesFromEntity<T extends {}>(source: T) {
    const out: {} = {};
    recursiveKeys(source).map(key => Reflect.set(out, key, _.get(source, key)))
    return out;
}

export function useClientPredicates<T>() {
    type $Proxy = MetaGetDeepTypedProperty.MapDeepTypedProperty<T, {
        IsIgnoringArray: true;
        IsReplacingValuesWithDeepPaths: true;
    }>;
    return <ReturnType extends Narrow<TReturnType>, TReturnType>(fn: (source: $Proxy) => ReturnType & AfterNarrow<MetaGetDeepTypedProperty.ValidateProperties<T, ReturnType>>) => {
        const $proxy: $Proxy = getTypedProperty();
        const predicates = fn($proxy);
        return (predicates);
    };
}
export type FindEnumValueFromLiteral<Enum extends EnumValue, Literal> = 
    IsEqual<Literal, GetGeneric<Literal>> extends true ? never :
    Enum extends infer Value ?
    Value extends EnumValue ?
    IsEqual<Value, Literal> extends false ?
    `${Value}` extends Literal ? Value
    : never
    : never
    : never
    : never
;


export function checkEarlyReturnSwitch<Ignore = never>() {
    return <Source, Missing = Exclude<Source, Ignore>>(source: Source & (IsNever<Missing> extends true ? unknown : $$<['Missing', Missing, 'on switch']>)) => {}
}



type DefaultMapKeys = Custom.$Utility.Format<Custom.$Utility.$PlaceholderText<'index'>>
type DefaultMapValues = Custom.$Utility.Identity

type CustomFormatEnum = Explicit<Required<IFormatCaseEnum>, {
    Keys: DefaultMapKeys;
    Values: DefaultMapValues;
    Union: never;
}>

interface IFormatCaseEnum {
    Keys?: Custom.Generic;
    Values?: Custom.Generic;
    Union?: string;
}

type PreviousItDefaultParams<T extends E, Default extends E, E, Keys extends keyof E = Extract<keyof E, keyof Default>> = { [key in Keys]: IsUnknown<T[key]> extends true ? GetByKey<Default, key> : T[key] }
export type PreviousDefaultParams<T extends E, Default extends E, E = unknown> = IsUnknown<T> extends true ? Default : T extends object ? PreviousItDefaultParams<T, Default, E> : T;
export type DefaultParams<T, Default> = Compute<
    & T
    & { [key in keyof Default as (THasKey<T, key> extends false ? key : never)]: Default[key] }
>;



export type TDefineEnumConfig<Config extends $Extends<Config, IFormatCaseEnum>> = Config;
export function defineEnum<Config extends $Extends<Config, IFormatCaseEnum>, TConfig extends Compute<IFormatCaseEnum> = PreviousDefaultParams<Config, CustomFormatEnum, IFormatCaseEnum>>(): <
    Enum extends $Extends<Enum, ItNarrowable>,
    Validate = Custom.Chain<Enum, [
        Custom.$Utility.MapKeys<TConfig['Keys']>,
        Custom.$Utility.Map<TConfig['Values']>,
    ]>,
>(_enum:
    & Enum
    & AfterNarrow<Enum extends unknown ?
        & $$.ExtendsObject<Enum, Validate>
        & (
            IsEmpty<TConfig['Union']> extends false ?
            $$.CheckIfHasAllEnumValues<Assert<Enum, EnumObject>, TConfig['Union']>
            : unknown
        )
    : never>
) => Enum
export function defineEnum() {
    return (_enum: object): object => {
        return _enum;
    }
}

declare namespace DefineEnum {
    namespace ByKeys {
        interface Case<CustomGeneric extends Custom.Generic> extends Custom.Generic {
            compute: () => Custom.Chain<this['index'], [CustomGeneric]> 
        }
    }
}
// export const defineCamelCaseEnum = defineEnum<{ Keys: DefineEnum.ByKeys.Case<WordUtils.ICamelCase> }>();
// export const definePascalCaseEnumByValues = defineEnum<{ Keys: WordUtils.IPascalCase }>();

export type IsMatch<T, E> = T extends E ? true : false;
export type ItPropertyType = string | number | symbol;

/**
 * Unnest object
 * @example
 * type Input = {
 *      user: {
 *          name?: string;
 *          age: number;
 *          info: {
 *              size: number;
 *          }
 *      }
 *      text: string;
 *      message: string;
 *  }
 * type Output = {
 *     UserName?: string;
 *     UserAge: number;
 *     UserInfoSize: number;
 *     Text: string;
 *     Message: string;
 * }
 */
export type FlatProperties<T, Prefix = ''> =
    Compute<UnionToIntersection<$ValueOf<{ [key in keyof T]: key extends string ? IsObject<T[key]> extends true ? FlatProperties<T[key], `${Extract<Prefix, string>}${Capitalize<key>}`> : { [$key in key as `${`${Extract<Prefix, string>}${Capitalize<key>}`}`]: T[key] } : never }>>>
;


export type DeepReMapObject<T> = DeepCompute<UnionToIntersection<$ValueOf<T>>>
export type ReMapObject<T> = Compute<UnionToIntersection<NonNullable<$ValueOf<T>>>>


type State$Add = unknown;

type $AddName = [name: string];
type $SourceAdd = [...$AddName, ...[source: unknown]];
type $BasicAdd = [it: (item: {}, index: number, items: {}[], state: State$Add) => unknown, updateState?: ((item: {}, state: State$Add) => State$Add)];
type $AddProp = [...$AddName, ...$BasicAdd];

type $Add =
    | $AddName
    | $SourceAdd
    | $AddProp
    | $BasicAdd
;
export type _$Add = typeof $add;
// export function $add<Name extends string, T>(name: Name): (item: T, index: number, items: T[]) => Compute<{ [key in Name]: T }>
// export function $add<Name extends string, T extends {}, TR, State extends object>(name: Name, it: TR | ((item: T, index: number, items: T[], state: State) => TR), updateState?: ((item: T, state: State) => State)): (item: T, index: number, items: T[]) => Compute<{ [key in keyof T as (key extends Name ? never : key)]: T[key] } & { [key in Name]: TR } >
// export function $add<T extends {}, TR, State extends object>(it: (item: T, index: number, items: T[], state: State) => TR, updateState?: ((item: T, state: State) => State)): (item: T, index: number, items: T[]) => TR
// export function $add<T extends {}>(...params: $Add) {
//     let state: object = {}
//     if (is<$BasicAdd>(() => typeof params[0] === 'function')(params)) {
//             const [it, updateState] = params
            
//             return (item: T, index: number, items: T[]) => {
//             state = updateState?.(item, state) ?? state
//             return it(item, index, items, state)
//         }
//     }

//     if (is<$AddProp>(() => (typeof params[0] === 'string') && (typeof params[1] === 'function'))(params)) {
//         const [name, it, updateState] = params
//         return (item: T, index: number, items: T[]) => {
//             state = updateState?.(item, state) ?? state
//             item[name] = it(item, index, items, state)
//             return item
//         }
//     }

//     if (is<$SourceAdd>(() => (typeof params[0] === 'string') && (typeof params[1] !== 'function') && (params.length === 2))(params)) {
//         const [name, source] = params
//         return (item: T, index: number, items: T[]) => {
//             item[name] = source
//             return item
//         }
//     }

//     const [name] = params
    
//     return (item: T) => {
//         return { [name]: item }
//     }

//     function is<T>(checker: () => boolean) {
//         return (source: unknown): source is T => checker()
//     }
// }

// type $AddName = [name: string];
// type $SourceAdd = [...$AddName, ...[source: unknown]];
// type $BasicAdd = [it: (item: {}, index: number, items: {}[], state: object) => unknown, updateState?: ((item: {}, state: object) => object)];
// type $AddProp = [...$AddName, ...$BasicAdd];

// type T$Add =
//     | $AddName
//     | $SourceAdd
//     | $AddProp
//     | $BasicAdd
// ;

export function $add<Name extends string, T>(name: Name): (item: T, index: number, items: T[]) => Compute<{ [key in Name]: T }>
export function $add<Name extends string, T extends {}, TR, State>(name: Name, it: TR | ((item: T, index: number, items: T[], state: State) => TR), updateState?: ((item: T, state: State) => State)): (item: T, index: number, items: T[]) => T extends object ? Compute<{ [key in keyof T as (key extends Name ? never : key)]: T[key] } & { [key in Name]: TR } > : AfterNarrow<Compute<{ [key in Name]: T }>>
export function $add<T extends {}, TR, State>(it: (item: T, index: number, items: T[], state: State) => TR, updateState?: ((item: T, state: State) => State)): (item: T, index: number, items: T[]) => TR
export function $add<T extends {}>(...params: $Add) {
    let state: unknown;
    if (is<$BasicAdd>(() => typeof params[0] === 'function')(params)) {
            const [it, updateState] = params
            
            return (item: T, index: number, items: T[]) => {
            state = updateState?.(item, state) ?? state
            return it(item, index, items, state)
        }
    }

    if (is<$AddProp>(() => (typeof params[0] === 'string') && (typeof params[1] === 'function'))(params)) {
        const [name, it, updateState] = params
        return (item: T, index: number, items: T[]) => {
            state = updateState?.(item, state) ?? state
            return setOrInitObject(name, item, it(item, index, items, state))
        }
    }

    if (is<$SourceAdd>(() => (typeof params[0] === 'string') && (typeof params[1] !== 'function') && (params.length === 2))(params)) {
        const [name, source] = params
        return (item: T, index: number, items: T[]) => {
            return setOrInitObject(name, item, source)
        }
    }

    const [name] = params
    
    return (item: T) => {
        return { [name]: item }
    }

    function setOrInitObject<Source>(name: string, item: T, source: Source) {
      if (typeof item !== 'object') return {
        [name]: source,
      }
      item[name] = source;
      return item;
    }

    function is<T>(checker: () => boolean) {
        return (source: unknown): source is T => checker()
    }
}

export const $emptyDecorator = () => () => {};

export function $declare<T>(): T {
    return empty;
}

export function $convert<T>(source: unknown): T {
    return source as T;
}
export function $cast<T, E>(source: T & ([IsMatch<T, E> | IsMatch<E, T>] extends [false] ? $$<`Can't cast`> : unknown)): E {
    return source as E
}





export function $assert<E>(source: E) {
    return <T extends E>() => source as T;
}

export type ToCamelCase<T> = ReMapObject<{ [key in keyof T]: { [$key in key as CamelCase<Assert<key, string>>]: T[key] } }>;
export type ToCapitalize<T> = ReMapObject<{ [key in keyof T]: { [$key in key as Capitalize<Assert<key, string>>]: T[key] } }>;

export type IsEnumOfUnion<Union, Enum, Values = UnionDifferences<Union, $ValueOf<Enum>>> = [Values] extends [never] ? true : false;
export type UnionDifferences<A, B> =
    Exclude<A | B, A & B>
;

/**
 * @example CheckExpression<true | true> // true
 * @example CheckExpression<true | false> // true
 * @example CheckExpression<true & true> // true
 * @example CheckExpression<false & true> // false
 * @example CheckExpression<(false & true) | (true & true) & false> // false
 */
export type CheckExpression<T extends boolean> = [T] extends [false] ? false : true;


export type EAssert<T extends Assert<E, U>, E, U = unknown> = never;

export type IsUnion<T> = Negate<IsEqual<UnionLast<T>, T>>;


type _MapValues<T, DeepValueKey extends string> = { [key in keyof T]: _Item<T[key], DeepValueKey, key> }
interface _Item<
    Value,
    DeepValueKey extends string,
    Key extends ItPropertyType = any
> { key: Key; value: MetaGetDeepTypedProperty.GetDeepPropertyStringValue<Value, DeepValueKey> }
type _ConditionalPartial<T, IsPartial extends boolean> = [IsPartial] extends [false] ? T : Partial<T>;

export type NonRepeatedValuesV2<T, DeepKey extends string = ''> =
    never extends EAssert<infer Mapped, _MapValues<T, DeepKey>> ?
    never extends EAssert<infer Items, $ValueOf<Mapped>> ?
        { [key in keyof T]: [UnionToIntersection<Extract<Items, _Item<T[key], DeepKey>>>] extends [never] ? $$<'Error'> : unknown }
    : never
    : never
;

interface IDefineScreenOptionsConfig { IsPartial?: boolean; IsLimitingFields?: boolean; ReturnTranslations?: boolean };

export function defineScreenOptions<Values extends EnumValue>() {
    const noOptions = options();

    function overload(param: IScreenOptions): ReturnType<typeof noOptions>;
    function overload(param: IDefineScreenOptionsConfig): ReturnType<typeof options>;
    function overload(param: IDefineScreenOptionsConfig | IScreenOptions) {
        if (!isKeysIn(param, ['idSerializable'])) return options(param);
        return param;
    }

    return (overload) as (typeof options & typeof noOptions);

    function options<InputOptions extends IDefineScreenOptionsConfig = {}>(options: InputOptions = ({} as InputOptions)) {
        type Options = DefaultParams<InputOptions, {
            IsPartial: false;
            IsLimitingFields: false;
            ReturnTranslations: false;
        }>

        
        type Define = _ConditionalPartial<{ [key in Values]: number }, Options['IsPartial']>;

        return function $define<
            Input extends IntersectPartialExplicit<IScreenOptions, { fields: Define }>,
            Fields extends Input['fields'],
        >(
            source: $NarrowV2<Input>
            & AfterNarrow<
                Input extends unknown ?
                [$$.IsAsConst<Input>] extends [false] ? {
                    fields: (
                        & NonRepeatedValuesV2<Fields>
                        & { [key in keyof Fields]: [Negate<Options['IsLimitingFields']> | IsMatch<Fields[key], IdField>] extends [false] ? $$.Range<{ from: 1; to: 25; number: Fields[key] }> : unknown }
                    )
                } : $$<`Remove 'as const'`> : never
            >
        ) {
            return source as Input;
        }
    }
}


export type UnNarrow<T, TNarrowable extends Narrowable = Narrowable> =
    T extends TNarrowable ? 
    T extends unknown ?
    TNarrowable extends unknown ?
    T extends TNarrowable ?
    TNarrowable extends T ? T : TNarrowable // Prevent enums
    : never
    : never
    : never
    : T
;



export type NamedSet<T extends [unknown]> = Set<T[0]> & CCustomType<'NamedSet'>;

// type InterfaceToFn<T, State extends Partial<T> = {}, Keys extends keyof T = Exclude<keyof T, keyof State>> = {
//     [key in Keys]: <Value extends (T & State)[key]>(param: Value) => InterfaceToFn<T, State & { [$key in key]: Value }> extends infer Computed ? {} extends Computed ? Compute<State & { [$key in key]: Value }> : Computed : never
// }
type InterfaceToFn<T, State extends Partial<T> = {}, Keys extends keyof T = Exclude<keyof T, keyof State>> = {
    [key in Keys]: <Value extends (T & State)[key]>(param: Value) => InterfaceToFn<T, State & { [$key in key]: Value }> extends infer Computed ? {} extends Computed ? Compute<State & { [$key in key]: Value }> : Computed : never
}

export type TPropertyKeyLiteral<T, K extends keyof T> = K;



/**
 * Regular expression with literal types for inferences
 */
export class $RegExp<T extends string, Flags extends string = string> extends RegExp {
    declare source: T;

    constructor(pattern: T | RegExp, flags?: Flags) {
        super(pattern, flags)
    }
}

type GroupName<T extends string> = `(?<${T}>${string})`;

type ExtractGroupsFromPattern<Source extends string, Current extends string[] = []> =
    Source extends `${string}${GroupName<infer Name>}${infer Remaining}`
    ? ExtractGroupsFromPattern<Remaining, [...Current, Name]>
    : Current
;

export type MapGroups<
    T extends string,
    Groups extends string[] = ExtractGroupsFromPattern<T>,
    Computed = { [key in Groups[number]]: string }
> =
    Computed
;

export function matchGroup<Source extends string>(pattern: $RegExp<Source>): (text: string) => $RegExpMatchArray<Source> | null
export function matchGroup<Source extends string>(pattern: $RegExp<Source>) {
    return <T extends string>(text: T) => {
        return text.match(pattern);
    };
}

export function matchGroupsFromPattern<Source extends string>(pattern: $RegExp<Source>): (text: string) => $RegExpMatchArray<Source>[]
export function matchGroupsFromPattern<Source extends string>(pattern: $RegExp<Source>) {
    return <T extends string>(text: T) => {
        resetPattern(pattern);
        return [...text.matchAll(pattern)];
    };
}

interface $RegExpMatchArray<Source extends string> extends RegExpMatchArray {
    groups?: MapGroups<Source>;
    indices?: $RegExpMatchArrayIndices<Source>;
}

interface $RegExpMatchArrayIndices<Source extends string> extends Array<[number, number]> {
    groups?: { [key in ExtractGroupsFromPattern<Source>[number]]?: [number, number] }
}


/**
 * A simple join with literal types
 */
export function join<T extends [string?, ...string[]]>(...items: T): Join<T, ''>;
export function join<T extends [string?, ...string[]]>(...items: T): string {
    return items.join('');
}


export type AddCapitalizedWord<T extends string> = Capitalize<T> | T;

/**
 * Create interface rules from a definition
 * @example
 *   @input {
 *     name: string;
 *     id: string;
 *     timestamp: number;
 *   }
 *   @output {
 *     [x: `${string}name`]: string;
 *     [x: `${string}Name`]: string;
 *     [x: `${string}id`]: string;
 *     [x: `${string}Id`]: string;
 *     [x: `${string}timestamp`]: number;
 *     [x: `${string}Timestamp`]: number;
 *   }
 */
export type MakeRules<T> = ReMapObject<{
    [key in keyof T]: {
        [$key in `${string}${AddCapitalizedWord<Extract<key, string>>}`]?: T[key];
    }
}>;


export function getNonNullable<T>(entity: T | null | undefined, name?: string): T {
    assertNonNullable(entity, name);
    return entity;
}



export type Nullish = undefined | null;

export declare namespace TurnURLParametersIntoJSON {
    type TargetOf<Text extends string> =
        Text extends `${string}?${infer Target}`
        ? Target
        : never
    ;

    type ItemsOf<Target extends string> =
        EcmaSplit<Target, '&'>
    ;

    type ToEntriesOf<Target extends string> =
        EcmaSplit<Target, '='>
    ;

    type MapToEntries<T extends string[]> = { [key in keyof T]: ToEntriesOf<Extract<T[key], string>> }

    type Entry = [PropertyKey, unknown?];
    type FromEntry<T extends Entry> = { [key in T[0]]: T[1] };

    type FromEntries<T> = ReMapObject<{ [key in keyof T]: FromEntry<Extract<T[key], Entry>> }>;

    type Output = { [key in string]: string | undefined };

    type Execute<Text extends string, Target = FromEntries<MapToEntries<ItemsOf<TargetOf<Text>>>>> =
        Extract<Target, Output>
    ;
}


export type Empty<T> = T | undefined;

type RecusiveListDeepProperties<T, Prefix extends string = ''> = {
    [key in keyof T]: T[key] extends object ? ValueOf<RecusiveListDeepProperties<T[key], `${Extract<key, string>}.`>> : `${Prefix}${Extract<key, string>}`
};
export type ListDeepProperties<T, Prefix extends string = '', Items = ValueOf<RecusiveListDeepProperties<T, Prefix>>> = Items extends unknown ? Items : never;

export function $getDeepPropertyValue<Source, Property extends string>(source: Source, property: Property): 
    Source extends unknown ?
    Property extends unknown ?
    AnyOrUnknownTo<MetaGetDeepTypedProperty.GetDeepPropertyStringValue<Source, Extract<Property, string>>, never>
    : never
    : never
;
export function $getDeepPropertyValue(source: object, property: PropertyKey): unknown {
    return lodash.get(source, property);
}

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export function defineProperty<T, Property extends keyof T>(
    source: T,
    property: Property,
    descriptor: TypedPropertyDescriptor<T[Property]>
) {
    return Object.defineProperty(source, property, descriptor)
}

export function defineProperties<T>(
    source: T,
    descriptors: TypedPropertyDescriptors<T>
) {
    return Object.defineProperties(source, descriptors)
}

export type TypedPropertyDescriptors<T> = { [key in keyof T]?: TypedPropertyDescriptor<T[key]> }

type _ConvertToEnum<T, Prefix = ''> = ReMapObject<{ [key in keyof T]: { [$key in `${Extract<Prefix, string>}${Capitalize<Extract<key, string>>}`]: T[key] extends object ? $ValueOf<_ConvertToEnum<T[key], $key>> : { [$$key in $key]: T[key] } } }>
export type ConvertToEnum<T, TT = ReMapObject<_ConvertToEnum<T>>> = TT;

export type RemovePrefix<T, Prefix extends string> = T extends `${Prefix}${infer Value}` ? Value : never




/**
 * Auto cast by inference 
 * @example
 * const input: string = empty;
 * const text: 'novo' = cast(input);
 * @example
 * function getText(): 'novo' {
 *     const input: string = empty;
 *     return cast(input);
 * }
 */
export function cast<T, R extends T>(source: T): R
export function cast<T>(source: T): T {
    return source;
}

export type Search<T, U extends Partial<T>> = U;



export interface ProxyObserverObserver {
    <T extends {}, Property extends keyof T>(input: { target: T; prop: Property; value?: T[Property]; oldValue?: T[Property]; receiver?: unknown }): void
}

/**
 * @link https://github.com/createvibe/proxyobserver
 * @returns 
 */


export interface ProxyObserverWatchers<ObserverFn extends ProxyObserverObserver = ProxyObserverObserver> {
    beforeChange?: ObserverFn;
    afterChange?: ObserverFn;
}


interface ProxyObserver<T extends {}> extends ProxyObserverWatchers {
    target: T;
    map?: Map<unknown, unknown>;
}
export function proxyObserver<T extends {}>({ target, beforeChange, afterChange, map = new Map() }: ProxyObserver<T>) {
    try {
        const proxy: T = new Proxy(target, {
            get: (target, prop, receiver) => {
                const value = target[prop as keyof T];
                if ((typeof value === 'object') && (value !== null)) {
                    if (!map.has(value)) {
                        const input = {
                            target,
                            prop: prop as never,
                            value: value as never,
                            receiver,
                        }
                        map.set(
                            value,
                            proxyObserver({
                                target: value as {},
                                beforeChange: beforeChange && beforeChange.bind?.(null, input),
                                afterChange: afterChange && afterChange.bind?.(null, input),
                                map,
                            }),
                        );
                    }
                    return map.get(value);
                }
                return value ;
            },
            set: (target, prop , value, receiver) => {
                const oldValue = Unsafe.get(target, prop, receiver);
                if (oldValue === value) {
                    return true;
                }
                const input = { target, prop: prop as ((keyof T) & (PropertyKey)), value, oldValue: oldValue as never, receiver }
                const isAllowingChange = beforeChange ? beforeChange(input) : true;
                if (!isAllowingChange) return oldValue;
                const out = Reflect.set(target, prop, value, receiver);
                afterChange?.(input);
                return out;
            },
            deleteProperty: (target, prop) => {
                if (prop in target) {
                    const oldValue = Unsafe.get(target, prop);
                    const input = {target, prop: prop as never, oldValue: oldValue as never }
                    const isAllowingChange = beforeChange ? beforeChange(input) : true;
                    if (!isAllowingChange) return oldValue;
                    const out = Reflect.deleteProperty(target, prop);
                    afterChange?.(input);
                    return out;
                }
                return false;
            }
        });
        return proxy;
    } catch (err) {
        console.log({
            err,
            target,
        });
        throw err;
    }
}




export namespace DeepMap {
    interface Deep<T extends any[]> {
        get<Items extends Extract<Partial<Poped>, unknown[]>, Poped extends Pop<T>>(items: Items): T extends [...Items, ...infer Remaining] ? DeepMap<Remaining> : Items;
        set<Items extends T>(items: Items): T extends [...Items, ...infer Remaining] ? DeepMap<Remaining> : Items;
        has<Items extends Partial<T>>(items: Items): boolean;
    }

    type Pop<T> = T extends [...infer Items, infer Item] ? Items : never;

    type Output = Map<$unknown, Output>;

    function createDeep<T extends any[]>(map: DeepMap<T>): Deep<T>;
    function createDeep(inputMap: any): { get: TFunction; set: TFunction; has: TFunction } {
        const map: Output = inputMap;
        
        return {
            get,
            set,
            has,
        };

        function has(items: $unknown[]): boolean {
            let output: Output | undefined = map;
            let index = 0; 
            while (index < (items.length - 1)) {
                const item = items[index];
                if (!(output instanceof Map)) return false;
                if (!output.has(item)) return false;
                output = output.get(item);
                index++;
            }
            if (!output) return false;
            return output.has(items[index]);
        }

        function set(items: any[]) {
            let output = map;
        
            let index = 0; 
            while (index < (items.length - 2)) {
                const item = items[index];
                output.set(item, output.get(item) ?? new Map());
                output = output.get(item)!;
                index++;
            }
        
            output.set(items[index], items[index + 1]);
        }


        function get(items: $unknown[]): Output | undefined {
            let output: Output | undefined = map;
            for (const item of items) {
                if (!(output instanceof Map)) return;
                output = output.get(item)
            }
            return output;
        }
    }
}

export type DeepMap<List extends any[]> = (
    List extends [infer Value] ? Value : 
    List extends [infer Value, ...infer Items] ? Map<Value, DeepMap<Items>>
    : never
) & (List extends [unknown] ? unknown : TypeDeepMap<List>);


export function getTypedPropertyDS<T>() {
    type $Proxy = MetaGetDeepTypedProperty.MapDeepTypedProperty<T, {
        IsIgnoringArray: true;
        IsReplacingValuesWithDeepPaths: true;
    }>;

    return getTypedProperty<$Proxy>()

    function getTypedProperty<T>(): T {
        const properties: string[] = [];
      
        const joinAndResetTypedProperties: () => string = initJoinAndResetTypedProperties(properties);
      
        const me: any = new Proxy({}, {
            get: (_target, propertyName) => {
                
                switch (propertyName) {
                    case Symbol.toPrimitive: {
                        return joinAndResetTypedProperties;
                    };
                    default: {
                      properties.push(propertyName as string);
                      return me;
                    }
                }
            },
        });
      
        return me as T;
      }
      function initJoinAndResetTypedProperties(properties: string[]): () => string {
        return (): string => {
            const result: string = properties.join('.');
            properties.splice(0);
            return result;
        }
    }
}

export function getDeepTypedPropertyDS<T>() {
    type $Proxy = MetaGetDeepTypedProperty.MapDeepTypedProperty<T, {
        IsIgnoringArray: true;
        IsReplacingValuesWithDeepPaths: true;
    }>;

    return execute;

    function execute<FieldName extends string>(fn: ($: $Proxy) => FieldName): FieldName
    function execute<FieldName extends string>(fn: ($: $Proxy) => FieldName): string {
        return String(fn(getTypedProperty()));
    }
    
    function getTypedProperty<T>(): T {
        const properties: string[] = [];
      
        const joinAndResetTypedProperties: () => string = initJoinAndResetTypedProperties(properties);
      
        const me: any = new Proxy({}, {
            get: (_target, propertyName) => {
                
                switch (propertyName) {
                    case Symbol.toPrimitive: {
                        return joinAndResetTypedProperties;
                    };
                    default: {
                      properties.push(propertyName as string);
                      return me;
                    }
                }
            },
        });
      
        return me as T;
      }
      function initJoinAndResetTypedProperties(properties: string[]): () => string {
        return (): string => {
            const result: string = properties.join('.');
            properties.splice(0);
            return result;
        }
    }
      
}

export function sort<T, U>(fn: (item: T) => U): ((a: T, b: T) => number);
export function sort<T, U>(fn: (item: T, other: T) => U): ((a: T, b: T) => number);
export function sort<T, U>(input: ((item: T) => U) | ((item: T, other: T) => number)) {
    if (!isSimple(input)) {
        return input;
    }

    return function execute(a: T, b: T): number {
        return sort.compare(input(a), input(b));
    }

    function isSimple(fn: typeof input): fn is (item: T) => U {
        return input.length === 1;
    }
}
export namespace sort {
    export function compare<T>(a: T, b: T): number {
        if (a === b) return 0;
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    }
    
    export const asc = sort;
    export const desc = reverse;

    export function reverse<T, U>(fn: (item: T, other?: T) => U) {
        const sortFn = sort(fn);
        return function execute(inputA: T, inputB: T) {
            return (sortFn(inputA, inputB)) * -1
        }
    }

    export function orderBy<T>(item: { [key in keyof T]: number }) {
        return function execute(inputA: T, inputB: T) {
            for (const name in item) {
                const out = sort.compare(inputA[name], inputB[name]) * item[name];
                if (out) return out;
            }
            return 0;
        }
    }

    export function compose<T, U>(fns: ((item: T, other: T) => AfterNarrow<U>)[]) {
        return function execute(inputA: T, inputB: T) {
            for (let input of fns) {
                const out = sort(input)(inputA, inputB);
                if (out) return out;
            }
            return 0;
        }
    }
}


export namespace StringHelpers {
    export function toLowerCase<T extends string>(target: T): Lowercase<T>
    export function toLowerCase<T extends string>(target: T): string {
        return target.toLowerCase();
    }

    export function toUpperCase<T extends string>(target: T): Uppercase<T>
    export function toUpperCase<T extends string>(target: T): string {
        return target.toUpperCase();
    }
}

export namespace ObjectHelpers {
    export function* keys<Target>(target: Target): Generator<keyof Target> {
        for (const name in target) yield name;
    }
    export function* values<Target>(target: Target): Generator<ValueOf<Target>> {
        for (const name in target) yield target[name];
    }

    export function toCamelCase<From extends {}>(source: From): ToCamelCase<From>
    export function toCamelCase<From extends {}>(source: From): {} {
        return _.mapKeys(source, (item, key) => _.camelCase(key));
    }
}

function merge<T>(input: T[][], items: T[]): T[][];
function merge<T>(input: T[][], items: T[]) {
    return input.map(inputItem => items.map(item => [inputItem, item].flat())).flat();
}

function recursiveMerge<T>(items: T[][], filter?: (list: T[]) => boolean) {
    let input: T[][] = [[]];

    const execute = filter ? withFilter : common;

    for (const item of items) {
        execute(item);
    }

    return input;

    function common(item: T[]) {
        input = merge(input, item);
    }

    function withFilter(item: T[]) {
        common(item);
        input = input.filter(filter!);
    }
}


export function searcher(pattern: string, target: string) {
    const mapCharToIndexes = getMapCharToIndexes();
    const list = getState();

    if (!list) return;

    const merged = recursiveMerge(list, list => {
        if (at(list, -2)! > at(list, -1)!) return false;
        return true;
    });

    let current: { list: number[]; weight: number; difference?: number } | undefined;

    for (const list of merged) {
        const weight = getWeight(list);

        if (!current || (weight > current.weight)) {
            current = {
                list,
                weight,
            };
            continue;
        }

        if (weight < current.weight) continue;

        const difference = getDifference(list);
        current.difference ??= getDifference(current.list);
        
        if (difference < current.difference) current = {
            list,
            weight,
            difference,
        }
    }

    if (current) current.difference ??= getDifference(current.list);

    return current;

    function getWeight(nums: number[]) {
        if (nums.length <= 1) return 0;
        let sum = 0;
        for (let index = 1; index < nums.length; index++) {
            const item = nums[index];
            const previous = nums[index - 1];
            sum += (item) === (previous + 1) ? 1 : 0;
        }
        return sum;
    }

    function at<T>(target: T[], position: number): T | undefined {
        const index = (position < 0) ? target.length + position : position;
        return target[index];
    }

    function getDifference(nums: number[]) {
        if (nums.length <= 1) return 0;
        return at(nums, -1)! - at(nums, 0)!;
    }

    function getMapCharToIndexes() {
        const map = new Map<string, number[]>();
        for (let index = 0; index < target.length; index++) {
            const char = target[index];
            if (!map.has(char)) map.set(char, []);
            map.get(char)!.push(index);
        }
        return map;
    }

    function getState() {
        const state: number[][] = [];

        for (const char of pattern) {
            const items = mapCharToIndexes.get(char);
            if (!items) return;
            state.push(items);
        }

        return state;
    }
}



type IterableSize<T> = Iterable<T> & { [key in number]: T } & { length: number };

export function forEachReversed<T>(items: IterableSize<T>, invoke: (item: T, index: number, source: IterableSize<T>) => void) {
    for (let index = items.length - 1; index >= 0; index--) {
        invoke(items[index], index, items);
    }
}


/**
 * Remount interface with all its properties
 * @example
 * const user = {
 *     type: 'user',
 *     name: '',
 *     lastName: '',
 *     created: 100,
 * }
 * 
 * interface Created {
 *     created: number;
 *     type?: string;
 * }
 * 
 * const created: RemountInterface<Created> = {
 *     type: 'user',
 *     created: 100,
 * } 
 * const error: RemountInterface<Created> = {
 *     // type: 'user',
 *     created: 100,
 * } 
 */
export type RemountInterface<T, Keys extends PropertyKey = keyof T> = { [key in Keys]: GetByKey<T, key> } & { [key in keyof T]: unknown };

export function remountInterface<T>(source: RemountInterface<T>): T {
    return source;
}


export function defineConfig<Definition extends Generic, Generic extends {}>(config: Definition): Generic;
export function defineConfig<Definition extends {}, Generic extends Definition>(config: Definition): Generic;
export function defineConfig(config: {}): {} {
    return config;
}


type ValidateRequiredList<T, U extends T[]> = AfterNarrow<([Exclude<T, U[number]>] extends [infer Missing] ? [Missing] extends [never] ? unknown : $$<['Missing the following items', Missing]>  : never)>;

export function requiredList<T extends string>() {
    return <U extends T[]>(
        items: U & ValidateRequiredList<T, U>
    ): T[] => {
        return items;
    }
}

/**
 * Enable enums to accept their respective text and provide auto-suggestions
 */
export type FlexibilizeEnum<T extends string> = T | `${T}`;

export class RequiredSet<T> extends Set<T> {
    static create<T extends string>() {
        return <U extends T[]>(
            items: U & AfterNarrow<([Exclude<T, U[number]>] extends [infer Missing] ? [Missing] extends [never] ? unknown : $$<['Missing the following items', Missing]>  : never)>
        ) => {
            return new RequiredSet(items);
        }
    }

    is(item: unknown): item is T {
        return this.has(item as T);
    }
}

export type RequiredByValue<T, Target> = ReMapObject<{
    [key in keyof T]-?: [NonNullable<T[key]>] extends [Target] ? { [$key in key]-?: T[key] } : { [$key in key]?: T[key] }
}>;

export type DeepRequiredByValue<T, Target> = 
    [NonNullable<T>] extends [object] ? RequiredByValue<{ [key in keyof T]?: DeepRequiredByValue<T[key], Target> }, Target> : T
;


export type DeepRequired<T> = { [key in keyof T]-?: T[key] extends object ? DeepRequired<T[key]> : T[key] };


export type SetAsCaseInsensitive<T extends string> = 
    | Uppercase<T>
    | Lowercase<T>
;

export type BasicGenName = never;

export type MakeGetters<T> = {
    [name in keyof T as `get${Capitalize<name & string>}`]: T[name]
}
export type MakeSetters<T> = {
    [name in keyof T as `set${Capitalize<name & string>}`]: T[name]
}


export type RequiredOnlyOneV2<T, Key extends keyof T = keyof T> = 
    Key extends unknown ? Compute<Pick<T, Key> & Partial<Pick<T, Exclude<keyof T, Key> >>> : never
;


/**
 * Bem que essa merda podia ser nativa.
 */
export type Not<T, Block> = IsEqual<T, Block> extends true ? T & $$<['Type cannot be', Block]> : T;