import { Avatar } from "@colmeia/core/src/business/avatar";
import { constant } from "@colmeia/core/src/business/constant";
import { Group } from "@colmeia/core/src/business/group";
import { GroupType } from "@colmeia/core/src/business/group-type";
import { Participant } from "@colmeia/core/src/business/participant";
import { PlayerCachedInfo } from "@colmeia/core/src/business/player-cached";
import { TDriverFeedbackArray } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { FriendlyMessage } from "@colmeia/core/src/error-control/friendly-message";
import { Feedback } from "@colmeia/core/src/interaction/feedback";
import { Interaction, TInteractionArray, TOnlineFilterFunction } from "@colmeia/core/src/interaction/interaction";
import { FeedbackFiguresCarrier } from "@colmeia/core/src/interaction/tracker/feedback-carrier-interaction";
import { TMultimediaInstanceArray } from "@colmeia/core/src/multi-media/multi-media-instance";
import { IFeedbackDetailResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { complexValidation, handleTaskFilter, IDynamicFilter, IHashInteractionFilterProcessing, IOkInteraction, isValidTaskFilter, ITasksFilter, TServerCheckFeedbackArray } from "@colmeia/core/src/rules/dynamic-filter/dynamic-filter";
import { IConfirmFeedbackFilterResponse, IDynamicFilter2, IFilterlet } from "@colmeia/core/src/rules/dynamic-filter/dynamic-filter-interfaces";
import {
    hashToArray,
    isInvalid,
    isInvalidArray, isValidAndEqual,
    isValidArray, isValidRef
} from "@colmeia/core/src/tools/utility";
import { findIndex } from 'lodash';
import { BehaviorSubject, Subject } from "rxjs";
import { ChatDisplayOptions, EChatViewStyle } from "../components/backbone/chat-backbone.component";
import { IAvatarView } from "../components/chat/group-chat-right-side-bar/group-chat-right-side-bar.component";
import { ChatBackboneHandler } from "../handlers/chat-backbone.handler";
import { ChatbarHandler } from "../handlers/chat-bar-interactions/chat-bar-handler";
import { MessageContainerHandler } from "../handlers/message-container-handler";
import { MessageHandlerCallbak } from "../handlers/message-instance-handler/message-handler-callback";
import {
    MessageInstanceHandler, TIMessageEndPointCallbackArray,
    TMessageInstanceHandlerArray
} from "../handlers/message-instance-handler/message-instance-handler";
import { ContractService } from "../services/controllers-services/contract-services/contract.service";
import { NavigatorServices } from "../services/controllers-services/navigator/navigator.service";
import { TExecutableItemArray } from "../services/controllers-services/security-controller/executable-item";
import { SafeMenuBuilderServices } from "../services/controllers-services/security-controller/safe-menu-builder";
import { GroupChatServices } from "../services/group-chat.services";
import { QueuService } from "../services/queue.service";
import { SessionService } from "../services/session.service";
import { SignalListenerService } from "../services/signal/signal-listener";
import { ClientInfraResponse } from "./client-infra-comm";
import { IDashboardStage } from "./dash-transformer";
import { GroupSubscription } from "./group-subscription.model";
import { IListenerSubscription } from "./signal/ps-interfaces";
import { SubscriptionSignal } from "./signal/subscription-signal";

export interface IToggleViewModeOptions {
    shouldScrollDown?: boolean
}
export namespace NChatBackBone {
    export enum EViewMode {
        None,
        Chat,
        SurveySingleView
    }

    export interface IChatBackboneClientData {
        visible: boolean,
        viewMode: EViewMode,
        rootInteraction: Interaction;
    }
}



export interface ICallbackSurveyComponent {

}

export type TChatBackboneModelArray = Array<ChatBackboneModel>;

export type TNewFeedbackReactionFunction = (message: FeedbackFiguresCarrier, handler: MessageInstanceHandler, node: IDashboardStage) => void;
type TReactToNewReactionFunctionMap = Map<TNewFeedbackReactionFunction, IDashboardStage>;
type TReactToNewMessageMap = Map<ICallbackSurveyComponent, TReactToNewReactionFunctionMap>;

type TAllBackboneHandlers = Map<Interaction, MessageInstanceHandler>;

export interface IChatBackboneRightBar {
    getDisplayOptions(): ChatDisplayOptions;
    onTaskChangedCallback(taskFilter: ITasksFilter): void;
    onAvatarClickedCallback(itemClicked: IAvatarView): any;
    onInversorClickedCallback(selected: boolean): void;
    onViewModeChangedCallback(viewStyle: EChatViewStyle): void;
    setAdminInteractionView(canShow: boolean): Promise<boolean>;
}

export interface IChatAdvancedFilter {
    showAdminInteraction?: boolean;
    isFiltering?: boolean;

}

export class ChatBackboneModel implements IChatBackboneRightBar, IListenerSubscription {

    private static chatBackboneDatabase: { [idGroup: string]: ChatBackboneModel } = {};
    private searcBackbone: TOnlineFilterFunction;
    private dynFilter: IDynamicFilter; // Filtros baseados na interação
    public mySubscription: GroupSubscription;
    private personalParticipant: Participant;
    private group: Group;
    private active: boolean;
    private containerHandler: MessageContainerHandler;
    public currentChatBarHandler: ChatbarHandler;
    private firstLevelMsgCallbackHandler: MessageHandlerCallbak;
    private backboneHandler: ChatBackboneHandler;
    private onLineMessageReaction: TReactToNewMessageMap;
    private allHandlers: TAllBackboneHandlers;
    private advancedFilters: IChatAdvancedFilter;

    private appliedFilters: IFilterlet[] = [];

    public filterChange = new BehaviorSubject<IFilterlet[]>([]);
    public filterApplied = new Subject();

    private displayOptions: ChatDisplayOptions = {
        viewStyle: EChatViewStyle.chat
    }

    public static syncronizeWithBelongsTo(cached: PlayerCachedInfo): void {
        for (const idGroup in ChatBackboneModel.chatBackboneDatabase) {
            if (!cached.belongsToGroup(idGroup)) {
                delete ChatBackboneModel.chatBackboneDatabase[idGroup];
            }
        }
    }

    public static chatBackboneModelFactory(
        sessionService: SessionService, navigatorService: NavigatorServices, listener: SignalListenerService,
        chatSvc: GroupChatServices, queue: QueuService, contractSvc: ContractService, groupId?: TGlobalUID): ChatBackboneModel {
        // chatSvc: GroupChatServices, queue: QueuService, groupId?: TGlobalUID): ChatBackboneModel {
        if (isInvalid(groupId)) {
            groupId = sessionService.getSelectedGroupID();
        }
        let model: ChatBackboneModel = ChatBackboneModel.chatBackboneDatabase[groupId];
        if (!model) {
            model = new ChatBackboneModel(sessionService, navigatorService, listener, chatSvc, queue, contractSvc);
        };
        return model;
    };

    public static setActiveGroup(idGroup: TGlobalUID): void {
        for (const model of ChatBackboneModel.getChatBackboneModelArray(false)) {
            model.setActive(model.getCurrentGroupID() == idGroup);
        };
    };

    public getGroupChatService(): GroupChatServices { return this.chatSvc; };

    public static getActiveBackBoneModel(): ChatBackboneModel {
        const activeModels = ChatBackboneModel.getChatBackboneModelArray(true);
        if (!activeModels.length)
            throw new Error((new Error()).stack.slice(6));
        return activeModels[0];
    }

    public static getBackboneModel(idGroup: TGlobalUID): ChatBackboneModel {
        return ChatBackboneModel.chatBackboneDatabase[idGroup];
    }

    public static reset() {
        ChatBackboneModel.chatBackboneDatabase = {};
    };

    public static getChatBackboneModelArray(onlyActive: boolean): TChatBackboneModelArray {
        return (<TChatBackboneModelArray>hashToArray(ChatBackboneModel.chatBackboneDatabase))
            .filter((bb) => { return !onlyActive || bb.isActive() });
    };

    public static storeBackboneModel(model: ChatBackboneModel): void {
        ChatBackboneModel.chatBackboneDatabase[model.getCurrentGroupID()] = model;
    };

    public static getChatBackboneModel(idGroup: TGlobalUID): ChatBackboneModel {
        return ChatBackboneModel.chatBackboneDatabase[idGroup];
    }

    private constructor(
        private sessionService: SessionService,
        private navigatorService: NavigatorServices,
        private listener: SignalListenerService,
        private chatSvc: GroupChatServices,
        private queue: QueuService,
        private contractSvc: ContractService,
    ) {
        const idGroup = sessionService.getSelectedGroupID();
        this.sessionService = sessionService;
        this.group = Group.staticFactory(idGroup);
        this.mySubscription = this.sessionService.getGroupSubscription(idGroup);
        this.onLineMessageReaction = new Map();
        this.allHandlers = new Map();
        this.clearInteractionFilter();
        this.advancedFilters = {};
        this.firstLevelMsgCallbackHandler = this.newFirstLevelMsgCallback(
            this.mySubscription.getInteractions(),
            navigatorService
        );
        this.active = true;

        this.listener.listenSubscriptionChanges(this)
    }

    public changeSubscriptionSignCallback(subscriptionSignal: SubscriptionSignal): void {
        this.clearInteractionFilter();
    }

    public getQueueServices(): QueuService { return this.queue; }
    public getAdvancedFilters(): IChatAdvancedFilter { return this.advancedFilters; };
    public hasAdvancedFilters(): boolean { return Object.values(this.advancedFilters).some((v) => { return isValidAndEqual(v, true) }) };

    public async getInteractionDetails(
        idInteraction: TGlobalUID,
        cursor: string,
        idFeedback: TGlobalUID
    ): Promise<IFeedbackDetailResponse | boolean> {
        return this.chatSvc.interactionDetails(idInteraction, cursor, idFeedback);
    }

    setBackboneHandler(backboneHandler: ChatBackboneHandler) {
        this.backboneHandler = backboneHandler
    }

    getBackboneHandler(): ChatBackboneHandler {
        return this.backboneHandler;
    }

    getDynamicFilters(): IDynamicFilter {
        return this.dynFilter
    }

    private setActive(active: boolean): void {
        this.active = active;
    }

    public isActive(): boolean {
        return this.active;
    }

    public async saveInteraction(interaction: Interaction, multimediaInstanceArray: TMultimediaInstanceArray, idGeneralAnswer: TGlobalUID): Promise<FriendlyMessage> {
        let saved: FriendlyMessage = new FriendlyMessage('saveInteraction', false)
        if (this.getGroupType().is(constant.groupType.functional.distributionList) && !interaction.hasEngagement()) {
            interaction.setFeedbackEngagement();
        };
        try {
            saved = (
                await this.chatSvc.safeSaveInteraction(interaction, multimediaInstanceArray, idGeneralAnswer)
            ).friendlyMessage;
        } catch (err) {

        } finally {
            // this.resetChatBarHandler();
        }
        return saved;
    }

    onReactBarReaction(interactionID: TGlobalUID, interactionTypeID: TGlobalUID, idGroup: TGlobalUID, feedback: Feedback): void {
        this.saveReaction(
            interactionID,
            interactionTypeID,
            idGroup,
            feedback.getInteractionTypeID(),
            feedback.getFeedbackID(),
            this.getParticipant()
        );
    }

    public async saveReaction(
        interactionID: TGlobalUID,
        interactionTypeID: TGlobalUID,
        idGroup: TGlobalUID,
        idInteractionType: TGlobalUID,
        idFeedback: TGlobalUID,
        participant: Participant): Promise<ClientInfraResponse> {

        return this.chatSvc.saveReaction(
            interactionID,
            interactionTypeID,
            idFeedback,
            idInteractionType,
            participant.getPrimaryID(),
            this.sessionService.getSelectedGroupID()
        );
    }

    public deleteInteraction(interaction: Interaction): void {
        this.chatSvc.deleteInteraction(interaction, this.getParticipant());
    }

    public getParticipant(): Participant { return this.sessionService.getParticipant(this.getCurrentGroupID()); };
    public getPersonalGroupParticipant(): Participant { return this.sessionService.getCurrentPersonalParticipant(); }
    public getNewInteraction(): Interaction { return this.currentChatBarHandler.getNewInteraction(this.getParticipant()); };
    public getTimedMessages(): TExecutableItemArray {
        const menu: SafeMenuBuilderServices = new SafeMenuBuilderServices(this.sessionService.getMenuSafeBuilderData());
        return menu.getTimedMenu();
    };

    public getChatContainerHandler(): MessageContainerHandler {
        if (!this.containerHandler) {
            this.containerHandler = new MessageContainerHandler({
                chatBackbone: this,
                clientCallback: null
            });
        };
        return this.containerHandler;
    };

    getFirstLevelMsgCallback(): MessageHandlerCallbak {
        return this.firstLevelMsgCallbackHandler;
    }

    private newFirstLevelMsgCallback(interactions: TInteractionArray, navigatorService: NavigatorServices): MessageHandlerCallbak {
        let handler: MessageHandlerCallbak;

        handler = MessageHandlerCallbak.newInstance(
            interactions,
            this,
            true,
            navigatorService,
            null,
            this.queue,
            this.sessionService,
            this.contractSvc,
        );
        return handler;
    }

    public getGroupType(): GroupType { return this.group.getGroupType() };
    public getGroup(): Group { return this.group; };
    public getAvatar(): Avatar { return this.getParticipant().getAvatar() };
    public getGroupID(): TGlobalUID { return this.group.getGroupID() };
    public getCurrentGroupID(): TGlobalUID { return this.group.getGroupID(); };

    public getChatBackbone(): ChatBackboneModel { return this; };

    //#region IChatBackboneRightBar Compliance
    removeAvatar(avatarList, avatarClicked) {
        const idx = avatarList.findIndex(avatar => avatar.iss(avatarClicked))
        const idxFound = idx > -1;
        if (idxFound)
            avatarList.splice(idx, 1);

        this.detectFilter();
    }

    onInversorClickedCallback(selected: boolean): void {
        this.dynFilter.positiveMatch = !this.dynFilter.positiveMatch;
        this.detectFilter();
        this.toogleViewMode();
    }

    onAvatarClickedCallback(itemClicked: IAvatarView) {
        if (!this.dynFilter.avatarList)
            this.dynFilter.avatarList = []

        if (itemClicked.selected) {
            this.dynFilter.avatarList.push(itemClicked.avatar)
        } else {
            this.removeAvatar(this.dynFilter.avatarList, itemClicked.avatar);
        }
        this.detectFilter();

        this.toogleViewMode();
    }

    onTaskChangedCallback(taskFilter: ITasksFilter): void {
        this.dynFilter.tasks = taskFilter;
        this.detectFilter();
        this.toogleViewMode();
    }

    async onViewModeChangedCallback(viewStyle: EChatViewStyle) {
        this.displayOptions = { viewStyle: viewStyle };
        this.detectFilter();
        await this.toogleViewMode();
    }

    async setAdminInteractionView(canShow: boolean): Promise<boolean> {
        this.advancedFilters.showAdminInteraction = canShow;
        this.detectFilter();
        await this.toogleViewMode();
        return this.advancedFilters.showAdminInteraction;
    }

    private detectFilter(): void {
        const allInteractionFilterFreed: boolean = isInvalid(this.dynFilter.tasks)
            || !Object.values(this.dynFilter.tasks).some((v) => { return isValidAndEqual(v, true) })

        if (allInteractionFilterFreed && isInvalidArray(this.dynFilter.avatarList) && !this.dynFilter.timed
            && isInvalidArray(this.dynFilter.feedbacks) && isInvalidArray(this.dynFilter.feedbacks)) {
            this.clearInteractionFilter()
        } else {
            this.setComplexInteractionFilter();
        }

        if (!this.hasAdvancedFilters()) {
            this.advancedFilters = {};
        }
    };

    public applyFilters(filterlets: IFilterlet[] = undefined): void {
        this.toogleViewMode(filterlets);
    }

    //#endregion

    public isStyleMode(styleMode: EChatViewStyle): boolean {
        return this.getDisplayOptions().viewStyle == styleMode
    }

    public isTopicViewMode(): boolean {
        return this.isStyleMode(EChatViewStyle.topic)
    }

    public isChatViewMode(): boolean {
        return this.isStyleMode(EChatViewStyle.chat)
    }

    public isForumViewMode(): boolean {
        return this.isStyleMode(EChatViewStyle.forum)
    }

    // public resetChatBarHandler(): void { this.setCurrentChatBarHandler(this.standardChatBarHandler); };

    public isChatGroup(): boolean {
        return this.getGroupType().isCommon()
    }

    public isFeatureCreator(): boolean {
        return this.getGroupType().is(constant.groupType.functional.featureCreator);
    }

    //////// HTML /////
    public getDrivenInteractionTypeArray(): TDriverFeedbackArray {
        const feeds = this.getGroup().getDriverFeedbacks();
        return feeds;
    }

    public getDisplayOptions(): ChatDisplayOptions { return this.displayOptions; };

    //// FILTER OPTIONS AND CONTROL

    private async toogleViewMode(
        filterlets: IFilterlet[] = undefined,
        options?: IToggleViewModeOptions
    ): Promise<void> {

        if (!filterlets) filterlets = this.appliedFilters

        const firstLevelSavedEndPoints: TIMessageEndPointCallbackArray = this
            .firstLevelMsgCallbackHandler
            .getEndPoints()


        const groupId = this.getCurrentGroupID()
        const key = MessageHandlerCallbak.composeKey(groupId, null)
        MessageHandlerCallbak.destroyMessageHandlerCallback(key)

        // await this.delay(2000)
        // console.log('this.mySubscription.getInteractions()', this.mySubscription.getInteractions())


        const filteredInteractions: TInteractionArray = await this.getFilteredInteractions(
            this.getGroupID(),
            { filterlets },
            this.mySubscription.getInteractions()
        )



        this.firstLevelMsgCallbackHandler = this.newFirstLevelMsgCallback(
            filteredInteractions,
            this.navigatorService
        )

        this.firstLevelMsgCallbackHandler.setEndPoints(firstLevelSavedEndPoints)
        this.firstLevelMsgCallbackHandler.markForCheckEndPoints()

        this.filterApplied.next(options)
    }

    getFilteredInteractionsByInteractions(interactions: TInteractionArray) {
        if (!this.appliedFilters) return interactions

        return this.getFilteredInteractions(
            this.getGroupID(),
            { filterlets: this.appliedFilters },
            interactions
        )
    }

    public delay(time) {
        return new Promise((resolve) => {
            const timer = setTimeout(() => resolve(timer), time)
        })
    }

    private async getFilteredInteractions(idGroup: TGlobalUID, filter: IDynamicFilter2, interactionArray: TInteractionArray): Promise<TInteractionArray> {
        const checker: IHashInteractionFilterProcessing = {};
        for (const interaction of interactionArray) {
            const ok: IOkInteraction = complexValidation(interaction, filter, idGroup)

            if (ok.ok) {
                checker[interaction.getInteractionID()] = ok;
            }
        };

        const toBeChecked: TServerCheckFeedbackArray = [];

        for (const idInteraction in checker) {
            if (isValidArray(checker[idInteraction].checkFeedbacks)) {
                for (const check of checker[idInteraction].checkFeedbacks) {
                    toBeChecked.push({
                        ...check, idGroup: idGroup, idInteraction: idInteraction
                    })
                }
            }
        }

        let filteredInteractions = interactionArray.filter(int => checker[int.getPrimaryID()] && checker[int.getPrimaryID()].ok);

        if (isValidArray(toBeChecked)) {
            const confirm: IConfirmFeedbackFilterResponse = await this.chatSvc.confirmFeedbackFilter(toBeChecked);
            if (isValidRef(confirm)) {
                filteredInteractions = filteredInteractions.filter(int => confirm.confirmedInteractions.includes(int.getPrimaryID()));
            }
        }

        return filteredInteractions;
    }

    public getCurrentInteractionFilter(): TOnlineFilterFunction {
        return this.searcBackbone;
    };

    private setComplexInteractionFilter(): void {
        this.searcBackbone = this.complexFilterProcess;
    }

    private clearInteractionFilter(): void {
        this.searcBackbone = this.allClearFilters;
        this.dynFilter = { ...this.dynFilter, positiveMatch: true };
    }

    public allClearFilters(interaction: Interaction, dinFilter: IDynamicFilter = this.dynFilter): boolean {
        return true;
    };

    public complexFilterProcess(interaction: Interaction, dinFilter: IDynamicFilter = this.dynFilter, idGroup: TGlobalUID): boolean {
        let visible: boolean = true;

        if (isValidArray(dinFilter.avatarList)) {
            visible = interaction.getParticipant().getAvatar().iss(...dinFilter.avatarList) === dinFilter.positiveMatch;
        };
        if (visible && isValidArray(dinFilter.feedbacks) && interaction.hasInteractionFeedbackFigures(idGroup)) {
            visible = interaction.hasFeedback(idGroup, ...dinFilter.feedbacks.map((f) => { return f.getFeedbackID() }));
        };
        if (visible && isValidRef(dinFilter.timed)) {
            visible = (dinFilter.timed == interaction.isTimed()) === dinFilter.positiveMatch;
        };
        if (visible && dinFilter.tasks && isValidTaskFilter(dinFilter.tasks)) {
            visible = handleTaskFilter(dinFilter, interaction, idGroup, interaction.getParticipant().getAvatar()) === dinFilter.positiveMatch;
        };

        return visible;
    }

    public addToAllHandlers(handler: MessageInstanceHandler): void {
        this.allHandlers.set(handler.getInteraction(), handler);
    }

    public removeFromAllHandlers(interaction: Interaction): void {
        this.allHandlers.delete(interaction);
    }

    //////// Realtime Online Survey Support

    public getNextLevelSurveyHandler(node: MessageInstanceHandler): TMessageInstanceHandlerArray {
        return node.getNextLevelHandler().getInteractionHandlerArray();
    }

    public subscribeToOnlineChanges(component: ICallbackSurveyComponent, func: TNewFeedbackReactionFunction, root: IDashboardStage): void {
        if (!this.onLineMessageReaction.has(component)) {
            this.onLineMessageReaction.set(component, new Map());
        };
        this.onLineMessageReaction.get(component).set(func, root);
    }

    public unsubsubscribeToOlineChanges(component: ICallbackSurveyComponent): void {
        this.onLineMessageReaction.delete(component);
    }

    public async publishReactEvents(feedback: FeedbackFiguresCarrier): Promise<void> {
        const handler: MessageInstanceHandler = this.allHandlers.get(feedback.getInteractionParent());
        for (let [component, setOfFunction] of this.onLineMessageReaction) {
            for (let [func, root] of setOfFunction) {
                await func(feedback, handler, root);
            }
        };
    };

    public refreshInteractionNode(idInteraction: TGlobalUID, idInteractionParent: TGlobalUID): void {
        const parentCallback: MessageHandlerCallbak = MessageHandlerCallbak.getMessageHandlerCallback(
            MessageHandlerCallbak.composeKey(this.getGroupID(), idInteractionParent));

        const childCallback: MessageHandlerCallbak = MessageHandlerCallbak.getMessageHandlerCallback(
            MessageHandlerCallbak.composeKey(this.getGroupID(), idInteraction));

        // childCallback.callParentChangeDetector(true); //
        parentCallback.refreshVector(); // esse nao deveria ser necessario
        parentCallback.changeAllSiblingsDetection(); // esse nao deveria ser necessario
        parentCallback.changeDectionChildren(); //

    };

    public addFilter(filterlet: IFilterlet) {
        this.appliedFilters.push(filterlet);
        this.filterChange.next(this.appliedFilters);
        this.applyFilters();
    }

    public reloadFiltersAndBlockScrollDown(): Promise<void> {
        if (isInvalidArray(this.appliedFilters)) return

        return this.toogleViewMode(undefined, { shouldScrollDown: false })
    }

    public removeFilter(filterlet: IFilterlet) {
        this.appliedFilters.splice(findIndex(this.appliedFilters, filterlet), 1)

        this.filterChange.next(this.appliedFilters)

        this.applyFilters()
    }

    public getAppliedFilters(): IFilterlet[] {
        return this.appliedFilters;
    }
}
