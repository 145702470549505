import { daysInMS, Miliseconds } from "@colmeia/core/src/time/time-utl";

export enum ECRMTimeWindowField {
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
}

export enum ECRMTimeWindows {
    t7 = 't7',
    t15 = 't15',
    t30 = 't30',
    custom = 'custom',
}

export interface ICRMTimeWindowConfig {
    id: ECRMTimeWindows,
    field: ECRMTimeWindowField,

    /**
     * Valor extado
     * @example Date.now() = 1720791690700
     */
    maxTimestamp?: Miliseconds;
    minTimestamp?: Miliseconds;

    // OR

    /**
     * Valor em ms que sera subtraído do timestamp atual
     * @example daysToMS(30)
     */
    maxTimeOffset?: Miliseconds;
    minTimeOffset?: Miliseconds;
}


export function getDefaultTimeWindow(): ICRMTimeWindowConfig {
    return {
        id: ECRMTimeWindows.t30,
        field: ECRMTimeWindowField.updatedAt,
        minTimestamp: daysInMS(30),
    }
}

export const defaultTimeWindows: ICRMTimeWindowConfig[] = [
    {
        id: ECRMTimeWindows.t7,
        field: ECRMTimeWindowField.updatedAt,
        minTimeOffset: daysInMS(7),
    },
    {
        id: ECRMTimeWindows.t15,
        field: ECRMTimeWindowField.updatedAt,
        minTimeOffset: daysInMS(15),
    },
    {
        id: ECRMTimeWindows.t30,
        field: ECRMTimeWindowField.updatedAt,
        minTimeOffset: daysInMS(30),
    },
];