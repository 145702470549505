
import { Injectable } from '@angular/core';
import { constant } from '@colmeia/core/src/business/constant';
import { EGeolocationIntType, EServiceGroupIntType } from '@colmeia/core/src/business/constant.enums';
import { Group } from '@colmeia/core/src/business/group';
import { PlayerCachedInfo } from '@colmeia/core/src/business/player-cached';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { IGroupJSON } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { IEnableRealtimeMonitoring, IInteractionJSON } from "@colmeia/core/src/comm-interfaces/interaction-interfaces";
import {
    IFinishChatService,
    IServiceChatInitiatedInteractionJSON
} from "@colmeia/core/src/comm-interfaces/service-group-interfaces";
import {
    IChangeDataInteractionJSON, IInteractionLiveInfo, ITrackerInteractionJSON, ITrackerPayloadCarrierDeliveryGuarantee, ITrackerReadReceiveInteractionJSON, ITypingBroadcasterJSON
} from '@colmeia/core/src/comm-interfaces/tracker-interfaces';
import { allSeverityRegistry } from '@colmeia/core/src/core-constants/tracker-qualifiers';
import { ESecurityChanged } from "@colmeia/core/src/core-constants/types";
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { InteractionType } from '@colmeia/core/src/interaction/interaction-type';
import { IMenuReplyInteractionJSON } from '@colmeia/core/src/interaction/menu-interaction/menu-interaction-interfaces';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { isCallCenterServiceChat, isInternalServiceChat } from '@colmeia/core/src/rules/inter-classification';
import { interactionReadableMessage } from "@colmeia/core/src/rules/interaction-filter";
import { getIDConversationFromThread } from '@colmeia/core/src/rules/thread-conversation-functions';
import { delay, isThisOneOfThat, isValidRef } from '@colmeia/core/src/tools/utility';
import { MessageHandlerCallbak } from 'app/handlers/message-instance-handler/message-handler-callback';
import { createServiceLogger } from 'app/model/client-utility';
import { SupervisorAgentService } from 'app/supervisor-agent.service';
import { Observable, Subject } from 'rxjs';
import { ChatBackboneModel } from "../model/chat-backbone.model";
import { IInterfaceInfoSignal, InterfaceInfoSignal } from "../model/signal/interface-signal";
import { SecurityContextSignal } from "../model/signal/state-signals/sec-contex-signal";
import { TypingSignal } from "../model/signal/trackers/typing-signal";
import { AttendanceService } from "./attendance.service";
import { NavigatorServices } from "./controllers-services/navigator/navigator.service";
import { DashBoardService } from './dashboard/dashboard.service';
import { GroupShortcutHandlerService } from "./group-shortcut-handler.service";
import { GuaranteedPayloadHandlerService } from './guaranteed-payload-handler.service';
import { SecurityContextChangeHandlerService } from './handlers/security-context-change-handler.service';
import { HardwareLayerService } from "./hardware";
import { InteractionPersistorServices } from "./interaction-persistor.service";
import { NotificationsService } from "./notifications";
import { IPlayerInfoChangedData, PlayerInfoService } from './player-info.service';
import { RouterManagerService } from './router-manager.service';
import { SessionService } from './session.service';
import { SignalPublisherService } from "./signal/signal-publisher";
import { SocialNetworkDatabaseService } from "./social-network-database.service";
import { SusbcriptionContainerService } from "./subscriptions.service";
import { UniversalRehydrator } from "./universal-rehydrator";

@Injectable()
export class PosProcessingInteractionService {
    log = createServiceLogger('PosProcessingInteractionService', '#509aef')

    private navigator: NavigatorServices;
    private subsContainer: SusbcriptionContainerService;
    private attendanceSvc: AttendanceService;
    private _interaction$ = new Subject<Interaction | IInteractionJSON>();

    constructor(
        private dashboardSvc: DashBoardService,
        private playerServices: PlayerInfoService,
        private rehydrator: UniversalRehydrator,
        private publisher: SignalPublisherService,
        private notificationSVC: NotificationsService,
        private interactionPersistor: InteractionPersistorServices,
        private sessionService: SessionService,
        private hw: HardwareLayerService,
        private snDatabase: SocialNetworkDatabaseService,
        private attendance: AttendanceService,
        private shortcut: GroupShortcutHandlerService,
        private routeManagerSvc: RouterManagerService,
        private securityContextChangeHandlerService: SecurityContextChangeHandlerService,
        private supervisorAgentService: SupervisorAgentService,
        private playerInfoService: PlayerInfoService,
        private interactionPersistorSvc: InteractionPersistorServices,
        private guaranteedPayloadSvc: GuaranteedPayloadHandlerService,
    ) { }

    public async posProcessInteraction(interaction: IInteractionJSON, rehydrated: Interaction): Promise<void> {

        if (this.isMenuReply(interaction)) {
            this.onReceiveMenuReply(interaction);
        }

        // this.log(`Arrived: ${interaction.idInteractionType}`, interaction);

        let count: number = 0;
        let hasGroup: boolean = this.snDatabase.existOnDatabase(interaction.idGroup, constant.objectType.group);

        while (!hasGroup && ++count < 600) {
            await delay(150);
            hasGroup = this.snDatabase.existOnDatabase(interaction.idGroup, constant.objectType.group);
        }

        if (!hasGroup) {
            const errorObj = {
                fnName: "PosProcessingInteractionService.posProcessInteraction",
                message: `Falha ao tentar inserir uma interação via socket interaction.primaryID: ${interaction.primaryID}`,
                idGroup: interaction.idGroup,
            }
            console.error(errorObj)
            this.interactionPersistorSvc.sendTrace(
                allSeverityRegistry.critical,
                "PosProcessingInteractionService.posProcessInteraction",
                errorObj);
        }

        const interactionType: InteractionType = InteractionType.staticFactory(interaction.idInteractionType);
        // if (this.attendanceSvc.isCurrentUserAnAgentLoggedInAndInteractionDoesNotBelongToMe(interaction)) {
        //     const errorObj = {
        //         description: 'isAgentLoggedAndInteractionDoesNotBelongToHim interaction nao pertence a nenhum atendimento deste agente: ',
        //         avatarId: this.sessionService.getAvatarID(),
        //         avatarName: this.sessionService.getAvatarName(),
        //         interactionType: interactionType.getPrimaryID(),
        //         interactionId: interaction.primaryID,
        //     };
        //     this.interactionPersistorSvc.sendTrace(
        //         allSeverityRegistry.critical,
        //         "PosProcessingInteractionService.isAgentLoggedAndInteractionDoesNotBelongToHim",
        //         errorObj);
        //     this.log({ errorObj });
        //     return
        // }

        this.interactionPersistor.receiveInteraction(interaction.primaryID);

        if (interactionType.isNotifiable()) {
            this.notificationSVC.notificate(interaction);
            if (UberCache.testCache(interaction.idGroup)) {
                const grp: Group = Group.staticFactory(interaction.idGroup);
                grp.setLastGroupNavigatorInteractionJSON(this.interactionToLiveInfo(interaction));
            }
        }

        if (isThisOneOfThat(interaction.idInteractionType, EGeolocationIntType.beginRealtimeTracking, EGeolocationIntType.endRealTimeTracking)) {
            if (this.sessionService.avatarBelongsToMe((<IEnableRealtimeMonitoring>interaction).idTrackedAvatar)) {
                console.log('ONE OF THAT ', interaction.idInteractionType)
                if (interaction.idInteractionType === EGeolocationIntType.beginRealtimeTracking) {
                    this.hw.getGeolocation().start();
                } else {
                    this.hw.getGeolocation().stop();
                };
            }
        } else if (isCallCenterServiceChat(interaction)) {
            await this.attendance.onServiceChatInitialized(<IServiceChatInitiatedInteractionJSON>interaction);
            this.updateGroupListInLeftBar();

        } else if (isInternalServiceChat(interaction)) {
            this.updateGroupListInLeftBar();
            this.attendance.addInternalSupportGroups(interaction.idGroup);

        } else if (interaction.idInteractionType === EServiceGroupIntType.finishService) {
            if (this.attendanceSvc.isMyServiceInteractionJSON(interaction)) {
                this.attendance.onServiceChatFinished(<IFinishChatService>interaction);
                this.updateGroupListInLeftBar();
            }
        }

        if (interactionType.isTrackerInformation()) {
            await this.processTracker(<ITrackerInteractionJSON>interaction);
        } else {
            this.shortcut.onReceiveNotHydratedInteraction(interaction);
        }

        this._interaction$.next(rehydrated || interaction);
    }

    public async onReceiveMenuReply(menuReply: IMenuReplyInteractionJSON): Promise<void> {
        await this.routeManagerSvc.goToInteractionID(menuReply.idGroup, menuReply.idInteractionParent);
    }

    private async processTracker(tracker: ITrackerInteractionJSON): Promise<void> {
        switch (tracker.trackerType) {

            case constant.trackerTypes.dataSynch.sendPayload:
                this.guaranteedPayloadSvc.handle(tracker as ITrackerPayloadCarrierDeliveryGuarantee);
                break;

            case constant.trackerTypes.dataSynch.sendAvatarData:
                const carrier: IChangeDataInteractionJSON = <IChangeDataInteractionJSON>tracker;
                const serializable: Serializable = Serializable.staticFactory(carrier.universal.primaryID);
                serializable.updateHeader(carrier.universal);
                break;

            case constant.trackerTypes.dataSynch.sendGroupData: {
                await delay(1000);
                const carrier: IChangeDataInteractionJSON = <IChangeDataInteractionJSON>tracker;
                if (UberCache.testCache(carrier.universal.primaryID)) {
                    const serializable: Serializable = Serializable.staticFactory(carrier.universal.primaryID);
                    serializable.updateHeader(carrier.universal);
                } else {
                    await this.rehydrator.rehydrate(carrier.universal);
                }

                this.snDatabase.updateGroup(<IGroupJSON>carrier.universal);
                if (this.sessionService.getPlayerInfo().belongsToGroup(carrier.universal.primaryID)) {
                    this.publisher.emitInterfaceSiginal({ newUniversal: carrier.universal.primaryID });
                    this.publisher.emitInterfaceSiginal({ changeGroupHeader: carrier.universal.primaryID });
                }
            }
                break;

            case constant.trackerTypes.dataSynch.sendClientInformation: {
                const aux: IChangeDataInteractionJSON = <IChangeDataInteractionJSON>tracker;
                this.sessionService.getPlayerInfo().setClientInfo(aux.clientInfo);
            };
                break;

            case constant.trackerTypes.typingInfo: {
                this.publisher.specificSignalEmissorOnGenericStream(new TypingSignal((<ITypingBroadcasterJSON>tracker).receivedSignature));
            };
                break;

            case constant.trackerTypes.readControl.receivedMessage: {
                const received: ITrackerReadReceiveInteractionJSON = <ITrackerReadReceiveInteractionJSON>tracker;
                const containerController: MessageHandlerCallbak = MessageHandlerCallbak.getMessageHandlerCallback(
                    MessageHandlerCallbak.composeKey(received.idGroup, received.idServerInteraction));

                if (containerController) {
                    containerController.setReceiveReadUpdate(received.confirmationType);
                };
            };
                break;

            case constant.trackerTypes.dataSynch.sendPlayerInfo: {
                if (this.attendance.isPlayerAnAgentInCurrentSocialNetwork()) {
                    await this.attendance.synchronizeStatus();
                }
                const oldCachedPlayer: PlayerCachedInfo = this.sessionService.getPlayerInfo();
                const aux: IChangeDataInteractionJSON = <IChangeDataInteractionJSON>tracker;
                const newPlayerCached: PlayerCachedInfo = new PlayerCachedInfo(aux.playerInfo, true);
                const signal: SecurityContextSignal = new SecurityContextSignal(newPlayerCached, oldCachedPlayer, aux);
                this.playerServices.init(newPlayerCached);
                this.dashboardSvc.reset();
                await this.shortcut.playerCachedChanged();
                this.supervisorAgentService.emitNewParticipantMapArrived(aux.playerInfo.part)

                /* IF you are attending and were removed from current group, navigate to socialnetwork... */
                const removedGroupsFromPlayer: Set<string> = this.securityContextChangeHandlerService.getLastRemovedGroupsToPlayer(signal)
                const addedGroupsToPlayer: Set<string> = this.securityContextChangeHandlerService.getLastGroupsAddedToPlayer(signal)
                const playerChangedData: IPlayerInfoChangedData = {
                    removedGroupsFromPlayer, addedGroupsToPlayer
                }
                this.log("🚀 ~ file: pos-processing-interaction.service.ts ~ PosProcessingInteractionService ~ processTracker ~ playerChangedData",
                    { oldCachedPlayer, newPlayerCached, playerChangedData })
                this.playerInfoService.broadcastPlayerInfoChanged(playerChangedData)

                await this.attendance.onPlayerInfoReceived(removedGroupsFromPlayer);

                if (aux.changedType === ESecurityChanged.archive) {
                    this.navigator.setArchived(aux.archivedGroups, oldCachedPlayer);
                    this.subsContainer.setArchived(aux.archivedGroups, oldCachedPlayer)
                    this.snDatabase.onGroupsArchived(aux.archivedGroups);
                    const inter: IInterfaceInfoSignal = { archivedGroups: aux.archivedGroups };
                    this.publisher.emitInterfaceSiginal(inter);
                } else {
                    this.navigator.refreshCached();
                    this.subsContainer.refreshCached();
                    MessageHandlerCallbak.synchronizeWithGroupsBelongsTo(newPlayerCached);
                    ChatBackboneModel.syncronizeWithBelongsTo(newPlayerCached);
                }

                this.publisher.securityContextChanged(signal);
                this.securityContextChangeHandlerService.handlePlayerChangedInfoThroughWebSocket(signal);
            };
                break;

            case constant.trackerTypes.readControl.readMultimedia: {
                this.log('Message ===>', tracker.trackerType);
            };
                break;
        };

    };

    public setNavigatorDependency(navigator: NavigatorServices): void {
        this.navigator = navigator;
    };

    public setSubscriptionContainerDependency(subsContainer: SusbcriptionContainerService): void {
        this.subsContainer = subsContainer;
    }

    public setAttendaceServiceDependacy(attendaceSvc: AttendanceService) {
        this.attendanceSvc = attendaceSvc;
    }

    private isMenuReply(interaction: IInteractionJSON): interaction is IMenuReplyInteractionJSON {
        return interaction.idInteractionType === constant.interactionType.menu.menuReply;
    }

    private updateGroupListInLeftBar(): void {
        this.publisher.specificSignalEmissorOnGenericStream(new InterfaceInfoSignal({ updateGroupList: true }));
    }

    private interactionToLiveInfo(interaction: IInteractionJSON): IInteractionLiveInfo {
        return {
            clockTick: interaction.clockTick,
            idGroup: interaction.idGroup,
            nameAvatar: this.sessionService.getSelectedAvatar().getName(),
            primaryID: interaction.primaryID,
            text: interactionReadableMessage(interaction),
            idInteractionType: interaction.idInteractionType
        };
    }

    interactionReceived$(): Observable<Interaction | IInteractionJSON> {
        return this.posProcessedInteraction$();
    }

    posProcessedInteraction$(): Observable<Interaction | IInteractionJSON> {
        return this._interaction$.asObservable();
    }

};
