import { EServiceChatType } from "../../../comm-interfaces/service-group-interfaces";
import { typedScreenOptions } from "../../../tools/utility";
import { EFaceCompanyServiceType } from "../../face-company/ns-facecompany";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";

export const fragmentsScreenOption1 = typedScreenOptions({
    idSerializable: EAllSerializable.FragmentsOne,
    fields: {
        replyWindow: 1, // replyWindow 19
        internalSupportWindow: 2,//internalSupportWindow 19
        resetDates: 3, //chatDateFilter 19
        tasks: 4, //ChatFilter 20,
        perPage: 5,// perPage: 
        advancedSettings: 6, // Configurações Avançadas
        totalFields: 7, // GeneralForm 29
        filterGroupTitle: 8, // Filtrar Grupos
        childEntities: 9, // knowledgeBase/entities 39
        clientRegistration: 10,// ChatOption/ClientRegistration 1
        finalizationRegister: 11, // ChatOption/Finalization 1
        transference: 12, //ChatOptions/Transference 1  
        activeCommunication: 13, //activeCommunication 1
        addTemplate: 14, // whatsAppTemplate 22
        openMenu: 15, // dashboardView 19
        phoneNumber: 16, // SNManagement/socialMedias 28
        mediaSocialConnection: 17,// Conexão Mídia Social
        copyTemplateToEmail: 18,// Copiar template para E-mail
        prioritizeOpenChannelCase: 19,// Priorizar caso canal aberto
        emitAfter: 20, //Emitir após
        timeAddedToOtherChannels: 21, //Tempo adicionado aos outros canais
        emailTemplate: 22, //Template do e-mail
        attendantRegister: 23, // Registro de atendente
    }
} as const);

export const fragmentsScreenOption2 = typedScreenOptions({
    idSerializable: EAllSerializable.FragmentsTwo,
    fields: {
        emailTitle: 1, // Título do e-mail
        listTitle: 2, //  'knowledgeBase/entities' 23
        createEntity: 3, // 'knowledgeBase/entities' 30
        actionType: 4, // Tipo de ação
        inviteToGroup: 5, // Convite para o grupo
        minimalConfirmation: 6, // Confirmação mínima
        sendToAllChannels: 7, // Enviar para todos os canais independente da confirmação
        commStrategy: 8, // Estratégia de comunicação
        noCampaings: 9, //Não há campanhas para mostrar
        addCampaing: 10, // Adicionar campanha
        connectionName: 11, // Nome da conexão
        authRoute: 12, // Rota autenticada
        versionNumber: 13, // versão
        restart: 14, // Reinicie o aplicativo para otimizar
        otimization: 15, // Otimização
        waitUntilUpdate: 16, // Estamos otimizando o seu sistema, aguarde alguns instantes
        serviceName: 17, // Nome do serviço
        noServices: 18,// Não há serviços para mostrar.
        createKnowledgeBase: 19, // Criar Base de Conhecimento
        closeMenu: 20, // 'dashboardMenu' 19
        selectionWay: 21, // nsPicker/modal 21
        tree: 22, // nsPicker/modal/tab 19
        tagGroups: 23, // nsPicker/modal/tab 20
        confirmText: 24, // SNManagement/users 26
        confirmTextGroup: 25, // SNManagement/users 30
    }
} as const);

export const fragmentsScreenOption3 = typedScreenOptions({
    idSerializable: EAllSerializable.FragmentsThree,
    fields: {
        addTheFollowingItemsToAPatch: 1, // addToProdDialog 19
        addToTheDeploysList: 2, // addToProdDialog 21
        chooseADeploy: 3, // addToProdDialog 22
        search: 4, // changesProdDeploy 19
        objects: 5, // changesProdDeploy 21
        createPatch: 6, // createPatchDialog 19
        patchDescription: 7, // createPatchDialog 20
        patchName: 8, // createPatchDialog 21
        noIntents: 9, // Não há intenções
        deploy: 10, // prodDeployEdit 19
        promote: 11, // promotePatchDialog 19
        addWallet: 12, // chargeDialog 19
        omniChannel: 13, // featureItemDetails 19
        negativeText: 14, // onConfirmationEvent 19
        positiveText: 15, // onConfirmationEvent 20
        scheduled: 16, // Agendado,
        delivered: 17, // Entregue,
        processed: 18, // Processado,
        snManagement: 19, // Gestão da Rede Social
        tools: 20, // Ferramentas
        myProfile: 21, // Meu perfil
        personalMessages: 22, // Mensagens pessoais
        logout: 23, // Sair
        about: 24, // Sobre
        goToRootGroup: 25, // Grupo
        create: 26, // Criar
        update: 27, // Atualizar
        delete: 28, // Deletar
        operation: 29, // Operação
        remote: 30, // Remoto
        local: 31 // local
    }
} as const);

export const fragmentsScreenOption4 = typedScreenOptions({
    idSerializable: EAllSerializable.FragmentsFour,
    fields: {
        loadImg: 1, // carregar imagem
        heritageFromFather: 2, // Herdar usuários do grupo pai?
        aboutYou: 3, // Conte um pouco sobre você
        bindSocialNetworks: 4, // Vincular a redes sociais
        
        idade: 5, // Formato de data inválido!
        yourSN: 6, // Suas redes sociais
        yourProfiles: 7, // Seus perfis
        addReactions: 8, // Adicionar reações
        removeReactions: 9, // Remover reações
        chooseFeedbacks: 10, // Escolher feedbacks
        availableReactions: 11, // Reações disponíveis
        noMoreReactions: 12, // Você não tem mais reações a serem adicionadas
        available: 13, // Disponíveis
        activateNotifications: 14, // Ativar Notificações
        deactivateNotifications: 15, // Desativar Notificações
        devices: 16, // Dispositivos
        addDevice: 17, // Adicionar este dispositivo?
        devicePlaceHolder: 18, // ex: Notebook de trabalho
        deviceSmallName: 19, // Insira um nome maior para o dispositivo
        
        deac1: 20, // Desativar por duas horas
        deac2: 21, // Desativar por um dia
        deac3: 22, // Desativar por um mês
        deac4: 23, // Desativar indefinidamente

        deacu: 24, // Desativado até:
        typeYourMessage: 25, // Digite sua mensagem aqui
    }
} as const);

export const fragmentsScreenOption5 = typedScreenOptions({
    idSerializable: EAllSerializable.FragmentsFive,
    fields: {
        newPassword: 1, // Nova senha
        objectType: 2, // dependenciesDialog 20
        icon: 3, // Ícone do Face Company
        iconUpload: 4, // Upload do ícone
        askDeleteInteraction: 5, // Tem certeza que deseja remover esta interação?
        confirmLeaveWithoutSave: 6, // Há alterações pendentes, sair sem salvar?
        confirmShareServiceLeaveWithoutSave: 7, //Oops, parece que você esqueceu de salvar! Gostaria de salvar suas alterações?
        archiveGroup: 8, //Arquivar grupo
        participationWithAnotherAvatar: 9,// Você já participa deste grupo, mas como outra Persona.
        mustBeAParticipantToExecute: 10, // Acesso apenas para participantes
        sendClientRegisterConfirmation: 11, // Deseja enviar para o cliente?
        [EFaceCompanyServiceType.goToGroup]:12, //Ir para o grupo
        [EServiceChatType.panic]: 13, // Pânico
        [EServiceChatType.service]: 14, // Serviço
        [EServiceChatType.socialReceptiveCallcenter]:15, // Atendimento Receptivo
        [EServiceChatType.internalSupport]:16, // Suporte Interno
        [EServiceChatType.inactives]: 17, // Inativos
        [EServiceChatType.embeddedColmeiaChat]: 30, // @TODO Daniel ver isso
        savedTemplate: 18, // O template foi salvo
        saveErrorTemplate: 19, // Não foi possível salvar o template
        visibleToGroup: 20, //Visivel para o grupo
        readMore: 21,// Ler mais
        readLess: 22, // Ler menos
        goBackTo: 23, // Voltar para

        serviceFinished: 24, // Atendimento finalizado
        botHistory: 25, // startServiceChat 25
        
    }
} as const);

export const fragmentsScreenOption6 = typedScreenOptions({
    idSerializable: EAllSerializable.FragmentsSix,
    fields: {
        noBotHistory: 1, // Não há histórico do bot
        startingService: 2, // Iniciando atendimento
        chatOptions: 3, // Opções do chat
        providers: 4, // Provedores
        integrations: 5, // Integrações
        formHistory: 6, // Histórico de formulários
        bulkGroupToInvite: 7, // Escolha um Grupo
        bulkTemplateLabel: 8, // modelo
        bulkFieldLabel: 9, // campo
        typeServiceName: 10, // Digite o nome do Serviço
        chooseGroups:11, // Escolha os grupos
        pathname: 12, // Nome do caminho
        chooseFunctionalities: 13, // Escolha as funcionalidades
        next: 14, // Próximo
        previous: 15, // Anterior
        choosePermissions: 16,// Escolha as permissões
        ready: 17, // Pronto!
        serviceCanBeSavedNow: 18, //Agora o serviço já pode ser salvo
        subgroups: 19, // Subgrupo
        include: 20, // Incluir
        shareService: 21,// Compartilhar Serviço
        selectedFeatures: 22,// Funcionalidade selecionada
        selectFeaturesAsYouWish: 23, // Selecona as funcionalidades que desejas
        whichTypeOfFeatures: 24,// Qual tipo de funcionalidade?
        askExitWithoutSave: 25,// Sair sem salvar?




    }
} as const);

export const fragmentsScreenOption7 = typedScreenOptions({
    idSerializable: EAllSerializable.FragmentsSeven,
    fields: { 
        viewPermission: 1,
        usePermission: 2,
        seeDependencies: 3,
        sureOfDeleteObject: 4,
        confirm: 5, // Confirmar 
        delete: 6,// Deletar
        accessDenied: 7, //Acesso Negado
        filterTransaction: 8, // Filtrar Gerador de Conteúdo
        filterAccuraci: 9, // Filtar Acurácia
        filterFeedback: 10, // Filtar Feedback
        filterResult: 11, // Filtar Resultado
        filterScore: 12, //Filtar Score
        maximumScore: 13, // Score máximo
        positiveResult: 14, // Resultado positivo?
        positiveFeedback: 15, // Feedback positivo?
        userFeedbackML: 16, // Inputs de usuários
        notPossibleToSelectDiferentTransactions: 17, // Não é possivel selecionar inputs de transações diferentes
        trusted: 18, // Confiável
        notTrusted: 19, // Não confiavel
        needToConfirm: 20, // Necessário confirmar
        selectTransaction: 21, // Selecione um gerador de conteúdo
        input: 22, // Input,
        selectInput: 23, // Selecione o input
        botName: 24, // Nome do bot
        transactionName: 25, // Nome do gerador de conteúdo
        selectTemplate: 26 // Selecione template
    }
} as const);

export const fragmentsScreenOption8 = typedScreenOptions({
    idSerializable: EAllSerializable.FragmentsEight,
    fields: { 
        filteredInput: 1, // Input filtrada
        hasFeedback: 2, // Possui feedback
        score: 3, // Score
        idColmeiaProvider: 4, // Id do provedor Colmeia,
        nlpUserInput: 5, // Texto do usuário
        nplComputedSentence: 6, // Texto semântico
        nlpIntentsSelect: 7, // Selecione a intenção
        newSocialNetwork: 8, // Nova Rede Enterprise
    }
} as const);



export const allFragmentsTranslations = {
    ...getTranslationsFields(fragmentsScreenOption1),
    ...getTranslationsFields(fragmentsScreenOption2),
    ...getTranslationsFields(fragmentsScreenOption3),
    ...getTranslationsFields(fragmentsScreenOption4),
    ...getTranslationsFields(fragmentsScreenOption5),
    ...getTranslationsFields(fragmentsScreenOption6),
    ...getTranslationsFields(fragmentsScreenOption7),
    ...getTranslationsFields(fragmentsScreenOption8),
};


