// defaultNserValidator
// srPackageValidator

import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { ETypeOf, isInvalid, isValidString, pickKeys, values } from "../../../../tools/utility";
import { ValueOf } from "../../../../tools/utility-types";

import { gTranslations } from "../../../const-text/translations";
import { ISRPackageServer, TSRdatabaseConfig } from "../../../crm/crm-services/crm-config-interfaces";
import { assertNser } from "../helpers";
import { TNserValidator } from "../validate-nser";
import { defaultNserValidator } from "./nser";

export const srPackageValidator: TNserValidator = (nser: ISRPackageServer, friendly: FriendlyMessage) => {

    defaultNserValidator(nser, friendly);

    if (!isValidString(nser.projectKey)) {
        friendly.addReturnGenericErrorMessage("Defina a chave do projeto");
    }

    if (isInvalid(nser.idTicketStateConfig)) {
        friendly.addReturnTranslationResponse(gTranslations.crm.missingIdTicketStateConfig);
    }

    if (isInvalid(nser.idSRPhasesConfig)) {
        friendly.addReturnTranslationResponse(gTranslations.crm.missingIdSRPhasesConfig);
    }
    
    if (isInvalid(nser.idSeverityConfig)) {
        friendly.addReturnTranslationResponse(gTranslations.crm.missingIdSeverityConfig);
    }

    if (isInvalid(nser.idUrgencyLevelConfig)) {
        friendly.addReturnTranslationResponse(gTranslations.crm.missingIdUrgencyLevelConfig);
    }

    if (isInvalid(nser.idCloseStateConfig)) {
        friendly.addReturnTranslationResponse(gTranslations.crm.missingIdCloseStateConfig);
    }

    assertNser(nser, friendly, {
        supportingDB: {
            type: ETypeOf.Object,
            isRequired: true,
            custom: ({ value }) => {
                const $keys = pickKeys<ValueOf<TSRdatabaseConfig>>()
                values(value)
                .map(item => {
                    if (isInvalid(item.idDatabaseManifest)) {
                        friendly.addValidationError(
                            gTranslations.errors.missingField,
                            $keys.idDatabaseManifest,
                        )
                    }

                    if (isInvalid(item.idDatabaseManifest)) {
                        friendly.addValidationError(
                            gTranslations.errors.missingField,
                            $keys.idDatabaseManifest,
                        )
                    }
                })
            },
        },
    })

    return friendly;
};