import { Component, Inject, ViewChild, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { IColmeiaDialogComponentData } from 'app/components/dialogs/dialog/dialog.component';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { TFormSchemmaArray } from '@colmeia/core/src/general-form/general-form-interface';
import { GeneralFormService } from 'app/services/general-form.service';
import { SessionService } from 'app/services/session.service';
import { AttendanceService } from 'app/services/attendance.service';
import { IAttendentServicePackClient } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendente-service-pack';
import { AnnotationsModalHandler, EAnnotationsType } from 'app/handlers/annotations-modal-handler';
import { TArrayID } from '@colmeia/core/src/core-constants/types';
import { ourTags } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { IGeneralFormAnswer } from "@colmeia/core/src/general-form/general-form-answer";
import { isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { TComputedInfo } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';

@Component({
    selector: 'app-annotations-modal',
    templateUrl: './annotations-modal.component.html',
    styleUrls: ['./annotations-modal.component.scss']
})
export class AnnotationsModalComponent extends RootComponent<
    'list' | 'create' | 'header'
> implements OnInit {
    @ViewChild(MatTabGroup) matTabs: MatTabGroup;
    schemas: TFormSchemmaArray;
    handler: AnnotationsModalHandler;
    public computedInfo: TComputedInfo;
    public isWindow: boolean = false;
    public idGroup: TGlobalUID;

    constructor(
        private session: SessionService,
        private generalFormSvc: GeneralFormService,
        private attendanceSvc: AttendanceService,
        @Optional() private dialogRef: MatDialogRef<AnnotationsModalComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dataToComponent: IColmeiaDialogComponentData<AnnotationsModalHandler>,
        @Optional() private windowRef: ColmeiaWindowRef<AnnotationsModalComponent, unknown, AnnotationsModalHandler>,
    ) {
        super({
            create: gTranslations.common.create,
            list: gTranslations.annotations.list,
            header: gTranslations.annotations.header,
        });

        if (isValidRef(windowRef)) {
            this.isWindow = true;
            this.handler = windowRef.data;
        } else {
            this.handler = dataToComponent.getParamsToChildComponent();
        }

        this.computedInfo = this.handler.getComponentParameter().computedInfo || {};
    }

    ngAfterViewInit() {
        if (this.handler.getComponentParameter().openOnCreate) {
            setTimeout(() => {
                this.matTabs.selectedIndex = 1;
            }, 0);
        }
    }


    async ngOnInit() {
        this.idGroup = this.handler.getComponentParameter().idGroup || this.session.getSelectedGroupID()

        this.schemas = this.handler.getType() == EAnnotationsType.onlyAnswersFromInteraction
            ? []
            : await this.generalFormSvc.getSchemas(ourTags.colmeiaTag.markers.serviceAttendent.idNS, null, this.idGroup);
        if (!await this.attendanceSvc.isReadyToAttend()) {
            return
        }

        const macroPackage: IAttendentServicePackClient = this.attendanceSvc.getAttendentServicePackForGroup(this.idGroup)
        if (!macroPackage) {
            return
        }

        // @TODO look this
        const metadatas: TArrayID = macroPackage.attendantRegisterMedatadas.map(metadata => metadata.idMetadata);
        this.schemas = this.schemas.filter(schema => metadatas.includes(schema.idSchemma))
    }

    annotationCreated(answer: IGeneralFormAnswer) {
        this.handler.onSaveAnnotationCallback(answer);

        if (this.handler.getComponentParameter().disableAutoClose) return;

        if (this.isWindow) {
            this.windowRef.close();
        } else {
            this.dialogRef.close();
        }
    }

    canShowCreateTab(): boolean {
        const { hideCreateTab } = this.handler.getComponentParameter();
        return !hideCreateTab;
    }

    public get hasAnswerList() {
        return !isValidRef(this.handler.answer) && isValidString(this.handler.serializableId);
    }
}
