<div class="l-container">
  <ng-container *ngIf="isOnline() else mustBeOnlineDude">
    <ng-container *ngIf="hasPreviousState()">
      <div class="preview-container" [class.is-viewing-previous]="isViewPreviousState()">
        <button class="previous-state-view" (click)="toggleViewPreviousState()">
          <div class="l-previous">
            <mat-icon class="mat-14 l-ps-toggle">{{ getIsViewPreviousStateIcon() }}</mat-icon>
            {{ getPreviousStateButtonLabel() }}
          </div>
        </button>
        <div class="last-state-date">
          Resultado em <span class="state-time">{{ previousStateDate() }}h</span>
        </div>
      </div>
    </ng-container>

    <header>
      <h4 class="l-title">Atendimentos na fila:</h4>
      <span class="counter">
        <svg class="processing-indicator" width="100%" [class.paused]="isInBet || isViewPreviousState()" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stop-color="#80ebd7" />
              <stop offset="50%" stop-color="#4effb7" />
            </linearGradient>
          </defs>
          <rect width="100%" height="32" rx="4" stroke="url(#gradient)" stroke-width="4px"/>
        </svg>
        {{ attendancesIslandCountHash.totalCases }}</span>
    </header>

    <div *ngIf="attendancesIslandCountHash.totalCases" [@verticalAppear]="'in'">
      <div class="islands-list nice-scroll scroll-accent">
        <div *ngFor="let islandCase of attendancesIslandCountHash.islandCases; islandTrackBy"
          class="island-item"
          [class.highlighted-island]="getHighlightStatusTheme(islandCase.idIsland)"
          [ngClass]="getHighlightStatusTheme(islandCase.idIsland)">
          <span class="island-name">
            {{ islandCase.name }}
            <mat-icon *ngIf="getHighlightStatusIcon(islandCase.idIsland) as matIcon" class="mat-12 status-icon" style="margin-right: 4px;">{{ matIcon }}</mat-icon>
          </span>
          <span class="cases-count">{{ islandCase.casesLength }}</span>
        </div>
      </div>
    </div>
    <div class="button-alert-container">
      <button-alert #buttonAlert wrap [config]="initialButtonAlertState"></button-alert>
      <div class="button-container">
        <button
          class="call-to-action button-w-icon"
          mat-flat-button
          [disabled]="!attendancesIslandCountHash.totalCases"
          [loading]="!isInCacheInvalidationAfterStatusUpdate"
          color="accent"
          (click)="catchOne($event)"
        >
          Atender
          <mat-icon>arrow_circle_down</mat-icon>&nbsp;
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #mustBeOnlineDude>
    <app-alert type="accent" matIcon="info">Você precisa estar online para atender</app-alert>
  </ng-template>
</div>
