import { RemountInterface } from "@colmeia/core/src/tools/utility-types";
import { EDelivery360Action } from "../../comm-interfaces/interaction-interfaces";
import { genericTypedSuggestions } from "../../tools/type-utils";
import { isInEnum, isInvalid, isInvalidArray, isThisOneOfThat, isValidArray, isValidRef, pickKeys, typedCloneLodash } from "../../tools/utility";
import { EGeneratorTransactionType } from "../knowledge-base/bot-transaction/bot-transaction";
import { EMetadataNames } from "../metadata/metadata-db";
import { IBasicAsset, KAssetType, KBAssetType } from "./bot-asset-model";
import { EBotContentEvent, IContentBasicAsset, TContentAssetArray, hasValidContent } from "./bot-content-model";
import { EBotEventType } from "./bot-event-model";
import { EActionTreeIncrementor } from "./bot-interfaces";
import { EMenuMode, ENextGenBotElementType, EWhatsappMenuMode, IBotElement, IBotElementWithRenderOptions, IBotRoot, IConfigChannelRender, IMenuContainerDisplayOptions, IWhatsAppActionMessageTemplate, TENextGenBotElementTypeArray, getInitialActionWithId } from "./bot-model";
import { EBotActionType } from "./new-bot-action";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { TComputedInfo, replaceCompiledMessageByTagContent } from "../metadata/metadata-utils";


export interface IGlobalConincalConf {
    isSafe?: true;
    isUsableOn?: TENextGenBotElementTypeArray;
}

const allGlobalCond = genericTypedSuggestions<{ [globalCononical: string]: IGlobalConincalConf }>()({
    [EMetadataNames.envGreetingDaily]: {
        isSafe: true,
        isUsableOn: [ENextGenBotElementType.nlpTransaction] as ENextGenBotElementType[],
    },
    [EMetadataNames.envInFrontOfMe]: {
        isSafe: true,
    },
    [EMetadataNames.envDispatch]: {
        isSafe: true,
    },
    [EMetadataNames.lastUserInput]: {
        isSafe: true,
    }
});

export function hasRequiredBotContentProperty<Bot extends IBotElement>(bot: Bot):
    Bot['botLevel'] extends keyof typeof botMinorConfig
    ? typeof botMinorConfig[Bot['botLevel']]['hasContentProperty']
    : false {
    const config: IBotMinorConfig = botMinorConfig[bot.botLevel as keyof typeof botMinorConfig];
    return isValidRef(config) ? botMinorConfig[bot.botLevel].hasContentProperty : false;
}

export interface IBotMinorConfig {
    hasContentProperty: boolean;
}

export const botMinorConfig = genericTypedSuggestions<{ [key in ENextGenBotElementType]?: IBotMinorConfig }>()({
    [ENextGenBotElementType.menuContainer]: {
        hasContentProperty: true,
    },
    [ENextGenBotElementType.botMenuItem]: {
        hasContentProperty: true,
    },
})

export function isMetadataSafe(meta: EMetadataNames): boolean {
    const conf: IGlobalConincalConf = allGlobalCond[meta];
    return isValidRef(conf) ? conf.isSafe : false
}

export function isMetaAvaliableOnBotLevel(meta: EMetadataNames, botLevel: ENextGenBotElementType): boolean {
    const conf: IGlobalConincalConf = allGlobalCond[meta];
    return isValidRef(conf) ? isInvalidArray(conf.isUsableOn) || conf.isUsableOn.includes(botLevel) : true;
}


export function isNLP(type: EGeneratorTransactionType): boolean {
    return isThisOneOfThat(type, ENextGenBotElementType.nlpTransaction)
}

export function isColmeiaForms(type: EGeneratorTransactionType): boolean {
    return isThisOneOfThat(type, ENextGenBotElementType.formTransaction)
}

export function isRegularContentAsset(asset: IBasicAsset): boolean {
    return isContentAsset(asset.type) && isInvalid((<IContentBasicAsset>asset).botContentType)
}


export function isPrePosContentAsset(asset: IBasicAsset): asset is IContentBasicAsset {
    return isContentAssetOfType(asset, EBotContentEvent.preContent, EBotContentEvent.posContent)
}

export function isContentAsset(type: KAssetType): boolean {
    return isInEnum(KBAssetType, type)
}

export function isActionAsset(type: KAssetType): boolean {
    return isInEnum(EBotActionType, type)
}

export function isEventAsset(type: KAssetType): boolean {
    return isInEnum(EBotEventType, type)
}

// const isAsset = isEntities<
//     | (IContentBasicAsset & { type: KBAssetType.content })
//     | IBotEvent
// >();


export function isExitingAction(action: EBotActionType): boolean {
    return isThisOneOfThat(action, EBotActionType.goBack, EBotActionType.goFirstTree, EBotActionType.isEnd);
}


export function onlyPreContentAsset(arr: TContentAssetArray): TContentAssetArray {
    return arr.filter((e) => { return isContentAssetOfType(e, EBotContentEvent.preContent) });
}

export function onlyPosContentAsset(arr: TContentAssetArray): TContentAssetArray {
    return arr.filter((e) => { return isContentAssetOfType(e, EBotContentEvent.posContent) });
}

export function getContentAssetOfType(arr: TContentAssetArray, contentType: EBotContentEvent): TContentAssetArray {
    if (isInvalidArray(arr)) {
        return [];
    }
    const hasType: boolean = isValidRef(contentType);

    return arr.filter((a) => { return hasType ? isContentAssetOfType(a, contentType) : isRegularContentAsset(a) });
};

export function getSingleContentOfType(arr: TContentAssetArray, contentType: EBotContentEvent): IContentBasicAsset {
    const content = getContentAssetOfType(arr, contentType);
    return isValidArray(content) ? content[0] : undefined;
}


export function isContentAssetOfType(asset: IBasicAsset, ...contentType: EBotContentEvent[]): boolean {
    return isContentAsset(asset.type) && isThisOneOfThat((<IContentBasicAsset>asset).botContentType, ...contentType);
}

export function isAccurracyEvent(type: KAssetType): boolean {
    return isThisOneOfThat(type, EBotEventType.accuracyEvent, EBotEventType.inconclusiveAccuracy, EBotEventType.conclusiveAccuracy, EBotEventType.insuficientAccuracy)
}


export function hasContentOfTypes(contents: TContentAssetArray, types: EBotContentEvent[]) {
    const mapTypeToTrue = new Map();
    for (const content of contents) {
        mapTypeToTrue.set(content.botContentType, true);
    }
    return types.every(type => mapTypeToTrue.has(type));
}



export const initialBotElement: Readonly<IBotElement> = ({ // Object.freeze
    nsType: ENonSerializableObjectType.bot,
    nName: '',
    events: [],
    botLevel: undefined,
});


export function getDisplayOptions(
    bot: IBotElementWithRenderOptions,
    socialProvider: EDelivery360Action,
): IMenuContainerDisplayOptions {
    const disp: IMenuContainerDisplayOptions = bot.renderOptions?.[socialProvider] ?? getDefaultDisplayOptions();
    return disp;
}


export function getDefaultDisplayOptions(): IMenuContainerDisplayOptions {
    const defaultDisplayOptions: IMenuContainerDisplayOptions = {
        menuRenderConfigType: EMenuMode.Text,
        whatsappNumericMenuMode: EWhatsappMenuMode.Enhanced
    }
    return defaultDisplayOptions;
}
export function getDefaultRenderOptions(): IConfigChannelRender {
    const defaultRenderOptions: IConfigChannelRender = {
        [EDelivery360Action.Delivery360WhatsApp]: getDefaultDisplayOptions(),
        [EDelivery360Action.Delivery360SMS]: getDefaultDisplayOptions(),
        [EDelivery360Action.DeliveryInstagram]: getDefaultDisplayOptions(),
        [EDelivery360Action.Delivery360FBMessenger]: getDefaultDisplayOptions(),
        [EDelivery360Action.Delivery360Email]: getDefaultDisplayOptions(),
        [EDelivery360Action.Delivery360Voice]: getDefaultDisplayOptions(),
        [EDelivery360Action.DeliveryTelegram]: getDefaultDisplayOptions(),
        [EDelivery360Action.DeliveryColmeia]: getDefaultDisplayOptions(),
    }

    return defaultRenderOptions;
}


const $botLevel = pickKeys<IBotElement>().botLevel;
const $renderOptions = pickKeys<IBotElementWithRenderOptions>().renderOptions;


export function isBotElement(target: {}): target is IBotElement {
    return $botLevel in target;
}

export function isElementWithRenderOptions(target: {}): target is IBotElementWithRenderOptions {
    return $renderOptions in target;
}

export function initBotRootElement(): IBotRoot {
    const initalBotRootElement: Readonly<IBotRoot> = ({ // Object.freeze
        ...initialBotElement,
        allBotSubject: [],
        botLevel: ENextGenBotElementType.root,
        events: [],
        firstAction: getInitialActionWithId(),
        incrementor: EActionTreeIncrementor.numeric,
        tags: [],
        canonicalIds: [],
        canonicals: [],
        renderOptions: getDefaultRenderOptions(),

    });

    return typedCloneLodash(initalBotRootElement);
}



export function pickBotElementWithRenderOptions(input: IBotElementWithRenderOptions): IBotElementWithRenderOptions {
    const out: RemountInterface<IBotElementWithRenderOptions> = {
        multipleChoiceAdvancedTitle: input.multipleChoiceAdvancedTitle,
        renderOptions: input.renderOptions,
        selectorButton: input.selectorButton,
        whatsApp: input.whatsApp,
    };
    return out;
}



export function isValidActionMessageTemplate(whatsApp: IWhatsAppActionMessageTemplate | undefined) {
    if (!whatsApp) {
        return false;
    }

    if (hasValidContent(whatsApp?.header?.content)) {
        return true;
    }
    if (hasValidContent(whatsApp?.footer?.content)) {
        return true;
    }

    return false;
}


export function compileTitleAndDescriptionForBotAction(
    computedInfo: TComputedInfo,
    contentArray: TContentAssetArray,
    extraOptions?: { titleAssetFallback?: IContentBasicAsset, descAssetFallback?: IContentBasicAsset }
): { title?: string, desc?: string } {
    const titleAsset = getSingleContentOfType(contentArray, EBotContentEvent.optionTitle) || extraOptions?.titleAssetFallback;
    const descriptionAsset = getSingleContentOfType(contentArray, EBotContentEvent.optionDescription) || extraOptions?.descAssetFallback;

    const title = titleAsset
        ? replaceCompiledMessageByTagContent(
            titleAsset.variablesTemplate.compiledTemplate,
            titleAsset.variablesTemplate,
            [],
            computedInfo,
            {}
        )
        : 'empty';

    const desc = descriptionAsset
        ? replaceCompiledMessageByTagContent(
            descriptionAsset.variablesTemplate.compiledTemplate,
            descriptionAsset.variablesTemplate,
            [],
            computedInfo,
            {}
        )
        : '';

    return { title, desc }
}