import { asConst } from '../tools/type-utils';
import { EnumValue } from '../tools/utility';
import {
    EGeolocationIntType, EObjectType, EServiceGroupIntType, ETimedInteractionTypeID, EUnitTypeID, EWalletOperationsIT
} from './constant.enums';
import { trackerTypes } from './tracker-constant';

// I had to leave it here because almost everywhere TGlobalUID is used  
export type TGlobalUID = string;

export const refreshLinks = '__live';
export const gColmeiaEmail = '@colmeia.me';

type TConstant = { readonly [key in EnumValue]: EnumValue | TConstant };
export const iSessionData = 'iSessionData'

export const constant = asConst<TConstant>()({
    objectType: {
        ...EObjectType
    },

    groupType: {
        root: 'root',

        functionalRoot: {
            playerPersonalGroup: 'personalGroup',
            tribe: 'inspire',
            corporate: 'corporate',
            sponsored: 'sponsored',
        },

        standard: {
            standardGroup: 'standardGroup',
            channel: 'channel',
            anonymous: 'anonymousGroup',
        },

        personalGroupTypes: {
            avatarPersonalGroup: 'avatarPersonalGroup',
            privateMessage: 'privateMessage',
        },

        support: {
            personalDistributionListRecipient: 'personalDistributionListRecipient',
            invitedGroup: 'invitedGroup',
            archive: 'archive',
            administrator: 'administrator',
            advertising: 'advertising',
            adminConfigs: 'adminConfigs',
        },

        functional: {
            spokenGroup: 'spokenGroup',
            broadcast: 'broadcast',
            massCommunication: 'massCommunication',
            distributionList: 'distribution',
            featureCreator: 'featureCreator',
            inventory: 'inventory'
        },
    },

    interactionType: {
        menu: {
            menuReply: 'menuReply',
            menuHeader: 'menuHeader',
        } as const,
        annotation: {
            interactionType: 'annotation',
            group: {
                archive: 'archiveGroup'
            }
        },
        wallet: {
            ...EWalletOperationsIT,
        },
        products: {
            prePurchase: {
                billableDriverFeedback: 'billable',
                feedbacks: {
                    wantPurchase: 'wantPurchase',
                    wishList: 'interestPurchase',
                    notInterested: 'notInterestedPurchase',
                },
            },
            purchase: {
                confirmation: 'purchaseConfimation',
                feedbackDriver: 'payment',
                feedbacks: {
                    iConfirmMyPurchase: 'iConfirmMyPurchase',
                    personalPurchaseConfirmation: 'personalPurchaseConfirmed'
                }
            }

        },
        payment: {
            medium: 'paymentMedium',
            customer: 'paymentCustomer',
            source: 'paymentSource'
        },
        standard: {
            deleteInteraction: 'deleteOwnMessage',
            message: 'message',
            citation: 'citation',
            checkIn: 'checkIn',
            driverInteraction: 'drivenInteraction',
            featureCarrier: 'featureCarrier'
        },


        configCarriers: {
            configurationCarrier: 'configurationCarrier',
            configurationItem: 'configurationItem',
        },

        gpsTracker: {
            ...EGeolocationIntType
        },

        special: {
            grantSpokeStatus: 'grantSpokeStatus',
            setSpokeAvatar: 'setSpokeAvatar',
            dueDate: 'dueDate',
            warnMessage: 'warnMessage',
            wrapperInteraction: 'wrapper'
        },

        serviceGroup: {
            ...EServiceGroupIntType
        },

        security: {
            groupSecurityRule: 'newGroupSecurity',
            revokeGroupSecurity: 'removeGroupSec',
            newParticipant: 'newParticipant',
            newRole: 'newRole',
            revokeRole: 'revokeRole',
            grantorRegister: 'grantor',
            newGroupCreated: 'newGroup',
            welcomeToColmeia: 'welcomeToColmeia',
        },

        feedback: {
            denounce: 'denounce',
            qualify: 'qualifyInformation',
            react: 'react',
            vote: 'voteChoice',
            choseResourceTree: 'choseResourceTree',
            choseResourceSolicitation: 'choseResourceSolicitation',
            chosenPreference: 'chosenPreference',
            newThread: 'newThread',

        },

        sharing: {
            shareInteraction: 'shareInteraction',
            addAudience: 'sumAudience',
            stopPublishGroup: 'stopGroupPublish',
            startPublishGroup: 'messageReplicate',
        },

        subscription: {
            subscription: 'subscription',
            stdResponse: 'standardNotificationResponse',
            feedback: {
                accepted: 'solicitationAccepted',
                rejected: 'solicitationRejected',
                canceled: 'subCanceled',
                spam: 'soliciationSpam',
                automaticallyApproved: 'automaticApproved',
                automaticRejected: 'automaticRejected',
            },
        },
        changeStateSynch: {
            trackers: 'groupViewManager',
            changedCarrierInteraction: 'changedCarrierInteraction',
            feedbackFiguresCarrier: 'feedbackFiguresCarrier',
        },
    },

    subscription: {
        request: {
            group: {
                employee: 'followAsEmployee',
                groupParticipation: 'groupParticipateRequest',
            },
            avatar: {
                realTime: 'realTimeFollow',
                teamMembershipRequest: 'teamMembership',
            },
            friendship: 'friendshipRequest',
            dottedTeamMembership: 'dottedTeamMembership',
            inviteToGroup: 'inviteToGroupRequest',
            groupFusionRequest: 'groupFusionRequest',
            distributionList: 'distributionListRequest',
            privateMessage: 'privateMessageRequest',
        },
        teaming: {
            teamMembership: 'teamMembership',
            dottedTeamMembership: 'dottedTeamMembership',
        },
    },
    commands: {
        tracking: {
            beginTracking: 'commandOnlineTracking',
            endTracking: 'commandEndOnlineTracking',
        },
        addPayment: 'commandAddPayment',
        sharedParameters: {
            avatar: 'parOnlineTrackingIDAvatar',
            group: 'parGetGroup',
        },
    },
    socialContext: {
        colmeia: 'ALL-ROOT',
    },
    dueDate: {
        allResponseSettle: 'allResponseSettle',
    },
    entity: {
        rootGroups: {
            root: 'ALL-ROOT',
            enterprise: 'allCorporate',
            tribes: 'allInspire',
            allPersonalRoot: 'allPersonalRoot',
        },
        player: {
            odin: 'ODIN',
        },
        firstSecuritySettings: 'first_sec_settings',
        avatarPrivacy: 'odinPrivacyOption',
        odinAvatar: '*ODIN*',
    },

    passport: {
        field: 'user',
        snlog: 'snlog',
    },
    unity: {
        ...EUnitTypeID
    },
    feedback: {
        opcao1: '1aOpcao',
        opcao2: '2aOpcao',
        opcao3: '3aOpcao',
        amando: 'Amando',
        correta: 'Correta',
        discriminatorio: 'Discriminatorio',
        explicito: 'Explicito',
        gostei: 'Gostei',
        improprio: 'Improprio',
        incorreta: 'Incorreta',
        irado: 'Irado',
        naoGostei: 'NaoGostei',
        rindoMuito: 'RindoMuito',
        triste: 'Triste',
        troll: 'Troll',
        veriricar: 'Veriricar',
        wow: 'Wow',
        playerConfirmQueueAllocation: 'playerConfirmQueueAllocation',
        playerCancelQueueAllocation: 'playerCancelQueueAllocation',
        admReleaseQueueAllocation: 'admReleaseQueueAllocation',
        admAllocateQueueToPlayer: 'admAllocateQueueToPlayer',
        threadFeedback: 'threadFeedback',

    },
    relatedToInteractionType: {
        security: 'SEC',
        feedback: 'FEE',
        soliciation: 'SOL',
        message: 'MSG',
        other: 'OTH',
        queue: 'QUE',
        deleted: 'DEL',
        timed: 'TIM',
        config: 'CFG',
        faceCompany: 'FAC',
    },
    queueStatus: {
        enqueuedWaitingList: 'enqueuedWaitingList',
        automaticAllocated: 'automaticAllocate',
        couldNotAllocate: 'couldNotAllocate',
        allocatedUntilConfirmation: 'allocatedUntilConfirmation',
        avatarConfirmedAllocation: 'avatarConfirmedAllocation',
        rejected: 'avatarRejectedAllocation',
        confirmationExpired: 'confirmationExpire',
    },
    events: {
        newInteraction: 'newInteraction'
    },
    elasticSearch: {
        maxSearchResults: 50
    },
    serializableField: {
        name: 1,
        description: 2,
        tooltip: 3,
        chat_text: 4,
        prompt: 5,
        disclaimer: 6,
        queue: {
            comeHere: 7,
            noResource: 8,
            notAssigned: 9,
            assigned: 10,
        },
        personalData: {
            signature: 11,
            telephone: 12,
            country: 15,
            birthdate: 16,
            city: 17,
        },
        transcription: 13,
        emoji: 14,
        icon: 18,
        auxiliars: {
            aux01: 19,
            aux02: 20,
            aux03: 21,
            aux04: 22,
            aux05: 23,
            aux06: 24,
            aux07: 25,
            aux08: 26,
            aux09: 27,
            aux10: 28,
            aux11: 29,
            aux12: 30,
            aux13: 31,
            aux14: 32,
            aux15: 33,
            aux16: 34,
            aux17: 35,
            aux18: 36,
            aux19: 37,
            aux20: 38,
            aux21: 39,
            aux22: 40,
            aux23: 41,
            aux24: 42,
            aux25: 43,
            aux26: 44,
            aux27: 45,
            aux28: 46,
            aux29: 47,
            aux30: 48,
            aux31: 49,
            aux32: 50,
            lastAuxiliar: 7000
        },
        subscription: {
            ownerMessage: 7005,
            receiverMessage: 7006,
            iconNotification: 7007,
            notificationText: 7001,

        },
        notificationRequestText: 7002,
        notificationResponseText: 7003,
        group: {
            recertificationInterval: 7004,
        }
    },
    avatarRelatedTo: {
        group: 'G',
        player: 'P',
    },
    structures: {
        apiSessionDescriptor: 'apiSessionStatus',
        ISessionData: iSessionData,
        idPlayer: 'idPlayer',
        begin: 'Z',
        mobile: 'M',
        socketID: 'socketID'
    },
    visualElementParameter: {
        permission: "permission",
        url: "url"
    },
    trackerTypes: {
        ...trackerTypes
    },
    timedType: {
        ...ETimedInteractionTypeID
    },
    configurationType: {
        emojis: 'empojis',
    },
    panes: {
        whoCanSeeMessageReceptionPane: 'whoCanSeeMessageReceptionPane',
    },
    errorType: {
        internalSystemError: 'I', // erros ficam no servidor
        securityIssue: 'S',
        bug: 'X',
        featureNotCoveredByContract: 'C',
        cantProceedWithRequest: 'R',
        message: 'M'
    },

    generalForm: {
        serializableId: 'GeneralForm',
        annotations: 'Annotations'
    },

    tagger: {
        serializableId: 'Tagger'
    },

    chatFilter: {
        /**
         * @deprecated botão não mais usado
         */
        serializableId: 'ChatFilter'
    },

    chatOption: {
        /**
         * @deprecated screenGroup deprecado, o alternativo está sendo usado
         */
        screenGroup: 'ChatOptions',
        alternateScreenGroup: 'ChatOptions2', // criado para facilitar o deploy
        serializableId: 'ChatOption',
        /**
         * @deprecated botão não mais usado
         */
        visualization: 'ChatOption/Visualization',
        attendantRegistration: 'ChatOption/AttendantRegistration',
        attendantDynamicListRegistration: 'ChatOption/AttendantDynamicListRegistration',
        clientRegistration: 'ChatOption/ClientRegistration',
        finalization: 'ChatOption/Finalization',
        /**
         * @deprecated botão não mais usado
         */
        attendance: 'ChatOption/Attendance',
        attendanceScreenGroup: 'ChatOptions/Attendant',
        transference: 'ChatOptions/Transference',
        support: 'support',
        activeCommunication: 'activeCommunication'
    },

    attendanceIsland: {
        serializableId: 'AttendanceIsland',
        attendantRegistration: 'ChatOption/AttendantRegistration'
    },

    preDeletionMessages: {
        serializableId: 'PreDeletionMessages'
    },
    channelTypePicker: 'channelTypePicker',
    maxAssetTextLength: 1600,
} as const);

export const friendlyAction = {
    none: 0,
    subscription: {
        goToGroup: 1,
    }
};

export const JAVASCRIPT_MAX_DATE_IN_MS = 8640000000000000 // 

export const nonTranslatedField = [
    constant.serializableField.emoji, constant.serializableField.icon, constant.serializableField.transcription,
    constant.serializableField.subscription.iconNotification
]
