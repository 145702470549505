export enum EDocCanonicalCommonProps {
  fullName = "fullName",
  idDocument = "idDocument",
  cpf = "cpf",
  birthLocation = "birthLocation",
  expirationDate = "expirationDate",
  observation = "observation",
  filiation = "filiation",
  filiation1 = "filiation1",
  filiation2 = "filiation2",
  issuingAuthority = "issuingAuthority",
  birthDate = "birthDate",
  emissionDate = "emissionDate",
  signatureLocation = "signatureLocation",
}

export enum ECNHCanonical {
  docIdentidadeNumber = "docIdentidadeNumber",
  category = "category",
  firstLicenseDate = "firstLicenseDate",
  nationality = "nationality",
}

export enum ERgCanonical {
  socialName = "socialName",
  originDoc = "originDoc",
  voterIdCard = "voterIdCard",
  socialSecurityNumber = "socialSecurityNumber",
  militaryCertificateNumber = "militaryCertificateNumber",
  cnhNumber = "cnhNumber",
  ctpsNumber = "ctpsNumber",
  nationalPublicHealthCardNumber = "nationalPublicHealthCardNumber",
  gender = "gender",
  rhFactor = "rhFactor",
  registroCivil = "registroCivil",
}

export type TDocCanonical =
  | EDocCanonicalCommonProps
  | ECNHCanonical
  | ERgCanonical;

export const EDocCanonical = {
  ...EDocCanonicalCommonProps,
  ...ECNHCanonical,
  ...ERgCanonical,
};

// export type TFieldHandlers = (blocks: Object, acc: Object) => Object;

const CNHFields = {...EDocCanonicalCommonProps, ...ECNHCanonical};

export type ECNHFields = typeof CNHFields;

export type TGenericDocFields<T> = {
    [fieldName in keyof T]: string
}

export type TSanityCheckSignature = (value: string) => boolean;

export interface ISanityChecker {
    (value: string): boolean;
}

export interface IStrategyConfig {

    fieldName: string
    blockToSearch: string,
    fieldSearchMatchString: Array<string>,
    sanityChecker?: TSanityCheckSignature,
    additionalCheck?: (x: any) => boolean
    fieldExtractor?: (x: string) => string
}


export enum EDocTypes {
    RG = "RG",
    CNH = "CNH",
    CUSTOM = "CUSTOM"
}

export enum EDOCSegment {
  front = "front",
  back = "back",
}

export type IStrategyConfigArray = Array<IStrategyConfig>