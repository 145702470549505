import { TGlobalUID } from "../../core-constants/types";
import { getIdentifiedNS } from "../../nser/blank-ns";
import { isValidArray, isValidObject, isValidRef, objectShallowReplace } from "../../tools/barrel-tools";
import { addTagToNS } from "../colmeia-tags/tag-config-db";
import { EDefaultTag, TTagAssignmentArray } from "../colmeia-tags/tags";
import { ENonSerializableObjectType, INonSerializableIdentification } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";
import { EYouTaskStatus, EYouTasksType, IYouTaskFormSolicitationClient, IYouTaskGroupApprovalClient, IYouTasksClientBasic } from "./solicitation-tasks-model";

export interface IUserTaskFactoryNserBasic {
    nName: string;
    ident?: INonSerializableIdentification;
    tagsIds?: string[];
    solicitantName: string;
}

export interface IUserTaskFactoryNserFormSolicitation extends IUserTaskFactoryNserBasic {
    idAnswer: IdDep<ENonSerializableObjectType.clientGeneralAnswer>;
    solicitantTarget: string;
    override?: Partial<IYouTaskFormSolicitationClient>;
}

export interface IUserTaskFactoryNserGroupApproval extends IUserTaskFactoryNserBasic {
    idGroup: TGlobalUID;
    idSocialNetwork: TGlobalUID;
    idAvatar: TGlobalUID;
    groupName: string;
    taskType?: EYouTasksType.groupParticiparionApproval | EYouTasksType.socialNetworkParticipationApproval;
    override?: Partial<IYouTaskGroupApprovalClient>;
}

export class YouTaskCoreService {

    private static getBasicNSerProps(): Omit<IYouTasksClientBasic, 'details'> {
        return {
            isOpen: true,
            solicitationStatus: EYouTaskStatus.open,
            alertCT: 0,
            recipients: [],
            inputs: [],
        }
    }

    static factoryFormSolicitationNser(par: IUserTaskFactoryNserFormSolicitation): IYouTaskFormSolicitationClient {
        const taskNSName: string = par.nName;
        const taskNS: IYouTaskFormSolicitationClient = {
            ...getIdentifiedNS(ENonSerializableObjectType.yourTasks, taskNSName, par.ident),
            ...YouTaskCoreService.getBasicNSerProps(),
            details: {
                taskType: EYouTasksType.formSolicitation,
                idAnswer: par.idAnswer,
                solicitantName: par.solicitantName,
                solicitantTarget: par.solicitantTarget,
            },
        };

        if (isValidObject(par.override)) {
            objectShallowReplace(taskNS, par.override);
        }

        par.tagsIds ||= [];
        addTagToNS(taskNS, par.ident.idAvatar, EDefaultTag.security, par.tagsIds);

        return taskNS;
    }

    static factoryGroupApprovalNser(par: IUserTaskFactoryNserGroupApproval): IYouTaskGroupApprovalClient {
        const taskNSName: string = par.nName;
        const taskNS: IYouTaskGroupApprovalClient = {
            ...getIdentifiedNS(ENonSerializableObjectType.yourTasks, taskNSName, par.ident),
            ...YouTaskCoreService.getBasicNSerProps(),
            details: {
                taskType: par.taskType,
                idGroup: par.idGroup,
                idSocialNetwork: par.idSocialNetwork,
                solicitantAvatarName: par.solicitantName,
                groupName: par.groupName,
            },
        };

        if (isValidObject(par.override)) {
            objectShallowReplace(taskNS, par.override);
        }

        par.tagsIds ||= [];
        addTagToNS(taskNS, par.idAvatar, EDefaultTag.security, par.tagsIds);

        return taskNS;
    }
}