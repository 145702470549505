
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { ETooltipInfoKey } from "@colmeia/core/src/shared-business-rules/tooltip-json/tooltip-json.model";
import { MS } from "@colmeia/core/src/time/time-utl";
import { defaultFields } from "@colmeia/core/src/tools/utility";
import { MainHandler } from "app/handlers/main-handler";
import { IComponentParameter } from "app/model/component-comm/basic";


export interface IInputNumberTextParameter {
    placeholderText?: string;
    label?: string;
}

interface Unit {
    name: string;
    toMS: MS;
}

export interface InputNumberParameter extends IComponentParameter, IInputNumberTextParameter {
    initialNumber: number;
    min: number;
    max: number;
    clientCallback: IInputNumberClientCallback;
    suffix?: string
    appearance?: MatFormFieldAppearance;
    disabled?: boolean;
    removeMarginBottom?: boolean;
    numberOfUnitConversion?: number;
    units?: Unit[];
    thousandSeparator?: boolean;
    /**
     * @deprecated implementei esse parâmetro e não precisei usar, portanto não foi muito bem testado
     * usei a tag deprecated para que isso ficasse evidente
     */
    allowEmpty?: boolean;
    helperInfo?: ETooltipInfoKey | string;
    hiPopover?: boolean;
}

export type IInputNumberParameter =
    & InputNumberParameter
    & ({ placeholderText: string; } | { label: string; })
    ;

export interface IInputNumberClientCallback {
    onChangeNumberCallback(number: number): void;
}

export interface IInputNumberSlaveCallback {
    onChangeNumberSlaveCallback(number: number): void;
}
function getDefaultParameters() {
    const defaultParameters: Partial<IInputNumberParameter> = {
        numberOfUnitConversion: 1,
    };
    return defaultParameters;
}

export class InputNumberHandler extends MainHandler<IInputNumberParameter> {

    slave: IInputNumberSlaveCallback;

    constructor(parameter: IInputNumberParameter) {
        defaultFields(parameter, getDefaultParameters());
        super(parameter);
    }

    setSlave(slave: IInputNumberSlaveCallback): void {
        this.slave = slave;
    }

    setInitialNumber(num: number) {
        this.getComponentParameter().initialNumber = num
    }
}
