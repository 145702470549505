import { NamedString } from "@colmeia/core/src/core-constants/named-types";
import { TArrayID } from "@colmeia/core/src/core-constants/types";
import { IBotSubject, IBruteForceAcuracyInfoCalc } from "@colmeia/core/src/shared-business-rules/bot/bot-interfaces";
import { EEmbeddingProvider } from "@colmeia/core/src/shared-business-rules/generative/generative-model";
import { AzureCLUCore } from "@colmeia/core/src/shared-business-rules/knowledge-base/clu/azure-clu.core.interfaces";
import { IBasicRegression, TFitterStrategyConfig } from "@colmeia/core/src/shared-business-rules/knowledge-base/fit-curve";
import { INonSerializableHeader, NonSerializable, ENonSerializableObjectType, IdVector, IdChunk, IdPrompt, IdSchema } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";
import { EUnitTypeID } from "../../business/constant.enums";
import { EConnectionType } from "../connections/endpoint-model";


export interface IMLCreateIntent {
    intentName: string
}

export type TIUtteranceServerArray = Array<IUtteranceServer>;


export interface IUtteranceClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.utterance
}
export interface IUtteranceServer extends IUtteranceClient, NonSerializable<ENonSerializableObjectType.utterance> {
    nsType: ENonSerializableObjectType.utterance
    idKB: string;
    utterance: IMLLuisUtterenceExample;
};

export interface IMLLuisPattern {
    patternId?: string;
    pattern: string;
    intentName: string;
}
export interface IPredictiveKeywordsForGenerative {
    idPredictConfig: string;
    keyword: string;
}

export interface ISemanticIntentConfig {
    keywords: IPredictiveKeywordsForGenerative[]
}

export interface IToggleable {
    isOn?: boolean;
}

export interface ISemanticIntentConfig extends IToggleable {
    keywords: IPredictiveKeywordsForGenerative[]
}

export interface IGenerativeIntentConfig extends IToggleable {
    idVector: IdVector;
    idsNSChunk: IdChunk[];
}

export interface IBruteForceIntentConfig extends IToggleable, IBotSubject {}

export interface IMachineLearningIntentConfig extends IToggleable {}

export interface IOmniSenseIntentConfig {
    botSubject?: IBruteForceIntentConfig; // Configuração: Força bruta
    // idCorporateSearch?: string // Zé disse para arrancarms do Enum

    generative?: IGenerativeIntentConfig // Configuração: Generativo
    predictConfig?: ISemanticIntentConfig; // Configuração: Semantico
    machineLearning?: IMachineLearningIntentConfig; // Configuração: Machine learning
}

export type ExternalId = NamedString<'ExternalId'>;
export type IntentName = NamedString<'IntentName'>;
export type IntentId = NamedString<'IntentId'>;
export type IdIntent = IntentId;
export type IdAsset = NamedString<'IdAsset'>;


export interface IMLLuisIntent extends IBruteForceAcuracyInfoCalc {
    intentId: IntentId; // Nosso
    externalId?: ExternalId;
    intentName: IntentName;
    utterancesCount: number;
    biasOn?: boolean;
    bias?: number; //[0..2] slide de 0 até 2
    intentOSConfig?: IOmniSenseIntentConfig;
    extractor?: IEntityExtractor;
}

export interface IEntityExtractor {
    idSchema: IdSchema;
    propertiesConfigs: IEntityExtractorProperty[];
}

export interface IEntityExtractorProperty {
    description?: string;
    idProperty: string;
}


export interface IMLLuisPredictIntentStrategy {
    score: number;
    strategy: EOmniSenseMethods;
    weight: number
}



export interface IGenerativePredictionInfo {
    idVector: IdVector;
    idsNSChunk: IdChunk[];
    idPrompt: IdPrompt;
}
export interface IMLLuisPredictIntent {
    score: number;
    intent: IntentName;
    idIntent: IntentId;
    strategy: EOmniSenseMethods;
    strategies: { [key in EOmniSenseMethods]?: IMLLuisPredictIntentStrategy }
    // esses campos são preeenchidos no generativpo
}

export interface IMLLuisPredictEntityResolution {
    values: string[]
}

export interface IMLLuisPredictEntity {
    matched: string;
    entityName: string;
    entityType: EMLEntityType;
    role?: string;
    resolution: IMLLuisPredictEntityResolution,
    childs?: IMLLuisPredictEntity[],
    startIndex: number;
    endIndex: number;
    idIntent?: IntentId;
}

export interface IMLLuisPredictResult {
    appId: string;
    query: string;
    hasSubject?: boolean;
    originalText?: string;
    topScoreIntent: IMLLuisPredictIntent;
    intents: IMLLuisPredictIntent[];
    entities: IMLLuisPredictEntity[];
}

export interface IMLLuisAdjustedPredictIntentStrategy extends IMLLuisPredictIntentStrategy {
    adjustedScore?: number;
}

export interface IMLLuisAdjustedPredictIntent extends Omit<IMLLuisPredictIntent, "strategies"> {
    strategies: { [key in EOmniSenseMethods]?: IMLLuisAdjustedPredictIntentStrategy }
}

export interface IMLLuisAdjustedPredictResult extends Omit<IMLLuisPredictResult, "intents">{
    intents: IMLLuisAdjustedPredictIntent[];
}


export interface IMLLuisUtterenceLabel {
    entityName: string;
    entityId: string;
    startCharIndex: number;
    endCharIndex: number;
    children: IMLLuisUtterenceLabel[];
    role?: string;
}

export interface IMLLuisUtterenceEntityPrediction {
    entityName: string;
    entityId: string;
    startTokenIndex: number;
    endTokenIndex: number;
    phrase: string;
    children?: IMLLuisUtterenceEntityPrediction[];
    entityType?: EReadableEntityType;
}

export interface IMLLuisUtterenceExample {  // Simnplificação do Modelo de UTterance do Luis
    intentName: IntentName,
    intentID: string;
    example: string, // sugestões nesse apenas
    exampleId?: number,
    ignoredByEquallity?: boolean;
    isValid?: boolean;
    entityLabels: IMLLuisUtterenceLabel[];
    entityPredictions?: IMLLuisUtterenceEntityPrediction[];
    tokenizedText?: string[];
    userSentence: string; // ex: quero meu boleto de 2019 porque ... => gera o exemplo
    trainedAt?: number; // zero


    lastScore?: number;
    languge?: AzureCLUCore.Language;
}

export interface IMLLuisUtterenceIntentPrediction {
    /**
     * The intent's name
     */
    name?: string;
    /**
     * The intent's score, based on the prediction model.
     */
    score?: number;
}



// prep
// querer meu boleto 2019

export type TUtteranceArray = Array<IMLLuisUtterenceExample>;

export enum EMLLuisTrainResultType {
    inProgress = "InProgress",
    upToDate = "UpToDate",
    fail = "Fail",
    success = "Success",
    queued = "Queued",
    error = "error"
}

export const luisTrainResultTypeConfig: { [key in EMLLuisTrainResultType]: { isError: boolean } } = {
    [EMLLuisTrainResultType.inProgress]: {
        isError: false,
    },
    [EMLLuisTrainResultType.upToDate]: {
        isError: false,
    },
    [EMLLuisTrainResultType.fail]: {
        isError: true,
    },
    [EMLLuisTrainResultType.success]: {
        isError: false,
    },
    [EMLLuisTrainResultType.queued]: {
        isError: false,
    },
    [EMLLuisTrainResultType.error]: {
        isError: true,
    },
}

export interface IMLLuisTrainIntentResult {
    modelId: string,
    result: EMLLuisTrainResultType;
    exampleCount: number;
    failureReason?: string;
}

export interface IMLLuisTrainResult {
    finalResult: EMLLuisTrainResultType;
    intentsResult: IMLLuisTrainIntentResult[];
}

export interface IMLLuisPublishResult {
    appId: string;
    endpointUrl: string;
}

export interface IMLLuisPhraseList {
    phrasesId?: number,
    phrasesName: string,
    phrasesList: TArrayID,
    isExchangeable: boolean
}

export enum EMLEntityType {
    list = 'list',
    simple = 'simple',
    regex = 'regex',
    composite = 'composite',
    patternAny = 'pattern.any',
    prebuilt = 'predefined',
    machineLearned = 'machineLearned',
}

export enum EReadableEntityType {
    MachineLearned = 1,
    Child = 2,
    Hierarchical = 3,
    HierarchicalChild = 4,
    Composite = 5,
    List = 6,
    Prebuilt = 7,
    IntentClassifier = 8,
    PatternAny = 9,
    ClosedList = 10,
    Regex = 11,
}

export interface IMLLuisRole {
    roleId?: string;
    value: string;
}


export interface IMLLuisEntityInfo {
    /**
     * @deprecated
     */
    roles: IMLLuisRole[];
}

export namespace KBCorporateEntity {
    export namespace List {
        export interface SubList {
            listKey: string;
            synonyms: SubListSynonym[];
        }
        export interface SubListSynonym {
            language: AzureCLUCore.Language;
            values: string[];
        }
        export interface Interface {
            sublists: List.SubList[];
        }
    }

    export import List = List.Interface;

    export namespace Regex {
        export interface Expression {
            language: AzureCLUCore.Language;
            regexKey: string;
            regexPattern: string;
        }
        export interface Interface {
            expressions: Expression[];
        }
    }
    export import Regex = Regex.Interface;

    export interface Interface {
        list?: KBCorporateEntity.List;
        regex?: KBCorporateEntity.Regex;
    }
}


export interface INSKBCorporateEntityServer extends NonSerializable<ENonSerializableObjectType.kbCorporateEntity> {
    entity: KBCorporateEntity.Interface;
}

export type TMLCLUEntityInfo =
    | IMLLuisEntitySimpleInfo
    | IMLLuisEntityListInfo
    | IMLLuisEntityRegexInfo
    ;
export type IMLLuisNormalizedValueHash = {
    [canonicalForm in string]: string[]
}
export interface IMLLuisEntitySimpleInfo extends IMLLuisEntityInfo {
}

export interface IMLLuisEntityListInfo extends IMLLuisEntityInfo {
    values: IMLLuisNormalizedValueHash
}

export interface IMLLuisEntityRegexInfo extends IMLLuisEntityInfo {
    regex: string;
}

export interface IMLLuisEntityCompositeInfo extends IMLLuisEntityInfo {
    entitiesName: string[]
}

export interface IMLLuisEntityPatternAnyInfo extends IMLLuisEntityInfo {
}

export enum EFeatureType {
    Phrase = 'phrase',
    Entity = 'entity',
}
export interface IMLLuisFeatureEntity {
    type: EFeatureType.Entity;
    modelName?: string; // Entity name
    isRequired?: boolean;
}
export interface IMLLuisFeaturePhrase {
    type: EFeatureType.Phrase;
    featureName: string; // Phrase name
    isRequired?: false;
}
export type TMLLuisFeature =
    | IMLLuisFeatureEntity
    | IMLLuisFeaturePhrase
    ;
export interface IMLLuisEntity {
    entityId: string;
    entityInfo: IMLLuisEntityInfo;
    entityName: string;
    entityType: EMLEntityType;
    /**
     * @deprecated
     */
    subEntities?: IMLLuisEntityChild[];
    features: TMLLuisFeature[];
};



export interface IMLLuisEntitySimple extends IMLLuisEntity {
    entityType: EMLEntityType.simple;
    entityInfo: IMLLuisEntitySimpleInfo;
}

export interface IMLLuisEntityList extends IMLLuisEntity {
    entityType: EMLEntityType.list;
    entityInfo: IMLLuisEntityListInfo;
}

export interface IMLLuisEntityRegex extends IMLLuisEntity {
    entityType: EMLEntityType.regex;
    entityInfo: IMLLuisEntityRegexInfo;
}

export interface IMLLuisEntityPrebuilt extends IMLLuisEntity {
    entityType: EMLEntityType.prebuilt;
}

export type TMLLuisEntity =
    | IMLLuisEntitySimple
    | IMLLuisEntityList
    | IMLLuisEntityRegex
    | IMLLuisEntityPrebuilt
    ;



export interface IMLLuisPrebuiltEntity extends IMLLuisEntity {
    entityName: EAvailablePrebuiltEntity;
    entityType: EMLEntityType.prebuilt;
    subEntities?: [];
}


export enum EAvailablePrebuiltEntity {
    Age = "age",
    DatetimeV2 = "datetimeV2",
    Dimension = "dimension",
    Email = "email",
    KeyPhrase = "keyPhrase",
    Money = "money",
    Number = "number",
    Ordinal = "ordinal",
    Percentage = "percentage",
    Phonenumber = "phonenumber",
    Temperature = "temperature",
    URL = "url",
}
export interface IAvailablePrebuiltEntity {
    name: string;
    description: string;
    examples: string;
}

export interface ITranslatedAvailablePrebuiltEntity extends IAvailablePrebuiltEntity {
    type: EAvailablePrebuiltEntity;
}

export interface IMLLuisEntityChild extends IMLLuisEntity {
}

export interface IKBAppConfig {
    UseAllTrainingData?: boolean;
    NormalizeDiacritics?: boolean;
    NormalizePunctuation?: boolean;
}

export const isKBCommited = Symbol('isKBCommited');



export interface IMLLuisApp {
    appName: string; //nao pode repetir @TODO gerar readable unique id
    appId?: string; // gerado pelo luis
    lastVersionId?: string;
    appConfig: IKBAppConfig;

    extractorConfig?:  IMLLuisExtractorConfig;

    intents: IMLLuisIntent[];
    /**
     * @deprecated
     */
    readonly phrases: IMLLuisPhraseList[];
    entities: IMLLuisEntity[];
    corporateEntities: IdDep<ENonSerializableObjectType.kbCorporateEntity>[];
    patterns: IMLLuisPattern[];
    osStrategy: IOminiSenseIAConfig;
    intentionChangeConfig?: IOmniSenseSubjectChangeConfig
    fusor?: IFusorConfig;
    clu: {
        shouldCommitWhenChange?: boolean;
        commitedAt?: number;
        config: IAzureCLUAppConfig;
        exported?: string;
        [isKBCommited]?: boolean;
    };
    providers?: {
      embedding?: IEmbeddingProvider
    }
}

export interface IProviderConn {
  idConnection?: IdDep<ENonSerializableObjectType.connection>;
  connectionType?: EConnectionType;
}

export interface ILLMProvider extends IProviderConn {
  connectionType?: EConnectionType.OpenAi;
}

export interface IEmbeddingProvider extends IProviderConn {
  connectionType?: EConnectionType.OpenAi;
}

export interface ICLUProvider extends IProviderConn {
  connectionType?: EConnectionType.CLU;
  description?: string;

  // Verificar se essas informações estão/serão  usadas ese podem ficar aqui
  // CONFIG CLU

  // shouldCommitWhenChange?: boolean;
  // commitedAt?: number;
  // config: IAzureCLUAppConfig;
  // exported?: string;
  // [isKBCommited]?: boolean;
}

export interface IFusorConfig {
    type: EFusorType,
    softmaxTemperature?: number
}

interface IMLLuisExtractorConfig {
    /** Configuration inside each intent, more specifically on: `IMLLuisIntent.extractor` */
    llm: {
        isOn: boolean;
    },
    /** Entities are configured in `IMLLuisApp.entities` */
    machineLearning: {
        isOn: boolean;
    };
}




export interface IAzureCLUAppConfig {
    language: AzureCLUCore.Language;
    projectKind: AzureCLUCore.ProjectKind;
    settings: AzureCLUCore.ConversationalAnalysisAuthoringProjectSettings;
    multilingual?: boolean;
}

export enum EOmniSenseMethods {
    machineLearning = 'OSmachLea',
    // corporateSearch = 'OSsearch', // Zé pediu p arrancar do enum
    bruteForce = 'OSbrute',
    generative = 'OSGenerative',
    semanticPrediction = 'OSSemntcPredctn',
}

export interface IGenerativeFitCurve {
    scalingFactor: number; // a // 0.0001 <-> 0.001 // 0.0005 => 0.00001
    yInterceptor: number; // o
    ratingVariation: number; // b // 3 <-> 10 // 0.005
}

export interface ILimitedGeneativeCurve extends IGenerativeFitCurve {
    type: 'exp' | 'line',
    min: number;
    max: number;
}

export interface ICurves {
    bellow: ILimitedGeneativeCurve;
    focused: ILimitedGeneativeCurve;
    above: ILimitedGeneativeCurve;
}



export interface IOmniSenseStrategyConfig {
    isOn: boolean;
    osTatic: EOmniSenseMethods;
    weight: number; // 1..10;
    fitter?: TFitterStrategyConfig;
}



export interface IBruteForceConfig {
    scoreComparedMatches: boolean; // Considere no Score a quantidade Comparada de Acertos
    comparedMatchWeight: number; //  Relevância    0..2, números reais (0.3, 1.5 et.,), default 1
    minScoreToBeAMatch: number; // score mínimi para ser considerado Match (default 0.5)
}


export interface IBruteForceStragtegyConfig extends IOmniSenseStrategyConfig {
    osTatic: EOmniSenseMethods.bruteForce;
    bruteConfig: IBruteForceConfig
}


export interface ISemanticConfig {}

export interface ISemanticStrategyConfig extends IOmniSenseStrategyConfig {
    osTatic: EOmniSenseMethods.semanticPrediction;
    semanticConfig: ISemanticConfig
}

export interface IGenerativeStrategyConfig extends IOmniSenseStrategyConfig {
    osTatic: EOmniSenseMethods.generative;
}


export type TIOmniSenseStrategyConfigArray = Array<IOmniSenseStrategyConfig>;

export enum EFusorType {
    StaticWeights = "staticWeights",
    SoftmaxWeigths = "softmaxWeights"
}

export interface IOmniSenseStrategiesConfig {
    strategy: TIOmniSenseStrategyConfigArray;
}

export interface IOminiSenseIAConfig extends IOmniSenseStrategiesConfig {
    // Utilizar o primeiro Match
    firstMatchPrediction?: boolean; //

    firstMatchThreshold?: number;
}

export interface IOmniSenseSubjectChangeConfig extends IOmniSenseStrategiesConfig {
    enabled?: boolean;
    accuracyThreshold?: number;
}


export interface WebLuisRecommendationResultRank {
    Rank: number;
    RankerName: string;
}

export interface WebLuisRecommendationResult {
    Blacks: string[];
    InputWords: string[];
    RankingInfos: {
        [rankWord: string]: WebLuisRecommendationResultRank
    };
    SuggestedEntries: string[];
}
