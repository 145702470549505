import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NoopActionBarComponent } from "../noop-action-bar/noop-action-bar.component";
import { delay, isValidFunction, isValidRef } from "@colmeia/core/src/tools/utility";
import {ChatBarWindowHandler} from "../../../../handlers/chat-bar-interactions/chat-bar-window-handler";
import {ICitationHandlers} from "../../../../handlers/chat-bar-interactions/citation/citations-handler";
import {IChatSearchParticipantHandler} from "../../../../handlers/chat-bar-interactions/chat-search-participant-handler";
import {ICitedParticipantsListHandler} from "../../../../handlers/chat-bar-interactions/citation/cited-participants-list-handler";
import {AttachBarHandler} from "../../../../handlers/attach-bar-handler";
import {RecorderHandler} from "../../../../handlers/recorder-handler";
import {ChatSearchParticipantComponent} from "../../chat-search-participant/chat-search-participant.component";
import {MacrosHandler} from "../../../../handlers/macros";
import { debounce } from 'lodash';

@Component({
    selector: 'chat-action-bar-window-component',
    templateUrl: './chat-action-bar-window.component.html',
    styleUrls: ['./chat-action-bar-window.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatActionBarWindowComponent {

    private _showOutlet = true;

    @ViewChild('searchParticipant') searchParticipant: ChatSearchParticipantComponent;

    @Input() handler: ChatBarWindowHandler;
    isMinimized: boolean = false;

    constructor(private cdrRef: ChangeDetectorRef) { }

    setShowOutlet(val: boolean): void {
        this._showOutlet = val;
    }

    get showOutlet(): boolean {
        return this._showOutlet;
    }

    public markForCheck(): void {
        this.cdrRef.markForCheck();

        if(isValidFunction(this.handler?.getParameter().UIUpdate)) {
            this.handler.getParameter().UIUpdate();
        }
    }

    public show(): void {
        this.handler.setShown(true);
        this.markForCheck();
    }

    public onUserClosed(): void {
        this.isMinimized = true;
        this.cdrRef.markForCheck();

        this.handler.getParameter().onUserWindowClosed();
        this.isMinimized = false;
    }

    onMinimizeClicked(): void {
        this.isMinimized = !this.isMinimized;
        this.markForCheck();
    }

    public canShow(): boolean {
        return !this.isMinimized;
    }

    detectChanges(): void {
        this.markForCheck();
    }

    public isReply(): boolean {
        return false;
    }

    public canMinimize(): boolean {
        return this.handler && this.handler.canMinimize() && !this.isReply()
    }

    public canClose(): boolean {
        return this.handler && this.handler.canClose();
    }

    public isValidWindow(): boolean {
        return this.showSearchCitationParticipant
            || this.showCitedParticipants
            || this.showAudioRecorder
            || this.showAttachment
            || this.showMacroList
            || this.outletComponent !== NoopActionBarComponent;
    }

    get outletComponent(): any {
        return isValidRef(this.handler) && isValidRef(this.handler.getParameter().outlet)
            ? this.handler.getParameter().outlet
            : NoopActionBarComponent;
    }

    get showAudioRecorder(): boolean {
        return this.handler && this.handler.getParameter().audioRecorder;
    }

    get showAttachment(): boolean {
        return this.handler && this.handler.getParameter().attachment;
    }

    public getAudioRecorderHandler(): RecorderHandler {
        return this.handler.getParameter().audioRecorderHandler;
    }

    public getAttachmentHandler(): AttachBarHandler {
        return this.handler.getParameter().attachmentHandler;
    }

    public onTypedMessage(typedMessage: string): void {
        this.markForCheck();
        if(this.showSearchCitationParticipant && this.searchParticipant){
            this.searchParticipant.onTypedMessage(typedMessage);
            this.markForCheck();
            return;
        }
    }

    get showMacroList(): boolean {
        if (! this.handler) {
            return false;
        }

        return isValidRef(this.handler.getParameter().macros);
    }

    get showSearchCitationParticipant(): boolean {
        if (! this.handler) {
            return false;
        }
        const citation: ICitationHandlers = this.handler.getParameter().citation;
        return citation && citation.search
                ? true
                : false;
    }

    get chatSearchHandler(): IChatSearchParticipantHandler {
        return this.handler.getParameter().citation.search;
    }

    get showCitedParticipants(): boolean {
        if (! this.handler) {
            return false;
        }
        const citation: ICitationHandlers = this.handler.getParameter().citation;
        return citation && citation.listed.citedParticipantsList.length > 0;
    }

    get citedParticipantsListHandler(): ICitedParticipantsListHandler {
        return this.handler.getParameter().citation.listed;
    }

    getMacroHandler(): MacrosHandler {
        return (isValidRef(this.handler)) ? this.handler.getParameter().macros : undefined;
    }
}
