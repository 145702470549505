import { MultimediaInstance, TMultimediaInstanceArray, MMconstant, MultiMediaType, MultimediaObject } from '../multi-media/barrel-multimedia';
import { IUniversalJSON } from '../comm-interfaces/business-interfaces';
import { TGlobalUID, constant } from '../business/constant';
import { IMultimediaObjectJSON, IMultimediaInstanceJSON } from '../comm-interfaces/multi-media-interfaces';
import { isValidArray, isInvalidArray, isInvalid, isValidRef, isValidAndEqual, isEmptyObject, isInvalidString, isValidString, typedCloneLodash, isValidObject } from '../tools/utility';
import {I360Media, T360MediaArray} from '../core-constants/bot';
import { INonSerializable } from '../shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { Empty, Nullish } from '../tools/utility-types';
import { NamedNumber } from '@colmeia/core/src/core-constants/types';
import { IExternalDBConfig } from '../shared-business-rules/social-network/social-network-user-settings.model';


export function getMultimediaJSONFrom360(medias: T360MediaArray): IMultimediaObjectJSON {
    if (isInvalid360MediaArray(medias)) {
        return undefined;
    }

    const mo: MultimediaObject = MultimediaObject.getNewMultimediaObjectFrom360(medias, constant.objectType.interaction);

    for(const mi of mo.getAllMultimediaInstance()) {
        mi.setHashFileAsMediaID();
        mi.setFileNameAsMediaID();
    }

    return mo.toJSON();
}

export function isInvalid360MediaArray(medias: T360MediaArray): boolean {
    return isInvalidArray(medias) || medias.some((m) => {return ! isValidMedia(m)});
}

export function isValidMedia(media: Empty<I360Media>): media is I360Media {
    return isValidRef(media) && !isEmptyObject(media) && isValidRef(media.idMedia);
}

export function isAudioMimeType(type: string): type is `audio/${string}` {
    return type.startsWith('audio/')
}
export function matchesWithAudioMedia(media: I360Media): boolean {
    return isAudioMimeType(media.mymeType) || media.isVoiceMessage;
}

export function matchesWithVoiceMedia(media: I360Media): boolean {
    return media && media.isVoiceMessage || false;
}

export function hasVoiceMedia(arr: T360MediaArray): boolean {
    return isValidArray(arr) && arr.some(matchesWithVoiceMedia);
}

export function getFirstAudioMedia(arr: T360MediaArray): I360Media {
    const onlyAudio = arr.filter(matchesWithAudioMedia);
    return onlyAudio[0];
}

export function getBestIDMEdiaFromUniversal(universal: IUniversalJSON): TGlobalUID {
    if (universal) {
        return getBestMultimediaFromMultimediaObject(universal.multimediaObject, universal.idObjectType);
    } ;
    return null;
};

export function getBestMultimediaFromMultimediaObject(multimediaObject: IMultimediaObjectJSON, idObjectType: TGlobalUID): string {
    if (multimediaObject && isValidArray(multimediaObject.multiMediaInstanceArray)) {

        for (const thumb of multimediaObject.multiMediaInstanceArray.filter((i) => {return i.idMultiMediaTag == MMconstant.tag.thumbnail})) {
            const mInstance: IMultimediaInstanceJSON = multimediaObject.multiMediaInstanceArray.find((i) => {
                return i.multiMediaKey == thumb.idThumbnailParentKey
                    && i.idMultiMediaTag == MMconstant.tag.photo
                    && MultiMediaType.getMutimediaTypeFromMime(i.mimeType).isImage()
            });
            if (mInstance) {
                return thumb.idMedia;
            };
        };
        // chegou aqui, não tem thumb para a memsagem

        const mInstance: IMultimediaInstanceJSON = multimediaObject.multiMediaInstanceArray.find((i) => {
            return (i.idMultiMediaTag == MMconstant.tag.photo
                || i.idMultiMediaTag == MMconstant.tag.thumbnail)
                && MultiMediaType.getMutimediaTypeFromMime(i.mimeType).isImage();
        });

        if (mInstance) {
            return mInstance.idMedia;
        };
    };

    if (idObjectType === constant.objectType.group) {
        return MMconstant.profilePhoto.group;
    } else if  (idObjectType === constant.objectType.avatar) {
        return MMconstant.profilePhoto.avatar;
    }
    return null;

}

export function getFirstIdMediaFromMultimediaObject(multimediaObject: IMultimediaObjectJSON): TGlobalUID {
    let idMedia: TGlobalUID = '';
    if (multimediaObject && isValidArray(multimediaObject.multiMediaInstanceArray)) {
        for(const mmInstance of multimediaObject.multiMediaInstanceArray) {
            if(mmInstance.mimeType) {
                idMedia = getIdMediaOfVoiceAudio(mmInstance);
                break;
            }
        }
    }

    return idMedia;
}

export function getIdMediaFirstAudioFromMultimediaObject(multimediaObject: IMultimediaObjectJSON): TGlobalUID {
    let idMedia: TGlobalUID = '';
    if (multimediaObject && isValidArray(multimediaObject.multiMediaInstanceArray)) {
        for(const mmInstance of multimediaObject.multiMediaInstanceArray) {
            if (MultiMediaType.getMutimediaTypeFromMime(mmInstance.mimeType).isAudio()) {
                idMedia = getIdMediaOfVoiceAudio(mmInstance);
                break;
            }
        }
    }

    return idMedia;
}

export function getMM360FromIDMedia(idMedia: string, mimeType: string, size: number=0): I360Media {
    const multimediaType: MultiMediaType = MultiMediaType.getMutimediaTypeFromMime(mimeType, true);
    return {
        idMedia: idMedia,
        idMediaType: multimediaType ? multimediaType.getPrimaryID(): null,
        mymeType: mimeType,
        size: size
    };
}



export function getPrioritize360Media(multimediaObject: IMultimediaObjectJSON): I360Media | null {
    let idMedia: TGlobalUID = getIdMediaFirstAudioFromMultimediaObject(multimediaObject);
    if (isInvalidString(idMedia)) {
        idMedia = getFirstIdMediaFromMultimediaObject(multimediaObject);
    }
    if (isValidString(idMedia)) {
        const mminstance: IMultimediaInstanceJSON = multimediaObject.multiMediaInstanceArray.find((f) => {return f.idMedia === idMedia})
        return getI360MediaFromMMInstance(mminstance);
    }
    return null;
}

export function getPrioritizedMedia(multimediaObject: IMultimediaObjectJSON): TGlobalUID {
    let idMedia: TGlobalUID = getIdMediaFirstAudioFromMultimediaObject(multimediaObject);
    if (isInvalidString(idMedia)) {
        idMedia = getFirstIdMediaFromMultimediaObject(multimediaObject);
    }
    return idMedia;
}

export function getSafeI360MediaFromMMInstance(mminstance: IMultimediaInstanceJSON) {
    mminstance = typedCloneLodash(mminstance);
    addIdMediaNormalization(mminstance)
    return getI360MediaFromMMInstance(mminstance);
}

export function addIdMediaNormalization(mminstance: IMultimediaInstanceJSON) {
    mminstance.idMedia = getIdMediaOfVoiceAudio(mminstance);
    return mminstance;
}

export function getI360MediaFromMMInstance(mminstance: IMultimediaInstanceJSON): I360Media {
    return {
        idMedia: mminstance.idMedia,
        idMediaType: MultiMediaType.gertMultimediaIDFromMime(mminstance.mimeType, true),
        mymeType: mminstance.mimeType,
        size: mminstance.fileSize,
        displayMediaName: mminstance.fileName,
    }
}



export function getIdMediaOfVoiceAudio(mmInstance: IMultimediaInstanceJSON): string {
    return (mmInstance.idMultiMediaTag === MMconstant.tag.voiceMessage)
        ? mmInstance.idMedia += MMconstant.mp3suffix
        : mmInstance.idMedia;
}


export function sameMultimediaInstance(mm1:  MultimediaInstance, mm2: MultimediaInstance): boolean {
    return mm1.iss(mm2) || isValidAndEqual(mm1.getIdMedia() ,mm2.getIdMedia())
}



export function addToMultimediaInstanceArray(mm:  MultimediaInstance, array: TMultimediaInstanceArray): void {
    const idx: number = array.findIndex((m) => {return sameMultimediaInstance(mm,m)});
    if (idx > -1) {
        array[idx] = mm;
    } else {
        array.push(mm);
    };
};


export namespace Sizes {
    export type MB = NamedNumber<'MB'>;
    export type Bytes = NamedNumber<'Bytes'>;
}

export function mbToBytes(mb: Sizes.MB): Sizes.Bytes {
    return mb * (2 ** 20);
}

export function deleteMultimediaInstance(instance:  MultimediaInstance, array: TMultimediaInstanceArray): void {
    if (isInvalidArray(array)) {
        return;
    }
    let idx: number = array.findIndex((mm) => {return mm.getMultmediaKey() === instance.getMultmediaKey()});
    if (idx > -1) {
        array.splice(idx, 1);
    };

    idx = array.findIndex((mm) => {return mm.getTag().is(MMconstant.tag.thumbnail) && instance.getMultmediaKey() === mm.getThumbnailParentKey()});

    if (idx > -1) {
        array.splice(idx, 1);
    };
}


export function getMergedMMObjectIntoInstance(multimediaObject: MultimediaObject, array: TMultimediaInstanceArray): TMultimediaInstanceArray {
    if (isInvalid(array) && isValidRef(multimediaObject)) {
        return multimediaObject.getAllMultimediaInstance();
    }

    if (isInvalidArray(array) && isValidRef(multimediaObject)) {
        array.push(...multimediaObject.getAllMultimediaInstance());
        return array;
    }

    if (isValidRef(multimediaObject)) {
        multimediaObject.getAllMultimediaInstance().forEach((mm) => {
            const instance: MultimediaInstance = array.find((mmo) => {return sameMultimediaInstance(mm, mmo)});
            if (! instance) {
                array.push(mm);
            };
        });
    }

    return array;
}

export function getBestIdMediaFromNS(nonSerializable: INonSerializable): string {
    return getMediaIDFromT360(nonSerializable.medias)
}

export function getMediaIDFromT360(medias: T360MediaArray): string {
    if (isInvalidArray(medias)) {
        return undefined
    } else {
        const mm = medias
            .filter(m => isValidRef(m))
            .find((m)=> isValidRef(m.idMedia) && !m.isThumb);
        return isValidRef(mm) ? mm.idMedia : undefined;
    }
}

export function getBestMediaIDFromNS(nonSerializable: INonSerializable): string {
    const media = getBestMediaFromNS(nonSerializable);
    return isValidRef(media)
        ? media.idMedia
        : undefined;
}

export function getBestMediaFromNS(nonSerializable: INonSerializable): I360Media {
    return isValidArray(nonSerializable.medias) ? nonSerializable.medias[0] : undefined;
}

export function getBestThumbFromNS(nonSerializable: INonSerializable): I360Media | undefined {
    return isValidArray(nonSerializable.medias) ? nonSerializable.medias?.find(media => media.isThumb) : undefined;
}


export function hasMMExternalBucket(config: IExternalDBConfig | undefined): boolean {
    return isValidObject(config) && config!.useMultimediaBucket && isValidString(config!.multimediaBucketName)
}

export function getExternalMediaBucketName(config: IExternalDBConfig | undefined): string | Nullish {
    return hasMMExternalBucket(config) ? config!.multimediaBucketName : null

}

export function hasBlobExternalBucket(config: IExternalDBConfig | undefined): boolean {
    return isValidObject(config) && config!.useExternalBlobAPI && isValidString(config!.externalBlobAPIBucketName)
}

export function getBlobBucketName(config: IExternalDBConfig | undefined): string | Nullish {
    return hasBlobExternalBucket(config) ? config!.externalBlobAPIBucketName : null

}

