import { Days } from "@colmeia/core/src/time/time-utl";
import { IBPMValidatorActionOnError } from "../BPM/bpm-action-model";
import { ECampaignExecutionType } from "../campaigns/capaing-exec-interfaces";
import { ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";
import { IBotActionAsset } from "./bot-action-model";
import { IExecutableAction } from "./bot-asset-model";
import { TContentAssetArray } from "./bot-content-model";
import { IBotCondition } from "./bot-interfaces";

export enum EBotEventType {

    desambiguation = "desambiguation",
    onInvalidOption = 'actType.onInvalidOpt',
    customerExpire = 'actType.custExp', // cliente não responde
    providerExpire = 'actType.provExp', // atendente não responde

    commandText = 'actType.commandText', // texto de comando

    exitCommand = 'evt.exitCommand',

    preCondition = 'actType.preCondition',


    defaultReturnOption = 'actType.defRet',

    onHumanAssignation = 'actType.humanAssig', // está sendo usado?
    onIdentificationClient = 'actType.onIdentifClient', // está sendo usado?

    onError = 'actType.onError',

    onFinish = 'actType.onFinish',


    missingInformation = 'actType.missingInformation',

    addressSearch = 'ev.addrsearch',  // vamos deprecar
    accuracyEvent = 'ev.accuracy',

    onConfirmation = 'act.Confirmation', // transaction (form e nlp)
    reuseFormInformation = 'act.reuseFormInformation', // form 
    useFormDataToFieldReuse = 'act.useFormDataToFieldReuse', // Utiliza os dados do formulário anteriormente preenchido no reuso de campos
    isUsefullInsight = 'act.usefullInsight',   // transaction (form e nlp)

    // onConnection = 'ev.connection', 

    //tipos de configurableConfirmantion interno de eventos

    insuficientAccuracy = 'ignored',  // insuficient vola
    inconclusiveAccuracy = 'confirm',  // 
    conclusiveAccuracy = 'success',

    // IslandEvents

    onQueueReturn = 'onQueueReturn', // retornoParaFila
    onQueueTimeRestriction = 'onQueueTimeRestriction',
    onQueueAdd = 'onQueueAdd',
    onQueueNext = 'onQueueNext',
    onQueueOnBegin = 'onQueueOnBegin',
    onQueueOnFinish = 'onQueueOnFinish',
    onCustomerGaveUp = 'onCustomerGaveUp',
    onQueueTransfer = 'onQueueTransfer',
    onCreatingCredentials = 'onCreatingCredentials',
    onAttendentAbsent = 'onAttendentAbsent', // Quando o atendente está ausente

    onChannelClosedOnAgentChat = 'onClosedChannel',

    onRtfInjectionParameter = 'onRtfInjectParam',

    onMaximumCustomerQueueReach = 'onMaxCusQueue',
    onAgentNotAvailable = 'onAgtNotAvail',

    // help
    onHelp = 'onHelp',

    // Anomalias
    onTooManuCustomerReturn = 'onTooManuCustomerReturn',
    onTooManyFormTries = 'onTooManyFormReturn',
    onTooManyFieldTries = 'onTooManyFieldTries',
    onTooManyMenuTries = 'onTooManyMenuTries',
}

export type TQueueEventSubtype = EBotEventType.onQueueReturn | EBotEventType.onQueueTimeRestriction | EBotEventType.onQueueAdd |
    EBotEventType.onQueueNext | EBotEventType.onQueueOnBegin | EBotEventType.onQueueOnFinish | EBotEventType.onCustomerGaveUp |
    EBotEventType.onQueueTransfer | EBotEventType.onCreatingCredentials | EBotEventType.onAttendentAbsent
    | EBotEventType.onChannelClosedOnAgentChat | EBotEventType.onAgentNotAvailable | EBotEventType.onMaximumCustomerQueueReach


export type TAnomalyEvents = EBotEventType.onTooManuCustomerReturn |
    EBotEventType.onTooManyFieldTries |
    EBotEventType.onTooManyFormTries |
    EBotEventType.onTooManyMenuTries;


// onConnection
// noRoot


export type EBotConfirmationEvent = EBotEventType.isUsefullInsight | EBotEventType.reuseFormInformation | EBotEventType.onConfirmation | EBotEventType.accuracyEvent | EBotEventType.useFormDataToFieldReuse

export type TBotEventTypeArray = Array<EBotEventType>;

export type TBotEventArray = Array<IBotEvent>;


export interface IBotEventExecuteCampaign {
    /**
     * Pode ser o uma ação de campanha ou régua de comunicação
     */
    idElement: string;
    type: ECampaignExecutionType;
}

// TBotActionTypeConfig
export interface IBotEvent extends IExecutableAction {
    type: EBotEventType;
    eventText: TContentAssetArray;
    idCalendar?: IdDep<ENonSerializableObjectType.attendanceCalendar>;
    eventAction?: IBotActionAsset;
    preCondition?: IBotCondition;
    camp?: IBotEventExecuteCampaign;
    /**
     * Para funções que devem ser executadas ANTES do evento
     */
    idFunctionBefore?: IdDep<ENonSerializableObjectType.userFunction>;
    /**
     * Para funções que devem ser executadas DEPOIS do evento
     */
    idFunction?: IdDep<ENonSerializableObjectType.userFunction>;
};

export enum ETypeOfError {
    colmeia, // Quando houver um erro inesperado na plataforma
    userDefined, // Quando uma User Function retornar códigos de erros
    userGenerated, // Quando uma User Function não estiver funcionando corretamente
}

export interface IBotEventError extends IBotEvent {
    errorType: ETypeOfError;
    idCampaignAction: string;
}


export interface IBasicConfirmationEvent extends IBotEvent {
    action?: IBotActionAsset;  // no transaction quando é acurácia insuficiente. 
    yesAction: IBotActionAsset;
    noAction: IBotActionAsset;
    warning: IBotActionAsset;
}

//Apenas para Inconclusivo
export interface IConfigurableConfirmationEvent extends IBasicConfirmationEvent {
    isConfirm: boolean; // Usuário quer utilizar mecanismo de confirmação simples
    isDisambiguation: boolean // usuário quer mostrar as intenções mais relevantes
    disambiguationTitle: TContentAssetArray;
    firstNIntents: number; // Número Máximo de Intents (limita em 8)
    isShowProbability?: boolean; // mostra os percentuais ao usuário final

    //alreadyHaveText: IContentBasicAsset; usar o eventText para oque era alreadyhavetext
    // o titulo era already e so mostra em caso de confirm
}



export interface ITransactionToTransactionConfirmation extends IConfigurableConfirmationEvent {
    // eventText = question
    type: EBotEventType.onConfirmation;
}

export interface IReuseFormDataOnFieldReuse extends IBotEvent {
    type: EBotEventType.useFormDataToFieldReuse;
    reuseIfSameChannelAddress?: boolean; // Reusar apenas se o canal de contato (Business) for o mesmo
    hours: number;
};


export interface IReuseFormEvent extends IConfigurableConfirmationEvent {
    type: EBotEventType.reuseFormInformation;
    reuseIfSameChannelAddress?: boolean; // Reusar apenas se o canal de contato (Business) for o mesmo
    hours: number;
};

export interface IIsHelpfullAIInsight extends IBasicConfirmationEvent {
    type: EBotEventType.isUsefullInsight;
    firePercentage: number
}

export interface IKBIntentName {
    intentID: string;
    intentName: string;
    intentFriendlyName: string;
}

export interface IInvalidOption extends IBotEvent {
    // Tem contentAsset aqui? Não
    useActionOnErrors?: boolean;
    actionOnErrors?: IBPMValidatorActionOnError;
}

export interface IExpireEvent extends IBotEvent {
    attempts?: number;
    expireInMinutes?: number;

}

export interface IAccuracyData extends IBotEvent {
    type: EBotEventType.accuracyEvent,

    insuficientAccuracyUntil: number;
    inconclusiveAccuracyUntil: number;


    onConclusiveAccuracy: IBasicConfirmationEvent;
    onInsuficientAccuracy: IBasicConfirmationEvent;
    onInconclusiveAccuracy: IConfigurableConfirmationEvent;

    intentNames: {
        [intentID: string]: string;
    }

}



export interface IAgentDoesNotAnwer extends IExpireEvent {
    /**
     * Apenas para primeira checagem
     */
    onlyFistAnswer?: boolean;
    /**
     * Penalidade aplicada para o caso devolvido a fila.
     */
    agentPenaltyMS?: number;
}

export interface ICustomerDoesNotAnswer extends IExpireEvent { // BOT
    idOpenChannelCampaignAction: string; // FE só no expire do BOT
    sendExpireMessagesInOrder: boolean;
    // eventText = goodByMessage
};

export interface IBotDesambiguation extends IBotEvent {
    // eventText aqui é o texto de desambiguacao
}

export interface IBotCommand extends IBotEvent {
    commandText: string

}

export interface IBotCommandText extends IBotCommand {
    type: EBotEventType.commandText;
}

// Não existirá ação aqui.. apenas o sistema irá 
export interface IBotCommandHelp extends IBotCommand {
    type: EBotEventType.onHelp;
}




/// ISLAND EVENTs

export interface IOnDIspatchToIsland extends IBotEvent {

}

export interface IOnChannelCloseOnService extends IBotEvent { // ILHA
    idOpenChannelCampaignAction: string
}

/**
 * Provavelmetne descontinuado
 */
export interface ISmartFlowTRFInjection {
    type: EBotEventType.onRtfInjectionParameter,
    smartFlowRTFHeaderId: string
}


