import { Injectable, InjectionToken } from '@angular/core';
import { ICRMTicketDataWithCustomerName, TTicketInteraction } from '@colmeia/core/src/shared-business-rules/crm/crm-entities';
import { ETicketNotificationContentType, TCRMTicketNotificationContent } from '@colmeia/core/src/shared-business-rules/crm/crm-notification.model';
import { ETicketInteractionType } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-model-bpm-exec';
import { ENotificationPayload } from '@colmeia/core/src/shared-business-rules/new-notifications/new-notification-model';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { NewNotificationsService } from 'app/services/new-notifications.service';
import { RoutingService } from 'app/services/routing.service';
import { Observable, Subject } from 'rxjs';
import { CRMTicketsDialogService } from './crm-tickets-dialog.service';
import { CRMTicketRouterService } from './crm-ticket-router.service';

export interface ITicketNoficiationInteractionStreamData {
    ticketData: ICRMTicketDataWithCustomerName;
    interaction: TTicketInteraction;
}

@Injectable({
    providedIn: 'root'
})
export class CRMTicketNotificationService {

    #$newTicketsStream: Subject<ICRMTicketDataWithCustomerName> = new Subject();
    get $newTicketsStream(): Observable<ICRMTicketDataWithCustomerName> {
        return this.#$newTicketsStream.asObservable();
    }

    #$newTicketsInteractionStream: Subject<ITicketNoficiationInteractionStreamData> = new Subject();
    get $newTicketsInteractionStream(): Observable<ITicketNoficiationInteractionStreamData> {
        return this.#$newTicketsInteractionStream.asObservable();
    }

    constructor(
        private routeSvc: RoutingService,
        private notificationSvc: NewNotificationsService,
        private ticketDialogSvc: CRMTicketsDialogService,
        private ticketRouterSvc: CRMTicketRouterService,
    ) {
        this.notificationSvc.notifications$.subscribe((notification) => {
            if (notification.type === ENotificationPayload.crmTicket) {
                this.handleTicketNotification(notification.content);
            }
        })
    }


    async handleTicketNotification(content: TCRMTicketNotificationContent) {
        switch (content.type) {
            case ETicketNotificationContentType.interaction:
                this.handleTicketInteractionFromSocket(content)
        }
    }

    private handleTicketInteractionFromSocket(content: TCRMTicketNotificationContent) {
        switch (content.interaction.interactionType) {
            case ETicketInteractionType.allocation:
                this.#$newTicketsStream.next(content.ticket);
                break;
            case ETicketInteractionType.content:
            case ETicketInteractionType.formAnswer:
            case ETicketInteractionType.status:
                this.#$newTicketsInteractionStream.next({
                    ticketData: content.ticket,
                    interaction: content.interaction,
                });
                break;
        }
    }

    async handleNotificationClick(content: TCRMTicketNotificationContent) {
        const isWindowMode = this.ticketDialogSvc.restoreWindow();
        const isInTicketPage = document.URL.includes(routeList.dashboard.children.serviceStatus.children.tickets.path);

        if (isWindowMode || isInTicketPage) {
            this.ticketDialogSvc.openTicketWindow(content.ticket);
            return;
        }

        this.ticketRouterSvc.navigateToTicket(content.ticket);
    }

}
