import { ICRMRootServer } from '../../../crm/crm-services/crms-model-bpm-def';
import { ENonSerializableObjectType } from '../../../non-serializable-id/non-serializable-id-interfaces';
import { TReferencedObject } from '../../toolbar/config-toolbar.types';
import { CrmHostNode } from './crm-host-node';

export class CrmRootHost extends CrmHostNode {
    constructor(crmRoot: ICRMRootServer) {
        super(crmRoot)
    }

    static create(crmRoot: ICRMRootServer): CrmRootHost {
        return new CrmRootHost(crmRoot)
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        return nodeTypeToDrag === ENonSerializableObjectType.crmServiceConfigAgentEvent
            || nodeTypeToDrag === ENonSerializableObjectType.crmServiceConfigProcessingNode
            || nodeTypeToDrag === ENonSerializableObjectType.crmServiceConfigNotification
    }

    public isParenthoodPredicateDrawable(target: TReferencedObject): boolean {
        return target === ENonSerializableObjectType.crmServiceConfigAgentEvent
    }
}
