<cm-generic-dashboard-edit *ngIf="genericDashboardEdit; else spinner" [handler]="genericDashboardEdit">

  <div class="waba__container">
    <div class="white-box inputs__wrapper">
      <h4>Dados da WABA</h4>

      <mat-form-field appearance="fill" class="full-width-field">
        <mat-label>Nome da Waba</mat-label>
        <input matInput [(ngModel)]="wabaName">
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width-field">
        <mat-label>WhatsApp Business Account ID</mat-label>
        <input matInput [(ngModel)]="wabaId">
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width-field">
        <mat-label>Token</mat-label>
        <input matInput [(ngModel)]="token">
      </mat-form-field>

      <!-- <mat-form-field appearance="fill" class="full-width-field">
        <mat-label>Recado</mat-label>
        <input
          matInput
          [(ngModel)]="note"
        >
      </mat-form-field> -->
    </div>

    <div class="white-box inputs__wrapper">
      <h4>Compartilhar WABA</h4>

      <mat-form-field appearance="fill" class="full-width-field">
        <mat-label>Selecione um Parceiro</mat-label>
        <mat-select [(ngModel)]="provider" (selectionChange)="onPartnerChange($event)">
          <mat-select-trigger class="provider-content">
            <img *ngIf="provider" [src]="getSocialMediaProvider(provider)?.icon" class="trigger-img" />
            {{provider ? getSocialMediaProvider(provider)?.title : 'Selecione' }}
          </mat-select-trigger>
          <mat-option [value]="undefined">Nenhum</mat-option>
          <mat-option *ngFor="let item of availableProviders" [value]="item.enum">
            <span class="provider-content">
              <img [src]="item.icon" [alt]="item.title" />
              <p>
                {{ item.title }}
              </p>
            </span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button class="meta-button-login-sdk" [class.disabled-btn]="isEditing"
        [disabled]="!isEmbededSignUpButtonEnabled || isEditing" (click)="launchWhatsAppEmbeddedSignup()">
        <span>Entrar com Facebook</span>
      </button>

    </div>

    <div class="white-box inputs__wrapper" *ngIf="isAbleToManageCreditLine()">
      <h4>Linha de Crédito</h4>

      <p *ngIf="canAttachCreditLine();">A Linha de crédito não está vinculada!</p>
      <p *ngIf="canRevokeCreditLine();">A Linha de crédito está vinculada!</p>

      <ng-container >
        <mat-form-field appearance="fill" class="full-width-field">
          <mat-label>Id da Linha de Crédito</mat-label>
          <input matInput readonly [(ngModel)]="creditLineAllocationId" />
        </mat-form-field>
      </ng-container>

      <icon-button class="button__item" icon="link" color="primary" text="Vincular" *ngIf="canAttachCreditLine()" (click)="confirmAttachCreditLine()"/>
      <icon-button class="button__item" icon="link_off" color="red" text="Revogar" *ngIf="canRevokeCreditLine()" (click)="confirmRevokeCreditLine()"/>
    </div>

    <div class="white-box inputs__wrapper" *ngIf="isAbleToRegisterWebhook()">
      <h4>WebHook</h4>
      <ng-container >
        <mat-form-field appearance="fill" class="full-width-field">
          <mat-label>WebHook URL</mat-label>
          <input matInput [(ngModel)]="webhookURL" />
        </mat-form-field>
      </ng-container>

      <icon-button class="button__item" text="Preencher webhook com o domínio atual"
        (click)="insertDomainOnMetaWebhookUrl()"></icon-button>
    </div>

  </div>

</cm-generic-dashboard-edit>


<ng-template #spinner>
  <app-dashboard-spinner></app-dashboard-spinner>
</ng-template>
