import { EDelivery360Action } from "../../comm-interfaces/barrel-comm-interfaces";
import { mbToByte } from "../../tools/utility";

interface IDelivery360Config {
  hasVoiceSupport: boolean;
  maxFileBytesLength: number;
}

type TDelivery360ConfigDB = {
  [key in EDelivery360Action]: IDelivery360Config
}

export const delivery360ConfigDB: TDelivery360ConfigDB = {
  [EDelivery360Action.Delivery360Email]: {
    hasVoiceSupport: false,
    maxFileBytesLength: -1,
  },
  [EDelivery360Action.Delivery360FBMessenger]: {
    hasVoiceSupport: false,
    maxFileBytesLength: -1,
  },
  [EDelivery360Action.Delivery360SMS]: {
    hasVoiceSupport: false,
    maxFileBytesLength: mbToByte(5),
  },
  [EDelivery360Action.Delivery360Voice]: {
    hasVoiceSupport: true,
    maxFileBytesLength: 0,
  },
  [EDelivery360Action.Delivery360WhatsApp]: {
    hasVoiceSupport: true,
    maxFileBytesLength: mbToByte(16),
  },
  [EDelivery360Action.DeliveryColmeia]: {
    hasVoiceSupport: true,
    maxFileBytesLength: mbToByte(100),
  },
  [EDelivery360Action.DeliveryInstagram]: {
    hasVoiceSupport: false,
    maxFileBytesLength: -1,
  },
  [EDelivery360Action.DeliveryTelegram]: {
    hasVoiceSupport: true,
    maxFileBytesLength: mbToByte(100),
  },
}
