import { TGlobalUID } from "../../business/constant";
import { Miliseconds } from "../../time/time-utl";
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";


export enum EYouTaskRequestType {
    solicitationResponse = 'solicitationResponse',
    solicitationCreate = 'solicitationCreate'
}

export enum EYouTasksType {
    groupParticiparionApproval = 'youGrpApp',
    socialNetworkParticipationApproval = 'snPartAppr',
    formSolicitation = 'formSolic'
}

export enum ESolicitationDestination {
    AllFromGroup = 'allGrp',
    AllAdminFromGroup = 'allGrpAdm',
    IslandRandom = 'isldRndm',
    ToAvatar = 'avtr'
}

export enum EYouTaskStatus {
    open = 'yOpen',
    done = 'yDone',
    rejected = 'yRejec',
    forwarded = 'yForw',
    processing = 'yPrcss',
}

/**
 *******************************
 *
 * Base interfaces
 *
 *******************************
 */

/**
 * Tipo da solicitação.
 * Descrição fornecida pelo usuário
 */
export interface IYouTaskDetails {
    taskType: EYouTasksType;
    description?: string;
};

export interface IYouTaskDestinationSettings {
    destType: ESolicitationDestination;
    destId: string;
    agentOwner: boolean;
}

export type TYouTaskDetailsTypes = IYouTaskDetailsGroupApproval | IYouTaskDetailFormSoliciation;

/**
 * Informações do usuário que gerou a solicitação
 *  - Participação em grupo.
 *  - Formulário respondido.
 */
export interface IYouUserInput {
    idAvatar: string;
    description: string;
    clockTick: number;
}

export type TIUserInputArray = Array<IYouUserInput>

export interface IYouRecipient {
    /**
     * Id do usuário que vai receber a solicitação.
     */
    idAvatar: string;
}
export type TIYouRecipientArray = Array<IYouRecipient>;

export interface IYouTasksResolutionInfo {
    idAvatar: TGlobalUID;
    clockTick: number;
}

export interface IYouTasksClientBasic {
    alertCT: Miliseconds; // ms
    details: TYouTaskDetailsTypes; // solicitar

    solicitationStatus: EYouTaskStatus;
    isOpen: boolean; // EYouTaskStatus.open
    inputs: IYouUserInput[]; // metadata from receives
    /**
     * Fulfilled by backend
     */
    recipients: TIYouRecipientArray; // receive // probably = back end
    idForwaredFrom?: number;  // deixar para depois
    resolutionInfo?: IYouTasksResolutionInfo;
}
export interface IYouTasksClient extends IYouTasksClientBasic, INonSerializableHeader {
    nsType: ENonSerializableObjectType.yourTasks;
}

export interface IYouTasksServer extends IYouTasksClient, INonSerializable {
    nsType: ENonSerializableObjectType.yourTasks;
}
/**
 *******************************
 *
 * Interfaces de solicitação de participação em Rede social
 *
 *******************************
 */

export interface IYouTaskDetailsSocialNetwork extends IYouTaskDetails {
    taskType: EYouTasksType.socialNetworkParticipationApproval;
    /**
     * Id da Rede Social que o usuário solicitou participar.
     */
    idGroup: TGlobalUID;
    idSocialNetwork: TGlobalUID;

    // Campos preenchidos no servidor
    groupName?: string;
    solicitantAvatarName?: string;
}

export interface IYouUserInputForSNAppr extends IYouUserInput {
    userProfilesList: TGlobalUID[]
}

export interface IYouTaskSocialNetworkClientBasic extends IYouTasksClientBasic {
    details: IYouTaskDetailsSocialNetwork; // solicitar
    inputs: IYouUserInputForSNAppr[]; // metadata from receives
}

export interface IYouTaskSocialNetworkClient extends IYouTasksClient, IYouTaskSocialNetworkClientBasic {
    details: IYouTaskDetailsSocialNetwork; // solicitar
    inputs: IYouUserInputForSNAppr[]; // metadata from receives
}

export interface IYouTaskSocialNetworkServer extends IYouTaskSocialNetworkClient, IYouTasksServer {
    details: IYouTaskDetailsSocialNetwork;
    inputs: IYouUserInputForSNAppr[]; // metadata from receives
}





/**
 *******************************
 *
 * Interfaces de solicitação de participação em grupo
 *
 *******************************
 */

export interface IYouTaskDetailsGroupApproval extends IYouTaskDetails {
    taskType: EYouTasksType.groupParticiparionApproval | EYouTasksType.socialNetworkParticipationApproval;
    /**
     * Id do grupo que o usuário solicitou participar.
     */
    idGroup: TGlobalUID;
    /**
     * rede social atual que o usuario esta ao pedir a solicitacao
     */
    idSocialNetwork: TGlobalUID;
    /**
     * rede social target que o usuario pede participacao
     */
    targetIdSocialNetwork?: TGlobalUID;

    // Campos preenchidos no servidor
    groupName?: string;
    solicitantAvatarName?: string;
}

export interface IYouTaskGroupApprovalClientBasic extends IYouTasksClientBasic {
    details: IYouTaskDetailsGroupApproval; // solicitar
}

export interface IYouTaskGroupApprovalClient extends IYouTasksClient, IYouTaskGroupApprovalClientBasic {
    details: IYouTaskDetailsGroupApproval; // solicitar
}

export interface IYouTaskGroupApprovalServer extends IYouTaskGroupApprovalClient, IYouTasksServer {
    details: IYouTaskDetailsGroupApproval;
}

// Tipos utilitários, não fazem parte do modelo.
export type TSolicitationGroupIdToTask = Record<TGlobalUID, IYouTasksServer>


/**
 *******************************
 *
 * Interfaces de solicitação de resposta de formulário
 *
 *******************************
 */

export interface IYouTaskDetailFormSoliciation extends IYouTaskDetails {
    taskType: EYouTasksType.formSolicitation;
    idAnswer: IdDep<ENonSerializableObjectType.clientGeneralAnswer>;
    solicitantName?: string;
    solicitantTarget?: string;
}

export interface IYouTaskFormSolicitationClientBasic extends IYouTasksClientBasic {
    details: IYouTaskDetailFormSoliciation; // solicitar
}

export interface IYouTaskFormSolicitationClient extends IYouTasksClient, IYouTaskFormSolicitationClientBasic {
    details: IYouTaskDetailFormSoliciation; // solicitar
}

export interface IYouTaskFormSolicitationServer extends IYouTaskFormSolicitationClient, IYouTasksServer {
    details: IYouTaskDetailFormSoliciation;
}

/**
 * Agrupamento da modelagem
 */
export type TAllYouTasksClient = IYouTaskGroupApprovalClient | IYouTaskFormSolicitationClient | IYouTaskSocialNetworkClient;

export type TAllYouTasksServer = IYouTaskGroupApprovalServer | IYouTaskFormSolicitationServer | IYouTaskSocialNetworkServer;

export type TAllYouTasksNser = TAllYouTasksClient | TAllYouTasksServer;
