import { Injectable } from '@angular/core';
import { apiRequestType, EFacebookChannelIntegrationReqRes, EWABARequest } from '@colmeia/core/src/request-interfaces/message-types';
import { IRequest } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { IMetaWebhookRegisterRequest, IMetaWebhookRegisterResponse } from '@colmeia/core/src/shared-business-rules/channels/facebook-integration-req-resp.dto';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IWhatsappBusinessAccountClient, IWhatsappBusinessAccountServer } from '@colmeia/core/src/shared-business-rules/waba/waba.model';
import { IAttachWabaCreditLineRequest, IAttachWabaCreditLineResponse, IRevokeWabaCreditLineRequest, IRevokeWabaCreditLineResponse, IWabaCreditLineStatusRequest, IWabaCreditLineStatusResponse, IWhatsappBusinessAccountRequest, IWhatsappBusinessAccountResponse } from '@colmeia/core/src/shared-business-rules/whatsapp-business-account/whatsapp-business-account.req-resp.dto';
import { IGenericDashboardRequestParameters } from 'app/components/dashboard/dashboard-foundation/generic-dashboard-home/generic-dashboard-home.model';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { GenericDashboardRequester } from './generic-dashboard-requester.service';
import { RoutingService } from './routing.service';
import { ServerCommunicationService } from './server-communication.service';
import {  IGetScheduledCampaignsByTemplateIdNSRequest, IGetScheduledCampaignsByTemplateIdNSResponse, IRemoveScheduledCampaignsByTemplateIdNSRequest, IRemoveScheduledCampaignsByTemplateIdNSResponse, ITempalteUnpauseRequest, ITempalteUnpauseResponse, IWabaWithChannelResponse } from '@colmeia/core/src/shared-business-rules/social-media/template-req-resp';
import { IMetaIWhatsAppSocialMediaConnectionServer } from '@colmeia/core/src/shared-business-rules/social-media/social-media.model';
import { IdDep, IdNS } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';

const baseRoute = routeList.dashboard.children.communication.children.waba;


@Injectable({
    providedIn: 'root'
})
export class DashboardWabaService {

    constructor(
        private api: ServerCommunicationService,
        private requester: GenericDashboardRequester,
        private routeSvc: RoutingService,
    ) { }


    getWabaListRequest(parameters: IGenericDashboardRequestParameters): IRequest {
        return this.requester.getRequestForNS({
            //@ts-ignore
            apiRequestType: apiRequestType.nonSerializable.list,
            //@ts-ignore
            cursor: null,
            //@ts-ignore
            nsType: ENonSerializableObjectType.waba,
            //@ts-ignore
            taggable: parameters.taggable
        })
    }

    getEmptyWaba(): IWhatsappBusinessAccountClient {
        const waba: IWhatsappBusinessAccountClient = {
            wabaId: '',
            token: '',
            webhookURL: '',
            nsType: ENonSerializableObjectType.waba,
            nName: '',
            //@ts-ignore
            ident: null,
        }
        return waba;
    }

    goToWabaEdit(waba: IWhatsappBusinessAccountServer) {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.communication.path,
            baseRoute.path,
            baseRoute.children.edit.path.replace(baseRoute.children.edit.routeParam, waba.idNS!),
        )
    }

    goToWabaCreate() {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.communication.path,
            baseRoute.path,
            baseRoute.children.create,
        )
    }

    goToWabaList() {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.communication.path,
            baseRoute.path,
            baseRoute.children.list,
        )
    }

    goToChannelWithTemplateList(ns: IMetaIWhatsAppSocialMediaConnectionServer) {
        const baseWabaQuality = routeList.dashboard.children.marketing.children.wabaQuality
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.marketing.path,
            baseWabaQuality.path,
            baseWabaQuality.children.channelWhitTemplate.path,
            ns.idNS!,
        )
    }

    goToWabaNotificatiosList(wabaIdNS: IdNS<IWhatsappBusinessAccountServer>) {
        const baseWabaQuality = routeList.dashboard.children.marketing.children.wabaQuality
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.marketing.path,
            baseWabaQuality.path,
            baseWabaQuality.children.wabaNotifications.path,
            wabaIdNS
        )
    }

    goToWabaQualityList() {
        const baseWabaQuality = routeList.dashboard.children.marketing.children.wabaQuality
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.marketing.path,
            baseWabaQuality.path
        )
    }
    
    async saveWaba(ns: IWhatsappBusinessAccountClient | IWhatsappBusinessAccountServer) {
        try {
            const res = this.api.sendRequest<IWhatsappBusinessAccountRequest, IWhatsappBusinessAccountResponse>(EWABARequest.saveWABA)({ ns });
            return res

        } catch (error) {
            console.error(error)
        }
    }

    async registerWebhookAndGetURL(ns: IWhatsappBusinessAccountClient | IWhatsappBusinessAccountServer): Promise<IMetaWebhookRegisterResponse> {
        const res = await this.api.sendRequest<IMetaWebhookRegisterRequest, IMetaWebhookRegisterResponse>(EFacebookChannelIntegrationReqRes.registerWebhookURL
        )({
            data: {
                sharedWABAID: ns.wabaId,
                accessToken: ns.token
            }
        });
        console.log({ res })
        return res!;
    }

    async getWABACreditLineStatus(ns: IWhatsappBusinessAccountClient) {
        const res = await this.api.sendRequest<IWabaCreditLineStatusRequest, IWabaCreditLineStatusResponse>(
            EWABARequest.getCreditLineStatus
        )({
            data: {
                creditLineId: ns.creditLineAllocationId,
            }
        });

        return res;
    }

    async attachWABACreditLine(ns: IWhatsappBusinessAccountClient, idNs: IdDep<ENonSerializableObjectType.waba>) {
        const res = await this.api.sendRequest<IAttachWabaCreditLineRequest, IAttachWabaCreditLineResponse>(
            EWABARequest.extendCreditLine
        )({
            data: {
                wabaId: ns.wabaId,
                idNs: idNs,
            }
        });

        return res;
    }

    async revokeWABACreditLine(ns: IWhatsappBusinessAccountClient) {
        const res = await this.api.sendRequest<IRevokeWabaCreditLineRequest, IRevokeWabaCreditLineResponse>(
            EWABARequest.revokeCreditLine
        )({
            data: {
                creditLineId: ns.creditLineAllocationId,
            }
        });

        return res;
    }

    async getWabaListWithChannel():Promise<IWabaWithChannelResponse>{
        const response = await this.api.sendRequest(apiRequestType.dashboardExtra.marketing.wabaQuality)({}) as IWabaWithChannelResponse;
        return response;
    }


    public async unpauseTemplate(templateIdNS: string, channelIdNS: string):Promise<boolean>{
        const response = await this.api.sendRequest<ITempalteUnpauseRequest,ITempalteUnpauseResponse>(apiRequestType.dashboardExtra.marketing.updateTemplateUnpause)
        ({
            templateMetaId: templateIdNS, 
            channelIdNS
        })
        return response.success
    }


    public async getScheduledCampaignsByTempladeId(templateIdNS: string){
        const client =  this.api.sendRequest<IGetScheduledCampaignsByTemplateIdNSRequest,IGetScheduledCampaignsByTemplateIdNSResponse>(apiRequestType.dashboardExtra.marketing.getScheduledCampaignByTemplateIdNS)
        const response = await client({templateMetaId:templateIdNS})
        return response.runCampaigns
    }

    public async cancelScheduledCampaignsByTempladeId(runCampaignsIds: string[]){
        const client =  this.api.sendRequest<IRemoveScheduledCampaignsByTemplateIdNSRequest,IRemoveScheduledCampaignsByTemplateIdNSResponse>(apiRequestType.dashboardExtra.marketing.cancelScheduledCampaignByTemplateIdNS)
        const response = await client({runCampaignsIds: runCampaignsIds})
        return response.jobsIds
    }
}
