import {MainHandler} from "./main-handler";
import {IComponentParameter} from "../model/component-comm/basic";
import {ENserVisualizationType, INonSerializable} from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import {isValidRef} from "@colmeia/core/src/tools/utility";
import { environment } from "environments/environment-client";
import { AcceptPromise } from "@colmeia/core/src/tools/utility-types";
import { EBatchSaveType, TEBatchSaveTypeArray } from "@colmeia/core/src/shared-business-rules/production-deploy/prod-deploy-req-res";

export enum ENsMoreOptions {
    AddToPatch = 'addPatch',
    Delete = 'delete',
    Backup = 'backup',
    Clone = 'clone',
    CheckDependencies = 'cdependencies',
    CopyID = 'cid',
    CopyObject = 'copyObj',
    Archive = 'archive',
    changesHistory = 'changesHistory',
    deploysHistory = 'deploysHistory'
}

export type TMoreOptionsConfigs = {
    deployConfig?: {
        allowedOptions?: TEBatchSaveTypeArray
    }
}

export interface NsMoreOptionsClientCallback {
    onNsMoreOptionsDeleteClicked(nser: INonSerializable): void;
    onNsMoreOptionsCopyClicked(nser: INonSerializable): void;
    onNsMoreOptionsCheckDependenciesClicked?(nser: INonSerializable): void;
    onNsMoreOptionArchiveClicked?(nser: INonSerializable): void;
    onNsMoreOptionNSArchived?(nser: INonSerializable): void;
}

export type AllNsOptionsBoolean = {
    [key in ENsMoreOptions]: boolean;
}

export interface INSMoreOptionsCustomOption {
    label: string;
    matIcon: string;
    callback(ns: INonSerializable): AcceptPromise<any>
    callbackIsDisabled?(ns: INonSerializable): boolean;
    isDisabled?: boolean;
}

export type TNSMoreOptionsCustomOptionsArray = Array<INSMoreOptionsCustomOption>

export interface INsMoreOptionsHandlerParameter extends IComponentParameter {
    nser: INonSerializable;
    clientCallback: NsMoreOptionsClientCallback;
    options: Partial<AllNsOptionsBoolean>;
    customOptions?: TNSMoreOptionsCustomOptionsArray
    configs?: TMoreOptionsConfigs
}

export class NsMoreOptionsHandler extends MainHandler<INsMoreOptionsHandlerParameter> {
    useGenericSave: boolean;

    constructor(
        parameter: INsMoreOptionsHandlerParameter,
        useGenericSave = false
    ) {
        super(parameter);
        this.useGenericSave = useGenericSave;
    }

    get parameters() {
        return this.getComponentParameter()
    }

    get hasDeleteButton(): boolean {
        return (this.parameters.options[ENsMoreOptions.Delete]);
    }

    get hasDeployButton(): boolean {
        return (this.parameters.options[ENsMoreOptions.AddToPatch]);
    }

    get hasCopyIDButton(): boolean {
        return (this.parameters.options[ENsMoreOptions.CopyID]);
    }

    get hasCheckDependenciesButton(): boolean {
        return (this.parameters.options[ENsMoreOptions.CheckDependencies]);
    }

    get hasChangesHistory(): boolean { 
        return (this.parameters.options[ENsMoreOptions.changesHistory]);
    }

    get hasDeploysHistory(): boolean { 
        return (this.parameters.options[ENsMoreOptions.deploysHistory]);
    }

    get hasCopyObjectButton(): boolean {
        return environment.allDevFeatures
    }

    get hasArchiveButton(): boolean {
        return (this.parameters.options[ENsMoreOptions.Archive] !== false);
    }

    get isArchived(): boolean {
        return this.parameters.nser.visualizationType === ENserVisualizationType.archived;
    }
}
