<ng-container *ngIf="!isEdit">
  <app-dashboard-integration-create></app-dashboard-integration-create>
</ng-container>

<ng-container *ngIf="isEdit">
  <cm-generic-dashboard-edit *ngIf="genericEditHandler" [handler]="genericEditHandler">
    <app-colmeia-window-top-bar></app-colmeia-window-top-bar>
    <div class="top-content">
      <selected-tags-list *ngIf="selectedTagsListHandler" [handler]="selectedTagsListHandler"></selected-tags-list>
      <div class="right-top-buttons">
        <ng-container *ngIf="!showFileUpsert">
          <div matTooltip="O metadado do banco precisa ter uma propriedade marcada como 'Primary key' para a atualização ser possível.">
            <button mat-raised-button disabled>
              <div class="btn-label-wrapper">
                <mat-icon size="20">upload</mat-icon>
                Upload1
              </div>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="showFileUpsert">
          <button mat-raised-button (click)="openUpsertModal()">
            <div class="btn-label-wrapper">
              <mat-icon size="20">upload</mat-icon>
              Upload2
            </div>
          </button>
        </ng-container>

        <button mat-raised-button class="button-w-icon" (click)="downloadFileBlob()">
          <div class="btn-label-wrapper">
            <mat-icon size="20">download</mat-icon>
            Download
          </div>
        </button>

        <button mat-raised-button class="button-w-icon" (click)="openDatabaseDelete()">
          <div class="btn-label-wrapper">
            <mat-icon size="20">delete</mat-icon>
            Delete
          </div>
        </button>
      </div>
    </div>

    <mat-dialog-content style="padding-left: 12px !important; padding-right: 12px !important;">
      <mat-tab-group color="primary" backgroundColor="primary" mat-stretch-tabs class="rounded-tab-header borderless-tab-header disable-body-content-scroll">
        <mat-tab label="Detalhes do banco de dados">
          <div *ngIf="file" class="file-detail">
            <div class="file-details mat-typography">
              <div class="details">
                <h4>{{ translations.fileName }}: </h4>
                <p>{{ file.fileName }}</p>
              </div>
              <div class="details">
                <h4>{{ translations.fileSize }}: </h4>
                <p>{{ getFileSize(file.file.size) }}</p>
              </div>
              <div class="details">
                <h4>{{ translations.savedLines }}: </h4>
                <p>{{ file.savedLines }}</p>
              </div>
              <div class="details">
                <h4>{{ translations.linesWithError }}: </h4>
                <p>{{ file.linesWithErrors }}</p>
              </div>
              <div class="details">
                <h4>{{ translations.creationDate }}: </h4>
                <p>{{ getDate(file?.clockTick) }}</p>
              </div>
              <div class="details">
                <app-ns-picker *ngIf="metadataVisualizationPicker" [disabled]="true" [handler]="metadataVisualizationPicker"></app-ns-picker>
              </div>
              <div class="details">
                <app-alert *ngIf="file.startedAsAnEmptyDB" type="info" dense style="display: inline-flex;">Esse banco foi inicializado vazio.</app-alert>
              </div>
            </div>

            <table *ngIf="!file.startedAsAnEmptyDB" mat-table [dataSource]="file.dataProfile" style="width: 100%;">
              <ng-container matColumnDef="columnName">
                <th mat-header-cell *matHeaderCellDef> {{ translations.columnName }}: </th>
                <td mat-cell *matCellDef="let element"> {{element.csvColumnName}} </td>
              </ng-container>

              <ng-container matColumnDef="formField">
                <th mat-header-cell *matHeaderCellDef> {{ translations.formField }}: </th>
                <td mat-cell *matCellDef="let element"> {{element.prompt}} </td>
              </ng-container>

              <ng-container matColumnDef="nullFields">
                <th mat-header-cell *matHeaderCellDef>{{ translations.nullFields }}: </th>
                <td mat-cell *matCellDef="let element"> {{element.nulls}} </td>
              </ng-container>

              <ng-container matColumnDef="cardinality">
                <th mat-header-cell *matHeaderCellDef> {{ translations.cardinality }}: </th>
                <td mat-cell *matCellDef="let element"> {{element.cardinality}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab label="Conteúdo do banco de dados">
          <ng-container *ngIf="contentFileRows">
            <div *ngIf="true || hasContentFileRows; else noContentFileRows" class="db-content-table-container">
                <table class="db-content-table table mat-table" [class.expanded-column]="expandDBTable">
                  <thead #contentHeader>
                    <tr class="mat-header-row">
                      <th class="expand">
                        <button
                          mat-icon-button
                          (click)="toggleAllContentRows()"
                          class="expand-button">
                          <mat-icon [class.rotate]="isAllRowContentExpanded">expand_more</mat-icon>
                        </button>
                      </th>
                      <th>Dados computados</th>
                      <th class="filter-wrapper">
                        <div class="filter-container">
                          <div class="filter-value-container">
                            <span class="search-icon">
                              <mat-icon size="18">search</mat-icon>
                            </span>
                            <input
                              [(ngModel)]="filterByValue"
                              (keyup)="buildSearchFilterContentRows()"
                              class="simple-input" />
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody [style.height]="calculateTBodyContentHeight()">

                    <ng-container *ngIf="filterByValue.length">
                      <tr
                        *ngFor="let rowData of filterByValueRows; index as i;"
                        class="mat-row search-row"
                        [class.zebra]="!(i % 2)"
                        >
                        <td>
                          <div class="data-line" *ngFor="let field of rowData; index as columnIndex">
                            <b>{{ fileContent.columns[columnIndex] }}:</b>
                            <span *ngIf="hasSearchTerm(field)" [innerHTML]="highlightSearchValue(field)"></span>
                            <span *ngIf="!hasSearchTerm(field)">{{ field }}</span>
                          </div>
                        </td>
                      </tr>
                    </ng-container>

                    <cdk-virtual-scroll-viewport *ngIf="!filterByValue.length" appendOnly class="nice-scroll" [style.height]="'100%'">
                      <tr
                        *cdkVirtualFor="let rowData of contentFileRows; templateCacheSize: 0; index as i;"
                        class="mat-row"
                        #cTR
                        [class.zebra]="!(i % 2)"
                        [class.highlight]="isExpandedContentRow(i) && !isAllRowContentExpanded"
                        >

                        <td class="expand">
                            <button
                              mat-icon-button
                              [disabled]="rowData.length === 1 || isAllRowContentExpanded"
                              (click)="toggleRowContentShow(rowData, i, cTR)"
                              class="expand-button">
                              <mat-icon [class.rotate]="isExpandedContentRow(i)">expand_more</mat-icon>
                            </button>
                        </td>
                        <td>
                          <div class="data-line">
                            <b>{{ rowData[0].name }}:</b>
                            <span>{{ rowData[0].value }}</span>
                          </div>

                          <div #extraContent [attr.data-source-index]="i" [visible-horizontal]="isExpandedContentRow(i)">
                            <ng-container *ngFor="let field of rowData; index as columnIndex">
                              <ng-container *ngIf="columnIndex > 0">
                                <div class="data-line">
                                  <b>{{ rowData[columnIndex].name }}:</b>
                                  <span>{{ rowData[columnIndex].value }}</span>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>

                          <div *ngIf="rowData.length > 1" [visible-horizontal]="!isExpandedContentRow(i)">
                            <div class="more-data-indicator">...</div>
                          </div>
                        </td>
                      </tr>
                    </cdk-virtual-scroll-viewport>
                  </tbody>
                </table>
            </div>
            <ng-template #noContentFileRows>
              <app-alert type="info">Não há conteúdo nesse banco de dados</app-alert>
            </ng-template>
            <div #contentPagination class="box-style-2">
              <cm-generic-dashboard-pagination *ngIf="hasContentFileRows && !filterByValue.length" [dense]="true" [handler]="paginationContentHandler">
              </cm-generic-dashboard-pagination>
            </div>
          </ng-container>
        </mat-tab>
        <mat-tab label="Erros no upload">
          <table class="table error-table" *ngIf="hasErrors; else noErrorOnDB">
            <thead #contentErrorsRowsHeader>
              <tr>
                <th class="expand">
                  <button
                    mat-icon-button
                    (click)="isAllErrorExpanded = !isAllErrorExpanded"
                    class="expand-button">
                    <mat-icon [class.rotate]="isAllErrorExpanded">expand_more</mat-icon>
                  </button>
                </th>
                <th class="line">
                  Linha
                </th>
                <th>
                  Dados computados
                </th>
              </tr>
            </thead>
            <tbody [style.height]="calculateTBodyErrorsRowsHeight()" class="nice-scroll">
              <ng-container *ngIf="getErrorsRows().length">
                <tr *ngFor="let col of getErrorsRows(); index as rowIndex" class="error-row" [class.highlight]="expandedErrorsRows[rowIndex] && !isAllErrorExpanded">
                  <ng-container *ngIf="col.fields.length">
                    <td class="expand">
                      <button
                        mat-icon-button
                        [disabled]="col.fields.length === 1 || isAllErrorExpanded"
                        (click)="toggleRowErrorShow(rowIndex)"
                        class="expand-button">
                        <mat-icon [class.rotate]="expandedErrorsRows[rowIndex] || isAllErrorExpanded">expand_more</mat-icon>
                      </button>
                    </td>
                    <td class="line"><div>{{col.line}}</div></td>
                    <td>
                      <div class="data-line">
                        <b>{{ col.fields[0].name }}:</b>
                        <span
                          [class.is-error]="col.fields[0].isError"
                          [class.is-empty]="isEmptyField(col.fields[0].value)"> {{ printFieldValue(col.fields[0].value) }} </span>
                      </div>
                      <ng-container *ngTemplateOutlet="errorDetails; context: { field: col.fields[0] }"></ng-container>
                      <div [visible-horizontal]="expandedErrorsRows[rowIndex] || isAllErrorExpanded">
                        <ng-container *ngFor="let field of col.fields; index as columnIndex">
                          <ng-container *ngIf="columnIndex > 0">
                            <div class="data-line">
                              <b>{{ field.name }}:</b>
                              <span
                                [class.is-error]="field.isError"
                                [class.is-empty]="isEmptyField(field.value)"> {{ printFieldValue(field.value) }} </span>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div *ngIf="col.fields.length > 1" [visible-horizontal]="!expandedErrorsRows[rowIndex] && !isAllErrorExpanded">
                        <div class="more-data-indicator">...</div>
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <ng-template #noErrorOnDB>
            <app-alert type="info">Não há erros nesse banco de dados</app-alert>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-dialog-content>
  </cm-generic-dashboard-edit>
</ng-container>
