
import { Injectable } from "@angular/core";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { IBotRootServer } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { TBpmMarketingNaturalNsTypes, TBpmMarketingNserServer } from "@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model";
import { ENonSerializableObjectType, INonSerializable, TINonSerializableArray } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { ISaveBotSerializableResponse } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp";
import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";
import { suggestions } from '@colmeia/core/src/tools/type-utils';
import { isInvalid, isValidFunction, isValidRef } from "@colmeia/core/src/tools/utility";
import { MktBpmDataService } from "app/components/dashboard/marketing-bpm/mkt-bpm-data.service";
import { EBPMType } from '../../../../../core/src/shared-business-rules/BPM/bpm-model';
import { DashboardBotsService } from "../dashboard/dashboard-bots.service";
import { NSValidationService } from "../nser-validation.service";
import { EAppAlertTypes, SnackMessageService } from "../snack-bar/snackbar-message.service";
import { BpmCrmApiService } from './api/bpm-crm-api.service';
import { IBPMSubjectRulesProcessorMethods } from "./bpm-subject-rules/bpm-rules.types";
import { ISaveHostedFromGraph, TBPMSpecificHandlers, TBPMHandlers } from '@colmeia/core/src/shared-business-rules/BPM/bpm-model'
import { BpmNestedAIApiService } from './api/bpm-nested-ai-api.service';
import { TCRMBPMConfigs } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crms-model-bpm-def";

@Injectable({
    providedIn: "root",
})
export class BpmSaveHostedService {

    public constructor(
        private dashboardBotsService: DashboardBotsService,
        private snackSvc: SnackMessageService,
        private validator: NSValidationService,
        private bpmCrmApiService: BpmCrmApiService,
        private mktBpmDataSvc: MktBpmDataService,
        private nestedAIApiService: BpmNestedAIApiService,
    ) { }

    public init({ validator, ruleAbstract: rules }: { validator: NSValidationService, ruleAbstract: IBPMSubjectRulesProcessorMethods }) {
        this.setValidator(validator);
    }

    public setValidator(validator: NSValidationService) {
        this.validator = validator;
    }

    public saveBot = async (element: ISaveHostedFromGraph): Promise<TINonSerializableArray> => {
        return (await this.dashboardBotsService.saveBotElementDetailedResponse(element))?.savedElements || [];
    }

    public saveCRM = async (el: ISaveHostedFromGraph): Promise<TINonSerializableArray> => {
        return await this.bpmCrmApiService.save(el.element as TCRMBPMConfigs, el.idParentGraphNode);
    }

    public saveMKT = async (el: ISaveHostedFromGraph): Promise<TINonSerializableArray> => {
        return this.mktBpmDataSvc.save(<TBpmMarketingNaturalNsTypes>el.element.nsType, el.element);
    }

    public saveNestedAI = async (el: ISaveHostedFromGraph): Promise<TINonSerializableArray> => {
        return await this.nestedAIApiService.save(el.element);
    }

    public handlers: TBPMHandlers = suggestions<TBPMSpecificHandlers>()({
        [EBPMType.bot]: this.saveBot,
        [EBPMType.crm]: this.saveCRM,
        [EBPMType.marketing]: this.saveMKT,
        [EBPMType.nestedAI]: this.saveNestedAI
    });

    public async save(bpmType: EBPMType, element: INonSerializable, idGraphRoot: IdDep<ENonSerializableObjectType.graphElement>, idParentGraphNode?: string): Promise<TINonSerializableArray> {
        if (!this.validate(element)) return;

        const saver: (element: ISaveHostedFromGraph) => Promise<TINonSerializableArray> = this.handlers[bpmType];
        if (!isValidFunction(saver)) {
            this.snackSvc.openDefaultMessage(EAppAlertTypes.Error)('Não é possível salvar esse tipo de elemento');
            return;
        }

        const saved = saver({ element, idGraphRoot, idParentGraphNode });

        return saved
    }

    public validate(element: INonSerializable): boolean {
        const message: string = this.validator.promptAndGetFirstErrorFrom(element, element.nsType);
        if (isValidRef(message)) this.snackSvc.openDefaultMessage(EAppAlertTypes.Error)(message);
        return isInvalid(message);
    }
}
