<app-colmeia-window-top-bar *ngIf="isWindow" [title]="getWindowTitle()"
  [disableWindowMoving]="true"></app-colmeia-window-top-bar>

<section class="tickets-view-container" [class.windowed]="isWindow">
  <ng-container *ngIf="!initialLoading; else loading">
      <div class="header header-container">
        <div class="left" >
          <ng-container *ngIf="selectedPackage">
            <div class="package-selector">
              <mat-select [value]="selectedPackage.packageId" (selectionChange)="handlePackageChange($event)">
                <mat-select-trigger class="triger-select" style="font-weight: 600; padding: 10px 18px;">
                  {{selectedPackage.name}} <span class="crm-project-key">{{selectedPackage.projectKey}}</span>&nbsp;<span
                    class="crm-counter">{{getTicketsLength(selectedPackage.packageId)}}</span>
                </mat-select-trigger>
                <ng-container *ngFor="let package of crmPackages">
                  <mat-option [value]="package.packageId">{{package.name}} <span
                      class="crm-project-key">{{package.projectKey}}</span>&nbsp;<span
                      class="crm-counter">{{getTicketsLength(package.packageId)}}</span></mat-option>
                </ng-container>
              </mat-select>
            </div>
  
            <div class="package-selector">
              <mat-select [panelClass]="'custom-select-panel'" [value]="selectedHeader" (selectionChange)="handleItemPackageChange($event)">
                <mat-select-trigger class="triger-select">
                  {{selectedHeader.name}} <span *ngIf="getTicketsLength(selectedHeader.headerPackageId)"
                    class="crm-counter">{{getTicketsLength(selectedHeader.headerPackageId)}}</span>
                </mat-select-trigger>
  
                <ng-container *ngFor="let itemPackage of crmHeaders">
                  <mat-option [value]="itemPackage">{{itemPackage.name}} <span
                      *ngIf="getTicketsLength(itemPackage.headerPackageId)"
                      class="crm-counter">{{getTicketsLength(itemPackage.headerPackageId)}}</span></mat-option>
                </ng-container>
              </mat-select>
            </div>
  
            <div class="filter-container">
              <button mat-icon-button [matMenuTriggerFor]="filtersGroup"
                [matBadge]="getActiveFiltersLength(filterGroup) || null" matBadgeColor="accent" matBadgeSize="small" class="filter-button">
                <mat-icon>filter_alt</mat-icon>
              </button>
              <div [visible]="!!getActiveFiltersLength(filterGroup)">
                <button aria-label="Limpar filtros" title="Limpar filtros"
                  aria-roledescription="Remove todos os filtros selectionados" mat-stroked-button
                  class="dense button-w-icon" (click)="resetFilters()">
                  <mat-icon>clear</mat-icon>&nbsp;Limpar</button>
              </div>
            </div>
  
            <mat-menu #filtersGroup="matMenu">
              <ng-container *ngFor="let filterGroup of currentFilterGroups">
                <button mat-menu-item class="gs-ticket-menu-btn" [matMenuTriggerFor]="filters">
                  <span class="group-label" [matBadge]="getActiveFiltersLengthForGroup(filterGroup) || null"
                    matBadgeColor="accent" matBadgeSize="small">{{ filterGroup.label }}</span>
                </button>
  
                <mat-menu #filters="matMenu">
                  <ng-container *ngFor="let filter of filterGroup.filters">
                    <button mat-menu-item class="gs-ticket-menu-btn"
                      (click)="handleFilterClick(filterGroup, filter, $event)"
                      [class.active]="isActiveFilter(filterGroup.itemPackageId, filter.statusId)">
                      <span class="content">
                        {{ filter.label }}
                      </span>
                    </button>
                  </ng-container>
                </mat-menu>
  
              </ng-container>
  
            </mat-menu>
          </ng-container>
        

        </div>

        <div class="right">

          <div class="time-window-container">
            <app-time-window-picker [loading]="fetchingTicket" [selectedTimeWindow]="selectedTimeWindow"
              (timeWindowSelected)="setTimeWindowConfig($event)"></app-time-window-picker>
          </div>

          <button *ngIf="!isWindow" (click)="onClickOpenWindowed($event)" mat-icon-button matTooltip="Abrir em janela">
            <mat-icon>open_in_browser</mat-icon>
          </button>
        </div>
      </div>


      <app-kanban-viewer *ngIf="currentView === 'kanban'"></app-kanban-viewer>

      <div *ngIf="currentView === 'list'" class="list">
        <div class="filters box-style-2">
          <div class="left-actions">
            <div class="action">
              <button mat-stroked-button class="button-mode-button button-w-icon" color="primary"
                [matMenuTriggerFor]="statusMenu">
                Status
                <mat-icon>arrow_drop_down</mat-icon>
              </button>

              <mat-menu #statusMenu="matMenu">
                <button mat-menu-item>
                  --
                </button>
              </mat-menu>
            </div>

            <div class="action">
              <button mat-stroked-button class="button-mode-button button-w-icon" color="primary"
                [matMenuTriggerFor]="statusMenu">
                Urgência
                <mat-icon>arrow_drop_down</mat-icon>
              </button>

              <mat-menu #statusMenu="matMenu">
                <button mat-menu-item>
                  --
                </button>
              </mat-menu>
            </div>

            <div class="action">
              <button mat-stroked-button class="button-mode-button button-w-icon" color="primary"
                [matMenuTriggerFor]="statusMenu">
                Severidade
                <mat-icon>arrow_drop_down</mat-icon>
              </button>

              <mat-menu #statusMenu="matMenu">
                <button mat-menu-item>
                  --
                </button>
              </mat-menu>
            </div>

            <div class="action">
              <button mat-stroked-button class="button-mode-button button-w-icon" color="primary"
                [matMenuTriggerFor]="statusMenu">
                Data
                <mat-icon>arrow_drop_down</mat-icon>
              </button>

              <mat-menu #statusMenu="matMenu">
                <button mat-menu-item>Mais recente</button>
                <button mat-menu-item>Mais antiga</button>
              </mat-menu>
            </div>
          </div>

          <div class="right-actions">
            <div class="load-controls-container">
              <button mat-icon-button [disabled]="isLoading">
                <mat-icon>refresh</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="tickets">
          <tickets-view-card *ngFor="let ticketItem of filteredTicketItems" [data]="ticketItem"></tickets-view-card>
        </div>

        <mat-paginator showFirstLastButtons [length]="ticketItems?.length || 0" pageSize="pagination"
          [pageSizeOptions]="paginationOptions" (page)="onChangePagination($event)" [pageIndex]="currentPageIndex">
        </mat-paginator>
      </div>

  </ng-container>
</section>

<ng-template #loading>
  <div class="loading-wrapper">
    <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
  </div>
</ng-template>

<ng-template #windowWarning>
  <div class="window-warning-container">
    <mat-icon class="mat-92">open_in_browser</mat-icon>

    <p>
      A tela de gestão de tickets já está aberta em uma janela!
    </p>

    <button mat-flat-button color="accent" (click)="onClickOpenWindowed($event)">
      Restaurar janela
    </button>
  </div>
</ng-template>
