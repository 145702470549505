import { MetaTranslation } from "@colmeia/core/src/shared-business-rules/const-text/meta-translation";
import { ESocialMedia } from "../../../business/social";
import { asConst, suggestions } from "../../../tools/type-utils";
import { IdField, typedEntryFields, typedFields, typedScreenOptions } from "../../../tools/utility";
import { $$ } from "../../../tools/utility/types/error";
import { EAdvancedTemplateOption, EColmeiaWATemplateApprovalStatus, TColmeiaWhatsAppTemplateApprovalStatus } from "../../social-media/social-media.model";
import { WhatsApp } from "../../social-media/whatsapp-interface";
import { ITranslationConfig, ITranslationHash } from "../../translation/translation-engine";
import { EAllSerializable, defineTranslations, getTranslationsFields, getTranslationsFieldsAndScreenOptions } from "../all-serializables";
import { EMetaWhatsappPhoneStatus, TColmeiaWhatsAppPhoneStatus } from "../../channels/meta-whatsapp-channel.model";

const socialMedia = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.SocialMedia,
    fields: {
        requiredField: 1, // SNManagement/socialMedias 26
        mediaType: 2, // SNManagement/socialMedias 21
        phoneNumber: 3, // SNManagement/socialMedias 28
        idPage: 4, // SNManagement/socialMedias 29
        tokenPage: 5, // SNManagement/socialMedias 30
        key: 6, // SNManagement/socialMedias 31
        token: 7, // SNManagement/socialMedias 32
        default: 8, // SNManagement/socialMedias 33
        alreadyDefault: 9,// SNManagement/socialMedias 34
        service: 10, // SNManagement/socialMedias 37
        socialMedias: 13, // 'SNManagement/socialMedias' 1
        addSocialMedia: 14, // 'SNManagement/socialMedias' 19
        loadError: 11, // 'SNManagement/socialMedias' 23
        noItens: 12, // 'SNManagement/socialMedias' 24
        [ESocialMedia.Google]: 13, // Google
        [ESocialMedia.Facebook]: 14, // Facebook
        [ESocialMedia.Linkedin]: 15, // Linkedin
        [ESocialMedia.Twitter]: 16, // Twitter
    },
} as const);
export const socialMediaScreenOptions = socialMedia.screenOptions;
export const socialMediaTranslations = socialMedia.translations;

const whatsAppTemplate = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.WhatsAppTemplate,
    fields: {
        invalidMeaning: 1, // "whatsappTemplateItem", 26
        contingencyTemplate: 2, // "whatsAppTemplate", 21
        meanings: 3, // "whatsAppTemplate", 24
        requiredField: 4, // "SNManagement/socialMedias", 26
        noBreakLines: 5, // "whatsAppTemplate", 25
        noTabs: 6, // "whatsAppTemplate", 26
        noConsecutiveSpaces: 7, // "whatsAppTemplate", 27
        meaning: 8, // "whatsappTemplateItem", 20

    }
} as const);

const whatsAppAdvancedTemplateOption = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.WhatsappAdvancedTemplateOptions,
    fields: typedFields<EAdvancedTemplateOption>()({
        [EAdvancedTemplateOption.callToAction]: 1, // Chamada para ação
        [EAdvancedTemplateOption.quickReply]: 2, // Resposta rápida
        [EAdvancedTemplateOption.otp]: 3, // OTP
    })
} as const);


const whatsAppLanguageTranslations1 = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.WhatsappLanguages1,
    fields: typedFields<WhatsApp.Template.Language, { IsPartial: true; IsLimitingFields: true }>()({
        [WhatsApp.Template.Language.Afrikaans]: 1, // Africâner
        [WhatsApp.Template.Language.Albanian]: 2, // Albanês
        [WhatsApp.Template.Language.Arabic]: 3, // Árabe
        [WhatsApp.Template.Language.Azerbaijani]: 4, // Azerbaijano
        [WhatsApp.Template.Language.Bengali]: 5, // Bengalês
        [WhatsApp.Template.Language.Bulgarian]: 6, // Búlgaro
        [WhatsApp.Template.Language.Catalan]: 7, // Catalão
        [WhatsApp.Template.Language.ChineseCHN]: 8, // Chinês (CHN)
        [WhatsApp.Template.Language.ChineseHKG]: 9, // Chinês (HKG)
        [WhatsApp.Template.Language.ChineseTAI]: 10, // Chinês (TAI)
        [WhatsApp.Template.Language.Croatian]: 11, // Croata
        [WhatsApp.Template.Language.Czech]: 12, // Tcheco
        [WhatsApp.Template.Language.Danish]: 13, // Dinamarquês
        [WhatsApp.Template.Language.Dutch]: 14, // Holandês
        [WhatsApp.Template.Language.English]: 15, // Inglês
        [WhatsApp.Template.Language.EnglishUK]: 16, // Inglês (Reino Unido)
        [WhatsApp.Template.Language.EnglishUS]: 17, // English (US)
        [WhatsApp.Template.Language.Estonian]: 18, // Estoniano
        [WhatsApp.Template.Language.Filipino]: 19, // Filipino
        [WhatsApp.Template.Language.Finnish]: 20, // Finlandês
        [WhatsApp.Template.Language.French]: 21, // Francês
        [WhatsApp.Template.Language.Georgian]: 22, // Georgian
        [WhatsApp.Template.Language.German]: 23, // Alemão
        [WhatsApp.Template.Language.Greek]: 24, // Grego
        [WhatsApp.Template.Language.Gujarati]: 25, // Guzerate
    })
} as const));


const whatsAppLanguageTranslations2 = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.WhatsappLanguages2,
    fields: typedFields<WhatsApp.Template.Language, { IsPartial: true; IsLimitingFields: true }>()({
        [WhatsApp.Template.Language.Hausa]: 1, // Hauçá
        [WhatsApp.Template.Language.Hebrew]: 2, // Hebraico
        [WhatsApp.Template.Language.Hindi]: 3, // Hindi
        [WhatsApp.Template.Language.Hungarian]: 4, // Húngaro
        [WhatsApp.Template.Language.Indonesian]: 5, // Indonésio
        [WhatsApp.Template.Language.Irish]: 6, // Irlandês
        [WhatsApp.Template.Language.Italian]: 7, // Italiano
        [WhatsApp.Template.Language.Japanese]: 8, // Japonês
        [WhatsApp.Template.Language.Kannada]: 9, // Canarês
        [WhatsApp.Template.Language.Kazakh]: 10, // Cazaque
        [WhatsApp.Template.Language.Kinyarwanda]: 11, // Kinyarwanda
        [WhatsApp.Template.Language.Korean]: 12, // Coreano
        [WhatsApp.Template.Language.KyrgyzKyrgyzstan]: 13, // Kyrgyz (Kyrgyzstan)
        [WhatsApp.Template.Language.Lao]: 14, // Laociano
        [WhatsApp.Template.Language.Latvian]: 15, // Letão
        [WhatsApp.Template.Language.Lithuanian]: 16, // Lituano
        [WhatsApp.Template.Language.Macedonian]: 17, // Macedônio
        [WhatsApp.Template.Language.Malay]: 18, // Malaio
        [WhatsApp.Template.Language.Malayalam]: 19, // Malaiala
        [WhatsApp.Template.Language.Marathi]: 20, // Marati
        [WhatsApp.Template.Language.Norwegian]: 21, // Norueguês
        [WhatsApp.Template.Language.Persian]: 22, // Persa
        [WhatsApp.Template.Language.Polish]: 23, // Polonês
        [WhatsApp.Template.Language.PortugueseBR]: 24, // Português (BR)
        [WhatsApp.Template.Language.PortuguesePOR]: 25, // Português (POR)
    })
} as const));

const whatsAppLanguageTranslations3 = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.WhatsappLanguages3,
    fields: typedFields<WhatsApp.Template.Language, { IsPartial: true; IsLimitingFields: true }>()({
        [WhatsApp.Template.Language.Punjabi]: 1, // Punjabi
        [WhatsApp.Template.Language.Romanian]: 2, // Romeno
        [WhatsApp.Template.Language.Russian]: 3, // Russo
        [WhatsApp.Template.Language.Serbian]: 4, // Sérvio
        [WhatsApp.Template.Language.Slovak]: 5, // Eslovaco
        [WhatsApp.Template.Language.Slovenian]: 6, // Esloveno
        [WhatsApp.Template.Language.Spanish]: 7, // Espanhol
        [WhatsApp.Template.Language.SpanishARG]: 8, // Espanhol (ARG)
        [WhatsApp.Template.Language.SpanishSPA]: 9, // Espanhol (ESP)
        [WhatsApp.Template.Language.SpanishMEX]: 10, // Espanhol (MEX)
        [WhatsApp.Template.Language.Swahili]: 11, // Suaíli
        [WhatsApp.Template.Language.Swedish]: 12, // Sueco
        [WhatsApp.Template.Language.Tamil]: 13, // Tâmil
        [WhatsApp.Template.Language.Telugu]: 14, // Telugo
        [WhatsApp.Template.Language.Thai]: 15, // Tailandês
        [WhatsApp.Template.Language.Turkish]: 16, // Turco
        [WhatsApp.Template.Language.Ukrainian]: 17, // Ucraniano
        [WhatsApp.Template.Language.Urdu]: 18, // Urdu
        [WhatsApp.Template.Language.Uzbek]: 19, // Uzbeque
        [WhatsApp.Template.Language.Vietnamese]: 20, // Vietnamita
        [WhatsApp.Template.Language.Zulu]: 21, // Zulu
    })
} as const));


export const whatsAppDeprecatedCategoryTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.WhatsappCategory,
    fields: typedFields<WhatsApp.Template.Category, { IsLimitingFields: true }>()({
        [WhatsApp.Template.Category.AccountUpdate]: 1, // Atualização da Conta
        [WhatsApp.Template.Category.PaymentUpdate]: 2, // Atualização de Pagamento
        [WhatsApp.Template.Category.PersonalFinanceUpdate]: 3, // Atualização de Finanças Pessoais
        [WhatsApp.Template.Category.ShippingUpdate]: 4, // Atualização de Envio
        [WhatsApp.Template.Category.ReservationUpdate]: 5, // Atualização de Reserva
        [WhatsApp.Template.Category.IssueResolution]: 6, // Solução de Problemas
        [WhatsApp.Template.Category.AppointmentUpdate]: 7, // Atualização de Agendamento
        [WhatsApp.Template.Category.TransportationUpdate]: 8, // Atualização de Transporte
        [WhatsApp.Template.Category.TicketUpdate]: 9, // Atualização de Tíquete
        [WhatsApp.Template.Category.AlertUpdate]: 10, // Atualização de Alerta
        [WhatsApp.Template.Category.AutoReply]: 11, // Resposta Automática
    })
} as const));


export const whatsAppCategoryTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.WhatsappCategoryV14,
    fields: typedFields<WhatsApp.Template.CategoryV14 | WhatsApp.Template.CategoryV15, { IsLimitingFields: true }>()({
        [WhatsApp.Template.CategoryV14.Transactional]: 1, // Transacional
        [WhatsApp.Template.CategoryV14.Mkt]: 2, // Marketing
        [WhatsApp.Template.CategoryV14.OTP]: 3, // One Time Password
        [WhatsApp.Template.CategoryV15.Authentication]: 4, // Autenticação
        // [WhatsApp.Template.CategoryV15.Marketing]: 5, // Marketing
        [WhatsApp.Template.CategoryV15.Utility]: 6, // Utility
    })
} as const));


export const whatsAppLanguageTranslations: { [key in WhatsApp.Template.Language]: ITranslationConfig } = {
    ...whatsAppLanguageTranslations1,
    ...whatsAppLanguageTranslations2,
    ...whatsAppLanguageTranslations3,
}


export const whatsAppAdvancedTemplateActionErrors = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.WhatsappAdvancedTemplateOptions,
    fields: suggestions<{ [key in string]: IdField }>()({
        noMultipleCallToActionButtonsWithSameType: 1, // Só é possível ter no máximo 2 botões de chamada para ação e com tipos diferentes
        quickReply: 2, // Resposta rápida
        invalidTemplate: 3, // Template inválido
        invalidButtonPhoneNumber: 4, // Botão com telefone inválido
        invalidButtonURL: 5, // Botão com URL inválida
        allowOnlyDifferentTypesOnCallToAction: 6, // Só é possível acrescentar botões de tipos distintos na chamada para ação
        allowOnlyTwoButtonsOnCallToAction: 7, // Só é possível acrescentar 2 botões na chamada para ação
        invalidButtonType: 8, // Tipos de botões inválidos
        allowMaxTenButtonsOnTemplate: 9, // Só é possível acrescentar até 10 botões
        allowMaxOnePhoneButton: 10, // Só é possível acrescentar 1 botão do tipo ligar
        allowMaxTwoURLButtons: 11, // Só é possível acrescentar até 2 botões do tipo visitar site
        invalidButtonGrouping: 12, // Os tipos de botões devem estar agrupados e não misturados
    })
} as const));

export const whatsAppSchemaToValidateWhatsAppTemplate = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.WhatsAppTemplateValidationError,
    fields: suggestions<{ [key in string]: IdField }>()({
        namePattern: 1, // O nome só pode conter caracteres alfanuméricos minúsculos e sublinhados
        templateMax: 2, // O conteúdo do template é muito grande
        languageValid: 3, // Idioma inválido
        categoryValid: 4, // Categoria inválida
    })
} as const));

export const whatsAppStatusTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.WhatsAppTemplateApprovalStatus,
    fields: typedFields<TColmeiaWhatsAppTemplateApprovalStatus, { IsLimitingFields: true }>()({
        [WhatsApp.Template.Status.Approved]: 1, // Aprovado
        [WhatsApp.Template.Status.InAppeal]: 2, // Em recurso
        [WhatsApp.Template.Status.Pending]: 3, // Pendente
        [WhatsApp.Template.Status.Rejected]: 4, // Rejeitado
        [WhatsApp.Template.Status.PendingDeletion]: 5, // Aguardando exclusão
        [WhatsApp.Template.Status.Deleted]: 6, // Deletado
        [WhatsApp.Template.Status.Disabled]: 7, // Desabilitado
        [WhatsApp.Template.Status.Flagged]: 8, // Sinalizado
        [WhatsApp.Template.Status.Reinstated]: 9, // Reinstalado
        [EColmeiaWATemplateApprovalStatus.Notsent]: 10, // Não enviado
        [WhatsApp.Template.Status.Paused]: 11, // Pausado
        [WhatsApp.Template.Status.LimitExceeded]: 12, // Limite excedido
    })
} as const));

export const  Translations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.Waba,
    fields: typedFields<TColmeiaWhatsAppPhoneStatus, { IsLimitingFields: true }>()({
        [WhatsApp.Waba.PhoneStatus.CONNECTED]: 1, // Aprovado
        [WhatsApp.Waba.PhoneStatus.FLAGGED]: 2, // Em recurso
        [WhatsApp.Waba.PhoneStatus.RESTRICTED]: 3, // Pendente
    })
} as const));

export const whatsAppTemplateRejectedReason = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.WhatsAppTemplateRejectedReason,
    fields: typedFields<WhatsApp.Message.Template.Structure.RejectedReason, { IsLimitingFields: true }>()({
        [WhatsApp.Message.Template.Structure.RejectedReason.ABUSIVE_CONTENT]: 1, // Conteúdo abusivo
        [WhatsApp.Message.Template.Structure.RejectedReason.INVALID_FORMAT]: 2, // Formato inválido
        [WhatsApp.Message.Template.Structure.RejectedReason.NONE]: 3, // Nenhum
        [WhatsApp.Message.Template.Structure.RejectedReason.PROMOTIONAL]: 4, // Promocional
        [WhatsApp.Message.Template.Structure.RejectedReason.TAG_CONTENT_MISMATCH]: 5, // Incompatibilidade de conteúdo de tag
        [WhatsApp.Message.Template.Structure.RejectedReason.NON_TRANSIENT_ERROR]: 6, // Erro não transitório
        [WhatsApp.Message.Template.Structure.RejectedReason.INCORRECT_CATEGORY]: 7, // Categoria incorreta
    })
} as const));


export const whatsAppVerticalTranslations = (defineTranslations({
    idSerializable: EAllSerializable.WhatsAppVertical,
    fields: {
        [WhatsApp.Profile.Vertical.Automotive]: MetaTranslation.field(1, 'Automotivo'),
        [WhatsApp.Profile.Vertical.BeautySpaAndSalon]: MetaTranslation.field(2, 'Beleza, Spa e Salão'),
        [WhatsApp.Profile.Vertical.ClothingAndApparel]: MetaTranslation.field(3, 'Vestuário e Acessórios'),
        [WhatsApp.Profile.Vertical.Education]: MetaTranslation.field(4, 'Educação'),
        [WhatsApp.Profile.Vertical.Entertainment]: MetaTranslation.field(5, 'Entretenimento'),
        [WhatsApp.Profile.Vertical.EventPlanningAndService]: MetaTranslation.field(6, 'Planejamento e Serviço de Eventos'),
        [WhatsApp.Profile.Vertical.FinanceAndBanking]: MetaTranslation.field(7, 'Finanças e Bancos'),
        [WhatsApp.Profile.Vertical.FoodAndGrocery]: MetaTranslation.field(8, 'Alimentos e Supermercado'),
        [WhatsApp.Profile.Vertical.PublicService]: MetaTranslation.field(9, 'Serviço Público'),
        [WhatsApp.Profile.Vertical.HotelAndLodging]: MetaTranslation.field(10, 'Hotel e Hospedagem'),
        [WhatsApp.Profile.Vertical.MedicalAndHealth]: MetaTranslation.field(11, 'Saúde e Medicina'),
        [WhatsApp.Profile.Vertical.NonProfit]: MetaTranslation.field(12, 'Sem Fins Lucrativos'),
        [WhatsApp.Profile.Vertical.ProfessionalServices]: MetaTranslation.field(13, 'Serviços Profissionais'),
        [WhatsApp.Profile.Vertical.ShoppingAndRetail]: MetaTranslation.field(14, 'Compras e Varejo'),
        [WhatsApp.Profile.Vertical.TravelAndTransportation]: MetaTranslation.field(15, 'Viagem e Transporte'),
        [WhatsApp.Profile.Vertical.Restaurant]: MetaTranslation.field(16, 'Restaurante'),
        [WhatsApp.Profile.Vertical.Other]: MetaTranslation.field(17, 'Outro'),
    }
}));



export const generalErrors = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.SocialMediaConnectionErrors,
    fields: asConst<{ [key in string]: IdField }>()({
        invalidDomain: 1, // Domínio inválido
    })
}));


export const whatsAppTemplateTranslations = {
    ...whatsAppTemplate.translations,
    ...whatsAppAdvancedTemplateOption.translations,
    ...whatsAppAdvancedTemplateActionErrors,
    ...generalErrors,
    ...whatsAppLanguageTranslations,
    ...whatsAppDeprecatedCategoryTranslations,
    ...whatsAppCategoryTranslations,
    ...whatsAppSchemaToValidateWhatsAppTemplate,
    ...whatsAppStatusTranslations,
    ...whatsAppVerticalTranslations,
};
