import { Component, Injector, OnInit } from "@angular/core";
import { AppService } from "app/services/app.service";
import { ServerCommunicationService } from "app/services/server-communication.service";
import { HardwareLayerService } from "./services/hardware";
import { InitSystemService } from "./services/init-system.service";
import { ScreenSpinnerService } from "./services/screen-spinner.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    static injector: Injector;
    systemReady: boolean = false;

    constructor(
        private initSystemService: InitSystemService,
        injector: Injector,
        private hw: HardwareLayerService,
        private loadingSvc: ScreenSpinnerService,
        private servercomm: ServerCommunicationService,
        private appService: AppService
    ) {
        AppComponent.injector = injector;
        console.log({ AppComponentConstructorTimestamp: (new Date()).toISOString() });
        this.appService.getSetAppTypeFromUrlAsync()
    }

    async ngOnInit() {
        this.loadingSvc.show();
        this.servercomm.init();
        await this.hw.loadPlatformSpecificAssets();
        await this.initSystemService.initSystem();
        await this.hw.waitUntilUnlock();
        this.systemReady = true;
    }
}
