import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { IGeneralFormAnswer, ISaveFormAnswer } from '@colmeia/core/src/general-form/general-form-answer';
import { IConditionalData, TValidateFunction } from '@colmeia/core/src/general-form/general-form-condition-processor';
import { GeneralFormEvaluator } from '@colmeia/core/src/general-form/general-form-evaluator';
import { IFormSchema, TFormSchemmaArray, TSchemaPropertyArray } from '@colmeia/core/src/general-form/general-form-interface';
import { EFileRequest, apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { ourTags } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { IBasicCondition, IMetaEngagement } from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { TComputedInfo } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { get0AMCurrentDay, secToMS } from '@colmeia/core/src/time/time-utl';
import { isValidFunction, isValidRef } from '@colmeia/core/src/tools/utility';
import { UserFunctionsService } from 'app/components/dashboard/functions-page/services/user-functions.service';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { GeneralFormViewerComponent } from 'app/components/general-form/general-form-viewer/general-form-viewer.component';
import { EGeneralFormViewType, IGeneralAnswerListItemServer } from 'app/model/general-form.model';
import { AnnotationsService } from 'app/services/annotations.service';
import { GeneralFormService } from 'app/services/general-form.service';
import { SessionService } from 'app/services/session.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { get } from 'lodash';

interface IMappedSchemas {
    [name: string]: IFormSchema
}
// export class MyErrorStateMatcher implements ErrorStateMatcher {
//     isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//         const formFieldPropId = (Object.keys(control.parent.controls).find(key => control.parent.controls[key] === control));
//         const formFieldValue = control.value
//         return !this.generalFormEvaluator.isFormFieldWithError(formFieldPropId, formFieldValue)
//     }
// }

@Component({
    selector: 'app-annotations-upsert',
    templateUrl: './annotations-upsert.component.html',
    styleUrls: ['./annotations-upsert.component.scss'],
})
export class AnnotationsUpsertComponent extends RootComponent<
    'createTemplate' | 'createAnnotation' | 'editAnnotation' | 'typeLabel' | 'save' | 'saveSuccess' | 'saveError' | 'typeYourAnnotation'
> implements OnInit {
    // matcher = new MyErrorStateMatcher();
    noTemplate: boolean = true;
    @Input() schemas: TFormSchemmaArray;
    @Input('schema') inputSchema: IFormSchema;

    @ViewChild('generalFormViewer') generalFormViewer: GeneralFormViewerComponent;

    _schema: IFormSchema;
    get schema(): IFormSchema { return this._schema }
    set schema(value: IFormSchema) {
        this._schema = value;
    }

    @Input() targetDatabaseId: string;
    @Input() idGroup: string;
    @Input('answer') inputAnswer: IGeneralFormAnswer;
    @Input() computedInfo: TComputedInfo;
    @Input() persist: boolean;
    @Input() engagement: IMetaEngagement;
    answer: IGeneralFormAnswer;
    loading: boolean = true;
    disabledAnnotations: boolean = true;
    annotations: IGeneralAnswerListItemServer[];
    todayAnnotations: IGeneralAnswerListItemServer[];
    text: string;
    _viewType: EGeneralFormViewType;
    @Input() serializableId: TGlobalUID;
    @Output() save: EventEmitter<IGeneralFormAnswer> = new EventEmitter<IGeneralFormAnswer>();
    generalFormEvaluator: GeneralFormEvaluator;
    fnFieldValidator: TValidateFunction;

    @Input()
    viewType: EGeneralFormViewType;

    @Input()
    beforeSend?: (answer: IGeneralFormAnswer, ref: AnnotationsUpsertComponent) => boolean;

    public saving: boolean = false;
    @Output('saving')
    savingEvent = new EventEmitter<boolean>();

    constructor(
        private generalFormSvc: GeneralFormService,
        private session: SessionService,
        private snackSvc: SnackMessageService,
        private annotationsService: AnnotationsService,
        private userFunctionsService: UserFunctionsService,
        // private cdr: ChangeDetectorRef
    ) {
        super({
            save: gTranslations.common.save,
            createTemplate: gTranslations.annotations.createTemplate, // Annotations 19
            createAnnotation: gTranslations.annotations.createAnnotation, // Annotations 20
            editAnnotation: gTranslations.annotations.editAnnotation, // Annotations 37
            typeLabel: gTranslations.annotations.typeLabel, // Annotations 21
            saveSuccess: gTranslations.annotations.saveSuccess, // Annotations 34
            saveError: gTranslations.annotations.saveError, // Annotations 35
            typeYourAnnotation: gTranslations.annotations.typeYourAnnotation, // Annotations 38
        });
    }

    public shouldPrintTypedMessage: boolean;

    async ngOnInit() {
        this.schema = this.inputSchema;

        if (!this.inputSchema) {
            await this.loadSchemas();
        }

        if (isValidRef(this.serializableId)) {
            this.annotations = await this.annotationsService.getMyAnnotations(this.serializableId);

            this.loadTodayAnnotations();
            this.loadAnswer();
        }

        this._viewType = isValidRef(this.viewType) ? this.viewType : this.isEdit ? EGeneralFormViewType.edit : EGeneralFormViewType.save

        this.loading = false;
        setTimeout(() => {
            this.disabledAnnotations = false;
        }, 500);
        this.sortFormFields();
        this.shouldPrintTypedMessage = true;
        this.fnFieldValidator = (cond: IBasicCondition, v: IConditionalData) => this.userFunctionsService.validateService(cond, v, this.shouldPrintTypedMessage);
        this.buildFormValidator();

        console.log({ schema: this.schema, annotations: this.annotations, engagement: this.engagement, generalFormEvaluator: this.generalFormEvaluator });
    }

    //<< Can be used in case we choose to update/change an already shown form
    // async updateSchema(schema: IFormSchema) {

    //     if (!this.schema) {
    //         await this.loadSchemas();
    //     }

    //     this.schema = schema;

    //     if (isValidRef(this.serializableId)) {
    //         this.annotations = await this.annotationsService.getMyAnnotations(this.serializableId);

    //         this.loadTodayAnnotations();
    //         this.loadAnswer();
    //     }

    //     this._viewType = isValidRef(this.viewType) ? this.viewType : this.isEdit ? EGeneralFormViewType.edit : EGeneralFormViewType.save

    //     this.loading = false;
    //     setTimeout(() => {
    //         this.disabledAnnotations = false;
    //     }, 500);
    //     this.sortFormFields();
    //     this.shouldPrintTypedMessage = true;
    //     this.fnFieldValidator = (cond: IBasicCondition, v: IConditionalData) => this.userFunctionsService.validateService(cond, v, this.shouldPrintTypedMessage);
    //     this.buildFormValidator()

    //     console.log({ schema: this.schema, annotations: this.annotations, engagement: this.engagement, generalFormEvaluator: this.generalFormEvaluator });

    //     this.generalFormViewer.setSchema(this.schema);
    //     await this.generalFormViewer.updateForm();
    //     this.cdr.markForCheck();
    // }

    sortFormFields() {
        if (this.schema && this.engagement?.elementsOrder) {
            const sortedForm: TSchemaPropertyArray = []

            for (const formFieldId of this.engagement.elementsOrder) {
                const formField = this.schema.form.find(form => form.idProperty == formFieldId)
                if (formField) {
                    sortedForm.push(formField)
                }
            }

            this.schema.form = sortedForm
        }
    }

    buildFormValidator() {
        this.generalFormEvaluator = GeneralFormEvaluator.create({ engagement: this.engagement })
    }

    loadAnswer() {
        this.answer = this.lastTodayAnnotation || this.inputAnswer
    }

    selectionChange(matSelectChange: MatSelectChange) {
        console.log('selection change', matSelectChange)
    }

    async loadSchemas() {
        const groupID: TGlobalUID = this.session.getSelectedGroupID()
        this.schemas = await this.generalFormSvc.getSchemas(ourTags.colmeiaTag.markers.serviceAttendent.idNS, null, groupID);
    }

    get isEdit(): boolean {
        return isValidRef(this.viewType) ? this.viewType === EGeneralFormViewType.edit : !!this.lastTodayAnnotation
    }

    get lastTodayAnnotation(): IGeneralFormAnswer {
        if (!this.todayAnnotations) return

        return this.todayAnnotations[0]
    }

    last(items) {
        if (!items) return

        return items[items.length - 1]
    }

    getDayTime(): number {
        return get0AMCurrentDay();
    }

    loadTodayAnnotations(): void {
        const dayTime = this.getDayTime();
        const idSchemma = get(this.answer || this.schema, 'idSchemma')

        this.todayAnnotations = this.schema
            ? this.annotations
                .filter(annotation => annotation.idSchemma === idSchemma)
                .filter(annotation => annotation.clockTick > dayTime)
            : undefined;
    }

    getInputLabel(idProperty: string) {
        const formField = this.schema.form.find(f => f.idProperty == idProperty)
        if (formField) {
            return formField.prompt
        }
        return ''
    }

    async saveAnnotation({ formAnswer = this.answer, idNS }: ISaveFormAnswer): Promise<void> {
        try {
            if (isValidFunction(this.beforeSend) && !this.beforeSend(this.answer, this)) return;

            this.setSavingState(true);

            this.shouldPrintTypedMessage = false;

            const allFieldsValidated = await this.generalFormEvaluator.validateForm(
                formAnswer,
                this.fnFieldValidator
            );

            const isSomeFormFieldWithError = this.generalFormEvaluator.isSomeFieldWithError(allFieldsValidated);

            console.log({ schema: this.schema, annotations: this.annotations, engagement: this.engagement, formAnswer, allFieldsValidated });

            if (isSomeFormFieldWithError) {
                this.snackSvc.openError('Algum dos valores do formulário não foi validado corretamente.', 4000);
                this.setSavingState(false);
                return
            }

            if (this.persist) {
                const requestType: EFileRequest.saveGeneralAnswer | EFileRequest.saveFeatureAnswer = isValidRef(formAnswer.primaryID)
                    ? apiRequestType.files.saveGeneralAnswer
                    : apiRequestType.files.saveFeatureAnswer;

                const success = await this.generalFormSvc.customSaveAnswer({
                    requestType,
                    answer: formAnswer,
                    ignoreAttendance: false,
                    idNS,
                    engagement: this.engagement,
                    idGroup: this.idGroup,
                    targetDatabaseId: this.targetDatabaseId,
                });

                if (success) {
                    this.snackSvc.openSuccess(this.translations.saveSuccess.value, secToMS(3));

                    this.text = '';
                    this.save.emit(formAnswer);
                } else {
                    this.snackSvc.openError(this.translations.saveError.value);
                }
            } else {
                // todo chamar callback de item criado.
                this.save.emit(formAnswer);
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setSavingState(false);
        }
    }

    setSavingState(value: boolean) {
        this.saving = value;
        this.savingEvent.next(value);
    }

    saveTemplatelessAnnotation() {
        const answer: IGeneralFormAnswer = {
            idSchemma: null,
            responses: [],
            text: this.text,
            primaryID: this.serializableId,
            json: undefined
        }
        this.saveAnnotation(
            { formAnswer: answer }
        );
    }
}
