import { Injectable } from '@angular/core';
import { TNserUID, TPostgresPrimaryKey } from '@colmeia/core/src/core-constants/types';
import { IPostgreSQLCursorRequest } from '@colmeia/core/src/dashboard-control/dashboard-request-interfaces';
import { EJournalingRequestTypes } from '@colmeia/core/src/request-interfaces/message-types';
import { EMutationType, IGetAllChangesFromNonSerializableRequest, IGetAllChangesFromNonSerializableResponse, IGetObjectDeployDataRequest, IGetObjectDeployDataResponse, IJournalingDeleteNonSerializablesFromVersionRequest, IJournalingDeleteNonSerializablesFromVersionResponse, IJournalingGetVersionsRequest, IJournalingGetVersionsResponse, IJournalingTransferNonSerializablesToVersionRequest, IJournalingTransferNonSerializablesToVersionResponse, IJournalingVersionUpdateRequest, IJournalingVersionUpdateResponse, IListAllNonSerializablesChangedInVersionRequest, IListAllNonSerializablesChangedInVersionResponse, IListAllNonserializablesWithoutVersionRequest, IListAllNonserializablesWithoutVersionResponse, IUpdateAllJournalingToVersionIDRequest, IUpdateAllJournalingToVersionIDResponse } from "@colmeia/core/src/shared-business-rules/journaling/journaling-req-res.dto";
import { IMigrationChangeTrackingWithNames, IMigrationJournaling, IMigrationObjectDeploy, IMigrationVersionData } from '@colmeia/core/src/shared-business-rules/journaling/journaling.model';
import { IMigrationVersionComm } from 'app/model/journaling-client.model';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SessionService } from 'app/services/session.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MigrationVersioningJournalingService {

    private selectedVersionSubject = new BehaviorSubject<IMigrationVersionComm>({ isSelected: false });
    private selectedVersion$ = this.selectedVersionSubject.asObservable();

    constructor(
        private api: ServerCommunicationService,
        private session: SessionService
    ) { }

    setCurrentVersion(row: IMigrationVersionComm) {
        this.api.setActiveChangeTrackingVersionID(row.version.idVersion)
        this.selectedVersionSubject.next(row);
    }

    clearCurrentVersion() {
        this.api.clearActiveChangeTrackingVersionID()
        this.selectedVersionSubject.next({ isSelected: false });
    }

    listenVersionSelected(): Observable<IMigrationVersionComm> {
        return this.selectedVersion$
    }

    getCurrentVersionSelected(): IMigrationVersionComm {
        return this.selectedVersionSubject.getValue();
    }

    public async getAllVersions() {
        const response = await this.api.sendRequest<
            IJournalingGetVersionsRequest,
            IJournalingGetVersionsResponse
        >(EJournalingRequestTypes.getAllMigrationVersions)({
            data: {
                idSocialContext: this.session.getCurrentSocialNetworkID(),
                limit: 50,
                cursor: {
                    lastTimestamp: new Date().toISOString()
                }
            }
        });

        return response.versionList;
    }

    public async getAllChangesFromVersion(idVersion: TPostgresPrimaryKey): Promise<IMigrationJournaling[]> {
        const response = await this.api.sendRequest<
            IListAllNonSerializablesChangedInVersionRequest,
            IListAllNonSerializablesChangedInVersionResponse
        >(EJournalingRequestTypes.listAllNonSerializablesChangedInVersion)({
            data: {
                idVersion
            }
        });

        return response?.changedNserList
    }

    public async getAllChangesWithoutVersion(allChangesWithoutVersionParameterData: IPostgreSQLCursorRequest): Promise<IMigrationJournaling[]> {
        const response = await this.api.sendRequest<
            IListAllNonserializablesWithoutVersionRequest,
            IListAllNonserializablesWithoutVersionResponse>
            (EJournalingRequestTypes.listAllNonSerializablesChangedWithoutVersion)({
                data: <IPostgreSQLCursorRequest>allChangesWithoutVersionParameterData
            });

        return response?.changesWithoutVersion;
    }

    public async getAllChangesFromNonSerializable(nserID: TNserUID): Promise<IMigrationChangeTrackingWithNames[]> {
        const response = await this.api.sendRequest<
            IGetAllChangesFromNonSerializableRequest,
            IGetAllChangesFromNonSerializableResponse>
            (EJournalingRequestTypes.getAllChangesFromNonSerializable)({
                data: {
                    nserID: nserID
                }
            });

        return response?.changes;
    }

    public async deleteNonSerializablesFromList(idsCapturedObjects: string[]) {
        const response = await this.api.sendRequest<
            IJournalingDeleteNonSerializablesFromVersionRequest,
            IJournalingDeleteNonSerializablesFromVersionResponse
        >(EJournalingRequestTypes.deleteNonSerializablesFromVersion)({
            data: {
                idsCapturedObjects: idsCapturedObjects
            }
        });

        return response;
    }

    public async transferNonSerializablesToVersion(idVersionTarget: TPostgresPrimaryKey, idsCapturedObjects: string[]) {
        const response = await this.api.sendRequest<
            IJournalingTransferNonSerializablesToVersionRequest,
            IJournalingTransferNonSerializablesToVersionResponse
        >(EJournalingRequestTypes.transferNonSerializablesToVersion)({
            data: {
                idVersionTarget: idVersionTarget,
                idsChangedObjects: <TPostgresPrimaryKey[]>idsCapturedObjects
            }
        });

        return response;
    }

    public async getDeployDataFromNonSerializable(nserID: TNserUID): Promise<IMigrationObjectDeploy[]> {
        const response = await this.api.sendRequest<
            IGetObjectDeployDataRequest,
            IGetObjectDeployDataResponse>
            (EJournalingRequestTypes.getObjectDeployDataRequest)({
                data: {
                    nserID: nserID
                }
            });

        return response?.changes;
    }

    public async addChanngedJournalingsToVersion(versionId: TPostgresPrimaryKey, journalingIDList: TPostgresPrimaryKey[]): Promise<void> {
        await this.api.sendRequest<
            IUpdateAllJournalingToVersionIDRequest,
            IUpdateAllJournalingToVersionIDResponse
        >(EJournalingRequestTypes.updateAllJournalingToVersionID)({
            versionID: <TPostgresPrimaryKey>versionId,
            journalingIDList: <TPostgresPrimaryKey[]>journalingIDList
        })
    }

    async saveVersion(operationType: EMutationType, version: IMigrationVersionData) {
        const response = await this.api.sendRequest<
            IJournalingVersionUpdateRequest,
            IJournalingVersionUpdateResponse
        >(EJournalingRequestTypes.upsertMigrationVersion)({
            data: {
                operationType,
                version
            }
        });
        return response
    }
}
