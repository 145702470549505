import { IBasicCRMElement, ICRMAgentEventServer, ICRMNotificationServer, ICRMProcessingNodeEventServer, ICRMRootServer } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crms-model-bpm-def';
import { IBPMMarketingAcknowledgeServer, IBPMMarketingActionServer, IBPMMarketingListenerServer, IBPMMarketingPauseServer, IBPMMarketingRootServer, TBpmMarketingNaturalNsTypes, TBpmMarketingNserServer } from '../shared-business-rules/BPM/marketing/bpm-marketing.model';
import { TBPMCRMElementTypes } from '../shared-business-rules/graph-transaction/toolbar/config-toolbar.types';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from '../shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { isValidString } from '../tools/utility';
import { VitualNonSerializableGenerator } from './virtual-ns';

export interface INonSerializableFactoryParams {
    name: string;
    idParent?: string;
}

export class NonSerializableFactory {
    static create(type: TBPMCRMElementTypes, params: INonSerializableFactoryParams): INonSerializableHeader {
        const commonFields: IBasicCRMElement = {
            timer: {
                fireAfter: undefined,
            },
            listenConditions: [],
            condFormsAction: [],
            step: {
                force: {
                    idPhase: undefined,
                    idSeverity: undefined,
                    idTicketState: undefined,
                    idCloseState: undefined,
                    idSupportLevel: undefined,
                    idUrgencyLevel: undefined,
                },
                allowConfigure: []
            },
            listenTo: {
                type: undefined,
                idSubscribedTo: undefined,
            },
            onEntry: { bpmAction: undefined },
            onFinish: { bpmAction: undefined },
            bpmScheduleMS: 0
        }

        if (type != ENonSerializableObjectType.crmServiceConfigRoot && !isValidString(params.idParent)) {
            throw new Error(`info.idParent is mandatory for type: ${type}`);
        }

        switch (type) {
            case ENonSerializableObjectType.crmServiceConfigRoot:
                const crmRoot: ICRMRootServer = {
                    ...VitualNonSerializableGenerator.getNS(ENonSerializableObjectType.crmServiceConfigRoot, params.name, true),
                    ...commonFields,
                    titleTemplate: undefined,
                    idConfigurationPackage: '',
                }
                return crmRoot;

            case ENonSerializableObjectType.crmServiceConfigAgentEvent:
                const crmUserEvent: ICRMAgentEventServer = {
                    ...VitualNonSerializableGenerator.getNS(type, params.name, true),
                    ...commonFields,
                    idParent: params.idParent,
                    agentAvailableForms: [],
                }
                return crmUserEvent;

            case ENonSerializableObjectType.crmServiceConfigNotification:
                const crmNotification: ICRMNotificationServer = {
                    ...VitualNonSerializableGenerator.getNS(type, params.name, true),
                    ...commonFields,
                    idParent: params.idParent,
                    idCampaignAction: undefined,
                    deadlineCK: undefined,
                }
                return crmNotification;

            case ENonSerializableObjectType.crmServiceConfigProcessingNode:
                const processingNode: ICRMProcessingNodeEventServer = {
                    ...VitualNonSerializableGenerator.getNS(type, params.name, true),
                    ...commonFields,
                    idParent: params.idParent,
                    processingAction: [],
                    action: {
                        bpmAction: undefined
                    }
                }
                return processingNode;

            default:
                throw new Error(`NonSerializableFactory.create unrecognized nsType:${type}`)
        }

        // checkEarlyReturnSwitch()(nsType)
    }

    static createMktBPM(nsType: TBpmMarketingNaturalNsTypes, name: string, idParent?: string): INonSerializable {
        const base: TBpmMarketingNserServer = {
            ...VitualNonSerializableGenerator.getNS(nsType, name, false),
            idNS: undefined,
            idParent,
        } as TBpmMarketingNserServer;
        switch (nsType) {
            case ENonSerializableObjectType.bpmMarketingRoot:
                const mktRoot: IBPMMarketingRootServer = {
                    ...base,
                    bpmScheduleMS: 0,
                    idCampaingAction: undefined,
                    nsType,
                    condFormsAction: [],
                    listenTo: {
                        type: undefined,
                        idSubscribedTo: undefined,
                    },
                    listenConditions: [],
                };
                return mktRoot as IBPMMarketingRootServer;
            case ENonSerializableObjectType.bpmMarketingAction:
                const mktAction: IBPMMarketingActionServer = {
                    ...base,
                    nsType,
                    listenTo: {
                        type: undefined,
                        idSubscribedTo: undefined,
                    },
                    listenConditions: [],
                    condFormsAction: [],
                    bpmScheduleMS: 0,
                };
                return mktAction as IBPMMarketingActionServer;
            case ENonSerializableObjectType.bpmMarketingListener:
                const mktListener: IBPMMarketingListenerServer = {
                    ...base,
                    nsType,
                    listenTo: {
                        type: undefined,
                        idSubscribedTo: undefined,
                    },
                    listenConditions: [],
                };
                return mktListener as IBPMMarketingListenerServer;
            case ENonSerializableObjectType.bpmMarketingAcknowledge:
                const mktAcknowledge: IBPMMarketingAcknowledgeServer = {
                    ...base,
                    nsType,
                    listenTo: {
                        type: undefined,
                        idSubscribedTo: undefined,
                    },
                    listenConditions: [],
                };
                return mktAcknowledge as IBPMMarketingAcknowledgeServer;
            case ENonSerializableObjectType.bpmMarketingPause: {
                return <IBPMMarketingPauseServer>{ ...base }
            }
            default:
                return { ...base }
        }
    }
}
