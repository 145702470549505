import { Injectable } from '@angular/core';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { TServiceIslandArray } from '@colmeia/core/src/shared-business-rules/attendance-island/attendance-island';
import { ICRMTicketDataWithCustomerName, ITicketInteractionStatus, TICRMTicketDataBasicArray, TICRMTicketItems, TTicketInteracitonArray } from '@colmeia/core/src/shared-business-rules/crm/crm-entities';
import { ECRMCanonicalItemsTypes } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-canonical-model';
import { ICRMHeaderPackageBase, ICRMPackage, TICRMPackageArray } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config-interfaces';
import { ETicketInteractionType } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-model-bpm-exec';
import { ECRMTicketRuntimeRequests, ICRMGetAgentIslandsRequest, ICRMGetAgentIslandsResponse, ICRMGetAvatarPackagesRequest, ICRMGetAvatarPackagesResponse, ICRMGetAgentTicketsRequest, ICRMGetAllTicketInteractionsRequest, ICRMGetAllTicketInteractionsResponse, ICRMTicketGetAttendanceTicketsRequest, ICRMTicketGetAttendanceTicketsResponse } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-runtime-req-res';

import { ICRMTimeWindowConfig, getDefaultTimeWindow } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-time-window.model';
import { getItemStatusKeyByType, getItemStatusNameKeyByType } from '@colmeia/core/src/shared-business-rules/crm/crm-utils';
import { typedClone } from '@colmeia/core/src/tools/barrel-tools';
import { ITicketInteractionStatusClient, TTicketInteractionClient } from 'app/crm-service-tickets-view/tickets-view-card-dialog/ticket-interaction/ticket-interaction.component';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { AttendanceService, IRegistreService } from 'app/services/attendance.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { RoutingService } from 'app/services/routing.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { cloneDeep } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { CRMTicketNotificationService } from './crm-ticket-notification.service';
import { CRMTicketPersistenceService, IGetAllTicketsAndPackagesResponse } from './crm-ticket-persistence.service';
import { CRMTicketsDialogService } from './crm-tickets-dialog.service';

export const crmTicketsColmeiaWindowId = "tickets-window";

export type TStatusInteractionProp = keyof ITicketInteractionStatus & `id${string}Item`;


export const statusInteractionProps: TStatusInteractionProp[] = [
    "idSeverityItem",
    "idTicketStateItem",
    "idSupportLevelItem",
    "idPhaseItem",
    "idCloseStateItem",
    "idUrgencyLevelItem",
];

export interface IGetTicketsForAttendanceParams {
    idStartInteracrion: string;
    timeWindow?: ICRMTimeWindowConfig;
    useVisualizerIdForTitle: string;
    showAllCustomerTicketsToAgent?: boolean;
    showProjectKey?: boolean;
}


export interface ITicketParamsBase {
    ticketData: ICRMTicketDataWithCustomerName
}

export interface ITicketsLoaded {
    tickets: TICRMTicketItems;
    packages: TICRMPackageArray;
}

@Injectable({
    providedIn: 'root'
})
export class CRMTicketsService {

    #ticketAdded: Subject<ITicketParamsBase> = new Subject();
    #ticketUpdate: Subject<ITicketParamsBase> = new Subject();
    #ticketsLoaded: Subject<ITicketsLoaded> = new Subject();

    get $ticketAdded(): Observable<ITicketParamsBase> { return this.#ticketAdded.asObservable(); }
    get $ticketUpdate(): Observable<ITicketParamsBase> { return this.#ticketUpdate.asObservable(); }
    get $ticketsLoaded(): Observable<ITicketsLoaded> { return this.#ticketsLoaded.asObservable(); }

    #itensName: Map<string, string> = new Map();

    #packages: Map<string, ICRMPackage> = new Map();
    #tickets: Map<string, ICRMTicketDataWithCustomerName> = new Map();

    get packages() { return [...this.#packages.values()]; }
    get tickets() { return [...this.#tickets.values()]; }

    constructor(
        private api: ServerCommunicationService,
        private routeSvc: RoutingService,
        private dashboardSvc: DashBoardService,
        private attendanceSvc: AttendanceService,
        private ticketDialogSvc: CRMTicketsDialogService,
        protected persistenceSvc: CRMTicketPersistenceService,
        private ticketNotificationSvc: CRMTicketNotificationService,
        private ticketPersistenceSvc: CRMTicketPersistenceService,
    ) {
        this.ticketNotificationSvc.$newTicketsStream.subscribe((ticket) => {
            this.addTicket(ticket);
        });

        this.ticketNotificationSvc.$newTicketsInteractionStream.subscribe(({ ticketData }) => {
            this.updateTicket(ticketData);
        });
    }

    async getAllTicketsAndPackages(req?: Partial<ICRMGetAgentTicketsRequest>): Promise<IGetAllTicketsAndPackagesResponse> {

        const { tickets, packages } = await this.ticketPersistenceSvc.getAllTicketsAndPackages(req);

        packages.forEach(pkg => {
            this.#packages.set(pkg.packageId, pkg)
        });

        tickets.forEach(ticket => {
            this.#tickets.set(ticket.idTicket, ticket)
        });

        this.buildItemsNames();

        this.#ticketsLoaded.next({ packages, tickets });

        return {
            packages,
            tickets,
        }
    }

    private buildItemsNames() {
        this.#itensName.clear();

        this.#packages.forEach((project) => {
            this.#itensName.set(project.packageId, project.name);

            Object.values(project.packages).forEach((pkg: ICRMHeaderPackageBase) => {
                this.#itensName.set(pkg.headerPackageId, pkg.name);

                pkg.items.forEach(item => {
                    this.#itensName.set(item.itemId, item.name)
                })
            });
        });

        this.#tickets.forEach(ticket => {
            this.#itensName.set(ticket.idTicket, ticket.title);
        });
    }

    async getAllIslands(): Promise<TServiceIslandArray> {
        const result = await this.api.sendRequest<ICRMGetAgentIslandsRequest, ICRMGetAgentIslandsResponse>(ECRMTicketRuntimeRequests.getAgentIslands)({});

        return result.islands;
    }

    public async fetchAvatarPackages(): Promise<TICRMPackageArray> {
        const result = await this.api.sendRequest<ICRMGetAvatarPackagesRequest, ICRMGetAvatarPackagesResponse>(ECRMTicketRuntimeRequests.getAvatarPackages)({});

        return result?.packages || [];
    }

    public async getAllTicketInteractions(idTicket: string): Promise<TTicketInteracitonArray> {
        const result = await this.api.sendRequest<ICRMGetAllTicketInteractionsRequest, ICRMGetAllTicketInteractionsResponse>(ECRMTicketRuntimeRequests.getAllTicketInteractions)({ idTicket });

        result.ticketInteractions.sort((a, b) => b.clockTick - a.clockTick);

        return result?.ticketInteractions || [];
    }

    /**
     * faz algumas modificações nas interações para facilitar a exibição no client
     */
    public parseTicketInteractionsForClient(interactions: TTicketInteracitonArray): TTicketInteractionClient[] {
        const newInteractions: TTicketInteractionClient[] = [];

        for (let interaction of interactions) {
            switch (interaction.interactionType) {
                case ETicketInteractionType.status:
                    const clientInteraction = this.parseStatusInteraction(interaction, interactions);

                    newInteractions.push(clientInteraction);
                    break;

                default:
                    newInteractions.push(interaction);
            }
        }

        return newInteractions;
    }

    private parseStatusInteraction(current: ITicketInteractionStatus, array: TTicketInteracitonArray): ITicketInteractionStatusClient {
        const newInteraction: ITicketInteractionStatusClient = typedClone(current);
        let lastStatusInteraction = undefined;

        // get last status interaction
        for (let i = array.indexOf(current) + 1; i < array.length; i++) {
            const item = array[i];

            if (item.interactionType === ETicketInteractionType.status) {
                lastStatusInteraction = item;
                break;
            }
        }

        if (lastStatusInteraction) {
            newInteraction.changedStatusProperties = this.getStatusInteractionChangedProps(lastStatusInteraction, current);
        } else {
            newInteraction.isFirst = true;
        }

        return newInteraction;
    }

    private getStatusInteractionChangedProps(a: ITicketInteractionStatus, b: ITicketInteractionStatus): string[] {
        const properties = [];
        const propNames: string[] = statusInteractionProps;

        for (let prop of propNames) {
            if (a[prop] !== b[prop]) {
                properties.push(prop);
            }
        }

        return properties;
    }

    async checkTicketsAccess(): Promise<boolean> {
        const { isAllowed } = await this.dashboardSvc.getRouteAccessInfo(
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.tickets.path
        );

        return isAllowed;
    }

    public navigateToTickets() {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.tickets.path
        );
    }

    public navigateOrOpenTicketsWindow() {
        if (this.ticketDialogSvc.findOpenedWindow()) {
            this.ticketDialogSvc.restoreWindow();
        } else {
            this.navigateToTickets();
        }
    }

    public async getTicketsForAttendance({
        idStartInteracrion,
        useVisualizerIdForTitle,
        timeWindow = getDefaultTimeWindow(),
        showAllCustomerTicketsToAgent,
        showProjectKey
    }: IGetTicketsForAttendanceParams): Promise<TICRMTicketDataBasicArray> {

        const attRegistry: IRegistreService = this.attendanceSvc.getAttendanceRegistryByIdInteraction(idStartInteracrion);
        const result = await this.api.sendRequest<ICRMTicketGetAttendanceTicketsRequest, ICRMTicketGetAttendanceTicketsResponse>(ECRMTicketRuntimeRequests.getAttendanceTickets)({
            timeWindow,
            includeAnotherAgentTickets: false,
            useVisualizerIdForTitle,
            showAllCustomerTicketsToAgent,
            showProjectKey,
            idCustomerAvatar: attRegistry.interaction.participant.idAvatar,
        });

        return result?.tickets || [];
    }

    public getSelectedTicketForAttendance(idStartInteractionId: TGlobalUID): string {
        return this.attendanceSvc.getAttendanceRegistryByIdInteraction(idStartInteractionId)?.interaction.idTicket;
    }

    public async updateTicketStatusID(idTicket: string, statusId: string, type: ECRMCanonicalItemsTypes): Promise<ICRMTicketDataWithCustomerName | undefined> {
        const currentTicket = this.#tickets.get(idTicket);
        const newTicket = cloneDeep(currentTicket)

        const result = await this.persistenceSvc.updateTicketStatusItem(
            idTicket,
            type,
            statusId
        );

        if (!!result) {
            const statusKey = getItemStatusKeyByType(type);
            newTicket.currentStatus[statusKey] = statusId;
            const statusName = getItemStatusNameKeyByType(type);
            newTicket.currentStatusNames[statusName] = this.getItemName(statusId);
            // this.#ticketUpdate.next({ ticketData: newTicket });

            this.updateTicket(newTicket)
            return newTicket;
        }
    }

    /**
     * Retorna o nome de qualquer item do sistema que esteja em memória
     */
    getItemName(idItem: TGlobalUID): string {
        return this.#itensName.get(idItem);
    }

    public async addTicket(ticketData: ICRMTicketDataWithCustomerName) {
        await this.loadPackageForTicketIfNeeded(ticketData);

        this.#tickets.set(ticketData.idTicket, ticketData);

        this.buildItemsNames();

        this.#ticketAdded.next({ ticketData });
    }

    async openTicket(idTicket: TGlobalUID) {
        const ticketData: ICRMTicketDataWithCustomerName = await this.persistenceSvc.getTicket(idTicket)
        await this.addTicket(ticketData);

        this.ticketDialogSvc.openTicketWindow(ticketData);
    }

    private async loadPackageForTicketIfNeeded(ticketData: ICRMTicketDataWithCustomerName) {
        const hasPackageInMemory = this.#packages.has(ticketData.idPackage);

        if (!hasPackageInMemory) {
            const [pkg] = await this.persistenceSvc.getPackages([ticketData.idPackage]);

            this.#packages.set(pkg.packageId, pkg);
        }
    }

    public async updateTicket(ticketData: ICRMTicketDataWithCustomerName) {
        const hasPackageInMemory = this.#packages.has(ticketData.idPackage);

        if (!hasPackageInMemory) {
            const [pkg] = await this.persistenceSvc.getPackages([ticketData.idPackage]);

            this.#packages.set(pkg.packageId, pkg);
        }

        this.#tickets.set(ticketData.idTicket, ticketData);

        this.buildItemsNames();

        this.#ticketUpdate.next({ ticketData });

    }

    async getProject(idPackage: string): Promise<ICRMPackage> {
        const fromMemory = this.#packages.get(idPackage);

        if (fromMemory) return fromMemory;

        const [pkg] = await this.persistenceSvc.getPackages([idPackage])

        pkg && this.#packages.set(idPackage, pkg)

        return pkg;
    }

}
