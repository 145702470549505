import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from '../../non-serializable-id/non-serializable-id-interfaces';
import { IBPConditionalEvaluator } from '../../BPM/bpm-model';
import { TGlobalUID } from '../../../business/constant';
import { ECloseState, EServiceManagementPhases, ESeverityLevel, ESupportLevel, ETicketState, EUrgencyLevel } from './crm-canonical-model';

export enum ECRmExecutionType {
    eCrmRoot = 'eroot',
    eCrmAgent = 'eagent',
    eCrmCustomer = 'ecustomer',
    eCrmSystem = 'esystem',
    eCrmSupervisor = 'esupervisor'
}

export enum ETicketInteractionType {
    content = 'ctt',
    status = 'sts',
    allocation = 'alloc',
    formAnswer = 'frmAnsr',
    queue = 'q',
}

// interação com humanos por exemplo
export interface ICRMResponseForm {
    idFormulary: string;
    idAnswer: string;
}

export type TICRMResponseFormArray = Array<ICRMResponseForm>;

export interface ICRMWatchers {
    idAvatar: TGlobalUID;
    condition: IBPConditionalEvaluator
}

export interface IShortNotes {
    clockTick: number;
    description: string;
}

export type TShortNoteArray=Array<IShortNotes>;

export type TIInterestedPartyArray=Array<ICRMWatchers>;


export interface ICRMStatusConfiguration {
    idSeverity: string;
    idUrgency: string;
    idSupportLevel: string;
    idServicePhase: string;
    idCloseState: string;
    idTicketState: string;

} 

export interface ICRMExecClassificationModel extends ICRMStatusConfiguration {
    severity: ESeverityLevel;
    urgency: EUrgencyLevel;
    supportLevel: ESupportLevel;
    servicePhase: EServiceManagementPhases;
    closeState: ECloseState;
    ticketState: ETicketState;
}

export interface ICRMContentInteraction {
    shortNotes: TShortNoteArray;
    answers: TICRMResponseFormArray;
}
export interface ICRMBasicIdentifierModel {
    idService: string; // id do serviço em execução
    idBpmCRM: string; // aponta para o modelo do BPM (idNS)
    idBPMRoot: string; // root do bot
    

    watchers: TIInterestedPartyArray;
    status: ICRMExecClassificationModel
}

export interface ICRMExecBasicInterface extends INonSerializableHeader, ICRMBasicIdentifierModel, ICRMContentInteraction {
    execType: ECRmExecutionType

}

export interface ICRMExecBasicInterfaceTyped extends ICRMExecBasicInterface, INonSerializable {
    nsType: ENonSerializableObjectType.crmExecution
    
}

export interface ICRMExecRoot extends ICRMExecBasicInterfaceTyped {
    execType: ECRmExecutionType.eCrmRoot
    
}

export interface ICRMEAgentInteraction extends ICRMExecBasicInterfaceTyped {
    execType: ECRmExecutionType.eCrmAgent
    
};

