import { Injectable } from "@angular/core";
import { ICRMPackageItemBase } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config-interfaces";
import { defaultTimeWindows, getDefaultTimeWindow, ICRMTimeWindowConfig } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-time-window.model";
import { IKanbanCard } from "@colmeia/core/src/shared-business-rules/kanban/kanban-shared-model";
import { getClock, keys, sumOf, values } from "@colmeia/core/src/tools/barrel-tools";
import { ITicketKanbanCardData } from "app/crm-service-tickets-view/crm-kanban-view/crm-kanban-shared.model";
import { TicketKanbanCard } from "app/crm-service-tickets-view/crm-kanban-view/crm-ticket.kanban-card";
import { CRM_USER_CONFIG_LOCAL_STORAGE_KEY } from "app/crm-service-tickets-view/crm-user-config/crm-user-config.constants";
import { IUserTicketUIConfig, ICRMKanbanUserSettings } from "app/crm-service-tickets-view/crm-user-config/crm-user-config.model";
import { HardwareLayerService } from "app/services/hardware";
import { cloneDeep } from "lodash";



function getInitialUserConfigSettings(): IUserTicketUIConfig {
    return {
        selectedPackageId: '',
        kanban: {
            timeWindow: getDefaultTimeWindow(),
            selectedBoardId: '',
            selectedFilters: {},
            customFilters: [],
            sortedCards: {},
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class CRMUserConfigService {
    private userConfig!: IUserTicketUIConfig;

    constructor(
        private hardwareSvc: HardwareLayerService,
    ) {
        this.loadPersisted();
    }

    private loadPersisted() {
        const saved = this.storage.getItem<IUserTicketUIConfig | undefined>(CRM_USER_CONFIG_LOCAL_STORAGE_KEY);

        if (!saved) {
            this.userConfig = getInitialUserConfigSettings();
            this.saveUserConfig();
        } else {

            this.userConfig = saved;
        }

        if (!this.userConfig.kanban) {
            this.userConfig = {
                selectedPackageId: this.userConfig.selectedPackageId,
                kanban: cloneDeep(this.userConfig) as any
            }
            // this.userConfig
        }
    }

    private saveUserConfig() {
        const toSave = cloneDeep(this.userConfig);

        // Para não bloquear a UI
        requestAnimationFrame(() => {
            this.storage.putItem(CRM_USER_CONFIG_LOCAL_STORAGE_KEY, toSave);
        });
    }

    get storage() {
        return this.hardwareSvc.getStorage();
    }

    public toggleItemFilterActive(idItemPackage: string, itemId: string) {
        const selectedFilters = this.selectedFilters;

        const idx = selectedFilters[idItemPackage]?.indexOf(itemId)
        const isActive = idx >= 0;

        if (isActive) {
            const isUniq = selectedFilters[idItemPackage].length === 1;

            if (isUniq) {
                delete selectedFilters[idItemPackage];
            } else {
                selectedFilters[idItemPackage].splice(idx, 1);
            }
        } else {
            selectedFilters[idItemPackage] ||= [];

            selectedFilters[idItemPackage].push(itemId);
        }

        this.saveUserConfig();
    }

    get selectedFilters() {
        const selectedFilters = this.userConfig.kanban.selectedFilters[this.userConfig.selectedPackageId] ||= {};

        return selectedFilters;
    }

    cardFilterFn = (card: IKanbanCard<ITicketKanbanCardData>) => {
        const ticketData = card.getData();
        const allItemsPackages = values(ticketData.ticketPackage.packages);
        const allTicketStatusIds = values(ticketData.ticketData.currentStatus)
        const allTicketItemsPackages = allTicketStatusIds
            .map(ticketStatusItemId => {
                return allItemsPackages.find(pkg => {
                    return pkg.items.some((item: ICRMPackageItemBase) => item.itemId === ticketStatusItemId)
                })
            })
            .filter(pkg => !!pkg);

        const allTicketItemsPackagesIds = allTicketItemsPackages.map(pkg => pkg.headerPackageId)
        const itemPkgFilterSelection = keys(this.selectedFilters);

        for (const packageId in this.selectedFilters) {

            if (itemPkgFilterSelection.length >= 0 && !allTicketItemsPackagesIds.includes(packageId)) {
                return false;
            }

            const statusIdsFromFilter = this.selectedFilters[packageId];

            if (!allTicketStatusIds.some(ticketStatusId => statusIdsFromFilter.includes(ticketStatusId))) {
                return false;
            }
        }

        return true;
    }

    get sortedCards(): ICRMKanbanUserSettings['sortedCards'] {
        let config = this.userConfig.kanban.sortedCards ||= {};

        return config;
    }

    cardSortFn = (cardA: TicketKanbanCard, cardB: TicketKanbanCard, columnId: string) => {
        const sortedCards = this.sortedCards;
        const sortCardAConfig = sortedCards[cardA.getCardID()];
        const sortCardBConfig = sortedCards[cardB.getCardID()];

        if (!sortCardAConfig && !sortCardBConfig) {
            return 0;
        }

        const cardASort = sortCardAConfig?.boards[this.userConfig.kanban.selectedBoardId];
        const cardBSort = sortCardBConfig?.boards[this.userConfig.kanban.selectedBoardId];

        const mostRecentConfig = sortCardAConfig?.clockTick > sortCardBConfig?.clockTick
            ? cardASort
            : cardBSort;

        console.log("SORT-FN", { mostRecentConfig, cardASort, cardBSort });

        /**
         * Quando o card é motivo para o topo da lista
         */
        if (!cardBSort?.afterCardId && !cardASort?.afterCardId) {
            return -1;
        }

        if (!cardBSort?.afterCardId && cardASort?.afterCardId) {
            return -1;
        }

        return cardBSort?.afterCardId === cardA.getCardID()
            ? -1
            : 1;
    }

    public setSelectedHeader(selectedHeaderId: string) {
        if (this.userConfig.kanban.selectedBoardId === selectedHeaderId) return;

        this.userConfig.kanban.selectedBoardId = selectedHeaderId;
        this.saveUserConfig();
    }

    public getUserConfig() {
        return this.userConfig;
    }

    public setSelectedPackageId(packageId: string) {
        this.userConfig.selectedPackageId = packageId;

        this.saveUserConfig();
    }


    public getActiveFiltersLength(): number {
        return sumOf(values(this.selectedFilters).map(filter => filter.length));
    }

    public getActiveFiltersLengthForGroup(itemPackageId: string): number {
        return this.selectedFilters[itemPackageId]?.length || 0;
    }

    public isActiveFilter(itemPackageId: string, statusId: string) {
        return this.selectedFilters[itemPackageId]?.includes(statusId);
    }

    public cardMoved(cardId: string, afterCardId: string, columnId: string) {
        const clockTick = getClock();
        const current = this.userConfig.kanban.sortedCards[cardId] ||= { cardId, boards: {}, clockTick };

        // if (current.boards[this.userConfig.selectedBoardId]?.afterCardId === afterCardId) {
        //     return;
        // }

        current.boards[this.userConfig.kanban.selectedBoardId] = {
            afterCardId,
        };

        current.clockTick = clockTick;

        this.saveUserConfig();
    }

    public resetFilters() {
        this.userConfig.kanban.selectedFilters = {};
        this.saveUserConfig();
    }

    public getTimeWindowConfig(): ICRMTimeWindowConfig {
        return this.userConfig?.kanban?.timeWindow || defaultTimeWindows[0];
    }

    public setTimeWindwConfig(config: ICRMTimeWindowConfig): void {
        this.userConfig.kanban.timeWindow = config;
        this.saveUserConfig();
    }
}
