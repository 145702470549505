import { ChangeDetectorRef, Component, Inject, Input, OnChanges } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BBCode } from "@colmeia/core/src/shared-business-rules/bbcode/bbcode-main";
import { isMetadataSafe } from "@colmeia/core/src/shared-business-rules/bot/bot-function-model-helper";

import { ILocalCanonical, IServerLocalCanonical } from "@colmeia/core/src/shared-business-rules/canonical-model/local-canonical";
import { IServerColmeiaTag } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { metadataNamesTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/metadata";
import { EMetadataNames } from "@colmeia/core/src/shared-business-rules/metadata/metadata-db";
import {
    addBracket, compileText, IEditorVariable,
    removeBracket,
    textCompiledDelimeters,
    TIEditorVariableArray
} from "@colmeia/core/src/shared-business-rules/metadata/metadata-utils";
import { ENonSerializableObjectType, INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { createTypeGuard, getNormalizedValue, isInvalid, isValidArray, isValidRef, isValidString, mapBy } from "@colmeia/core/src/tools/utility";
import { FindKeysWithValueOf, PickByValueType } from "@colmeia/core/src/tools/utility-types";
import { RootComponent } from "app/components/foundation/root/root.component";
import { IVarEditorAreaSlaveCallback, IVarEditorTextAreaParameter, VarEditorTextAreaHandler } from "app/handlers/var-editor-textarea.handler";
import { $SimpleChange, OnChange } from "app/model/client-utility";
import { ToSimpleChange } from "app/model/client-utility-types";
import { CanonicalService } from "app/services/canonical.service";
import { LookupService } from "app/services/lookup.service";
import { EAppAlertTypes, SnackMessageService } from "app/services/snack-bar";
import { EVarEditorEntityType, ICompileVariablesResult, ITextAndVariables, IUsedVariablesID, IVarEditorHandlerParameter, pickFormatVisibility, VarEditorHandler } from "../../../handlers/var-editor.handler";
import { IColmeiaDialogComponentData } from "../../dialogs/dialog/dialog.component";

const serverLocalCanonicalGuard = createTypeGuard<IServerLocalCanonical>((ns: INonSerializable) => ns.nsType === ENonSerializableObjectType.canonical);
const serverColmeiaTagGuard = createTypeGuard<IServerColmeiaTag>((ns: INonSerializable) => ns.nsType === ENonSerializableObjectType.colmeiaTags);

type TSelectedTextareaHandler = FindKeysWithValueOf<VarEditorComponent, VarEditorTextAreaHandler>;

export interface IEditorVariableClient extends IEditorVariable {
    tooltip?: string;
    variableWithoutBrackets?: string;
}

export interface IEditorSafeVariableClient extends IEditorVariableClient {
    isSafe: true
}

@Component({
    selector: "app-var-editor",
    templateUrl: "./var-editor.component.html",
    styleUrls: ["./var-editor.component.scss"],
})
export class VarEditorComponent extends RootComponent<
    | 'fullfillFallBackMessage'
    | 'youCantWriteInsideAVariable'
    | EMetadataNames
> implements OnChanges {

    public mapIdNSToNS: Map<string, INonSerializable> = new Map();
    public textareaCursorPosition: number = 0;
    public rawTextHandler: VarEditorTextAreaHandler;
    public fallbackHandler: VarEditorTextAreaHandler;
    public messageIfNoBind: string;
    public _handler: VarEditorHandler;
    public selectedTextareaHandler: string;
    public isDialog: boolean;

    private getNSCache = this.lookupSvc.createNSCacheImplementation();

    @OnChange()
    public isLoading: boolean = false;

    /**
     * Inseri esse método, cuja função é observar mutações, neste lugar porque o componente ancestral (BotActionEditorComponent) possui 'OnPush' como 'ChangeDetectionStrategy'
     * Isso resulta num bug no qual este componente (VarEditorComponent) não atualiza quando seu atributo isLoading sofre uma mutação
     * Invocar o markForCheck resolve o problema
     */
    public onChangeIsLoading(change: $SimpleChange<this, 'isLoading'>): void {
        this.cdr.markForCheck();
    }

    public searchToken: string = '';
    public _searchVariablesArray: TIEditorVariableArray
    public valid: boolean = false;

    public get handler(): VarEditorHandler {
        return this._handler;
    }
    @Input()
    public set handler(value: VarEditorHandler) {
        this._handler = value;
        this.onSetHandler();
    }

    public canonicalFilter: boolean = false;
    public tagFilter: boolean = false;
    public schemaPropertyFilter: boolean = false;
    public allVariables: IEditorVariableClient[];
    public allSafeVariables: IEditorSafeVariableClient[];

    constructor(
        @Inject(MAT_DIALOG_DATA)
        data: IColmeiaDialogComponentData<VarEditorHandler>,
        private dialogRef: MatDialogRef<any>,
        private snack: SnackMessageService,
        private canonicalService: CanonicalService,
        private cdr: ChangeDetectorRef, // Leia o comentário do método onChangeIsLoading
        private lookupSvc: LookupService,
    ) {
        super({
            fullfillFallBackMessage: gTranslations.errors.fullfillFallBackMessage,
            youCantWriteInsideAVariable: gTranslations.errors.youCantWriteInsideAVariable,
            ...metadataNamesTranslations,
        });

        if (isValidRef(data)) {
            const handler: VarEditorHandler = data.getParamsToChildComponent();
            if (handler instanceof VarEditorHandler) {
                this.handler = handler;
                this.isDialog = true;
            };
        }
    }

    public async onSetHandler() {
        await this.init();
        this.handler.setHomeInstance(this);
        this.rawText = this.rawText;
    }

    public forceSave(): void {
        this.save();
    }

    public ngOnChanges(changes: ToSimpleChange<this>): void { }

    public getTextAreaHandler(property: keyof PickByValueType<VarEditorComponent | ITextAndVariables, string>, extraParameters: Pick<IVarEditorTextAreaParameter, 'placeholderText'>): VarEditorTextAreaHandler {

        return VarEditorTextAreaHandler.factory({
            rawText: this[property],
            clientCallback: {
                onVarEditorChangeRawText: this.createOnChangeRawTextArea(property).bind(this),
                onVarEditorCreated: () => this.updateHasUsedSomeUnsafeVariable(),
            },
            ...pickFormatVisibility(this.parameter),
            ...extraParameters,
        });
    }

    public createOnChangeRawTextArea(property: keyof PickByValueType<VarEditorComponent | ITextAndVariables, string>) {
        return (rawText: string): void => {
            this[property] = rawText;

            switch (property) {
                case 'rawText': {
                    this.updateHasUsedSomeUnsafeVariable();
                }
                    break;
            }
        }
    }

    public initRawTextHandler(): void {
        this.rawTextHandler = this.getTextAreaHandler('rawText', { placeholderText: 'Mensagem principal' });
    }
    public initFallbackHandler(): void {
        this.fallbackHandler = this.getTextAreaHandler('messageIfNoBind', { placeholderText: '' });
    }

    public hasInvalidText(): boolean {
        return this.rawTextHandler.hasInvalidText() || this.fallbackHandler.hasInvalidText()
    }

    public async init(): Promise<void> {
        this.isLoading = true;
        if (isValidRef(this.parameter.enableContextCanonicals)) this.injectContextVariables();

        if (isInvalid(this.parametersMessageIfNoBind)) this.parametersMessageIfNoBind = '';

        this.setVariables([
            ...(isValidArray(this.variables[EVarEditorEntityType.NonSerializable])
                ? await this.fetchNonSerializableVariableValues()
                : []
            ),
            ...(isValidArray(this.variables[EVarEditorEntityType.SchemaProperty])
                ? await this.fetchIdLocalCanonicalsOfSchemaProperties(this.variables[EVarEditorEntityType.SchemaProperty])
                : []),
        ]);

        this.initMessageIfNoBind();
        this.initRawTextHandler();
        this.initFallbackHandler();
        this.isLoading = false;
    }

    private setVariables(vars: TIEditorVariableArray) {
        this.allVariables = [...vars.sort((v1, v2) => v1.variable.localeCompare(v2.variable))];
        this.allSafeVariables = [];

        for (const variable of this.allVariables) {
            this.allVariablesSafeInfoCache[variable.idProperty] = this.isVariableSafe(variable);
            variable.isSafe = this.allVariablesSafeInfoCache[variable.idProperty];
            variable.tooltip = this.getTooltipForVariable(variable);

            if (variable.isSafe) {
                this.allSafeVariables.push(variable as IEditorSafeVariableClient);
            }
        }

        this.parameter.clientCallback.onAllVariablesLoaded?.(this.allVariables);
    }

    public async fetchNonSerializableVariableValues(): Promise<IEditorVariable[]> {
        const initialVariables: TIEditorVariableArray = this.variables[EVarEditorEntityType.NonSerializable];
        const mapInitialVariables = mapBy(initialVariables, item => item.idProperty);
        const nss: INonSerializable[] = (await this.lookupSvc.getBatchNonSerializables(initialVariables.map(variable => variable.idProperty)));
        this.updateMapIdNSToNS(nss);
        const variables: IEditorVariable[] = nss
            .map(ns => this.nsToVariable(ns))
            .map(item => (item.isSafe ||= mapInitialVariables.get(item.idProperty)?.isSafe, item))
            ;
        return variables;
    }

    //
    public nsToVariable(ns: INonSerializable): IEditorVariable {
        return {
            variable: this.addBracket(ns.nName),
            idProperty: this.removeBrackets(ns.idNS),
            isTagVariable: ns.nsType === ENonSerializableObjectType.colmeiaTags,
            canonical: ns.nsType === ENonSerializableObjectType.canonical && (ns as ILocalCanonical)
        };
    }

    public async fetchIdLocalCanonicalsOfSchemaProperties(variables: IEditorVariable[]): Promise<IEditorVariable[]> {
        const idsToFetch: string[] = variables.filter(v => isValidString(v.idLocalCanonical)).map(v => v.idLocalCanonical);
        const canonicals: ILocalCanonical[] = await this.getNSCache<ILocalCanonical>(idsToFetch);

        return variables.map((v) => {
            return {
                ...v,
                canonical: canonicals.find(c => c.idNS === v.idLocalCanonical)
            }
        });
    }

    public injectContextVariables(): void {
        const variables: Map<string, IEditorVariable> = new Map();

        for (let variable of (this.parameter.variables[EVarEditorEntityType.NonSerializable] || [])) variables.set(variable.idProperty, variable);

        for (let variable of (this.canonicalService.getCanonicalsVariables() || [])) {
            const isSafe = variables.get(variable.idProperty)?.isSafe || variable.isSafe;
            variables.set(variable.idProperty, {
                ...variable,
                isSafe,
            });
        }

        this.updateMapIdNSToNS(this.canonicalService.getCanonicalsFromPicker());

        this.variables[EVarEditorEntityType.NonSerializable] = [...variables.values()];
    }

    public get parameter(): IVarEditorHandlerParameter {
        return this.handler.getComponentParameter();
    }
    get parameters() {
        return this.parameter;
    }

    public set rawText(value: string) {
        this.parameter.rawText = value;

    }

    public get rawText(): string {
        return this.parameter.rawText
    }

    public get disableFallback(): boolean {
        return this.parameter.disableFallback;
    }

    get variablesIteration(): TIEditorVariableArray {
        const target: TIEditorVariableArray = isValidString(this.searchToken)
            ? this._searchVariablesArray
            : this.allVariables;

        const shouldFilter = this.canonicalFilter || this.tagFilter || this.schemaPropertyFilter;

        return shouldFilter
            ? target.reduce((result, item) => {
                const isSchemaProperty: boolean = this.isSchemaProperty(item);
                const isCanonical: boolean = this.isCanonical(item);
                const isTag: boolean = item.isTagVariable;

                if (this.canonicalFilter && isCanonical) {
                    result.push(item);
                } else if (this.schemaPropertyFilter && isSchemaProperty) {
                    result.push(item);
                } else if (this.tagFilter && isTag) {
                    result.push(item);
                }

                return result
            }, [])
            : target;
    }

    hasUsedSomeUnsafeVariable: boolean;

    public updateHasUsedSomeUnsafeVariable(): void {
        const slave: IVarEditorAreaSlaveCallback = this.rawTextHandler.getSlave();
        const mapVariableNameToVariable: Map<string, IEditorVariable> = new Map();
        for (let variable of this.allVariables) {
            mapVariableNameToVariable.set(this.removeBrackets(variable.variable), variable);
        }
        const usedVariables: IEditorVariable[] = slave.usedVariablesNames.map((name: string) => mapVariableNameToVariable.get(name)).filter(Boolean);
        const hasUsedSomeUnsafeVariable: boolean = usedVariables.some(variable => !this.isVariableSafe(variable));

        this.hasUsedSomeUnsafeVariable = isValidRef(this.rawTextHandler) && isValidRef(slave) && Boolean(hasUsedSomeUnsafeVariable);
    }

    public hasMessageIfNoBind(): boolean {
        return isValidRef(this.messageIfNoBind) 
        && isValidRef(this.fallbackHandler) 
        && this.hasUsedSomeUnsafeVariable 
        && !this.disableFallback;
    }

    public initMessageIfNoBind(): void {
        const reverseVariables: IEditorVariable[] = this.allVariables.map(variable => this.reverseVariable(variable));
        const messageIfNoBind: string = compileText(this.parametersMessageIfNoBind, reverseVariables, undefined, true).variablesTemplate.compiledTemplate;
        this.messageIfNoBind = messageIfNoBind;
    }

    public reverseVariable(variable: IEditorVariable): IEditorVariable {
        const reversedVariable: IEditorVariable = { variable: this.addBracket(variable.idProperty), idProperty: this.removeBrackets(variable.variable) };
        return reversedVariable;
    }


    get textCompiledDelimeters() {
        return textCompiledDelimeters;
    }

    public decompileMessage(message: string) {
        return compileText(message, this.allVariables, this.textCompiledDelimeters, true);
    }

    public set parametersMessageIfNoBind(value: string) {
        this.parameter.messageIfNoBind = value;
    }

    public get parametersMessageIfNoBind(): string {
        return this.parameter.messageIfNoBind
    }

    //


    public get hasParameters() {
        return isValidRef(this.handler) && isValidRef(this.parameter);
    }

    public removeBrackets(vr) {
        return removeBracket(vr);
    }

    public addBracket(vr): string {
        return addBracket(vr);
    }

    public errorMessage(message: string) {
        this.valid = false;
        this.snack.open({
            type: EAppAlertTypes.Error,
            message,
            duration: 4000,
        });
        return;
    }

    public insertVariable(editorVariable: IEditorVariable): void {
        const handlers: { [key in TSelectedTextareaHandler]: () => void } = {
            rawTextHandler: () => {
                this[this.selectedTextareaHandler as TSelectedTextareaHandler].getSlave().onVarEditorSelectVariable(editorVariable);
            },
            fallbackHandler: () => {
                if (this.isVariableSafe(editorVariable)) this[this.selectedTextareaHandler as TSelectedTextareaHandler].getSlave().onVarEditorSelectVariable(editorVariable);
                else this.errorMessage('Você não pode inserir uma variável insegura no texto de fallback')
            },
        };

        if (isInvalid(this.selectedTextareaHandler)) this.selectDefaultTextAreaHandler('rawTextHandler')

        this.updateHasUsedSomeUnsafeVariable();
        handlers[(this.selectedTextareaHandler)]();
    }

    public selectDefaultTextAreaHandler(handlerName: TSelectedTextareaHandler) {
        this.selectedTextareaHandler = handlerName;
    }

    public closeWithoutSave(): void {
        this.dialogRef.close();
    }

    public select(property: TSelectedTextareaHandler): void {
        this.selectedTextareaHandler = property;
    }

    public updateMapIdNSToNS(nss: INonSerializable[]): void {
        this.mapIdNSToNS.clear();
        for (let ns of nss || []) this.mapIdNSToNS.set(ns.idNS, ns)
    }

    public isVariableSafe(variable: IEditorVariable): boolean {
        const idNS: string = variable.idProperty;
        const ns: INonSerializable = this.mapIdNSToNS.get(idNS);

        if (variable.isSafe) return true;

        if (isInvalid(ns)) return false;

        if (serverLocalCanonicalGuard(ns)) {
            const globalCanonical = ns.globalCanonical;
            const isSafe: boolean = isMetadataSafe(globalCanonical);
            return isSafe || this.canonicalService.isCanonicalSafeOnConfig(ns);
        }

        if (serverColmeiaTagGuard(ns)) {
            return true;
        }

    }

    public allVariablesSafeInfoCache: { [idProperty: string]: boolean } = {};
    public get variables() {
        return this.parameter.variables;
    }
    public set variables(variables) {
        this.parameter.variables = variables;
    }

    public hasValidBBCode(): boolean {
        if (!BBCode.isValidText(this.rawText)) return false;
        if (!BBCode.isValidText(this.messageIfNoBind)) return false;
        return true;
    }

    public hasCharactersLimit(): boolean {
        return isValidRef(this.parameter.limitCharacters);
    }

    public hasBrokenCharactersLimit(): boolean {
        if (this.rawText.length > this.parameter.limitCharacters) return true;
        if (this.hasMessageIfNoBind() && this.messageIfNoBind.length > this.parameter.limitCharacters) return true;
        return false;
    }

    public save(): void {
        if (this.hasCharactersLimit() && this.hasBrokenCharactersLimit())
            return this.errorMessage(`Limite máximo de caracters quebrado (${this.parameter.limitCharacters})`);

        if (this.hasMessageIfNoBind() && !this.messageIfNoBind)
            return this.errorMessage(this.translations.fullfillFallBackMessage.value);

        if (!this.hasValidBBCode())
            return this.errorMessage('Código BBCode inválido');

        this.valid = true;

        const rawTextResult: ICompileVariablesResult = this.parameter.compileFunction(
            this.rawText,
            this.allVariables
        );

        const fallbackResult: ICompileVariablesResult = this.parameter.compileFunction(
            this.messageIfNoBind,
            this.allVariables
        );

        const variablesMap: Map<string, IUsedVariablesID> = new Map();

        for (let variable of rawTextResult.usedVariablesID) variablesMap.set(variable.idProperty, variable);
        for (let variable of fallbackResult.usedVariablesID) variablesMap.set(variable.idProperty, variable);

        const variables: IUsedVariablesID[] = [...variablesMap.values()].map(variable => ({ value: undefined, idProperty: variable.idProperty }));

        this.parametersMessageIfNoBind = fallbackResult.compiled;

        this.parameter.clientCallback.onVariableEditFinished(
            this.rawText,
            rawTextResult.compiled,
            variables,
            this.parameter.editorIdentity,
            fallbackResult.compiled
        );

        if (this.isDialog) this.dialogRef.close();
    }

    public buildSearchVariablesIteration() {
        this._searchVariablesArray = [...this.allVariables].filter(v => v.variable.toLowerCase().includes(this.searchToken.toLowerCase())).filter(v => isValidRef(v));
    }

    public getTooltipForVariable(vr: IEditorVariable): string {
        if (vr.isTagVariable) {
            return 'Tag do tipo variável'
        }

        return this.getCanonicalTooltipFor(vr);
    }

    public getCanonicalTooltipFor(vr: IEditorVariable): string {
        const isNotTheType: boolean = !this.isCanonical(vr);
        let suffix: string = isNotTheType ? vr.canonical?.nName + '' : '';

        if (isValidRef(vr.canonical?.globalCanonical)) {
            suffix += ' — Global: ' + this.translations[vr.canonical.globalCanonical].value;
        }
        return `Significado${isNotTheType ? ':' : ''} ${suffix}`.trim();
    }

    public isSchemaProperty(vr: IEditorVariable): boolean {
        return vr?.idProperty.length === 10 && !vr?.isTagVariable;
    }

    public isCanonical(vr: IEditorVariable): boolean {
        return vr.canonical && !this.isSchemaProperty(vr);
    }

    public hasSomeSchemaProperty(): boolean {
        return this.allVariables.some(v => this.isSchemaProperty(v));
    }

    public hasSomeTag(): boolean {
        return this.allVariables.some(v => v.isTagVariable);
    }

    public hasSomeCanonical(): boolean {
        return this.allVariables.some(v => this.isCanonical(v));
    }

    public getHighlighQuery(vr: string): string {
        return '[data-id="' + getNormalizedValue(vr) + '"]'
    }
}

