import { TGlobalUID } from "../../business/constant";
import { Merger } from "../../tools/utility/types/entities/merger";
import { IContentBasicAsset } from "../bot/bot-content-model";
import { IMetadataRegister } from "../metadata/meta-engagement";
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";



export interface ICRMIntegration {
    fieldName: string;
    idConnection: string;
}

export interface IIslandAccessibility {
    enableAccessibility: boolean;
    enableAudioMessage: boolean;
    enableVoiceTranscription: boolean; // customer -> agent
    enableVoiceTranscriptionFromAgentToCustomer: boolean;
}

export interface IIslandMessages {
    returnToQueue: IContentBasicAsset;
    timeRestriction: IContentBasicAsset;
    enqueueMessage: IContentBasicAsset;
    nextOnQueueMessage: IContentBasicAsset;
    onAttendanceBegin: IContentBasicAsset;
    onAttendanceEnd: IContentBasicAsset;
}

// Contas
export interface INamedAccountIslandConfig {
    applyRuleOnlyIfNotLogged: boolean; // Somente se Agente não logado
    contingenceAfterMinutes: number; // Contingência após XXX minutois
    // ACÃO
    whatToDo: {
        anyAgent: boolean;  // Envia para qualquer agente da Fila
        goToIsland: string; // Encaminha para a Ilha
    }

}

export enum EPriorizationType {
    prioritize = 'prioritize',
    deprioritized = 'deprioritized'
}

export interface IPriorityIsland {
    type: EPriorizationType; // Prioritizar, desprioritizar
    gradientMinutes: number;  // Gradiente (minutos) - (máximo 200)
    percentage: number;  // 0..100, inteiro  ==>   Utililzar a regra em % das alocações
}

export interface ICustomerQueuePrioritization {
    hasQueuePrioritization?: boolean;  // Prioridade
    prioritization?: IPriorityIsland;
}

export interface ICustomerReturning extends ICustomerQueuePrioritization {
    prioritizeSameAgentUntilHR: number; // Em retorno, priorizar o mesmo agente até
    applyToAllIsland: boolean; // Aplica a regra para qualquer ilha de origem
}

// Essa interface serve para mudanças temporárias da priorização da fila
export interface ITemporaryQueuePrioritization extends IPriorityIsland, ITemporaryQueuePrioritizationConfig {

}


export interface ITemporaryQueuePrioritizationConfig {
    validForMinutes: number;
    idIsland: IdDep<ENonSerializableObjectType.serviceIsland>;
}


export interface ITemporaryQueuePrioritizationConfigPayload extends ITemporaryQueuePrioritizationConfig {
    previous: IPriorityIsland | undefined;
    previousHasQueuePrioritization: boolean;
}

export interface IAttEngagementThresholds {
    untilMinutes: number;
    noColor?: boolean;
    color: `#${string}`;
    sound?: string;
    showNotificationDialog?: boolean;
    soundLoop?: boolean;
}

export type TIAttEngagementThresholdsArray = Array<IAttEngagementThresholds>

export interface IIslandAttEngagement {
    thresholds: TIAttEngagementThresholdsArray;
    beepWhenNewService: boolean;
    useVisualFeedback: boolean;
    visualFeedbackIntervalSeconds: number
}



export enum EIntegrationAllocationType {
    Automatic = 'a', // padrão
    Broadcast = 'b',
    pool = 'p'
}

export enum EBotRunningType {
    normal = 'normal',
    survey = 'survey'
}

export enum EIslandType {
    onlineColmeia = 'onlineColmeia', // Atendimento Online Colmeia
    crmColmeia = 'crmColmeia', // CRM Colmeia
    externalCrm = 'externalCRM',  // CRM Externo
    internalSupport = 'internalSupport', // Suporte Internal

}

export enum ERegisterMessageOnQueue {
    none = 'none', // nenhum,
    customer = 'customer', // Cliente
    both = 'both', // Cliente e Bot
}
export interface IServiceIsland extends INonSerializableHeader, ICustomerQueuePrioritization {
    idServiceGroup: TGlobalUID; // A partir desse grupo, serão criado todos os grupos para atendimento
    idISlandCommunicationGroup: TGlobalUID; // Normalmente um grupo de Broadcast
    islandType: EIslandType;
    allocationType: EIntegrationAllocationType;

    checkOnlineAgent?: boolean; // Proíbe entrada na fila caso não haja agentes on-line
    maximumCustomerOnQueue?: number; // Número máximo de clientes na fila

    transferForm: IMetadataRegister;
    servicePackId: string;
    crmIntegration?: ICRMIntegration;
    weight: number;
    firePercentage?: number;
    idAgentSatisfactionSurveyBot?: string;
    maxWeight: number;
    forceCloseAttendanceAfterDays?: number; // Em dias. 0 logo apRemove o acesso do atendente após o término
    idCalendar: string;
    isStopMessagingInactiveCustomerWhenAbsent?: boolean; // Parar de enviar mensagem quando agente em absente  
    eventsId: string;
    accessibility: IIslandAccessibility;
    goQueueIfRestrictionFound: boolean;
    useNamedAccount?: boolean;
    namedAccount?: INamedAccountIslandConfig;
    allowCustomerReturnControl?: boolean;
    customerReturnControl?: ICustomerReturning;
    allowAttEngagmentConfig?: boolean;
    attEngagmentConfig?: IIslandAttEngagement;
    disableSoundNewMsg?: boolean;
    registerWhenOnQueue?: ERegisterMessageOnQueue;
    visualizationControl?: IHistoricSegmentationControl;
}

export interface IHistoricSegmentationControl {
    allowedGroupID: TGlobalUID;
    attSeeOnlyOwnColab: boolean;
    visualizationControlScope: EHistoricSegmentationControl
    hideVisualizationToOtherIslands: boolean;
}

export enum EHistoricSegmentationControl {
    conversation = 'conversation',
    chat = 'chat'
}

export interface IIslandAttendant extends INonSerializableHeader {
    isActive: boolean;
    lastStatusClocktick: number;
    idAvatar: string;
    idParent: IdDep<ENonSerializableObjectType.serviceIsland>;
}


export type TServiceIslandArray = Array<IServiceIslandServer>;
export interface IServiceIslandServer extends IServiceIsland, INonSerializable { }

export interface IIslandAttendantServer extends Merger<IIslandAttendant, INonSerializable> {
    idNS?: IdDep<ENonSerializableObjectType.attendantAllowedAvatar>;
}

export interface IAvatarEmailResult {
    id_avatar: string;
    email: string;
    id_player: string;
};
