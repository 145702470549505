import { EventEmitter, Input, Output, SimpleChange } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UrlSegment } from '@angular/router';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { IMultimediaObjectJSON } from '@colmeia/core/src/comm-interfaces/multi-media-interfaces';
import { MultimediaObject } from '@colmeia/core/src/multi-media/multi-media-object';
import { BBCode } from '@colmeia/core/src/shared-business-rules/bbcode/bbcode-main';
import { removeBracket, textCompiledDelimeters } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { ENonSerializableObjectType, INonSerializableHeader } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { EColmeiaEnvironment, environmentBaseURL, EnvironmentBaseURLDefinition } from '@colmeia/core/src/shared-business-rules/production-deploy/prod-deploy-model';
import { ITranslationConfig, ITranslationHash } from '@colmeia/core/src/shared-business-rules/translation/translation-engine';
import { EScreenGroups } from '@colmeia/core/src/shared-business-rules/visual-constants';
import { secToMS } from '@colmeia/core/src/time/time-utl';
import { createIndexedSearch, EImplementAccessModifier, empty, getClock, getDeepTypedProperty, getTypedProperty, getUniqueStringID, isValidFunction, isValidRef, keys, mapBy, pickKeys, random, safeStringifyJSON, Swap, swap, typedCloneLodash, upperFirst, values } from '@colmeia/core/src/tools/utility';
import { Implement } from "@colmeia/core/src/tools/utility/functions/Implement";
import { $ValueOf, AcceptPromise, CheckExpression, Compute, IsEqual, IsMatch, IsPartial, UnionToIntersection } from '@colmeia/core/src/tools/utility-types';
import { getDomainOrigin } from '@colmeia/core/src/tools/utility/functions/get-domain-origin';
import { $$ } from '@colmeia/core/src/tools/utility/types/error';
import { Custom } from '@colmeia/core/src/tools/utility/types/generic';
import { environment } from 'environments/environment-client';
import * as _ from 'lodash';
import { mapValues } from 'lodash';
import { fromEvent, merge, Subject, Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { takeUntil } from 'rxjs/operators';
import { mapCurrentDashboardClient } from './dashboard/db/dashboard-db-mapper';
import { IMapCurrentDashboardClient } from './dashboard/db/dashboard-db-types';
import { routeList } from './routes/route-constants';
import { TSafeVarNameAndType } from 'app/components/dashboard/asset-adder/asset-adder.component';
import { EVarEditorEntityType } from 'app/handlers/var-editor.handler';
import { Translation } from 'app/components/foundation/translation/translation-helper';
import { Merger } from '@colmeia/core/src/tools/utility/types/entities/merger';



// para um parametro tipo /:idGroup e transforma em idGroup
export function getNormalizedParameter(param: string): string {
    return param.slice(2);
}

export function getDashboardScreenGroups(): EScreenGroups[] {
    return values(mapCurrentDashboardClient).map(item => item.screenGroup.name)
}

export namespace ClientTimeUtils {
    export function defineTo(hour: number) {
        return function to(time: number = getClock()): number {
            const d: Date = new Date(time);
            d.setHours(hour, 0, 0, 0);
            return d.getTime();
        }
    }

    export const to0 = defineTo(0);
    export const to24 = defineTo(24);
}


export function getMMObjectAnalogue(multimediaObject: MultimediaObject): MultimediaObject {
    const clone: IMultimediaObjectJSON = multimediaObject.toJSON();
    clone.primaryID = getUniqueStringID();
    const editingMMObject: MultimediaObject = MultimediaObject.factoryMessage(clone);
    return editingMMObject;
};

export const findOnDashboardClient = createIndexedSearch(values(mapCurrentDashboardClient) as IMapCurrentDashboardClient[], $ => [
    $.route,
    $.allowRules,
    $.screenGroup,
    $.defaultTag,
    $.primaryID,
    $.sharedRequests,
], { isDynamic: true })


export function ifServiceStatusSetEmptyRoute(route: string): '' | undefined {
    return route === routeList.dashboard.children.serviceStatus.path ? '' : undefined;
}
export function slowGetDashboardInfoFromPrimaryId(primaryID: string): IMapCurrentDashboardClient {
    return (Object.values(mapCurrentDashboardClient).find((item: IMapCurrentDashboardClient) => item.primaryID === primaryID));
}

const mapCurrentDashboardClientItems = values(mapCurrentDashboardClient) as IMapCurrentDashboardClient[];
const mapCurrentDashboardClientByRoute = mapBy(mapCurrentDashboardClientItems, item => item.route);

export function getDashboardInfoFromCurrentDashboard(currentDashboard: string): IMapCurrentDashboardClient {
    return mapCurrentDashboardClientByRoute.get(currentDashboard)!;
}

export function getTargetScreenGroup(fakeCurrentDashboard: string): EScreenGroups {
    const screenGroup: EScreenGroups = getDashboardInfoFromCurrentDashboard(fakeCurrentDashboard).screenGroup.name;
    return screenGroup;
}

export function getDashboardScreenGroupItemFromSegments(segments: UrlSegment[]): EScreenGroups {
    const [_dashboardPath, targetRoute, targetIdAngularRoute = ifServiceStatusSetEmptyRoute(targetRoute)]: string[] = segments.map((segment: UrlSegment) => segment.path);
    const screenGroup: EScreenGroups = getTargetScreenGroup(targetRoute);
    return screenGroup;
}

export function getAmountOfDaysInAMonth(): number {
    return new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate();
}



export function WatchComponent<Component>(definitions: { [key in keyof Component]?: (context: Component) => void; }) {
    return <Key extends keyof Component>(initialContext: Component, propertyKey: Key) => {
        const state: Partial<Component> = {};
        Object.defineProperty(initialContext, propertyKey, {
            get: () => state[propertyKey],
            set: (context) => {
                Object.defineProperties(context, mapValues(definitions, (fn, property) => ({
                    set: (value) => {
                        console.log(initialContext)
                        state[property] = value;
                        fn(context);
                    },
                    get: () => state[property],
                })))
            }
        });
        return undefined as Component[Key] extends Component ? void : unknown;
    };
}


export function Watch<T extends object>(fn: (context: T) => void) {
    return (target: T, key) => {
        const state: Partial<T> = {};

        Object.defineProperty(target, key, {
            get() {
                return state[key];
            },
            set(value) {
                state[key] = value;
                fn(this);
            }
        })
    }
}

export function watch<T extends object, Property extends ((keyof T) & string)>(source: T, property: Property, onChange: (value: T[Property], previousValue: T[Property]) => void) {
    const $property: unique symbol = Symbol(property);
    Object.defineProperty(source, property, {
        get() {
            return this[$property];
        },
        set(value: T[Property]) {
            const previousValue = this[$property];
            this[$property] = value;
            onChange(this[$property], previousValue);
        }
    })
}


export class $SimpleChange<T, Key extends keyof T> extends SimpleChange {

    constructor(
        public property: Key,
        public previousValue: T[Key],
        public currentValue: T[Key],
        public firstChange: boolean,
    ) {
        super(previousValue, currentValue, firstChange);
    }

    isFirstChange(): boolean {
        return this.firstChange;
    }
}

type OnChangeKey<Key extends string> = `onChange${Capitalize<Key>}`;
type GetOnChangeKey<Key extends string, ForceKey extends string> = OnChangeKey<string extends ForceKey ? Key : ForceKey>;

/**
 *
 * @example
 * * @OnChange()
 * * public isLoading: boolean = false;
 * *
 * * onChangeIsLoading(change: $SimpleChange<this, 'isLoading'>): void
 *
 */
export function OnChange<ForceKey extends string>(forceKey?: ForceKey) {
    return <T extends { [key in GetOnChangeKey<Key, ForceKey>]: (value: $SimpleChange<T, Key>) => AcceptPromise<void> }, Key extends (keyof T) & string>(target: T, key: Key) => {
        const onChangeProperty = `onChange${forceKey ?? upperFirst(key)}` as GetOnChangeKey<Key, ForceKey>;
        const property = Symbol(key);
        let firstChange: boolean = true;

        Object.defineProperty(target, key, {
            get() {
                return this[property];
            },
            set(value) {
                const shouldMakeSimpleChange: boolean = Boolean(this[onChangeProperty].length);
                let previousValue = shouldMakeSimpleChange
                    ? (typeof this[property] === 'function' ? this[property] : typedCloneLodash(this[property]))
                    : undefined
                    ;

                this[property] = value;
                let simpleChange: $SimpleChange<T, Key> | undefined;


                // It will make the parameter only if the function uses it
                if (shouldMakeSimpleChange) {
                    simpleChange = new $SimpleChange(
                        key,
                        previousValue,
                        value,
                        firstChange,
                    )
                }

                this[onChangeProperty]?.(simpleChange);
                firstChange &&= false;
            }
        })
    };
}

const getterPattern = /get(.*)/
function getPropertyOfGetter<Property extends string>(property: Property): GetPropertyOfGetter<Property>
function getPropertyOfGetter(property: string): string {
    return property.replace(getterPattern, (text, $1: string) => (`${$1.charAt(0).toLowerCase()}${$1.slice(1)}`));
}

type GetPropertyOfGetter<Property> = (Property extends `get${infer Property}` ? Uncapitalize<Property> : never)

export function Sync() {
    return function execute<Target extends { [key in GetPropertyOfGetter<Property>]: Value }, Property extends (keyof Target & string), Fn extends () => unknown, Value extends ReturnType<Target[Property] & Fn>>(target: Target, getterProperty: Property, descriptor: TypedPropertyDescriptor<Fn>) {
        const entityProperty = String(descriptor.value?.apply(getTypedProperty<Target>()));
        const property = String(getPropertyOfGetter(getterProperty));
        Object.defineProperty(target, property, {
            get: descriptor.value,
            set(value) {
                _.set(this, entityProperty, value);
            },
        });
    }
}

// export function GettersAndSetters<State extends {}>() {
//     return <T extends { prototype: {} }>(source: T) => {
//         type AddPrefix<T, Prefix extends string, Mapper extends Custom.Generic> = Compute<UnionToIntersection<$ValueOf<{ [key in keyof T]: { [$key in key as `${Prefix}${Capitalize<key & string>}`]: Custom.Chain<T[key], [Mapper]> } }>>>
//         interface IGetter extends Custom.Generic {
//             compute: () => (() => this['item'])
//         }
//         interface ISetter extends Custom.Generic {
//             compute: () => ((value: this['item']) => void)
//         }
//         type AddGetters<T> = AddPrefix<T, 'get', IGetter>
//         type AddSetters<T> = AddPrefix<T, 'set', ISetter>

//         type AddBoth<T> = Compute<AddGetters<T> & AddSetters<T>>
//         type $GettersAndSetters = AddBoth<State>

//         // Object.defineProperty(source.prototype, $state, {
//         //     get() {
//         //         console.log('get')
//         //         return this[$symbolState]
//         //     },
//         //     set(this: Source, value: State) {
//         //         this[$symbolState] = value;

//         //         console.log('change')
//         //         Object.keys(value).map(key =>
//         //             Object.defineProperty(
//         //                 this,
//         //                 key,
//         //                 ({ get() { return this.state[key] }, set($value) { this.state[key] = $value } })
//         //             )
//         //         )
//         //     }
//         // })

//         type Set<T, Modifier extends EImplementAccessModifier> = { [key in keyof T]: { isStatic: false; modifier: Modifier } }
//         return Implement<$GettersAndSetters, Set<State, EImplementAccessModifier.public> & Set<$GettersAndSetters, EImplementAccessModifier.private>>()<T>(source)
//     }
// }

export function SyncState<State extends {}>() {
    const $state = 'state'
    const $symbolState = Symbol($state)
    type Source = State & { [$state]: State; }
    return <T extends { prototype: {} }>(source: T) => {
        Object.defineProperty(source.prototype, $state, {
            get() {
                return this[$symbolState]
            },
            set(this: Source, value: State) {
                this[$symbolState] = value;

                Object.keys(value).map(key =>
                    Object.defineProperty(
                        this,
                        key,
                        ({ configurable: false, get() { return this.state[key] }, set($value) { this.state[key] = $value } })
                    )
                )
            }
        })
        return Implement<Source, { [key in (keyof Source)]: { isStatic: false; modifier: EImplementAccessModifier.public } }>()<T>(source)

    }
}

export function $SyncState() {
    return <T extends { prototype: Prototype }, Prototype extends {}, Property extends (keyof Prototype) & string>(source: T, property: Property, descriptor) => {
        const $state: Property = property
        const $symbolState = Symbol($state)

        type State = T['prototype'][Property]
        type Source = State & { [key in typeof $state]: State; }

        Object.defineProperty(source.prototype, $state, {
            get() {
                return this[$symbolState]
            },
            set(this: Source, value: State) {
                this[$symbolState] = value;

                Object.keys(value).map(key =>
                    Object.defineProperty(
                        this,
                        key,
                        ({ configurable: false, get() { return this.state[key] }, set($value) { this.state[key] = $value } })
                    )
                )
            }
        })
        return //Implement<Source, { [key in (keyof Source)]: { isStatic: false; modifier: EImplementAccessModifier.public } }>()<T>(source)

    }
}

function $defineModifier<Context extends { [key in Property]: Value }, Property extends string, Value, A extends unknown[], B>(input: (context: Context, property: Property) => (...a: A) => B, fn: () => Value) {
    let property: Property;
    const $fn = (new Function(`return ${fn.toString()}`))
    const init = (new Proxy(fn, {
        apply: (fn, inputContext: Context, params) => {
            if (!property) property = $fn.bind(pickKeys())()();

            console.log(inputContext)
            // @ts-expect-error
            return input(inputContext, property, params[0])(...params)
        }
    }))
    return init as unknown as (...a: A) => B
}

export const $getter = <A extends unknown[], B>(fn: (...a: A) => B) => $defineModifier((context, property) => () => (console.log({ context, property }), context[property]), fn)
export const $setter = <A extends unknown[], B>(fn: (...a: A) => B) => $defineModifier((context, property) => (value: typeof context[typeof property]) => { context[property] = value }, fn)


export function defineSetterFromGetter<Context extends {}, Value>(context: Context, fn: (this: Context) => Value) {
    const property = getDeepTypedProperty(fn);

    return function execute(value: Value) {
        _.set(context, property, value);
    }
}

export const variableRegex = new RegExp(`${textCompiledDelimeters.startCharacter}[^}]+${textCompiledDelimeters.endCharacter}`, "g");

export function highlightTemplate(content: string, secureVars: TSafeVarNameAndType = [], safeVarTooltipTitle: string = "Variável segura"): string {
    return BBCode.parseHTML(
        content.replace(
            variableRegex,
            (va) => {
                const safeVar = secureVars.find(vr => vr.varName === removeBracket(va));
                return `<span class="variable mat-chip mat-standard-chip${safeVar ? " is-safe" : ""}">${safeVar 
                        ? `<span class="legacy-tooltip">
                                ${safeVarTooltipTitle}
                            </span><span class="material-icons safe-icon">${getVarIcon(safeVar.varType)}</span>` 
                        : ""}${removeBracket(va)}</span>`;
            }
        )
    );
}
export function getVarIcon(varType: ENonSerializableObjectType) {
    switch (varType) {
        case ENonSerializableObjectType.canonical:
            return 'spellcheck';

        case ENonSerializableObjectType.colmeiaTags:
        return 'local_offer'; 

        default:
            return '';
    }
} 


export function getElementCenterOffset(target: HTMLElement): { offsetX: number, offsetY: number } {
    const { top, left, width, height } = target.getBoundingClientRect();
    return {
        offsetX: top + (height / 2),
        offsetY: left + (width / 2)
    }
}

export function getFilesFromDataTransfer(dataTransfer: DataTransfer): File[] {
    const response: File[] = [];
    if (dataTransfer.items) {
        for (let i = 0; i < dataTransfer.items.length; i++) {
            if (dataTransfer.items[i].kind === 'file') {
                response.push(
                    dataTransfer.items[i].getAsFile()
                );
            }
        }
    } else {
        for (var i = 0; i < dataTransfer.files.length; i++) {
            response.push(dataTransfer.files[i]);
        }
    }

    return response;
}

export class SubscriptionGroup {
    constructor(
        private destroyer$: Subject<void> = new Subject(),
        public destroyed$: Observable<void> = destroyer$.asObservable()
    ) { }

    from<T extends Subject<any> | Observable<any>, R = T extends Subject<any> ? ReturnType<T["asObservable"]> : T>(source: T): R {
        return source.pipe(takeUntil(this.destroyer$)) as unknown as R;
    }

    destroy() {
        this.destroyer$.next();
        this.destroyer$.complete();
    }
}

export class AnimationsFramesGroup {
    private framesSectors: Map<number | string, number> = new Map();
    constructor() { }

    public run(sector: number | string, fn: () => void) {
        if (this.framesSectors.has(sector)) {
            window.cancelAnimationFrame(this.framesSectors.get(sector));
        }

        this.framesSectors.set(sector, window.requestAnimationFrame(() => {
            fn();
            this.framesSectors.delete(sector);
        }));
    }

    public runAsync(sector: number | string): Promise<void> {
        if (this.framesSectors.has(sector)) {
            window.cancelAnimationFrame(this.framesSectors.get(sector));
        }

        return new Promise((resolve) => {
            this.framesSectors.set(sector, window.requestAnimationFrame(() => {
                resolve();
                this.framesSectors.delete(sector);
            }));
        });
    }

    public clearAll() {
        for (const [, frame] of this.framesSectors) {
            window.cancelAnimationFrame(frame);
        }
    }
}

export function mergeDomEvent(target: HTMLElement, events: string[]): Observable<Event> {
    return merge(...events.map(ev => fromEvent(target, ev)));
}

export type IPoint = { x: number, y: number };

export type TMoveEventsOfCallback = {
    start?: (event: MouseEvent | TouchEvent) => void;
    move?: (event: MouseEvent | TouchEvent, pointerTranslation: IPoint) => void;
    end?: (event: MouseEvent | TouchEvent) => void;
}

export function getOffsetOfPointerEvent(event: MouseEvent | TouchEvent): IPoint {
    let point: IPoint = { x: 0, y: 0 };

    if (event instanceof MouseEvent) {
        point = { x: event.clientX, y: event.clientY };
    }

    // In Firefox, TouchEvent is only available on mobile version
    if (isValidRef(TouchEvent) && event instanceof TouchEvent) {
        const touch = event.touches[0];
        point = { x: touch.clientX, y: touch.clientY };
    }

    return point;
}

function callMoveCB(cb: Function, ...args: [Event] | [MouseEvent | TouchEvent, IPoint]) {
    if (!isValidFunction(cb)) return;

    if (args.length === 1) {
        cb(args[0])
    } else {
        cb(args[0], args[1]);
    }
}

export function moveEventsOf(target: HTMLElement, cb: TMoveEventsOfCallback): Subscription {
    const startPointCoordinates: IPoint = { x: 0, y: 0 };
    let moving: boolean = false;
    let moveSubscription: Subscription;
    let endSubscription: Subscription;

    const startSubscription = mergeDomEvent(target, ["mousedown", "touchstart"]).subscribe((startEvent: MouseEvent | TouchEvent) => {
        startEvent instanceof MouseEvent && startEvent.stopPropagation();
        if (moving) return;
        moving = true;

        if (moveSubscription) return;

        const { x, y } = getOffsetOfPointerEvent(startEvent);

        startPointCoordinates.x = x;
        startPointCoordinates.y = y;

        callMoveCB(cb.start, startEvent);

        if (!moveSubscription)
            moveSubscription = mergeDomEvent(window.document.documentElement, ["mousemove", "touchmove"]).subscribe((moveEvent: MouseEvent | TouchEvent) => {
                moveEvent instanceof MouseEvent && moveEvent.preventDefault();
                moveEvent.stopPropagation();

                const point: IPoint = { x: 0, y: 0 };
                const { x, y } = getOffsetOfPointerEvent(moveEvent);

                point.x = x - startPointCoordinates.x;
                point.y = y - startPointCoordinates.y;

                callMoveCB(cb.move, moveEvent, point);
            });


        if (!endSubscription) {
            endSubscription = mergeDomEvent(window.document.documentElement, ["mouseup", "touchend"]).subscribe((endEvent: MouseEvent | TouchEvent) => {
                endEvent instanceof MouseEvent && endEvent.preventDefault();
                endEvent.stopPropagation();

                moveSubscription.unsubscribe();
                endSubscription.unsubscribe();

                moveSubscription = undefined;
                endSubscription = undefined;

                callMoveCB(cb.end, endEvent);
                moving = false;
            });
        }
    });

    return startSubscription;
}

export class ExpirableEvent {
    public readonly expireTS: number

    constructor(duration: number = secToMS(10)) {
        this.expireTS = Date.now() + duration
    }

    public get expired(): boolean {
        return this.expireTS < Date.now();
    }
}

function getSafeStringValue(input: any) {
    return safeStringifyJSON(input);
}

export function detectSimpleChange(change: SimpleChange) {
    if (!change) return false;
    if (change.firstChange) return true;

    return getSafeStringValue(change.previousValue) !== getSafeStringValue(change.currentValue);
}


export function fillTemplateForm<T>(group: UntypedFormGroup, source: T): void {
    for (const key in source) {
        const field: AbstractControl = group.get(key);
        if (field) {
            field.setValue(source[key]);
        }
    }
}


export function highlightNSMapName(source: string, color: string = '#4dd165'): string {
    return `<span style="font-weight: bold;">-</span> <span style="color: ${color}; font-weight: bold;">${source}</span>`;
}

export const preventKeyboardKeys: string[] = ["Escape", "Shift", "Control", "Meta", "Alt", "AltGraph", "ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft", "CapsLock"];



function createElement<Props, Children extends unknown[]>(tag: string, props: Props, ...children: Children) {

}

export function convertObjArrayToCSVLines(array: object[], separator: string): string {
    let str = '';

    array.forEach(arrItem => {
        let line = '';

        values(arrItem).forEach((v) => {
            if (line != '') line += separator
            line += `"${((v as string)?.replaceAll('"', '""') || '')}"`;
        });

        str += line + '\r\n';
    });

    return str;
}

export function exportCSVFile<T extends object = object>(headers: T, items: T[], fileName: string, separator: string = ';') {

    items.unshift(headers);

    const csv = convertObjArrayToCSVLines(items, separator);
    const exportedFilenmae = fileName || 'export';
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");

    if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}

export async function openURL(url: string) {
    const link = document.createElement("a");

    if (link.download !== undefined) {
        link.setAttribute("href", url);
        link.setAttribute("target", "_blank");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export function identJSONString(value: string | object | any[], spaceSize: number = 2) {
    return typeof value === "string"
        ? JSON.stringify(JSON.parse(value), null, spaceSize)
        : JSON.stringify(value, null, spaceSize);
}

// https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color
export function getContrastColorFor(hex: string): string {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // https://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
        ? '#000000'
        : '#FFFFFF';
}


type DomainToEnvironment = Swap<EnvironmentBaseURLDefinition>;
function mapSanitizedEnvs(): DomainToEnvironment {
    const envs: EnvironmentBaseURLDefinition = { ...environmentBaseURL };
    keys(envs).forEach(env => envs[env] = getDomainOrigin(envs[env]));
    return swap(envs);
}

export function getCurrentEnvironmentName(): EColmeiaEnvironment {
    console.log({environment})
    const domain = environment.ROOT_URL;
    return mapSanitizedEnvs()[domain];
}


export namespace HandlerMaker {
    type SanitizeOutput<Item> = (Item extends EventEmitter<infer Value> ? ((value: Value) => void) : Item)

    function CreateMaker<Key extends PropertyKey>(targets: { [key in Key]: unknown }) {
        return <T>() => targets as { [key in Key]: Make<T> };
    }
    type MakerItem<T extends {}, TKey extends keyof T, ForceOptional extends boolean = boolean> = <
        Component extends {},
        ShouldBePartial extends IsEqual<ForceOptional, boolean> extends false ? ForceOptional : IsPartial<Component, Key>,
        Key extends keyof Component,
        Value extends SanitizeOutput<Component[Key]>,
        MustBe extends ShouldBePartial extends true ? { [key in FKey]?: Value } : { [key in FKey]: Value },
        FKey extends PropertyKey = [keyof T] extends [TKey] ? Key : TKey,
    >(target: Component, property: Key) => CheckExpression<IsMatch<T, MustBe> & IsMatch<IsPartial<T, Extract<FKey, keyof T>>, ShouldBePartial>> extends true ? never : $$<['Key', Key, 'must be', MustBe, 'on', T, 'type']>;;


    interface Make<T extends {}> {
        <TKey extends keyof T>(key?: TKey): MakerItem<T, TKey>;
        <ForceOptional extends boolean>(): MakerItem<T, keyof T, ForceOptional>;
    }

    export const Execute = <T>() => {
        const output = CreateMaker({
            Input,
            Output
        })<T>();

        return {
            ...output,
            CheckProperties: CheckProperties<T>()
        }

    }

    export function CheckProperties<T>() {
        return () => function execute<Component extends { prototype: { [key in keyof T]: unknown } }>(component: Component):
            [Exclude<keyof T, keyof Component['prototype']>] extends [infer Missing] ?
            [Missing] extends [never] ?
            never
            : $$<['Missing', Missing, 'on', Component]>
            : never {
            return empty;
        }
    }
}


export function loadScript(src) {
    return new Promise(function (resolve, reject) {
        var s;
        s = document.createElement('script');
        s.src = src;
        s.onload = resolve;
        s.onerror = reject;
        document.head.appendChild(s);
    });
}

export async function esTranspile(code: string, options: any): Promise<{ code: string }> {
    return (window as any).esbuild.transform(code, { loader: 'ts', tsconfigRaw: JSON.stringify(options), })
}

export function evaluateHTML(htmlMarkup: string): HTMLElement {
    const container = document.createElement('div');
    container.innerHTML = htmlMarkup;
    return container;
}

export function isElementNode(node: Node): node is HTMLElement {
    return node.nodeType === Node.ELEMENT_NODE;
}

export function TempNS<NS extends INonSerializableHeader>() {
    return function decorator<T extends { [key in Property]?: NS }, Property extends (keyof T & string)>(target: T, key: Property) {
        const property = Symbol(key);

        Object.defineProperty(target, key, {
            set(value: T[Property]) {
                return Reflect.set(this, property, value);
            },
            get() {
                const out = this[property]
                Reflect.set(this, property, undefined);
                return out;
            },

        })
    }
}

export type TMatDialogRef<T = any, R = any> = MatDialogRef<T, R>;

export class SetList<T> extends Set<T> {
    get length() {
        return this.size;
    }
}


export function Translate<T, Property extends (keyof T & string), Translations extends ITranslationHash>(target: T, key: Property) {
    const property = Symbol(key);

    Object.defineProperty(target, key, {
        set(value: Translations) {
            for (const key in value) {
                value[key] = {
                    ...value[key],
                    value: Serializable.getTranslation(value[key]),
                    toString(): string | undefined {
                        return this.value;
                    }
                }
            }
            return Reflect.set(this, property, value);
        },
        get() {
            const out = this[property]
            return out;
        },
    });

}

export class TranslationString extends String implements ITranslationConfig {
    constructor(
        public serializableId: string,
        public idField: number,
        public value: string = '',
        public language?: string,
    ) {
        super(value);
    }

    toString(): string {
        return this.value;
    }
}



export function translationsOf<Keys extends string, Translations extends ITranslationHash<Keys>>(value: Translations) {
    return mapValues(value, config => {
        const value = Serializable.getTranslation(config);
        return new TranslationString(config.serializableId, config.idField, value);
    });
}

export function createServiceLogger(target: object | string, color?: string): (...args: any[]) => void {
    const h = random(360, 0);
    const s = random(100, 50);
    const l = 70;

    color ||= `hsl(${h},${s}%,${l}%)`;

    const title = typeof target === 'string' ? target : target.constructor.name;
    const titleStyle = `background: #000; color: ${color};font-weight: bold;border-radius: 4px;`;
    const titleFormated = `%c ${title} \n`;

    const logger = console.log.bind(undefined, titleFormated, titleStyle);
    logger.warn = console.warn.bind(undefined, titleFormated, titleStyle);
    logger.error = console.error.bind(undefined, titleFormated, titleStyle);
    logger.info = console.info.bind(undefined, titleFormated, titleStyle);

    return logger;
}
