import { IAPIExecutionInjectionClientBasic } from '@colmeia/core/src/shared-business-rules/user-function/user-function-model';
import { TGlobalUID } from '../business/constant';
import { EUnitTypeID } from '../business/constant.enums';
import { I360Media, T360MediaArray } from '../core-constants/bot';
import { TIdProperty } from '../core-constants/types';
import { IRequest } from '../request-interfaces/request-interfaces';
import { IResponse } from '../request-interfaces/response-interfaces';
import { TCatalogField } from '../shared-business-rules/catalog/catalog.interfaces';
import { ICatalogTypeFlag } from '../shared-business-rules/files/files';
import { TGraphPropertyData } from '../shared-business-rules/graph/essential/graph-types';
import { IMetaEngagement } from '../shared-business-rules/metadata/meta-engagement';
import { EMetadataNames } from '../shared-business-rules/metadata/metadata-db';
import { TComputedInfo } from '../shared-business-rules/metadata/metadata-utils';
import { ENonSerializableObjectType, INonSerializable, INonSerializableMedia } from '../shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '../shared-business-rules/non-serializable-id/non-serializable-types';
import { ICoordinates } from '../tools/geo-util';
import { getTypedProperty, isInvalid, isValidRef } from '../tools/utility';
import { IFormChoice, SchemaProperty } from './general-form-interface';

export type TGeneralFormAnswerArray = Array<IGeneralFormAnswer>;


export interface IGeneralFormAnswer extends ICatalogTypeFlag {  // Client
    primaryID: TGlobalUID;
    idSchemma: string;
    responses?: TGeneralFieldArray;
    text?: string;
    json: IGeneralFormAnswerJson;
    finished?: boolean;
    hasCanonical?: boolean;
    partial?: string; // salva um texto mostrando se foi abandonado parcialmente
    idContentGenerator?: IdDep<ENonSerializableObjectType.contentGenerator>;
};

export interface ISaveFormAnswer {
    formAnswer: IGeneralFormAnswer,
    idNS?: TGlobalUID,
}

export type TGeneralFormFieldAnswerValue = string | number | IGeneralFormAnswerJson | TGeneralFormFieldAnswerValue[];

export type IGeneralFormAnswerJson = {
    [propertyName in string]: TGeneralFormFieldAnswerValue
}

export type TGeneralFormServerAnswerArray = Array<IGeneralFormAnswerServer>;

export interface IGeneralFormAnswerServer extends INonSerializable, IGeneralFormAnswer {
    idConversation?: string;
    isShakeHands?: boolean;
    idSocialKey?: string,
    address?: string;
    idNS?: IdDep<ENonSerializableObjectType.clientGeneralAnswer>;
}


export type TIRFieldResponseBasis = Array<IRFieldResponseBasis>
export interface IRFieldResponseBasis {
    idGlobalCanonical: EMetadataNames;
    raw: string;
}

export type TGeneralFieldArray = Array<IRFieldResponse>;

export interface IRFieldResponseBasic {
    idProperty: TIdProperty;
    value: TGraphPropertyData;
}


export interface ICoreCommonAddressInfo { // São os campos comuns as consultas a APIs de endereços Google e ViaCEP (@colmeai/bot -> ICommonAddressInfo)
    streetNumber: string | undefined;
    street: string | undefined;
    neighborhood: string | undefined;
    city: string | undefined;
    country: string | undefined;
}

export interface IAddressOnField {
    geoLocation?: ICoordinates; // Se o usuário mandou geo, coloque aqui
    googleIDPlace?: string; // mesmo caso acima.. só que no caso da consulta de endereco autoComplete, o GPS não vem
    ZIP?: string;  // Aqui pega o CEP
    commonFields?: Partial<ICoreCommonAddressInfo> // Campos retornados pela busca separados por tipo
}

export interface IRFieldResponse extends INonSerializableMedia, IRFieldResponseBasis, IRFieldResponseBasic, IAddressOnField {
    row?: number;

    clockTick?: number; // preenchido em caso de que seja uma data
    offset?: number; // preenchido em caso de que seja uma data
    idLocalCanonical: string;
    propertyName?: string;

    isLGPDSensitive?: boolean;

    answClock?: number; // A hora que foi preenchido o campo no bot

    // Quando em um corporate search, pode se ter o display diferente do que irá retornar efetivamente ao form
    // Neste caso, guardamos o ID aqui
    returningID?: string;

    lineVersion?: string; // Para fazer a carga incremental de um arquivo para o CorporateSearch

    isNotVisibleByAgent?: boolean;

    wasIgnored?: boolean; // Pergunta foi ignorada devido a regras de visualização condicional

    processedByFlow?: boolean;
    // resposta Multipa do WAF
    multipleChoice?: Array<string>

    optionalWasNull?: boolean;
}

export interface IGetSerializableAnswerRequest extends IRequest {
    primaryID: TGlobalUID;
    cursor: string;
    hasSecurityFilter?: boolean;
}

export interface IGetSerializableAnswerResponse extends IResponse {
    responses: TGeneralFormServerAnswerArray;
    cursor: string | undefined;
    allFieldMap?: TComputedInfo;
    ignoredBySecurity?: number;
}


export interface IGeneralFormAnswerSaveResponse extends IResponse {
    answer: IGeneralFormAnswerServer
};

export interface IGeneralFormAnswerSaveRequest extends IRequest {
    idNS?: TGlobalUID;
    form: IGeneralFormAnswer;
    idConversation?: string;
    engagement?: IMetaEngagement;
    dynamicListDatabaseID?: string
    aditionalAttendenceInfo?: IAPIExecutionInjectionClientBasic
};


/// @JOY o que é essa classe, perguntar ao GABRIEL
export class GeneralFormField implements SchemaProperty, IRFieldResponse {

    isNotVisibleByAgent?: boolean
    idProperty: string;
    propertyName: string;
    idUnity: EUnitTypeID;
    prompt: string;
    // metadata?: EMetadataNames;
    nestedSchema?: SchemaProperty[];
    multipleAnswers?: boolean;
    choices?: IFormChoice[];
    medias?: T360MediaArray;

    idLocalCanonical: string;
    idGlobalCanonical: EMetadataNames;
    canDisplayField: boolean;

    offset?: number;
    clockTick?: number;


    row: number;

    raw: string;

    // track individual value
    value: any;
    // track answers for field that are multiple (field.multipleAnswers && field.isAnswer)
    isAnswer: boolean = false;

    children: GeneralFormField[];

    constructor(
        field: SchemaProperty,
        value?: any,
        answer?: IRFieldResponse
    ) {
        Object.assign(this, field);

        if (isValidRef(answer))
            Object.assign(this, answer)
                ;

        this.children = [];
        this.value = value;
    }



    createChild(field: SchemaProperty = null, isAnswer: boolean = false, value?: any): GeneralFormField {
        if (field === null) {
            field = this.toSchemaProperty();
        }

        const child = new GeneralFormField(field, value);
        child.isAnswer = isAnswer;
        this.addChild(child);
        return child;
    }

    isMultipleFieldRoot() {
        return this.multipleAnswers && !this.isAnswer;
    }

    addChild(child: GeneralFormField) {
        this.children.push(child);
    }

    deleteChild(child: GeneralFormField) {
        const index = this.children.indexOf(child);
        this.children.splice(index, 1);
    }

    toSchemaProperty(): SchemaProperty {
        const schemaProperty: SchemaProperty = {
            idProperty: this.idProperty,
            propertyName: this.propertyName,
            idUnity: this.idUnity,
            multipleAnswers: this.multipleAnswers,
            prompt: this.prompt,
        };

        [
            // getTypedProperty<SchemaProperty>().metadata, // deleted
            String(getTypedProperty<SchemaProperty>().nestedSchema),
            String(getTypedProperty<SchemaProperty>().choices)
        ].forEach(prop => {
            if (isValidRef(this[prop])) {
                schemaProperty[prop] = this[prop];
            }
        });

        return schemaProperty;
    }

    toFieldResponse(): IRFieldResponse {
        if (this.children.length > 0) {
            return null;
        }

        let value = this.value;

        if (this.idUnity === EUnitTypeID.logicalType && isInvalid(value)) {
            value = false;
        }

        const result: IRFieldResponse = {
            idProperty: this.idProperty,
            value,
            raw: value,
            medias: this.medias,
            idGlobalCanonical: this.idGlobalCanonical,
            idLocalCanonical: this.idLocalCanonical,
            clockTick: this.clockTick,
            offset: this.offset,
        }

        if (isValidRef(this.row)) {
            result.row = this.row;
        }

        return result;
    }

    toFieldResponseArray(): TGeneralFieldArray {
        if (this.children.length === 0) {
            return [this.toFieldResponse()];
        }

        return this.children.reduce((acc, child) => {
            acc.push(...child.toFieldResponseArray());
            return acc;
        }, []);
    }

    toValue(): TGeneralFormFieldAnswerValue {
        if (this.isMultipleFieldRoot()) {
            return this.children.map(child => child.toValue());
        }

        if (this.value) {
            return this.value;
        }


        if (this.idUnity === EUnitTypeID.objectType) {
            return reduceFieldsToJson(this.children);
        }

        return undefined;
    }
}

// export function reduceFieldsToJson(fields: GeneralFormField[]): IGeneralFormAnswerJson {
//     return fields.reduce((acc, field) => {
//         acc[field.propertyName] = field.multipleAnswers ? (acc[field.propertyName] ?? []).concat(...field.toValue() as unknown[]) : field.toValue();
//         return acc;
//     }, {});
// }

export function reduceFieldsToJson(fields: GeneralFormField[]): IGeneralFormAnswerJson {
    return fields.reduce((acc, field) => {
        acc[field.propertyName] = field.toValue();
        return acc;
    }, {});
}

export function generalFormAnswerJsonToFields(json: IGeneralFormAnswerJson): GeneralFormField[] {
    return null;
}
