

import {EAvailablePrebuiltEntity, EMLEntityType, EMLLuisTrainResultType, EOmniSenseMethods, IKBAppConfig} from "../../knowledge-base/luis-core-interfaces";
import { MetaTranslation } from '@colmeia/core/src/shared-business-rules/const-text/meta-translation';
import { defineTranslations, EAllSerializable, getTranslationsFields, getTranslationsFieldsAndScreenOptions } from "../all-serializables";
import {kbPredictItems} from "../../visual-constants";
import { createHashFromEntries, createHashFromEntriesV2, createMapFromEntries, formatMessage, IdField, typedEntryFields, typedFields, typedScreenOptions } from "../../../tools/utility";
import { genericTypedSuggestions, deprecatedSuggestions, suggestions } from "../../../tools/type-utils";
import { constant } from "../../../business/constant";


export const coordinatorDeletionMenu = defineTranslations({
    idSerializable: EAllSerializable.CoordinatorDeletion,
    fields: {
        coordinatorDeletionTitle: MetaTranslation.field(constant.serializableField.name, 'Deleção de coordinator'),
        coordinatorDeletionIcon: MetaTranslation.field(constant.serializableField.icon, 'Deleção de coordinator'),
    },
});

export const coordinatorDeletionTranslations = {
    ...coordinatorDeletionMenu,
};
