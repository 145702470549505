import { ECatalogType, TCatalogField } from '@colmeia/core/src/shared-business-rules/catalog/catalog.interfaces';
import { emailPattern } from '../../core-constants/types';
import { mergePatterns } from '../../tools/type-utils';
import { getDeepTypedProperty } from '../../tools/utility/functions/getDeepTypedProperty';
import { Join, MetaGetDeepTypedProperty, Narrow, RequiredPick } from '../../tools/utility-types';
import { MapDiscriminatedUnion, ValueOf } from '../../tools/utility/types';
import { Meta } from '../catalog/meta-interface';
import { sanitizeEmail } from '../const-text/views/canonical';
import { getNormalizedCellphone } from '../social-cc/config-cell';
import { IGeneralMetadata, IMetadaJSON, IGeneralMetadataCatalogConfigField, IGeneralMetadataCatalogConfigFieldGeneric, MetaCatalogProductFields } from './metadata-interface';
import { PickKeys } from '../../tools/utility';
import { EUnitTypeID } from '../../business/constant.enums';


export type TMetadataNameArray = Array<EMetadataNames>;

export enum EMetadataNames {
    // Fundamentais
    email = 'metaEmail',
    name = 'metaName', // 

    // @fe esses tres
    customerProvideName = 'cusProvName', // Nome do cliente fornecido pelo Canal
    channelAddress = 'channelAddress', // Identificador Canal (Ex: telefone whatsapp)
    channelId = 'channelId', //  ID único do Canal (NonSerializable)
    channetlType = 'channelType', // Tipo de Canal (whatsApp, telegram, FB MEssenger)
    customerAddress = 'cusAddress', // ID canal do cliente (ex: email, celular, FB ID, Colmeia ID, etc..)

    cellular = 'metaCel',
    instagramID = 'metaInsta',
    facebookID = 'metaFace',
    telegramID = 'telegramID',
    colmeiaID = 'colmID', // ID único identificando o cliente no canal colmeoa

    // Fluxo e conteúdo
    botDestiny = 'metaBotDest',
    openMediaLink = 'metaImgLink', // Link media
    crmTypeOfCase = 'typeCase', // Indica no formulário qual o tipo de caso que será integrado
    errorMessage = 'metaErrMsg',
    attendentID = 'attID',  // ID do atendente para Named Account,
    urlShakingHands = 'urlSH',  // Shaking Hands passado através da URL

    // estado Interno
    envInFrontOfMe = 'envInFrontOfMe', // safe
    lastUserInput = 'lastUserInput', // safe
    envDispatch = 'envDispatch', // safe
    attendent = 'mAtt',
    envGreetingDaily = 'envGreetingDaily',  // safe
    client = 'mClient', // Esse qui se fere ao nome do cliente mas apenas NO CLIENT web. 

    logConversation = 'logConversation', // PlaceHolder para colocar o conteúdo da conversação

    // Auxiliares para Organização e futuro uso
    fullAdress = 'metaAdress',
    mainDoc = 'metaNID',  // Documento Principal
    idUser = 'metaIdUser',
    textMessage = 'metaTxtMsg', // aqui a idéia é colocar a última mensagem do cliente
    date = 'mDate',
    optOut = 'optOut', // No começo do bot colocar o estado do optOut dele

    GPS = 'geolocationCoord',
    ZipCode = 'ZipCode',

    // Nested Form Contro
    nestedFormToGo = 'nestedFormToGo',
    nestedFormCompleted = 'nestedFormCompleted',

    ProductId = 'prdId',
    ProductName = 'prdName',
    ProductCategory = 'prdCategory',
    ProductCurrency = 'prdCurrency',
    ProductImageURL = 'prdImageURL',
    ProductPrice = 'prdPrice',
    ProductDescription = 'prdDescription',
    ProductURL = 'prdURL',
    ProductGTIN = 'prdGTIN',

    firstMessage = 'prdFirstMsg',

    // CRM
    numberOfOpenTickets = 'numberOfOpenTicket',
    ticketTitle = 'tckTitle',
    ticketProtocol = 'tckProtcl',
    ticketSeverity = 'tckSevty',
    ticketUrgency = 'tckUrgcy',
    ticketSupportLevel = 'tckSupLvl',
    ticketServicePhase = 'tckServPhs',
    ticketCloseState = 'tckClsStt',
    ticketTicketState = 'tckStt',
};

export type ENaturalCanonicals = EMetadataNames.customerProvideName |
    EMetadataNames.channetlType | EMetadataNames.channelAddress


export function createGlobalCanonicalHash<T extends { [key in EMetadataNames]?: true }>(hash: T) {
    const allHash: { [key in EMetadataNames]?: boolean } = {};
    for (let key in EMetadataNames) allHash[key] = Boolean(hash[key]);

    return allHash as {
        [key in (keyof T) | Exclude<EMetadataNames, keyof T>]: T[key] extends true ? true : false
    };
}


declare const $GeneralMetadata: PickKeys<IGeneralMetadata>;
export type TAllMetadata =
    & MapDiscriminatedUnion<IMetadaJSON, typeof $GeneralMetadata.idMetadata>
    & { [key in EMetadataNames]: (key extends TCatalogField ? RequiredPick<IGeneralMetadata, 'catalogConfig'> : unknown) }
    ;

/**
 * metaGetDeepTypedProperty<{ a: { b: { c: 4 } } }>()($ => $.a.b.c) => 'a.b.c'
 * @returns string
 */
export function metaGetDeepTypedProperty<T>() {
    type $Proxy = MetaGetDeepTypedProperty.MapDeepTypedProperty<T, {
        IsReplacingValuesWithDeepPaths: true;
    }>;

    function execute<ReturnType>(call: (entity: $Proxy) => Narrow<ReturnType>): [ReturnType] extends [string] ? ReturnType : ReturnType extends MetaGetDeepTypedProperty.MapDeepTypedProperty<infer Entity, infer K, infer Values> ? Join<Values, '.'> : never
    function execute<ReturnType>(call: (entity: $Proxy) => Narrow<ReturnType>): unknown {
        return getDeepTypedProperty(call);
    };
    return execute
}

function defineCatalogConfig<Key extends MetaCatalogProductFields>(config: IGeneralMetadataCatalogConfigFieldGeneric<Key>) {
    return config;
}

function defineAllMetadata() {
    const allMetadata: TAllMetadata = ({
        [EMetadataNames.firstMessage]: {
            idMetadata: EMetadataNames.firstMessage,
        },
        [EMetadataNames.ProductId]: {
            idMetadata: EMetadataNames.ProductId,
            catalogConfig: {
                [ECatalogType.Meta]: defineCatalogConfig({
                    key: metaGetDeepTypedProperty<Meta.Catalog.Product>()($ => $.retailer_id),
                    type: EUnitTypeID.stringType,
                }),
            },
        },
        [EMetadataNames.ProductName]: {
            idMetadata: EMetadataNames.ProductName,
            catalogConfig: {
                [ECatalogType.Meta]: {
                    key: metaGetDeepTypedProperty<Meta.Catalog.Product>()($ => $.name),
                    type: EUnitTypeID.stringType,
                },
            },
        },
        [EMetadataNames.ProductCategory]: {
            idMetadata: EMetadataNames.ProductCategory,
            catalogConfig: {
                [ECatalogType.Meta]: {
                    key: metaGetDeepTypedProperty<Meta.Catalog.Product>()($ => $.category),
                    type: EUnitTypeID.stringType,
                },
            },
        },
        [EMetadataNames.ProductCurrency]: {
            idMetadata: EMetadataNames.ProductCurrency,
            catalogConfig: {
                [ECatalogType.Meta]: {
                    key: metaGetDeepTypedProperty<Meta.Catalog.Product>()($ => $.currency),
                    type: EUnitTypeID.stringType,
                },
            },
        },
        [EMetadataNames.ProductImageURL]: {
            idMetadata: EMetadataNames.ProductImageURL,
            catalogConfig: {
                [ECatalogType.Meta]: {
                    key: metaGetDeepTypedProperty<Meta.Catalog.Product>()($ => $.image_url),
                    type: EUnitTypeID.stringType,
                },
            },
        },
        [EMetadataNames.ProductPrice]: {
            idMetadata: EMetadataNames.ProductPrice,
            catalogConfig: {
                [ECatalogType.Meta]: {
                    key: metaGetDeepTypedProperty<Meta.Catalog.Product>()($ => $.price),
                    type: EUnitTypeID.stringType,
                },
            },
        },

        [EMetadataNames.ProductDescription]: {
            idMetadata: EMetadataNames.ProductDescription,
            catalogConfig: {
                [ECatalogType.Meta]: {
                    key: metaGetDeepTypedProperty<Meta.Catalog.Product>()($ => $.description),
                    type: EUnitTypeID.stringType,
                },
            },
        },

        [EMetadataNames.ProductURL]: {
            idMetadata: EMetadataNames.ProductURL,
            catalogConfig: {
                [ECatalogType.Meta]: {
                    key: metaGetDeepTypedProperty<Meta.Catalog.Product>()($ => $.url),
                    type: EUnitTypeID.stringType,
                },
            },
        },

        [EMetadataNames.ProductGTIN]: {
            idMetadata: EMetadataNames.ProductGTIN,
            catalogConfig: {
                [ECatalogType.Meta]: {
                    key: metaGetDeepTypedProperty<Meta.Catalog.Product>()($ => $.gtin),
                    type: EUnitTypeID.stringType,
                },
            },
        },


        [EMetadataNames.nestedFormToGo]: {
            idMetadata: EMetadataNames.nestedFormToGo,
        },
        [EMetadataNames.nestedFormCompleted]: {
            idMetadata: EMetadataNames.nestedFormCompleted,
        },
        [EMetadataNames.channelId]: {
            idMetadata: EMetadataNames.channelId,
        },
        [EMetadataNames.telegramID]: {
            idMetadata: EMetadataNames.telegramID,
        },

        [EMetadataNames.optOut]: {
            idMetadata: EMetadataNames.optOut,
        },
        [EMetadataNames.customerAddress]: {
            idMetadata: EMetadataNames.customerAddress,
        },

        [EMetadataNames.customerProvideName]: {
            idMetadata: EMetadataNames.customerProvideName,
        },
        [EMetadataNames.channetlType]: {
            idMetadata: EMetadataNames.channetlType,
        },
        [EMetadataNames.channelAddress]: {
            idMetadata: EMetadataNames.channelAddress,
        },
        [EMetadataNames.crmTypeOfCase]: {
            idMetadata: EMetadataNames.crmTypeOfCase,
        },
        [EMetadataNames.urlShakingHands]: {
            idMetadata: EMetadataNames.urlShakingHands,
        },
        [EMetadataNames.mainDoc]: {
            idMetadata: EMetadataNames.mainDoc,
        },
        [EMetadataNames.colmeiaID]: {
            idMetadata: EMetadataNames.colmeiaID,
        },
        [EMetadataNames.email]: {
            idMetadata: EMetadataNames.email,
            regex: emailPattern,
            sanitizer: sanitizeEmail,
            examples: ['address@colmeia.me'],
        },
        [EMetadataNames.name]: {
            idMetadata: EMetadataNames.name,
            hasInsertionOnSocialNetworkCreation: true,
            examples: ['John', 'Elon'],
        },
        [EMetadataNames.cellular]: {
            idMetadata: EMetadataNames.cellular,
            sanitizer: (phone: string) => getNormalizedCellphone(phone),
            regex: mergePatterns([
                /^([0-9]{2})?([0-9]{2})([0-9]{3}|[0-9]{4})[0-9]{5}$/, // BR
                // ... 
            ]),
            examples: ['5511999999999', '5531999999999'],
        },
        [EMetadataNames.botDestiny]: {
            idMetadata: EMetadataNames.botDestiny,
        },
        [EMetadataNames.openMediaLink]: {
            idMetadata: EMetadataNames.openMediaLink,
        },
        [EMetadataNames.fullAdress]: {
            idMetadata: EMetadataNames.fullAdress,
        },
        [EMetadataNames.idUser]: {
            idMetadata: EMetadataNames.idUser,
        },
        [EMetadataNames.errorMessage]: {
            idMetadata: EMetadataNames.errorMessage,
        },
        [EMetadataNames.textMessage]: {
            idMetadata: EMetadataNames.textMessage,
        },
        [EMetadataNames.instagramID]: {
            idMetadata: EMetadataNames.instagramID,
        },
        [EMetadataNames.facebookID]: {
            idMetadata: EMetadataNames.facebookID,
        },
        [EMetadataNames.envGreetingDaily]: {
            idMetadata: EMetadataNames.envGreetingDaily,
            hasOnServicePack: true,
            hasInsertionOnSocialNetworkCreation: true,
        },
        [EMetadataNames.envInFrontOfMe]: {
            idMetadata: EMetadataNames.envInFrontOfMe,
            hasInsertionOnSocialNetworkCreation: true,
        },
        [EMetadataNames.envDispatch]: {
            idMetadata: EMetadataNames.envDispatch,
            hasInsertionOnSocialNetworkCreation: true,
        },
        [EMetadataNames.lastUserInput]: {
            idMetadata: EMetadataNames.lastUserInput,
        },

        [EMetadataNames.client]: {
            idMetadata: EMetadataNames.client,
            hasOnServicePack: true,
        },
        [EMetadataNames.date]: {
            idMetadata: EMetadataNames.date,
            hasOnServicePack: true,
        },
        [EMetadataNames.attendent]: {
            idMetadata: EMetadataNames.attendent,
            hasOnServicePack: true,
        },
        [EMetadataNames.attendentID]: {
            idMetadata: EMetadataNames.attendentID,
        },
        [EMetadataNames.logConversation]: {
            idMetadata: EMetadataNames.logConversation
        },
        [EMetadataNames.GPS]: {
            idMetadata: EMetadataNames.GPS,
        },
        [EMetadataNames.ZipCode]: {
            idMetadata: EMetadataNames.ZipCode,
        },
        [EMetadataNames.numberOfOpenTickets]: {
            idMetadata: EMetadataNames.numberOfOpenTickets,
        },
        [EMetadataNames.ticketTitle]: {
            idMetadata: EMetadataNames.ticketTitle,
        },
        [EMetadataNames.ticketProtocol]: {
            idMetadata: EMetadataNames.ticketProtocol,
        },
        [EMetadataNames.ticketSeverity]: {
            idMetadata: EMetadataNames.ticketSeverity,
        },
        [EMetadataNames.ticketUrgency]: {
            idMetadata: EMetadataNames.ticketUrgency,
        },
        [EMetadataNames.ticketSupportLevel]: {
            idMetadata: EMetadataNames.ticketSupportLevel,
        },
        [EMetadataNames.ticketServicePhase]: {
            idMetadata: EMetadataNames.ticketServicePhase,
        },
        [EMetadataNames.ticketCloseState]: {
            idMetadata: EMetadataNames.ticketCloseState,
        },
        [EMetadataNames.ticketTicketState]: {
            idMetadata: EMetadataNames.ticketTicketState,
        },


    })
    return allMetadata;
}

export const allMetadata: IMetadaJSON = defineAllMetadata();

export const servicePackFixedGlobalCanonicals: EMetadataNames[] = values(allMetadata)
    .filter(item => item.hasOnServicePack)
    .map(item => item.idMetadata)
    ;

export const globalCanonicals = mapValues(allMetadata, item => Boolean(item.hasInsertionOnSocialNetworkCreation));

// 

function values<T extends object>(object: T): ValueOf<T>[] {
    return Object.values(object) as ValueOf<T>[];
}

function mapValues<T extends object, IT extends (value: ValueOf<T>, key: keyof T) => any>(source: T, iterator: IT) {
    const result: { [key in keyof T]: ReturnType<IT> } = {} as any;
    (Object.keys(source) as (keyof T)[]).forEach((key) => result[key] = iterator(source[key], key))
    return result;
}

export const customerNamesMetadataTypes: EMetadataNames[] = [
    EMetadataNames.customerProvideName,
    EMetadataNames.client,
    EMetadataNames.name
];

export function isNameOrCellphoneCanonical(canonical: EMetadataNames) {
    return canonical === EMetadataNames.name || canonical === EMetadataNames.cellular;
}