export type TESmartFieldArray = Array<ESmartField>;
export enum ESmartField {
    Address = 'address',
    Multimedia = 'Multimedia',
    smartConfirmation = 'smartConfirmation',
    QRCode = 'qrCode',
    TwoFactorValidation = '2fv',
    NPS = 'nps',
    LGPD = 'lgpd',
    DynamicMenu = 'dynMenu',
    RTFValidator = 'rtfV', 
    FieldReuse = 'fldReuse',
    CRM = 'crm',
    docReader = 'docRdr',
}
