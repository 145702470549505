import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injector, OnDestroy, OnInit, Optional } from '@angular/core';
import { INotificationPayloadClient } from 'app/services/new-notifications-client.model';
import { NewNotificationsService, TNotificationIdMap } from 'app/services/new-notifications.service';
import { Subscription } from 'rxjs';
import { NSSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service';
import { jobTypeTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/job';
import { IJobServer, TIJobServerArray } from '@colmeia/core/src/shared-business-rules/jobs/jobs-model';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { jobTypeToRoutMap } from '../dashboard/jobs-page/jobs/jobs.component';
import { RoutingService } from 'app/services/routing.service';
import { ENotificationPayload } from '@colmeia/core/src/shared-business-rules/new-notifications/new-notification-model';
import { MatDialogRef } from '@angular/material/dialog';
import { ColmeiaDialogService } from 'app/services/dialog/dialog.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { OVERLAY_REF_DATA } from '../navigation/main-header/new-main-header.component';

@Component({
    selector: 'app-notification-dialog-box',
    templateUrl: './notification-dialog-box.component.html',
    styleUrls: ['./notification-dialog-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationDialogBoxComponent implements OnInit, OnDestroy {
    public onlyUnread: boolean = false;
    private notificationSubscription: Subscription;
    notificationsIdMap: TNotificationIdMap;
    nss: TIJobServerArray;


    constructor(
        private notificationSvc: NewNotificationsService,
        private cdr: ChangeDetectorRef,
        private routingSvc: RoutingService,
        @Inject(OVERLAY_REF_DATA) private dialogRef: OverlayRef | undefined
    ) { }

    ngOnInit() {
        this.notificationSubscription = this.notificationSvc.listenNewNotificationsToShowOnScreen().subscribe(() => {
            this.cdr.markForCheck();
            this.notificationSvc.clearUnreadCount();

            this.notificationsIdMap = this.getNotifications();
            this.getNSsFromNotifications(this.notificationsIdMap);
        });
        this.notificationSvc.clearUnreadCount();

        this.notificationsIdMap = this.getNotifications();
        this.getNSsFromNotifications(this.notificationsIdMap);
        this.cdr.markForCheck();

    }

    ngOnDestroy() {
        this.notificationSubscription.unsubscribe();
    }

    public getNotifications(): TNotificationIdMap {
        const notificationsIdMap = this.notificationSvc.getNotificationsInMemoryGrouped();
        const unreadNotifications = this.notificationSvc.getUnreadNotificationsInMemory();
        
        if(!this.onlyUnread) {
            return notificationsIdMap;
        }

        const notificationsUnreadIdMap: TNotificationIdMap = {};

        for(const w in notificationsIdMap) {
            notificationsUnreadIdMap[w] = notificationsIdMap[w].filter( ntf => unreadNotifications.includes(ntf));
        }
        return notificationsUnreadIdMap;
    }

    private async getNSsFromNotifications(notificationsIdMap: TNotificationIdMap) {
        this.nss = await NSSharedService.getNSs(Object.keys(notificationsIdMap)) as TIJobServerArray
        this.cdr.markForCheck();
    }

    public getJobTypeGroupName(idNs: string) {
        const ns = this.nss?.find(ns => ns.idNS === idNs);
        if (ns) return Serializable.getTranslation(jobTypeTranslations[ns.jobType]);
    }

    public isJobNotification(notificationType: ENotificationPayload): boolean {
        return notificationType === ENotificationPayload.job;
    }

    public navigateToPage(idNs: string, notification: INotificationPayloadClient) {
        let ns: IJobServer | undefined;
        if (idNs) ns = this.nss?.find(ns => ns.idNS === idNs);
      
        switch (notification.type) {

            case ENotificationPayload.formSolicitationComplete:
            case ENotificationPayload.adminSolicitationTaskApproval:
                this.routingSvc.navigateToId(
                    routeID.dashboard,
                    routeList.dashboard.children.security.path,
                    routeList.dashboard.children.security.children.tasks.path,
                );
                this.dialogRef?.dispose();
                break;
        
            case ENotificationPayload.job: 
                if (ns && jobTypeToRoutMap[ns.jobType]) {
                    this.routingSvc.navigateToId(
                        routeID.dashboard,
                        routeList.dashboard.children.jobs.path,
                        jobTypeToRoutMap[ns.jobType],
                    );
                }     
                this.dialogRef?.dispose();
                break;
                

        }
    }

    public canShowRedirectBtn(notificationType: ENotificationPayload): boolean {
        switch (notificationType) {
            case ENotificationPayload.job:
            case ENotificationPayload.adminSolicitationTaskApproval:
            case ENotificationPayload.formSolicitationComplete:
                return true;
        
            default: 
                return false;
        }
    }

    public markAllAsRead() {
        this.notificationSvc.resetCounter();

        this.cdr.markForCheck();
    }

    public clearAll() {
        this.notificationSvc.setNotificationsInMemoryGrouped({});
        this.cdr.markForCheck();
    }

    get notificationsLength(): number {
        return this.notificationSvc.getNotificationsInMemoryAsArray().length
        // return this.notificationSvc.getNotificationCount();
    }

    public hasUnread(): boolean {
        const unreadNotifications = this.notificationSvc.getUnreadNotificationsInMemory();
        return !!unreadNotifications.length;
    }

    public toggleReadState(notification: INotificationPayloadClient) {
        if(this.isNotificationUnread(notification)) {
            this.notificationSvc.removeFromUnread(notification);
        } else {
            this.notificationSvc.addToUnread(notification);
        }

        this.cdr.markForCheck();
    }
    public isNotificationUnread(notification: INotificationPayloadClient): boolean {
        return this.notificationSvc.getUnreadNotificationsInMemory().includes(notification);
    }
}
