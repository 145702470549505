<!-- <h3 mat-dialog-title>
  {{ translations[event.type].value }}
</h3> -->
<!-- <div *ngIf="enumPickerHandler;">
  <app-enum-picker [disabled]="isEdit" [handler]="enumPickerHandler"></app-enum-picker>
</div> -->

<div class="event-title" *ngIf="isCommandText()">
  <mat-form-field appearance="fill" class="full-width-field" style="margin-top: 14px;">
    <mat-label>{{ translations.command }}</mat-label>
    <input matInput [(ngModel)]="commandText">
  </mat-form-field>
</div>

<ng-container *ngIf="event.type">
  <ng-container *ngIf="calendarPickerHandler">
    <h5 class="ns-picker-title">Calendário de execução</h5>
    <app-ns-picker [handler]="calendarPickerHandler"></app-ns-picker>
    <mat-divider style="margin-bottom: 12px;"></mat-divider>
  </ng-container>

  <ng-container *ngIf="userFunctionBeforePickerHandler">
    <h5 class="ns-picker-title">Executar função</h5>
    <app-ns-picker [handler]="userFunctionBeforePickerHandler" style="margin-bottom: 12px;"></app-ns-picker>
    <mat-divider style="margin-bottom: 12px;"></mat-divider>
  </ng-container>

  <ng-container *ngIf="canShowEventAction()">
    <cm-bot-action-editor [handler]="eventActionHandler"></cm-bot-action-editor>
  </ng-container>

  <ng-container *ngIf="isOnConfirmation">
    <app-on-confirmation-event style="margin-bottom: 15px;" [handler]="onConfirmationConfirmHandler">
    </app-on-confirmation-event>
  </ng-container>

  <app-ns-picker *ngIf="smartFlowRTFHeaderPicker" [handler]="smartFlowRTFHeaderPicker"></app-ns-picker>

  <div *ngIf="canShowContentArray && handlerAssets" class="assets">
    <asset-adder [handler]="handlerAssets" [schemaVariables]="schemaVariables"></asset-adder>
  </div>

  <ng-container *ngIf="isInvalidOption">
    <app-conditional-expansion-panel label="Executar ação após tentativas" [(active)]="event.useActionOnErrors"
      style="margin-top: 12px;">
      <app-action-on-error-editor *ngIf="actionOnErrorHandler" [handler]="actionOnErrorHandler">
      </app-action-on-error-editor>
    </app-conditional-expansion-panel>

  </ng-container>

  <div class="event-title" *ngIf="canShowEventTitle">
    <mat-expansion-panel [expanded]="true" class="no-padding">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ nameEventText }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-single-content-asset-editor [handler]="titleSingleAssetAdderHandler"></app-single-content-asset-editor>
    </mat-expansion-panel>
  </div>

  <div *ngIf="isAddressSearch">
    <mat-expansion-panel class="no-padding">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Cabeçalho
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-single-content-asset-editor *ngIf="searchAddressHandlers.header" [handler]="searchAddressHandlers.header">
      </app-single-content-asset-editor>
    </mat-expansion-panel>
    <mat-expansion-panel class="no-padding">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Quando nenhum endereço for encontrado
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-single-content-asset-editor *ngIf="searchAddressHandlers.noAddressFound"
        [handler]="searchAddressHandlers.noAddressFound"></app-single-content-asset-editor>
    </mat-expansion-panel>
    <mat-expansion-panel class="no-padding">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Ao pesquisar endereço com método indisponível
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-single-content-asset-editor *ngIf="searchAddressHandlers.invalidSearchMode"
        [handler]="searchAddressHandlers.invalidSearchMode"></app-single-content-asset-editor>
    </mat-expansion-panel>
  </div>

  <div *ngIf="isAccuracy" class="accuracy-container">
    <form>
      <mat-accordion>
        <mat-expansion-panel #insuficientAccuracyPanel class="accuracy-panel no-padding">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="insuficient">flip</mat-icon>
              {{ insuficientAccuracyText }}

              <div class="slider-container">
                <span class="slider-label">
                  {{ defaultAccuracyMin }}%
                </span>
                <mat-icon>arrow_right_alt</mat-icon>
                <span class="slider-label">
                  {{ event.insuficientAccuracyUntil }}%
                </span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-on-confirmation-event [handler]="accuracyIgnoredConfigurableConfirmHandler">
          </app-on-confirmation-event>
        </mat-expansion-panel>

        <mat-expansion-panel #inconclusiveAccuracyPanel class="accuracy-panel no-padding">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="inconclusive">blur_off</mat-icon>
              {{ inconclusiveAccuracyText }}

              <div class="slider-container">
                <span class="slider-label">
                  {{ event.insuficientAccuracyUntil }}%
                </span>
                <mat-icon>arrow_right_alt</mat-icon>
                <span class="slider-label">
                  {{ event.inconclusiveAccuracyUntil }}%
                </span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-on-confirmation-event style="margin-bottom: 15px;"
            [handler]="accuracyConfirmConfigurableConfirmHandler"></app-on-confirmation-event>
        </mat-expansion-panel>

        <mat-expansion-panel class="accuracy-panel no-padding">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="conclusive">blur_on</mat-icon>
              {{ conclusiveAccuracyText}}
              <div class="slider-container">
                <span class="slider-label">
                  {{ event.inconclusiveAccuracyUntil }}%
                </span>
                <mat-icon>arrow_right_alt</mat-icon>
                <span class="slider-label">
                  {{ defaultAccuracyMax }}%
                </span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-on-confirmation-event style="margin-bottom: 15px;"
            [handler]="accuracySuccessConfigurableConfirmHandler"></app-on-confirmation-event>
        </mat-expansion-panel>
      </mat-accordion>

      <!-- <nouislider [config]="nouisliderConfig" (change)="setAccuracyConfig($event)"></nouislider> -->
      <app-colmeia-slider #accuracySlider *ngIf="sliderHandler" [handler]="sliderHandler" (change)="setAccuracyConfig($event)" ></app-colmeia-slider>
    </form>

    <div class="intent-list" *ngIf="hasIntent()">
      <h5>Lista de intencoes</h5>

      <ng-container *ngFor="let intent of intentNames">
        <mat-form-field class="intent-list-field" appearance="fill">
          <mat-label>{{ intent.intentName }}</mat-label>
          <input matInput [(ngModel)]="intent.intentFriendlyName">
          <mat-hint>Coloque um nome mais amigavel, se preferir</mat-hint>
        </mat-form-field>
      </ng-container>
    </div>
  </div>


  <ng-container *ngIf="hasPreCondition()">
    <h4 style="margin-left: 4px; margin-bottom: 0;">{{ translations.preCondition }}</h4>
    <mat-radio-group class="pre-condition-radio-group" [color]="'primary'" [(ngModel)]="event.preCondition.checkType">
      <mat-radio-button class="pre-condition-radio-button" (change)="radioChange($event)"
        *ngFor="let item of preConditions" [value]="item.type">{{ item.text }}</mat-radio-button>
    </mat-radio-group>
    <app-ns-picker *ngIf="canShowPreConditionNodeSelect()" [handler]="preConditionSelectHandler"></app-ns-picker>
  </ng-container>

  <div *ngIf="canShowPrePosAssets && handlerPreAssets" class="assets">
    <h5>Pré conteúdo</h5>
    <asset-adder [handler]="handlerPreAssets" [schemaVariables]="schemaVariables"></asset-adder>
    <br />
    <br />
  </div>


  <div *ngIf="canShowExpireTime()" class="expire">
    <mat-form-field class="expire-time-input" appearance="fill">
      <mat-label>{{ translations.takeActionAfter }}</mat-label>
      <input matInput type="number" matInput min="0" [(ngModel)]="expireInMinutes">
      <span matSuffix style="text-transform: lowercase;">{{ translations.minutes }}</span>
    </mat-form-field>


    <mat-form-field *ngIf="!shouldHideExpireAttemps" class="expire-time-input" appearance="fill">
      <mat-label>{{ translations.attemptsNumber }}</mat-label>
      <input matInput type="number" matInput min="0" [(ngModel)]="attempts">
    </mat-form-field>

    <div *ngIf="isProviderExpire" class="expire-time-input">
      <mat-checkbox class="boxed boxed-14 wrap-label" [(ngModel)]="event.onlyFistAnswer">
        Devolver para fila apenas em caso de demora do agente na primeira resposta
      </mat-checkbox>
    </div>

    <div *ngIf="isCustomerExpire" class="expire-time-input">
      <mat-checkbox *ngIf="shouldEnableExpireMessagesInOrder" [(ngModel)]="event.sendExpireMessagesInOrder" class="boxed boxed-14 wrap-label">
        {{ translations.sendExpireMessagesInOrder }}
      </mat-checkbox>
    </div>
  </div>

  <app-timestamp-picker
    *ngIf="isProviderExpire"
    style="width: 48%;"
    class="provider-expire-timestamp-picker"
    label="Permitir retornar atendimento para o agente penalizado em:"
    [elements]="agentPenaltyElements"
    [(time)]="agentPenalty">
  </app-timestamp-picker>

  <div *ngIf="isReuseForm() || isFieldReuseForm()">
    <app-input-number *ngIf="reuseFormNumberInputHandler" [handler]="reuseFormNumberInputHandler"></app-input-number>
    <mat-checkbox style="margin-bottom: 18px;" class="boxed boxed-14" [(ngModel)]="reuseIfSameChannelAddress">
      Reutilizar apenas se o canal de contato for o mesmo
    </mat-checkbox>
    <app-on-confirmation-event *ngIf="isReuseForm()" style="margin-bottom: 15px;" [handler]="onConfirmationConfirmHandler">
    </app-on-confirmation-event>
  </div>

  <div *ngIf="isUsefullInsight()">
    <app-input-number [handler]="insighPercentagetNumberInputHandler"></app-input-number>

    <mat-expansion-panel class="matExpasion no-padding">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Texto de caso de sucesso
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-single-content-asset-editor [handler]="yesQuestionSingleAssetAdderHandler">
      </app-single-content-asset-editor>
    </mat-expansion-panel>

    <mat-expansion-panel class="matExpasion no-padding">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Texto de caso de melhoria
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-single-content-asset-editor [handler]="noQuestionSingleAssetAdderHandler">
      </app-single-content-asset-editor>
    </mat-expansion-panel>

  </div>

  <div *ngIf="canShowPrePosAssets && handlerPosAssets" class="assets">
    <h5>Pós conteúdo</h5>
    <asset-adder [handler]="handlerPosAssets" [schemaVariables]="schemaVariables"></asset-adder>
    <br />
    <br />
  </div>
</ng-container>

<ng-container *ngIf="showChannelChooseReopen()">
  <h5>Ação para reabertura de canal</h5>
  <app-campaign-action-picker class="campaign-action-picker" [(ngModel)]="event.idOpenChannelCampaignAction"
    [filters]="onChannelCloseCampaignFilters">
  </app-campaign-action-picker>
</ng-container>

<ng-container *ngIf="allowExecuteCampaign()">
    <h5>Disparo de campanha</h5>
    <app-conditional-expansion-panel [(active)]="botFireCampaign" label="Habilitar">
    <app-bot-execute-campaign
      *ngIf="botExecuteCampaignHandler"
      [handler]="botExecuteCampaignHandler">
    </app-bot-execute-campaign>
  </app-conditional-expansion-panel>


</ng-container>

<ng-container *ngIf="userFunctionPickerHandler">
  <h5>Executar função</h5>
  <app-ns-picker [handler]="userFunctionPickerHandler"></app-ns-picker>
</ng-container>
<!-- <mat-dialog-content style="padding-bottom: 4px;">

</mat-dialog-content> -->
<mat-dialog-actions align="end" *ngIf="event.type" style="padding-bottom: 0; padding-right: 0;">
  <button mat-button (click)="closeModal()">{{ translations.cancel }}</button>
  <button mat-flat-button color="primary" (click)="saveEvent()">{{ translations.save }}</button>
</mat-dialog-actions>
