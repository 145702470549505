import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/interaction-interfaces";
import { values } from 'lodash';
import { EConfirmationType, TConfirmationTypeArray } from '../../core-constants/types';
import { EQualityOfCEP } from '../../tools/cep-core-utils';
import { freeze } from '../../tools/utility';
import { EBPMAction, TAllBPMACtion } from '../BPM/bpm-action-model';
import { EBPMMarketingActionType } from '../BPM/marketing/bpm-marketing.model';
import { ETypeBPMActionModel } from '../crm/crm-services/crm-action-model';
import { ENonSerializableObjectType } from '../non-serializable-id/non-serializable-id-interfaces';
import { EBPMConditionInformationSource, EBotConditionInformationSource, EBotTrackConditions, ECalendarAvailable, ECustomerVisitMetrics, EMKTConditionInformationSource, EMetaEngagementConditionType, EMetadataEngagementType, ESourceOfInfo, IBasicCondition, TAllConditionalType } from './meta-engagement';
import { EBPMSubEvent } from "../BPM/common/common-bpm";
import { ETransformerDocumentResult } from "../transformer/transformer.model";

export enum EEngagementValueTypes {
    String = "String",
    Number = "Number",
    List = "List",
    Empty = "Empty",
    UserFunction = "UserFunction",
    Enum = 'Enum',
    DateValidation = "DateValidation",
    Timestamp = "Timestamp",
    GenerativeChunks = "GenerativeChunks",
    NonSerializable = "nser"
}

export interface IControlMeta {
    allowCanonical?: true;
    hideTarget?: true;
    onlyPreviousFields?: true;
    needIdInformationSource?: boolean;
    idsInformationSource?: string[],
    /**
     * Apenas para UI, os valores são sempre salvos como string
     */
    valueType?: EEngagementValueTypes;
    maxTokens?: number;
    minTokens?: number;
    valuesList?: string[];
    noNeedCondition?: boolean;
    needIdUserFunction?: boolean;
    enums?: string[];
    multiple?: boolean;
    hideReverse?: boolean;
    valuePatternRegex?: RegExp;
    nsType?: ENonSerializableObjectType;
}

export type TAllMetaEngagementControlForCondition = {
    bpmActionConfig: IControlMeta,
    metaConditionTypeConfig: IControlMeta,
    eSourceInfoConfig: IControlMeta,
    eSourceInfoConfigDefault: IControlMeta,
    eSourceInfoConfigMetaTypeOverrides: IControlMeta,
    idsInfoConfig: IControlMeta,
};

type TAllEngagementOptions = EMetadataEngagementType | TAllBPMACtion | TAllConditionalType | ESourceOfInfo;
type TDbControlMeta = { [key in TAllEngagementOptions]?: IControlMeta };

const baseMktControl: IControlMeta = {
    idsInformationSource: [
        EMKTConditionInformationSource.lastStatus,
        EMKTConditionInformationSource.iddleTime
    ],
    needIdInformationSource: true,
    hideReverse: true
}

export const dbControlMetaEngagement: TDbControlMeta = {

    // Value Types
    [EMetaEngagementConditionType.list]: {
        valueType: EEngagementValueTypes.List
    },
    [EMetaEngagementConditionType.interval]: {
        valueType: EEngagementValueTypes.List,
        minTokens: 2,
        maxTokens: 2
    },
    [EMetaEngagementConditionType.userFunction]: {
        valueType: EEngagementValueTypes.UserFunction,
        needIdUserFunction: true,
        noNeedCondition: true
    },
    [EMetaEngagementConditionType.notNull]: {
        valueType: EEngagementValueTypes.Empty,
        noNeedCondition: true
    },
    [EMetaEngagementConditionType.isAllwaysTrue]: {
        valueType: EEngagementValueTypes.Empty,
        noNeedCondition: true,
        hideTarget: true
    },
    [EMetaEngagementConditionType.isChannel]: {
        valueType: EEngagementValueTypes.Enum,
        enums: values(EDelivery360Action),
        multiple: true,
        hideTarget: true
    },
    [EMetaEngagementConditionType.metaNull]: {
        valueType: EEngagementValueTypes.Empty,
        noNeedCondition: true
    },
    [EMetaEngagementConditionType.greater]: {
        valueType: EEngagementValueTypes.Number,
    },
    [EMetaEngagementConditionType.smaller]: {
        valueType: EEngagementValueTypes.Number,
    },
    [EMetaEngagementConditionType.literal]: {
        valueType: EEngagementValueTypes.String,
    },
    [EMetaEngagementConditionType.regex]: {
        valueType: EEngagementValueTypes.String,
    },
    [EMetaEngagementConditionType.contains]: {
        valueType: EEngagementValueTypes.List,
    },
    [EMetaEngagementConditionType.dateValidation]: {
        valueType: EEngagementValueTypes.DateValidation,
        noNeedCondition: true
    },

    // LGPD
    [EBPMAction.getTitularData]: {
        hideTarget: true
    },
    [EBPMAction.optIn]: {
        hideTarget: true
    },
    [EBPMAction.optOut]: {
        hideTarget: true
    },
    [EBPMAction.anomization]: {
        hideTarget: true
    },

    // Common
    [EBPMAction.validator]: {
    },
    [EBPMAction.action]: {
        allowCanonical: true,
        needIdInformationSource: true
    },
    [EBPMAction.conditionalDisplay]: {
        allowCanonical: true,
        onlyPreviousFields: true,
        needIdInformationSource: true
    },
    [ETypeBPMActionModel.Assign]: {},
    [ETypeBPMActionModel.CloseTicket]: {},
    [ETypeBPMActionModel.GerFollowUpFromCustomer]: {},
    [ETypeBPMActionModel.Notification]: {},
    [ETypeBPMActionModel.executeFunction]: {},

    [EBPMMarketingActionType.goCampaignWithBot]: {
        ...baseMktControl,
    },

    [EBPMMarketingActionType.messageWithCampaing]: {
        ...baseMktControl,
    },

    [ESourceOfInfo.mkt]: {
        ...baseMktControl,
    },

    [ESourceOfInfo.bot]: {
        idsInformationSource: values(EBotConditionInformationSource),
        needIdInformationSource: true,
        hideReverse: true
    },
    [ESourceOfInfo.metrics]: {
        idsInformationSource: values(ECustomerVisitMetrics),
        needIdInformationSource: true,
        hideReverse: true,
    },
    [ESourceOfInfo.botTracker]: {
        idsInformationSource: values(EBotTrackConditions),
        needIdInformationSource: true,
    },
    [ESourceOfInfo.crm]: {
        idsInformationSource: [
            EBPMConditionInformationSource.event,
            ENonSerializableObjectType.crmItemState,
            ENonSerializableObjectType.crmItemCloseState,
            ENonSerializableObjectType.crmItemPhase,
            ENonSerializableObjectType.crmItemSeverity,
            ENonSerializableObjectType.crmItemSupportLevel,
            ENonSerializableObjectType.crmItemUrgencyLevel,
        ] as string[],
        needIdInformationSource: true,
    },
} as const;


type TSourceInfoConfig = {
    [key in ESourceOfInfo]: {
        idInformationSourceNSType?: ENonSerializableObjectType;
        conditionalTypes: TAllConditionalType[],
        idMetaOverride?: {
            [idFixed: string]: TDbControlMeta
        },
        conditionalTypeMetaOverride?: TDbControlMeta
    }
};

const marketingActionStatusValues: TConfirmationTypeArray = [
    EConfirmationType.read,
    EConfirmationType.respond,
    EConfirmationType.notRead,
    EConfirmationType.notAnswered,
    EConfirmationType.ignored,
    EConfirmationType.error,
];

export const sourceInfoConditionalTypeControl: TSourceInfoConfig = freeze<TSourceInfoConfig>({
    [ESourceOfInfo.canonical]: {
        idInformationSourceNSType: ENonSerializableObjectType.canonical,
        conditionalTypes: values(EMetaEngagementConditionType)
    },
    [ESourceOfInfo.property]: {
        conditionalTypes: values(EMetaEngagementConditionType)
    },
    [ESourceOfInfo.mkt]: {
        conditionalTypes: [
            EMetaEngagementConditionType.literal,
            EMetaEngagementConditionType.list,
            EMetaEngagementConditionType.smaller,
            EMetaEngagementConditionType.greater,
        ],
        idMetaOverride: {
            [EMKTConditionInformationSource.lastStatus]: {
                [EMetaEngagementConditionType.literal]: {
                    valueType: EEngagementValueTypes.Enum,
                    enums: marketingActionStatusValues,
                    multiple: false,
                },
                [EMetaEngagementConditionType.list]: {
                    valueType: EEngagementValueTypes.Enum,
                    enums: marketingActionStatusValues,
                    multiple: true,
                }
            },
            [EMKTConditionInformationSource.iddleTime]: {
                [EMetaEngagementConditionType.greater]: {
                    ...dbControlMetaEngagement[EMetaEngagementConditionType.greater],
                    valueType: EEngagementValueTypes.Timestamp,
                },
                [EMetaEngagementConditionType.smaller]: {
                    ...dbControlMetaEngagement[EMetaEngagementConditionType.smaller],
                    valueType: EEngagementValueTypes.Timestamp,
                }
            }
        }
    },
    [ESourceOfInfo.crm]: {
        conditionalTypes: [EMetaEngagementConditionType.notNull, EMetaEngagementConditionType.literal],
        idMetaOverride: {
            [EBPMConditionInformationSource.event]: {
                [EMetaEngagementConditionType.literal]: {
                    valueType: EEngagementValueTypes.Enum,
                    enums: values(EBPMSubEvent),
                    multiple: false,
                },
            },
            [ENonSerializableObjectType.crmItemState]: {
                [EMetaEngagementConditionType.literal]: {
                    valueType: EEngagementValueTypes.NonSerializable,
                    nsType: ENonSerializableObjectType.crmItemState,
                },
                [EMetaEngagementConditionType.list]: {
                    valueType: EEngagementValueTypes.NonSerializable,
                    nsType: ENonSerializableObjectType.crmItemPhase,
                    multiple: true,
                },
            },
            [ENonSerializableObjectType.crmItemCloseState]: {
                [EMetaEngagementConditionType.literal]: {
                    valueType: EEngagementValueTypes.NonSerializable,
                    nsType: ENonSerializableObjectType.crmItemCloseState,
                },
            },
            [ENonSerializableObjectType.crmItemPhase]: {
                [EMetaEngagementConditionType.literal]: {
                    valueType: EEngagementValueTypes.NonSerializable,
                    nsType: ENonSerializableObjectType.crmItemPhase,
                },
            },
            [ENonSerializableObjectType.crmItemSeverity]: {
                [EMetaEngagementConditionType.literal]: {
                    valueType: EEngagementValueTypes.NonSerializable,
                    nsType: ENonSerializableObjectType.crmItemSeverity,
                },
            },
            [ENonSerializableObjectType.crmItemSupportLevel]: {
                [EMetaEngagementConditionType.literal]: {
                    valueType: EEngagementValueTypes.NonSerializable,
                    nsType: ENonSerializableObjectType.crmItemSupportLevel,
                },
            },
            [ENonSerializableObjectType.crmItemUrgencyLevel]: {
                [EMetaEngagementConditionType.literal]: {
                    valueType: EEngagementValueTypes.NonSerializable,
                    nsType: ENonSerializableObjectType.crmItemUrgencyLevel,
                },
            }
        },
    },
    [ESourceOfInfo.bot]: {
        conditionalTypes: [
            EMetaEngagementConditionType.literal,
            EMetaEngagementConditionType.list,
        ],
        idMetaOverride: {
            [EBotConditionInformationSource.lastAddressSearch]: {
                [EMetaEngagementConditionType.literal]: {
                    enums: values(EQualityOfCEP),
                    valueType: EEngagementValueTypes.Enum,
                },
                [EMetaEngagementConditionType.list]: {
                    valueType: EEngagementValueTypes.Enum,
                    multiple: true,
                    enums: values(EQualityOfCEP),
                }
            },
            [EBotConditionInformationSource.currentChannel]: {
                [EMetaEngagementConditionType.literal]: {
                    valueType: EEngagementValueTypes.Enum,
                    enums: values(EDelivery360Action)
                },
                [EMetaEngagementConditionType.list]: {
                    valueType: EEngagementValueTypes.Enum,
                    enums: values(EDelivery360Action),
                    multiple: true,
                }
            }
        },
    },
    [ESourceOfInfo.serviceScheduler]: {
        idInformationSourceNSType: ENonSerializableObjectType.attendanceCalendar,
        conditionalTypes: [
            EMetaEngagementConditionType.literal,
        ],
        conditionalTypeMetaOverride: {
            [EMetaEngagementConditionType.literal]: {
                valueType: EEngagementValueTypes.Enum,
                enums: values(ECalendarAvailable),
            }
        }
    },
    [ESourceOfInfo.campaignAction]: {
        idInformationSourceNSType: ENonSerializableObjectType.campaing,
        conditionalTypes: [
            EMetaEngagementConditionType.literal,
            EMetaEngagementConditionType.list,
        ],
        conditionalTypeMetaOverride: {
            [EMetaEngagementConditionType.literal]: {
                valueType: EEngagementValueTypes.Enum,
                enums: marketingActionStatusValues,
            },
            [EMetaEngagementConditionType.list]: {
                valueType: EEngagementValueTypes.Enum,
                multiple: true,
                enums: marketingActionStatusValues,
            }
        },
    },
    [ESourceOfInfo.metrics]: {
        conditionalTypes: [
            EMetaEngagementConditionType.literal,
            EMetaEngagementConditionType.greater,
            EMetaEngagementConditionType.smaller,
        ],
        conditionalTypeMetaOverride: {
            [EMetaEngagementConditionType.literal]: {
                ...dbControlMetaEngagement[EMetaEngagementConditionType.literal],
                valueType: EEngagementValueTypes.Number,
            },
        }
    },
    [ESourceOfInfo.botTracker]: {
        conditionalTypes: [
            EMetaEngagementConditionType.literal,
        ],
    },
    [ESourceOfInfo.generativeChunks]: {
        idInformationSourceNSType: ENonSerializableObjectType.contentVectorChunk,
        conditionalTypes: [
            EMetaEngagementConditionType.list,
        ],
        conditionalTypeMetaOverride: {
            [EMetaEngagementConditionType.list]: {
                valueType: EEngagementValueTypes.GenerativeChunks,
                multiple: true,
            }
        }
    },
    [ESourceOfInfo.transformer]: {
        idInformationSourceNSType: ENonSerializableObjectType.transformer,
        conditionalTypes: [
            EMetaEngagementConditionType.literal,
        ],

        conditionalTypeMetaOverride: {
            [EMetaEngagementConditionType.literal]: {
                valueType: EEngagementValueTypes.Enum,
                enums: values(ETransformerDocumentResult),
            }
        },
    }
});

export function getAllMetaEngagementConfigsByCondition(condition: IBasicCondition, bpmAction?: TAllBPMACtion): TAllMetaEngagementControlForCondition {
    const bpmActionConfig = dbControlMetaEngagement[bpmAction];
    const metaConditionTypeConfig = dbControlMetaEngagement[condition.metaConditionType];
    const eSourceInfoConfigDefault = dbControlMetaEngagement[condition.sourceInfo];
    const eSourceInfoConfigMetaTypeOverrides = sourceInfoConditionalTypeControl[condition.sourceInfo]?.conditionalTypeMetaOverride?.[condition.metaConditionType];
    const idsInfoConfig = sourceInfoConditionalTypeControl[condition.sourceInfo]?.idMetaOverride?.[condition.idInformationSource]?.[condition.metaConditionType];

    return {
        bpmActionConfig,
        metaConditionTypeConfig,
        idsInfoConfig,
        eSourceInfoConfig: {
            ...eSourceInfoConfigDefault,
            ...eSourceInfoConfigMetaTypeOverrides,
        },
        eSourceInfoConfigDefault,
        eSourceInfoConfigMetaTypeOverrides,
    }
}

export function getValueRegexByCondition(condition: IBasicCondition, bpmAction?: TAllBPMACtion): RegExp | undefined {
    const { idsInfoConfig, bpmActionConfig, metaConditionTypeConfig, eSourceInfoConfig } = getAllMetaEngagementConfigsByCondition(condition, bpmAction);
    const valueRegex = idsInfoConfig?.valuePatternRegex
        || eSourceInfoConfig?.valuePatternRegex
        || bpmActionConfig?.valuePatternRegex;

    return valueRegex;
}

export function getBestMetaEngagementConfig(condition: IBasicCondition, bpmAction?: TAllBPMACtion): IControlMeta | undefined {
    const { idsInfoConfig, bpmActionConfig, metaConditionTypeConfig, eSourceInfoConfig } = getAllMetaEngagementConfigsByCondition(condition, bpmAction);
    return {
        ...bpmActionConfig,
        ...eSourceInfoConfig,
        ...idsInfoConfig,
    }
}

export function getBestMetaEngagementConfigByCondition(condition: IBasicCondition, bpmAction?: TAllBPMACtion): IControlMeta {
    const { bpmActionConfig, metaConditionTypeConfig, eSourceInfoConfig, idsInfoConfig } = getAllMetaEngagementConfigsByCondition(condition, bpmAction);

    return {
        ...metaConditionTypeConfig,
        ...bpmActionConfig,
        ...eSourceInfoConfig,
        ...idsInfoConfig,
    };
}

export function getMetaEngagementConfigsConditionType(sourceInfo: ESourceOfInfo, metaConditionType: EMetaEngagementConditionType, idInformationSource?: string): IControlMeta {
    const metaConditionTypeConfig = dbControlMetaEngagement[metaConditionType];
    const eSourceInfoConfigDefault = dbControlMetaEngagement[sourceInfo];
    const eSourceInfoConfigMetaTypeOverrides = sourceInfoConditionalTypeControl[sourceInfo]?.conditionalTypeMetaOverride?.[metaConditionType];
    const idsInfoConfig = sourceInfoConditionalTypeControl[sourceInfo]?.idMetaOverride?.[idInformationSource]?.[metaConditionType];

    return {
        ...metaConditionTypeConfig,
        ...eSourceInfoConfigDefault,
        ...eSourceInfoConfigMetaTypeOverrides,
        ...idsInfoConfig,
    }
}
